import { useNavigate } from "react-router-dom"
import { ArrowLeftIcon } from "../../../../ui/icons/arrow-left"
import { Layout } from "../../../../ui/layout"
import Button from "../../../../ui/molecules/button"
import { ClientBanner } from "../../../../ui/molecules/clientBanner"
import { Banner } from "../../../../ui/organisms/banner"
import { MenuItems } from "../../../../ui/organisms/navbar/dto"
import { attivita_bio, normativa, optionsFormatter } from "../../../../utils"
import { RightBar } from "../../../../ui/organisms/rightBar"
import { EditRIDForms } from "./forms"
import { RightEditRID } from "./right"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { GetRIDBYId, resetGetRidByIdResponse, resetRIDUpdateStatus, RIDUpdate } from "../slice"

export function EditRID() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const ridState = useAppSelector(state => state.rid)
    const sessionState = useAppSelector(state => state.session)
    const [showSuccess, setShowSuccess] = useState<boolean>(false)

    useEffect(() => {
        if(ridState.ridUpdateStatus === 'successfully') {
            setShowSuccess(true)
            dispatch(resetRIDUpdateStatus())
            dispatch(GetRIDBYId(ridState.ridIdToUpdate))
        }
    }, [ridState.ridUpdateStatus])

    useEffect(() => {
        if(ridState.ridStateUpdateStatus === 'successfully') {
            setShowSuccess(true)
        }
    }, [ridState.ridStateUpdateStatus])

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setShowSuccess(false)
            }, 2000)
        }
    }, [showSuccess])

    useEffect(() => {
        dispatch(resetGetRidByIdResponse())
    }, [])
    
    if(sessionState.selected.customerId) {
        return (
            <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione RID']}
                noPaddingLat
                headerLabel="Gestione R.I.D."
                headerChildren={
                    <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>


                        <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                            onClick={() => {
                                dispatch(RIDUpdate({body: ridState.ridUpdateRequest, id: ridState.ridIdToUpdate}))
                            }}
                        />

                    </div>
                }
                headerIcon={
                    <button style={{paddingTop: '12px'}} onClick={() => navigate('/rid')}>
                        <ArrowLeftIcon />
                    </button>
                }
            >
                <div style={{display: 'flex', minHeight: window.innerHeight}}>
                    <div style={{padding: '24px', flex: '0.7'}}>
                        {/* <div className="pb-5">
                            <ClientBanner
                                region={sessionState.selected.customerRegion!}
                                client={sessionState.selected.customerName!}
                                code={sessionState.selected.customerCode!}
                                activity={sessionState.selected.customerActivities ? `${sessionState.selected.customerActivities.map(act => optionsFormatter(act, attivita_bio))}` : undefined}
                                norm={optionsFormatter(sessionState.selected.customerNorm!, normativa)!}
                            />
                        </div> */}

                        {
                            showSuccess &&
                            <div className="gap-[8px] text-left" style={{ margin: '24px', borderRadius: '12px', backgroundColor: '#D1F5D9', borderColor: '#8AE59F', borderWidth: 2 }}>
                                <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                                    Dati salvati correttamente.
                                </span>
                            </div>
                        }

                        <div>
                            <EditRIDForms />
                        </div>
                    </div>
                    <div className="bg-brandPrimary-100" style={{flex: '0.3'}}>
                        <RightBar>
                            <RightEditRID />
                        </RightBar>
                    </div>
                </div>
            </Layout>
        )
    }

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Cartella Aziendale']}
            noPaddingLat
            headerLabel={"Cartella Aziendale"}
            customerName={sessionState.selected.customerName ? sessionState.selected.customerName : undefined}
            customerCode={sessionState.selected.customerCode ? sessionState.selected.customerCode : undefined}
        >
            <Banner
                type="error"
                visible={!sessionState.selected.customerId}
                label={"Devi prima selezionare un cliente per accedere all'area"}
                closeAction={function (): void {
                    alert("Devi prima selezionare un cliente per accedere all'area")
                }}
            />
        </Layout>
    )
}