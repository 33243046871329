import { useEffect, useState } from "react"
import Button from "../../ui/molecules/button"
import { SelectCustom } from "../../ui/molecules/select"
import Input from "../../ui/molecules/input/Input"
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks"
import { addPMResource, GetAllCodexSamples, resetGetAllCodexSampleFilters, setGetAllCodexSampleEnvelopNumber, setGetAllCodexSamplePage, setGetAllCodexSamplePersonalManagementId, setGetAllCodexSampleStatus } from "./slice"
import { Pagination } from "../../ui/organisms/pagination"
import { CodexSampleStatusOptions, optionsFormatter, OptionType } from "../../utils"
import { GetAllPersonalManagement, resetGetAllPersonalManagementResourcesStatus } from "../gestionePersonale/slice"
import { GetByIdAnagraphic, resetGetByIdStatus } from "../gestionePersonale/boxAnagrafica/slice"

enum FilteringTypologyEnum {
    INSPECTOR = 'INSPECTOR',
    NUMBER = 'NUMBER'
}

export function GestionePrelieviList() {
    const dispatch = useAppDispatch()
    const codexSampleState = useAppSelector(state => state.codexSample)
    const [filteringTypology, setFilteringTypology] = useState<FilteringTypologyEnum>(FilteringTypologyEnum.INSPECTOR)

    useEffect(() => {
        dispatch(GetAllCodexSamples(codexSampleState.getAllCodexSampleFilters))
    }, [codexSampleState.getAllCodexSampleFilters.page])

    return (
        <div>
            <div style={{ padding: '0px 24px 0px 24px', display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'flex-end' }}>
                <div style={{ flex: '0.25' }}>
                    <div className="text-left">
                        <span className="input-label">Filtra per</span>
                    </div>
                    <SelectCustom
                        value={filteringTypology}
                        placeholder={'Seleziona tipo di filtro...'}
                        options={[{ value: FilteringTypologyEnum.INSPECTOR, label: 'Tecnico Ispettore' },
                        { value: FilteringTypologyEnum.NUMBER, label: 'Numero Busta' }]}
                        onChange={(value) => setFilteringTypology(value as FilteringTypologyEnum)}
                    />
                </div>
                {
                    filteringTypology === FilteringTypologyEnum.INSPECTOR && (
                        <div style={{ flex: '0.5', display: 'flex', gap: '24px' }}>
                            <div style={{ flex: '0.5' }}>
                                <div className="text-left">
                                    <span className="input-label">Tecnico Ispettore</span>
                                </div>
                                <SelectCustom
                                    key={codexSampleState.getAllCodexSampleFilters.personalManagementId || 'default'}
                                    value={codexSampleState.getAllCodexSampleFilters.personalManagementId}
                                    placeholder={'Seleziona ispettore...'}
                                    options={codexSampleState.PMResources}
                                    onChange={(value) => dispatch(setGetAllCodexSamplePersonalManagementId(value))}
                                />
                            </div>
                            <div style={{ flex: '0.5' }}>
                                <div className="text-left">
                                    <span className="input-label">Stato Buste Prelievo Campione</span>
                                </div>
                                <SelectCustom
                                    key={codexSampleState.getAllCodexSampleFilters.status || 'default'}
                                    value={codexSampleState.getAllCodexSampleFilters.status}
                                    errorLabel='Stato mancante'
                                    placeholder={'Seleziona stato...'}
                                    options={CodexSampleStatusOptions}
                                    onChange={(value) => dispatch(setGetAllCodexSampleStatus(value))}
                                />
                            </div>
                        </div>
                    )
                }
                {
                    filteringTypology === FilteringTypologyEnum.NUMBER && (
                        <div style={{ flex: '0.25' }}>
                            <div className="text-left">
                                <span className="input-label">Numero busta</span>
                            </div>
                            <Input
                                value={codexSampleState.getAllCodexSampleFilters.envelopNumber !== undefined ?
                                    String(codexSampleState.getAllCodexSampleFilters.envelopNumber) : undefined}
                                placeholder="Specifica n. busta..."
                                onChangeText={(text) => dispatch(setGetAllCodexSampleEnvelopNumber(text))}
                            />
                        </div>
                    )
                }
                <Button
                    size={"lg"}
                    iconPosition={"off"}
                    variant={"link"}
                    label="Pulisci"
                    color={"gray"}
                    onClick={() => dispatch(resetGetAllCodexSampleFilters())}
                />
                <Button
                    size={"lg"}
                    iconPosition={"off"}
                    variant={"outline"}
                    label="Cerca"
                    color={"blue"}
                    onClick={() => dispatch(GetAllCodexSamples(codexSampleState.getAllCodexSampleFilters))}
                />
            </div>
            <div style={{ padding: '24px' }}>
                <table id="users-table" className="w-[100%]">
                    <thead className="h-[52px] uppercase">
                        <tr>
                            <th style={{ borderTopLeftRadius: '8px' }}></th>
                            <th>Ispettore</th>
                            <th>Numero Busta Prelievo Campioni</th>
                            <th>Protocollo Assegnazione</th>
                            <th>Stato</th>
                            <th>Incarico / Visita</th>
                            <th>Cliente</th>
                            <th>Normativa</th>
                            <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            codexSampleState.getAllCodexSampleResponse?.data.map((pre, index) =>
                                <tr
                                    key={index}
                                    style={{
                                        color: '#4D797F',
                                        background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                    }}
                                >
                                    <td></td>
                                    <td>{pre.personalManagementInfo}</td>
                                    <td>{`${Array(7-String(pre.envelopNumber).length).fill('0').join('')}${pre.envelopNumber}`}</td>
                                    <td>{pre.entryProtocolInfo}</td>
                                    <td>{optionsFormatter(pre.status, CodexSampleStatusOptions)}</td>
                                    <td>{`Incarico: TO FUTURE della Visita ispettiva del TO FUTURE`}</td>
                                    <td>CLIENTE [DA FARE]</td>
                                    <td>NORMATIVA [DA FARE]</td>
                                    <td></td>
                                </tr>
                            )}
                    </tbody>
                </table>
                <Pagination
                    numbResults={codexSampleState.getAllCodexSampleResponse?.total || 0}
                    pages={codexSampleState.getAllCodexSampleResponse?.totalPage || 1}
                    currentPage={codexSampleState.getAllCodexSampleFilters.page || 0}
                    setPage={
                        (page) => {
                            dispatch(setGetAllCodexSamplePage(page))
                        }
                    }
                />
            </div>
        </div>
    )
}