import { ReactNode, useEffect, useRef, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks';
import { Layout } from "../../ui/layout";
import { keycloak } from "../../lib/keycloak"
import './style.scss';
import Select from 'react-select'
import Button from "../../ui/molecules/button";
import { useNavigate } from "react-router-dom";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { SelectCustom } from "../../ui/molecules/select";
import { status, sot, normativa } from "../../utils";
import { ClientiList } from "./list";
import Input from "../../ui/molecules/input/Input";
import { GetCustomers, resetFilters, setFindAllCustomersFiltersNorm, setFindAllCustomersFiltersPage, setFindAllCustomersFiltersSearch, setHeadQuarterFilter, setStatusFilter } from "./slice";
import { Pagination } from "../../ui/organisms/pagination";
import { SOTFilter } from "../sot";

interface Props {
    children: ReactNode
}

export function Clienti() {

    const clientiState = useAppSelector(state => state.clienti)
    const usersState = useAppSelector(state => state.users)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [searchCliente, setSearchCliente] = useState('')
    const selectInputRef = useRef<Select>(null);
    const [selectedValue, setSelectedValue] = useState<string | undefined>('');
    const [activeFilter, setActiveFilter] = useState<'active' | 'inactive' | 'idle'>('idle');

    useEffect(() => {
        if (usersState.findMeStatus === 'successfully') {
            if (usersState.findMeResponse.headQuarterInfo?.code != 'SC' && usersState.findMeResponse.role !== 'admin') {
                dispatch(setHeadQuarterFilter(usersState.findMeResponse.headQuarterInfo?.id))
            }
        }
    }, [usersState.findMeStatus])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Clienti']}
            headerLabel="Lista Clienti"
            headerChildren={
                <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>


                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Aggiungi Cliente" color={"orange"}
                        onClick={() => {
                            navigate('/cliente')
                        }}
                    />

                </div>
            }
        >
            <div className="w-[100%] flex flex-col gap-[16px] filter" style={{ padding: '24px 0px 0px 0px' }}>
                <div className="flex flex-row gap-[16px]" style={{ flex: 1, alignItems: 'center' }}>
                    <div style={{ justifyContent: 'left', flex: 0.7 }} className="flex gap-[16px]">
                        <div style={{ flex: 0.6 }}>
                            <Input
                                placeholder="Cerca Codice Cliente, CUAA o Ragione Sociale"
                                value={clientiState.filters.search}
                                onChangeText={(text) =>
                                    dispatch(setFindAllCustomersFiltersSearch(text))
                                }
                            />
                        </div>
                        <div style={{ flex: 0.3 }}>
                            <SOTFilter
                                key={clientiState.filters.headquarterId || 'default'}
                                value={clientiState.filters.headquarterId}
                                disabled={usersState.findMeResponse.headQuarterInfo?.code !== 'SC' && usersState.findMeResponse.role !== 'admin'}
                                onChange={(value) => {
                                    dispatch(setHeadQuarterFilter(value))
                                }}
                            />
                            
                        </div>

                        <div style={{ flex: 0.4 }}>
                            <SelectCustom
                                key={clientiState.filters.norm || 'default'}
                                ref={selectInputRef}
                                placeholder={'Norma'}
                                options={normativa}
                                value={clientiState.filters.norm}
                                onChange={(value) => {
                                    dispatch(setFindAllCustomersFiltersNorm(value))
                                    if (value !== undefined)
                                        setSelectedValue(value)
                                }}
                            />
                        </div>
                        <div>
                            <SelectCustom
                                key={activeFilter || 'default'}
                                placeholder={'Stato'}
                                options={status}
                                value={activeFilter}
                                onChange={(value) => {
                                    dispatch(setStatusFilter(value))
                                    if (value === 'active' || value === 'inactive') {
                                        setActiveFilter(value)
                                    }
                                }}
                            />

                        </div>
                        <div>
                            <span>
                                <Button
                                    size={"lg"}
                                    iconPosition={"off"}
                                    variant={"link"}
                                    label="Pulisci"
                                    color={"gray"}
                                    onClick={
                                        () => {
                                            dispatch(resetFilters())
                                            if (usersState.findMeResponse.headQuarterInfo?.code != 'SC' && usersState.findMeResponse.role !== 'admin') {
                                                dispatch(setHeadQuarterFilter(usersState.findMeResponse.headQuarterInfo?.id))
                                            }
                                            setSelectedValue(undefined)
                                            setActiveFilter('idle')
                                        }
                                    }
                                />
                            </span>
                        </div>

                        <div>
                            <span>
                                <Button
                                    size={"lg"}
                                    iconPosition={"off"}
                                    variant={"outline"}
                                    label="Cerca"
                                    color={"blue"}
                                    onClick={
                                        () => {
                                            dispatch(GetCustomers(clientiState.filters))
                                        }
                                    }
                                />
                            </span>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'right', flex: 0.3 }}>

                        <Pagination
                            numbResults={clientiState.getCustomersResponse ? clientiState.getCustomersResponse.total : 0}
                            pages={clientiState.getCustomersResponse?.totalPage || 1}
                            currentPage={clientiState.filters.page ? clientiState.filters.page : 0}
                            detailsResults={'clienti'}
                            setPage={
                                (page) => {
                                    dispatch(setFindAllCustomersFiltersPage(page))
                                    dispatch(GetCustomers(clientiState.filters))
                                }
                            }

                        />
                    </div>


                </div>


            </div>
            <ClientiList></ClientiList>
        </Layout>
    )
}