import { useNavigate } from "react-router-dom"
import { ArrowLeftIcon } from "../../../../ui/icons/arrow-left"
import { Layout } from "../../../../ui/layout"
import { MenuItems } from "../../../../ui/organisms/navbar/dto"
import Button from "../../../../ui/molecules/button"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { Banner } from "../../../../ui/organisms/banner"
import { useEffect, useState } from "react"
import { EditFormRequest } from "./form"
import { RightBar } from "../../../../ui/organisms/rightBar"
import { EditStateRequest } from "./editStateRequest"
import { ComplianceRequestsUpdate, ComplianceRequetsValidation, GetByIdComplianceRequests, resetComplianceRequestsStateStatus, resetComplianceRequestsValidationStatus, resetCreateStateRequest, resetDeleteStateStatus, resetGetByIdStateStatus, resetUpdateStatus, setEditCustomerOtherOdcDefault, setEditRequestDescription, setEditRequestNote, setEditRequestProduct, setEditRequestProtocolId, setEditRequestSender, setEditRequestType, setRequestId, setRequestStateDetails } from "../slice"
import { request } from "http"

export function EditRequestComplianceRequest() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const sessionState = useAppSelector(state => state.session)
    const complianceRequestState = useAppSelector(state => state.complianceRequest)

    useEffect(() => {
        if (complianceRequestState.complianceRequestUpdateStatus === 'successfully') {
            setShowSuccess(true)
            dispatch(resetUpdateStatus())
            setTimeout(() => {
                setShowSuccess(false)
                navigate('/complianceRequests')
            }, 2000);
        }
    }, [complianceRequestState.complianceRequestUpdateStatus])

    useEffect(() => {
        if (complianceRequestState.complianceRequestErrorsStatus === 'successfully') {
            dispatch(resetComplianceRequestsValidationStatus())
            dispatch(ComplianceRequestsUpdate(
                {
                    body: complianceRequestState.complianceRequestsUpdate,
                    id: complianceRequestState.complianceRequestId
                }
            ))
        }
    }, [complianceRequestState.complianceRequestErrorsStatus])

    useEffect(() => {
        if (complianceRequestState.ComplianceRequestStateDeleteStatus === 'successfully') {
            dispatch(resetDeleteStateStatus())
            dispatch(GetByIdComplianceRequests(complianceRequestState.complianceRequestId!))
        }
    }, [complianceRequestState.ComplianceRequestStateDeleteStatus])

    useEffect(() => {
        if (complianceRequestState.ComplianceRequestStateCreationStatus === 'successfully') {
            dispatch(resetComplianceRequestsStateStatus())
            dispatch(GetByIdComplianceRequests(complianceRequestState.complianceRequestId!))
            dispatch(resetCreateStateRequest())
        }
    }, [complianceRequestState.ComplianceRequestStateCreationStatus])



    useEffect(() => {
        if (complianceRequestState.getComplianceRequestByIdStatus === 'successfully') {
            dispatch(resetGetByIdStateStatus())
            if (complianceRequestState.getComplianceRequestsByIdResponse) {
                dispatch(setRequestStateDetails(complianceRequestState.getComplianceRequestsByIdResponse.complianceRequestStates))
                if (complianceRequestState.getComplianceRequestsByIdResponse.otherOdcCustomer &&
                    complianceRequestState.getComplianceRequestsByIdResponse.otherOdcCustomer?.length > 0) {
                    dispatch(setEditCustomerOtherOdcDefault(complianceRequestState.getComplianceRequestsByIdResponse.otherOdcCustomer))
                }

                dispatch(setRequestId(complianceRequestState.getComplianceRequestsByIdResponse.id))
                dispatch(setEditRequestDescription(complianceRequestState.getComplianceRequestsByIdResponse.description))
                dispatch(setEditRequestNote(complianceRequestState.getComplianceRequestsByIdResponse.note))
                dispatch(setEditRequestProtocolId(complianceRequestState.getComplianceRequestsByIdResponse.requestProtocolId))
                dispatch(setEditRequestSender(complianceRequestState.getComplianceRequestsByIdResponse.sender))
                dispatch(setEditRequestType(complianceRequestState.getComplianceRequestsByIdResponse.requestType))
                dispatch(setEditRequestProduct(complianceRequestState.getComplianceRequestsByIdResponse.product))
            }

        }
    }, [complianceRequestState.getComplianceRequestByIdStatus])


    if (sessionState.selected.customerId) {
        return (
            <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Gestione Certificazione', 'Richieste Conformità', 'Nuova Richiesta']}
                headerLabel="Modifica Richiesta Conformità"
                rightBar={true}
                headerIcon={
                    <button style={{ paddingTop: '12px' }} onClick={() => navigate('/complianceRequests')}>
                        <ArrowLeftIcon />
                    </button>
                }
                headerChildren={
                    <div className="text-left flex flex-row justify-end" style={{ padding: '16px', justifyContent: 'end' }}>
                        <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                            onClick={() => {
                                dispatch(ComplianceRequetsValidation(complianceRequestState.complianceRequestsUpdate))
                            }}
                        />
                    </div>
                }

            >
                <>
                    {
                        (showSuccess) &&

                        <div className="gap-[8px] text-left bg-green-300" style={{ margin: '24px', borderRadius: '8px' }}>
                            <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                                Dati salvati correttamente.
                            </span>
                        </div>
                    }
                    <div style={{ display: 'flex', minHeight: window.innerHeight }}>
                        <div style={{ flex: '0.6' }}>
                            <EditFormRequest />
                        </div>

                        <div className="bg-brandPrimary-100" style={{ flex: '0.4' }}>
                            <RightBar >
                                <EditStateRequest />
                            </RightBar>
                        </div>
                    </div>
                </>

            </Layout>
        )
    }
    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Gestione Certificazione', 'Richieste Conformità', 'Nuova Richiesta']}
            headerLabel="Modifica Richiesta Conformità"
            rightBar={true}
            headerIcon={
                <button style={{ paddingTop: '12px' }} onClick={() => navigate('/complianceRequests')}>
                    <ArrowLeftIcon />
                </button>
            }
            

        >
            <Banner
                type="error"
                visible={!sessionState.selected.customerId}
                label={"Devi prima selezionare un cliente per accedere all'area"}
                closeAction={function (): void {
                    alert("Devi prima selezionare un cliente per accedere all'area")
                }}
            />
        </Layout>
    )
}