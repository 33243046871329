import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../utils";
import { ConversionPeriodCreationDTO, ConversionPeriodDTO, ConversionPeriodUpdateDTO, GetAllConversionPeriodsDTO, GetAllConversionPeriodsFiltersDTO } from "./dataService/dto";
import { NewConversionPeriodService } from "./dataService/service";

interface ConversionPeriodState {

    // REQUEST
    conversionPeriodCreationRequest: ConversionPeriodCreationDTO
    conversionPeriodUpdateRequest: ConversionPeriodUpdateDTO
    conversionPeriodIdToUpdate: string
    conversionPeriodCustomerId?: string

    // FILTERS
    getAllConversionPeriodsFilters: GetAllConversionPeriodsFiltersDTO

    // STATUS
    conversionPeriodCreationStatus: PromiseStatuses
    conversionPeriodUpdateStatus: PromiseStatuses
    conversionPeriodDeleteStatus: PromiseStatuses
    getAllConversionPeriodsStatus: PromiseStatuses
    getConversionPeriodByIdStatus: PromiseStatuses

    // RESPONSE
    getAllConversionPeriodsResponse?: GetAllConversionPeriodsDTO
    getConversionPeriodByIdResponse?: ConversionPeriodDTO
}

const initialState: ConversionPeriodState = {

    // REQUEST
    conversionPeriodCreationRequest: {
        requestProtocolId: ''
    },
    conversionPeriodUpdateRequest: {},
    conversionPeriodIdToUpdate: '',

    // FILTERS
    getAllConversionPeriodsFilters: {
        itemsPerPage: 0,
        page: 0
    },

    // STATUS
    conversionPeriodCreationStatus: 'idle',
    conversionPeriodUpdateStatus: 'idle',
    conversionPeriodDeleteStatus: 'idle',
    getAllConversionPeriodsStatus: 'idle',
    getConversionPeriodByIdStatus: 'idle'
}

export const ConversionPeriodCreation = createAsyncThunk(
    'conversionPeriod/Creation',
    async (body: ConversionPeriodCreationDTO, thunkApi): Promise<void> => {
        const conversionPeriodService = NewConversionPeriodService()
        return conversionPeriodService.CreateConversionPeriod(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const ConversionPeriodUpdate = createAsyncThunk(
    'conversionPeriod/Update',
    async (request: {body: ConversionPeriodUpdateDTO, id: string}, thunkApi): Promise<void> => {
        const conversionPeriodService = NewConversionPeriodService()
        return conversionPeriodService.UpdateConversionPeriod(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const ConversionPeriodDelete = createAsyncThunk(
    'conversionPeriod/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const conversionPeriodService = NewConversionPeriodService()
        return conversionPeriodService.DeleteConversionPeriod(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllConversionPeriods = createAsyncThunk(
    'conversionPeriod/GetAll',
    async (filters: GetAllConversionPeriodsFiltersDTO, thunkApi): Promise<GetAllConversionPeriodsDTO> => {
        const conversionPeriodService = NewConversionPeriodService()
        return conversionPeriodService.GetAllConversionPeriods(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetConversionPeriodById = createAsyncThunk(
    'conversionPeriod/GetById',
    async (id: string, thunkApi): Promise<ConversionPeriodDTO> => {
        const conversionPeriodService = NewConversionPeriodService()
        return conversionPeriodService.GetConversionPeriodById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

const ConversionPeriodSlice = createSlice({
    name: 'conversionPeriod/slice',
    initialState,
    reducers: {
        // REQUEST [CREATION]
        setConversionPeriodCreationRequestProtocolId: (state, action) => {
            state.conversionPeriodCreationRequest.requestProtocolId = action.payload
        },
        setConversionPeriodCreationInspectionVisitProtocolId: (state, action) => {
            state.conversionPeriodCreationRequest.inspectionVisitProtocolId = action.payload
        },
        setConversionPeriodCreationTrialReportProtocolId: (state, action) => {
            state.conversionPeriodCreationRequest.trialReportProtocolId = action.payload
        },
        setConversionPeriodCreationSamplingResult: (state, action) => {
            state.conversionPeriodCreationRequest.samplingResult = action.payload
        },
        setConversionPeriodCreationActiveIngredientsFound: (state, action) => {
            state.conversionPeriodCreationRequest.activeIngredientsFound = action.payload
        },
        setConversionPeriodCreationSendingDocumentationProtocolId: (state, action) => {
            state.conversionPeriodCreationRequest.sendingDocumentationProtocolId = action.payload
        },
        setConversionPeriodCreationRegionResponseProtocolId: (state, action) => {
            state.conversionPeriodCreationRequest.regionResponseProtocolId = action.payload
        },
        setConversionPeriodCreationNote: (state, action) => {
            state.conversionPeriodCreationRequest.note = action.payload
        },
        setConversionPeriodCreationCanceledRequestProtocolId: (state, action) => {
            state.conversionPeriodCreationRequest.canceledRequestProtocolId = action.payload
        },
        setConversionPeriodCreationCanceledRequestNote: (state, action) => {
            state.conversionPeriodCreationRequest.canceledRequestNote = action.payload
        },
        setConversionPeriodCreationArchivedRequestProtocolId: (state, action) => {
            state.conversionPeriodCreationRequest.archivedRequestProtocolId = action.payload
        },
        setConversionPeriodCreationArchivedRequestNote: (state, action) => {
            state.conversionPeriodCreationRequest.archivedRequestNote = action.payload
        },
        setConversionPeriodCreationLastNote: (state, action) => {
            state.conversionPeriodCreationRequest.lastNote = action.payload
        },
        resetConversionPeriodCreationRequest: (state) => {
            state.conversionPeriodCreationRequest = {
                requestProtocolId: ''
            }
        },

        // REQUEST [UPDATE]
        setConversionPeriodUpdateRequestProtocolId: (state, action) => {
            state.conversionPeriodUpdateRequest.requestProtocolId = action.payload
        },
        setConversionPeriodUpdateInspectionVisitProtocolId: (state, action) => {
            state.conversionPeriodUpdateRequest.inspectionVisitProtocolId = action.payload
        },
        setConversionPeriodUpdateTrialReportProtocolId: (state, action) => {
            state.conversionPeriodUpdateRequest.trialReportProtocolId = action.payload
        },
        setConversionPeriodUpdateSamplingResult: (state, action) => {
            state.conversionPeriodUpdateRequest.samplingResult = action.payload
        },
        setConversionPeriodUpdateActiveIngredientsFound: (state, action) => {
            state.conversionPeriodUpdateRequest.activeIngredientsFound = action.payload
        },
        setConversionPeriodUpdateSendingDocumentationProtocolId: (state, action) => {
            state.conversionPeriodUpdateRequest.sendingDocumentationProtocolId = action.payload
        },
        setConversionPeriodUpdateRegionResponseProtocolId: (state, action) => {
            state.conversionPeriodUpdateRequest.regionResponseProtocolId = action.payload
        },
        setConversionPeriodUpdateNote: (state, action) => {
            state.conversionPeriodUpdateRequest.note = action.payload
        },
        setConversionPeriodUpdateCanceledRequestProtocolId: (state, action) => {
            state.conversionPeriodUpdateRequest.canceledRequestProtocolId = action.payload
        },
        setConversionPeriodUpdateCanceledRequestNote: (state, action) => {
            state.conversionPeriodUpdateRequest.canceledRequestNote = action.payload
        },
        setConversionPeriodUpdateArchivedRequestProtocolId: (state, action) => {
            state.conversionPeriodUpdateRequest.archivedRequestProtocolId = action.payload
        },
        setConversionPeriodUpdateArchivedRequestNote: (state, action) => {
            state.conversionPeriodUpdateRequest.archivedRequestNote = action.payload
        },
        setConversionPeriodUpdateState: (state, action) => {
            state.conversionPeriodUpdateRequest.state = action.payload
        },
        setConversionPeriodUpdateLastNote: (state, action) => {
            state.conversionPeriodUpdateRequest.lastNote = action.payload
        },
        setConversionPeriodUpdateCustomerId: (state, action) => {
            state.conversionPeriodCustomerId = action.payload
        },
        setConversionPeriodIdToUpdate: (state, action) => {
            state.conversionPeriodIdToUpdate = action.payload
        },
        resetConversionPeriodUpdateRequest: (state) => {
            state.conversionPeriodUpdateRequest = {}
        },

        // FILTERS
        setGetAllConversionPeriodItemsPerPage: (state, action) => {
            state.getAllConversionPeriodsFilters.itemsPerPage = action.payload
        },
        setGetAllConversionPeriodPage: (state, action) => {
            state.getAllConversionPeriodsFilters.page = action.payload
        },
        setGetAllConversionPeriodOrder: (state, action) => {
            state.getAllConversionPeriodsFilters.order = action.payload
        },
        setGetAllConversionPeriodSort: (state, action) => {
            state.getAllConversionPeriodsFilters.sort = action.payload
        },
        setGetAllConversionPeriodCountryRegion: (state, action) => {
            state.getAllConversionPeriodsFilters.countryRegion = action.payload
        },
        setGetAllConversionPeriodHeadQuarter: (state, action) => {
            state.getAllConversionPeriodsFilters.headQuarter = action.payload
        },
        setGetAllConversionPeriodState: (state, action) => {
            state.getAllConversionPeriodsFilters.state = action.payload
        },
        setGetAllConversionPeriodYear: (state, action) => {
            state.getAllConversionPeriodsFilters.year = action.payload
        },
        resetGetAllConversionPeriodFilters: (state) => {
            state.getAllConversionPeriodsFilters = {
                itemsPerPage: 0,
                page: 0,
                countryRegion: '',
                state: undefined,
                year: undefined,
                headQuarter: undefined
            }
        },

        // STATUS
        resetConversionPeriodCreationStatus: (state) => {
            state.conversionPeriodCreationStatus = 'idle'
        },
        resetConversionPeriodUpdateStatus: (state) => {
            state.conversionPeriodUpdateStatus = 'idle'
        },
        resetConversionPeriodDeleteStatus: (state) => {
            state.conversionPeriodDeleteStatus = 'idle'
        },
        resetGetAllConversionPeriodsStatus: (state) => {
            state.getAllConversionPeriodsStatus = 'idle'
        },
        resetGetConversionPeriodByIdStatus: (state) => {
            state.getConversionPeriodByIdStatus = 'idle'
        }
    },
    extraReducers(builder) {
        builder

            // [CONVERSION PERIOD]
            // CREATION
            .addCase(ConversionPeriodCreation.pending, (state) => {
                state.conversionPeriodCreationStatus = 'loading'
            })
            .addCase(ConversionPeriodCreation.fulfilled, (state) => {
                state.conversionPeriodCreationStatus = 'successfully'
            })
            .addCase(ConversionPeriodCreation.rejected, (state) => {
                state.conversionPeriodCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(ConversionPeriodUpdate.pending, (state) => {
                state.conversionPeriodUpdateStatus = 'loading'
            })
            .addCase(ConversionPeriodUpdate.fulfilled, (state) => {
                state.conversionPeriodUpdateStatus = 'successfully'
            })
            .addCase(ConversionPeriodUpdate.rejected, (state) => {
                state.conversionPeriodUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(ConversionPeriodDelete.pending, (state) => {
                state.conversionPeriodDeleteStatus = 'loading'
            })
            .addCase(ConversionPeriodDelete.fulfilled, (state) => {
                state.conversionPeriodDeleteStatus = 'successfully'
            })
            .addCase(ConversionPeriodDelete.rejected, (state) => {
                state.conversionPeriodDeleteStatus = 'failed'
            })

            // GET ALL
            .addCase(GetAllConversionPeriods.pending, (state) => {
                state.getAllConversionPeriodsStatus = 'loading'
            })
            .addCase(GetAllConversionPeriods.fulfilled, (state, action) => {
                state.getAllConversionPeriodsStatus = 'successfully'
                state.getAllConversionPeriodsResponse = action.payload
            })
            .addCase(GetAllConversionPeriods.rejected, (state) => {
                state.getAllConversionPeriodsStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetConversionPeriodById.pending, (state) => {
                state.getConversionPeriodByIdStatus = 'loading'
            })
            .addCase(GetConversionPeriodById.fulfilled, (state, action) => {
                state.getConversionPeriodByIdStatus = 'successfully'
                state.getConversionPeriodByIdResponse = action.payload
            })
            .addCase(GetConversionPeriodById.rejected, (state) => {
                state.getConversionPeriodByIdStatus = 'failed'
            })
    },
})

export const {
    // REQUEST
    setConversionPeriodCreationActiveIngredientsFound,
    setConversionPeriodCreationArchivedRequestNote,
    setConversionPeriodCreationArchivedRequestProtocolId,
    setConversionPeriodCreationCanceledRequestNote,
    setConversionPeriodCreationCanceledRequestProtocolId,
    setConversionPeriodCreationInspectionVisitProtocolId,
    setConversionPeriodCreationLastNote,
    setConversionPeriodCreationNote,
    setConversionPeriodCreationRegionResponseProtocolId,
    setConversionPeriodCreationRequestProtocolId,
    setConversionPeriodCreationSamplingResult,
    setConversionPeriodCreationSendingDocumentationProtocolId,
    setConversionPeriodCreationTrialReportProtocolId,
    resetConversionPeriodCreationRequest,
    setConversionPeriodIdToUpdate,
    setConversionPeriodUpdateCustomerId,
    setConversionPeriodUpdateRequestProtocolId,
    setConversionPeriodUpdateActiveIngredientsFound,
    setConversionPeriodUpdateArchivedRequestNote,
    setConversionPeriodUpdateArchivedRequestProtocolId,
    setConversionPeriodUpdateCanceledRequestNote,
    setConversionPeriodUpdateCanceledRequestProtocolId,
    setConversionPeriodUpdateInspectionVisitProtocolId,
    setConversionPeriodUpdateLastNote,
    setConversionPeriodUpdateNote,
    setConversionPeriodUpdateRegionResponseProtocolId,
    setConversionPeriodUpdateSamplingResult,
    setConversionPeriodUpdateSendingDocumentationProtocolId,
    setConversionPeriodUpdateState,
    setConversionPeriodUpdateTrialReportProtocolId,
    resetConversionPeriodUpdateRequest,
    
    // FILTERS
    setGetAllConversionPeriodCountryRegion,
    setGetAllConversionPeriodHeadQuarter,
    setGetAllConversionPeriodItemsPerPage,
    setGetAllConversionPeriodOrder,
    setGetAllConversionPeriodPage,
    setGetAllConversionPeriodSort,
    setGetAllConversionPeriodState,
    setGetAllConversionPeriodYear,
    resetGetAllConversionPeriodFilters,

    // STATUS
    resetConversionPeriodCreationStatus,
    resetConversionPeriodDeleteStatus,
    resetConversionPeriodUpdateStatus,
    resetGetAllConversionPeriodsStatus,
    resetGetConversionPeriodByIdStatus
} = ConversionPeriodSlice.actions

export default ConversionPeriodSlice.reducer