import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../utils";
import { AppliedMeasureCreationDTO, AppliedMeasureDTO, AppliedMeasureUpdateDTO, GetAllAppliedMeasureDTO, GetAllAppliedMeasureFiltersDTO } from "./components/measureBox/dataService/dto";
import { GetAllNewMeasureDTO, GetAllNewMeasuresFiltersDTO, NewMeasureCreationDTO, NewMeasureDTO, NewMeasureErrorDTO, NewMeasureUpdateDTO } from "./dataService/dto";
import { NewNewMeasureService } from "./dataService/service";
import { NewAppliedMeasureService } from "./components/measureBox/dataService/service";

export interface NewMeasureTempDTO {
    id: string
    protocolInfo: string
    ncCode: {
        id?: string
        code?: string
    }
}

export interface OldMeasureTempDTO {
    id: string
    protocolInfo: string
    code?: string
}

interface NewMeasureState {
    savedNewMeasures: NewMeasureTempDTO[]
    savedOldMeasures: OldMeasureTempDTO[]

    // REQUEST [APPLIED MEASURE]
    appliedMeasureCreationRequest: AppliedMeasureCreationDTO
    appliedMeasureUpdateRequest: AppliedMeasureUpdateDTO
    appliedMeasureIdToUpdate: string

    // REQUEST [NEW MEASURE]
    newMeasureCreationRequest: NewMeasureCreationDTO
    newMeasureUpdateRequest: NewMeasureUpdateDTO
    newMeasureIdToUpdate: string

    // ERRORS
    newMeasureError: NewMeasureErrorDTO

    // FILTERS
    getAllAppliedMeasureFilters: GetAllAppliedMeasureFiltersDTO
    getAllNewMeasureFilters: GetAllNewMeasuresFiltersDTO

    // STATUS
    appliedMeasureCreationStatus: PromiseStatuses
    appliedMeasureUpdateStatus: PromiseStatuses
    appliedMeasureDeleteStatus: PromiseStatuses
    getAllAppliedMeasureStatus: PromiseStatuses
    getAppliedMeasureByIdStatus: PromiseStatuses

    newMeasureValidationStatus: PromiseStatuses
    newMeasureCreationStatus: PromiseStatuses
    newMeasureUpdateStatus: PromiseStatuses
    newMeasureDeleteStatus: PromiseStatuses
    getAllNewMeasureStatus: PromiseStatuses
    getNewMeasureByIdStatus: PromiseStatuses

    // RESPONSE
    getAllAppliedMeasureResponse?: GetAllAppliedMeasureDTO
    getAppliedMeasureByIdResponse?: AppliedMeasureDTO

    getAllNewMeasureResponse?: GetAllNewMeasureDTO
    getNewMeasureByIdResponse?: NewMeasureDTO
    newMeasureCreationResponse?: string
}

const initialState: NewMeasureState = {
    savedNewMeasures: [],
    savedOldMeasures: [],

    appliedMeasureCreationRequest: {
        isAdditional: false
    },
    appliedMeasureUpdateRequest: {},
    appliedMeasureIdToUpdate: '',

    newMeasureCreationRequest: {
        customerId: '',
        officerId: ''
    },
    newMeasureUpdateRequest: {},
    newMeasureIdToUpdate: '',

    newMeasureError: {
        isCodexRealesed: false,
        nonComplianceCodeVersion: false,
        m024Id: false,
        dateOrProtocol: false
    },

    getAllAppliedMeasureFilters: {
        itemsPerPage: 0,
        page: 0
    },
    getAllNewMeasureFilters: {
        itemsPerPage: 0,
        page: 0
    },

    appliedMeasureCreationStatus: 'idle',
    appliedMeasureUpdateStatus: 'idle',
    appliedMeasureDeleteStatus: 'idle',
    getAllAppliedMeasureStatus: 'idle',
    getAppliedMeasureByIdStatus: 'idle',

    newMeasureValidationStatus: 'idle',
    newMeasureCreationStatus: 'idle',
    newMeasureUpdateStatus: 'idle',
    newMeasureDeleteStatus: 'idle',
    getAllNewMeasureStatus: 'idle',
    getNewMeasureByIdStatus: 'idle'
}

export const NewMeasureValidation = createAsyncThunk(
    'newMeasure/Validation',
    async (request: NewMeasureCreationDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetNewMeasureErrors())

        if (request.isCodexRealesed === undefined) {
            thunkApi.dispatch(setValidateIsCodexRealesed(true))
            isValid = false
        }

        if (request.nonComplianceCodeVersion === undefined) {
            thunkApi.dispatch(setValidateNonComplianceCodeVersion(true))
            isValid = false
        }

        if (request.m024IdTest === undefined) {
            thunkApi.dispatch(setValidateM024Id(true))
            isValid = false
        }

        if (request.measureDate === undefined && request.departureProtocolId === undefined) {
            thunkApi.dispatch(setValidateDateOrProtocol(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()

    }
)

export const NewMeasureCreation = createAsyncThunk(
    'newMeasure/Creation',
    async (body: NewMeasureCreationDTO, thunkApi): Promise<string> => {
        const newMeasureService = NewNewMeasureService()

        return newMeasureService.CreateNewMeasure(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const NewMeasureUpdate = createAsyncThunk(
    'newMeasure/Update',
    async (request: { body: NewMeasureUpdateDTO, id: string }, thunkApi): Promise<void> => {
        const newMeasureService = NewNewMeasureService()

        return newMeasureService.UpdateNewMeasure(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const NewMeasureDelete = createAsyncThunk(
    'newMeasure/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const newMeasureService = NewNewMeasureService()

        return newMeasureService.DeleteNewMeasure(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllNewMeasure = createAsyncThunk(
    'newMeasure/GetAll',
    async (filters: GetAllNewMeasuresFiltersDTO, thunkApi): Promise<GetAllNewMeasureDTO> => {
        const newMeasureService = NewNewMeasureService()

        return newMeasureService.GetAllNewMeasures(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetNewMeasureById = createAsyncThunk(
    'newMeasure/GetById',
    async (id: string, thunkApi): Promise<NewMeasureDTO> => {
        const newMeasureService = NewNewMeasureService()

        return newMeasureService.GetNewMeasureById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const AppliedMeasureCreation = createAsyncThunk(
    'appliedMeasure/Creation',
    async (body: AppliedMeasureCreationDTO, thunkApi): Promise<void> => {
        const appliedMeasureService = NewAppliedMeasureService()

        return appliedMeasureService.CreateAppliedMeasure(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const AppliedMeasureUpdate = createAsyncThunk(
    'appliedMeasure/Update',
    async (request: { body: AppliedMeasureUpdateDTO, id: string }, thunkApi): Promise<void> => {
        const appliedMeasureService = NewAppliedMeasureService()

        return appliedMeasureService.UpdateAppliedMeasure(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const AppliedMeasureDelete = createAsyncThunk(
    'appliedMeasure/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const appliedMeasureService = NewAppliedMeasureService()

        return appliedMeasureService.DeleteAppliedMeasure(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllAppliedMeasure = createAsyncThunk(
    'appliedMeasure/GetAll',
    async (filters: GetAllAppliedMeasureFiltersDTO, thunkApi): Promise<GetAllAppliedMeasureDTO> => {
        const appliedMeasureService = NewAppliedMeasureService()

        return appliedMeasureService.GetAllAppliedMeasure(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAppliedMeasureById = createAsyncThunk(
    'appliedMeasure/GetById',
    async (id: string, thunkApi): Promise<AppliedMeasureDTO> => {
        const appliedMeasureService = NewAppliedMeasureService()

        return appliedMeasureService.GetAppliedMeasureById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

const NewMeasureSlice = createSlice({
    name: 'newMeasure/slice',
    initialState,
    reducers: {
        setSavedNewMeasures: (state, action) => {
            state.savedNewMeasures = action.payload
        },
        setSavedOldMeasures: (state, action) => {
            state.savedOldMeasures = action.payload
        },

        // REQUEST (CREATION) [APPLIED MEASURE]
        setAppliedMeasureCreationCode: (state, action) => {
            state.appliedMeasureCreationRequest.code = action.payload
        },
        setAppliedMeasureCreationDetail: (state, action) => {
            state.appliedMeasureCreationRequest.detail = action.payload
        },
        setAppliedMeasureCreationExpirationDate: (state, action) => {
            state.appliedMeasureCreationRequest.expirationDate = action.payload
        },
        setAppliedMeasureCreationEndingDate: (state, action) => {
            state.appliedMeasureCreationRequest.endingDate = action.payload
        },
        setAppliedMeasureCreationIsAdditional: (state, action) => {
            state.appliedMeasureCreationRequest.isAdditional = action.payload
        },
        setAppliedMeasureCreationAppliedMeasureRe16Id: (state, action) => {
            state.appliedMeasureCreationRequest.appliedMeasureRe16Id = action.payload
            state.appliedMeasureCreationRequest.additionalMeasureRe16Id = undefined
        },
        setAppliedMeasureCreationAdditionMeasureRe16Id: (state, action) => {
            state.appliedMeasureCreationRequest.appliedMeasureRe16Id = undefined
            state.appliedMeasureCreationRequest.additionalMeasureRe16Id = action.payload
        },
        resetAppliedMeasureCreationRequest: (state) => {
            state.appliedMeasureCreationRequest = {
                isAdditional: false
            }
        },

        // REQUEST (UPDATE) [APPLIED MEASURE]
        setAppliedMeasureIdToUpdate: (state, action) => {
            state.appliedMeasureIdToUpdate = action.payload
        },
        setAppliedMeasureUpdateDetail: (state, action) => {
            state.appliedMeasureUpdateRequest.detail = action.payload
        },
        setAppliedMeasureUpdateExpirationDate: (state, action) => {
            state.appliedMeasureUpdateRequest.expirationDate = action.payload
        },
        setAppliedMeasureUpdateEndingDate: (state, action) => {
            state.appliedMeasureUpdateRequest.endingDate = action.payload
        },
        resetAppliedMeasureUpdateRequest: (state) => {
            state.appliedMeasureUpdateRequest = {}
        },

        // REQUEST (CREATION) [NEW MEASURE]
        setNewMeasureCreationIsCodexRealesed: (state, action) => {
            state.newMeasureCreationRequest.isCodexRealesed = action.payload
        },
        setNewMeasureCreationNonComplianceCodeVersion: (state, action) => {
            state.newMeasureCreationRequest.nonComplianceCodeVersion = action.payload
        },
        setMewMeasureCreationM024Id: (state, action) => {
            state.newMeasureCreationRequest.m024IdTest = action.payload
        },
        setNewMeasureCreationMeasureDate: (state, action) => {
            state.newMeasureCreationRequest.measureDate = action.payload
        },
        setNewMeasureCreationDepartureProtocolId: (state, action) => {
            state.newMeasureCreationRequest.departureProtocolId = action.payload
        },
        setNewMeasureCreationCustomerId: (state, action) => {
            state.newMeasureCreationRequest.customerId = action.payload
        },
        setNewMeasureCreationOfficerId: (state, action) => {
            state.newMeasureCreationRequest.officerId = action.payload
        },
        resetNewMeasureCreationRequest: (state) => {
            state.newMeasureCreationRequest = {
                customerId: '',
                officerId: ''
            }
        },

        // REQUEST (UPDATE) [NEW MEASURE]
        setNewMeasureIdtoUpdate: (state, action) => {
            state.newMeasureIdToUpdate = action.payload
        },
        setNewMeasureUpdateOldNonComplianceCode: (state, action) => {
            state.newMeasureUpdateRequest.oldNonComplianceCode = action.payload
        },
        setNewMeasureUpdateNewNonComplianceCodeId: (state, action) => {
            state.newMeasureUpdateRequest.newNonComplianceCodeId = action.payload
        },
        setNewMeasureUpdateNonComplianceNote: (state, action) => {
            state.newMeasureUpdateRequest.nonComplianceNote = action.payload
        },
        setNewMeasureUpdateActivities: (state, action) => {
            state.newMeasureUpdateRequest.activities = action.payload
        },
        setNewMeasureUpdateProductsCategories: (state, action) => {
            state.newMeasureUpdateRequest.productsCategories = action.payload
        },
        setNewMeasureUpdateMeasureCorrectionversion: (state, action) => {
            state.newMeasureUpdateRequest.measureCorrectionVersion = action.payload
        },
        setNewMeasureUpdateMeasureCorrectionId: (state, action) => {
            state.newMeasureUpdateRequest.measureCorrectionId = action.payload
        },
        setNewMeasureUpdateOldNonComplianceCodeCorrection: (state, action) => {
            state.newMeasureUpdateRequest.oldNonComplianceCodeCorrection = action.payload
        },
        setNewMeasureUpdateNewNonComplianceCodeCorrectionId: (state, action) => {
            state.newMeasureUpdateRequest.newNonComplianceCodeCorrectionId = action.payload
        },
        setNewMeasureUpdateNoCorrectionNote: (state, action) => {
            state.newMeasureUpdateRequest.noCorrectionNote = action.payload
        },
        setNewMeasureUpdateNonComplianceLevel: (state, action) => {
            state.newMeasureUpdateRequest.nonComplianceLevel = action.payload
        },
        setNewMeasureUpdateAdditionalNote: (state, action) => {
            state.newMeasureUpdateRequest.additionalNote = action.payload
        },
        setNewMeasureUpdateControlType: (state, action) => {
            state.newMeasureUpdateRequest.controlType = action.payload
        },
        setNewMeasureUpdateNonComplianceFindingDate: (state, action) => {
            state.newMeasureUpdateRequest.nonComplianceFindingDate = action.payload
        },
        setNewMeasureUpdateNonComplianceFindingNote: (state, action) => {
            state.newMeasureUpdateRequest.nonComplianceFindingNote = action.payload
        },
        setNewMeasureUpdateDocumentEntryProtocolId: (state, action) => {
            state.newMeasureUpdateRequest.documentEntryProtocolId = action.payload
        },
        setNewMeasureUpdateDocumentDepartureProtocolId: (state, action) => {
            state.newMeasureUpdateRequest.documentDepartureProtocolId = action.payload
        },
        setNewMeasureUpdateDocumentIntegrationRequestId: (state, action) => {
            state.newMeasureUpdateRequest.documentIntegrationRequestId = action.payload
        },
        setNewMeasureUpdateInspectionVisitId: (state, action) => {
            state.newMeasureUpdateRequest.inspectionVisitIdTest = action.payload
        },
        setNewMeasureUpdateFindingFrom: (state, action) => {
            state.newMeasureUpdateRequest.findingFrom = action.payload
        },
        setNewMeasureUpdateInspectorMeasureConfirmed: (state, action) => {
            state.newMeasureUpdateRequest.inspectorMeasureConfirmed = action.payload
        },
        setNewMeasureUpdateLaboratoryTestReportProtocolId: (state, action) => {
            state.newMeasureUpdateRequest.laboratoryTestReportProtocolId = action.payload
        },
        setNewMeasureUpdatePreventiveSuppressionM024Id: (state, action) => {
            state.newMeasureUpdateRequest.preventiveSuppressionM024IdTest = action.payload
        },
        setNewMeasureUpdateM018Id: (state, action) => {
            state.newMeasureUpdateRequest.m018IdTest = action.payload
        },
        setNewMeasureUpdateOfficerMeasureSignatureId: (state, action) => {
            state.newMeasureUpdateRequest.officerMeasureSignatureId = action.payload
        },
        setNewMeasureUpdateDepartureChannel: (state, action) => {
            state.newMeasureUpdateRequest.departureChannel = action.payload
        },
        setNewMeasureUpdateP0035Id: (state, action) => {
            state.newMeasureUpdateRequest.p0035IdTest = action.payload
        },
        setNewMeasureUpdateMeasureRecipientsIds: (state, action) => {
            state.newMeasureUpdateRequest.measureRecipientsIds = action.payload
        },
        setNewMeasureUpdateStatus: (state, action) => {
            state.newMeasureUpdateRequest.status = action.payload
        },
        setNewMeasureUpdateStatusProtocolId: (state, action) => {
            state.newMeasureUpdateRequest.statusProtocolId = action.payload
        },
        setNewMeasureUpdateActionPlaneProtocolId: (state, action) => {
            state.newMeasureUpdateRequest.actionPlaneProtocolId = action.payload
        },
        setNewMeasureUpdateClosingNote: (state, action) => {
            state.newMeasureUpdateRequest.closingNote = action.payload
        },
        setNewMeasureUpdatePresentationDate: (state, action) => {
            state.newMeasureUpdateRequest.presentationDate = action.payload
        },
        setNewMeasureUpdatePresentationProtocolId: (state, action) => {
            state.newMeasureUpdateRequest.presentationProtocolId = action.payload
        },
        setNewMeasureUpdateRecourseDetail: (state, action) => {
            state.newMeasureUpdateRequest.recourseDetail = action.payload
        },
        setNewMeasureUpdateAppealReportDate: (state, action) => {
            state.newMeasureUpdateRequest.appealReportDate = action.payload
        },
        setNewMeasureUpdateAppealReportProtocolId: (state, action) => {
            state.newMeasureUpdateRequest.appealReportProtocolId = action.payload
        },
        setNewMeasureUpdaterecourseResult: (state, action) => {
            state.newMeasureUpdateRequest.recourseResult = action.payload
        },
        setNewMeasureUpdateRecourseNote: (state, action) => {
            state.newMeasureUpdateRequest.recourseNote = action.payload
        },
        setNewMeasureUpdateRequest: (state, action) => {
            state.newMeasureUpdateRequest = action.payload
        },
        resetNewMeasureUpdateRequest: (state) => {
            state.newMeasureUpdateRequest = {}
        },

        // ERRORS
        setValidateIsCodexRealesed: (state, action) => {
            state.newMeasureError.isCodexRealesed = action.payload
        },
        setValidateNonComplianceCodeVersion: (state, action) => {
            state.newMeasureError.nonComplianceCodeVersion = action.payload
        },
        setValidateM024Id: (state, action) => {
            state.newMeasureError.m024Id = action.payload
        },
        setValidateDateOrProtocol: (state, action) => {
            state.newMeasureError.dateOrProtocol = action.payload
        },
        resetNewMeasureErrors: (state) => {
            state.newMeasureError = {
                isCodexRealesed: false,
                nonComplianceCodeVersion: false,
                m024Id: false,
                dateOrProtocol: false
            }
        },

        // FILTERS
        setGetAllAppliedMeasureItemsPerPage: (state, action) => {
            state.getAllAppliedMeasureFilters.itemsPerPage = action.payload
        },
        setGetAllAppliedMeasurePage: (state, action) => {
            state.getAllAppliedMeasureFilters.page = action.payload
        },
        setGetAllAppliedMeasureOrder: (state, action) => {
            state.getAllAppliedMeasureFilters.order = action.payload
        },
        setGetAllAppliedMeasureSort: (state, action) => {
            state.getAllAppliedMeasureFilters.sort = action.payload
        },
        setGetAllAppliedMeasureNewMeasureId: (state, action) => {
            state.getAllAppliedMeasureFilters.newMeasureId = action.payload
        },
        resetGetAllAppliedMeasureFilters: (state) => {
            state.getAllAppliedMeasureFilters = {
                itemsPerPage: 0,
                page: 0
            }
        },

        setGetAllNewMeasureItemsPerPage: (state, action) => {
            state.getAllNewMeasureFilters.itemsPerPage = action.payload
        },
        setGetAllNewMeasurePage: (state, action) => {
            state.getAllNewMeasureFilters.page = action.payload
        },
        setGetAllNewMeasureOrder: (state, action) => {
            state.getAllNewMeasureFilters.order = action.payload
        },
        setGetAllNewMeasureSort: (state, action) => {
            state.getAllNewMeasureFilters.sort = action.payload
        },
        setGetAllNewMeasureCustomerId: (state, action) => {
            state.getAllNewMeasureFilters.customerId = action.payload
        },
        resetgetAllNewMeasureFilters: (state) => {
            state.getAllNewMeasureFilters = {
                itemsPerPage: 0,
                page: 0
            }
        },

        // STATUS
        resetAppliedMeasureCreationStatus: (state) => {
            state.appliedMeasureCreationStatus = 'idle'
        },
        resetAppliedMeasureUpdateStatus: (state) => {
            state.appliedMeasureUpdateStatus = 'idle'
        },
        resetAppliedMeasureDeleteStatus: (state) => {
            state.appliedMeasureDeleteStatus = 'idle'
        },
        resetGetAllAppliedMeasureStatus: (state) => {
            state.getAllAppliedMeasureStatus = 'idle'
        },
        resetGetAppliedMeasureByIdStatus: (state) => {
            state.getAppliedMeasureByIdStatus = 'idle'
        },

        resetNewMeasureValidationStatus: (state) => {
            state.newMeasureValidationStatus = 'idle'
        },
        resetNewMeasureCreationStatus: (state) => {
            state.newMeasureCreationStatus = 'idle'
        },
        resetNewMeasureUpdateStatus: (state) => {
            state.newMeasureUpdateStatus = 'idle'
        },
        resetNewMeasureDeleteStatus: (state) => {
            state.newMeasureDeleteStatus = 'idle'
        },
        resetGetAllNewMeasureStatus: (state) => {
            state.getAllNewMeasureStatus = 'idle'
        },
        resetGetNewMeasureByIdStatus: (state) => {
            state.getNewMeasureByIdStatus = 'idle'
        }
    },
    extraReducers(builder) {
        builder

            // [APPLIED MEASURE]
            // CREATION
            .addCase(AppliedMeasureCreation.pending, (state) => {
                state.appliedMeasureCreationStatus = 'loading'
            })
            .addCase(AppliedMeasureCreation.fulfilled, (state) => {
                state.appliedMeasureCreationStatus = 'successfully'
            })
            .addCase(AppliedMeasureCreation.rejected, (state) => {
                state.appliedMeasureCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(AppliedMeasureUpdate.pending, (state) => {
                state.appliedMeasureUpdateStatus = 'loading'
            })
            .addCase(AppliedMeasureUpdate.fulfilled, (state) => {
                state.appliedMeasureUpdateStatus = 'successfully'
            })
            .addCase(AppliedMeasureUpdate.rejected, (state) => {
                state.appliedMeasureUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(AppliedMeasureDelete.pending, (state) => {
                state.appliedMeasureDeleteStatus = 'loading'
            })
            .addCase(AppliedMeasureDelete.fulfilled, (state) => {
                state.appliedMeasureDeleteStatus = 'successfully'
            })
            .addCase(AppliedMeasureDelete.rejected, (state) => {
                state.appliedMeasureDeleteStatus = 'failed'
            })

            // GET ALL
            .addCase(GetAllAppliedMeasure.pending, (state) => {
                state.getAllAppliedMeasureStatus = 'loading'
            })
            .addCase(GetAllAppliedMeasure.fulfilled, (state, action) => {
                state.getAllAppliedMeasureStatus = 'successfully'
                state.getAllAppliedMeasureResponse = action.payload
            })
            .addCase(GetAllAppliedMeasure.rejected, (state) => {
                state.getAllAppliedMeasureStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetAppliedMeasureById.pending, (state) => {
                state.getAllAppliedMeasureStatus = 'loading'
            })
            .addCase(GetAppliedMeasureById.fulfilled, (state, action) => {
                state.getAllAppliedMeasureStatus = 'successfully'
                state.getAppliedMeasureByIdResponse = action.payload
            })
            .addCase(GetAppliedMeasureById.rejected, (state) => {
                state.getAllAppliedMeasureStatus = 'failed'
            })

            // [NEW MEASURE]
            // CREATION
            .addCase(NewMeasureCreation.pending, (state) => {
                state.newMeasureCreationStatus = 'loading'
            })
            .addCase(NewMeasureCreation.fulfilled, (state, action) => {
                state.newMeasureCreationStatus = 'successfully'
                state.newMeasureCreationResponse = action.payload
            })
            .addCase(NewMeasureCreation.rejected, (state) => {
                state.newMeasureCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(NewMeasureUpdate.pending, (state) => {
                state.newMeasureUpdateStatus = 'loading'
            })
            .addCase(NewMeasureUpdate.fulfilled, (state) => {
                state.newMeasureUpdateStatus = 'successfully'
            })
            .addCase(NewMeasureUpdate.rejected, (state) => {
                state.newMeasureUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(NewMeasureDelete.pending, (state) => {
                state.newMeasureDeleteStatus = 'loading'
            })
            .addCase(NewMeasureDelete.fulfilled, (state) => {
                state.newMeasureDeleteStatus = 'successfully'
            })
            .addCase(NewMeasureDelete.rejected, (state) => {
                state.newMeasureDeleteStatus = 'failed'
            })

            // GET ALL
            .addCase(GetAllNewMeasure.pending, (state) => {
                state.getAllNewMeasureStatus = 'loading'
            })
            .addCase(GetAllNewMeasure.fulfilled, (state, action) => {
                state.getAllNewMeasureStatus = 'successfully'
                state.getAllNewMeasureResponse = action.payload
            })
            .addCase(GetAllNewMeasure.rejected, (state) => {
                state.getAllNewMeasureStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetNewMeasureById.pending, (state) => {
                state.getNewMeasureByIdStatus = 'loading'
            })
            .addCase(GetNewMeasureById.fulfilled, (state, action) => {
                state.getNewMeasureByIdStatus = 'successfully'
                state.getNewMeasureByIdResponse = action.payload
            })
            .addCase(GetNewMeasureById.rejected, (state) => {
                state.getNewMeasureByIdStatus = 'failed'
            })

            // VALIDATION
            .addCase(NewMeasureValidation.pending, (state) => {
                state.newMeasureValidationStatus = 'loading'
            })
            .addCase(NewMeasureValidation.fulfilled, (state) => {
                state.newMeasureValidationStatus = 'successfully'
            })
            .addCase(NewMeasureValidation.rejected, (state) => {
                state.newMeasureValidationStatus = 'failed'
            })
    },
})

export const {
    setSavedNewMeasures,
    setSavedOldMeasures,

    // REQUEST [APPLIED MEASURE]
    setAppliedMeasureCreationAdditionMeasureRe16Id,
    setAppliedMeasureCreationAppliedMeasureRe16Id,
    setAppliedMeasureCreationCode,
    setAppliedMeasureCreationDetail,
    setAppliedMeasureCreationEndingDate,
    setAppliedMeasureCreationExpirationDate,
    setAppliedMeasureCreationIsAdditional,
    setAppliedMeasureIdToUpdate,
    setAppliedMeasureUpdateDetail,
    setAppliedMeasureUpdateEndingDate,
    setAppliedMeasureUpdateExpirationDate,
    resetAppliedMeasureCreationRequest,
    resetAppliedMeasureUpdateRequest,

    // REQUEST [NEW MEASURE]
    setMewMeasureCreationM024Id,
    setNewMeasureCreationCustomerId,
    setNewMeasureCreationDepartureProtocolId,
    setNewMeasureCreationIsCodexRealesed,
    setNewMeasureCreationMeasureDate,
    setNewMeasureCreationNonComplianceCodeVersion,
    setNewMeasureCreationOfficerId,
    setNewMeasureUpdateActionPlaneProtocolId,
    setNewMeasureUpdateActivities,
    setNewMeasureUpdateAdditionalNote,
    setNewMeasureUpdateAppealReportDate,
    setNewMeasureUpdateAppealReportProtocolId,
    setNewMeasureUpdateClosingNote,
    setNewMeasureUpdateControlType,
    setNewMeasureUpdateDepartureChannel,
    setNewMeasureUpdateDocumentDepartureProtocolId,
    setNewMeasureUpdateDocumentEntryProtocolId,
    setNewMeasureUpdateDocumentIntegrationRequestId,
    setNewMeasureUpdateFindingFrom,
    setNewMeasureUpdateInspectionVisitId,
    setNewMeasureUpdateInspectorMeasureConfirmed,
    setNewMeasureUpdateLaboratoryTestReportProtocolId,
    setNewMeasureUpdateM018Id,
    setNewMeasureUpdateMeasureCorrectionId,
    setNewMeasureUpdateMeasureCorrectionversion,
    setNewMeasureUpdateMeasureRecipientsIds,
    setNewMeasureUpdateNewNonComplianceCodeCorrectionId,
    setNewMeasureUpdateNewNonComplianceCodeId,
    setNewMeasureUpdateNoCorrectionNote,
    setNewMeasureUpdateNonComplianceFindingDate,
    setNewMeasureUpdateNonComplianceFindingNote,
    setNewMeasureUpdateNonComplianceLevel,
    setNewMeasureUpdateNonComplianceNote,
    setNewMeasureUpdateOfficerMeasureSignatureId,
    setNewMeasureUpdateOldNonComplianceCode,
    setNewMeasureUpdateOldNonComplianceCodeCorrection,
    setNewMeasureUpdateP0035Id,
    setNewMeasureUpdatePresentationDate,
    setNewMeasureUpdatePresentationProtocolId,
    setNewMeasureUpdatePreventiveSuppressionM024Id,
    setNewMeasureUpdateProductsCategories,
    setNewMeasureUpdateRecourseDetail,
    setNewMeasureUpdateRecourseNote,
    setNewMeasureUpdateStatus,
    setNewMeasureUpdateStatusProtocolId,
    setNewMeasureUpdaterecourseResult,
    setNewMeasureUpdateRequest,
    resetNewMeasureCreationRequest,
    resetNewMeasureUpdateRequest,

    // ERRORS
    setValidateDateOrProtocol,
    setValidateIsCodexRealesed,
    setValidateM024Id,
    setValidateNonComplianceCodeVersion,
    resetNewMeasureErrors,

    // FILTERS
    setGetAllAppliedMeasureItemsPerPage,
    setGetAllAppliedMeasureOrder,
    setGetAllAppliedMeasurePage,
    setGetAllAppliedMeasureSort,
    setGetAllAppliedMeasureNewMeasureId,
    setGetAllNewMeasureCustomerId,
    setGetAllNewMeasureItemsPerPage,
    setGetAllNewMeasureOrder,
    setGetAllNewMeasurePage,
    setGetAllNewMeasureSort,
    setNewMeasureIdtoUpdate,
    resetGetAllAppliedMeasureFilters,
    resetgetAllNewMeasureFilters,

    // STATUS
    resetAppliedMeasureCreationStatus,
    resetAppliedMeasureDeleteStatus,
    resetAppliedMeasureUpdateStatus,
    resetGetAllAppliedMeasureStatus,
    resetGetAllNewMeasureStatus,
    resetGetAppliedMeasureByIdStatus,
    resetNewMeasureValidationStatus,
    resetGetNewMeasureByIdStatus,
    resetNewMeasureCreationStatus,
    resetNewMeasureDeleteStatus,
    resetNewMeasureUpdateStatus
} = NewMeasureSlice.actions

export default NewMeasureSlice.reducer