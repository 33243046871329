import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { PromiseStatuses } from "../../lib/utils"
import { format } from "date-fns";
import { CreateTechnicianDTO, GetBusinessTechnicianDTO, TechnicianErrorsDTO, addTechinicianUserDTO, findAllBusinessTechnicianDTO } from "./dto";
import { NewBusinessTechnicianService } from "./service";
import { act } from "react";

interface BusinessTechnicianState {
    createBusinessTechnician: CreateTechnicianDTO
    technicianErrors: TechnicianErrorsDTO
    technicianCreationStatus: PromiseStatuses
    businessTechinicianResponse?: findAllBusinessTechnicianDTO
    businessTechById?: GetBusinessTechnicianDTO
    businessTechByIdStatus: PromiseStatuses
    businessTechinicianResponseStatus: PromiseStatuses
    businessTechinicianEditStatus: PromiseStatuses
    businessTechnicianValidationStatus: PromiseStatuses
    userBusinessTechnicianLink: addTechinicianUserDTO
    idBusinessTechUser: string | undefined
    selectedId: string
}

const initialState: BusinessTechnicianState = {

    technicianCreationStatus: 'idle',
    businessTechinicianResponseStatus: 'idle',
    businessTechByIdStatus: 'idle',
    businessTechinicianEditStatus: 'idle',
    businessTechnicianValidationStatus: 'idle',
    idBusinessTechUser: '',
    selectedId: '',
    createBusinessTechnician: {
        companyName: '',
        vat: '',
        fiscalCode: '',
        email: '',
        address: '',
        countryRegion: '',
        province: '',
        city: '',
        zipCode: '',
        activationDate: new Date(),
        pec: '',
        phone: '', 
        mobilePhone: ''
    },
    technicianErrors: {
        phone: false,
        mobilephone: false,
        zipCode: false,
        fiscalCode: false,
        address: false,
        province: false,
        city: false,
        vat: false,
        countryRegion: false
    },
    userBusinessTechnicianLink: {
        id: '',
        techId: ''
    }

}

export const BusinessTechnicianValidation = createAsyncThunk(
    'BusinessTechinician/Validation',
    async (request: CreateTechnicianDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetTechnicianErrors())

        if(request.phone === '' || 
            request.phone.length < 7 ||
            request.phone.length > 15) {
            thunkApi.dispatch(setValidateTechnicianPhone(true))
            isValid = false
        }

        if(request.mobilePhone === '' ||
            request.mobilePhone.length < 9 ||
            request.mobilePhone.length > 13) {
            thunkApi.dispatch(setValidateTechnicianMobilephone(true))
            isValid = false
        }

        if(request.zipCode === '' || 
            request.zipCode.length !== 5) {
            thunkApi.dispatch(setValidateTechnicianZipCode(true))
            isValid = false
        }

        if(request.fiscalCode === '' || 
            request.fiscalCode.length !== 16) {
            thunkApi.dispatch(setValidateTechnicianFiscalCode(true))
            isValid = false
        }

        if(request.vat === '' || 
            request.vat.length < 11 ||
            request.vat.length > 16) {
            thunkApi.dispatch(setValidateTechnicianVat(true))
            isValid = false
        }

        if(request.address === '') {
            thunkApi.dispatch(setValidateTechnicianAddress(true))
            isValid = false
        }

        if(request.province === '') {
            thunkApi.dispatch(setValidatteTechnicianProvince(true))
            isValid = false
        }

        if(request.city === '') {
            thunkApi.dispatch(setValidateTechnicianCity(true))
            isValid = false
        }

        if(request.countryRegion === '') {
            thunkApi.dispatch(setvalidateTechnicianCountryRegion(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)



export const CreateTechnicianCreation = createAsyncThunk(
    'BusinessTechinician/CreateTechnicianCreation',
    async (request: CreateTechnicianDTO, thunkApi): Promise<void> => {
        const businessTechinicianService = NewBusinessTechnicianService()

        return businessTechinicianService.CreateTechnicianCreation(request).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })

    },
)

export const GetBusinessTechnician = createAsyncThunk(
    'BusinessTechinician/GetBusinessTechnician',
    async (thunkApi): Promise<any> => {
        const businessTechinicianService = NewBusinessTechnicianService()
        return businessTechinicianService.GetBusinessTechnician()
    }
)

export const GetBusinessTechnicianById = createAsyncThunk(
    'BusinessTechinician/GetBusinessTechnicianById',
    async (id: string, thunkApi): Promise<any> => {
        const businessTechinicianService = NewBusinessTechnicianService()
        return businessTechinicianService.GetBusinessTechnicianById(id)
    }
)

export const EditBusinessTechnician = createAsyncThunk(
    'BusinessTechinician/EditBusinessTechnician',
    async (request: { id: string, data: CreateTechnicianDTO }, thunkApi): Promise<any> => {
        const businessTechinicianService = NewBusinessTechnicianService()
        return businessTechinicianService.EditBusinessTechnician(request.id, request.data)
    }
)

const BusinessTechnicianSlice = createSlice({
        name: 'BusinessTechinician/slice',
        initialState,
        reducers: {

            //Tecnico aziendale
            setBusinessTechnician: (state, action) => {
                state.userBusinessTechnicianLink.techId = action.payload.toString()
            },
            setCustomerBusinessTech: (state, action) => {
                state.userBusinessTechnicianLink.id = action.payload.toString()
            },
            setCompanyName: (state, action) => {
                state.createBusinessTechnician.companyName = action.payload
            },
            setFiscalCode: (state, action) => {
                state.createBusinessTechnician.fiscalCode = action.payload
            },
            setVat: (state, action) => {
                state.createBusinessTechnician.vat = action.payload
            },
            setAddress: (state, action) => {
                state.createBusinessTechnician.address = action.payload
            },
            setCountryRegion: (state, action) => {
                state.createBusinessTechnician.countryRegion = action.payload
            },
            setProvince: (state, action) => {
                state.createBusinessTechnician.province = action.payload
            },
            setCity: (state, action) => {
                state.createBusinessTechnician.city = action.payload
            },
            setZipCode: (state, action) => {
                state.createBusinessTechnician.zipCode = action.payload
            },
            setPhone: (state, action) => {
                state.createBusinessTechnician.phone = action.payload
            },
            setMobilePhone: (state, action) => {
                state.createBusinessTechnician.mobilePhone = action.payload
            },
            setEmail: (state, action) => {
                state.createBusinessTechnician.email = action.payload
            },
            setPec: (state, action) => {
                state.createBusinessTechnician.pec = action.payload
            },
            setActivationDate: (state, action) => {
                state.createBusinessTechnician.activationDate = action.payload
            },
            setDeactivationDate: (state, action) => {
                state.createBusinessTechnician.deactivationDate = action.payload
            },
            setIdBusinessTechUser: (state, action) => {
                state.idBusinessTechUser = action.payload !== undefined ? action.payload.toString() : undefined
            },
            setBusinessTechnicianByIdStatusIdle: (state) => {
                state.businessTechByIdStatus = 'idle'
            },

            // ERRORS
            setValidateTechnicianPhone: (state, action) => {
                state.technicianErrors.phone = action.payload
            },
            setValidateTechnicianMobilephone: (state, action) => {
                state.technicianErrors.mobilephone = action.payload
            },
            setValidateTechnicianZipCode: (state, action) => {
                state.technicianErrors.zipCode = action.payload
            },
            setValidateTechnicianFiscalCode: (state, action) => {
                state.technicianErrors.fiscalCode = action.payload
            },
            setValidateTechnicianAddress: (state, action) => {
                state.technicianErrors.address = action.payload
            },
            setValidatteTechnicianProvince: (state, action) => {
                state.technicianErrors.province = action.payload
            },
            setValidateTechnicianCity: (state, action) => {
                state.technicianErrors.city = action.payload
            },
            setvalidateTechnicianCountryRegion: (state, action) => {
                state.technicianErrors.countryRegion = action.payload
            },
            setValidateTechnicianVat: (state, action) => {
                state.technicianErrors.vat = action.payload
            },
            resetTechnicianErrors: (state) => {
                state.technicianErrors = {
                    phone: false,
                    mobilephone: false,
                    zipCode: false,
                    fiscalCode: false,
                    address: false,
                    province: false,
                    city: false,
                    vat: false,
                    countryRegion: false
                }
            },

            resetBusinessTechnicianByIdResponse: (state, action) => {
                state.businessTechById = action.payload
            },

            setSelectedTech: (state, action) => {
                state.selectedId = action.payload.toString()
            },

            resetCreateStatus: (state, action) => {
                state.technicianCreationStatus = action.payload
            },
            resetGetTechByIdStatus: (state, action) => {
                state.businessTechByIdStatus = action.payload
            },
            resetEditStatus: (state, action) => {
                state.businessTechinicianEditStatus = action.payload
            },
            resetBusinessTechnicianValidationStatus: (state) => {
                state.businessTechnicianValidationStatus = 'idle'
            }
        },
        extraReducers(builder) {
            builder
                .addCase(CreateTechnicianCreation.pending, (state) => {
                    state.technicianCreationStatus = 'loading';
                })
                .addCase(CreateTechnicianCreation.fulfilled, (state) => {
                    state.technicianCreationStatus = 'successfully';
                })
                .addCase(CreateTechnicianCreation.rejected, (state) => {
                    state.technicianCreationStatus = 'failed';
                })

                .addCase(BusinessTechnicianValidation.pending, (state) => {
                    state.businessTechnicianValidationStatus = 'loading';
                })
                .addCase(BusinessTechnicianValidation.fulfilled, (state) => {
                    state.businessTechnicianValidationStatus = 'successfully';
                })
                .addCase(BusinessTechnicianValidation.rejected, (state) => {
                    state.businessTechnicianValidationStatus = 'failed';
                })

                .addCase(GetBusinessTechnician.pending, (state) => {
                    state.businessTechinicianResponseStatus = 'loading';
                })
                .addCase(GetBusinessTechnician.fulfilled, (state, action) => {
                    state.businessTechinicianResponseStatus = 'successfully';
                    state.businessTechinicianResponse = action.payload
                })
                .addCase(GetBusinessTechnician.rejected, (state) => {
                    state.businessTechinicianResponseStatus = 'failed';
                })

                .addCase(GetBusinessTechnicianById.pending, (state) => {
                    state.businessTechByIdStatus = 'loading';
                })
                .addCase(GetBusinessTechnicianById.fulfilled, (state, action) => {
                    state.businessTechByIdStatus = 'successfully';
                    state.businessTechById = action.payload
                })
                .addCase(GetBusinessTechnicianById.rejected, (state) => {
                    state.businessTechByIdStatus = 'failed';
                })

                .addCase(EditBusinessTechnician.pending, (state) => {
                    state.businessTechinicianEditStatus = 'loading';
                })
                .addCase(EditBusinessTechnician.fulfilled, (state, action) => {
                    state.businessTechinicianEditStatus = 'successfully';
                    state.businessTechById = action.payload
                })
                .addCase(EditBusinessTechnician.rejected, (state) => {
                    state.businessTechinicianEditStatus = 'failed';
                });

        },
    })

export const {
    setCompanyName,
    setFiscalCode,
    setAddress,
    setCity,
    setCountryRegion,
    setEmail,
    setMobilePhone,
    setPec,
    setPhone,
    setProvince,
    setVat,
    setZipCode,
    setActivationDate,
    setDeactivationDate,
    setBusinessTechnician,
    setCustomerBusinessTech,
    setIdBusinessTechUser,
    setValidateTechnicianFiscalCode,
    setValidateTechnicianMobilephone,
    setValidateTechnicianPhone,
    setValidateTechnicianZipCode,
    setValidateTechnicianAddress,
    setValidateTechnicianCity,
    setValidatteTechnicianProvince,
    setvalidateTechnicianCountryRegion,
    setValidateTechnicianVat,
    resetTechnicianErrors,
    setSelectedTech,
    resetCreateStatus,
    resetGetTechByIdStatus,
    resetEditStatus,
    resetBusinessTechnicianByIdResponse,
    resetBusinessTechnicianValidationStatus,
    setBusinessTechnicianByIdStatusIdle

} = BusinessTechnicianSlice.actions

export default BusinessTechnicianSlice.reducer