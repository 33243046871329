import { OptionType } from "../../../../../utils"
import { nonComplianceAreaDTO } from "../../../NonComplianceCode/dto"

export interface MeasureBoxProps {
    isAdditional: boolean
    newMeasureId: string
    standardMeasure?: string
    standardMeasureToSet?: boolean
    setStandardMeasureToSet?: (b: boolean) => void
    availableOptions: OptionType[]
}

export type MeasureBoxColor = 'green' | 'orange'

export const MeasureBoxColorMap = new Map<MeasureBoxColor, {
    titleColor: string,
    subtitleColor: string,
    contentColor: string,
    backgroundColor: string,
    borderBottomColor: string
}>([
    ['green', { titleColor: '#4d797f', subtitleColor: '#314d51', contentColor: '#4d797f', backgroundColor: '#D9E6E8', borderBottomColor: '#BFCFD1' }],
    ['orange', { titleColor: '#925016', subtitleColor: '#925016', contentColor: '#BA6520', backgroundColor: '#fff4e6', borderBottomColor: '#FAE7D7' }]
])