import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../../lib/redux/hooks';
import '../style.scss';
import DatePicker, { registerLocale } from "react-datepicker";
import { it } from 'date-fns/locale/it';
import "react-datepicker/dist/react-datepicker.css";
import React from "react";
import {  formatterDate } from "../../../utils";
import { addEditLegalRapp, DeactivateRappLegale, GetAllRappLegale, GetRappLegaleById, resetCreateEditRapprLegale, resetCreateRappLegaleStatus, resetGetRappLegaleByIdStatus, resetUpdateRappLegaleStatus, setCustomerId,  setEditLegalRapp,  setIdsLegalRapp, setOnEditRappLegalePopup, setSelectedLegalRapp } from "../slice";
import { Dropdown } from "../../../ui/organisms/dropdown";
import { EditIcon } from "../../../ui/icons/edit";
import { TrashIcon } from "../../../ui/icons/trash";
import Button from "../../../ui/molecules/button";
import { RappLegalePopup } from "../../../ui/molecules/rappLegalePopup";
import { GetRapplegaleByIdDTO } from "../dto";
import { Banner } from "../../../ui/organisms/banner";


registerLocale('it', it)

type OptionType = {
    value: string;
    label: string;
};

export function BoxRappLegaleEdit() {
    const clientiState = useAppSelector(state => state.clienti)
    const usersState = useAppSelector(state => state.users)
    const dispatch = useAppDispatch()
    const [popupOpen, setPopupOpen] = useState<boolean>(false);
    const [expanded, setExpanded] = React.useState<string | false>("panel1");
    const [disableButton, setDisableButton] = useState<boolean>(false);
    const [showError2, setShowError2] = useState<boolean>(false)

    const voidRappLeg: GetRapplegaleByIdDTO = {
        userId: usersState.selectedUserId,
        fiscalCode: '',
        surname: '',
        name: '',
        sex: '',
        birthdate: new Date(),
        state: 'IT',
        countryRegion: '',
        province: '',
        city: '',
        contactAddress: '',
        contactCountryRegion: '',
        contactProvince: '',
        contactCity: '',
        contactZipCode: '',
        phone: '',
        mobilePhone: '',
        email: '',
        fax: '',
        note: '',
        activationDate: new Date(),
}

    const handleOnEdit = (legale: GetRapplegaleByIdDTO) => {
        dispatch(setOnEditRappLegalePopup(true));
        dispatch(setSelectedLegalRapp(legale));
    }

    useEffect(() => {
        if (clientiState.idsLegalRap !== undefined && clientiState.idsLegalRap.length > 0) {
            for (let index = 0; index < clientiState.idsLegalRap.length; index++) {
                dispatch(GetRappLegaleById(clientiState.idsLegalRap[index].toString()))
            }
            dispatch(setIdsLegalRapp([]))
        }
    }, [])

    useEffect(() => {
        if (clientiState.GetRappLegaleByIdResponseStatus === 'successfully') {
            dispatch(resetGetRappLegaleByIdStatus());
            if (clientiState.GetRappLegaleByIdResponse !== undefined) {
                if(clientiState.GetRappLegaleByIdResponse.active) {
                    setDisableButton(true)
                }
                dispatch(addEditLegalRapp(clientiState.GetRappLegaleByIdResponse));
            }
        }
    }, [clientiState.GetRappLegaleByIdResponseStatus]);

    useEffect(() => {
        if(clientiState.rappLegaleCreateResponseStatus === 'successfully') {
            dispatch(GetAllRappLegale())
        }
      }, [clientiState.rappLegaleCreateResponseStatus])
    
      useEffect(() => {
        if(clientiState.GetAllRappLegaleStatus === 'successfully' &&
            clientiState.GetAllRappLegaleResponse !== undefined
        ) {
            for(const l of clientiState.GetAllRappLegaleResponse.data) {
                if(l.userId === usersState.selectedUserId) {
                    dispatch(addEditLegalRapp(l));
                    if(l.active) {
                        setDisableButton(true);
                    } else {
                        setDisableButton(false)
                    }
                }
            }
        }
      }, [clientiState.GetAllRappLegaleStatus])

      useEffect(() => {
        if(clientiState.rappLegaleUpdateStatus === 'successfully') {
            dispatch(resetUpdateRappLegaleStatus())
            dispatch(resetCreateEditRapprLegale())
            dispatch(setEditLegalRapp([]))
            dispatch(GetAllRappLegale());
        }
        
      }, [clientiState.rappLegaleUpdateStatus])

      useEffect(() => {
        if(clientiState.rappLegaleCreateResponseStatus === 'successfully') {
            dispatch(resetCreateRappLegaleStatus("idle"));
            dispatch(resetCreateEditRapprLegale())
            dispatch(setEditLegalRapp([]))
            dispatch(GetAllRappLegale());
        }
        
      }, [clientiState.rappLegaleCreateResponseStatus])

      useEffect(() => {
        if(clientiState.rappLegaleDeactivateStatus === 'successfully') {
            dispatch(setEditLegalRapp([]))
            dispatch(GetAllRappLegale());
        }
      }, [clientiState.rappLegaleDeactivateStatus])

    return (
        <div style={{ padding: '24px' }} id="boxAnagrafica">
            {
                popupOpen && (
                    <div>
                        <RappLegalePopup closeHandler={() => {setPopupOpen(false)}} />
                    </div>
                )
            }
            {
                !showError2 &&

                <Banner
                    errorsList={clientiState.errorMessages}
                    type="error"
                    visible={clientiState.validateNewLegalRappStatus === 'failed'}
                    label={"Non è possibile aggiungere un rappresentante legale se il precedente è ancora attivo"}
                    closeAction={function (): void {
                        setShowError2(false)
                        dispatch(resetCreateRappLegaleStatus('idle'))
                    }}
                />
            }
            <div>
            <table id="customers-table" className="w-[100%]">
                    <thead className="h-[52px] uppercase">
                        <tr className="bg-brandPrimary-600">
                            <th style={{ borderTopLeftRadius: '8px', width: '25%'  }}>CODICE FISCALE</th>
                            <th >COGNOME</th>
                            <th>NOME</th>
                            <th>DATA DI NASCITA</th>
                            <th>INDIRIZZO</th>
                            <th>CAP</th>
                            <th>COMUNE</th>
                            <th>PROVINCIA</th>
                            <th>DATA DI INGRESSO</th>
                            <th>DATA USCITA</th>
                            <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {clientiState.editLegalRaps.map(legal =>
                            <tr
                            className=" h-[52px]  border-b-[1px] border-b-neutral-200"
                            key={legal.userId}
                            >
                                <td className="text-neutral-700">
                                    {legal.fiscalCode}
                                </td>
                                <td className="text-neutral-700">
                                    {legal.surname}
                                </td>
                                <td className="text-neutral-700">
                                    {legal.name}
                                </td>
                                <td className="text-neutral-700">
                                    {formatterDate(legal.birthdate)}
                                </td>
                                <td className="text-neutral-700">
                                    {legal.contactAddress}
                                </td>
                                <td className="text-neutral-700">
                                    {legal.contactZipCode}
                                </td>
                                <td className="text-neutral-700">
                                    {legal.city}
                                </td>
                                <td className="text-neutral-700">
                                    {legal.province}
                                </td>
                                <td className="text-neutral-700">
                                    {formatterDate(legal.activationDate)}
                                </td>
                                <td className="text-neutral-700">
                                    {formatterDate(legal.deactivationDate)}
                                </td>
                                <td className="w-[20px] cursor-pointer">
                                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                                        {
                                        legal.active ?
                                        (
                                            <Dropdown items={[
                                                {
                                                    label: 'Modifica',
                                                    icon: <EditIcon color={""} size={0} />,
                                                    onClick: () => {
                                                        handleOnEdit(legal);
                                                        setPopupOpen(true)
                                                    }
                                                },
                                                {
                                                    label: 'Disattiva',
                                                    icon: <TrashIcon color={""} size={0} />,
                                                    onClick: () => {
                                                        dispatch(DeactivateRappLegale(legal.id!))
                                                    }
                                                }
    
                                            ]} />
                                         ) :
                                        (
                                            <Dropdown items={[
                                                {
                                                    label: 'Modifica',
                                                    icon: <EditIcon color={""} size={0} />,
                                                    onClick: () => {
                                                        handleOnEdit(legal);
                                                        setPopupOpen(true)
                                                    }
                                                }
    
                                            ]} />
                                        )
                                        }
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>

                </table>
                <div className="mt-5">
                    <Button 
                        disabled={disableButton}
                        size="md" 
                        iconPosition="off" 
                        variant="solid" 
                        color="orange" 
                        label="Aggiungi Rappresentante Legale" 
                        onClick={() => {
                            dispatch(setCustomerId(clientiState.customerId))
                            dispatch(setSelectedLegalRapp(voidRappLeg))
                            dispatch(setOnEditRappLegalePopup(false));
                            setPopupOpen(true)
                        }}
                        />
                </div>
            </div>
        </div >
    )
}