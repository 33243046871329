import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import Button from "../../../ui/molecules/button";
import Input from "../../../ui/molecules/input";
import { MeasureRecipientUpdate, setMeasureRecipientUpdateDescription, setMeasureRecipientUpdateEmail, setMeasureRecipientUpdatePec } from "./slice";

interface Props {
    close: () => void
}

export function MeasureRecipientPopupEdit(props: Props) {
    const dispatch = useAppDispatch()
    const measureRecipientState = useAppSelector(state => state.measureRecipient)

    useEffect(() => {
        if(measureRecipientState.measureRecipientUpdateStatus === 'successfully') {
            props.close()
        }
    }, [measureRecipientState.measureRecipientUpdateStatus])

    return (
        <div>
            <div className="bg-brandPrimary-100 pr-5 pl-5 pt-4 pb-5" style={{ borderTopWidth: 1 }}>
                <div className="pb-3" style={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Descrizione'}
                            placeholder="Specifica descrizione..."
                            value={measureRecipientState.measureRecipientUpdateRequest.description}
                            onChangeText={(text) => dispatch(setMeasureRecipientUpdateDescription(text))}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Email'}
                            placeholder="Specifica email..."
                            value={measureRecipientState.measureRecipientUpdateRequest.email}
                            onChangeText={(text) => dispatch(setMeasureRecipientUpdateEmail(text))}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Pec'}
                            placeholder="Specifica pec..."
                            value={measureRecipientState.measureRecipientUpdateRequest.pec}
                            onChangeText={(text) => dispatch(setMeasureRecipientUpdatePec(text))}
                        />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingRight: 15 }}>
                <Button
                    size="md"
                    variant="outline"
                    iconPosition="off"
                    label="Annulla"
                    color="dimgrey"
                    onClick={() => {
                        props.close();
                    }}
                />
                <div className="pl-2">
                    <Button
                        size="md"
                        variant="solid"
                        iconPosition="off"
                        label="Salva"
                        color="orange"
                        onClick={() => dispatch(MeasureRecipientUpdate({body: measureRecipientState.measureRecipientUpdateRequest, id: measureRecipientState.measureRecipientIdToUpdate}))}
                    />
                </div>
            </div>
        </div>
    )
}