import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import Toggle from "../../../ui/molecules/toggle"
import { GetAllMeasureRecipients, MeasureRecipientDelete, MeasureRecipientRemove, MeasureRecipientRestore, resetMeasureRecipientCreationStatus, resetMeasureRecipientDeleteStatus, resetMeasureRecipientRemoveStatus, resetMeasureRecipientRestoreStatus, resetMeasureRecipientUpdateStatus, setMeasureRecipientIdToUpdate, setMeasureRecipientUpdateDescription, setMeasureRecipientUpdateEmail, setMeasureRecipientUpdatePec } from "./slice"
import { MeasureRecipientDTO } from "./dataService/dto"
import { EditIcon } from "../../../ui/icons/edit"
import { TrashIcon } from "../../../ui/icons/trash"
import { Alert } from "../../../ui/organisms/alert"
import Button from "../../../ui/molecules/button"
import { PopupForm } from "../../../ui/organisms/popupForm"
import { MeasureRecipientPopupEdit } from "./popup"

export function MeasureRecipientList() {
    const dispatch = useAppDispatch()
    const measureRecipientState = useAppSelector(state => state.measureRecipient)
    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const [mRecToDelete, setMRecToDelete] = useState<MeasureRecipientDTO | null>(null)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [showPopup, setShowPopup] = useState<boolean>(false)

    const onEditHandle = (measureRec: MeasureRecipientDTO) => {
        dispatch(setMeasureRecipientUpdateDescription(measureRec.description))
        dispatch(setMeasureRecipientUpdateEmail(measureRec.email))
        dispatch(setMeasureRecipientUpdatePec(measureRec.pec))
        dispatch(setMeasureRecipientIdToUpdate(measureRec.id))
        setShowPopup(true)
    }

    useEffect(() => {
        dispatch(GetAllMeasureRecipients(measureRecipientState.getAllMeasureRecipientFilters))
    }, [])

    useEffect(() => {
        if(measureRecipientState.measureRecipientCreationStatus === 'successfully') {
            dispatch(resetMeasureRecipientCreationStatus())
            dispatch(GetAllMeasureRecipients(measureRecipientState.getAllMeasureRecipientFilters))
            setShowSuccess(true)
        }

        if(measureRecipientState.measureRecipientUpdateStatus === 'successfully') {
            dispatch(resetMeasureRecipientUpdateStatus())
            dispatch(GetAllMeasureRecipients(measureRecipientState.getAllMeasureRecipientFilters))
            setShowSuccess(true)
        }

        if(measureRecipientState.measureRecipientDeleteStatus === 'successfully') {
            dispatch(resetMeasureRecipientDeleteStatus())
            dispatch(GetAllMeasureRecipients(measureRecipientState.getAllMeasureRecipientFilters))
        }

        if(measureRecipientState.measureRecipientRemoveStatus === 'successfully') {
            dispatch(resetMeasureRecipientRemoveStatus())
            dispatch(GetAllMeasureRecipients(measureRecipientState.getAllMeasureRecipientFilters))
        }

        if(measureRecipientState.measureRecipientRestoreStatus === 'successfully') {
            dispatch(resetMeasureRecipientRestoreStatus())
            dispatch(GetAllMeasureRecipients(measureRecipientState.getAllMeasureRecipientFilters))
        }
    }, [
        measureRecipientState.measureRecipientCreationStatus,
        measureRecipientState.measureRecipientUpdateStatus,
        measureRecipientState.measureRecipientDeleteStatus,
        measureRecipientState.measureRecipientRemoveStatus,
        measureRecipientState.measureRecipientRestoreStatus
    ])

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setShowSuccess(false)
            }, 2000)
        }
    }, [showSuccess])

    return (
        <div className="w-[100%] flex flex-col gap-[16px] filter bg-brandPrimary-200" style={{ flex: 0.7, padding: '0px 24px 24px 24px', borderTopRightRadius: '20px' }}>
            <div className="w-[100%]">
                {
                    (showSuccess) &&

                    <div className="gap-[8px] text-left bg-green-300" style={{ margin: '24px', borderRadius: '8px' }}>
                        <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                            Dati salvati correttamente.
                        </span>
                    </div>
                }
                <div style={{ padding: '24px 0' }}>
                    <table id="users-table" className="w-full table-fixed">
                        <thead className="h-[52px] uppercase">
                            <tr>
                                <th className="w-[100px]">Descrizione</th>
                                <th className="w-[100px]">Email</th>
                                <th className="w-[100px]">Pec</th>
                                <th className="w-[70px]">Attivo</th>
                                <th className="w-[30px]" style={{ borderTopRightRadius: '8px'}}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {measureRecipientState.getAllMeasureRecipientsResponse?.data.map((measureRec, index) => (
                                <tr
                                    key={index}
                                    style={{
                                        color: '#4D797F',
                                        background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                    }}
                                >
                                    <td className="whitespace-normal break-words" style={{ maxWidth: '150px' }}>{measureRec.description}</td>
                                    <td className="whitespace-normal break-words" style={{ maxWidth: '150px' }}>{measureRec.email}</td>
                                    <td className="whitespace-normal break-words" style={{ maxWidth: '150px' }}>{measureRec.pec}</td>
                                    <td>
                                        <Toggle
                                            checked={measureRec.deletedAt === null}
                                            onChange={(value) =>
                                                value
                                                    ? dispatch(MeasureRecipientRestore(measureRec.id!))
                                                    : dispatch(MeasureRecipientRemove(measureRec.id!))
                                            }
                                        />
                                    </td>
                                    <td className="whitespace-normal break-words" style={{ maxWidth: '100px' }}>
                                        <div className="flex justify-content-between align-items-center gap-[8px]">
                                            <button onClick={() => onEditHandle(measureRec)}>
                                                <EditIcon color={"Orange"} size={18} />
                                            </button>
                                            <div className="flex align-items-center">
                                                <button onClick={() => {
                                                    setMRecToDelete(measureRec)
                                                    setShowAlert(true)
                                                }}>
                                                    <TrashIcon color={"Orange"} size={0} />
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {
                (showAlert && mRecToDelete) && (
                    <Alert title="Conferma operazione" close={() => setShowAlert(false)}>
                        <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
                            <div className="pb-3">
                                <div>
                                    Sei sicuro di voler eliminare l'allegato?
                                </div>
                                <div className="mt-3 font-bold">
                                    {`${ mRecToDelete.description} - [${mRecToDelete.email}] - [${mRecToDelete.pec}]`}
                                </div>
                            </div>
                            <div className="mt-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    variant="outline"
                                    color="dimgrey"
                                    iconPosition="off"
                                    label="Annulla"
                                    size="md"
                                    onClick={() => setShowAlert(false)}
                                />
                                <div className="pl-8">
                                    <Button
                                        variant="solid"
                                        color="orange"
                                        iconPosition="off"
                                        label="Elimina"
                                        size="md"
                                        onClick={() => {
                                            dispatch(MeasureRecipientDelete(mRecToDelete.id!))
                                            setShowAlert(false)
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                    </Alert>
                )
            }
            {
                showPopup && (
                    <PopupForm title="Modifica Destinatario" close={() => setShowPopup(false)}>
                        <MeasureRecipientPopupEdit close={() => setShowPopup(false)} />
                    </PopupForm>
                )
            }
        </div>
    )
}