import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import Button from "../../../../ui/molecules/button";
import { ProtocolSearch } from "../../../../ui/molecules/ProtocolSearch";
import { SelectCustom } from "../../../../ui/molecules/select";
import TextArea from "../../../../ui/molecules/textArea";
import { optionsFormatter, statoRichiestaComplianceRequest } from "../../../../utils";
import { TrashIcon } from "../../../../ui/icons/trash";
import { Alert } from "../../../../ui/organisms/alert";
import { Calendar } from "../../../../ui/molecules/calendar";

export function AddStateRequest() {
    const dispatch = useAppDispatch()
    const reportingState = useAppSelector(state => state.reporting)
    const [showAlert, setShowAlert] = useState<boolean>(false)

    return (
        <div>

            <div className='text-left' style={{ padding: '24px 24px 12px 24px' }}>
                <span className='rid-title' style={{ color: '#4D797F' }}>Stati </span>
                <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                <span className='rid-title' style={{ color: '#314D51' }}> Creazione</span>
            </div>

            <div style={{ padding: '0px 24px 12px 24px' }} className="borderBottomLightGreen">
                <div style={{ textAlign: 'left' }}>

                    <SelectCustom
                        label='Tipologia della Richiesta'
                        placeholder={'Seleziona Tipologia'}
                        options={statoRichiestaComplianceRequest}
                        onChange={(value) => console.log()}
                    />
                </div>
                <div className="mt-3">

                    <div className="text-left">
                        <span className="input-label">Data</span>
                    </div>
                    <Calendar
                        onChange={(date) => console.log()}
                    />
                </div>
                <div className="mt-3">
                    <div>
                        <ProtocolSearch
                            type='entry'
                            onSelect={() => { }}
                        />
                    </div>
                </div>
                <div className="mt-3">
                    <div>
                        <ProtocolSearch
                            type='departure'
                            onSelect={() => { }}
                        />
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }} className="mt-6">
                    <Button
                        iconPosition="off"
                        variant="solid"
                        color="orange"
                        label="Aggiungi Stato"
                        size="sm"
                        onClick={() => console.log()}
                    />
                </div>
            </div>

        </div>
    )
}