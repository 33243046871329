import { useNavigate } from "react-router-dom"
import { Layout } from "../../../ui/layout"
import Button from "../../../ui/molecules/button"
import { MenuItems } from "../../../ui/organisms/navbar/dto"
import { SelectCustom } from "../../../ui/molecules/select"
import { ConversionPeriodStateOptions, sot, yearOptions } from "../../../utils"
import Input from "../../../ui/molecules/input"
import { PeriodoConversioneList } from "./list"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { GetAllConversionPeriods, resetConversionPeriodCreationStatus, resetConversionPeriodUpdateStatus, resetGetAllConversionPeriodFilters, setGetAllConversionPeriodCountryRegion, setGetAllConversionPeriodHeadQuarter, setGetAllConversionPeriodState, setGetAllConversionPeriodYear } from "./slice"
import { useEffect, useState } from "react"
import { SOTFilter } from "../../sot"

export function PeriodoConversione() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const conversionPeriodState = useAppSelector(state => state.conversionPeriod)
    const [showSuccess, setShowSuccess] = useState<boolean>(false)

    useEffect(() => {
        if (conversionPeriodState.conversionPeriodCreationStatus === 'successfully') {
            setShowSuccess(true)
            dispatch(resetConversionPeriodCreationStatus())
        }
        if (conversionPeriodState.conversionPeriodUpdateStatus === 'successfully') {
            setShowSuccess(true)
            dispatch(resetConversionPeriodUpdateStatus())
        }
    }, [conversionPeriodState.conversionPeriodCreationStatus, conversionPeriodState.conversionPeriodUpdateStatus])

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setShowSuccess(false)
            }, 2000)
        }
    }, [showSuccess])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Controllo', 'Elenco Richieste Periodi di Conversione']}
            noPaddingLat
            headerLabel="Elenco richieste periodo di conversione"
            headerChildren={
                <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>
                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Crea nuovo" color={"orange"}
                        onClick={() => {
                            navigate('/addPeriodoConversione')
                        }}
                    />
                </div>
            }
        >
            {/* FILTERS */}
            <div style={{ display: 'flex', padding: '12px 24px 0px 24px' }}>
                <div style={{ display: 'flex', flex: '0.70', gap: '24px' }}>
                    <div style={{ flex: '0.25' }}>
                        <div className="text-left">
                            <span className="input-label">Anno</span>
                        </div>
                        <SelectCustom
                            key={conversionPeriodState.getAllConversionPeriodsFilters.year || 'default'}
                            placeholder={'Anno'}
                            value={conversionPeriodState.getAllConversionPeriodsFilters.year}
                            options={yearOptions}
                            onChange={(value) => dispatch(setGetAllConversionPeriodYear(value))}
                        />
                    </div>
                    <div style={{ flex: '0.25' }}>
                        <div className="text-left">
                            <span className="input-label">Sede</span>
                        </div>
                        <SOTFilter
                            key={conversionPeriodState.getAllConversionPeriodsFilters.headQuarter || 'default'}
                            value={conversionPeriodState.getAllConversionPeriodsFilters.headQuarter}
                            // disabled={usersState.findMeResponse.headQuarterInfo?.code !== 'SC' && usersState.findMeResponse.role !== 'admin'}
                            onChange={(value) => dispatch(setGetAllConversionPeriodHeadQuarter(value))}
                        />
                    </div>
                    <div style={{ flex: '0.25' }}>
                        <div className="text-left">
                            <span className="input-label">Regione</span>
                        </div>
                        <Input
                            placeholder={'Regione'}
                            value={conversionPeriodState.getAllConversionPeriodsFilters.countryRegion}
                            onChangeText={(value) => dispatch(setGetAllConversionPeriodCountryRegion(value))}
                        />
                    </div>
                    <div style={{ flex: '0.25' }}>
                        <div className="text-left">
                            <span className="input-label">Esito</span>
                        </div>
                        <SelectCustom
                            key={conversionPeriodState.getAllConversionPeriodsFilters.state || 'default'}
                            placeholder={'Esito'}
                            value={conversionPeriodState.getAllConversionPeriodsFilters.state}
                            options={ConversionPeriodStateOptions} // DA RIMPIAZZARE CON ENUM REALE
                            onChange={(value) => dispatch(setGetAllConversionPeriodState(value))}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', gap: '18px', alignItems: 'flex-end' }}>
                    <Button
                        size={"lg"}
                        iconPosition={"off"}
                        variant={"link"}
                        label="Pulisci"
                        color={"gray"}
                        onClick={() => dispatch(resetGetAllConversionPeriodFilters())}
                    />
                    <Button
                        variant='outline'
                        color='blue'
                        size='md'
                        iconPosition='off'
                        label='Cerca'
                        onClick={() => dispatch(GetAllConversionPeriods(conversionPeriodState.getAllConversionPeriodsFilters))}
                    />
                </div>
            </div>

            {
                showSuccess &&
                <div className="gap-[8px] text-left" style={{ margin: '24px', borderRadius: '12px', backgroundColor: '#D1F5D9', borderColor: '#8AE59F', borderWidth: 2 }}>
                    <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                        Dati salvati correttamente.
                    </span>
                </div>
            }

            {/* LISTING */}
            <div className="fieldset bg-brandPrimary-200">
                <PeriodoConversioneList />
            </div>
        </Layout>
    )
}