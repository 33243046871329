import { sotFilterDTO } from "./dto";
import { SotServiceImpl } from "./serviceImpl";

export interface SotService {
    GetHeadQuarter(filters: sotFilterDTO): Promise<any>
    FindSotById(id: string): Promise<any>
}

export function NewSotService(): SotService {
    return new SotServiceImpl();
}
