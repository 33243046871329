import axios from "axios";
import { NcService } from "./service";
import { keycloak } from "../../../lib/keycloak";
import { AllAreasDTO, AllAreasResponseDTO, NonComplianceCodeByIdDTO, NonComplianceCodResponseDTO } from "./dto";

export class NcServiceImpl implements NcService {

    public GetAllNc(): Promise<NonComplianceCodResponseDTO> {
        return axios.get("/api/new-non-compliance-code", {
            params: {
                itemsPerPage: 0,
                page: 0,
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetAllNcByArea(areaCode: string): Promise<NonComplianceCodeByIdDTO[]> {
        return axios.get("/api/new-non-compliance-code/by-area-code/"+ areaCode, {
            params: {
                itemsPerPage: 0,
                page: 0,
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetAllAreas(): Promise<AllAreasResponseDTO> {
        return axios.get("/api/new-non-compliance-code/get-all-areas", {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetNcById(id: string): Promise<NonComplianceCodeByIdDTO> {
        return axios.get("/api/new-non-compliance-code/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

}