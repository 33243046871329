export enum ReinforcedMotivationsEnum {
    CLIENT_WITH_OVER_3_OFIS_REPORTINGS = 'CLIENT_WITH_OVER_3_OFIS_REPORTINGS',
    IRREGULAR_INFRACTION = 'IRREGULAR_INFRACTION',
    SPECIFIC_CRIMINAL_INVESTIGATIONS = 'SPECIFIC_CRIMINAL_INVESTIGATIONS',
    AT_REQUEST_OF_COMPETENT_AUTHORITY = 'AT_REQUEST_OF_COMPETENT_AUTHORITY',
    OTHER = 'OTHER'
}

export interface ReinforcedControlMeasuresCreationUpdateDTO {
    activeFrom?: Date // MANDATORY IN CREATION
    motivationType?: ReinforcedMotivationsEnum // MANDATORY IN CREATION
    motivationNote?: string
    entryProtocolIds?: string[]
    departureProtocolIds?: string[]
    note?: string
    closingDate?: Date
    closingMotivationNote?: string
    closingProtocolId?: string
    closingNote?: string
    customerDataId?: string // MANDATORY IN CREATION
}

export interface ReinforcedControlMeasureErrorsDTO {
    activeFrom: boolean
    motivationType: boolean
}

export interface ReinforcedControlMeasureDTO {
    id: string
    createdAt: Date | null
    updatedAt: Date | null
    deletedAt: Date | null
    activeFrom: Date
    motivationType: ReinforcedMotivationsEnum
    motivationNote: string
    entryProtocolsInfo: {
        protocolId: string
        protocolInfo: string
    }[]
    departureProtocolsInfo: {
        protocolId: string
        protocolInfo: string
    }[]
    note: string
    closingDate: Date
    closingMotivationNote: string
    closingNote: string
    closingProtocolId: string
    closingProtocolInfo: string
    customerDataId: string
    customerCompanyName: string
    customerCountryRegion: string
    customerHeadQuarters: string
}

export interface GetAllReinforcedControlMeasuresDTO {
    total: number,
    page: number,
    perPage: number | null,
    prev: string | null,
    next: string | null,
    totalPage: number | null,
    data: ReinforcedControlMeasureDTO[]
}

export interface GetAllReinforcedControlMeasureFiltersDTO {
    itemsPerPage: number
    page: number
    countryRegion?: string
    customerDataId?: string
    headQuarter?: string
    order?: boolean
    sort?: string
    year?: string
}