import { GetAllOdcDTO, GetAllOdcFiltersDTO, OdcDTO } from "./dto";
import { OdcServiceImpl } from "./serviceImpl";

export interface OdcService {
    CreateODC(data: OdcDTO): Promise<void>
    UpdateODC(data: OdcDTO, id: string): Promise<void>
    DeleteODC(id: string): Promise<void>
    GetAllODC(filters: GetAllOdcFiltersDTO): Promise<GetAllOdcDTO>
    GetODCById(id: string): Promise<OdcDTO>
    RemoveOdc(id: string): Promise<void>
    RestoreOdc(id: string): Promise<void>
}

export function NewOdcService(): OdcService {
    return new OdcServiceImpl()
}