import { Router } from "@remix-run/router"
import { useEffect, useState } from "react"
import { RouterProvider } from "react-router-dom"
import { keycloak } from "../keycloak"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { MenuItems } from "../../ui/organisms/navbar/dto"
import { setMenuItems } from "../../ui/layout/slice"
import adminRouter from "./adminRouter"
import userRouter from "./userRouter"

export function KeycloakRouter() {
    const roles = useAppSelector(state => state.keycloak.userRoles)

    const dispatch = useAppDispatch()

    const [router, setRouter] = useState<Router | undefined>(undefined)

    const routeConfig = {
        admin: {
            router: adminRouter,
            menuItems: [
                { item: MenuItems.DASHBOARD, section: 1 },
                { item: MenuItems.USERS, section: 1 },
                { item: MenuItems.CLIENTI, section: 1 },
                { item: MenuItems.GESTIONE_CLIENTI, section: 1 },
                { item: MenuItems.TECNICO_AZIENDALE, section: 1 },
                { item: MenuItems.PROTOCOLLO, section: 2 },
                { item: MenuItems.CARTELLA_AZIENDALE, section: 2 },
                { item: MenuItems.GESTIONE_CONTROLLO, section: 2 },
                { item: MenuItems.GESTIONE_CERTIFICAZIONE, section: 4 },
                { item: MenuItems.GESTIONE_PROVVEDIMENTI, section: 4 },
                { item: MenuItems.GESTIONE_PERSONALE, section: 4 },
                { item: MenuItems.AMMINISTRAZIONE, section: 4 },
                { item: MenuItems.STRUMENTI, section: 5 },
            ],
        },
        internalUser: {
            router: userRouter,
            menuItems: [
                { item: MenuItems.DASHBOARD, section: 1 },
                { item: MenuItems.CLIENTI, section: 1 },
                { item: MenuItems.GESTIONE_CLIENTI, section: 1 },
                { item: MenuItems.PROTOCOLLO, section: 2 },
                { item: MenuItems.GESTIONE_CONTROLLO, section: 2 },
                { item: MenuItems.GESTIONE_CERTIFICAZIONE, section: 4 },
                { item: MenuItems.GESTIONE_PROVVEDIMENTI, section: 4 },
                { item: MenuItems.GESTIONE_PERSONALE, section: 3 },
                { item: MenuItems.CARTELLA_AZIENDALE, section: 2 },
                { item: MenuItems.AMMINISTRAZIONE, section: 4 },
            ],
        },
    };


    useEffect(() => {
        if (roles !== undefined && roles.length > 0 && keycloak.token !== undefined) {
            if (keycloak.hasRealmRole('admin') || keycloak.hasRealmRole('RST')) {
                const config = routeConfig.admin;
                setRouter(config.router);
                dispatch(setMenuItems(config.menuItems));
            }
            else if (
                keycloak.hasRealmRole('FST') || keycloak.hasRealmRole('RST')
                || keycloak.hasRealmRole('DG') || keycloak.hasRealmRole('Amm')
                || keycloak.hasRealmRole('RSCo') || keycloak.hasRealmRole('FUCO')
                || keycloak.hasRealmRole('RSCe') || keycloak.hasRealmRole('FUCE')
                || keycloak.hasRealmRole('UCA') || keycloak.hasRealmRole('VAL')
                || keycloak.hasRealmRole('SM') || keycloak.hasRealmRole('CSI')
                || keycloak.hasRealmRole('RSOT') || keycloak.hasRealmRole('CSOT')
                || keycloak.hasRealmRole('CSM') || keycloak.hasRealmRole('OCD') || keycloak.hasRealmRole('RD')
            ) {
                const config = routeConfig.internalUser;
                setRouter(config.router);
                dispatch(setMenuItems(config.menuItems));
            }
            else {
                return
            }
        }
    }, [roles, keycloak.token])

    if (router === undefined) {
        return (<div>loading</div>)
    }

    return (
        <RouterProvider router={router} />
    );
}