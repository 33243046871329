import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { OptionType, PromiseStatuses } from "../../utils";
import { CodexSampleAssignDTO, CodexSampleChangeStatusEnum, CodexSampleCreationDTO, CodexSampleCreationResponseDTO, CodexSampleDTO, CodexSampleEditStatusDTO, GetAllCodexSampleDTO, GetAllCodexSampleFiltersDTO } from "./dataService/dto";
import { NewCodexSampleService } from "./dataService/service";

interface CodexSampleState {
    PMResources: OptionType[]

    // REQUEST
    codexSamplesCreationRequest: CodexSampleCreationDTO
    codexSamplesAssigmentRequest: CodexSampleAssignDTO
    codexSamplesEditRequest: CodexSampleEditStatusDTO

    // FILTERS
    getAllCodexSampleFilters: GetAllCodexSampleFiltersDTO

    // STATUS
    codexSamplesCreationStatus: PromiseStatuses
    codexSamplesAssigmentStatus: PromiseStatuses
    codexSamplesEditStatus: PromiseStatuses
    getAllCodexSamplesStatus: PromiseStatuses
    getCodexSampleByIdStatus: PromiseStatuses

    // RESPONSE
    codexSamplesCreationResponse?: CodexSampleCreationResponseDTO
    codexSamplesAssigmentResponse?: CodexSampleCreationResponseDTO
    codexSampleEditResponse?: CodexSampleCreationResponseDTO
    getAllCodexSampleResponse?: GetAllCodexSampleDTO
    getCodexSampleByIdResponse?: CodexSampleDTO
}

const initialState: CodexSampleState = {
    PMResources: [],

    // REQUEST
    codexSamplesCreationRequest: {},
    codexSamplesAssigmentRequest: {
        envelopNumbers: [],
        personalManagementId: '',
        entryProtocolId: '',
        status: CodexSampleChangeStatusEnum.DELIVERED
    },
    codexSamplesEditRequest: {
        envelopNumbers: []
    },

    // FILTERS
    getAllCodexSampleFilters: {
        itemsPerPage: 100,
        page: 0,
        order: true
    },

    // STATUS
    codexSamplesCreationStatus: 'idle',
    codexSamplesAssigmentStatus: 'idle',
    codexSamplesEditStatus: 'idle',
    getAllCodexSamplesStatus: 'idle',
    getCodexSampleByIdStatus: 'idle'
}

export const CodexSamplesCreation = createAsyncThunk(
    'codexSample/Creation',
    async (body: CodexSampleCreationDTO, thunkApi): Promise<CodexSampleCreationResponseDTO> => {
        const codexSampleService = NewCodexSampleService()
        return codexSampleService.CreateEnvelopeSamples(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const CodexSamplesAssignment = createAsyncThunk(
    'codexSample/Assignment',
    async (body: CodexSampleAssignDTO, thunkApi): Promise<CodexSampleCreationResponseDTO> => {
        const codexSampleService = NewCodexSampleService()
        return codexSampleService.AssignEnvelopeSamples(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const CodexSamplesEdit = createAsyncThunk(
    'codexSample/Edit',
    async (body: CodexSampleEditStatusDTO, thunkApi): Promise<CodexSampleCreationResponseDTO> => {
        const codexSampleService = NewCodexSampleService()
        return codexSampleService.EditEnvelopeSamplesStatus(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const GetAllCodexSamples = createAsyncThunk(
    'codexSample/getAll',
    async (filters: GetAllCodexSampleFiltersDTO, thunkApi): Promise<GetAllCodexSampleDTO> => {
        const codexSampleService = NewCodexSampleService()
        return codexSampleService.GetAllEnvelopeSamples(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const GetCodexSampleById = createAsyncThunk(
    'codexSample/getById',
    async (id: string, thunkApi): Promise<CodexSampleDTO> => {
        const codexSampleService = NewCodexSampleService()
        return codexSampleService.GetEnvelopeSampleById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

const CodexSampleSlice = createSlice({
    name: 'codexSample/slice',
    initialState,
    reducers: {
        resetPMResources: (state) => {
            state.PMResources = []
        },
        addPMResource: (state, action) => {
            state.PMResources.push(action.payload)
        },
        setPMResources: (state, action) => {
            state.PMResources = action.payload
        },

        // REQUEST [CREATION]
        setCodexSampleCreationFromEnvelopeNumber: (state, action) => {
            state.codexSamplesCreationRequest.fromEnvelopNumber = action.payload
        },
        setCodexSampleCreationToEnvelopeNumber: (state, action) => {
            state.codexSamplesCreationRequest.toEnvelopNumber = action.payload
        },
        resetCodexSampleCreationRequest: (state) => {
            state.codexSamplesCreationRequest = {
                fromEnvelopNumber: '',
                toEnvelopNumber: ''
            }
        },

        // REQUEST [ASSIGNMENT]
        setCodexSampleAssignmentEnvelopeNumbers: (state, action) => {
            state.codexSamplesAssigmentRequest.envelopNumbers = action.payload
        },
        setCodexSampleAssignmentNote: (state, action) => {
            state.codexSamplesAssigmentRequest.note = action.payload
        },
        setCodexSampleAssignmentPersonalManagementId: (state, action) => {
            state.codexSamplesAssigmentRequest.personalManagementId = action.payload
        },
        setCodexSampleAssignmentEntryProtocolId: (state, action) => {
            state.codexSamplesAssigmentRequest.entryProtocolId = action.payload
        },
        setCodexSampleAssignmentStatus: (state, action) => {
            state.codexSamplesAssigmentRequest.status = action.payload
        },
        resetCodexSampleAssignmentRequest: (state) => {
            state.codexSamplesAssigmentRequest = {
                envelopNumbers: [],
                personalManagementId: '',
                entryProtocolId: '',
                status: CodexSampleChangeStatusEnum.DELIVERED,
                note: ''
            }
        },

        // REQUEST [EDIT]
        setCodexSampleEditEnvelopeNumbers: (state, action) => {
            state.codexSamplesEditRequest.envelopNumbers = action.payload
        },
        setCodexSampleEditStatus: (state, action) => {
            state.codexSamplesEditRequest.status = action.payload
        },
        resetCodexSampleEditRequest: (state) => {
            state.codexSamplesEditRequest = {
                envelopNumbers: []
            }
        },

        // FILTERS
        setGetAllCodexSampleEntryProtocolId: (state, action) => {
            state.getAllCodexSampleFilters.entryProtocolId = action.payload
        },
        setGetAllCodexSampleEnvelopNumber: (state, action) => {
            state.getAllCodexSampleFilters.envelopNumber = action.payload
        },
        setGetAllCodexSampleItemsPerPage: (state, action) => {
            state.getAllCodexSampleFilters.itemsPerPage = action.payload
        },
        setGetAllCodexSampleOrder: (state, action) => {
            state.getAllCodexSampleFilters.order = action.payload
        },
        setGetAllCodexSamplePage: (state, action) => {
            state.getAllCodexSampleFilters.page = action.payload
        },
        setGetAllCodexSamplePersonalManagementId: (state, action) => {
            state.getAllCodexSampleFilters.personalManagementId = action.payload
        },
        setGetAllCodexSampleSort: (state, action) => {
            state.getAllCodexSampleFilters.sort = action.payload
        },
        setGetAllCodexSampleStatus: (state, action) => {
            state.getAllCodexSampleFilters.status = action.payload
        },
        resetGetAllCodexSampleFilters: (state) => {
            state.getAllCodexSampleFilters = {
                itemsPerPage: 100,
                page: 0,
                envelopNumber: '',
                order: true
            }
        },

        // STATUS
        resetCodexSamplesCreationStatus: (state) => {
            state.codexSamplesCreationStatus = 'idle'
        },
        resetCodexSamplesAssigmentStatus: (state) => {
            state.codexSamplesAssigmentStatus = 'idle'
        },
        resetCodexSamplesEditStatus: (state) => {
            state.codexSamplesEditStatus = 'idle'
        },
        resetGetAllCodexSamplesStatus: (state) => {
            state.getAllCodexSamplesStatus = 'idle'
        },
        resetGetCodexSamplebyIdStatus: (state) => {
            state.getCodexSampleByIdStatus = 'idle'
        },

        // RESPONSE
        resetCodexSamplesCreationResponse: (state) => {
            state.codexSamplesCreationResponse = undefined
        },
        resetCodexSampleAssigmentResponse: (state) => {
            state.codexSamplesAssigmentResponse = undefined
        },
        resetCodexSampleEditResponse: (state) => {
            state.codexSampleEditResponse = undefined
        },
    },
    extraReducers(builder) {
        builder

            // [CODEX SAMPLE]
            // CREATION
            .addCase(CodexSamplesCreation.pending, (state) => {
                state.codexSamplesCreationStatus = 'loading'
            })
            .addCase(CodexSamplesCreation.fulfilled, (state, action) => {
                state.codexSamplesCreationStatus = 'successfully'
                state.codexSamplesCreationResponse = action.payload
            })
            .addCase(CodexSamplesCreation.rejected, (state) => {
                state.codexSamplesCreationStatus = 'failed'
            })

            // ASSIGNMENT
            .addCase(CodexSamplesAssignment.pending, (state) => {
                state.codexSamplesAssigmentStatus = 'loading'
            })
            .addCase(CodexSamplesAssignment.fulfilled, (state, action) => {
                state.codexSamplesAssigmentStatus = 'successfully'
                state.codexSamplesAssigmentResponse = action.payload
            })
            .addCase(CodexSamplesAssignment.rejected, (state) => {
                state.codexSamplesAssigmentStatus = 'failed'
            })

            // EDIT
            .addCase(CodexSamplesEdit.pending, (state) => {
                state.codexSamplesEditStatus = 'loading'
            })
            .addCase(CodexSamplesEdit.fulfilled, (state, action) => {
                state.codexSamplesEditStatus = 'successfully'
                state.codexSampleEditResponse = action.payload
            })
            .addCase(CodexSamplesEdit.rejected, (state) => {
                state.codexSamplesEditStatus = 'failed'
            })

            // GET ALL
            .addCase(GetAllCodexSamples.pending, (state) => {
                state.getAllCodexSamplesStatus = 'loading'
            })
            .addCase(GetAllCodexSamples.fulfilled, (state, action) => {
                state.getAllCodexSamplesStatus = 'successfully'
                state.getAllCodexSampleResponse = action.payload
            })
            .addCase(GetAllCodexSamples.rejected, (state) => {
                state.getAllCodexSamplesStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetCodexSampleById.pending, (state) => {
                state.getCodexSampleByIdStatus = 'loading'
            })
            .addCase(GetCodexSampleById.fulfilled, (state, action) => {
                state.getCodexSampleByIdStatus = 'successfully'
                state.getCodexSampleByIdResponse = action.payload
            })
            .addCase(GetCodexSampleById.rejected, (state) => {
                state.getCodexSampleByIdStatus = 'failed'
            })
    },
})

export const {
    resetPMResources,
    setPMResources,
    addPMResource,

    // REQUEST
    setCodexSampleAssignmentEntryProtocolId,
    setCodexSampleAssignmentEnvelopeNumbers,
    setCodexSampleAssignmentNote,
    setCodexSampleAssignmentPersonalManagementId,
    setCodexSampleAssignmentStatus,
    setCodexSampleCreationFromEnvelopeNumber,
    setCodexSampleCreationToEnvelopeNumber,
    setCodexSampleEditEnvelopeNumbers,
    setCodexSampleEditStatus,
    resetCodexSampleAssignmentRequest,
    resetCodexSampleCreationRequest,
    resetCodexSampleEditRequest,

    // FILTERS
    setGetAllCodexSampleEntryProtocolId,
    setGetAllCodexSampleEnvelopNumber,
    setGetAllCodexSampleItemsPerPage,
    setGetAllCodexSampleOrder,
    setGetAllCodexSamplePage,
    setGetAllCodexSamplePersonalManagementId,
    setGetAllCodexSampleSort,
    setGetAllCodexSampleStatus,
    resetGetAllCodexSampleFilters,

    // STATUS
    resetCodexSamplesAssigmentStatus,
    resetCodexSamplesCreationStatus,
    resetCodexSamplesEditStatus,
    resetGetAllCodexSamplesStatus,
    resetGetCodexSamplebyIdStatus,

    // RESPONSE
    resetCodexSampleAssigmentResponse,
    resetCodexSampleEditResponse,
    resetCodexSamplesCreationResponse
} = CodexSampleSlice.actions

export default CodexSampleSlice.reducer