import { ComplianceRequestsCreateDTO, ComplianceRequestsDTO, ComplianceRequestStateDTO, ComplianceRequestsUpdateDTO, ComplianceResponseDTO, GetAllComplianceRequestsFiltersDTO } from "./dto";
import { CompilanceRequestServiceImpl } from "./serviceImpl";

export interface CompilanceRequestService {
    CreateCompilanceRequest(data: ComplianceRequestsCreateDTO): Promise<void>
    ComplianceRequestsState(data: ComplianceRequestStateDTO): Promise<void>
    GetAllCompilanceRequests(filters: GetAllComplianceRequestsFiltersDTO): Promise<ComplianceRequestsDTO>
    ComplianceRequestDelete(id: string): Promise<void>
    CompilanceRequestsDeleteState(id: string): Promise<void>
    GetByIdComplianceRequests(id: string): Promise<ComplianceResponseDTO>
    UpdateCompilanceRequest(data: ComplianceRequestsUpdateDTO, id: string): Promise<void>

}

export function NewCompilanceRequestService(): CompilanceRequestService {
    return new CompilanceRequestServiceImpl()
}