import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { Calendar } from "../../../../ui/molecules/calendar";
import { ProtocolSearch } from "../../../../ui/molecules/ProtocolSearch";
import { ProtocolSelectByObject } from "../../../../ui/molecules/ProtocolSelectByObj";
import { SelectCustom } from "../../../../ui/molecules/select";
import TextArea from "../../../../ui/molecules/textArea";
import { MeasureStatusOptions, RecourseResultOptions } from "../../../../utils/measuresUtils";
import { setOldCodexMeasureUpdateActiveFrom, setOldCodexMeasureUpdateClosingNote, setOldCodexMeasureUpdateClosingProtocolId, setOldCodexMeasureUpdateNextMeasureProtocolId, setOldCodexMeasureUpdatePresentationdate, setOldCodexMeasureUpdatePresentationProtocolId, setOldCodexMeasureUpdateRacProtocolId, setOldCodexMeasureUpdateReceiptProtocolId, setOldCodexMeasureUpdateRecourseDate, setOldCodexMeasureUpdateRecourseDetail, setOldCodexMeasureUpdateRecourseResult, setOldCodexMeasureUpdateResultProtocolId, setOldCodexMeasureUpdateStatus } from "./slice";

export function EditCodexMeasureRight() {
    const dispatch = useAppDispatch()
    const sessionState = useAppSelector(state => state.session)
    const oldCodexMeasure = useAppSelector(state => state.oldCodexMeasure)
    return (
        <div>
            <div style={{ padding: '24px 24px 32px 24px' }}>
                <div className='text-left'>
                    <span className='rid-title' style={{ color: '#4D797F' }}>Provvedimenti </span>
                    <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                    <span className='rid-title' style={{ color: '#314D51' }}> Stato</span>
                </div>

                <div className="text-left pt-5">
                    <span className="input-label">Attivo dal</span>
                </div>
                <Calendar
                    selected={oldCodexMeasure.oldCodexMeasureUpdateRequest.activeFrom}
                    onChange={(date) => { dispatch(setOldCodexMeasureUpdateActiveFrom(date)) }}
                />

                <div className="text-left pt-5">
                    <span className="input-label">Attivo al (data scadenza del Provvedimento)</span>
                </div>
                <Calendar
                    disabled={true}
                    onChange={() => { }}
                />

                <div className="text-left pt-5">
                    <span className="input-label">Protocollo Ricevuta</span>
                </div>
                <ProtocolSearch
                    value={oldCodexMeasure.oldCodexMeasureUpdateRequest.receiptProtocolId}
                    type="entry"
                    onSelect={(value) => { dispatch(setOldCodexMeasureUpdateReceiptProtocolId(value)) }}
                />

                <div className="text-left pt-5">
                    <span className="input-label">Stato</span>
                </div>
                <SelectCustom
                    value={oldCodexMeasure.oldCodexMeasureUpdateRequest.status}
                    placeholder={'Seleziona stato...'}
                    options={MeasureStatusOptions}
                    onChange={(value) => { dispatch(setOldCodexMeasureUpdateStatus(value)) }}
                />

                <div className="text-left pt-5">
                    <span className="input-label">Protocollo Chiusura (Entrata)</span>
                </div>
                <ProtocolSearch
                    type="entry"
                    value={oldCodexMeasure.oldCodexMeasureUpdateRequest.closingProtocolId}
                    onSelect={(value) => { dispatch(setOldCodexMeasureUpdateClosingProtocolId(value)) }}
                />

                <div className="text-left pt-5">
                    <span className="input-label">Rapporto Azione (R.A.C.)</span>
                </div>
                <ProtocolSearch
                    type="entry"
                    value={oldCodexMeasure.oldCodexMeasureUpdateRequest.racProtocolId}
                    onSelect={(value) => { dispatch(setOldCodexMeasureUpdateRacProtocolId(value)) }}
                />

                <div className="text-left pt-5">
                    <span className="input-label">Note</span>
                </div>
                <TextArea
                    value={oldCodexMeasure.oldCodexMeasureUpdateRequest.closingNote}
                    onChangeText={(text) => { dispatch(setOldCodexMeasureUpdateClosingNote(text)) }}
                />

                <div className="text-left pt-5">
                    <span className="input-label">Protocollo Provvedimento Successivo</span>
                </div>
                <div className="pb-5">
                    <ProtocolSelectByObject
                        type="departure"
                        value={oldCodexMeasure.oldCodexMeasureUpdateRequest.nextMeasureProtocolId}
                        objectId='16'
                        placeholder='Seleziona protocollo...'
                        customerId={sessionState.selected.customerId!}
                        onChange={(value) => dispatch(setOldCodexMeasureUpdateNextMeasureProtocolId(value))}
                    />
                </div>

                <div className='text-left pt-5' style={{ borderTopWidth: '2px' }}>
                    <span className='rid-title' style={{ color: '#4D797F' }}>Provvedimenti </span>
                    <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                    <span className='rid-title' style={{ color: '#314D51' }}> Gestione Ricorso</span>
                </div>

                <div className="text-left pt-5">
                    <span className="input-label">Data Presentazione</span>
                </div>
                <Calendar
                    selected={oldCodexMeasure.oldCodexMeasureUpdateRequest.presentationDate}
                    onChange={(date) => { dispatch(setOldCodexMeasureUpdatePresentationdate(date)) }}
                />

                <div className="text-left pt-5">
                    <span className="input-label">Protocollo Presentazione</span>
                </div>
                <ProtocolSearch
                    type="departure"
                    value={oldCodexMeasure.oldCodexMeasureUpdateRequest.presentationProtocolId}
                    onSelect={(value) => { dispatch(setOldCodexMeasureUpdatePresentationProtocolId(value)) }}
                />

                <div className="text-left pt-5">
                    <span className="input-label">Dettaglio Ricorso</span>
                </div>
                <TextArea
                    value={oldCodexMeasure.oldCodexMeasureUpdateRequest.recourseDetail}
                    onChangeText={(text) => { dispatch(setOldCodexMeasureUpdateRecourseDetail(text)) }}
                />

                <div className="text-left pt-5">
                    <span className="input-label">Esito</span>
                </div>
                <SelectCustom
                    // value={}
                    placeholder={'Seleziona stato...'}
                    value={oldCodexMeasure.oldCodexMeasureUpdateRequest.recourseResult}
                    options={RecourseResultOptions}
                    onChange={(value) => { dispatch(setOldCodexMeasureUpdateRecourseResult(value)) }}
                />

                <div className="text-left pt-5">
                    <span className="input-label">Data Esito</span>
                </div>
                <Calendar
                    selected={oldCodexMeasure.oldCodexMeasureUpdateRequest.recourseDate}
                    onChange={(date) => { dispatch(setOldCodexMeasureUpdateRecourseDate(date)) }}
                />

                <div className="text-left pt-5">
                    <span className="input-label">Protocollo Esito</span>
                </div>
                <div className="pb-5">
                    <ProtocolSearch
                        type="entry"
                        value={oldCodexMeasure.oldCodexMeasureUpdateRequest.receiptProtocolId}
                        onSelect={(value) => { dispatch(setOldCodexMeasureUpdateResultProtocolId(value)) }}
                    />
                </div>
            </div>
        </div>
    )
}