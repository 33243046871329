import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../utils";
import { ComplianceCustomerOtherOdcDTO, ComplianceRequestsCreateDTO, ComplianceRequestsDTO, ComplianceRequestsErrorsDTO, ComplianceRequestsStatesErrorsDTO, ComplianceRequestStateDetailsDTO, ComplianceRequestStateDTO, ComplianceRequestsUpdateDTO, ComplianceResponseDTO, GetAllComplianceRequestsFiltersDTO } from "./dataService/dto";
import { NewCompilanceRequestService } from "./dataService/service";
import { act } from "react";


interface ComplianceRequestsState {
    complianceRequestsCreation: ComplianceRequestsCreateDTO
    complianceRequestsUpdate: ComplianceRequestsUpdateDTO
    allComplianceRequestsResponse?: ComplianceRequestsDTO
    getComplianceRequestsByIdResponse?: ComplianceResponseDTO
    complianceRequestFilters: GetAllComplianceRequestsFiltersDTO
    ComplianceRequestStateCreation: ComplianceRequestStateDTO
    complianceRequestStates: ComplianceRequestStateDetailsDTO[]
    complianceRequestErrors: ComplianceRequestsErrorsDTO
    complianceRequestsStateErrors: ComplianceRequestsStatesErrorsDTO
    complianceRequestId: string
    fromCreationToUpdate: boolean
    complianceRequestErrorsStatus: PromiseStatuses
    complianceRequestCreationStatus: PromiseStatuses
    complianceRequestUpdateStatus: PromiseStatuses
    getAllComplianceRequestsStatus: PromiseStatuses
    getComplianceRequestByIdStatus: PromiseStatuses
    ComplianceRequestStateCreationStatus: PromiseStatuses
    ComplianceRequestStateDeleteStatus: PromiseStatuses
    complianceRequestsStateErrorsStatus: PromiseStatuses
    ComplianceRequestDeleteStatus: PromiseStatuses
    // REQUEST
}

const initialState: ComplianceRequestsState = {
    complianceRequestId: '',
    complianceRequestsCreation: {
        requestProtocolId: '',
        sender: '',
        description: '',
        requestType: '',
        otherOdcCustomer: [],
        officerId: '',
        customerDataId: ''
    },
    fromCreationToUpdate: false,
    complianceRequestErrors: {
        sender: false,
        date: false,
        description: false,
        requestProtocolId: false,
        requestType: false
    },
    complianceRequestsStateErrors: {
        date: false,
        state: false
    },
    complianceRequestStates: [],
    ComplianceRequestStateCreation: {
        state: '',
        date: new Date(),
    },
    complianceRequestsUpdate: {
        requestProtocolId: '',
        otherOdcCustomer: [],
        requestType: '',
    },
    complianceRequestCreationStatus: 'idle',
    complianceRequestErrorsStatus: 'idle',
    complianceRequestsStateErrorsStatus: 'idle',
    complianceRequestUpdateStatus: 'idle',
    getAllComplianceRequestsStatus: 'idle',
    getComplianceRequestByIdStatus: 'idle',
    ComplianceRequestStateCreationStatus: 'idle',
    ComplianceRequestStateDeleteStatus: 'idle',
    ComplianceRequestDeleteStatus: 'idle',
    complianceRequestFilters: {
        page: 0,
        itemsPerPage: 0
    }
}

export const ComplianceRequestsCreation = createAsyncThunk(
    'complianceRequests/Creation',
    async (body: ComplianceRequestsCreateDTO, thunkApi): Promise<void> => {
        const complianceRequestsService = NewCompilanceRequestService()

        return complianceRequestsService.CreateCompilanceRequest(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const ComplianceRequestsUpdate = createAsyncThunk(
    'complianceRequests/Update',
    async (request: { body: ComplianceRequestsUpdateDTO, id: string }, thunkApi): Promise<void> => {
        const complianceRequestsService = NewCompilanceRequestService()

        return complianceRequestsService.UpdateCompilanceRequest(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetByIdComplianceRequests = createAsyncThunk(
    'complianceRequests/GetByIdComplianceRequests',
    async (id: string, thunkApi): Promise<ComplianceResponseDTO> => {
        const complianceRequestsService = NewCompilanceRequestService()

        return complianceRequestsService.GetByIdComplianceRequests(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const ComplianceRequestDelete = createAsyncThunk(
    'complianceRequests/ComplianceRequestDelete',
    async (id: string, thunkApi): Promise<void> => {
        const complianceRequestsService = NewCompilanceRequestService()

        return complianceRequestsService.ComplianceRequestDelete(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const ComplianceRequestsDeleteState = createAsyncThunk(
    'complianceRequests/ComplianceRequestsDeleteState',
    async (id: string, thunkApi): Promise<void> => {
        const complianceRequestsService = NewCompilanceRequestService()

        return complianceRequestsService.CompilanceRequestsDeleteState(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllComplianceRequests = createAsyncThunk(
    'complianceRequests/getAll',
    async (filters: GetAllComplianceRequestsFiltersDTO, thunkApi): Promise<ComplianceRequestsDTO> => {
        const complianceRequestsService = NewCompilanceRequestService()

        return complianceRequestsService.GetAllCompilanceRequests(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const ComplianceRequestsState = createAsyncThunk(
    'ComplianceRequestsState/Creation',
    async (body: ComplianceRequestStateDTO, thunkApi): Promise<void> => {
        const complianceRequestsService = NewCompilanceRequestService()

        return complianceRequestsService.ComplianceRequestsState(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const ComplianceRequetsValidation = createAsyncThunk(
    'ComplianceRequestsState/Validation',
    async (request: ComplianceRequestsCreateDTO | ComplianceRequestsUpdateDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetcomplianceRequestErrors())

        if (request.description === '') {
            thunkApi.dispatch(setRequestDescriptionValidation(true))
            isValid = false
        }

        if (request.requestProtocolId === '') {
            thunkApi.dispatch(setRequestProtocolValidation(true))
            isValid = false
        }

        if (request.requestType === '') {
            thunkApi.dispatch(setRequestTypeValidation(true))
            isValid = false
        }

        if (request.sender === '') {
            thunkApi.dispatch(setRequestSenderValidation(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()

    }
)

export const ComplianceRequetsStatesValidation = createAsyncThunk(
    'ComplianceRequetsStatesValidation/Validation',
    async (request: ComplianceRequestStateDTO , thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetcomplianceRequestErrors())
        
        if (request.state === '') {
            thunkApi.dispatch(setRequestStateStateValidation(true))
            isValid = false
        }

        if (request.date ===  undefined) {
            thunkApi.dispatch(setRequestStateDateValidation(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()

    }
)

const ComplianceRequestsSlice = createSlice({
    name: 'ComplianceRequests/slice',
    initialState,
    reducers: {
        setFromCreationToUpdate: (state, action) => {
            state.fromCreationToUpdate = action.payload
        },
        // REQUEST [ComplianceRequests CREATION]
        setRequestProtocolId: (state, action) => {
            state.complianceRequestsCreation.requestProtocolId = action.payload
        },
        // setRequestDate: (state, action) => {
        //     state.complianceRequestsCreation.date = action.payload
        // },
        setRequestSender: (state, action) => {
            state.complianceRequestsCreation.sender = action.payload
        },
        setRequestType: (state, action) => {
            state.complianceRequestsCreation.requestType = action.payload
        },
        setRequestProduct: (state, action) => {
            state.complianceRequestsCreation.product = action.payload
        },
        setRequestOtherOdcCustomer: (state, action) => {
            state.complianceRequestsCreation.otherOdcCustomer = action.payload
        },
        setRequestDescription: (state, action) => {
            state.complianceRequestsCreation.description = action.payload
        },
        setRequestNote: (state, action) => {
            state.complianceRequestsCreation.note = action.payload
        },
        setRequestCustomerDataId: (state, action) => {
            state.complianceRequestsCreation.customerDataId = action.payload
        },
        setRequestOfficerId: (state, action) => {
            state.complianceRequestsCreation.officerId = action.payload
        },

        addCustomerOtherOdcDefault: (state) => {
            state.complianceRequestsCreation.otherOdcCustomer.push({
                companyName: '',
                cuaa: '',
                clientCode: ''
            } as ComplianceCustomerOtherOdcDTO)
        },

        setCompanyNameOtherOdcCreation: (state, action) => {
            state.complianceRequestsCreation.otherOdcCustomer[action.payload.index].companyName = action.payload.data
        },
        setCuaaOtherOdcCreation: (state, action) => {
            state.complianceRequestsCreation.otherOdcCustomer[action.payload.index].cuaa = action.payload.data
        },
        setClientCodeOtherOdcCreation: (state, action) => {
            state.complianceRequestsCreation.otherOdcCustomer[action.payload.index].clientCode = action.payload.data
        },

        setCreationCustomerOtherOdcDefault: (state, action) => {
            state.complianceRequestsCreation.otherOdcCustomer = action.payload
        },

        // UPDATE [ComplianceRequests: UPDATE].
        setEditCustomerOtherOdcDefault: (state, action) => {
            state.complianceRequestsUpdate.otherOdcCustomer = action.payload
        },

        setCompanyNameOtherOdcUpdate: (state, action) => {
            state.complianceRequestsUpdate.otherOdcCustomer[action.payload.index].companyName = action.payload.data
        },
        setCuaaOtherOdcUpdate: (state, action) => {
            state.complianceRequestsUpdate.otherOdcCustomer[action.payload.index].cuaa = action.payload.data
        },
        setClientCodeOtherOdcUpdate: (state, action) => {
            state.complianceRequestsUpdate.otherOdcCustomer[action.payload.index].clientCode = action.payload.data
        },

        addCustomerOtherOdcDefaultEdit: (state) => {
            state.complianceRequestsUpdate.otherOdcCustomer.push({
                companyName: '',
                cuaa: '',
                clientCode: ''
            } as ComplianceCustomerOtherOdcDTO)
        },
        
        setRequestId: (state, action) => {
            state.complianceRequestId = action.payload
        },
        setEditRequestProtocolId: (state, action) => {
            state.complianceRequestsUpdate.requestProtocolId = action.payload
        },
        setEditRequestDate: (state, action) => {
            state.complianceRequestsUpdate.date = action.payload
        },
        setEditRequestSender: (state, action) => {
            state.complianceRequestsUpdate.sender = action.payload
        },
        setEditRequestType: (state, action) => {
            state.complianceRequestsUpdate.requestType = action.payload
        },
        setEditRequestProduct: (state, action) => {
            state.complianceRequestsUpdate.product = action.payload
        },

        setEditRequestDescription: (state, action) => {
            state.complianceRequestsUpdate.description = action.payload
        },
        setEditRequestNote: (state, action) => {
            state.complianceRequestsUpdate.note = action.payload
        },
        //rightbar
        setRequestStateDetails: (state, action) => {
            state.complianceRequestStates = action.payload
        },
        setStatusRequest: (state, action) => {
            state.ComplianceRequestStateCreation.state = action.payload
        },
        setStatusDate: (state, action) => {
            state.ComplianceRequestStateCreation.date = action.payload
        },
        setStatusEntryProtocol: (state, action) => {
            state.ComplianceRequestStateCreation.entryProtocolId = action.payload
        },
        setStatusDepartureProtocol: (state, action) => {
            state.ComplianceRequestStateCreation.departureProtocolId = action.payload
        },
        setStatusNote: (state, action) => {
            state.ComplianceRequestStateCreation.note = action.payload
        },
        setStatusOfficer: (state, action) => {
            state.ComplianceRequestStateCreation.officerId = action.payload
        },
        setStatusComplianceRequestId: (state, action) => {
            state.ComplianceRequestStateCreation.complianceRequestId = action.payload
        },

        //FILTERS
        setRequestFilterCustomerId: (state, action) => {
            state.complianceRequestFilters.customerDataId = action.payload
        },

        setRequestFilterYear: (state, action) => {
            state.complianceRequestFilters.year = action.payload
        },
        setRequestFilterState: (state, action) => {
            state.complianceRequestFilters.state = action.payload
        },
        setRequestFilterSot: (state, action) => {
            state.complianceRequestFilters.headQuarter = action.payload
        },

        // ERRORS
        setRequestDateValidation: (state, action) => {
            state.complianceRequestErrors.date = action.payload
        },
        setRequestProtocolValidation: (state, action) => {
            state.complianceRequestErrors.requestProtocolId = action.payload
        },
        setRequestSenderValidation: (state, action) => {
            state.complianceRequestErrors.sender = action.payload
        },
        setRequestTypeValidation: (state, action) => {
            state.complianceRequestErrors.requestType = action.payload
        },
        setRequestDescriptionValidation: (state, action) => {
            state.complianceRequestErrors.description = action.payload
        },

        setRequestStateDateValidation: (state, action) => {
            state.complianceRequestsStateErrors.date = action.payload
        },

        setRequestStateStateValidation: (state, action) => {
            state.complianceRequestsStateErrors.state = action.payload
        },

        //RESET
        resetfilters: (state) => {
            state.complianceRequestFilters = {
                itemsPerPage: 0,
                page: 0
            }
        },

        resetCreateRequest: (state) => {
            state.complianceRequestsCreation = {
                requestProtocolId: '',
                otherOdcCustomer: [],
                requestType: '',
                sender: '',
                description: '',
                officerId: '',
                customerDataId: ''
            }
        },
        resetCreateStateRequest: (state) => {
            
            state.ComplianceRequestStateCreation = {
                state: undefined,
                date: new Date(),
                entryProtocolId: undefined,
                departureProtocolId: undefined,
                note: ''
            }
        },
        resetGetAllStatus: (state) => {
            state.getAllComplianceRequestsStatus = 'idle'
        },
        resetCreateStatus: (state) => {
            state.complianceRequestCreationStatus = 'idle'
        },
        resetUpdateStatus: (state) => {
            state.complianceRequestUpdateStatus = 'idle'
        },
        resetDeleteStateStatus: (state) => {
            state.complianceRequestUpdateStatus = 'idle'
        },
        resetGetByIdStateStatus: (state) => {
            state.getComplianceRequestByIdStatus = 'idle'
        },

        resetcomplianceRequestErrors: (state) => {
            state.complianceRequestErrors = {
                sender: false,
                requestProtocolId: false,
                requestType: false,
                date: false,
                description: false
            }
        },

        resetcomplianceRequestsStateErrors: (state) => {
            state.complianceRequestsStateErrors = {
                date: false,
                state: false
            }
        },

        resetComplianceRequestsValidationStatus: (state) => {
            state.complianceRequestErrorsStatus = 'idle'
        },

        resetComplianceRequestsStateValidationStatus: (state) => {
            state.complianceRequestsStateErrorsStatus = 'idle'
        },

        resetComplianceRequestsStateStatus: (state) => {
            state.ComplianceRequestStateCreationStatus = 'idle'
        },

    },
    extraReducers(builder) {
        builder
            // CREATION
            .addCase(ComplianceRequestsCreation.pending, (state) => {
                state.complianceRequestCreationStatus = 'loading'
            })
            .addCase(ComplianceRequestsCreation.fulfilled, (state) => {
                state.complianceRequestCreationStatus = 'successfully'
            })
            .addCase(ComplianceRequestsCreation.rejected, (state) => {
                state.complianceRequestCreationStatus = 'failed'
            })

            .addCase(ComplianceRequestsState.pending, (state) => {
                state.ComplianceRequestStateCreationStatus = 'loading'
            })
            .addCase(ComplianceRequestsState.fulfilled, (state) => {
                state.ComplianceRequestStateCreationStatus = 'successfully'
            })
            .addCase(ComplianceRequestsState.rejected, (state) => {
                state.ComplianceRequestStateCreationStatus = 'failed'
            })


            // UPDATE
            .addCase(GetAllComplianceRequests.pending, (state) => {
                state.getAllComplianceRequestsStatus = 'loading'
            })
            .addCase(GetAllComplianceRequests.fulfilled, (state, action) => {
                state.getAllComplianceRequestsStatus = 'successfully'
                state.allComplianceRequestsResponse = action.payload
            })
            .addCase(GetAllComplianceRequests.rejected, (state) => {
                state.getAllComplianceRequestsStatus = 'failed'
            })

            // DELETE
            .addCase(ComplianceRequestsDeleteState.pending, (state) => {
                state.ComplianceRequestStateDeleteStatus = 'loading'
            })
            .addCase(ComplianceRequestsDeleteState.fulfilled, (state, action) => {
                state.ComplianceRequestStateDeleteStatus = 'successfully'
            })
            .addCase(ComplianceRequestsDeleteState.rejected, (state) => {
                state.ComplianceRequestStateDeleteStatus = 'failed'
            })

            .addCase(ComplianceRequestDelete.pending, (state) => {
                state.ComplianceRequestDeleteStatus = 'loading'
            })
            .addCase(ComplianceRequestDelete.fulfilled, (state) => {
                state.ComplianceRequestDeleteStatus = 'successfully'
            })
            .addCase(ComplianceRequestDelete.rejected, (state) => {
                state.ComplianceRequestDeleteStatus = 'failed'
            })

            // GETALL
            .addCase(ComplianceRequestsUpdate.pending, (state) => {
                state.complianceRequestUpdateStatus = 'loading'
            })
            .addCase(ComplianceRequestsUpdate.fulfilled, (state, action) => {
                state.complianceRequestUpdateStatus = 'successfully'
            })
            .addCase(ComplianceRequestsUpdate.rejected, (state) => {
                state.complianceRequestUpdateStatus = 'failed'
            })

            // GETBYID
            .addCase(GetByIdComplianceRequests.pending, (state) => {
                state.getComplianceRequestByIdStatus = 'loading'
            })
            .addCase(GetByIdComplianceRequests.fulfilled, (state, action) => {
                state.getComplianceRequestByIdStatus = 'successfully'
                state.getComplianceRequestsByIdResponse = action.payload
            })
            .addCase(GetByIdComplianceRequests.rejected, (state) => {
                state.getComplianceRequestByIdStatus = 'failed'
            })

            // VALIDATION
            .addCase(ComplianceRequetsValidation.pending, (state) => {
                state.complianceRequestErrorsStatus = 'loading'
            })
            .addCase(ComplianceRequetsValidation.fulfilled, (state) => {
                state.complianceRequestErrorsStatus = 'successfully'
            })
            .addCase(ComplianceRequetsValidation.rejected, (state) => {
                state.complianceRequestErrorsStatus = 'failed'
            })

            .addCase(ComplianceRequetsStatesValidation.pending, (state) => {
                state.complianceRequestsStateErrorsStatus = 'loading'
            })
            .addCase(ComplianceRequetsStatesValidation.fulfilled, (state) => {
                state.complianceRequestsStateErrorsStatus = 'successfully'
            })
            .addCase(ComplianceRequetsStatesValidation.rejected, (state) => {
                state.complianceRequestsStateErrorsStatus = 'failed'
            })
    },
})

export const {
    // REQUEST
    setFromCreationToUpdate,
    setRequestCustomerDataId,
    // setRequestDate,
    setRequestDescription,
    setRequestNote,
    setRequestOfficerId,
    setRequestOtherOdcCustomer,
    setRequestProduct,
    setRequestProtocolId,
    setRequestSender,
    setRequestType,
    setCompanyNameOtherOdcCreation,
    setClientCodeOtherOdcCreation,
    setCuaaOtherOdcCreation,
    setCreationCustomerOtherOdcDefault,

    //update
    setRequestId,
    setEditRequestDate,
    setEditRequestDescription,
    setEditRequestNote,
    addCustomerOtherOdcDefault,
    setEditRequestProduct,
    setEditRequestProtocolId,
    setEditRequestSender,
    setEditRequestType,
    setEditCustomerOtherOdcDefault,
    addCustomerOtherOdcDefaultEdit,
    //right
    setClientCodeOtherOdcUpdate,
    setCompanyNameOtherOdcUpdate,
    setCuaaOtherOdcUpdate,
    setRequestStateDetails,
    setStatusRequest,
    setStatusDate,
    setStatusComplianceRequestId,
    setStatusDepartureProtocol,
    setStatusEntryProtocol,
    setStatusNote,
    setStatusOfficer,
    //filters
    setRequestFilterCustomerId,
    setRequestFilterSot,
    setRequestFilterState,
    setRequestFilterYear,

    //validation
    setRequestDateValidation,
    setRequestProtocolValidation,
    setRequestDescriptionValidation,
    setRequestSenderValidation,
    setRequestTypeValidation,
    setRequestStateStateValidation,
    setRequestStateDateValidation,

    //reset
    resetCreateRequest,
    resetGetAllStatus,
    resetUpdateStatus,
    resetCreateStatus,
    resetDeleteStateStatus,
    resetGetByIdStateStatus,
    resetcomplianceRequestErrors,
    resetComplianceRequestsValidationStatus,
    resetComplianceRequestsStateValidationStatus,
    resetComplianceRequestsStateStatus,
    resetCreateStateRequest,
    resetfilters

} = ComplianceRequestsSlice.actions

export default ComplianceRequestsSlice.reducer