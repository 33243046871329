import { Tab } from "react-tabs"
import { Layout } from "../../ui/layout"
import { HorizontalTab } from "../../ui/organisms/horizontalTab"
import { MenuItems } from "../../ui/organisms/navbar/dto"
import { useEffect, useState } from "react"
import clsx from 'clsx'
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks"
import { SchedaCalcoloList } from "./list"
import { SchedaCalcoloForms } from "./forms"
import { SchedaCalcoloPrint } from "./print"
import { Banner } from "../../ui/organisms/banner"
import { setOnEdit } from "./slice"

export function SchedaCalcoloAdmin() {
    const dispatch = useAppDispatch()
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const serviceAndBillingState = useAppSelector(state => state.billingAndServiceType)
    const [showError, setShowError] = useState<boolean>(false)

    useEffect(() => {
        if(serviceAndBillingState.billingWorksheetCreationStatus === 'failed') {
            setShowError(true)
        }
    }, [serviceAndBillingState.billingWorksheetCreationStatus])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Strumenti', 'Gestione Scheda di Calcolo']}
            noPaddingLat
            headerLabel="Gestione Scheda di Calcolo"
        >
            <HorizontalTab
                children={[
                    <>
                        <Tab
                            onClick={() => {
                                setSelectedTab(0)
                            }}
                            className={
                                clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                    "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": selectedTab === 0,
                                    "text-label-sm text-neutral-600": selectedTab !== 0
                                })
                            }
                            tabIndex={"0"}
                        >
                            Lista
                        </Tab>
                        <Tab
                            onClick={() => {
                                setSelectedTab(1)
                                dispatch(setOnEdit(false))
                            }}
                            className={
                                clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                    "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": selectedTab === 1,
                                    "text-label-sm text-brandSecondary-600": selectedTab !== 1
                                }
                                )
                            }
                            tabIndex={"1"}
                        >
                            Creazione/Modifica
                        </Tab>
                        <Tab
                            onClick={() => {
                                setSelectedTab(2)
                            }}
                            className={
                                clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                    "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": selectedTab === 2,
                                    "text-label-sm text-brandSecondary-600": selectedTab !== 2
                                }
                                )
                            }
                            tabIndex={"2"}
                        >
                            Stampa
                        </Tab>
                    </>
                ]}
            />
            {
                showError &&
                <Banner
                    type="error"
                    visible={serviceAndBillingState.billingWorksheetCreationStatus === 'failed'}
                    label={"Si è verificato un errore durante la creazione della scheda di calcolo"}
                    closeAction={function (): void {
                        setShowError(false)
                    }}
                />

            }
            <div className="fieldset bg-brandPrimary-200" style={{ padding: '12px 24px 24px 24px' }}>
                {
                    selectedTab === 0 &&
                    <SchedaCalcoloList />
                }
                {
                    selectedTab === 1 &&
                    <SchedaCalcoloForms />
                }
                {
                    selectedTab === 2 &&
                    <SchedaCalcoloPrint />
                }
            </div>
        </Layout>
    )
}