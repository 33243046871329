import { useEffect, useState } from "react"
import { InfoBox } from "../../../../../../ui/molecules/infoBox"
import { SelectCustom } from "../../../../../../ui/molecules/select"
import { OptionType } from "../../../../../../utils"
import TextArea from "../../../../../../ui/molecules/textArea"
import { Calendar } from "../../../../../../ui/molecules/calendar"
import Button from "../../../../../../ui/molecules/button"
import { nonMeasureMap } from "../../../../../../utils/measuresUtils"
import { AppliedMeasureDTO } from "../dataService/dto"
import { useAppDispatch, useAppSelector } from "../../../../../../lib/redux/hooks"
import { AppliedMeasureUpdate, resetAppliedMeasureUpdateRequest, resetAppliedMeasureUpdateStatus, setAppliedMeasureUpdateDetail, setAppliedMeasureUpdateEndingDate, setAppliedMeasureUpdateExpirationDate } from "../../../slice"

interface Props {
    close: () => void
    measure: AppliedMeasureDTO
    type: 'applied' | 'additional'
}

export function MeasureBoxEditPopup(props: Props) {
    const dispatch = useAppDispatch()
    const newMeasureState = useAppSelector(state => state.newMeasure)

    useEffect(() => {
        if(newMeasureState.appliedMeasureUpdateStatus === 'successfully') {
            dispatch(resetAppliedMeasureUpdateRequest())
            props.close()
        }
    }, [newMeasureState.appliedMeasureUpdateStatus])

    return (
        <div>
            <div style={{ padding: '24px 24px 18px 24px' }}>
                <div>
                    <InfoBox
                        color={props.type === 'applied' ? 'green' : 'orange'}
                        freeText={{
                            title: `Codice: ${props.measure.code}`,
                            label: props.measure.description
                        }}
                    />
                </div>
                <div>
                    <div className="text-left pt-5">
                        <span className="input-label">Dettaglio</span>
                    </div>
                    <TextArea
                        value={newMeasureState.appliedMeasureUpdateRequest.detail}
                        onChangeText={(text) => dispatch(setAppliedMeasureUpdateDetail(text))}
                    />
                </div>
                <div className="pt-5" style={{ display: 'flex', gap: '24px' }}>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Scadenza</span>
                        </div>
                        <Calendar
                            selected={newMeasureState.appliedMeasureUpdateRequest.expirationDate}
                            onChange={(date) => dispatch(setAppliedMeasureUpdateExpirationDate(date))}
                        />
                    </div>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Data Fine</span>
                        </div>
                        <Calendar
                            selected={newMeasureState.appliedMeasureUpdateRequest.endingDate}
                            onChange={(date) => dispatch(setAppliedMeasureUpdateEndingDate(date))}
                        />
                    </div>
                </div>
                <div className="pt-5" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        variant="solid"
                        color="orange"
                        size="md"
                        iconPosition="off"
                        label="Salva"
                        onClick={() => dispatch(AppliedMeasureUpdate({body: newMeasureState.appliedMeasureUpdateRequest, id: newMeasureState.appliedMeasureIdToUpdate}))}
                    />
                </div>
            </div>
        </div>
    )
}