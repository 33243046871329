import { useNavigate } from "react-router-dom"
import { EditIcon } from "../../../ui/icons/edit"
import { formatterDate } from "../../../utils"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { ReinforcedControlMeasureDTO } from "./dataService/dto"
import { setReinforcedUpdateActiveFrom, setReinforcedIdToUpdate, GetAllReinforcedControlMeasures, setReinforcedUpdateClosingDate, setReinforcedUpdateClosingMotivationNote, setReinforcedUpdateClosingNote, setReinforcedUpdateClosingProtocolId, setReinforcedUpdateDepartureProtocolIds, setReinforcedUpdateEntryProtocolIds, setReinforcedUpdateMotivationNote, setReinforcedUpdateMotivationType, setReinforcedUpdateNote } from "./slice"
import { useEffect } from "react"
import { handleProtocolListing } from "./utils"

export function ReinforcedList() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const reinforcedControlMeasureState = useAppSelector(state => state.reinforcedControlMeasure)

    useEffect(() => {
        dispatch(GetAllReinforcedControlMeasures(reinforcedControlMeasureState.getAllReinforcedControlMeasureFilters))
    }, [])

    const onEditHandle = (rcm: ReinforcedControlMeasureDTO) => {
        dispatch(setReinforcedUpdateActiveFrom(rcm.activeFrom))
        dispatch(setReinforcedUpdateMotivationType(rcm.motivationType))
        dispatch(setReinforcedUpdateMotivationNote(rcm.motivationNote))
        dispatch(setReinforcedUpdateEntryProtocolIds(rcm.entryProtocolsInfo ? rcm.entryProtocolsInfo.map(ep => ep.protocolId) : undefined))
        dispatch(setReinforcedUpdateDepartureProtocolIds(rcm.departureProtocolsInfo ? rcm.departureProtocolsInfo.map(dp => dp.protocolId) : undefined))
        dispatch(setReinforcedUpdateNote(rcm.note))
        dispatch(setReinforcedUpdateClosingDate(rcm.closingDate))
        dispatch(setReinforcedUpdateClosingMotivationNote(rcm.closingMotivationNote))
        dispatch(setReinforcedUpdateClosingProtocolId(rcm.closingProtocolId !== null ? rcm.closingProtocolId : undefined))
        dispatch(setReinforcedUpdateClosingNote(rcm.closingNote))
        dispatch(setReinforcedIdToUpdate(rcm.id))

        navigate('/editReinforcedControlMeasure')
    }

    return (
        <div style={{ padding: '24px' }}>
            <table id="users-table" className="w-[100%]">
                <thead className="h-[52px] uppercase">
                    <tr>
                        <th style={{ borderTopLeftRadius: '8px' }}></th>
                        <th>Cliente</th>
                        <th>Regione</th>
                        <th>Data attivazione</th>
                        <th>Documenti</th>
                        <th>Data chiusura</th>
                        <th>Motivazione chiusura</th>
                        <th>Comunicazione chiusura</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        reinforcedControlMeasureState.getAllReinforcedControlMeasureResponse &&
                        reinforcedControlMeasureState.getAllReinforcedControlMeasureResponse.data.map((rcm, index) =>
                            <tr
                                key={index}
                                style={{
                                    color: '#4D797F',
                                    background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                }}
                            >
                                <td>
                                    <div style={{ display: 'flex', gap: '6px' }}>
                                        <button onClick={() => onEditHandle(rcm)}>
                                            <EditIcon color="orange" size={20} />
                                        </button>
                                    </div>
                                </td>
                                <td>{rcm.customerCompanyName}</td>
                                <td>{rcm.customerCountryRegion}</td>
                                <td>{formatterDate(rcm.activeFrom)}</td>
                                <td>
                                    {handleProtocolListing(rcm.entryProtocolsInfo, rcm.departureProtocolsInfo)}
                                </td>
                                <td>{formatterDate(rcm.closingDate)}</td>
                                <td>{rcm.closingMotivationNote}</td>
                                <td>{rcm.closingProtocolInfo}</td>
                            </tr>
                        )}
                </tbody>
            </table>
        </div>
    )
}