import { useNavigate } from "react-router-dom"
import { Layout } from "../../../ui/layout"
import { MenuItems } from "../../../ui/organisms/navbar/dto"
import { useState } from "react"
import { HorizontalTab } from "../../../ui/organisms/horizontalTab"
import { Tab } from "react-tabs"
import clsx from "clsx"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { CustomerList } from "./customerList"
import { AllRequests } from "./allRequests"
import Button from "../../../ui/molecules/button"
import { SelectCustom } from "../../../ui/molecules/select"
import { sot, statoRichiestaComplianceRequest, yearOptions } from "../../../utils"
import { GetAllComplianceRequests, resetfilters, setRequestFilterSot, setRequestFilterState, setRequestFilterYear } from "./slice"
import { SOTFilter } from "../../sot"

export function ComplianceRequests() {
    const navigate = useNavigate()
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const sessionState = useAppSelector(state => state.session)
    const complianceRequestState = useAppSelector(state => state.complianceRequest)
    const dispatch = useAppDispatch()

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Certificazione', 'Richieste di Conformità']}
            noPaddingLat
            headerLabel="Richieste di Conformità"
            headerChildren={
                <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>
                    {
                        (sessionState.selected.customerId) &&
                        <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Crea nuova richiesta" color={"orange"}
                            disabled={!sessionState.selected.customerId}
                            onClick={() => {
                                navigate('/addComplianceRequest')
                            }}
                        />
                    }


                </div>
            }
        >
            <HorizontalTab
                children={[
                    <>
                        <Tab
                            onClick={() => {
                                setSelectedTab(0)
                            }}
                            className={
                                clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                    "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": selectedTab === 0,
                                    "text-label-sm text-neutral-600": selectedTab !== 0
                                })
                            }
                            tabIndex={"0"}
                        >
                            Elenco cliente
                        </Tab>
                        <Tab
                            onClick={() => {
                                setSelectedTab(1)
                            }}
                            className={
                                clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                    "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": selectedTab === 1,
                                    "text-label-sm text-brandSecondary-600": selectedTab !== 1
                                }
                                )
                            }
                            tabIndex={"1"}
                        >
                            Elenco completo
                        </Tab>
                    </>

                ]}
            />
            {
                (selectedTab === 1) && (
                    <div style={{ display: 'flex', padding: '12px 24px 0px 24px' }}>
                        <div style={{ display: 'flex', flex: '0.90', gap: '24px' }}>
                            <div style={{ flex: '0.3' }}>
                                <div className="text-left">
                                    <span className="input-label">Anno</span>
                                </div>
                                <SelectCustom
                                    key={complianceRequestState.complianceRequestFilters.year || 'default'}
                                    value={complianceRequestState.complianceRequestFilters.year}
                                    placeholder={'Anno'}
                                    options={yearOptions}
                                    onChange={(value) => dispatch(setRequestFilterYear(value))}
                                />
                            </div>
                            <div style={{ flex: '0.3' }}>
                                <div className="text-left">
                                    <span className="input-label">Sede</span>
                                </div>
                                <SOTFilter
                                    key={complianceRequestState.complianceRequestFilters.headQuarter || 'default'}
                                    value={complianceRequestState.complianceRequestFilters.headQuarter}
                                    // disabled={usersState.findMeResponse.headQuarterInfo?.code !== 'SC' && usersState.findMeResponse.role !== 'admin'}
                                    onChange={(value) => dispatch(setRequestFilterSot(value))}
                                />
                            </div>

                            <div style={{ flex: '0.3' }}>
                                <div className="text-left">
                                    <span className="input-label">Stato</span>
                                </div>
                                <SelectCustom
                                    placeholder={'Stato'}
                                    value={complianceRequestState.complianceRequestFilters.state}
                                    key={complianceRequestState.complianceRequestFilters.state || 'default'}
                                    options={statoRichiestaComplianceRequest}
                                    onChange={(value) => dispatch(setRequestFilterState(value))}
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', gap: '18px', alignItems: 'flex-end' }}>
                            <Button
                                size={"lg"}
                                iconPosition={"off"}
                                variant={"link"}
                                label="Pulisci"
                                color={"gray"}
                                onClick={() => dispatch(resetfilters())}
                            />
                            <Button
                                variant='outline'
                                color='blue'
                                size='md'
                                iconPosition='off'
                                label='Cerca'
                                onClick={() => dispatch(GetAllComplianceRequests(complianceRequestState.complianceRequestFilters))}
                            />
                        </div>
                    </div>
                )
            }
            <div className="fieldset bg-brandPrimary-200">
                {
                    selectedTab === 0 &&
                    <CustomerList />
                }
                {
                    selectedTab === 1 &&
                    <AllRequests />
                }
            </div>
        </Layout>
    )
}