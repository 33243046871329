import { useAppDispatch, useAppSelector } from '../../../../lib/redux/hooks'
import { ProtocolSearch } from '../../../../ui/molecules/ProtocolSearch'
import { ProtocolSelectByObject } from '../../../../ui/molecules/ProtocolSelectByObj'
import { SelectCustom } from '../../../../ui/molecules/select'
import TextArea from '../../../../ui/molecules/textArea'
import { ConversionPeriodStateOptions } from '../../../../utils'
import { ConversionPeriodState } from '../dataService/dto'
import { setConversionPeriodCreationActiveIngredientsFound, setConversionPeriodCreationArchivedRequestNote, setConversionPeriodCreationArchivedRequestProtocolId, setConversionPeriodCreationCanceledRequestNote, setConversionPeriodCreationCanceledRequestProtocolId, setConversionPeriodCreationInspectionVisitProtocolId, setConversionPeriodCreationLastNote, setConversionPeriodCreationNote, setConversionPeriodCreationRegionResponseProtocolId, setConversionPeriodCreationRequestProtocolId, setConversionPeriodCreationSamplingResult, setConversionPeriodCreationSendingDocumentationProtocolId, setConversionPeriodCreationTrialReportProtocolId, setConversionPeriodUpdateActiveIngredientsFound, setConversionPeriodUpdateArchivedRequestNote, setConversionPeriodUpdateArchivedRequestProtocolId, setConversionPeriodUpdateCanceledRequestNote, setConversionPeriodUpdateCanceledRequestProtocolId, setConversionPeriodUpdateInspectionVisitProtocolId, setConversionPeriodUpdateLastNote, setConversionPeriodUpdateNote, setConversionPeriodUpdateRegionResponseProtocolId, setConversionPeriodUpdateRequestProtocolId, setConversionPeriodUpdateSamplingResult, setConversionPeriodUpdateSendingDocumentationProtocolId, setConversionPeriodUpdateState, setConversionPeriodUpdateTrialReportProtocolId } from '../slice'
import '../styles.scss'

export function PeriodoConversioneEditForms() {
    const dispatch = useAppDispatch()
    const conversionPeriodState = useAppSelector(state => state.conversionPeriod)

    return (
        <div>
            <div className='pc-container'>
                <div className='pc-header'>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}>Protocollo Richiesta</span>
                    </div>
                </div>
                <div style={{padding: '24px 24px 32px 24px'}}>
                    <ProtocolSelectByObject
                        value={conversionPeriodState.conversionPeriodUpdateRequest.requestProtocolId}
                        objectId='91'
                        placeholder='Seleziona protocollo...'
                        type='entry'
                        onChange={(value) => dispatch(setConversionPeriodUpdateRequestProtocolId(value))}
                        />
                </div>
            </div>

            <div className='pc-container'>
                <div className='pc-header'>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}>Dettagli dei controlli effettuati</span>
                    </div>
                </div>

                <div style={{padding: '24px 24px 32px 24px'}}>
                    <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center'}}>
                        <div style={{flex: '0.33'}}>
                            <div className="text-left">
                                <span className="input-label">Visita Ispettiva</span>
                            </div>
                            <ProtocolSelectByObject
                                objectId='103'
                                customerId={conversionPeriodState.conversionPeriodCustomerId}
                                value={conversionPeriodState.conversionPeriodUpdateRequest.inspectionVisitProtocolId}
                                placeholder='Seleziona protocollo...'
                                type='entry'
                                onChange={(value) => dispatch(setConversionPeriodUpdateInspectionVisitProtocolId(value || undefined))}
                                />
                        </div>
                        <div style={{flex: '0.33'}}>
                            <div className="text-left">
                                <span className="input-label">Rapporto di Prova</span>
                            </div>
                            <ProtocolSelectByObject
                                objectId='63'
                                customerId={conversionPeriodState.conversionPeriodCustomerId}
                                value={conversionPeriodState.conversionPeriodUpdateRequest.trialReportProtocolId}
                                placeholder='Seleziona protocollo...'
                                type='entry'
                                onChange={(value) => dispatch(setConversionPeriodUpdateTrialReportProtocolId(value || undefined))}
                                />
                        </div>
                        <div style={{flex: '0.33'}}>
                            <div className="text-left">
                                <span className="input-label">Esito Prelievo</span>
                            </div>
                            <SelectCustom
                                placeholder={'Esito'}
                                value={conversionPeriodState.conversionPeriodUpdateRequest.samplingResult ? 'Positivo' : 'Negativo'}
                                options={[{value: 'Positivo', label: 'Positivo'}, {value: 'Negativo', label: 'Negativo'}]} 
                                onChange={(value) => dispatch(setConversionPeriodUpdateSamplingResult(value === 'Positivo'))}
                            />
                        </div>
                    </div>

                    <div>
                        <div className="text-left">
                            <span className="input-label">Principi attivi riscontrati</span>
                        </div>
                        <TextArea
                            value={conversionPeriodState.conversionPeriodUpdateRequest.activeIngredientsFound}
                            onChangeText={(value) => dispatch(setConversionPeriodUpdateActiveIngredientsFound(value))} />
                    </div>
                </div>
            </div>

            <div className='pc-container'>
                <div className='pc-header'>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}>Gestione Documentazione trasmessa alla Regione</span>
                    </div>
                </div>

                <div style={{padding: '24px 24px 32px 24px'}}>
                    <div className='pb-5' style={{borderBottomWidth: 1}}>
                        <ProtocolSearch
                            value={conversionPeriodState.conversionPeriodUpdateRequest.sendingDocumentationProtocolId || undefined}
                            label="Protocollo Invio Documentazione"
                            type="departure"
                            onSelect={(value) => dispatch(setConversionPeriodUpdateSendingDocumentationProtocolId(value))}
                        />
                    </div>
                    <div className='pt-3 pb-5'>
                        <ProtocolSearch
                            value={conversionPeriodState.conversionPeriodUpdateRequest.regionResponseProtocolId  || undefined}
                            label="Protocollo Risposta Regione"
                            type="entry"
                            onSelect={(value) => dispatch(setConversionPeriodUpdateRegionResponseProtocolId(value))}
                        />
                    </div>
                    <div className='pb-5' style={{borderBottomWidth: 1}}>
                        <div className="text-left">
                            <span className="input-label">Note</span>
                        </div>
                        <TextArea
                            value={conversionPeriodState.conversionPeriodUpdateRequest.note}
                            onChangeText={(value) => dispatch(setConversionPeriodUpdateNote(value))} />
                    </div>
                    <div className='pt-3 pb-5'>
                        <ProtocolSearch
                            value={conversionPeriodState.conversionPeriodUpdateRequest.canceledRequestProtocolId || undefined}
                            label="Richiesta Annullata dal Cliente"
                            type="entry"
                            onSelect={(value) => dispatch(setConversionPeriodUpdateCanceledRequestProtocolId(value))}
                        />
                    </div>
                    <div className='pb-5' style={{borderBottomWidth: 1}}>
                        <div className="text-left">
                            <span className="input-label">Note</span>
                        </div>
                        <TextArea
                            value={conversionPeriodState.conversionPeriodUpdateRequest.canceledRequestNote}
                            onChangeText={(value) => dispatch(setConversionPeriodUpdateCanceledRequestNote(value))} />
                    </div>
                    <div className='pt-3 pb-5'>
                        <ProtocolSearch
                            value={conversionPeriodState.conversionPeriodUpdateRequest.archivedRequestProtocolId || undefined}
                            label="Richiesta Archiviata da Codex S.r.l."
                            type="departure"
                            onSelect={(value) => dispatch(setConversionPeriodUpdateArchivedRequestProtocolId(value))}
                        />
                    </div>
                    <div className='pb-5' style={{borderBottomWidth: 1}}>
                        <div className="text-left">
                            <span className="input-label">Note</span>
                        </div>
                        <TextArea
                            value={conversionPeriodState.conversionPeriodUpdateRequest.archivedRequestNote}
                            onChangeText={(value) => dispatch(setConversionPeriodUpdateArchivedRequestNote(value))} />
                    </div>
                    <div className='pt-3 pb-5'>
                        <div className="text-left">
                            <span className="input-label">Stato / Esito</span>
                        </div>
                        <SelectCustom
                            placeholder={'Esito'}
                            onChange={(value) => dispatch(setConversionPeriodUpdateState(value))}
                            value={conversionPeriodState.conversionPeriodUpdateRequest.state}
                            options={ConversionPeriodStateOptions}
                        />
                    </div>
                    <div className='pb-5' style={{borderBottomWidth: 1}}>
                        <div className="text-left">
                            <span className="input-label">Note</span>
                        </div>
                        <TextArea
                            value={conversionPeriodState.conversionPeriodUpdateRequest.note}
                            onChangeText={(value) => dispatch(setConversionPeriodUpdateLastNote(value))} />
                    </div>
                </div>
            </div>
        </div>
    )
}