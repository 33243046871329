import axios from "axios";
import { GetAllReinforcedControlMeasureFiltersDTO, GetAllReinforcedControlMeasuresDTO, ReinforcedControlMeasureDTO, ReinforcedControlMeasuresCreationUpdateDTO } from "./dto";
import { ReinforcedControlMeasureService } from "./service";
import { keycloak } from "../../../../lib/keycloak";
import qs from "qs";

export class ReinforcedControlMeasureServiceImpl implements ReinforcedControlMeasureService {

    public CreateReinforcedControlMeasure(data: ReinforcedControlMeasuresCreationUpdateDTO): Promise<void> {
        return axios.post("/api/reinforced-control-measures",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateReinforcedControlMeasure(data: ReinforcedControlMeasuresCreationUpdateDTO, id: string): Promise<void> {
        return axios.put("/api/reinforced-control-measures/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteReinforcedControlMeasure(id: string): Promise<void> {
        return axios.delete("/api/reinforced-control-measures/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllReinforcedControlMeasures(filters: GetAllReinforcedControlMeasureFiltersDTO): Promise<GetAllReinforcedControlMeasuresDTO> {
        return axios.get("/api/reinforced-control-measures", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order,
                countryRegion: filters.countryRegion !== '' ? filters.countryRegion : undefined,
                headQuarter: filters.headQuarter ? [filters.headQuarter] : undefined,
                state: filters.customerDataId,
                year: filters.year
            },
            paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetReinforcedControlMeasureById(id: string): Promise<ReinforcedControlMeasureDTO> {
        return axios.get("/api/reinforced-control-measures/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}