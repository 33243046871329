import { Layout } from "../../../ui/layout";
import { MenuItems } from "../../../ui/organisms/navbar/dto";
import { RightBar } from "../../../ui/organisms/rightBar";
import { MeasureRecipientList } from "./list";
import { MeasureRecipientRight } from "./right";

export function MeasureRecipient() {
    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Strumenti', 'Gestione', 'Gestione Destinatari Provvedimento']}
            noPaddingLat
            headerLabel="Gestione Destinatari Provvedimento"
        >
            <div className="bg-brandPrimary-100" style={{ display: 'flex', height: '100%' }}>
                <MeasureRecipientList />

                <div className="bg-brandPrimary-100" style={{ flex: 0.3, borderTopRightRadius: '20px' }}>
                    <RightBar>
                        <MeasureRecipientRight />
                    </RightBar>
                </div>
            </div>
        </Layout>
    )
}