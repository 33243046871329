import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { EditIcon } from "../../../ui/icons/edit"
import { Banner } from "../../../ui/organisms/banner"
import { formatterDate } from "../../../utils"
import { ReinforcedControlMeasureDTO } from "./dataService/dto"
import { GetAllReinforcedControlMeasures, setReinforcedIdToUpdate, setReinforcedUpdateActiveFrom, setReinforcedUpdateClosingDate, setReinforcedUpdateClosingMotivationNote, setReinforcedUpdateClosingNote, setReinforcedUpdateClosingProtocolId, setReinforcedUpdateDepartureProtocolIds, setReinforcedUpdateEntryProtocolIds, setReinforcedUpdateMotivationNote, setReinforcedUpdateMotivationType, setReinforcedUpdateNote } from "./slice"
import { useEffect } from "react"
import { handleProtocolListing } from "./utils"

export function ReinforcedCustomerList() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const sessionState = useAppSelector(state => state.session)
    const reinforcedControlMeasureState = useAppSelector(state => state.reinforcedControlMeasure)

    useEffect(() => {
        dispatch(GetAllReinforcedControlMeasures({
            itemsPerPage: 0,
            page: 0,
            customerDataId: sessionState.selected.customerId || undefined
        }))
    }, [])

    const onEditHandle = (rcm: ReinforcedControlMeasureDTO) => {
        dispatch(setReinforcedUpdateActiveFrom(rcm.activeFrom))
        dispatch(setReinforcedUpdateMotivationType(rcm.motivationType))
        dispatch(setReinforcedUpdateMotivationNote(rcm.motivationNote))
        dispatch(setReinforcedUpdateEntryProtocolIds(rcm.entryProtocolsInfo ? rcm.entryProtocolsInfo.map(ep => ep.protocolId) : undefined))
        dispatch(setReinforcedUpdateDepartureProtocolIds(rcm.departureProtocolsInfo ? rcm.departureProtocolsInfo.map(dp => dp.protocolId) : undefined))
        dispatch(setReinforcedUpdateNote(rcm.note))
        dispatch(setReinforcedUpdateClosingDate(rcm.closingDate))
        dispatch(setReinforcedUpdateClosingMotivationNote(rcm.closingMotivationNote))
        dispatch(setReinforcedUpdateClosingProtocolId(rcm.closingProtocolId !== null ? rcm.closingProtocolId : undefined))
        dispatch(setReinforcedUpdateClosingNote(rcm.closingNote))
        dispatch(setReinforcedIdToUpdate(rcm.id))

        navigate('/editReinforcedControlMeasure')
    }

    if (sessionState.selected.customerId && sessionState.selected.customerNorm === 'NORMA_BIO') {
        return (
            <div style={{ padding: '24px' }}>
                <table id="users-table" className="w-[100%]">
                    <thead className="h-[52px] uppercase">
                        <tr>
                            <th style={{ borderTopLeftRadius: '8px' }}></th>
                            <th>Data attivazione</th>
                            <th>Documenti</th>
                            <th>Data chiusura</th>
                            <th>Motivazione chiusura</th>
                            <th>Comunicazione chiusura</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            reinforcedControlMeasureState.getAllReinforcedControlMeasureResponse &&
                            reinforcedControlMeasureState.getAllReinforcedControlMeasureResponse.data.map((rcm, index) =>
                                <tr
                                    key={index}
                                    style={{
                                        color: '#4D797F',
                                        background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                    }}
                                >
                                    <td>
                                        <div style={{ display: 'flex', gap: '6px' }}>
                                            <button onClick={() => onEditHandle(rcm)}>
                                                <EditIcon color="orange" size={20} />
                                            </button>
                                        </div>
                                    </td>
                                    <td>{formatterDate(rcm.activeFrom)}</td>
                                    <td>
                                        {handleProtocolListing(rcm.entryProtocolsInfo, rcm.departureProtocolsInfo)}
                                    </td>
                                    <td>{formatterDate(rcm.closingDate)}</td>
                                    <td>{rcm.closingMotivationNote}</td>
                                    <td>{rcm.closingProtocolInfo}</td>
                                </tr>
                            )}
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <Banner
            type="error"
            visible={!sessionState.selected.customerId || sessionState.selected.customerNorm !== 'NORMA_BIO'}
            label={"Devi prima selezionare un cliente con normativa Biologico per accedere all'area"}
            closeAction={function (): void {
                alert("Devi prima selezionare un cliente per accedere all'area")
            }}
        />
    )
}