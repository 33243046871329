import { useEffect, useState } from "react";
import { CustomRadio } from "../../ui/molecules/radioButton";
import { SelectCustom } from "../../ui/molecules/select";
import MultiSelectCheckbox from "../../ui/molecules/multiSelectCheckbox";
import { CodexSampleStatusOptions, optionsFormatter, OptionType } from "../../utils";
import Button from "../../ui/molecules/button";
import { TickIcon } from "../../ui/icons/TickIcon";
import Input from "../../ui/molecules/input/Input";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { CodexSamplesEdit, GetAllCodexSamples, resetCodexSamplesEditStatus, resetGetAllCodexSamplesStatus, setCodexSampleEditEnvelopeNumbers, setCodexSampleEditStatus, setGetAllCodexSampleEnvelopNumber, setGetAllCodexSamplePersonalManagementId, setGetAllCodexSampleStatus } from "./slice";
import { CodexSampleChangeStatusEnum } from "./dataService/dto";
import { RoundedCloseIcon } from "../../ui/icons/CloseIcon";

export function GestionePrelieviEdit() {
    const dispatch = useAppDispatch()
    const codexSampleState = useAppSelector(state => state.codexSample)
    const [selectionFlag, setSelectionFlag] = useState<string>('MULTIPLE')
    const [selectedEnvelopes, setSelectedEnvelopes] = useState<OptionType[]>([]);
    const [envelopeOptions, setEnvelopeOptions] = useState<OptionType[]>([])
    const [foundEnvelope, setFoundEnvelope] = useState<[boolean, string | null, string | null]>([false, null, null])

    const addZerosToNumber = (envelopNumber: string) => {
        return `${Array(7 - envelopNumber.length).fill('0').join('')}${envelopNumber}`
    }

    useEffect(() => {
        setSelectedEnvelopes([])
        dispatch(setCodexSampleEditEnvelopeNumbers([]))
        if (codexSampleState.getAllCodexSampleFilters.personalManagementId !== undefined &&
            codexSampleState.getAllCodexSampleFilters.status !== undefined
        ) {
            dispatch(GetAllCodexSamples({
                itemsPerPage: 0,
                page: 0,
                personalManagementId: codexSampleState.getAllCodexSampleFilters.personalManagementId,
                status: codexSampleState.getAllCodexSampleFilters.status
            }))
        }
    }, [codexSampleState.getAllCodexSampleFilters.personalManagementId,
    codexSampleState.getAllCodexSampleFilters.status
    ])

    useEffect(() => {
        if (codexSampleState.getAllCodexSamplesStatus === 'successfully' &&
            codexSampleState.getAllCodexSampleResponse !== undefined
        ) {
            dispatch(resetGetAllCodexSamplesStatus())
            if (selectionFlag === 'MULTIPLE') {
                const options = codexSampleState.getAllCodexSampleResponse.data.map(env => {
                    return {
                        value: String(env.envelopNumber),
                        label: addZerosToNumber(String(env.envelopNumber))
                    } as OptionType
                })

                setEnvelopeOptions(options)
                return
            }

            if (codexSampleState.getAllCodexSampleResponse.data.length > 0 &&
                codexSampleState.getAllCodexSampleResponse.data[0].envelopNumber !== undefined)
                setFoundEnvelope([true, String(codexSampleState.getAllCodexSampleResponse.data[0].envelopNumber), codexSampleState.getAllCodexSampleResponse.data[0].status])
        }
    }, [codexSampleState.getAllCodexSamplesStatus])

    useEffect(() => {
        if (codexSampleState.codexSamplesEditStatus === 'successfully') {
            setSelectedEnvelopes([])
            dispatch(setCodexSampleEditEnvelopeNumbers([]))
            if (selectionFlag === 'MULTIPLE') {
                dispatch(GetAllCodexSamples({
                    itemsPerPage: 0,
                    page: 0,
                    personalManagementId: codexSampleState.getAllCodexSampleFilters.personalManagementId,
                    status: codexSampleState.getAllCodexSampleFilters.status
                }))
            } else {
                dispatch(setCodexSampleEditEnvelopeNumbers([]))
                dispatch(setCodexSampleEditStatus(undefined))
                dispatch(setGetAllCodexSampleEnvelopNumber(''))
                setFoundEnvelope([false, null, null])
            }
        }
    }, [codexSampleState.codexSamplesEditStatus])

    useEffect(() => {
        if (selectionFlag === 'MULTIPLE') {
            dispatch(setGetAllCodexSampleEnvelopNumber(""))
            setFoundEnvelope([false, null, null])
        }
        else {
            dispatch(setGetAllCodexSamplePersonalManagementId(undefined))
            dispatch(setGetAllCodexSampleStatus(undefined))
            dispatch(setCodexSampleEditEnvelopeNumbers([]))
            setSelectedEnvelopes([])
        }
    }, [selectionFlag])

    useEffect(() => {
        if (selectionFlag === 'SINGLE' && codexSampleState.getAllCodexSampleFilters.envelopNumber !== '') {
            setFoundEnvelope([false, null, null])
            dispatch(GetAllCodexSamples({
                itemsPerPage: 1,
                page: 0,
                envelopNumber: codexSampleState.getAllCodexSampleFilters.envelopNumber
            }))
        }
    }, [codexSampleState.getAllCodexSampleFilters.envelopNumber])

    return (
        <div>
            <div className="prelievi-container">
                {/* HEADER */}
                <div className='prelievi-header'>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Buste Prelievo Campioni </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title uppercase' style={{ color: '#d9e6e8' }}> Modifica</span>
                    </div>
                </div>

                {/* CONTENT */}
                <div style={{ padding: '24px 24px 32px 24px' }}>

                    {/* MULTIPLE ENVELOPES */}
                    <div className='mb-5' style={{ display: 'flex', gap: '8px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CustomRadio
                                options={[{ label: '', value: 'MULTIPLE' }]}
                                selectedValue={selectionFlag}
                                onChange={(value) => setSelectionFlag(value)}
                                id={'multiple'}
                                name={'multiple'}
                            />
                        </div>
                        <div className='text-left'>
                            <span className='subtitle' style={{ color: '#5a8388' }}>Modifica Stato Buste Prelievo Campioni del Tecnico Ispettore</span>
                        </div>
                    </div>

                    <div className="ml-20">
                        <div className="pb-3" style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                            <div style={{ flex: '0.5' }}>
                                <div className="text-left">
                                    <span className="input-label">Tecnico Ispettore</span>
                                </div>
                                <SelectCustom
                                    key={codexSampleState.getAllCodexSampleFilters.personalManagementId || 'default'}
                                    value={codexSampleState.getAllCodexSampleFilters.personalManagementId}
                                    disabled={selectionFlag === 'SINGLE'}
                                    errorLabel='Ispettore mancante'
                                    placeholder={'Seleziona ispettore...'}
                                    options={codexSampleState.PMResources}
                                    onChange={(value) => dispatch(setGetAllCodexSamplePersonalManagementId(value))}
                                />
                            </div>
                            <div style={{ flex: '0.5' }}>
                                <div className="text-left">
                                    <span className="input-label">Stato Buste Prelievo Campione</span>
                                </div>
                                <SelectCustom
                                    key={codexSampleState.getAllCodexSampleFilters.status || 'default'}
                                    value={codexSampleState.getAllCodexSampleFilters.status}
                                    disabled={selectionFlag === 'SINGLE'}
                                    errorLabel='Stato mancante'
                                    placeholder={'Seleziona stato...'}
                                    options={CodexSampleStatusOptions}
                                    onChange={(value) => dispatch(setGetAllCodexSampleStatus(value))}
                                />
                            </div>
                        </div>

                        <div className="pb-5" style={{ borderBottomWidth: '1px' }}>
                            <div className="text-left">
                                <span className="input-label">Elenco Buste Prelievo Campione del Tecnico Ispettore</span>
                            </div>
                            <MultiSelectCheckbox
                                disabled={selectionFlag === 'SINGLE'}
                                errorLabel="Non è stata selezionata alcuna busta!"
                                onChange={(e) => {
                                    setSelectedEnvelopes(e.value)
                                    dispatch(setCodexSampleEditEnvelopeNumbers(e.value))
                                }}
                                value={selectedEnvelopes}
                                option={envelopeOptions}
                                placeholder=""
                                selectAllLabel="Tutte"
                            />
                        </div>

                        {
                            selectedEnvelopes.length > 0 && (
                                <div className="pb-3 pt-3" style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'flex-end' }}>
                                    <div style={{ flex: '0.5' }}>
                                        <div className="text-left">
                                            <span className="input-label">Nuovo Stato</span>
                                        </div>
                                        <SelectCustom
                                            disabled={selectionFlag === 'SINGLE'}
                                            errorLabel='Stato mancante'
                                            placeholder={'Seleziona nuovo stato...'}
                                            options={CodexSampleStatusOptions}
                                            onChange={(value) => dispatch(setCodexSampleEditStatus(value))}
                                        />
                                    </div>
                                    <Button
                                        disabled={selectionFlag === 'SINGLE'}
                                        iconPosition='left'
                                        icon={<TickIcon />}
                                        variant='outline'
                                        color='green'
                                        label='Assegna Nuovo Stato'
                                        size='lg'
                                        onClick={() => {
                                            dispatch(CodexSamplesEdit(codexSampleState.codexSamplesEditRequest))
                                        }}
                                    />
                                </div>
                            )
                        }

                        {
                            (codexSampleState.codexSamplesEditStatus === 'successfully' && selectionFlag === 'MULTIPLE') && (
                                <div>
                                    <div className="mt-4" style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className="prelievi-vertical-line" />
                                        <div className="pr-5" style={{ display: 'flex', gap: '24px' }}>
                                            <div className="text-left">
                                                <div className="prelievi-text">Stato buste correttamente modificato.</div>
                                            </div>
                                            <button onClick={() => dispatch(resetCodexSamplesEditStatus())}>
                                                <RoundedCloseIcon color="#166428" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                    {/* SINGLE ENVELOPE */}
                    <div className='mb-5 mt-10' style={{ display: 'flex', gap: '8px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CustomRadio
                                options={[{ label: '', value: 'SINGLE' }]}
                                selectedValue={selectionFlag}
                                onChange={(value) => setSelectionFlag(value)}
                                id={'single'}
                                name={'single'}
                            />
                        </div>
                        <div className='text-left'>
                            <span className='subtitle' style={{ color: '#5a8388' }}>Modifica Stato Busta Prelievo Campioni</span>
                        </div>
                    </div>

                    <div className="ml-20">
                        <div className="pb-3">
                            <div className="text-left">
                                <span className="input-label">Busta Prelievo Campioni n.</span>
                            </div>
                            <Input
                                value={codexSampleState.getAllCodexSampleFilters.envelopNumber}
                                disabled={selectionFlag === 'MULTIPLE'}
                                placeholder="Specifica n. busta..."
                                onChangeText={(text) => {
                                    dispatch(setGetAllCodexSampleEnvelopNumber(text))
                                    dispatch(setCodexSampleEditEnvelopeNumbers([text]))
                                }}
                            />
                        </div>

                        {
                            foundEnvelope[0] && (
                                <div>
                                    <div>
                                        <div className="mt-4" style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className="prelievi-vertical-line" />
                                            <div className="pr-5" style={{ display: 'flex', gap: '24px' }}>
                                                <div className="text-left">
                                                    <div className="prelievi-text-bold">Numero busta</div>
                                                    <div className="prelievi-text">{addZerosToNumber(foundEnvelope[1]!)}</div>
                                                </div>
                                                <div className="text-left">
                                                    <div className="prelievi-text-bold">Stato attuale</div>
                                                    <div className="prelievi-text">{optionsFormatter(foundEnvelope[2]!, CodexSampleStatusOptions)}</div>
                                                </div>
                                                <button onClick={() => {
                                                    setFoundEnvelope([false, null, null])
                                                    dispatch(setGetAllCodexSampleEnvelopNumber(''))
                                                    dispatch(setCodexSampleEditEnvelopeNumbers([]))
                                                }}>
                                                    <RoundedCloseIcon color="#166428" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', gap: '24px', alignItems: 'flex-end' }}>
                                        <div style={{ flex: '0.5' }}>
                                            <div className="text-left">
                                                <span className="input-label">Stato</span>
                                            </div>
                                            <SelectCustom
                                                key={codexSampleState.codexSamplesEditRequest.status || 'default'}
                                                value={codexSampleState.codexSamplesEditRequest.status}
                                                errorLabel='Stato mancante'
                                                placeholder={'Seleziona stato...'}
                                                options={CodexSampleStatusOptions}
                                                onChange={(value) => dispatch(setCodexSampleEditStatus(value))}
                                            />
                                        </div>
                                        <Button
                                            iconPosition='left'
                                            icon={<TickIcon />}
                                            variant='outline'
                                            color='green'
                                            label='Assegna Nuovo Stato'
                                            size='lg'
                                            onClick={() => dispatch(CodexSamplesEdit(codexSampleState.codexSamplesEditRequest))}
                                        />
                                    </div>
                                </div>
                            )
                        }

                        {
                            (codexSampleState.codexSamplesEditStatus === 'successfully' && selectionFlag === 'SINGLE') && (
                                <div>
                                    <div className="mt-4" style={{ display: 'flex', alignItems: 'center' }}>
                                        <div className="prelievi-vertical-line" />
                                        <div className="pr-5" style={{ display: 'flex', gap: '24px' }}>
                                            <div className="text-left">
                                                <div className="prelievi-text">Stato busta correttamente modificato.</div>
                                            </div>
                                            <button onClick={() => dispatch(resetCodexSamplesEditStatus())}>
                                                <RoundedCloseIcon color="#166428" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}