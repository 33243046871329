import { ReactNode, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks';
import { Layout } from "../../ui/layout";
import './style.scss';
import Input from "../../ui/molecules/input/Input";
import Button from "../../ui/molecules/button";
import { registerLocale } from "react-datepicker";
import { it } from 'date-fns/locale/it';
import "react-datepicker/dist/react-datepicker.css";
import { UserCreation, UserValidation, resetCreationStatusIdle, resetUserValidationStatus, setActivationDate, setEmail, setFiscalCode, setHeadQuarter, setIsMeasuresSignature, setName, setOffice, setOfficeProt, setPMId, setPec, setPhone, setRole, setSotProt, setSurname, setUsername } from "./slice";
import { format } from "date-fns";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { mapRole, officeOptions, OptionType, role, sot } from "../../utils";
import { SelectCustom } from "../../ui/molecules/select";
import { useNavigate } from "react-router-dom";
import { Banner } from "../../ui/organisms/banner";
import { Calendar } from "../../ui/molecules/calendar";
import RadioButtonGroup from "../../ui/molecules/RadioButtonGroup";
import { ArrowLeftIcon } from "../../ui/icons/arrow-left";
import { GetAllPersonalManagement, resetGetAllPersonalManagementResourcesStatus } from "../gestionePersonale/slice";
import { GetByIdAnagraphic, resetGetByIdStatus } from "../gestionePersonale/boxAnagrafica/slice";

registerLocale('it', it)

interface Props {
    children: ReactNode
}

interface PersonalResourceDTO {
    name: string
    surname: string
    fiscalCode: string
    email: string
    pec: string
    phone: string
    pmId: string
}

export function User() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const usersState = useAppSelector(state => state.users)
    const personalManagementState = useAppSelector(state => state.personalManagement)
    const anagraficaState = useAppSelector(state => state.PMAnagrafica)

    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(false)
    const [roleTable, setRoleTable] = useState('')
    const [roleRegione, setRoleRegione] = useState('')
    const [roleNorma, setRoleNorma] = useState('')
    const [roleSelect, setRoleSelect] = useState<string | boolean | undefined>(undefined)
    const [selectedOptionOfficeProt, setSelectedOptionOfficeProt] = useState<OfficeProtOptionsCode | null>(null);
    const [selectedOptionSotProt, setSelectedOptionSotProt] = useState<SotProtOptionsCode | null>(null);
    const [selectedIsMeasureSignature, setSelectedIsMeasureSignature] = useState<optionYesorNot | null>(null);
    const [personalResources, setPersonalResources] = useState<PersonalResourceDTO[]>([])
    const [selectedPR, setSelectedPR] = useState<PersonalResourceDTO | null>(null)

    useEffect(() => {
        dispatch(GetAllPersonalManagement({}))
    }, [])

    useEffect(() => {
        if (selectedPR === null)
            return

        dispatch(setName(selectedPR.name))
        dispatch(setSurname(selectedPR.surname))
        dispatch(setEmail(selectedPR.email))
        dispatch(setPhone(selectedPR.phone))
        dispatch(setFiscalCode(selectedPR.fiscalCode))
        dispatch(setPec(selectedPR.pec))
    }, [selectedPR])

    useEffect(() => {
        if (personalManagementState.getAllPersonalManagementResourcesStatus === 'successfully' &&
            personalManagementState.getAllPersonalManagementResourcesResponse !== undefined
        ) {
            dispatch(resetGetAllPersonalManagementResourcesStatus())

            for (const pm of personalManagementState.getAllPersonalManagementResourcesResponse.data) {
                if (pm.anagraphicId !== null)
                    dispatch(GetByIdAnagraphic(String(pm.anagraphicId)))
            }
        }
    }, [personalManagementState.getAllPersonalManagementResourcesStatus])

    useEffect(() => {
        if (anagraficaState.GetByIdAnagraphicStatus === 'successfully' &&
            anagraficaState.getByIdAnagraphicRequest !== undefined
        ) {
            dispatch(resetGetByIdStatus('idle'))
            const data = anagraficaState.getByIdAnagraphicRequest
            const newPR: PersonalResourceDTO = {
                name: data.name,
                surname: data.surname,
                fiscalCode: data.fiscalCode,
                email: data.firstEmail,
                pec: data.pec,
                phone: data.firstPhone,
                pmId: data.personalManagementId
            }
            const currPR = personalResources
            currPR.push(newPR)
            setPersonalResources(currPR)
        }
    }, [anagraficaState.GetByIdAnagraphicStatus])

    useEffect(() => {
        if (roleSelect != '' && roleSelect !== undefined && roleSelect) {
            mapRole.filter(({ role }) => role === roleSelect).map(el => {
                setRoleTable(el.label)
                setRoleRegione(el.regioni)
                setRoleNorma(el.normativa)
            })
        }


    }, [roleSelect])

    useEffect(() => {
        if (usersState.userValidationStatus === 'successfully') {
            dispatch(resetUserValidationStatus())
            dispatch(UserCreation(usersState.usersEditCreateRequest))
        }
    }, [usersState.userValidationStatus])

    enum SotProtOptionsCode {
        SI = 'SI',
        NO = 'NO',
    }

    enum OfficeProtOptionsCode {
        SI = 'SI',
        NO = 'NO',
    }

    enum optionYesorNot {
        SI = 'SI',
        NO = 'NO',
    }

    const handleOptionOfficeProtChange = (value: OfficeProtOptionsCode) => {
        setSelectedOptionOfficeProt(value);
        //dispatch(setCodeType(value))
        if (value === 'SI') {
            //setCodeDisabled(false)
            dispatch(setOfficeProt(true))
        }
        else {
            //dispatch(setExistingCode(''))
            dispatch(setOfficeProt(false))
        }
    };

    const handleOptionSotProtChange = (value: SotProtOptionsCode) => {
        setSelectedOptionSotProt(value);
        //dispatch(setCodeType(value))
        if (value === 'SI') {
            //setCodeDisabled(false)
            dispatch(setSotProt(true))
        }
        else {
            //dispatch(setExistingCode(''))
            dispatch(setSotProt(false))
        }
    };

    const handleOptionIsMeasureSignature = (value: optionYesorNot) => {
        setSelectedIsMeasureSignature(value);
        if (value === 'SI') {
            //setCodeDisabled(false)
            dispatch(setIsMeasuresSignature(true))
        }
        else {
            //dispatch(setExistingCode(''))
            dispatch(setIsMeasuresSignature(false))
        }
    };

    useEffect(() => {
        if (usersState.userCreationStatus === 'successfully') {

            setShowSuccess(true)
            dispatch(resetCreationStatusIdle())
            navigate('/users/')
        }
        else {
            setShowSuccess(false)
        }

        if (usersState.userCreationStatus === 'failed') {

            setShowError(true)
            dispatch(resetCreationStatusIdle())
        }
        else {
            setShowError(false)
        }

    }, [usersState.userCreationStatus])


    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Utenti', 'Nuovo Utente']}
            headerLabel="Nuovo Utente"
            rightBar={true}
            headerIcon={
                <button style={{ paddingTop: '12px' }} onClick={() => navigate('/users')}>
                    <ArrowLeftIcon />
                </button>
            }
            headerChildren={
                <div className="text-left flex flex-row justify-end" style={{ padding: '16px', justifyContent: 'end' }}>

                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                        onClick={() => {
                            dispatch(UserValidation(usersState.usersEditCreateRequest))
                        }}
                    />

                </div>

            }
        >

            <div style={{ display: 'flex', height: '100%' }}>
                <div className="fieldset rounded-lg" style={{ flex: 0.7 }}>
                    {
                        showSuccess &&

                        <div className="gap-[8px] text-left bg-green-300" style={{ margin: '24px', borderRadius: '8px' }}>
                            <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                                Dati salvati correttamente.
                            </span>
                        </div>

                    }
                    <Banner
                        type="error"
                        visible={usersState.userCreationStatus === 'failed'}
                        label={"Si è verificato un errore durante la creazione dell'utente"}
                        closeAction={function (): void {
                            dispatch(resetCreationStatusIdle())
                        }}
                    />


                    <div className="gap-[8px]" style={{ background: '#4D797F', margin: '24px', borderRadius: '8px' }}>
                        <>
                            <div className="w-full text-left boxFieldsetTitle" style={{ flex: 1, padding: '16px 24px 16px  24px' }}>
                                <span className="text-white fieldsetTitle text-uppercase">Credenziali</span>
                            </div>
                            <div style={{ display: 'flex', gap: '16px', padding: '24px', background: '#F2F7F7' }}>
                                <div style={{ flex: 0.5 }}>
                                    <Input
                                        label={'Username'}
                                        placeholder="Username"
                                        error={usersState.createUserErrors.username}
                                        supportingText={usersState.createUserErrors.username ? 'Username mancante' : undefined}
                                        onChangeText={(text) => dispatch(setUsername(text))}
                                    />
                                </div>

                            </div>
                        </>

                    </div>

                    <div className="gap-[8px]" style={{ background: '#4D797F', margin: '24px', borderRadius: '8px' }}>
                        <>
                            <div className="w-full text-left boxFieldsetTitle" style={{ flex: 1, padding: '16px 24px 16px  24px' }}>
                                <span className="text-white text-uppercase fieldsetTitle">Anagrafica</span>
                            </div>
                            <div style={{ display: 'flex', gap: '16px', padding: '24px', background: '#F2F7F7' }}>
                                <div style={{ flex: 0.3 }}>
                                    <Input
                                        value={usersState.usersEditCreateRequest.name}
                                        supportingText={usersState.createUserErrors.name ? "Inserire il nome." : undefined}
                                        error={usersState.createUserErrors.name} label={'Nome'}
                                        placeholder="Nome"
                                        onChangeText={(text) => dispatch(setName(text))}
                                    />
                                </div>
                                <div style={{ flex: 0.4 }}>
                                    <Input
                                        value={usersState.usersEditCreateRequest.surname}
                                        supportingText={usersState.createUserErrors.surname ? "Inserire il cognome." : undefined}
                                        error={usersState.createUserErrors.surname}
                                        label={'Cognome'}
                                        placeholder="Cognome"
                                        onChangeText={(text) => dispatch(setSurname(text))}
                                    />
                                </div>
                                <div style={{ flex: 0.4 }}>
                                    <Input
                                        value={usersState.usersEditCreateRequest.fiscalCode}
                                        supportingText={usersState.createUserErrors.fiscalCode ? "Inserire il codice fiscale (15 caratteri)." : undefined}
                                        error={usersState.createUserErrors.fiscalCode}
                                        label={'Codice Fiscale'}
                                        placeholder="Codice Fiscale"
                                        onChangeText={(text) => dispatch(setFiscalCode(text))}
                                    />
                                </div>
                            </div>

                            <div style={{ display: 'flex', gap: '16px', padding: '24px', background: '#F2F7F7' }}>
                                <div style={{ flex: 0.4 }}>
                                    <Input
                                        value={usersState.usersEditCreateRequest.email}
                                        supportingText={usersState.createUserErrors.email ? "Inserire email" : undefined}
                                        error={usersState.createUserErrors.email}
                                        label={'Email'}
                                        placeholder="Email"
                                        onChangeText={(text) => dispatch(setEmail(text))}
                                    />
                                </div>
                                <div style={{ flex: 0.4 }}>
                                    <Input
                                        value={usersState.usersEditCreateRequest.pec}
                                        label={'PEC'}
                                        placeholder="PEC"
                                        error={usersState.createUserErrors.pec}
                                        supportingText={usersState.createUserErrors.pec ? 'Pec mancante' : undefined}
                                        onChangeText={(text) => dispatch(setPec(text))}
                                    />
                                </div>
                                <div style={{ flex: 0.4 }}>
                                    <Input
                                        value={usersState.usersEditCreateRequest.phone}
                                        label={'Cellulare'}
                                        placeholder="Cellulare"
                                        error={usersState.createUserErrors.phone}
                                        supportingText={usersState.createUserErrors.phone ? 'Deve essere compreso tra 7 e 15 caratteri' : undefined}
                                        onChangeText={(text) => dispatch(setPhone(text))}
                                    />
                                </div>
                            </div>
                        </>

                    </div>

                </div>
                <div className="bg-brandPrimary-100" style={{ flex: 0.4, padding: '24px', borderLeft: '1px solid #E4E4F7' }}>
                    <div style={{ display: 'flex', gap: '24px', flexDirection: 'column' }}>
                        <div style={{ flex: 1 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                <span className="input-label mb-1">Sede</span>
                            </div>
                            <SelectCustom
                                placeholder={'Sede'}
                                error={usersState.createUserErrors.sot}
                                errorLabel={'Sede mancante'}
                                options={sot}
                                onChange={(value) => {
                                    dispatch(setHeadQuarter(value))
                                }}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                <span className="input-label mb-1">Ufficio</span>
                            </div>
                            <SelectCustom
                                placeholder={'Ufficio'}
                                options={officeOptions}
                                onChange={(value) => {
                                    dispatch(setOffice(value))
                                }}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                <span className="input-label mb-1">Personale</span>
                            </div>
                            <SelectCustom
                                placeholder={'Personale'}
                                options={personalResources.map(pr => {
                                    return { value: pr.pmId, label: `${pr.name} ${pr.surname}` } as OptionType
                                })}
                                onChange={(value) => {
                                    setSelectedPR(personalResources.find(pr => pr.pmId === value)!)
                                    dispatch(setPMId(value))
                                }}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <SelectCustom
                                placeholder={'Ruolo'}
                                options={role}
                                error={usersState.createUserErrors.role}
                                errorLabel={'Ruolo mancante'}
                                onChange={(value) => {
                                    dispatch(setRole(value))
                                    setRoleTable('')
                                    setRoleRegione('')
                                    setRoleNorma('')
                                    setRoleSelect(value)
                                }}
                            />

                        </div>
                        <div>
                            <table className="reviewPermissionTable">
                                <tr>
                                    <td className="label" style={{ borderTopLeftRadius: '8px' }}>Ufficio</td>
                                    <td className="value" >
                                        {roleTable}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="label">Normativa</td>
                                    <td className="value">{roleNorma}</td>
                                </tr>
                                <tr>
                                    <td className="label">Regioni</td>
                                    <td className="value">{roleRegione}</td>
                                </tr>
                            </table>
                        </div>
                        <div style={{ display: 'flex', textAlign: 'left', gap: '8px', flexDirection: 'column' }}>
                            <div>
                                <span className="text-uppercase text-brandPrimary-600 label-input">Data Attivazione</span>
                            </div>
                            <div>
                                <Calendar
                                    errorLabel={usersState.createUserErrors.activationDate ? 'Data di attivazione mancante' : undefined}
                                    selected={usersState.usersEditCreateRequest.activationDate}
                                    onChange={(date) => {
                                        dispatch(setActivationDate(format(new Date(date), 'yyyy-MM-dd')))
                                    }}
                                />
                            </div>

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '24px' }}>
                            <div>
                                <span className="input-label" style={{ fontWeight: 'bold' }}> Firma Provvedimento: </span>
                            </div>
                            <div>
                                <RadioButtonGroup
                                    name={'is_measure_signature'}
                                    options={Object.values(optionYesorNot)}
                                    selectedValue={selectedIsMeasureSignature}
                                    onChange={handleOptionIsMeasureSignature}
                                    optionLabels={{
                                        [optionYesorNot.SI]: 'Si',
                                        [optionYesorNot.NO]: 'No',
                                    }}
                                />
                            </div>
                        </div>
                        {
                            (usersState.usersEditCreateRequest.headQuarterId || usersState.usersEditCreateRequest.office) ? (
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                    <span className="input-label" style={{ fontWeight: '700', fontSize: '16px' }}>Gestione Protocollo</span>
                                </div>
                            ) : null
                        }

                        {
                            usersState.usersEditCreateRequest.headQuarterId ? (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '24px' }}>
                                    <div>
                                        <span className="input-label" style={{ fontWeight: 'bold' }}>Sede:  </span>
                                        <span className="input-label">
                                            {
                                                sot.filter(({ value }) => value === usersState.usersEditCreateRequest.headQuarterId).map(el =>
                                                    <span> {el.label}</span>
                                                )
                                            }
                                        </span>
                                    </div>
                                    <RadioButtonGroup
                                        name={'prot_sot_able'}
                                        options={Object.values(SotProtOptionsCode)}
                                        selectedValue={selectedOptionSotProt}
                                        onChange={handleOptionSotProtChange}
                                        optionLabels={{
                                            [SotProtOptionsCode.SI]: 'Si',
                                            [SotProtOptionsCode.NO]: 'No',
                                        }}
                                    />
                                </div>
                            ) : null
                        }

                        {
                            usersState.usersEditCreateRequest.office ? (
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '24px' }}>
                                    <div>
                                        <span className="input-label" style={{ fontWeight: 'bold' }}> Ufficio: </span>
                                        <span className="input-label">
                                            {
                                                officeOptions.filter(({ value }) => value === usersState.usersEditCreateRequest.office).map(el =>
                                                    <span> {el.label}</span>
                                                )
                                            }
                                        </span>
                                    </div>
                                    <div>
                                        <RadioButtonGroup
                                            name={'prot_office_able'}
                                            options={Object.values(OfficeProtOptionsCode)}
                                            selectedValue={selectedOptionOfficeProt}
                                            onChange={handleOptionOfficeProtChange}
                                            optionLabels={{
                                                [OfficeProtOptionsCode.SI]: 'Si',
                                                [OfficeProtOptionsCode.NO]: 'No',
                                            }}
                                        />
                                    </div>
                                </div>
                            ) : null
                        }


                    </div>

                </div>
            </div>



        </Layout>
    )
}