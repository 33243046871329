import { useNavigate } from "react-router-dom"
import Button from "../../../../ui/molecules/button"
import { useEffect, useState } from "react"
import { EditIcon } from "../../../../ui/icons/edit"
import { PopupForm } from "../../../../ui/organisms/popupForm"
import { EditLiberatoria } from "../edit"
import { AddLiberatoria } from "../add"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { DownloadWaiverToCodexDoc, GetAllWaiverToCodex, GetWaivertoExcel, resetWaiverCreateStatus, resetWaiverFilters, resetWaiverRequest, resetWaiverUpdateStatus, setAllowedDepartureProtocolId, setBreakoutDate, setCompletePassing, setCustomerId, setDeniedDepartureProtocolId, setEntryProtocol, setExpirationDate, setFieldsPassing, setIdWaiver, setNote, setNotificationChangeProtocolId, setOdc, setProtocolDate, setSenderOrReceiver, setSendingDossier, setStatusFilter, setSubmissionNotificationOdcDate, setYearFilter } from "./dataService/slice"
import { formatterDate, statusLiberatoria, yearOptions } from "../../../../utils"
import { SelectCustom } from "../../../../ui/molecules/select"
import clsx from "clsx"
import '../style.scss';
import { SpinnerComponent } from "../../../../lib/spinner"
import { DownloadIcon } from "../../../../ui/icons/DownloadIcon"
import { LogoSpinnerComponent } from "../../../../lib/spinner/spinnerLogo"

export function LiberatorieToCodex() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [showPopupAdd, setShowPopupAdd] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const waiverToState = useAppSelector(state => state.waiverToCodex)

    useEffect(() => {
        const currentYear = new Date().getFullYear().toString()
        dispatch(GetAllWaiverToCodex({
            page: 0,
            itemsPerPage: 0,
            year: currentYear
        }))
    }, [waiverToState.getAllWaiverToFilters])

    useEffect(() => {
        if (waiverToState.CreateWaiverToCodexStatus === 'successfully') {
            dispatch(GetAllWaiverToCodex(waiverToState.getAllWaiverToFilters))
            dispatch(resetWaiverUpdateStatus())
            setShowPopupAdd(false)
            dispatch(resetWaiverRequest())
            setShowSuccess(true)
            setTimeout(() => {
                setShowSuccess(false)
            }, 2500);
        }
    }, [waiverToState.CreateWaiverToCodexStatus])

    useEffect(() => {
        if (waiverToState.UpdateWaiverToCodexStatus === 'successfully') {
            dispatch(GetAllWaiverToCodex(waiverToState.getAllWaiverToFilters))
            dispatch(resetWaiverCreateStatus())
            dispatch(resetWaiverRequest())
            setShowPopup(false)
            setShowSuccess(true)
            setTimeout(() => {
                setShowSuccess(false)
            }, 2500);
        }
    }, [waiverToState.UpdateWaiverToCodexStatus])

    const handleOnEdit = (w: any) => {
        dispatch(setCompletePassing(w.completePassing))
        dispatch(setFieldsPassing(w.fieldsPassing))
        dispatch(setAllowedDepartureProtocolId(w.allowedDepartureProtocolId))
        dispatch(setDeniedDepartureProtocolId(w.deniedDepartureProtocolId))
        dispatch(setBreakoutDate(w.breakoutDate))
        dispatch(setExpirationDate(w.expirationDate))
        dispatch(setSendingDossier(w.sendingDossier))
        dispatch(setNote(w.note))
        dispatch(setOdc(w.odcReference))
        dispatch(setEntryProtocol(w.entryProtocolId))
        dispatch(setCustomerId(w.customerId))
        dispatch(setSenderOrReceiver(w.waiverInnerInfo.senderOrReceiver))
        dispatch(setProtocolDate(w.waiverInnerInfo.protocolDate))
        dispatch(setNotificationChangeProtocolId(w.notificationChangeProtocolId))
        dispatch(setSubmissionNotificationOdcDate(w.submissionNotificationOdcDate))
        dispatch(setIdWaiver(w.id))
        setShowPopup(true)
    }


    if (waiverToState.downloadDocStatus === 'loading') {
        return (
            <LogoSpinnerComponent fullScreen />
        )
    }

    if (waiverToState.GetAllWaiverToCodexStatus === 'loading') {
        return (
            <SpinnerComponent size={'large'} />
        )
    }

    if (waiverToState.downloadExcelStatus === 'loading') {
        return (
            <SpinnerComponent size={'large'} />
        )
    }

    return (
        <div style={{ padding: '24px' }}>
            {
                (showSuccess) &&

                <div className="gap-[8px] text-left bg-green-300" style={{ margin: '18px', borderRadius: '8px' }}>
                    <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                        Dati salvati correttamente.
                    </span>
                </div>
            }
            <div>

                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '24px' }}>
                    <div style={{ flex: '0.2' }} className="text-left">
                        <SelectCustom
                            label="Anno"
                            placeholder={'Seleziona Anno'}
                            options={yearOptions}
                            onChange={(value) => dispatch(setYearFilter(value))}
                        />
                    </div>
                    <div style={{ flex: '0.2' }} className="text-left">
                        <SelectCustom
                            label="Stato"
                            placeholder={'Seleziona Stato'}
                            options={statusLiberatoria}
                            onChange={(value) => dispatch(setStatusFilter(value))}
                        />
                    </div>

                    <div style={{ alignSelf: 'flex-end', paddingBottom: '6px' }}>
                        <span>
                            <Button
                                size={"lg"}
                                iconPosition={"off"}
                                variant={"link"}
                                label="Pulisci"
                                color={"gray"}
                                onClick={() => dispatch(resetWaiverFilters())}
                            />
                        </span>
                    </div>

                    <div style={{ flex: '0.2', alignSelf: 'flex-end', paddingBottom: '6px' }}>
                        <Button
                            size={"lg"}
                            iconPosition={"off"}
                            variant={"outline"}
                            label="Cerca"
                            color={"blue"}
                            onClick={() => dispatch(GetAllWaiverToCodex(waiverToState.getAllWaiverToFilters))}
                        />
                    </div>

                    <div style={{ flex: '0.2', alignSelf: 'flex-end', paddingBottom: '6px' }}>
                        <Button
                            size={"lg"}
                            iconPosition={"off"}
                            variant={"outline"}
                            label="Scarica Excel"
                            color={"blue"}
                            onClick={() => dispatch(GetWaivertoExcel(waiverToState.getAllWaiverToFilters))}
                        />
                    </div>

                    <div style={{ flex: '1', display: 'flex', justifyContent: 'flex-end', alignItems: 'end' }}>
                        <Button
                            size="md"
                            iconPosition="off"
                            variant="outline"
                            color="orange"
                            label="Aggiungi Richiesta"
                            onClick={() => setShowPopupAdd(true)}
                        />
                    </div>
                </div>

                <table id="list-table" className="w-[100%] mt-6">
                    <thead className="h-[52px] uppercase">
                        <tr className="bg-brandPrimary-600">
                            <th style={{ borderTopLeftRadius: '8px' }}>Sede</th>
                            <th >Data Protocollo</th>
                            <th>Numero Protocollo</th>
                            <th>Mittente</th>
                            <th>Regione</th>
                            <th>Provincia</th>
                            <th>Cliente</th>
                            <th>Cod. Cliente</th>
                            <th>Recesso</th>
                            <th>Scadenza</th>
                            <th>Evaso</th>
                            <th>Tipologia Liberatoria</th>
                            <th>Protocollo Liberatoria Negata/Concessa</th>
                            <th>Note</th>
                            <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            waiverToState.getAllWaiverToResponse?.data.map((w) =>
                                <tr
                                    className=" h-[52px]  border-b-[1px] border-b-neutral-200"

                                >
                                    <td className="text-blue-800 robotoBold text-left">
                                        {w.waiverInnerInfo.headQuarter}
                                    </td>
                                    <td className="text-neutral-700" style={{ fontFamily: 'Manrope', fontSize: '14px', fontWeight: '600', lineHeight: '20px' }}>
                                        {formatterDate(w.waiverInnerInfo.protocolDate)}
                                    </td>
                                    <td className="text-neutral-700">
                                        {w.waiverInnerInfo.protocolNumber}
                                    </td>
                                    <td className="text-neutral-700">
                                        {w.waiverInnerInfo.senderOrReceiver}
                                    </td>
                                    <td className="text-neutral-700">
                                        {w.waiverInnerInfo.region}
                                    </td>
                                    <td className="text-neutral-700">
                                        {w.waiverInnerInfo.province}
                                    </td>
                                    <td className="text-neutral-700">
                                        {w.waiverInnerInfo.companyName}
                                    </td>
                                    <td className="text-neutral-700">
                                        {w.waiverInnerInfo.clientCode}
                                    </td>
                                    <td className="text-neutral-700">
                                        {formatterDate(w.waiverInnerInfo.endingDate)}
                                    </td>
                                    <td className="text-neutral-700">
                                        {formatterDate(w.expirationDate)}
                                    </td>
                                    <td className="text-neutral-700">
                                        {
                                            statusLiberatoria.filter(({ value }) => value === w.breakoutType).map(el =>
                                                <span key={el.value} className={clsx("labelStatus", { "nonEvasa": w.breakoutType === 'NON_EVASA', "evasa": w.breakoutType === 'EVASA' })}> {el.label}</span>
                                            )
                                        }
                                    </td>
                                    <td>
                                        Liberatoria per cambio OdC
                                    </td>
                                    <td>
                                        {
                                            w.allowedDepartureProtocolId && (
                                                <span>{w.allowedDepartureProtocolInfo}</span>
                                            )
                                        }
                                        {
                                            w.deniedDepartureProtocolId && (
                                                <span>{w.deniedDepartureProtocolInfo}</span>
                                            )
                                        }
                                    </td>
                                    <td>
                                        {w.note}
                                    </td>
                                    <td className="w-[20px] cursor-pointer">
                                        <div className="flex justify-content-between align-items-center">
                                            <button
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    handleOnEdit(w)

                                                }}>
                                                <EditIcon color={"Orange"} size={18} />
                                            </button>

                                            <button
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    dispatch(DownloadWaiverToCodexDoc(w.id))

                                                }}>
                                                <DownloadIcon size="36" color={"Orange"} />
                                            </button>

                                        </div>

                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
            {
                showPopup && (
                    <PopupForm title="Modifica Liberatoria" close={() => setShowPopup(false)}>
                        <EditLiberatoria close={() => { setShowPopup(false); dispatch(resetWaiverRequest()) }} type="to" />
                    </PopupForm>
                )
            }
            {
                showPopupAdd && (
                    <PopupForm title="Crea liberatoria richiesta a Codex" close={() => setShowPopupAdd(false)}>
                        <AddLiberatoria close={() => { setShowPopupAdd(false); dispatch(resetWaiverRequest()) }} type="to" />
                    </PopupForm>
                )
            }
        </div>
    )
}