import { AdditionalExpenseCreationDTO, AdditionalExpenseDTO, AdditionalExpenseUpdateDTO, BillingWorksheetCreationDTO, BillingWorksheetDTO, BillingWorksheetUpdateDTO, CustomerBillingWorksheetCreationDTO, CustomerBillingWorksheetDTO, CustomerBillingWorksheetUpdateDTO, GetAllAdditionalExpenseDTO, GetAllAdditionalExpenseFiltersDTO, GetAllBillingWorksheetsDTO, GetAllBillingWorksheetsFiltersDTO, GetAllCustomerBillingWorksheetDTO, GetAllCustomerBillingWorksheetFiltersDTO, GetAllServiceDTO, GetAllServiceFiltersDTO, GetAllServiceTypeDTO, GetAllServiceTypeFiltersDTO, ServiceCreationDTO, ServiceDTO, ServiceTypeCreationDTO, ServiceTypeDTO, ServiceTypeNormEnum, ServiceTypeUpdateDTO, ServiceUpdateDTO } from "./dto";
import { AdditionalExpenseServiceImpl, BillingWorksheetServiceImpl, CustomerBillingWorksheetServiceImpl, ServiceEntityServiceImpl, ServiceTypeServiceImpl } from "./serviceImpl";

/* SERVICE INTERFACES */

export interface BillingWorksheetService {
    CreateBillingWorksheet(data: BillingWorksheetCreationDTO): Promise<string>
    UpdateBillingWorksheetById(data: BillingWorksheetUpdateDTO, id: string): Promise<void>
    DeleteBillingWorksheet(id: string): Promise<void>
    GetAllBillingWorksheet(filters: GetAllBillingWorksheetsFiltersDTO): Promise<GetAllBillingWorksheetsDTO>
    GetBillingWorksheetById(id: string): Promise<BillingWorksheetDTO>
}

export interface CustomerBillingWorksheetService {
    CreateCustomerBillingWorksheet(data: CustomerBillingWorksheetCreationDTO): Promise<void>
    UpdateCustomerBillingWorksheet(data: CustomerBillingWorksheetUpdateDTO, id: string): Promise<void>
    DeleteCustomerBillingWorksheet(id: string): Promise<void>
    GetAllCustomerBillingWorksheet(filters: GetAllCustomerBillingWorksheetFiltersDTO): Promise<GetAllCustomerBillingWorksheetDTO>
    GetCustomerBillingWorksheetById(id: string): Promise<CustomerBillingWorksheetDTO>
}

export interface ServiceTypeService {
    CreateServiceType(data: ServiceTypeCreationDTO): Promise<void>
    UpdateServiceType(data: ServiceTypeUpdateDTO, id: string): Promise<void>
    DeleteServiceType(id: string): Promise<void>
    GetAllServiceType(filters: GetAllServiceTypeFiltersDTO): Promise<GetAllServiceTypeDTO>
    GetServiceTypeById(id: string): Promise<ServiceTypeDTO>
}

export interface ServiceEntityService {
    CreateService(data: ServiceCreationDTO): Promise<void>
    UpdateService(data: ServiceUpdateDTO, id: string): Promise<void>
    DeleteService(id: string): Promise<void>
    GetAllService(filters: GetAllServiceFiltersDTO): Promise<GetAllServiceDTO>
    GetServiceById(id: string): Promise<ServiceDTO>
}

export interface AdditionalExpenseService {
    CreateAdditionalExpense(data: AdditionalExpenseCreationDTO): Promise<void>
    UpdateAdditionalExpense(data: AdditionalExpenseUpdateDTO, id: string): Promise<void>
    DeleteAdditionalExpense(id: string): Promise<void>
    GetAllAdditionalExpense(filters: GetAllAdditionalExpenseFiltersDTO): Promise<GetAllAdditionalExpenseDTO>
    GetAdditionalExpenseById(id: string): Promise<AdditionalExpenseDTO>
}

/* FACTORY METHODS */

export function NewBillingWorksheetService(): BillingWorksheetService{
    return new BillingWorksheetServiceImpl()
}

export function NewServiceTypeService(): ServiceTypeService {
    return new ServiceTypeServiceImpl()
}

export function NewServiceEntityService(): ServiceEntityService {
    return new ServiceEntityServiceImpl()
}

export function NewAdditionalExpenseService(): AdditionalExpenseService {
    return new AdditionalExpenseServiceImpl()
}

export function NewCustomerBillingWorksheetService(): CustomerBillingWorksheetService {
    return new CustomerBillingWorksheetServiceImpl()
}