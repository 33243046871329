import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../utils";
import { GetAllMeasureRecipientsDTO, GetAllMeasureRecipientsFiltersDTO, MeasureRecipientDTO } from "./dataService/dto";
import { NewMeasureRecipientService } from "./dataService/service";

interface MeasureRecipientState {
    // REQUEST
    measureRecipientCreationRequest: MeasureRecipientDTO
    measureRecipientUpdateRequest: MeasureRecipientDTO
    measureRecipientIdToUpdate: string

    // FILTERS
    getAllMeasureRecipientFilters: GetAllMeasureRecipientsFiltersDTO

    // STATUS
    measureRecipientCreationStatus: PromiseStatuses
    measureRecipientUpdateStatus: PromiseStatuses
    measureRecipientDeleteStatus: PromiseStatuses
    measureRecipientRemoveStatus: PromiseStatuses
    measureRecipientRestoreStatus: PromiseStatuses
    getAllMeasureRecipientsStatus: PromiseStatuses
    getMeasureRecipientByIdStatus: PromiseStatuses

    // RESPONSE
    getAllMeasureRecipientsResponse?: GetAllMeasureRecipientsDTO
    getMeasureRecipientByIdResponse?: MeasureRecipientDTO
}

const initialState: MeasureRecipientState = {

    // REQUEST
    measureRecipientCreationRequest: {},
    measureRecipientUpdateRequest: {},
    measureRecipientIdToUpdate: '',



    // FILTERS
    getAllMeasureRecipientFilters: {
        itemsPerPage: 0,
        page: 0,
        order: true
    },

    // STATUS
    measureRecipientCreationStatus: 'idle',
    measureRecipientUpdateStatus: 'idle',
    measureRecipientDeleteStatus: 'idle',
    measureRecipientRemoveStatus: 'idle',
    measureRecipientRestoreStatus: 'idle',
    getMeasureRecipientByIdStatus: 'idle',
    getAllMeasureRecipientsStatus: 'idle'
}

export const MeasureRecipientCreation = createAsyncThunk(
    'measureRecipient/Creation',
    async (request: MeasureRecipientDTO, thunkApi): Promise<void> => {
        const measureRecipientService = NewMeasureRecipientService()

        return measureRecipientService.CreateMeasureRecipient(request).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const MeasureRecipientUpdate = createAsyncThunk(
    'measureRecipient/Update',
    async (request: {body: MeasureRecipientDTO, id: string}, thunkApi): Promise<void> => {
        const measureRecipientService = NewMeasureRecipientService()

        return measureRecipientService.UpdateMeasureRecipient(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const MeasureRecipientDelete = createAsyncThunk(
    'measureRecipient/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const measureRecipientService = NewMeasureRecipientService()

        return measureRecipientService.DeleteMeasureRecipient(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const MeasureRecipientRemove = createAsyncThunk(
    'measureRecipient/Remove',
    async (id: string, thunkApi): Promise<void> => {
        const measureRecipientService = NewMeasureRecipientService()

        return measureRecipientService.RemoveMeasureRecipient(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const MeasureRecipientRestore = createAsyncThunk(
    'measureRecipient/Restore',
    async (id: string, thunkApi): Promise<void> => {
        const measureRecipientService = NewMeasureRecipientService()

        return measureRecipientService.RestoreMeasureRecipient(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)


export const GetAllMeasureRecipients = createAsyncThunk(
    'measureRecipient/GetAll',
    async (filters: GetAllMeasureRecipientsFiltersDTO, thunkApi): Promise<GetAllMeasureRecipientsDTO> => {
        const measureRecipientService = NewMeasureRecipientService()

        return measureRecipientService.GetAllMeasureRecipients(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetMeasureRecipientById = createAsyncThunk(
    'measureRecipient/GetById',
    async (id: string, thunkApi): Promise<MeasureRecipientDTO> => {
        const measureRecipientService = NewMeasureRecipientService()

        return measureRecipientService.GetMeasureRecipientById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

const MeasureRecipientSlice = createSlice({
    name: 'measureRecipient/slice',
    initialState,
    reducers: {

        // REQUEST (CREATION)
        setMeasureRecipientCreationDescription: (state, action) => {
            state.measureRecipientCreationRequest.description = action.payload
        },
        setMeasureRecipientCreationEmail: (state, action) => {
            state.measureRecipientCreationRequest.email = action.payload
        },
        setMeasureRecipientMeasureActive: (state, action) => {
            console.log(action.payload)
            state.measureRecipientCreationRequest.measureActive = action.payload
        },
        setMeasureRecipientCreationPec: (state, action) => {
            state.measureRecipientCreationRequest.pec = action.payload
        },
        resetMeasureCreationRequest: (state) => {
            state.measureRecipientCreationRequest = {
                description: '',
                email: '',
                pec: '',
                measureActive: false
            }
        },

        // REQUEST (UPDATE)
        setMeasureRecipientIdToUpdate: (state, action) => {
            state.measureRecipientIdToUpdate = action.payload
        },
        setMeasureRecipientUpdateDescription: (state, action) => {
            state.measureRecipientUpdateRequest.description = action.payload
        },
        setMeasureRecipientUpdateEmail: (state, action) => {
            state.measureRecipientUpdateRequest.email = action.payload
        },
        setMeasureRecipientUpdatePec: (state, action) => {
            state.measureRecipientUpdateRequest.pec = action.payload
        },
        resetMeasureRecipientUpdateRequest: (state) => {
            state.measureRecipientUpdateRequest = {}
        },

        // FILTERS
        setGetAllMeasureRecipientsItemsPerPage: (state, action) => {
            state.getAllMeasureRecipientFilters.itemsPerPage = action.payload
        },
        setGetAllMeasureRecipientsPage: (state, action) => {
            state.getAllMeasureRecipientFilters.page = action.payload
        },

        // STATUS
        resetMeasureRecipientCreationStatus: (state) => {
            state.measureRecipientCreationStatus = 'idle'
        },
        resetMeasureRecipientUpdateStatus: (state) => {
            state.measureRecipientUpdateStatus = 'idle'
        },
        resetMeasureRecipientDeleteStatus: (state) => {
            state.measureRecipientDeleteStatus = 'idle'
        },
        resetMeasureRecipientRemoveStatus: (state) => {
            state.measureRecipientRemoveStatus = 'idle'
        },
        resetMeasureRecipientRestoreStatus: (state) => {
            state.measureRecipientRestoreStatus = 'idle'
        },
        resetGetAllMeasureRecipientsStatus: (state) => {
            state.getAllMeasureRecipientsStatus = 'idle'
        },
        resetGetMeasureRecipientByIdStatus: (state) => {
            state.getMeasureRecipientByIdStatus = 'idle'
        },
    },
    extraReducers(builder) {
        builder

            // [MEASURE RECIPIENTS]

            // CREATION
            .addCase(MeasureRecipientCreation.pending, (state) => {
                state.measureRecipientCreationStatus = 'loading'
            })
            .addCase(MeasureRecipientCreation.fulfilled, (state) => {
                state.measureRecipientCreationStatus = 'successfully'
            })
            .addCase(MeasureRecipientCreation.rejected, (state) => {
                state.measureRecipientCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(MeasureRecipientUpdate.pending, (state) => {
                state.measureRecipientUpdateStatus = 'loading'
            })
            .addCase(MeasureRecipientUpdate.fulfilled, (state) => {
                state.measureRecipientUpdateStatus = 'successfully'
            })
            .addCase(MeasureRecipientUpdate.rejected, (state) => {
                state.measureRecipientUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(MeasureRecipientDelete.pending, (state) => {
                state.measureRecipientDeleteStatus = 'loading'
            })
            .addCase(MeasureRecipientDelete.fulfilled, (state) => {
                state.measureRecipientDeleteStatus = 'successfully'
            })
            .addCase(MeasureRecipientDelete.rejected, (state) => {
                state.measureRecipientDeleteStatus = 'failed'
            })

            // REMOVE
            .addCase(MeasureRecipientRemove.pending, (state) => {
                state.measureRecipientRemoveStatus = 'loading'
            })
            .addCase(MeasureRecipientRemove.fulfilled, (state) => {
                state.measureRecipientRemoveStatus = 'successfully'
            })
            .addCase(MeasureRecipientRemove.rejected, (state) => {
                state.measureRecipientRemoveStatus = 'failed'
            })

            // RESTORE
            .addCase(MeasureRecipientRestore.pending, (state) => {
                state.measureRecipientRestoreStatus = 'loading'
            })
            .addCase(MeasureRecipientRestore.fulfilled, (state) => {
                state.measureRecipientRestoreStatus = 'successfully'
            })
            .addCase(MeasureRecipientRestore.rejected, (state) => {
                state.measureRecipientRestoreStatus = 'failed'
            })

            // GET ALL
            .addCase(GetAllMeasureRecipients.pending, (state) => {
                state.getAllMeasureRecipientsStatus = 'loading'
            })
            .addCase(GetAllMeasureRecipients.fulfilled, (state, action) => {
                state.getAllMeasureRecipientsStatus = 'successfully'
                state.getAllMeasureRecipientsResponse = action.payload
            })
            .addCase(GetAllMeasureRecipients.rejected, (state) => {
                state.getAllMeasureRecipientsStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetMeasureRecipientById.pending, (state) => {
                state.getMeasureRecipientByIdStatus = 'loading'
            })
            .addCase(GetMeasureRecipientById.fulfilled, (state, action) => {
                state.getMeasureRecipientByIdStatus = 'successfully'
                state.getMeasureRecipientByIdResponse = action.payload
            })
            .addCase(GetMeasureRecipientById.rejected, (state) => {
                state.getMeasureRecipientByIdStatus = 'failed'
            })
    },
})

export const {
    // REQUEST
    setMeasureRecipientCreationDescription,
    setMeasureRecipientCreationEmail,
    setMeasureRecipientMeasureActive,
    setMeasureRecipientCreationPec,
    setMeasureRecipientIdToUpdate,
    setMeasureRecipientUpdateDescription,
    setMeasureRecipientUpdateEmail,
    setMeasureRecipientUpdatePec,
    resetMeasureCreationRequest,
    resetMeasureRecipientUpdateRequest,

    // FILTERS
    setGetAllMeasureRecipientsItemsPerPage,
    setGetAllMeasureRecipientsPage,

    // STATUS
    resetGetMeasureRecipientByIdStatus,
    resetMeasureRecipientCreationStatus,
    resetMeasureRecipientDeleteStatus,
    resetMeasureRecipientRemoveStatus,
    resetMeasureRecipientRestoreStatus,
    resetMeasureRecipientUpdateStatus,
    resetGetAllMeasureRecipientsStatus
} = MeasureRecipientSlice.actions

export default MeasureRecipientSlice.reducer