import React, { useEffect, useState } from "react";
import { HoursMinutesBoxProps } from "./hoursMinutesBox.types";
import { SelectCustom } from "../../../../ui/molecules/select";
import { HourOptions, MinuteOptions } from "../../../../utils";

export const HoursMinutesBox: React.FC<HoursMinutesBoxProps> = ({
    ...props
}) => {
    const [hours, setHours] = useState<number | undefined>(undefined)
    const [minutes, setMinutes] = useState<number | undefined>(undefined)

    useEffect(() => {
        // default value must be expressed between 0 and 1
        if (props.defaultValue === undefined)
            return

        const hours_temp = Math.trunc(props.defaultValue)
        setHours(hours_temp)
        const decimal = props.defaultValue - hours_temp
        const minutes_temp = Math.round(decimal * 60)
        setMinutes(minutes_temp)
    }, [])

    useEffect(() => {
        let res = 0
        if (hours)
            res = hours

        if (minutes)
            res += minutes / 60

        props.onChange && props.onChange(res)
    }, [hours, minutes])

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
            <div style={{ flex: '0.5' }}>
                <div className="text-left">
                    <span className="input-label">Ore</span>
                </div>
                <SelectCustom
                    placeholder='Specifica ore...'
                    options={HourOptions}
                    value={String(hours)}
                    onChange={(value) => setHours(Number(value))}
                />
            </div>
            <div style={{ flex: '0.5' }}>
                <div className="text-left">
                    <span className="input-label">Minuti</span>
                </div>
                <SelectCustom
                    placeholder='Specifica minuti...'
                    options={MinuteOptions}
                    value={String(minutes)}
                    onChange={(value) => setMinutes(Number(value))}
                />
            </div>
        </div>
    )
}