import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import Button from "../../ui/molecules/button";
import Input from "../../ui/molecules/input/Input";
import Toggle from "../../ui/molecules/toggle";
import { OdcUpdate, OdcValidation, setOdcUpdateAddress, setOdcUpdateCity, setOdcUpdateCode, setOdcUpdateCompanyName, setOdcUpdateEmail, setOdcUpdateNation, setOdcUpdatePec, setOdcUpdatePhone, setOdcUpdateProvince, setOdcUpdateRegion, setOdcUpdateZipcode } from "./slice";

interface Props {
    close: () => void
}

export function ODCPopupEdit(props: Props) {
    const dispatch = useAppDispatch()
    const odcState = useAppSelector(state => state.odc)

    useEffect(() => {
        if(odcState.odcvalidationStatus === 'successfully') {
            dispatch(OdcUpdate({body: odcState.odcUpdateRequest, id: odcState.odcIdToUpdate}))
        }
    }, [odcState.odcvalidationStatus])

    useEffect(() => {
        if(odcState.odcUpdateStatus === 'successfully') {
            props.close()
        }
     }, [odcState.odcUpdateStatus])

    return (
        <div>
            <div className="bg-brandPrimary-100 pr-5 pl-5 pt-4 pb-5" style={{ borderTopWidth: 1 }}>
                <div className="pb-3" style={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Nominativo'}
                            placeholder="Specifica nome..."
                            error={odcState.odcErrors.companyName}
                            supportingText={odcState.odcErrors.companyName ? 'Nome mancante' : undefined}
                            value={odcState.odcUpdateRequest.companyName}
                            onChangeText={(text) => dispatch(setOdcUpdateCompanyName(text))}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Codice OdC'}
                            placeholder="Specifica codice OdC..."
                            error={odcState.odcErrors.code}
                            supportingText={odcState.odcErrors.code ? 'Codice mancante' : undefined}
                            value={odcState.odcUpdateRequest.code}
                            onChangeText={(text) => dispatch(setOdcUpdateCode(text))}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Indirizzo'}
                            placeholder="Specifica indirizzo..."
                            value={odcState.odcUpdateRequest.address}
                            onChangeText={(text) => dispatch(setOdcUpdateAddress(text))}
                        />
                    </div>
                </div>

                <div className="pb-3" style={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Comune'}
                            placeholder="Specifica comune..."
                            value={odcState.odcUpdateRequest.city}
                            onChangeText={(text) => dispatch(setOdcUpdateCity(text))}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'CAP'}
                            placeholder="Specifica CAP..."
                            value={odcState.odcUpdateRequest.zipCode}
                            onChangeText={(text) => dispatch(setOdcUpdateZipcode(text))}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Provincia'}
                            placeholder="Specifica provincia..."
                            value={odcState.odcUpdateRequest.province}
                            onChangeText={(text) => dispatch(setOdcUpdateProvince(text))}
                        />
                    </div>
                </div>

                <div className="pb-3" style={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Regione'}
                            placeholder="Specifica regione..."
                            value={odcState.odcUpdateRequest.region}
                            onChangeText={(text) => dispatch(setOdcUpdateRegion(text))}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Telefono'}
                            placeholder="Specifica telefono..."
                            value={odcState.odcUpdateRequest.phone}
                            onChangeText={(text) => dispatch(setOdcUpdatePhone(text))}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            label={'Nazione'}
                            placeholder="Specifica nazione..."
                            value={odcState.odcUpdateRequest.nation}
                            onChangeText={(text) => dispatch(setOdcUpdateNation(text))}
                        />
                    </div>
                </div>

                <div className="pb-3" style={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
                    <div style={{ flex: '0.5' }}>
                        <Input
                            label={'PEC'}
                            placeholder="Specifica PEC..."
                            value={odcState.odcUpdateRequest.pec}
                            onChangeText={(text) => dispatch(setOdcUpdatePec(text))}
                        />
                    </div>
                    <div style={{ flex: '0.5' }}>
                        <Input
                            label={'Email'}
                            placeholder="Specifica email..."
                            value={odcState.odcUpdateRequest.email}
                            onChangeText={(text) => dispatch(setOdcUpdateEmail(text))}
                        />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingRight: 15 }}>
                <Button
                    size="md"
                    variant="outline"
                    iconPosition="off"
                    label="Annulla"
                    color="dimgrey"
                    onClick={() => {
                        props.close();
                    }}
                />
                <div className="pl-2">
                    <Button
                        size="md"
                        variant="solid"
                        iconPosition="off"
                        label="Salva"
                        color="orange"
                        onClick={() => dispatch(OdcValidation(odcState.odcUpdateRequest))}
                    />
                </div>
            </div>
        </div>
    )
}