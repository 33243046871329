import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "../../../../ui/icons/arrow-left";
import Button from "../../../../ui/molecules/button";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { useEffect, useState } from "react";
import clsx from "clsx"
import TextArea from "../../../../ui/molecules/textArea";
import { GetAllOdc, resetGetAllOdcStatus } from "../../../odc/slice";
import { codiceNC, OptionType } from "../../../../utils";
import { SelectCustom } from "../../../../ui/molecules/select";
import { Calendar } from "../../../../ui/molecules/calendar";
import { isNonComplianceMapping, nonComplianceMap, nonComplianceOptions, typeNcDetails } from "../../../../utils/measuresUtils";
import { setOtherOdcMeasureIsReleased, setOtherOdcMeasureNonComplianceDescription, setOtherOdcMeasureDescription, setOtherOdcMeasureNonComplianceOfficerId, setOtherOdcMeasuresCustomerId, setOtherOdcMeasuresDate, setOtherOdcMeasuresNonComplianceCode, setOtherOdcMeasuresOdc, setOtherOdcNewMeasuresOdc, setOtherOdcNewMeasuresDate, setOtherOdcNewMeasureNonComplianceDescription, setOtherOdcNewMeasureDescription, setOtherOdcNewMeasuresNewComplianceCode, setOtherOdcNewMeasuresNonComplianceVersion, setOtherOdcNewMeasureIsReleased, setOtherOdcNewMeasureNonComplianceOfficerId, setOtherOdcNewMeasuresCustomerId } from "../dataService/slice";
import { NonComplianceMapping } from "../dataService/dto";
import { NonComplianceCode } from "../../NonComplianceCode";
import { GetNcById, resetGetByIdStatus } from "../../NonComplianceCode/slice";
import { NonComplianceCodeByIdDTO } from "../../NonComplianceCode/dto";
import { format } from "date-fns";

interface Props {
    measureType: 'old' | 'new' | undefined
}

export function CustomerAddOtherOdcMeasueres(props: Props) {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const sessionState = useAppSelector(state => state.session)
    const OtherOdcMeasuresState = useAppSelector(state => state.OtherOdcMeasuresState)
    const odcState = useAppSelector(state => state.odc)
    const [odc, setOdc] = useState<OptionType[]>([])
    const [selectedNcCode, setSelectedNcCode] = useState<string>('');
    const [selectedNcInfoOld, setSelectedNcInfoOld] = useState<NonComplianceMapping | null>(null);
    const [selectedNcInfoNew, setSelectedNcInfoNew] = useState<NonComplianceCodeByIdDTO | null>(null);
    const NonComplianceCodesState = useAppSelector(state => state.NonComplianceCodes)

    const handleSelectedNc = (value: string) => {
        setSelectedNcCode(value)
        const infoNc = nonComplianceMap.get(value) || null
        if (infoNc && isNonComplianceMapping(infoNc)) {
            setSelectedNcInfoOld(infoNc)
        }
    }

    useEffect(() => {
        if (props.measureType === 'old') {
            dispatch(setOtherOdcMeasuresCustomerId(sessionState.selected.customerId))
            dispatch(setOtherOdcMeasureIsReleased(false))
            dispatch(setOtherOdcMeasureNonComplianceOfficerId(sessionState.selected.officerId))
        }
        if (props.measureType === 'new') {
            dispatch(setOtherOdcNewMeasuresNonComplianceVersion('NEW'))
            dispatch(setOtherOdcNewMeasuresCustomerId(sessionState.selected.customerId))
            dispatch(setOtherOdcNewMeasureIsReleased(false))
            dispatch(setOtherOdcNewMeasureNonComplianceOfficerId(sessionState.selected.officerId))
        }
    }, [])

    useEffect(() => {
        if (NonComplianceCodesState.ConComplianceCodeResponseByIdStatus === 'successfully') {
            dispatch(resetGetByIdStatus())
            setSelectedNcInfoNew(NonComplianceCodesState.ConComplianceCodeResponseById)
        }
    }, [NonComplianceCodesState.ConComplianceCodeResponseByIdStatus])

    useEffect(() => {
        dispatch(GetAllOdc({
            itemsPerPage: 0,
            page: 0
        }))
    }, [])

    useEffect(() => {
        if (odcState.getAllOdcStatus === 'successfully' &&
            odcState.getAllOdcResponse !== undefined
        ) {
            dispatch(resetGetAllOdcStatus())
            const data = odcState.getAllOdcResponse.data.map(odc => {
                return {
                    value: odc.id,
                    label: odc.code + ' - ' + odc.companyName
                } as OptionType
            })
            setOdc(data)
        }
    }, [odcState.getAllOdcStatus])

    return (
        <div>

            <div className='fieldset-container'>
                <div className='fieldset-header'>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}>Dati Non Conformità</span>
                    </div>
                </div>
                <div className="mt-6" style={{ padding: '0px 24px' }}>
                    <div style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                        <div style={{ flex: '0.5', textAlign: 'left' }}>
                            <SelectCustom
                                label="Organismo di controllo"
                                placeholder={'ODC'}
                                options={odc}
                                onChange={(value) => {
                                    if (props.measureType === 'old') {
                                        dispatch(setOtherOdcMeasuresOdc(value))
                                    }
                                    if (props.measureType === 'new') {
                                        dispatch(setOtherOdcNewMeasuresOdc(value))
                                    }

                                }}
                            />
                        </div>

                        <div style={{ flex: '0.5' }}>
                            <Calendar
                                errorLabel={OtherOdcMeasuresState.otherOdcMeasuserOldErrors.measureDate ? 'Data mancante' : undefined}
                                selected={props.measureType === 'old' ? OtherOdcMeasuresState.OtherOdcMeasuresCreationUpdate.measureDate : OtherOdcMeasuresState.OtherOdcNewMeasuresCreationUpdate.measureDate}
                                label="Data Provvedimento"
                                onChange={
                                    (date) => {
                                        if (props.measureType === 'old') {
                                            dispatch(setOtherOdcMeasuresDate((format(new Date(date), 'yyyy-MM-dd'))))
                                        }
                                        if (props.measureType === 'new') {
                                            dispatch(setOtherOdcNewMeasuresDate((format(new Date(date), 'yyyy-MM-dd'))))
                                        }
                                    }
                                }
                            />
                        </div>
                    </div>
                </div>

                <div className="mt-6" style={{ padding: '0px 24px' }}>
                    <NonComplianceCode
                        type={props.measureType}
                        onChange={(value) => {
                            if (props.measureType === 'old') {
                                dispatch(setOtherOdcMeasuresNonComplianceCode(value))
                            }
                            if (props.measureType === 'new') {
                                dispatch(setOtherOdcNewMeasuresNewComplianceCode(value))
                            }
                            if (value) {
                                handleSelectedNc(value)
                            }
                        }}
                    />
                </div>

                <div className="mt-6" style={{ padding: '0px 24px' }}>

                    <div className='pb-5 pt-3'>
                        <div className="text-left">
                            <span className="input-label">Descrizione non conformità </span>
                        </div>
                        <TextArea
                            onChangeText={(text) => {
                                if (props.measureType === 'old') {
                                    dispatch(setOtherOdcMeasureNonComplianceDescription(text))
                                }
                                if (props.measureType === 'new') {
                                    dispatch(setOtherOdcNewMeasureNonComplianceDescription(text))
                                }

                            }
                            }
                        />
                    </div>
                </div>
            </div>

            <div className='fieldset-container'>
                <div className='fieldset-header'>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}>Dati Provvedimento</span>
                    </div>
                </div>

                <div className="mt-6" style={{ padding: '0px 24px' }}>

                    <div className='pb-5 '>
                        <div className="text-left">
                            <span className="input-label">Descrizione Provvedimento </span>
                        </div>
                        <TextArea
                            onChangeText={(text) => {
                                if (props.measureType === 'old') {
                                    dispatch(setOtherOdcMeasureDescription(text))
                                }
                                if (props.measureType === 'new') {
                                    dispatch(setOtherOdcNewMeasureDescription(text))
                                }
                            }

                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}