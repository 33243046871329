import '../styles.scss'
import { useEffect, useState } from 'react';
import { MinusIcon } from '../../../../ui/icons/minusIcon';
import { PlusIconAccordion } from '../../../../ui/icons/plusIcon';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ProtocolSelectByObject } from '../../../../ui/molecules/ProtocolSelectByObj';
import { SelectCustom } from '../../../../ui/molecules/select';
import TextArea from '../../../../ui/molecules/textArea';
import Input from '../../../../ui/molecules/input';
import { SingleCheckbox } from '../../../../ui/molecules/SingleCheckbox';
import { ProtocolSelectByAttachment } from '../../../../ui/molecules/ProtocolSelectByAtt';
import { CustomRadio } from '../../../../ui/molecules/radioButton';
import Button from '../../../../ui/molecules/button';
import { TickIcon } from '../../../../ui/icons/TickIcon';
import { ProtocolSearch } from '../../../../ui/molecules/ProtocolSearch';
import { useAppDispatch, useAppSelector } from '../../../../lib/redux/hooks';
import { DownloadRidDocx, GetRIDBYId, resetRIDDeleteStatus, resetStateUpdateStatus, RIDDelete, RIDUpdateState, setRIDReportingId, setUpdateRIDCertificateRequestProtocolId, setUpdateRIDClosedNote, setUpdateRIDClosingProtocolIds, setUpdateRIDDeadlineRequestedDocument, setUpdateRIDDepartureProtocolId, setUpdateRIDDocumentalControlProtocolId, setUpdateRIDNote, setUpdateRIDObject, setUpdateRIDOther, setUpdateRIDReportingId } from '../slice';
import { useNavigate } from 'react-router-dom';
import { MultiProtocolSearch } from '../../../../ui/molecules/ProtocolSearch/multisearch';
import { CompilationStateDTO, RequestStateDTO } from '../dataService/dto';
import { Alert } from '../../../../ui/organisms/alert';
import { optionsFormatter, OptionType, ReportingStatusOption, ReportingTypologyOption } from '../../../../utils';
import { GetAllReportings, resetGetAllReportingsStatus } from '../../../gestioneControllo/segnalazioni/slice';

export function EditRIDForms() {
    const dispatch = useAppDispatch()
    const ridState = useAppSelector(state => state.rid)
    const userState = useAppSelector(state => state.users)
    const sessionState = useAppSelector(state => state.session)
    const reportingState = useAppSelector(state => state.reporting)
    const navigate = useNavigate()
    const [expanded, setExpanded] = useState<string | false>("panel1");
    const [notificationFlag, setNotificationFlag] = useState<boolean>(false)
    const [entryClient, setEntryClient] = useState<boolean>(false)
    const [inspectionControl, setInspectionControl] = useState<boolean>(false)
    const [documentControl, setDocumentControl] = useState<boolean>(false)
    const [certificationRequest, setCertificationRequest] = useState<boolean>(false)
    const [reportFlag, setReportFlag] = useState<boolean>(false)
    const [otherFlag, setOtherFlag] = useState<boolean>(false)
    const [closeFlag, setCloseFlag] = useState<string | undefined>(undefined)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [reportings, setReportings] = useState<OptionType[]>([])

    const compilationPredicate = (): boolean => {

        return ridState.getRidByIdResponse?.attachmentIds?.length! > 0 &&
            ridState.getRidByIdResponse?.departureProtocolId !== undefined &&
            ridState.getRidByIdResponse?.deadlineRequestedDocument !== undefined &&
            ridState.getRidByIdResponse?.compilationState === CompilationStateDTO.IN_CORSO &&
            ridState.getRidByIdResponse?.isMotivation
    }

    useEffect(() => {
        if (ridState.ridStateUpdateStatus === 'successfully') {
            dispatch(resetStateUpdateStatus())
            dispatch(GetRIDBYId(ridState.getRidByIdResponse?.id!))
        }
    }, [ridState.ridStateUpdateStatus])

    useEffect(() => {
        if (ridState.ridDeleteStatus === 'successfully') {
            dispatch(resetRIDDeleteStatus())
            navigate('/rid')
        }
    }, [ridState.ridDeleteStatus])

    useEffect(() => {
        dispatch(GetAllReportings({
            itemsPerPage: 0,
            page: 0,
            customerId: sessionState.selected.customerId!
        }))
    }, [])

    useEffect(() => {
        if (reportingState.getAllReportingsStatus === 'successfully' &&
            reportingState.getAllReportingsResponse !== undefined
        ) {
            dispatch(resetGetAllReportingsStatus())
            const data = reportingState.getAllReportingsResponse.data.map(rep => {
                return {
                    value: rep.id,
                    label: `${optionsFormatter(rep.reportingType, ReportingTypologyOption)} - ${optionsFormatter(rep.reportingState, ReportingStatusOption)}`
                } as OptionType
            })
            setReportings(data)
        }
    }, [reportingState.getAllReportingsStatus])

    useEffect(() => {
        dispatch(GetAllReportings({
            itemsPerPage: 0,
            page: 0,
            customerId: sessionState.selected.customerId!
        }))
        setReportFlag(ridState.ridUpdateRequest.reportingId !== null)
        setCertificationRequest(ridState.ridUpdateRequest.certificateRequestProtocolId !== null)
        setDocumentControl(ridState.ridUpdateRequest.documentalControlProtocolId !== null)
        setOtherFlag(ridState.ridUpdateRequest.other !== null)
        setCloseFlag(ridState.ridUpdateRequest.closingProtocolIds !== null ? 'CLOSE' : undefined) // DA MODIFICARE QUANDO CI SARANNO PROVVEDIMENTI
    }, [])

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className='text-left mt-3 mb-3' style={{ flex: '0.5', display: 'flex', justifyContent: 'flex-start' }}>
                    <span className='rid-title' style={{ color: '#4D797F' }}>Modifica R.I.D. </span>
                </div>
                <div style={{ flex: '0.5', display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
                    {
                        userState.findMeResponse.role === 'admin' ? (
                            <Button
                                iconPosition='off'
                                variant='outline'
                                color='red'
                                size='sm'
                                label='Elimina R.I.D.'
                                onClick={() => setShowAlert(true)}
                            />
                        ) : ridState.getRidByIdResponse?.requestState === RequestStateDTO.APERTA ? (
                            <Button
                                iconPosition='off'
                                variant='outline'
                                color='red'
                                size='sm'
                                label='Elimina R.I.D.'
                                onClick={() => setShowAlert(true)}
                            />
                        ) : <></>
                    }
                    {
                        compilationPredicate() && (
                            <Button
                                iconPosition='right'
                                icon={<TickIcon />}
                                variant='outline'
                                color='dimgrey'
                                size='sm'
                                label='Compilazione completata'
                                onClick={() => dispatch(RIDUpdateState({ body: ridState.ridUpdateStateRequest, id: ridState.ridIdToUpdate }))}
                            />
                        )
                    }
                </div>
            </div>

            <div id="boxAnagrafica">
                <div>
                    <Accordion
                        style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' }}
                        expanded
                        defaultExpanded
                    >
                        <AccordionSummary
                            style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}
                            aria-controls="panel1-content"
                            className="rounded-xl headingAccordion"
                            id="panel1-header"
                        >
                            <div><span className="text-uppercase text-white label-input">Dati generali R.I.D.</span></div>
                        </AccordionSummary>

                        <AccordionDetails style={{ backgroundColor: '#F2F7F7', padding: '24px', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' }}>
                            <div>

                                <div className='pb-3'>
                                    <ProtocolSelectByObject
                                        customerId={sessionState.selected.customerId!}
                                        objectId='77'
                                        type='departure'
                                        placeholder='Seleziona...'
                                        title='Protocollo RID'
                                        disabled={ridState.getRidByIdResponse?.compilationState === CompilationStateDTO.COMPLETATA}
                                        defaultValue={ridState.ridUpdateRequest.departureProtocolId}
                                        value={ridState.ridUpdateRequest.departureProtocolId}
                                        onChange={(value) => dispatch(setUpdateRIDDepartureProtocolId(value))}
                                    />
                                </div>

                                <div className='pb-3'>
                                    <div className="text-left">
                                        <span className="select-search-title">Oggetto</span>
                                    </div>
                                    <Input
                                        defaultValue='Richiesta integrazione documenti'
                                        disabled={ridState.getRidByIdResponse?.compilationState === CompilationStateDTO.COMPLETATA}
                                        onChangeText={(text) => dispatch(setUpdateRIDObject(text))}
                                    />

                                </div>

                                <div className='pb-3'>
                                    <div className="text-left">
                                        <span className="select-search-title">Note aggiuntive</span>
                                    </div>
                                    <TextArea
                                        disabled={ridState.getRidByIdResponse?.compilationState === CompilationStateDTO.COMPLETATA}
                                        value={ridState.ridUpdateRequest.note}
                                        onChangeText={(text) => dispatch(setUpdateRIDNote(text))} />
                                </div>

                                <div className='pb-3'>
                                    <div className="text-left">
                                        <span className="select-search-title">Scadenza invio documenti richiesti</span>
                                        <Input
                                            disabled={ridState.getRidByIdResponse?.compilationState === CompilationStateDTO.COMPLETATA}
                                            value={ridState.ridUpdateRequest.deadlineRequestedDocument}
                                            placeholder='Numero di giorni...'
                                            onChangeText={(text) => dispatch(setUpdateRIDDeadlineRequestedDocument(text))}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div className="text-left">
                                        <span className='select-search-title' style={{ color: '#314D51', fontWeight: '400' }}>Funzionario che ha emesso la RID: </span>
                                        <span className="select-search-title">{ridState.getRidByIdResponse?.officerInfo}</span>
                                    </div>
                                </div>

                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className='mt-6'>
                    <Accordion
                        style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' }}
                        expanded
                        defaultExpanded
                    >
                        <AccordionSummary
                            style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}
                            aria-controls="panel2-content"
                            className="rounded-xl headingAccordion"
                            id="panel2-header"
                        >
                            <div><span className="text-uppercase text-white label-input">Motivazioni che hanno generato la R.I.D.</span></div>
                        </AccordionSummary>

                        <AccordionDetails style={{ backgroundColor: '#F2F7F7', padding: '24px', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' }}>
                            <div>

                                {/* NOTIFICATION FLAG */}
                                <div className='mb-3' style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                    <div>
                                        <SingleCheckbox
                                            disabled={ridState.getRidByIdResponse?.compilationState === CompilationStateDTO.COMPLETATA}
                                            checked={notificationFlag}
                                            setChecked={(value) => {
                                                setNotificationFlag(value)
                                                // dispatch
                                            }}
                                            label=''
                                        />
                                    </div>
                                    <div style={{ flex: '1' }}>
                                        <div className="text-left">
                                            <span className="select-search-title">Notifica</span>
                                        </div>
                                        <SelectCustom
                                            options={[{ label: 'Notifica 1', value: '1' }]}
                                            placeholder='Seleziona notifica...'
                                            disabled={!notificationFlag || ridState.getRidByIdResponse?.compilationState === CompilationStateDTO.COMPLETATA}
                                        />
                                    </div>
                                </div>

                                {/* ENTRYCLIENT FLAG */}
                                <div className='mb-3' style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                    <div>
                                        <SingleCheckbox
                                            disabled={ridState.getRidByIdResponse?.compilationState === CompilationStateDTO.COMPLETATA}
                                            checked={entryClient}
                                            setChecked={(value) => {
                                                setEntryClient(value)
                                                // dispatch
                                            }}
                                            label=''
                                        />
                                    </div>
                                    <div style={{ flex: '1' }}>
                                        <div className="text-left">
                                            <span className="select-search-title">Cliente in ingresso</span>
                                        </div>
                                        <SelectCustom
                                            options={[{ label: 'Cliente 1', value: '1' }]}
                                            placeholder='Seleziona cliente...'
                                            disabled={!entryClient || ridState.getRidByIdResponse?.compilationState === CompilationStateDTO.COMPLETATA}
                                        />
                                    </div>
                                </div>

                                {/* INSPECTION CONTROL FLAG */}
                                <div className='mb-3' style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                    <div>
                                        <SingleCheckbox
                                            disabled={ridState.getRidByIdResponse?.compilationState === CompilationStateDTO.COMPLETATA}
                                            checked={inspectionControl}
                                            setChecked={(value) => {
                                                setInspectionControl(value)
                                                // dispatch
                                            }}
                                            label=''
                                        />
                                    </div>
                                    <div style={{ flex: '1' }}>
                                        <div className="text-left">
                                            <span className="select-search-title">Controllo ispettivo</span>
                                        </div>
                                        <SelectCustom
                                            options={[{ label: 'Controllo ispettivo 1', value: '1' }]}
                                            placeholder='Seleziona controllo (visita ispettiva)...'
                                            disabled={!inspectionControl || ridState.getRidByIdResponse?.compilationState === CompilationStateDTO.COMPLETATA}
                                        />
                                    </div>
                                </div>

                                {/* DOCUMENT CONTROL FLAG */}
                                <div className='mb-3' style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                    <div>
                                        <SingleCheckbox
                                            disabled={ridState.getRidByIdResponse?.compilationState === CompilationStateDTO.COMPLETATA}
                                            checked={documentControl}
                                            setChecked={(value) => {
                                                setDocumentControl(value)
                                                if (!value) {
                                                    dispatch(setUpdateRIDDocumentalControlProtocolId(undefined))
                                                }
                                            }}
                                            label=''
                                        />
                                    </div>
                                    <div style={{ flex: '1' }}>
                                        <div className="text-left">
                                            <span className="select-search-title">Controllo documentale</span>
                                        </div>
                                        <ProtocolSearch
                                            type='entry'
                                            disabled={!documentControl || ridState.getRidByIdResponse?.compilationState === CompilationStateDTO.COMPLETATA}
                                            defaultValue={ridState.ridUpdateRequest.documentalControlProtocolId}
                                            onSelect={(value) => dispatch(setUpdateRIDDocumentalControlProtocolId(value))}
                                        />
                                    </div>
                                </div>

                                {/* CERTIFICATION FLAG */}
                                <div className='mb-3' style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                    <div>
                                        <SingleCheckbox
                                            disabled={ridState.getRidByIdResponse?.compilationState === CompilationStateDTO.COMPLETATA}
                                            checked={certificationRequest}
                                            setChecked={(value) => {
                                                setCertificationRequest(value)
                                                if (!value) {
                                                    dispatch(setUpdateRIDCertificateRequestProtocolId(undefined))
                                                }
                                            }}
                                            label=''
                                        />
                                    </div>
                                    <div style={{ flex: '1' }}>
                                        <ProtocolSelectByAttachment
                                            customerId={sessionState.selected.customerId!}
                                            attachmentId='249'
                                            type='entry'
                                            placeholder='Seleziona protocollo entrata con Allegato “Richiesta Certificato”'
                                            title='Richiesta certificato'
                                            disabled={!certificationRequest || ridState.getRidByIdResponse?.compilationState === CompilationStateDTO.COMPLETATA}
                                            defaultValue={ridState.ridUpdateRequest.certificateRequestProtocolId}
                                            value={ridState.ridUpdateRequest.certificateRequestProtocolId}
                                            onChange={(value) => dispatch(setUpdateRIDCertificateRequestProtocolId(value))}
                                        />
                                    </div>
                                </div>

                                {/* REPORT FLAG */}
                                <div className='mb-3' style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                    <div>
                                        <SingleCheckbox
                                            disabled={ridState.getRidByIdResponse?.compilationState === CompilationStateDTO.COMPLETATA}
                                            checked={reportFlag}
                                            setChecked={(value) => {
                                                setReportFlag(value)
                                                if (!value) {
                                                    dispatch(setUpdateRIDReportingId(undefined))
                                                }
                                            }}
                                            label=''
                                        />
                                    </div>
                                    <div style={{ flex: '1' }}>
                                        <div className="text-left">
                                            <span className="select-search-title">Segnalazione</span>
                                        </div>
                                        <SelectCustom
                                            options={reportings}
                                            value={ridState.ridUpdateRequest.reportingId}
                                            onChange={(value) => dispatch(setUpdateRIDReportingId(value))}
                                            placeholder='Seleziona segnalazione...'
                                            disabled={!reportFlag || ridState.getRidByIdResponse?.compilationState === CompilationStateDTO.COMPLETATA}
                                        />
                                    </div>
                                </div>

                                {/* OTHER FLAG */}
                                <div className='mb-3' style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                    <div>
                                        <SingleCheckbox
                                            disabled={ridState.getRidByIdResponse?.compilationState === CompilationStateDTO.COMPLETATA}
                                            checked={otherFlag}
                                            setChecked={(value) => {
                                                setOtherFlag(value)
                                                if (!value) {
                                                    dispatch(setUpdateRIDOther(undefined))
                                                }
                                            }}
                                            label=''
                                        />
                                    </div>
                                    <div style={{ flex: '1' }}>
                                        <div className="text-left">
                                            <span className="select-search-title">Altro</span>
                                        </div>
                                        <Input
                                            disabled={!otherFlag || ridState.getRidByIdResponse?.compilationState === CompilationStateDTO.COMPLETATA}
                                            value={ridState.ridUpdateRequest.other}
                                            onChangeText={(text) => dispatch(setUpdateRIDOther(text))}
                                        />
                                    </div>
                                </div>

                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                {
                    ridState.getRidByIdResponse?.compilationState === CompilationStateDTO.COMPLETATA && (
                        <div className='mt-6'>
                            <Accordion
                                style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' }}
                                expanded
                                defaultExpanded
                            >
                                <AccordionSummary
                                    style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}
                                    aria-controls="panel1-content"
                                    className="rounded-xl headingAccordion"
                                    id="panel1-header"
                                >
                                    <div><span className="text-uppercase text-white label-input">Informazioni Stato di chiusura R.I.D.</span></div>
                                </AccordionSummary>

                                <AccordionDetails style={{ backgroundColor: '#F2F7F7', padding: '24px', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' }}>
                                    <div>
                                        <div className='mb-5' style={{ display: 'flex', gap: '8px' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <CustomRadio
                                                    disabled={ridState.getRidByIdResponse?.requestState === RequestStateDTO.CHIUSA}
                                                    options={[{ label: '', value: 'CLOSE' }]}
                                                    selectedValue={closeFlag}
                                                    onChange={(value) => setCloseFlag(value)}
                                                    id={'close'}
                                                    name={'close'}
                                                />
                                            </div>
                                            <div style={{ flex: '1' }}>
                                                <div className='text-left'>
                                                    <span className='tit-2-status'>Chiusa </span>
                                                    <span className='tit-1-status'>con i seguenti protocolli</span>
                                                </div>
                                                <div>
                                                    <MultiProtocolSearch
                                                        disabled={ridState.getRidByIdResponse?.requestState === RequestStateDTO.CHIUSA}
                                                        type='entry'
                                                        defaultValue={ridState.ridUpdateRequest.closingProtocolIds}
                                                        onSelect={(value) => dispatch(setUpdateRIDClosingProtocolIds(value))}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='mb-5' style={{ display: 'flex', gap: '8px' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <CustomRadio
                                                    disabled={ridState.getRidByIdResponse?.requestState === RequestStateDTO.CHIUSA}
                                                    options={[{ label: '', value: 'OPEN' }]}
                                                    selectedValue={closeFlag}
                                                    onChange={(value) => setCloseFlag(value)}
                                                    id={'open'}
                                                    name={'open'}
                                                />
                                            </div>
                                            <div style={{ flex: '1' }}>
                                                <div className='text-left'>
                                                    <span className='tit-2-status'>Non Chiusa </span>
                                                    <span className='tit-1-status'>Emissione Provvedimento</span>
                                                </div>
                                                <div>
                                                    <SelectCustom
                                                        disabled={ridState.getRidByIdResponse?.requestState === RequestStateDTO.CHIUSA}
                                                        options={[{ value: '1', label: 'SC E20241129/0001' }]}
                                                        placeholder='Seleziona protocollo...'
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className='pb-3'>
                                            <div className="text-left">
                                                <span className="select-search-title">Note</span>
                                            </div>
                                            <TextArea
                                                disabled={ridState.getRidByIdResponse?.requestState === RequestStateDTO.CHIUSA}
                                                value={ridState.ridUpdateRequest.closedNote}
                                                onChangeText={(text) => dispatch(setUpdateRIDClosedNote(text))} />
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    )
                }

                <div className='mt-6'>
                    <Accordion
                        style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' }}
                        expanded
                        defaultExpanded
                    >
                        <AccordionSummary
                            style={{ borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}
                            aria-controls="panel1-content"
                            className="rounded-xl headingAccordion"
                            id="panel1-header"
                        >
                            <div><span className="text-uppercase text-white label-input">Downlaod Documenti</span></div>
                        </AccordionSummary>

                        <AccordionDetails
                            style={{
                                backgroundColor: "#F2F7F7",
                                padding: "24px",
                                borderBottomLeftRadius: "20px",
                                borderBottomRightRadius: "20px",
                            }}
                        >
                            <div style={{ display: "flex", gap: "32px" }}>
                                <Button
                                    size="sm"
                                    iconPosition="off"
                                    variant="solid"
                                    label="Modello Ufficio Controllo/S.O.T. - Generica"
                                    color="lightGreen"
                                    onClick={() => {
                                        dispatch(DownloadRidDocx({id: ridState.ridIdToUpdate, infoType: 'CONTROLLO'}))
                                    }}
                                />
                                <Button
                                    size="sm"
                                    iconPosition="off"
                                    variant="solid"
                                    label="Modello Ufficio Controllo/S.O.T. - Fase di Avvio"
                                    color="lightGreen"
                                    onClick={() => {
                                        dispatch(DownloadRidDocx({id: ridState.ridIdToUpdate, infoType: 'FASE_DI_AVVIO'}))
                                    }}
                                />
                                <Button
                                    size="sm"
                                    iconPosition="off"
                                    variant="solid"
                                    label="Modello Ufficio Controllo/S.O.T. - Certificazione"
                                    color="lightGreen"
                                    onClick={() => {
                                        dispatch(DownloadRidDocx({id: ridState.ridIdToUpdate, infoType: 'CERTIFICAZIONE'}))
                                    }}
                                />
                            </div>
                        </AccordionDetails>

                    </Accordion>
                </div>
            </div>
            {
                showAlert && (
                    <Alert title="Conferma operazione" close={() => setShowAlert(false)}>
                        <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
                            <div className="pb-3">
                                <div>
                                    Sei sicuro di voler eliminare il R.I.D.?
                                </div>
                            </div>
                            <div className="mt-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    variant="outline"
                                    color="dimgrey"
                                    iconPosition="off"
                                    label="Annulla"
                                    size="md"
                                    onClick={() => setShowAlert(false)}
                                />
                                <div className="pl-8">
                                    <Button
                                        variant="solid"
                                        color="orange"
                                        iconPosition="off"
                                        label="Elimina"
                                        size="md"
                                        onClick={() => {
                                            dispatch(RIDDelete(ridState.ridIdToUpdate))
                                            setShowAlert(false)
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                    </Alert>
                )
            }
        </div>
    )
}