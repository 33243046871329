import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../utils";
import { AdditionalExpenseCreationDTO, AdditionalExpenseDTO, AdditionalExpenseUpdateDTO, BillingWorksheetCreationDTO, BillingWorksheetDTO, BillingWorksheetUpdateDTO, CustomerBillingWorksheetCreationDTO, CustomerBillingWorksheetDTO, CustomerBillingWorksheetUpdateDTO, GetAllAdditionalExpenseDTO, GetAllAdditionalExpenseFiltersDTO, GetAllBillingWorksheetsDTO, GetAllBillingWorksheetsFiltersDTO, GetAllCustomerBillingWorksheetDTO, GetAllCustomerBillingWorksheetFiltersDTO, GetAllServiceDTO, GetAllServiceFiltersDTO, GetAllServiceTypeDTO, GetAllServiceTypeFiltersDTO, ServiceCreationDTO, ServiceDTO, ServiceTypeCreationDTO, ServiceTypeDTO, ServiceTypeUpdateDTO, ServiceUpdateDTO } from "./dataService/dto";
import { NewAdditionalExpenseService, NewBillingWorksheetService, NewCustomerBillingWorksheetService, NewServiceEntityService, NewServiceTypeService } from "./dataService/service";

interface BillingWorksheetServiceState {
    onEdit: boolean

    // REQUEST [BILLING WORKSHEET]
    billingWorksheetCreationRequest: BillingWorksheetCreationDTO
    billingWorksheetUpdateRequest: BillingWorksheetUpdateDTO
    billingWorksheetIdToUpdate: string

    // ERRORS [BILLING WORKSHEET]

    // FILTERS [BILLING WORKSHEET]
    getAllBillingWorksheetFilters: GetAllBillingWorksheetsFiltersDTO

    // STATUS [BILLING WORKSHEET]
    billingWorksheetCreationStatus: PromiseStatuses
    billingWorksheetUpdateStatus: PromiseStatuses
    billingWorksheetDeleteStatus: PromiseStatuses
    getAllBillingWorksheetStatus: PromiseStatuses
    getBillingWorksheetByIdStatus: PromiseStatuses

    // RESPONSE [BILLING WORKSHEET]
    billingWorksheetCreationResponse?: string
    getAllBillingWorksheetResponse?: GetAllBillingWorksheetsDTO
    getBillingWorksheetByIdResponse?: BillingWorksheetDTO

    /////////////////////////////////////////////////////////////

    // REQUEST [SERVICE TYPE]
    serviceTypeCreationRequest: ServiceTypeCreationDTO
    serviceTypeUpdateRequest: ServiceTypeUpdateDTO
    serviceTypeIdToUpdate: string

    // ERRORS [SERVICE TYPE]
    serviceErrors: {
        serviceTypeId: boolean
        unitEmployed: boolean
    }

    // FILTERS [SERVICE TYPE]
    getAllServiceTypeFilters: GetAllServiceTypeFiltersDTO

    // STATUS [SERVICE TYPE]
    serviceTypeCreationStatus: PromiseStatuses
    serviceTypeUpdateStatus: PromiseStatuses
    serviceTypeDeleteStatus: PromiseStatuses
    getAllServiceTypeStatus: PromiseStatuses
    getServiceTypeByIdStatus: PromiseStatuses

    // RESPONSE [SERVICE TYPE]
    getAllServiceTypeResponse?: GetAllServiceTypeDTO
    getServiceTypeByIdResponse?: ServiceTypeDTO

    /////////////////////////////////////////////////////////////

    // REQUEST [SERVICE]
    serviceCreationRequest: ServiceCreationDTO
    serviceUpdateRequest: ServiceUpdateDTO
    serviceIdToUpdate: string

    // ERRORS [SERVICE]

    // FILTERS [SERVICE]
    getAllServiceFilters: GetAllServiceFiltersDTO

    // STATUS [SERVICE]
    serviceValidationStatus: PromiseStatuses
    serviceCreationStatus: PromiseStatuses
    serviceUpdateStatus: PromiseStatuses
    serviceDeleteStatus: PromiseStatuses
    getAllServiceStatus: PromiseStatuses
    getServiceByIdStatus: PromiseStatuses

    // RESPONSE [SERVICE]
    getAllServiceResponse?: GetAllServiceDTO
    getServiceByIdResponse?: ServiceDTO

    /////////////////////////////////////////////////////////////

    // REQUEST [ADDITIONAL EXPENSE]
    additionalExpenseCreationRequest: AdditionalExpenseCreationDTO
    additionalExpenseUpdateRequest: AdditionalExpenseUpdateDTO
    additionalExpenseIdToUpdate: string

    // ERRORS [ADDITIONAL EXPENSE]
    additionalErrors: {
        serviceTypeId: boolean
        taxableFee: boolean
    }

    // FILTERS [ADDITIONAL EXPENSE]
    getAllAdditionalExpenseFilters: GetAllAdditionalExpenseFiltersDTO

    // STATUS [ADDITIONAL EXPENSE]
    additionalExpenseValidationStatus: PromiseStatuses
    additionalExpenseCreationStatus: PromiseStatuses
    additionalExpenseUpdateStatus: PromiseStatuses
    additionalExpenseDeleteStatus: PromiseStatuses
    getAllAdditionalExpenseStatus: PromiseStatuses
    getAdditionalExpenseByIdStatus: PromiseStatuses

    // RESPONSE [ADDITIONAL EXPENSE]
    getAllAdditionalExpenseResponse?: GetAllAdditionalExpenseDTO
    getAdditionalExpenseByIdResponse?: AdditionalExpenseDTO

    /////////////////////////////////////////////////////////////

    // REQUEST [CUSTOMER BW]
    customerBillingWorksheetCreationRequest: CustomerBillingWorksheetCreationDTO
    customerBillingWorksheetUpdateRequest: CustomerBillingWorksheetUpdateDTO
    customerBillingWorksheetIdToUpdate: string

    // ERRORS [CUSTOMER BW]

    // FILTERS [CUSTOMER BW]
    getAllCustomerBillingWorksheetFilters: GetAllCustomerBillingWorksheetFiltersDTO

    // STATUS [CUSTOMER BW]
    customerBillingWorksheetCreationStatus: PromiseStatuses
    customerBillingWorksheetUpdateStatus: PromiseStatuses
    customerBillingWorksheetDeleteStatus: PromiseStatuses
    getAllCustomerBillingWorksheetStatus: PromiseStatuses
    getCustomerBillingWorksheetByIdStatus: PromiseStatuses

    // RESPONSE [CUSTOMER BW]
    getAllCustomerBillingWorksheetResponse?: GetAllCustomerBillingWorksheetDTO
    getCustomerBillingWorksheetByIdResponse?: CustomerBillingWorksheetDTO
}

const initialState: BillingWorksheetServiceState = {
    /* BILLING WORKSHEET */
    onEdit: false,

    billingWorksheetCreationRequest: {
        year: new Date().getFullYear()
    },
    billingWorksheetUpdateRequest: {},
    billingWorksheetIdToUpdate: '',

    getAllBillingWorksheetFilters: {
        itemsPerPage: 0,
        page: 0
    },

    billingWorksheetCreationStatus: 'idle',
    billingWorksheetUpdateStatus: 'idle',
    billingWorksheetDeleteStatus: 'idle',
    getAllBillingWorksheetStatus: 'idle',
    getBillingWorksheetByIdStatus: 'idle',

    /////////////////////////////////////////////////////////////

    /* SERVICE TYPE */
    serviceTypeCreationRequest: {},
    serviceTypeUpdateRequest: {},
    serviceTypeIdToUpdate: '',

    getAllServiceTypeFilters: {
        itemsPerPage: 0,
        page: 0
    },

    serviceTypeCreationStatus: 'idle',
    serviceTypeUpdateStatus: 'idle',
    serviceTypeDeleteStatus: 'idle',
    getAllServiceTypeStatus: 'idle',
    getServiceTypeByIdStatus: 'idle',

    /////////////////////////////////////////////////////////////

    /* SERVICE */
    serviceCreationRequest: {},
    serviceUpdateRequest: {},
    serviceIdToUpdate: '',

    serviceErrors: {
        serviceTypeId: false,
        unitEmployed: false
    },

    getAllServiceFilters: {
        itemsPerPage: 0,
        page: 0
    },

    serviceValidationStatus: 'idle',
    serviceCreationStatus: 'idle',
    serviceUpdateStatus: 'idle',
    serviceDeleteStatus: 'idle',
    getAllServiceStatus: 'idle',
    getServiceByIdStatus: 'idle',

    /////////////////////////////////////////////////////////////

    /* ADDITIONAL EXPENSE */
    additionalExpenseCreationRequest: {},
    additionalExpenseUpdateRequest: {},
    additionalExpenseIdToUpdate: '',

    additionalErrors: {
        serviceTypeId: false,
        taxableFee: false
    },

    getAllAdditionalExpenseFilters: {
        itemsPerPage: 0,
        page: 0
    },

    additionalExpenseValidationStatus: 'idle',
    additionalExpenseCreationStatus: 'idle',
    additionalExpenseUpdateStatus: 'idle',
    additionalExpenseDeleteStatus: 'idle',
    getAllAdditionalExpenseStatus: 'idle',
    getAdditionalExpenseByIdStatus: 'idle',

    /////////////////////////////////////////////////////////////

    /* CUSTOMER BW */
    customerBillingWorksheetCreationRequest: {},
    customerBillingWorksheetUpdateRequest: {},
    customerBillingWorksheetIdToUpdate: '',

    getAllCustomerBillingWorksheetFilters: {
        itemsPerPage: 0,
        page: 0
    },

    customerBillingWorksheetCreationStatus: 'idle',
    customerBillingWorksheetUpdateStatus: 'idle',
    customerBillingWorksheetDeleteStatus: 'idle',
    getAllCustomerBillingWorksheetStatus: 'idle',
    getCustomerBillingWorksheetByIdStatus: 'idle'
}

export const BillingWorksheetCreation = createAsyncThunk(
    'BW/Creation',
    async (body: BillingWorksheetCreationDTO, thunkApi): Promise<string> => {
        const BWService = NewBillingWorksheetService()
        return BWService.CreateBillingWorksheet(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const BillingWorksheetUpdate = createAsyncThunk(
    'BW/Update',
    async (request: {body: BillingWorksheetUpdateDTO, id: string}, thunkApi): Promise<void> => {
        const BWService = NewBillingWorksheetService()
        return BWService.UpdateBillingWorksheetById(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    }
)

export const BillingWorksheetDelete = createAsyncThunk(
    'BW/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const BWService = NewBillingWorksheetService()
        return BWService.DeleteBillingWorksheet(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllBillingWorksheet = createAsyncThunk(
    'BW/GetAll',
    async (filters: GetAllBillingWorksheetsFiltersDTO, thunkApi): Promise<GetAllBillingWorksheetsDTO> => {
        const BWService = NewBillingWorksheetService()
        return BWService.GetAllBillingWorksheet(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetBillingWorksheetById = createAsyncThunk(
    'BW/GetbyId',
    async (id: string, thunkApi): Promise<BillingWorksheetDTO> => {
        const BWService = NewBillingWorksheetService()
        return BWService.GetBillingWorksheetById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const ServiceTypeCreation = createAsyncThunk(
    'serviceType/Creation',
    async (body: ServiceTypeCreationDTO, thunkApi): Promise<void> => {
        const STService = NewServiceTypeService()
        return STService.CreateServiceType(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const ServiceTypeUpdate = createAsyncThunk(
    'serviceType/Update',
    async (request: {body: ServiceTypeUpdateDTO, id: string}, thunkApi): Promise<void> => {
        const STService = NewServiceTypeService()
        return STService.UpdateServiceType(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const ServiceTypeDelete = createAsyncThunk(
    'serviceType/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const STService = NewServiceTypeService()
        return STService.DeleteServiceType(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllServiceType = createAsyncThunk(
    'serviceType/GetAll',
    async (filters: GetAllServiceTypeFiltersDTO, thunkApi): Promise<GetAllServiceTypeDTO> => {
        const STService = NewServiceTypeService()
        return STService.GetAllServiceType(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetServiceTypeById = createAsyncThunk(
    'serviceType/GetById',
    async (id: string, thunkApi): Promise<ServiceTypeDTO> => {
        const STService = NewServiceTypeService()
        return STService.GetServiceTypeById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const ServiceValidation = createAsyncThunk(
    'service/Validation',
    async (request: ServiceCreationDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetServiceErrors())

        if(request.serviceTypeId === undefined) {
            thunkApi.dispatch(setValidateServiceServiceTypeId(true))
            isValid = false
        }

        if(request.unitEmployed === undefined) {
            thunkApi.dispatch(setValidateServiceUnitEmployed(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)

export const ServiceCreation = createAsyncThunk(
    'service/Creation',
    async (body: ServiceCreationDTO, thunkApi): Promise<void> => {
        const SEService = NewServiceEntityService()
        return SEService.CreateService(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const ServiceUpdate = createAsyncThunk(
    'service/Update',
    async (request: {body: ServiceUpdateDTO, id: string}, thunkApi): Promise<void> => {
        const SEService = NewServiceEntityService()
        return SEService.UpdateService(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const ServiceDelete = createAsyncThunk(
    'service/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const SEService = NewServiceEntityService()
        return SEService.DeleteService(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllService = createAsyncThunk(
    'service/GetAll',
    async (filters: GetAllServiceFiltersDTO, thunkApi): Promise<GetAllServiceDTO> => {
        const SEService = NewServiceEntityService()
        return SEService.GetAllService(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetServiceById = createAsyncThunk(
    'service/GetById',
    async (id: string, thunkApi): Promise<ServiceDTO> => {
        const SEService = NewServiceEntityService()
        return SEService.GetServiceById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const AdditionalExpenseValidation = createAsyncThunk(
    'additionalExpense/Validation',
    async (request: AdditionalExpenseCreationDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetAdditionalErrors())

        if(request.serviceTypeId === undefined) {
            thunkApi.dispatch(setValidateAdditionalServiceTypeId(true))
            isValid = false
        }

        if(request.taxableFee === undefined) {
            thunkApi.dispatch(setValidateAdditionalTaxableFee(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)

export const AdditionalExpenseCreation = createAsyncThunk(
    'additionalExpense/Creation',
    async (body: AdditionalExpenseCreationDTO, thunkApi): Promise<void> => {
        const AEService = NewAdditionalExpenseService()
        return AEService.CreateAdditionalExpense(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const AdditionalExpenseUpdate = createAsyncThunk(
    'additionalExpense/Update',
    async (request: {body: AdditionalExpenseUpdateDTO, id: string}, thunkApi): Promise<void> => {
        const AEService = NewAdditionalExpenseService()
        return AEService.UpdateAdditionalExpense(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const AdditionalExpenseDelete = createAsyncThunk(
    'additionalExpense/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const AEService = NewAdditionalExpenseService()
        return AEService.DeleteAdditionalExpense(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllAdditionalExpense = createAsyncThunk(
    'additionalExpense/GetAll',
    async (filters: GetAllAdditionalExpenseFiltersDTO, thunkApi): Promise<GetAllAdditionalExpenseDTO> => {
        const AEService = NewAdditionalExpenseService()
        return AEService.GetAllAdditionalExpense(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAdditionalExpenseById = createAsyncThunk(
    'additionalExpense/GetById',
    async (id: string, thunkApi): Promise<AdditionalExpenseDTO> => {
        const AEService = NewAdditionalExpenseService()
        return AEService.GetAdditionalExpenseById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const CustomerBillingWorksheetCreation = createAsyncThunk(
    'customerBW/Creation',
    async (body: CustomerBillingWorksheetCreationDTO, thunkApi): Promise<void> => {
        const CustomerBWService = NewCustomerBillingWorksheetService()
        return CustomerBWService.CreateCustomerBillingWorksheet(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const CustomerBillingWorksheetUpdate = createAsyncThunk(
    'customerBW/Update',
    async (request: {body: CustomerBillingWorksheetUpdateDTO, id: string}, thunkApi): Promise<void> => {
        const CustomerBWService = NewCustomerBillingWorksheetService()
        return CustomerBWService.UpdateCustomerBillingWorksheet(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const CustomerBillingWorksheetDelete = createAsyncThunk(
    'customerBW/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const CustomerBWService = NewCustomerBillingWorksheetService()
        return CustomerBWService.DeleteCustomerBillingWorksheet(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllCustomerBillingWorksheet = createAsyncThunk(
    'customerBW/GetAll',
    async (filters: GetAllCustomerBillingWorksheetFiltersDTO, thunkApi): Promise<GetAllCustomerBillingWorksheetDTO> => {
        const CustomerBWService = NewCustomerBillingWorksheetService()
        return CustomerBWService.GetAllCustomerBillingWorksheet(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetCustomerBillingWorksheetById = createAsyncThunk(
    'customerBW/GetById',
    async (id: string, thunkApi): Promise<CustomerBillingWorksheetDTO> => {
        const CustomerBWService = NewCustomerBillingWorksheetService()
        return CustomerBWService.GetCustomerBillingWorksheetById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

const BillingWorksheetServiceTypeSlice = createSlice({
    name: 'billingWorksheet/ServiceType/slice',
    initialState,
    reducers: {

        /* BILLING WORKSHEET */

        setOnEdit: (state, action) => {
            state.onEdit = action.payload
        },

        // REQUEST (CREATION/UPDATE)
        setBWCreationNorm: (state, action) => {
            state.billingWorksheetCreationRequest.norm = action.payload
        },
        setBWCreationYear: (state, action) => {
            state.billingWorksheetCreationRequest.year = action.payload
        }, 
        setBWCreationCloneServiceTypesFromYear: (state, action) => {
            state.billingWorksheetCreationRequest.yearToCloneServiceTypes = action.payload
        },
        resetBWCreationRequest: (state) => {
            state.billingWorksheetCreationRequest = {
                year: new Date().getFullYear()
            }
        },

        setBWIdToUpdate: (state, action) => {
            state.billingWorksheetIdToUpdate = action.payload
        },
        setBWUpdateCloneServiceTypesFromYear: (state, action) => {
            state.billingWorksheetUpdateRequest.yearToCloneServiceTypes = action.payload
        },
        resetBWUpdateRequest: (state) => {
            state.billingWorksheetUpdateRequest = {}
        },

        // ERRORS

        // FILTERS
        setGetAllBWItemsPerPage: (state, action) => {
            state.getAllBillingWorksheetFilters.itemsPerPage = action.payload
        },
        setGetAllBWPage: (state, action) => {
            state.getAllBillingWorksheetFilters.page = action.payload
        },
        setGetAllBWOrder: (state, action) => {
            state.getAllBillingWorksheetFilters.order = action.payload
        },
        setGetAllBWSort: (state, action) => {
            state.getAllBillingWorksheetFilters.sort = action.payload
        },
        setGetAllBWNorm: (state, action) => {
            state.getAllBillingWorksheetFilters.norm = action.payload
        },
        setGetAllBWYear: (state, action) => {
            state.getAllBillingWorksheetFilters.year = action.payload
        },
        resetGetAllBWFilters: (state) => {
            state.getAllBillingWorksheetFilters = {
                itemsPerPage: 0,
                page: 0
            }
        },

        // STATUS
        resetBWCreationStatus: (state) => {
            state.billingWorksheetCreationStatus = 'idle'
        },
        resetBWUpdateStatus: (state) => {
            state.billingWorksheetUpdateStatus = 'idle'
        },
        resetBWDeleteStatus: (state) => {
            state.billingWorksheetDeleteStatus = 'idle'
        },
        resetGetAllBWStatus: (state) => {
            state.getAllBillingWorksheetStatus = 'idle'
        },
        resetGetBWById: (state) => {
            state.getBillingWorksheetByIdStatus = 'idle'
        },

        /* SERVICE TYPE */

        // REQUEST (CREATION/UPDATE)
        setSTCreationCategory: (state, action) => {
            state.serviceTypeCreationRequest.category = action.payload
        },
        setSTCreationCode: (state, action) => {
            state.serviceTypeCreationRequest.code = action.payload
        },
        setSTCreationDescription: (state, action) => {
            state.serviceTypeCreationRequest.description = action.payload
        },
        setSTCreationNote: (state, action) => {
            state.serviceTypeCreationRequest.note = action.payload
        },
        setSTCreationUnitType: (state, action) => {
            state.serviceTypeCreationRequest.unitType = action.payload
        },
        setSTCreationAmountPerUnit: (state, action) => {
            state.serviceTypeCreationRequest.amountPerUnit = action.payload
        },
        setSTCreationBillingWorksheetId: (state, action) => {
            state.serviceTypeCreationRequest.billingWorksheetId = action.payload
        },
        resetSTCreationRequest: (state) => {
            state.serviceTypeCreationRequest = {}
        },

        setSTIdToUpdate: (state, action) => {
            state.serviceTypeIdToUpdate = action.payload
        },
        setSTUpdateCategory: (state, action) => {
            state.serviceTypeUpdateRequest.category = action.payload
        },
        setSTUpdateCode: (state, action) => {
            state.serviceTypeUpdateRequest.code = action.payload
        },
        setSTUpdateDescription: (state, action) => {
            state.serviceTypeUpdateRequest.description = action.payload
        },
        setSTUpdateNote: (state, action) => {
            state.serviceTypeUpdateRequest.note = action.payload
        },
        setSTUpdateUnitType: (state, action) => {
            state.serviceTypeUpdateRequest.unitType = action.payload
        },
        setSTUpdateAmountPerUnit: (state, action) => {
            state.serviceTypeUpdateRequest.amountPerUnit = action.payload
        },
        resetSTUpdateRequest: (state) => {
            state.serviceTypeCreationRequest = {}
        },

        // ERRORS

        // FILTERS
        setGetAllSTItemsPerPage: (state, action) => {
            state.getAllServiceTypeFilters.itemsPerPage = action.payload
        },
        setGetAllSTPage: (state, action) => {
            state.getAllServiceTypeFilters.page = action.payload
        },
        setGetAllSTOrder: (state, action) => {
            state.getAllServiceTypeFilters.order = action.payload
        },
        setGetAllSTSort: (state, action) => {
            state.getAllServiceTypeFilters.sort = action.payload
        },
        setGetAllSTYear: (state, action) => {
            state.getAllServiceTypeFilters.year = action.payload
        },
        setGetAllSTCategory: (state, action) => {
            state.getAllServiceTypeFilters.category = action.payload
        },
        setGetAllSTCode: (state, action) => {
            state.getAllServiceTypeFilters.code = action.payload
        },
        setGetAllSTNorm: (state, action) => {
            state.getAllServiceTypeFilters.norm = action.payload
        },
        setGetAllSTbillingWorksheetId: (state, action) => {
            state.getAllServiceTypeFilters.billingWorksheetId = action.payload
        },
        resetGetAllSTFilter: (state) => {
            state.getAllServiceTypeFilters = {
                itemsPerPage: 0,
                page: 0
            }
        },

        // STATUS
        resetSTCreationStatus: (state) => {
            state.serviceTypeCreationStatus = 'idle'
        },
        resetSTUpdateStatus: (state) => {
            state.serviceTypeUpdateStatus = 'idle'
        },
        resetSTDeleteStatus: (state) => {
            state.serviceTypeDeleteStatus = 'idle'
        },
        resetGetAllSTStatus: (state) => {
            state.getAllServiceTypeStatus = 'idle'
        },
        resetGetSTByIdStatus: (state) => {
            state.getServiceByIdStatus = 'idle'
        },

        /* SERVICE */

        // REQUEST (CREATION/UPDATE)
        setServiceCreationServiceTypeId: (state, action) => {
            state.serviceCreationRequest.serviceTypeId = action.payload
        },
        setServiceCreationDate: (state, action) => {
            state.serviceCreationRequest.date = action.payload
        },
        setServiceCreationOfficerId: (state, action) => {
            state.serviceCreationRequest.officerId = action.payload
        },
        setServiceCreationUnitEmployed: (state, action) => {
            state.serviceCreationRequest.unitEmployed = action.payload
        },
        setServiceCreationCustomerBillingWorksheetId: (state, action) => {
            state.serviceCreationRequest.customerBillingWorksheetId = action.payload
        },
        resetServiceCreationRequest: (state) => {
            state.serviceCreationRequest = {}
        },

        setServiceIdToUpdate: (state, action) => {
            state.serviceIdToUpdate = action.payload
        },
        setServiceUpdateServiceTypeId: (state, action) => {
            state.serviceUpdateRequest.serviceTypeId = action.payload
        },
        setServiceUpdateUnitEmployed: (state, action) => {
            state.serviceUpdateRequest.unitEmployed = action.payload
        },
        resetServiceUpdateRequest: (state, action) => {
            state.serviceUpdateRequest = {}
        },

        // ERROR
        setValidateServiceServiceTypeId: (state, action) => {
            state.serviceErrors.serviceTypeId = action.payload
        },
        setValidateServiceUnitEmployed: (state, action) => {
            state.serviceErrors.unitEmployed = action.payload
        },
        resetServiceErrors: (state) => {
            state.serviceErrors = {
                serviceTypeId: false,
                unitEmployed: false
            }
        },

        // FILTERS
        setGetAllServiceItemsPerPage: (state, action) => {
            state.getAllServiceFilters.itemsPerPage = action.payload
        },
        setGetAllServicePage: (state, action) => {
            state.getAllServiceFilters.page = action.payload
        },
        setGetAllServiceOrder: (state, action) => {
            state.getAllServiceFilters.order = action.payload
        },
        setGetAllServiceSort: (state, action) => {
            state.getAllServiceFilters.sort = action.payload
        },
        setGetAllServiceCustomerBillingWorksheetId: (state, action) => {
            state.getAllServiceFilters.customerBillingWorksheetId = action.payload
        },
        setGetAllServiceOfficerId: (state, action) => {
            state.getAllServiceFilters.officerId = action.payload
        },
        setGetAllServiceServiceTypeId: (state, action) => {
            state.getAllServiceFilters.serviceTypeId = action.payload
        },
        setGetAllServiceCustomerId: (state, action) => {
            state.getAllServiceFilters.customerId = action.payload
        },
        resetGetAllServiceFilters: (state) => {
            state.getAllServiceFilters = {
                itemsPerPage: 0,
                page: 0
            }
        },

        // STATUS
        resetServiceValidationStatus: (state) => {
            state.serviceValidationStatus = 'idle'
        },
        resetServiceCreationStatus: (state) => {
            state.serviceCreationStatus = 'idle'
        },
        resetServiceUpdateStatus: (state) => {
            state.serviceUpdateStatus = 'idle'
        },
        resetServiceDeleteStatus: (state) => {
            state.serviceDeleteStatus = 'idle'
        },
        resetGetAllServiceStatus: (state) => {
            state.getAllServiceStatus = 'idle'
        },
        resetGetServiceByIdStatus: (state) => {
            state.getServiceByIdStatus = 'idle'
        },

        /* ADDITIONAL EXPENSE */

        // REQUEST (CREATION/UPDATE)
        setAECreationOfficerId: (state, action) => {
            state.additionalExpenseCreationRequest.officerId = action.payload
        },
        setAECreationTaxableFee: (state, action) => {
            state.additionalExpenseCreationRequest.taxableFee = action.payload
        },
        setAECreationServiceTypeId: (state, action) => {
            state.additionalExpenseCreationRequest.serviceTypeId = action.payload
        },
        setAECreationCustomerBWId: (state, action) => {
            state.additionalExpenseCreationRequest.customerBillingWorksheetId = action.payload
        },
        resetAECreationRequest: (state) => {
            state.additionalExpenseCreationRequest = {}
        },

        setAEIdToUpdate: (state, action) => {
            state.additionalExpenseIdToUpdate = action.payload
        },
        setAEUpdateCode: (state, action) => {
            state.additionalExpenseUpdateRequest.code = action.payload
        },
        setAEUpdateDescription: (state, action) => {
            state.additionalExpenseUpdateRequest.description = action.payload
        },
        setAEUpdateNote: (state, action) => {
            state.additionalExpenseUpdateRequest.note = action.payload
        },
        setAEUpdateServiceTypeId: (state, action) => {
            state.additionalExpenseUpdateRequest.serviceTypeId = action.payload
        },
        setAEUpdateAmount: (state, action) => {
            state.additionalExpenseUpdateRequest.amount = action.payload
        },
        resetAEUpdateRequest: (state) => {
            state.additionalExpenseUpdateRequest = {}
        },

        // ERRORS
        setValidateAdditionalServiceTypeId: (state, action) => {
            state.additionalErrors.serviceTypeId = action.payload
        },
        setValidateAdditionalTaxableFee: (state, action) => {
            state.additionalErrors.taxableFee = action.payload
        },
        resetAdditionalErrors: (state) => {
            state.additionalErrors = {
                serviceTypeId: false,
                taxableFee: false
            }
        },

        // FILTERS
        setGetAllAEItemsPerPage: (state, action) => {
            state.getAllAdditionalExpenseFilters.itemsPerPage = action.payload
        },
        setGetAllAEPage: (state, action) => {
            state.getAllAdditionalExpenseFilters.page = action.payload
        },
        setGetAllAEOrder: (state, action) => {
            state.getAllAdditionalExpenseFilters.order = action.payload
        },
        setGetAllAESort: (state, action) => {
            state.getAllAdditionalExpenseFilters.sort = action.payload
        },
        setGetAllAECustomerBillingWorksheetId: (state, action) => {
            state.getAllAdditionalExpenseFilters.customerBillingWorksheetId = action.payload
        },
        setGetAllAECode: (state, action) => {
            state.getAllAdditionalExpenseFilters.code = action.payload
        },
        resetGetAllAEFilters: (state) => {
            state.getAllAdditionalExpenseFilters = {
                itemsPerPage: 0,
                page: 0
            }
        },

        // STATUS
        resetAEValidationStatus: (state) => {
            state.additionalExpenseValidationStatus = 'idle'
        },
        resetAECreationStatus: (state) => {
            state.additionalExpenseCreationStatus = 'idle'
        },
        resetAEUpdateStatus: (state) => {
            state.additionalExpenseUpdateStatus = 'idle'
        },
        resetAEDeleteStatus: (state) => {
            state.additionalExpenseDeleteStatus = 'idle'
        },
        resetGetAllAEStatus: (state) => {
            state.getAllAdditionalExpenseStatus = 'idle'
        },
        resetGetAEByIdStatus: (state) => {
            state.getAdditionalExpenseByIdStatus = 'idle'
        },

        /* CUSTOMER BW */

        // REQUEST (CREATION/UPDATE)
        setCustomerBWCreationBillingWorksheetId: (state, action) => {
            state.customerBillingWorksheetCreationRequest.billingWorksheetId = action.payload
        },
        setCustomerBWCreationCustomerId: (state, action) => {
            state.customerBillingWorksheetCreationRequest.customerId = action.payload
        },
        resetCustomerBWCreationRequest: (state) => {
            state.customerBillingWorksheetCreationRequest = {}
        },

        setCustomerBWIdToUpdate: (state, action) => {
            state.customerBillingWorksheetIdToUpdate = action.payload
        },
        setCustomerBWUpdateServiceIds: (state, action) => {
            state.customerBillingWorksheetUpdateRequest.serviceIds = action.payload
        },
        setCustomerBWUpdateAdditionalExpenseIds: (state, action) => {
            state.customerBillingWorksheetUpdateRequest.additionalExpenseIds = action.payload
        },
        resetCustomerBWUpdateRequest: (state) => {
            state.customerBillingWorksheetUpdateRequest = {}
        },

        // FILTERS
        setGetAllCustomerBWItemsPerPage: (state, action) => {
            state.getAllCustomerBillingWorksheetFilters.itemsPerPage = action.payload
        },
        setGetAllCustomerBWPage: (state, action) => {
            state.getAllCustomerBillingWorksheetFilters.page = action.payload
        },
        setGetAllCustomerBWOrder: (state, action) => {
            state.getAllCustomerBillingWorksheetFilters.order = action.payload
        },
        setGetAllCustomerBWSort: (state, action) => {
            state.getAllCustomerBillingWorksheetFilters.sort = action.payload
        },
        setGetAllCustomerBWBillingWorksheetId: (state, action) => {
            state.getAllCustomerBillingWorksheetFilters.billingWorksheetId = action.payload
        },
        setGetAllCustomerBWCustomerId: (state, action) => {
            state.getAllCustomerBillingWorksheetFilters.customerId = action.payload
        },
        resetGetAllCustomerBWFilters: (state) => {
            state.getAllCustomerBillingWorksheetFilters = {
                itemsPerPage: 0,
                page: 0
            }
        },

        // STATUS
        resetCustomerBWCreationStatus: (state) => {
            state.customerBillingWorksheetCreationStatus = 'idle'
        },
        resetCustomerBWUpdateStatus: (state) => {
            state.customerBillingWorksheetUpdateStatus = 'idle'
        },
        resetCustomerBWDeleteStatus: (state) => {
            state.customerBillingWorksheetDeleteStatus = 'idle'
        },
        resetGetAllCustomerBWStatus: (state) => {
            state.getAllCustomerBillingWorksheetStatus = 'idle'
        },
        resetGetCustomerBWByIdStatus: (state) => {
            state.getCustomerBillingWorksheetByIdStatus = 'idle'
        },
        
    },
    extraReducers(builder) {
        builder

            // [BILLING WORKSHEET]
            // CREATION
            .addCase(BillingWorksheetCreation.pending, (state) => {
                state.billingWorksheetCreationStatus = 'loading'
            })
            .addCase(BillingWorksheetCreation.fulfilled, (state, action) => {
                state.billingWorksheetCreationStatus = 'successfully'
                state.billingWorksheetCreationResponse = action.payload
            })
            .addCase(BillingWorksheetCreation.rejected, (state, action) => {
                state.billingWorksheetCreationStatus = 'failed'
                state.billingWorksheetCreationResponse = action.error.message
            })

            // UPDATE
            .addCase(BillingWorksheetUpdate.pending, (state) => {
                state.billingWorksheetUpdateStatus = 'loading'
            })
            .addCase(BillingWorksheetUpdate.fulfilled, (state, action) => {
                state.billingWorksheetUpdateStatus = 'successfully'
            })
            .addCase(BillingWorksheetUpdate.rejected, (state, action) => {
                state.billingWorksheetUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(BillingWorksheetDelete.pending, (state) => {
                state.billingWorksheetDeleteStatus = 'loading'
            })
            .addCase(BillingWorksheetDelete.fulfilled, (state) => {
                state.billingWorksheetDeleteStatus = 'successfully'
            })
            .addCase(BillingWorksheetDelete.rejected, (state) => {
                state.billingWorksheetDeleteStatus = 'failed'
            })

            // GET ALL
            .addCase(GetAllBillingWorksheet.pending, (state) => {
                state.getAllBillingWorksheetStatus = 'loading'
            })
            .addCase(GetAllBillingWorksheet.fulfilled, (state, action) => {
                state.getAllBillingWorksheetStatus = 'successfully'
                state.getAllBillingWorksheetResponse = action.payload
            })
            .addCase(GetAllBillingWorksheet.rejected, (state) => {
                state.getAllBillingWorksheetStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetBillingWorksheetById.pending, (state) => {
                state.getBillingWorksheetByIdStatus = 'loading'
            })
            .addCase(GetBillingWorksheetById.fulfilled, (state, action) => {
                state.getBillingWorksheetByIdStatus = 'successfully'
                state.getBillingWorksheetByIdResponse = action.payload
            })
            .addCase(GetBillingWorksheetById.rejected, (state) => {
                state.getBillingWorksheetByIdStatus = 'failed'
            })

            // [SERVICE TYPE]
            // CREATION
            .addCase(ServiceTypeCreation.pending, (state) => {
                state.serviceTypeCreationStatus = 'loading'
            })
            .addCase(ServiceTypeCreation.fulfilled, (state) => {
                state.serviceTypeCreationStatus = 'successfully'
            })
            .addCase(ServiceTypeCreation.rejected, (state) => {
                state.serviceTypeCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(ServiceTypeUpdate.pending, (state) => {
                state.serviceTypeUpdateStatus = 'loading'
            })
            .addCase(ServiceTypeUpdate.fulfilled, (state) => {
                state.serviceTypeUpdateStatus = 'successfully'
            })
            .addCase(ServiceTypeUpdate.rejected, (state) => {
                state.serviceTypeUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(ServiceTypeDelete.pending, (state) => {
                state.serviceTypeDeleteStatus = 'loading'
            })
            .addCase(ServiceTypeDelete.fulfilled, (state) => {
                state.serviceTypeDeleteStatus = 'successfully'
            })
            .addCase(ServiceTypeDelete.rejected, (state) => {
                state.serviceTypeDeleteStatus = 'failed'
            })

            // GET ALL
            .addCase(GetAllServiceType.pending, (state) => {
                state.getAllServiceTypeStatus = 'loading'
            })
            .addCase(GetAllServiceType.fulfilled, (state, action) => {
                state.getAllServiceTypeStatus = 'successfully'
                state.getAllServiceTypeResponse = action.payload
            })
            .addCase(GetAllServiceType.rejected, (state) => {
                state.getAllServiceTypeStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetServiceTypeById.pending, (state) => {
                state.getServiceTypeByIdStatus = 'loading'
            })
            .addCase(GetServiceTypeById.fulfilled, (state, action) => {
                state.getServiceTypeByIdStatus = 'successfully'
                state.getServiceTypeByIdResponse = action.payload
            })
            .addCase(GetServiceTypeById.rejected, (state) => {
                state.getServiceTypeByIdStatus = 'failed'
            })

            // [SERVICE]
            // CREATION
            .addCase(ServiceCreation.pending, (state) => {
                state.serviceCreationStatus = 'loading'
            })
            .addCase(ServiceCreation.fulfilled, (state) => {
                state.serviceCreationStatus = 'successfully'
            })
            .addCase(ServiceCreation.rejected, (state) => {
                state.serviceCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(ServiceUpdate.pending, (state) => {
                state.serviceUpdateStatus = 'loading'
            })
            .addCase(ServiceUpdate.fulfilled, (state) => {
                state.serviceUpdateStatus = 'successfully'
            })
            .addCase(ServiceUpdate.rejected, (state) => {
                state.serviceUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(ServiceDelete.pending, (state) => {
                state.serviceDeleteStatus = 'loading'
            })
            .addCase(ServiceDelete.fulfilled, (state) => {
                state.serviceDeleteStatus = 'successfully'
            })
            .addCase(ServiceDelete.rejected, (state) => {
                state.serviceDeleteStatus = 'failed'
            })

            // GET ALL
            .addCase(GetAllService.pending, (state) => {
                state.getAllServiceStatus = 'loading'
            })
            .addCase(GetAllService.fulfilled, (state, action) => {
                state.getAllServiceStatus = 'successfully'
                state.getAllServiceResponse = action.payload
            })
            .addCase(GetAllService.rejected, (state) => {
                state.getAllServiceStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetServiceById.pending, (state) => {
                state.getServiceByIdStatus = 'loading'
            })
            .addCase(GetServiceById.fulfilled, (state, action) => {
                state.getServiceByIdStatus = 'successfully'
                state.getServiceByIdResponse = action.payload
            })
            .addCase(GetServiceById.rejected, (state) => {
                state.getServiceByIdStatus = 'failed'
            })

            // VALIDATION
            .addCase(ServiceValidation.pending, (state) => {
                state.serviceValidationStatus = 'loading'
            })
            .addCase(ServiceValidation.fulfilled, (state) => {
                state.serviceValidationStatus = 'successfully'
            })
            .addCase(ServiceValidation.rejected, (state) => {
                state.serviceValidationStatus = 'failed'
            })

            // [ADDITIONAL EXPENSE]
            // CREATION
            .addCase(AdditionalExpenseCreation.pending, (state) => {
                state.additionalExpenseCreationStatus = 'loading'
            })
            .addCase(AdditionalExpenseCreation.fulfilled, (state) => {
                state.additionalExpenseCreationStatus = 'successfully'
            })
            .addCase(AdditionalExpenseCreation.rejected, (state) => {
                state.additionalExpenseCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(AdditionalExpenseUpdate.pending, (state) => {
                state.additionalExpenseUpdateStatus = 'loading'
            })
            .addCase(AdditionalExpenseUpdate.fulfilled, (state) => {
                state.additionalExpenseUpdateStatus = 'successfully'
            })
            .addCase(AdditionalExpenseUpdate.rejected, (state) => {
                state.additionalExpenseUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(AdditionalExpenseDelete.pending, (state) => {
                state.additionalExpenseDeleteStatus = 'loading'
            })
            .addCase(AdditionalExpenseDelete.fulfilled, (state) => {
                state.additionalExpenseDeleteStatus = 'successfully'
            })
            .addCase(AdditionalExpenseDelete.rejected, (state) => {
                state.additionalExpenseDeleteStatus = 'failed'
            })

            // GET ALL
            .addCase(GetAllAdditionalExpense.pending, (state) => {
                state.getAllAdditionalExpenseStatus = 'loading'
            })
            .addCase(GetAllAdditionalExpense.fulfilled, (state, action) => {
                state.getAllAdditionalExpenseStatus = 'successfully'
                state.getAllAdditionalExpenseResponse = action.payload
            })
            .addCase(GetAllAdditionalExpense.rejected, (state) => {
                state.getAllAdditionalExpenseStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetAdditionalExpenseById.pending, (state) => {
                state.getAdditionalExpenseByIdStatus = 'loading'
            })
            .addCase(GetAdditionalExpenseById.fulfilled, (state, action) => {
                state.getAdditionalExpenseByIdStatus = 'successfully'
                state.getAdditionalExpenseByIdResponse = action.payload
            })
            .addCase(GetAdditionalExpenseById.rejected, (state) => {
                state.getAdditionalExpenseByIdStatus = 'failed'
            })

            // VALIDATION
            .addCase(AdditionalExpenseValidation.pending, (state) => {
                state.additionalExpenseValidationStatus = 'loading'
            })
            .addCase(AdditionalExpenseValidation.fulfilled, (state) => {
                state.additionalExpenseValidationStatus = 'successfully'
            })
            .addCase(AdditionalExpenseValidation.rejected, (state) => {
                state.additionalExpenseValidationStatus = 'failed'
            })

            // [CUSTOMER BW]
            // CREATION
            .addCase(CustomerBillingWorksheetCreation.pending, (state) => {
                state.customerBillingWorksheetCreationStatus = 'loading'
            })
            .addCase(CustomerBillingWorksheetCreation.fulfilled, (state) => {
                state.customerBillingWorksheetCreationStatus = 'successfully'
            })
            .addCase(CustomerBillingWorksheetCreation.rejected, (state) => {
                state.customerBillingWorksheetCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(CustomerBillingWorksheetUpdate.pending, (state) => {
                state.customerBillingWorksheetUpdateStatus = 'loading'
            })
            .addCase(CustomerBillingWorksheetUpdate.fulfilled, (state) => {
                state.customerBillingWorksheetUpdateStatus = 'successfully'
            })
            .addCase(CustomerBillingWorksheetUpdate.rejected, (state) => {
                state.customerBillingWorksheetUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(CustomerBillingWorksheetDelete.pending, (state) => {
                state.customerBillingWorksheetDeleteStatus = 'loading'
            })
            .addCase(CustomerBillingWorksheetDelete.fulfilled, (state) => {
                state.customerBillingWorksheetDeleteStatus = 'successfully'
            })
            .addCase(CustomerBillingWorksheetDelete.rejected, (state) => {
                state.customerBillingWorksheetDeleteStatus = 'failed'
            })

            // GET ALL
            .addCase(GetAllCustomerBillingWorksheet.pending, (state) => {
                state.getAllCustomerBillingWorksheetStatus = 'loading'
            })
            .addCase(GetAllCustomerBillingWorksheet.fulfilled, (state, action) => {
                state.getAllCustomerBillingWorksheetStatus = 'successfully'
                state.getAllCustomerBillingWorksheetResponse = action.payload
            })
            .addCase(GetAllCustomerBillingWorksheet.rejected, (state) => {
                state.getAllCustomerBillingWorksheetStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetCustomerBillingWorksheetById.pending, (state) => {
                state.getCustomerBillingWorksheetByIdStatus = 'loading'
            })
            .addCase(GetCustomerBillingWorksheetById.fulfilled, (state, action) => {
                state.getCustomerBillingWorksheetByIdStatus = 'successfully'
                state.getCustomerBillingWorksheetByIdResponse = action.payload
            })
            .addCase(GetCustomerBillingWorksheetById.rejected, (state) => {
                state.getCustomerBillingWorksheetByIdStatus = 'failed'
            })
    },
})

export const {
    // BILLING WORKSHEET
    setOnEdit,
    setBWCreationNorm,
    setBWCreationCloneServiceTypesFromYear,
    setBWCreationYear,
    setBWIdToUpdate,
    setBWUpdateCloneServiceTypesFromYear,
    setGetAllBWItemsPerPage,
    setGetAllBWOrder,
    setGetAllBWNorm,
    setGetAllBWPage,
    setGetAllBWSort,
    setGetAllBWYear,
    resetBWCreationRequest,
    resetBWCreationStatus,
    resetBWDeleteStatus,
    resetGetAllBWFilters,
    resetGetAllBWStatus,
    resetBWUpdateRequest,
    resetBWUpdateStatus,
    resetGetBWById,

    // SERVICE TYPE
    setGetAllSTCategory,
    setGetAllSTCode,
    setGetAllSTItemsPerPage,
    setGetAllSTNorm,
    setGetAllSTOrder,
    setGetAllSTPage,
    setGetAllSTSort,
    setGetAllSTYear,
    setGetAllSTbillingWorksheetId,
    setSTCreationAmountPerUnit,
    setSTCreationBillingWorksheetId,
    setSTCreationCategory,
    setSTCreationCode,
    setSTCreationDescription,
    setSTCreationNote,
    setSTCreationUnitType,
    setSTIdToUpdate,
    setSTUpdateAmountPerUnit,
    setSTUpdateCategory,
    setSTUpdateCode,
    setSTUpdateDescription,
    setSTUpdateNote,
    setSTUpdateUnitType,
    resetGetAllSTFilter,
    resetGetAllSTStatus,
    resetGetSTByIdStatus,
    resetSTCreationRequest,
    resetSTCreationStatus,
    resetSTDeleteStatus,
    resetSTUpdateRequest,
    resetSTUpdateStatus,

    // SERVICE
    setGetAllServiceItemsPerPage,
    setGetAllServiceCustomerBillingWorksheetId,
    setGetAllServiceCustomerId,
    setGetAllServiceOfficerId,
    setGetAllServiceOrder,
    setGetAllServicePage,
    setGetAllServiceServiceTypeId,
    setGetAllServiceSort,
    setServiceCreationDate,
    setServiceCreationOfficerId,
    setServiceCreationCustomerBillingWorksheetId,
    setServiceCreationServiceTypeId,
    setServiceCreationUnitEmployed,
    setServiceIdToUpdate,
    setValidateServiceServiceTypeId,
    setValidateServiceUnitEmployed,
    setServiceUpdateServiceTypeId,
    setServiceUpdateUnitEmployed,
    resetGetAllServiceFilters,
    resetGetAllServiceStatus,
    resetGetServiceByIdStatus,
    resetServiceErrors,
    resetServiceValidationStatus,
    resetServiceCreationRequest,
    resetServiceCreationStatus,
    resetServiceDeleteStatus,
    resetServiceUpdateRequest,
    resetServiceUpdateStatus,

    // ADDITIONAL EXPENSE
    setAECreationTaxableFee,
    setAECreationOfficerId,
    setAECreationServiceTypeId,
    setAECreationCustomerBWId,
    setAEUpdateAmount,
    setAEUpdateCode,
    setAEUpdateDescription,
    setAEIdToUpdate,
    setAEUpdateNote,
    setValidateAdditionalServiceTypeId,
    setValidateAdditionalTaxableFee,
    resetAEValidationStatus,
    resetAdditionalErrors,
    setAEUpdateServiceTypeId,
    setGetAllAECode,
    setGetAllAECustomerBillingWorksheetId,
    setGetAllAEItemsPerPage,
    setGetAllAEOrder,
    setGetAllAEPage,
    setGetAllAESort,
    resetAECreationRequest,
    resetAECreationStatus,
    resetAEDeleteStatus,
    resetAEUpdateRequest,
    resetAEUpdateStatus,
    resetGetAEByIdStatus,
    resetGetAllAEFilters,
    resetGetAllAEStatus,

    // CUSTOMER BW
    setCustomerBWCreationBillingWorksheetId,
    setCustomerBWCreationCustomerId,
    setCustomerBWIdToUpdate,
    setCustomerBWUpdateAdditionalExpenseIds,
    setCustomerBWUpdateServiceIds,
    setGetAllCustomerBWBillingWorksheetId,
    setGetAllCustomerBWCustomerId,
    setGetAllCustomerBWItemsPerPage,
    setGetAllCustomerBWOrder,
    setGetAllCustomerBWPage,
    setGetAllCustomerBWSort,
    resetCustomerBWCreationRequest,
    resetCustomerBWCreationStatus,
    resetCustomerBWDeleteStatus,
    resetCustomerBWUpdateRequest,
    resetCustomerBWUpdateStatus,
    resetGetAllCustomerBWFilters,
    resetGetAllCustomerBWStatus,
    resetGetCustomerBWByIdStatus
} = BillingWorksheetServiceTypeSlice.actions

export default BillingWorksheetServiceTypeSlice.reducer