import axios from "axios";
import { GetAllRIDEntitiesDTO, GetAllRIDFiltersDTO, RIDCreationDTO, RIDEntityDTO, RIDUpdateDTO, RIDUpdateStateDTO } from "./dto";
import { RIDService } from "./service";
import { keycloak } from "../../../../lib/keycloak";

export class RIDServiceImpl implements RIDService {

    public CreateRID(data: RIDCreationDTO): Promise<string> {
        return axios.post("/api/document-integration-request",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateRID(data: RIDUpdateDTO, id: string): Promise<void> {
        return axios.put("/api/document-integration-request/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateRIDState(data: RIDUpdateStateDTO, id: string): Promise<void> {
        return axios.put("/api/document-integration-request/update-state/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteRID(id: string): Promise<void> {
        return axios.delete("/api/document-integration-request/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllRID(filters: GetAllRIDFiltersDTO): Promise<GetAllRIDEntitiesDTO> {
        return axios.get("/api/document-integration-request", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order,
                customerId: filters.customerId
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetRIDById(id: string): Promise<RIDEntityDTO> {
        return axios.get("/api/document-integration-request/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public DownloadRidDocx(data: { id: string, infoType: string }): Promise<void> {
        return axios.post(`/api/download/rid-docx/${data.id}`,
            { infoType: data.infoType },
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                },
                responseType: 'text',
            },

        ).then((response) => {
            const base64String = response.data.slice(1, -1);
            // Decode Base64
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            // Create blob with binary
            const blob = new Blob([byteArray], {
                type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });

            // tmp url
            const url = URL.createObjectURL(blob);

            //link trigger download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Rid' + data.infoType + '.docx');
            document.body.appendChild(link);
            link.click();

            // remove link
            document.body.removeChild(link);

            // empty memory
            URL.revokeObjectURL(url);
        }).catch((error) => {
            console.error('Errore durante il download del file:', error);
        });
    }
}