import { useEffect, useState } from "react";
import { headquartersOptions, yearOptions } from "../../../utils";
import Input from "../input";
import { SelectCustom } from "../select";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { GetAllEntryProtocols, GetEntryProtocolById, resetGetAllEntryProtocolsStatus, resetGetEntryProtocolByIdStatus } from "../../../pages/protocol/entry/slice";
import { GetAllDepartureProtocols, GetDepartureProtocolById, resetGetAllDepartureProtocolsStatus, resetGetDepartureProtocolByIdStatus } from "../../../pages/protocol/departure/slice";
import './styles.scss'
import { RoundedCloseIcon } from "../../icons/CloseIcon";
import { NewEntryProtocolService } from "../../../pages/protocol/entry/dataService/service";
import { NewDepartureProtocolService } from "../../../pages/protocol/departure/dataService/service";

interface ProtocolData {
    id: string
    name: string
    sender: string
    client: string[]
    region: string
    object: string
}

interface Props {
    type: 'entry' | 'departure' | 'all'
    onSelect?: (value: string[] | undefined) => void
    defaultValue?: string[]
    errorLabel?: string
    disabled?: boolean
}

export function MultiProtocolSearch(props: Props) {
    const [headquarter, setHeadquarter] = useState<string | undefined>(undefined)
    const [number, setNumber] = useState<string | undefined>(undefined)
    const [protocols, setProtocols] = useState<ProtocolData[]>([])
    const EntryProtocolService = NewEntryProtocolService()
    const DepartureProtocolService = NewDepartureProtocolService()


    useEffect(() => {
        if (props.defaultValue && props.defaultValue.length > 0) {
            const fetchProtocols = async () => {
                const protocolPromises = props.defaultValue!.map(async (p) => {
                    if (props.type === 'entry') {
                        const data = await EntryProtocolService.GetEntryProtocolById(p);
                        return {
                            id: data.id!,
                            name: `${data.headQuarter} E${data.protocolNumber}`,
                            sender: data.sender,
                            region: data.region,
                            client: data.customersInfo !== null ? [...data.customersInfo.map(c => c.companyName), data.customersNotRegistered!] : [],
                            object: data.entryObject.description + `[${data.entryObject.coding}]`
                        };
                    } else if (props.type === 'departure') {
                        const data = await DepartureProtocolService.GetDepartureProtocolById(p);
                        return {
                            id: data.id!,
                            name: `${data.headQuarter} D${data.protocolNumber}`,
                            sender: data.receiver,
                            region: data.region,
                            client: data.customersInfo !== null ? [...data.customersInfo.map(c => c.companyName), data.customersNotRegistered!] : [],
                            object: data.departureObject.description + `[${data.departureObject.coding}]`
                        };
                    } else {
                        const entryData = await EntryProtocolService.GetEntryProtocolById(p);
                        const departureData = await DepartureProtocolService.GetDepartureProtocolById(p);
                        
                        return [
                            {
                                id: entryData.id!,
                                name: `${entryData.headQuarter} E${entryData.protocolNumber}`,
                                sender: entryData.sender,
                                region: entryData.region,
                                client: entryData.customersInfo !== null ? [...entryData.customersInfo.map(c => c.companyName), entryData.customersNotRegistered!] : [],
                                object: entryData.entryObject.description + `[${entryData.entryObject.coding}]`
                            },
                            {
                                id: departureData.id!,
                                name: `${departureData.headQuarter} D${departureData.protocolNumber}`,
                                sender: departureData.receiver,
                                region: departureData.region,
                                client: departureData.customersInfo !== null ? [...departureData.customersInfo.map(c => c.companyName), departureData.customersNotRegistered!] : [],
                                object: departureData.departureObject.description + `[${departureData.departureObject.coding}]`
                            }
                        ];
                    }
                });
    
                const results = await Promise.all(protocolPromises);
                const flattenedResults = results.flat();
                setProtocols(flattenedResults);
            };
    
            fetchProtocols();
        }
    }, []);
    

    useEffect(() => {
        if (headquarter === undefined || number === undefined) {
            return
        }
        if (props.type === 'entry')
            EntryProtocolService.GetAllEntryProtocols({ headquarter: headquarter, number: number, itemsPerPage: '1', page: 0 })
                .then(response => {
                    const data = response.data
                    if (data.length !== 1) {
                        // setProtocol(null)
                        // props.onSelect && props.onSelect(undefined)
                        return
                    }
                    const newProtocol: ProtocolData = {
                        id: data[0].id!,
                        name: `${data[0].headQuarter} E${data[0].protocolNumber}`,
                        sender: data[0].sender,
                        region: data[0].region,
                        client: data[0].customersInfo !== null ? [...data[0].customersInfo.map(c => c.companyName), data[0].customersNotRegistered!] : [],
                        object: data[0].entryObject.description + `[${data[0].entryObject.coding}]`
                    }
                    const currProtocols = [...protocols]
                    if(currProtocols.map(p => p.id).includes(newProtocol.id))
                        return
                    currProtocols.push(newProtocol)
                    setProtocols(currProtocols)
                    props.onSelect && props.onSelect(currProtocols.map(p => p.id))
                })

        else if (props.type === 'departure')
            DepartureProtocolService.GetAllDepartureProtocols({ itemsPerPage: '1', headquarter: headquarter, number: number, page: 0 })
                .then(response => {
                    const data = response.data
                    if (data.length !== 1) {
                        // setProtocol(null)
                        // props.onSelect && props.onSelect(undefined)
                        return
                    }
                    const newProtocol: ProtocolData = {
                        id: data[0].id!,
                        name: `${data[0].headQuarter} D${data[0].protocolNumber}`,
                        sender: data[0].receiver,
                        region: data[0].region,
                        client: data[0].customersInfo !== null ? [...data[0].customersInfo.map(c => c.companyName), data[0].customersNotRegistered!] : [],
                        object: data[0].departureObject.description + `[${data[0].departureObject.coding}]`
                    }
                    const currProtocols = [...protocols]
                    if(currProtocols.map(p => p.id).includes(newProtocol.id))
                        return
                    currProtocols.push(newProtocol)
                    setProtocols(currProtocols)
                    props.onSelect && props.onSelect(currProtocols.map(p => p.id))
                })

        else
            EntryProtocolService.GetAllEntryProtocols({ headquarter: headquarter, number: number, itemsPerPage: '1', page: 0 })
                .then(response => {
                    const data = response.data
                    if (data.length !== 1) {
                        // setProtocol(null)
                        // props.onSelect && props.onSelect(undefined)
                        return
                    }
                    const newProtocol: ProtocolData = {
                        id: data[0].id!,
                        name: `${data[0].headQuarter} E${data[0].protocolNumber}`,
                        sender: data[0].sender,
                        region: data[0].region,
                        client: data[0].customersInfo !== null ? [...data[0].customersInfo.map(c => c.companyName), data[0].customersNotRegistered!] : [],
                        object: data[0].entryObject.description + `[${data[0].entryObject.coding}]`
                    }
                    return newProtocol
                })
                .then((entryProtocol) => DepartureProtocolService.GetAllDepartureProtocols({ itemsPerPage: '1', headquarter: headquarter, number: number, page: 0 })
                    .then(response => {
                        const data = response.data
                        if (data.length !== 1) {
                            // setProtocol(null)
                            // props.onSelect && props.onSelect(undefined)
                            return
                        }
                        const newProtocol: ProtocolData = {
                            id: data[0].id!,
                            name: `${data[0].headQuarter} D${data[0].protocolNumber}`,
                            sender: data[0].receiver,
                            region: data[0].region,
                            client: data[0].customersInfo !== null ? [...data[0].customersInfo.map(c => c.companyName), data[0].customersNotRegistered!] : [],
                            object: data[0].departureObject.description + `[${data[0].departureObject.coding}]`
                        }
                        const currProtocols = [...protocols]
                        if(entryProtocol) 
                            currProtocols.push(entryProtocol)
                        currProtocols.push(newProtocol)
                        setProtocols(currProtocols)
                        props.onSelect && props.onSelect(currProtocols.map(p => p.id))
                    }))
    }, [headquarter, number])

    return (
        <div>
            <div style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                <div style={{ flex: '0.5' }}>
                    <div className="text-left">
                        <span className="input-label">
                            {`Protocollo Richiesta Codex ${props.type === 'entry' ? 'Entrata' : 'Partenza'}`}
                        </span>
                    </div>
                    <SelectCustom
                        disabled={props.disabled}
                        placeholder={'Seleziona sede...'}
                        options={headquartersOptions}
                        onChange={(value) => setHeadquarter(value)}
                    />
                </div>



                <div style={{ flex: '0.5' }}>
                    <Input
                        disabled={props.disabled}
                        label={'Numero'}
                        placeholder="Specificare numero..."
                        onChangeText={(text) => setNumber(text === '' ? undefined : text)}
                    />
                </div>
            </div>

            {
                protocols.map((protocol, idx) =>
                    <div key={idx} className="mt-4" style={{ display: 'flex', alignItems: 'center' }}>

                        <div className="vertical-line" />

                        <div className="pr-5" style={{ display: 'flex', gap: '24px' }}>
                            <div className="text-left">
                                <div className="protocol-bold-text">Protocollo trovato </div>
                                <div className="protocol-text">{protocol.name}</div>
                            </div>
                            <div className="text-left">
                                <div className="protocol-bold-text">{props.type === 'entry' ? 'Mittente ' : 'Destinatario '}</div>
                                <div className="protocol-text">{protocol.sender}</div>
                            </div>
                            <div className="text-left">
                                <div className="protocol-bold-text">Regione</div>
                                <div className="protocol-text">{protocol.region}</div>
                            </div>
                            <div className="text-left">
                                <div className="protocol-bold-text">Clienti </div>
                                <div className="protocol-text">{protocol.client.map(c => `${c} `)}</div>
                            </div>
                            <div className="text-left">
                                <div className="protocol-bold-text">Oggetto </div>
                                <div className="protocol-text">{protocol.object}</div>
                            </div>
                        </div>

                        {
                            !props.disabled && (
                                <button onClick={() => {
                                    const currProtocols = [...protocols]
                                    setProtocols(currProtocols.filter(p => p.id !== protocol.id))
                                    props.onSelect && props.onSelect(currProtocols.filter(p => p.id !== protocol.id).map(p => p.id))
                                }}>
                                    <RoundedCloseIcon color="#166428" />
                                </button>
                            )
                        }
                    </div>
                )
            }
            {
                props.errorLabel && (
                    <div>
                        <span className="input-supporting-text" style={{ color: '#fd665b', justifyContent: 'left', display: 'flex' }}>{props.errorLabel}</span>
                    </div>
                )
            }
        </div>
    )
}