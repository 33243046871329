import { useEffect, useState } from 'react'
import { SelectCustom } from '../../ui/molecules/select'
import { normativa, schedaCalcoloYearsOptions, ServiceTypeNormoptions, yearOptions } from '../../utils'
import './styles.scss'
import Button from '../../ui/molecules/button'
import { RoundedPlusIcon } from '../../ui/icons/RoundedPlusIcon'
import { ServiceTypeBox } from './components'
import { ServiceTypeCategoryEnum, ServiceTypeNormEnum } from './dataService/dto'
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks'
import { BillingWorksheetCreation, BillingWorksheetUpdate, GetBillingWorksheetById, resetBWCreationRequest, resetBWCreationStatus, resetBWUpdateRequest, resetSTCreationRequest, setBWCreationNorm, setBWIdToUpdate, setBWUpdateCloneServiceTypesFromYear, setOnEdit, setSTCreationBillingWorksheetId } from './slice'
import { DocumentIcon } from '../../ui/icons/documentIcon'

export function SchedaCalcoloForms() {
    const dispatch = useAppDispatch()
    const serviceBillingState = useAppSelector(state => state.billingAndServiceType)
    const [normError, setNormError] = useState<boolean>(false)

    useEffect(() => {
        dispatch(resetSTCreationRequest())
        dispatch(resetBWCreationRequest())
        dispatch(resetBWUpdateRequest())
    }, [])

    useEffect(() => {
        if (serviceBillingState.billingWorksheetCreationStatus === 'successfully' &&
            serviceBillingState.billingWorksheetCreationResponse !== undefined
        ) {
            const bwId = serviceBillingState.billingWorksheetCreationResponse
            dispatch(setOnEdit(true))
            dispatch(setBWIdToUpdate(bwId))
        }
    }, [serviceBillingState.billingWorksheetCreationStatus])

    return (
        <div>
            <div className="sc-container">
                {/* HEADER */}
                <div className='sc-header'>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Scheda di Calcolo </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title uppercase' style={{ color: '#d9e6e8' }}> Inserimento</span>
                    </div>
                </div>

                <div style={{ padding: '24px 24px 32px 24px' }}>
                    <div>
                        <div className='pb-5' style={{ display: 'flex', gap: '24px' }}>
                            <div style={{ flex: '0.5' }}>
                                <div className="text-left">
                                    <span className="input-label">Normativa</span>
                                </div>
                                <SelectCustom
                                    value={serviceBillingState.billingWorksheetCreationRequest.norm}
                                    disabled={serviceBillingState.onEdit}
                                    error={normError}
                                    errorLabel='Normativa mancante'
                                    placeholder='Specifica normativa...'
                                    options={ServiceTypeNormoptions}
                                    onChange={(value) => dispatch(setBWCreationNorm(value))}
                                />
                            </div>
                            <div style={{ flex: '0.5' }}>
                                <div className="text-left">
                                    <span className="input-label">Anno</span>
                                </div>
                                <SelectCustom
                                    disabled
                                    placeholder='Specifica anno...'
                                    options={yearOptions}
                                    value={!serviceBillingState.onEdit ? String(new Date().getFullYear()) :
                                        String(serviceBillingState.billingWorksheetCreationRequest.year)
                                    }
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                disabled={serviceBillingState.onEdit}
                                variant='solid'
                                color='orange'
                                size='md'
                                iconPosition='left'
                                icon={<RoundedPlusIcon />}
                                label='Crea Scheda di Calcolo'
                                onClick={() => {
                                    setNormError(serviceBillingState.billingWorksheetCreationRequest.norm === undefined)
                                    if (!normError) {
                                        dispatch(BillingWorksheetCreation(serviceBillingState.billingWorksheetCreationRequest))
                                    }
                                }}
                            />
                        </div>
                    </div>

                    {
                        serviceBillingState.onEdit && (
                            <div>
                                <div className='pb-5 mb-5' style={{ display: 'flex', borderBottom: '1px solid #4D797F' }} />

                                <div className='pb-5' style={{ display: 'flex', gap: '24px', alignItems: 'flex-end' }}>
                                    <div style={{ flex: '0.9' }}>
                                        <div className="text-left">
                                            <span className="input-label">Copia Servizi dall'anno</span>
                                        </div>
                                        <SelectCustom
                                            value={String(serviceBillingState.billingWorksheetUpdateRequest.yearToCloneServiceTypes)}
                                            placeholder='Specifica anno...'
                                            options={schedaCalcoloYearsOptions}
                                            onChange={(value) => dispatch(setBWUpdateCloneServiceTypesFromYear(Number(value)))}
                                        />
                                    </div>
                                    <div className='pb-1' style={{ flex: '0.1' }}>
                                        <Button
                                            disabled={serviceBillingState.billingWorksheetUpdateRequest.yearToCloneServiceTypes === undefined}
                                            variant='outline'
                                            color='dimgrey'
                                            size='md'
                                            iconPosition='left'
                                            icon={<DocumentIcon />}
                                            label='Copia'
                                            onClick={() => dispatch(BillingWorksheetUpdate({ body: serviceBillingState.billingWorksheetUpdateRequest, id: serviceBillingState.billingWorksheetIdToUpdate }))
                                            }
                                        />
                                    </div>
                                </div>


                                {/* ELENCO SERVIZI VISIBILI SOLO SE BILLING CREATO */}
                                <div className='pb-5'>
                                    <ServiceTypeBox
                                        category={ServiceTypeCategoryEnum.SERVICE}
                                        billingWorksheetId={serviceBillingState.billingWorksheetIdToUpdate}
                                    />
                                </div>
                                <div className='pb-5'>
                                    <ServiceTypeBox
                                        category={ServiceTypeCategoryEnum.ADDITIONAL_EXPENSE}
                                        billingWorksheetId={serviceBillingState.billingWorksheetIdToUpdate}
                                    />
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}