import { GetAllNewMeasureDTO, GetAllNewMeasuresFiltersDTO, NewMeasureCreationDTO, NewMeasureDTO, NewMeasureUpdateDTO } from "./dto";
import { NewMeasureServiceImpl } from "./serviceImpl";

export interface NewMeasureService {
    CreateNewMeasure(data: NewMeasureCreationDTO): Promise<string>
    UpdateNewMeasure(data: NewMeasureUpdateDTO, id: string): Promise<void>
    DeleteNewMeasure(id: string): Promise<void>
    GetNewMeasureById(id: string): Promise<NewMeasureDTO>
    GetAllNewMeasures(filters: GetAllNewMeasuresFiltersDTO): Promise<GetAllNewMeasureDTO>
}

export function NewNewMeasureService(): NewMeasureService {
    return new NewMeasureServiceImpl()
}