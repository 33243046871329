import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../lib/redux/hooks"
import { Layout } from "../../ui/layout"
import { MenuItems } from "../../ui/organisms/navbar/dto"
import Button from "../../ui/molecules/button"
import { RightBar } from "../../ui/organisms/rightBar"
import { ODCList } from "./list"
import { ODCRight } from "./right"

export function OrganismiDiControllo() {
    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Strumenti', 'Gestione', 'Gestione ODC']}
            noPaddingLat
            headerLabel="Gestione ODC"
        >
            <div className="bg-brandPrimary-100" style={{ display: 'flex', height: '100%' }}>
                <ODCList />
                
                <div className="bg-brandPrimary-100" style={{ flex: 0.3, borderTopRightRadius: '20px' }}>
                    <RightBar>
                        <ODCRight />
                    </RightBar>
                </div>
            </div>
        </Layout>
    )
}