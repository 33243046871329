import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { SelectCustom } from "../../ui/molecules/select";
import { GetHeadQuarter, resetGetAllSot } from "./slice";
import { OptionType } from "../../utils";

interface Props {
    disabled?: boolean,
    onChange: (value: string | undefined) => void
    value?: string
    defaultValue?: string
}

export function SOTFilter(props: Props) {
    const sotState = useAppSelector(state => state.headQuarter)
    const dispatch = useAppDispatch()
    const [headquarter, setHeadquarters] = useState<OptionType[]>([])
    const [selectedSot, setSelectedSot] = useState<string | undefined>()

    useEffect(() => {
        dispatch(GetHeadQuarter(sotState.sotFilters))
    }, [])

    useEffect(() => {
        if (sotState.responseHeadQuarterStatus === 'successfully') {
            if (sotState.responseHeadQuarter) {
                const data = sotState.responseHeadQuarter.data
                const newObjects: OptionType[] = data.map(d => {
                    return { value: d.id, label: `${d.code}` } as OptionType
                })
                setHeadquarters(newObjects)
            }

            dispatch(resetGetAllSot())
        }
    }, [sotState.responseHeadQuarterStatus])

    return (
        <div>
            <SelectCustom
                placeholder={'Sede'}
                value={props.value || selectedSot}
                defaultValue={props.defaultValue}
                options={headquarter}
                disabled={props.disabled}
                onChange={(value) => {
                   props.onChange(value)
                   setSelectedSot(value)
                }}
            />
        </div>
    )
}