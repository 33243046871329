import { ConversionPeriodCreationDTO, ConversionPeriodDTO, ConversionPeriodUpdateDTO, GetAllConversionPeriodsDTO, GetAllConversionPeriodsFiltersDTO } from "./dto";
import { ConversionPeriodServiceImpl } from "./serviceImpl";

export interface ConversionPeriodService {
    CreateConversionPeriod(data: ConversionPeriodCreationDTO): Promise<void>
    UpdateConversionPeriod(data: ConversionPeriodUpdateDTO, id: string): Promise<void>
    DeleteConversionPeriod(id: string): Promise<void>
    GetAllConversionPeriods(filters: GetAllConversionPeriodsFiltersDTO): Promise<GetAllConversionPeriodsDTO>
    GetConversionPeriodById(id: string): Promise<ConversionPeriodDTO>
}

export function NewConversionPeriodService(): ConversionPeriodService {
    return new ConversionPeriodServiceImpl()
}