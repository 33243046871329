import { useState } from 'react'
import './styles.scss'
import { normativa, optionsFormatter } from '../../../../utils';
import { useAppDispatch, useAppSelector } from '../../../../lib/redux/hooks';
import { EditIcon } from '../../../../ui/icons/edit';
import { PlusIcon } from '../../../../ui/icons/plus';
import { MinusIcon } from '../../../../ui/icons/minusIcon';
import { DetailTableCA } from './detail';
import Button from '../../../../ui/molecules/button';
import { PopupForm } from '../../../../ui/organisms/popupForm';
import { AddProtocolPopup } from '../popup/addProtocol';
import { CompanyFolderDetailDTO } from '../../dataService/dto';
import { setCFDetailCreationRequestEntryProtocolAttachmentId, setCFDetailCreationRequestCustomerId } from '../../slice';

interface Props {
    id: string
    code: number
    title: string
    numOfDetails: number
    onEdit?: () => void
    historyView: boolean
    protocolIds: string[]
}

export function ListItemCA(props: Props) {
    const dispatch = useAppDispatch()
    const customerState = useAppSelector(state => state.clienti)
    const sessionState = useAppSelector(state => state.session)
    const cartellaAziendaleState = useAppSelector(state => state.cartellaAziendale)
    const { id, code, title, numOfDetails, historyView, protocolIds, onEdit } = props
    const [addPopupShow, setAddPopupShow] = useState<boolean>(false)

    const onNewProtocol = () => {
        dispatch(setCFDetailCreationRequestEntryProtocolAttachmentId(id))
        dispatch(setCFDetailCreationRequestCustomerId(sessionState.selected.customerId))
        setAddPopupShow(true)
    }

    const handleProtocols = () => {
        const protGroups: { pd: CompanyFolderDetailDTO, index: number }[] = []
        if (historyView) {
            const histReq = cartellaAziendaleState.historyCFDetailsUpdateRequest
            for (let i = 0; i < histReq.length; i++) {
                if (protocolIds.includes(histReq[i].id!))
                    protGroups.push({ pd: histReq[i], index: i })
            }
            return protGroups
        }
        const activeReq = cartellaAziendaleState.activeCFDetailsUpdateRequest
        for (let i = 0; i < activeReq.length; i++) {
            if (protocolIds.includes(activeReq[i].id!)) {
                protGroups.push({ pd: activeReq[i], index: i })
            }
        }
        return protGroups
    }

    return (
        <div style={{ backgroundColor: '#fff', borderRadius: '20px' }}>
            <div className='container-li-ca' style={{ padding: '10px 10px 20px 10px' }}>

                <div className='code'>
                    <span>{code < 10 ? `0${code}` : code}</span>
                </div>

                <div className='content'>
                    <span className='title' style={{ color: '#182B2E' }}>{`${title} - `}</span>
                    <span className='title' style={{ color: 'orange' }}>{`[${numOfDetails}]`}</span>
                </div>

                <div>
                    <Button
                        variant='solid'
                        color='orange'
                        iconPosition='plus'
                        size='plus'
                        label='+'
                        onClick={() => onNewProtocol()}
                    />
                </div>

            </div>

            <div>
                <DetailTableCA
                    historyView={historyView}
                    protocols={handleProtocols()}
                />
            </div>

            {
                addPopupShow && (
                    <PopupForm title="Aggiungi protocollo alla cartella" close={() => {
                        setAddPopupShow(false)
                    }}>
                        <AddProtocolPopup close={() => setAddPopupShow(false)} />
                    </PopupForm>
                )
            }
        </div>
    )
}