import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import Button from "../../../ui/molecules/button";
import Input from "../../../ui/molecules/input";
import { MeasureRecipientCreation, resetMeasureCreationRequest, setMeasureRecipientCreationDescription, setMeasureRecipientCreationEmail, setMeasureRecipientCreationPec, setMeasureRecipientMeasureActive } from "./slice";
import Toggle from "../../../ui/molecules/toggle";

export function MeasureRecipientRight() {
    const dispatch = useAppDispatch()
    const measureRecipientState = useAppSelector(state => state.measureRecipient)

    useEffect(() => {
        if (measureRecipientState.measureRecipientCreationStatus === 'successfully') {
            dispatch(resetMeasureCreationRequest())
        }
    }, [measureRecipientState.measureRecipientCreationStatus])

    return (
        <div style={{ padding: '0px 24px 24px 24px' }}>
            <div className="text-left pb-3 pt-5">
                <span className="rb-title" >
                    Destinatari Provvedimento
                </span>
            </div>

            <div className='pb-3'>
                <Input
                    label={'Descrizione'}
                    placeholder="Specifica descrizione..."
                    value={measureRecipientState.measureRecipientCreationRequest.description}
                    onChangeText={(text) => dispatch(setMeasureRecipientCreationDescription(text))}
                />
            </div>

            <div className='pb-3'>
                <Input
                    label={'Email'}
                    placeholder="Specifica email..."
                    value={measureRecipientState.measureRecipientCreationRequest.email}
                    onChangeText={(text) => dispatch(setMeasureRecipientCreationEmail(text))}
                />
            </div>

            <div className='pb-3'>
                <Input
                    label={'Pec'}
                    placeholder="Specifica Pec..."
                    value={measureRecipientState.measureRecipientCreationRequest.pec}
                    onChangeText={(text) => dispatch(setMeasureRecipientCreationPec(text))}
                />
            </div>
            <div className='pb-3'>
                <div className="text-left mb-3">
                    <span className="input-label">Presente in Provvedimento</span>
                </div>

                <div className='mb-3'>
                    <Toggle
                        placeholder='Presente in provvedimetno'
                        checked={measureRecipientState.measureRecipientCreationRequest.measureActive}
                        onChange={(value) => { dispatch(setMeasureRecipientMeasureActive(value)) }}
                    />
                </div>
            </div>
            <div>
                <Button
                    variant='solid'
                    color='orange'
                    iconPosition='off'
                    size='md'
                    label='Aggiungi OdC'
                    onClick={() => {
                        const req = measureRecipientState.measureRecipientCreationRequest
                        dispatch(MeasureRecipientCreation({
                            description: req.description !== '' ? req.description : undefined,
                            email: req.email !== '' ? req.email : undefined,
                            pec: req.pec !== '' ? req.pec : undefined,
                            measureActive: req.measureActive
                        }))
                    }}
                />
            </div>
        </div>
    )
}