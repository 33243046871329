import { useNavigate } from "react-router-dom";
import { Layout } from "../../../../ui/layout";
import Button from "../../../../ui/molecules/button";
import { MenuItems } from "../../../../ui/organisms/navbar/dto";
import { ArrowLeftIcon } from "../../../../ui/icons/arrow-left";
import { RightBar } from "../../../../ui/organisms/rightBar";
import { Banner } from "../../../../ui/organisms/banner";
import { AddReportingForms } from "./forms";
import { AddRightReporting } from "./right";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { useEffect, useState } from "react";
import { GetReportingById, ReportingCreation, ReportingUpdate, ReportingValidation, resetReportingCreationRequest, resetReportingStateCreationStatus, resetReportingUpdateRequest, resetReportingUpdateStatus, resetReportingValidationStatus, setFromCreationToUpdate, setReportingCustomerId, setReportingIdToUpdate, setReportingOfficerId, setReportingStateReportingId, setUpdateReportingCustomerOtherODC, setUpdateReportingDetails, setUpdateReportingEntryProtocolId, setUpdateReportingNote, setUpdateReportingReportingDate, setUpdateReportingReportingState, setUpdateReportingReportingType, setUpdateReportingSender } from "../slice";

export function AddReporting() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const reportingState = useAppSelector(state => state.reporting)
    const userState = useAppSelector(state => state.users)
    const sessionState = useAppSelector(state => state.session)
    const [showSuccess, setShowSuccess] = useState<boolean>(false)

    useEffect(() => {
        dispatch(resetReportingCreationRequest())
        dispatch(resetReportingUpdateRequest())
        dispatch(setReportingCustomerId(sessionState.selected.customerId))
        dispatch(setReportingOfficerId(userState.findMeResponse.id))
    }, [])

    useEffect(() => {
        if(reportingState.reportingCreationStatus === 'successfully' &&
            reportingState.reportingCreationResponse !== undefined
        ) {
            setShowSuccess(true)
            dispatch(resetReportingStateCreationStatus())
            dispatch(setFromCreationToUpdate(true))
            dispatch(GetReportingById(reportingState.reportingCreationResponse))
        }
    }, [reportingState.reportingCreationStatus])

    useEffect(() => {
        if(reportingState.reportingValidationStatus === 'successfully') {
            dispatch(resetReportingValidationStatus())
            !reportingState.fromCreationToUpdate ?
                dispatch(ReportingCreation(reportingState.reportingCreationRequest)) :
                dispatch(ReportingUpdate({body: reportingState.reportingUpdateRequest, id: reportingState.reportingIdToUpdate}))
        }
    }, [reportingState.reportingValidationStatus])

    useEffect(() => {
        if(reportingState.getReportingByIdStatus === 'successfully' &&
            reportingState.getReportingByIdResponse !== undefined
        ) {
            const data = reportingState.getReportingByIdResponse
            dispatch(setReportingIdToUpdate(data.id))
            dispatch(setReportingStateReportingId(data.id))
            dispatch(setUpdateReportingReportingDate(data.reportingDate))
            dispatch(setUpdateReportingSender(data.sender))
            dispatch(setUpdateReportingCustomerOtherODC(data.customerOtherODC))
            dispatch(setUpdateReportingEntryProtocolId(data.entryProtocolId))
            dispatch(setUpdateReportingReportingState(data.reportingState))
            dispatch(setUpdateReportingReportingType(data.reportingType))
            dispatch(setUpdateReportingDetails(data.details))
            dispatch(setUpdateReportingNote(data.note))
        }
    }, [reportingState.getReportingByIdStatus])

    useEffect(() => {
        if(reportingState.reportingUpdateStatus === 'successfully') {
            dispatch(resetReportingUpdateStatus())
            setShowSuccess(true)
        }
    }, [reportingState.reportingUpdateStatus])

    useEffect(() => {
        if(reportingState.reportingStateCreationStatus === 'successfully' ||
            reportingState.reportingStateDeleteStatus === 'successfully'
        ) {
            setShowSuccess(true)
        }
    }, [reportingState.reportingStateCreationStatus,
        reportingState.reportingStateDeleteStatus
    ])

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setShowSuccess(false)
            }, 2000)
        }
    }, [showSuccess])

    if(sessionState.selected.customerId) {
        return (
            <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Controllo', 'Aggiungi segnalazione']}
                noPaddingLat
                headerLabel="Segnalazioni"
                headerIcon={
                    <button style={{paddingTop: '12px'}} onClick={() => navigate('/segnalazioni')}>
                        <ArrowLeftIcon />
                    </button>
                }
                headerChildren={
                    <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>


                        <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                            onClick={() => dispatch(ReportingValidation(
                                !reportingState.fromCreationToUpdate ?
                                    reportingState.reportingCreationRequest :
                                    reportingState.reportingUpdateRequest
                            ))}
                        />

                    </div>
                }
            >
                <div style={{display: 'flex', minHeight: window.innerHeight}}>
                    <div style={{padding: '24px', flex: '0.6'}}>
                        {/* <div className="pb-5">
                            <ClientBanner
                                region={sessionState.selected.customerRegion!}
                                client={sessionState.selected.customerName!}
                                code={sessionState.selected.customerCode!}
                                activity={sessionState.selected.customerActivities ? `${sessionState.selected.customerActivities.map(act => optionsFormatter(act, attivita_bio))}` : undefined}
                                norm={optionsFormatter(sessionState.selected.customerNorm!, normativa)!}
                            />
                        </div> */}

                        {
                            showSuccess &&
                            <div className="gap-[8px] text-left" style={{ margin: '24px', borderRadius: '12px', backgroundColor: '#D1F5D9', borderColor: '#8AE59F', borderWidth: 2 }}>
                                <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                                    Dati salvati correttamente.
                                </span>
                            </div>
                        }

                        <div>
                            <AddReportingForms />
                        </div>
                    </div>
                    <div className="bg-brandPrimary-100" style={{flex: '0.4'}}>
                        <RightBar disabled={!reportingState.fromCreationToUpdate}>
                            <AddRightReporting />
                        </RightBar>
                    </div>
                </div>
            </Layout>
        )
    }

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione RID']}
            noPaddingLat
            headerLabel={"Gestione R.I.D."}
            customerName={sessionState.selected.customerName ? sessionState.selected.customerName : undefined}
            customerCode={sessionState.selected.customerCode ? sessionState.selected.customerCode : undefined}
        >
            <Banner
                type="error"
                visible={!sessionState.selected.customerId}
                label={"Devi prima selezionare un cliente per accedere all'area"}
                closeAction={function (): void {
                    alert("Devi prima selezionare un cliente per accedere all'area")
                }}
            />
        </Layout>
    )
}