import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../utils";
import { GetAllReportingsDTO, GetAllReportingsFiltersDTO, GetAllReportingStateFIltersDTO, GetAllReportingStatesDTO, ReportingCreationDTO, ReportingDTO, ReportingErrorsDTO, ReportingStateCreationUpdateDTO, ReportingStateDTO, ReportingStateErrorsDTO, ReportingUpdateDTO } from "./dataService/dto";
import { NewReportingService } from "./dataService/service";

interface ReportingState {
    fromCreationToUpdate: boolean

    // REQUEST
    reportingCreationRequest: ReportingCreationDTO
    reportingUpdateRequest: ReportingUpdateDTO
    reportingIdToUpdate: string

    reportingStateCreationRequest: ReportingStateCreationUpdateDTO
    reportingStateUpdateRequest: ReportingStateCreationUpdateDTO
    reportingStateIdToUpdate: string

    // ERRORS
    reportingErrors: ReportingErrorsDTO
    reportingStateErrors: ReportingStateErrorsDTO

    // FILTERS
    getAllReportingsFilters: GetAllReportingsFiltersDTO
    getAllReportingStatesFilters: GetAllReportingStateFIltersDTO

    // STATUS
    reportingCreationStatus: PromiseStatuses
    reportingUpdateStatus: PromiseStatuses
    reportingDeleteStatus: PromiseStatuses
    getAllReportingsStatus: PromiseStatuses
    getReportingByIdStatus: PromiseStatuses
    reportingValidationStatus: PromiseStatuses

    reportingStateCreationStatus: PromiseStatuses
    reportingStateUpdateStatus: PromiseStatuses
    reportingStateDeleteStatus: PromiseStatuses
    getAllReportingStatesStatus: PromiseStatuses
    getReportingStateByIdStatus: PromiseStatuses
    reportingStateValidationStatus: PromiseStatuses

    // RESPONSE
    reportingCreationResponse?: string
    getAllReportingsResponse?: GetAllReportingsDTO
    getReportingByIdResponse?: ReportingDTO
    getAllReportingStatesResponse?: GetAllReportingStatesDTO
    getReportingStateByIdResponse?: ReportingStateDTO
}

const initialState: ReportingState = {
    fromCreationToUpdate: false,

    // REQUEST
    reportingCreationRequest: {
        customerId: '',
        entryProtocolId: '',
        officerId: ''
    },
    reportingUpdateRequest: {
        entryProtocolId: ''
    },
    reportingIdToUpdate: '',

    reportingStateCreationRequest: {
        entryProtocolId: '',
        departureProtocolId: '',
        reportingId: ''
    },
    reportingStateUpdateRequest: {
        entryProtocolId: '',
        departureProtocolId: '',
        reportingId: ''
    },
    reportingStateIdToUpdate: '',

    // ERRORS
    reportingErrors: {
        reportingDate: false,
        sender: false,
        entryProtocolId: false,
        reportingState: false,
        reportingType: false,
        details: false
    },
    reportingStateErrors: {
        reportingOuterState: false,
        entryProtocolId: false,
        departureProtocolId: false
    },

    // FILTERS
    getAllReportingsFilters: {
        itemsPerPage: 0,
        page: 0
    },
    getAllReportingStatesFilters: {
        itemsPerPage: 0,
        page: 0
    },

    // STATUS
    reportingCreationStatus: 'idle',
    reportingUpdateStatus: 'idle',
    reportingDeleteStatus: 'idle',
    getAllReportingsStatus: 'idle',
    getReportingByIdStatus: 'idle',
    reportingValidationStatus: 'idle',

    reportingStateCreationStatus: 'idle',
    reportingStateUpdateStatus: 'idle',
    reportingStateDeleteStatus: 'idle',
    getAllReportingStatesStatus: 'idle',
    getReportingStateByIdStatus: 'idle',
    reportingStateValidationStatus: 'idle'
}

export const ReportingValidation = createAsyncThunk(
    'reporting/Validation',
    async (request: ReportingCreationDTO | ReportingUpdateDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetReportingErrors())

        if(request.reportingDate === undefined) {
            thunkApi.dispatch(setReportingValidationReportingDate(true))
            isValid = false
        }

        if(request.sender === undefined || request.sender === '') {
            thunkApi.dispatch(setReportingValidationSender(true))
            isValid = false
        }

        if(request.entryProtocolId === undefined || request.entryProtocolId === '') {
            thunkApi.dispatch(setReportingValidationEntryProtocolId(true))
            isValid = false
        }
        if(request.reportingState === undefined) {
            thunkApi.dispatch(setReportingValidationReportingState(true))
            isValid = false
        }
        if(request.reportingType === undefined) {
            thunkApi.dispatch(setReportingValidationReportingType(true))
            isValid = false
        }
        if(request.details === undefined || request.details === '') {
            thunkApi.dispatch(setReportingValidationDetails(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)

export const ReportingStateValidation = createAsyncThunk(
    'reportingState/Validation',
    async (request: ReportingStateCreationUpdateDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetReportingErrors())

        if(request.reportingOuterState === undefined) {
            thunkApi.dispatch(setReportingStateValidationReportingOuterState(true))
            isValid = false
        }

        if(request.entryProtocolId === undefined || request.entryProtocolId === '') {
            thunkApi.dispatch(setReportingStateValidationEntryProtocolId(true))
            isValid = false
        }

        if(request.departureProtocolId === undefined || request.departureProtocolId === '') {
            thunkApi.dispatch(setReportingStateValidationDepartureProtocolId(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)

export const ReportingCreation = createAsyncThunk(
    'reporting/Creation',
    async (body: ReportingCreationDTO, thunkApi): Promise<string> => {
        const reportingService = NewReportingService()

        return reportingService.CreateReporting(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const ReportingUpdate = createAsyncThunk(
    'reporting/Update',
    async (request: {body: ReportingUpdateDTO, id: string}, thunkApi): Promise<void> => {
        const reportingService = NewReportingService()

        return reportingService.UpdateReporting(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const ReportingDelete = createAsyncThunk(
    'reporting/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const reportingService = NewReportingService()

        return reportingService.DeleteReporting(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllReportings = createAsyncThunk(
    'reporting/GetAll',
    async (filters: GetAllReportingsFiltersDTO, thunkApi): Promise<GetAllReportingsDTO> => {
        const reportingService = NewReportingService()

        return reportingService.GetAllReportings(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetReportingById = createAsyncThunk(
    'reporting/GetById',
    async (id: string, thunkApi): Promise<ReportingDTO> => {
        const reportingService = NewReportingService()

        return reportingService.GetReportingById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const ReportingStateCreation = createAsyncThunk(
    'reportingState/Creation',
    async (body: ReportingStateCreationUpdateDTO, thunkApi): Promise<void> => {
        const reportingService = NewReportingService()

        return reportingService.CreateReportingState(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const ReportingStateUpdate = createAsyncThunk(
    'reportingState/Update',
    async (request: {body: ReportingStateCreationUpdateDTO, id: string}, thunkApi): Promise<void> => {
        const reportingService = NewReportingService()

        return reportingService.UpdateReportingState(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const ReportingStateDelete = createAsyncThunk(
    'reportingState/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const reportingService = NewReportingService()

        return reportingService.DeleteReportingState(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllReportingStates = createAsyncThunk(
    'reportingState/GetAll',
    async (filters: GetAllReportingStateFIltersDTO, thunkApi): Promise<GetAllReportingStatesDTO> => {
        const reportingService = NewReportingService()

        return reportingService.GetAllReportingStates(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetReportingStateById = createAsyncThunk(
    'reportingState/GetById',
    async (id: string, thunkApi): Promise<ReportingStateDTO> => {
        const reportingService = NewReportingService()

        return reportingService.GetReportingStateById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)


const ReportingSlice = createSlice({
    name: 'reporting/slice',
    initialState,
    reducers: {
        setFromCreationToUpdate: (state, action) => {
            state.fromCreationToUpdate = action.payload
        },

        // REQUEST [REPORTING CREATION]
        setReportingCustomerId: (state, action) => {
            state.reportingCreationRequest.customerId = action.payload
        },
        setReportingReportingDate: (state, action) => {
            state.reportingCreationRequest.reportingDate = action.payload
        },
        setReportingSender: (state, action) => {
            state.reportingCreationRequest.sender = action.payload
        },
        setReportingCustomerOtherODC: (state, action) => {
            state.reportingCreationRequest.customerOtherODC = action.payload
        },
        setReportingEntryProtocolId: (state, action) => {
            state.reportingCreationRequest.entryProtocolId = action.payload
        },
        setReportingReportingState: (state, action) => {
            state.reportingCreationRequest.reportingState = action.payload
        },
        setReportingOfficerId: (state, action) => {
            state.reportingCreationRequest.officerId = action.payload
        },
        setReportingReportingType: (state, action) => {
            state.reportingCreationRequest.reportingType = action.payload
        },
        setReportingDetails: (state, action) => {
            state.reportingCreationRequest.details = action.payload
        },
        setReportingNote: (state, action) => {
            state.reportingCreationRequest.note = action.payload
        },
        resetReportingCreationRequest: (state) => {
            state.reportingCreationRequest = {
                customerId: '',
                entryProtocolId: '',
                officerId: ''
            }
        },

        // REQUEST [REPORTING UPDATE]
        setUpdateReportingReportingDate: (state, action) => {
            state.reportingUpdateRequest.reportingDate = action.payload
        },
        setUpdateReportingSender: (state, action) => {
            state.reportingUpdateRequest.sender = action.payload
        },
        setUpdateReportingCustomerOtherODC: (state, action) => {
            state.reportingUpdateRequest.customerOtherODC = action.payload
        },
        setUpdateReportingEntryProtocolId: (state, action) => {
            state.reportingUpdateRequest.entryProtocolId = action.payload
        },
        setUpdateReportingReportingState: (state, action) => {
            state.reportingUpdateRequest.reportingState = action.payload
        },
        setUpdateReportingReportingType: (state, action) => {
            state.reportingUpdateRequest.reportingType = action.payload
        },
        setUpdateReportingDetails: (state, action) => {
            state.reportingUpdateRequest.details = action.payload
        },
        setUpdateReportingNote: (state, action) => {
            state.reportingUpdateRequest.note = action.payload
        },
        setReportingIdToUpdate: (state, action) => {
            state.reportingIdToUpdate = action.payload
        },
        resetReportingUpdateRequest: (state) => {
            state.reportingUpdateRequest = {
                entryProtocolId: ''
            }
        },

        // REQUEST [REPORTING STATE CREATION]
        setReportingStateReportingOuterState: (state, action) => {
            state.reportingStateCreationRequest.reportingOuterState = action.payload
        },
        setReportingStateEntryProtocolId: (state, action) => {
            state.reportingStateCreationRequest.entryProtocolId = action.payload
        },
        setReportingStateDepartureProtocolId: (state, action) => {
            state.reportingStateCreationRequest.departureProtocolId = action.payload
        },
        setReportingStateNote: (state, action) => {
            state.reportingStateCreationRequest.note = action.payload
        },
        setReportingStateReportingId: (state, action) => {
            state.reportingStateCreationRequest.reportingId = action.payload
        },
        resetReportingStateCreationRequest: (state) => {
            state.reportingStateCreationRequest = {
                entryProtocolId: '',
                departureProtocolId: '',
                note: '',
                reportingId: state.reportingStateCreationRequest.reportingId
            }
        },

        // REQUEST [REPORTING STATE UPDATE]
        setUpdateReportingStateReportingOuterState: (state, action) => {
            state.reportingStateUpdateRequest.reportingOuterState = action.payload
        },
        setUpdateReportingStateEntryProtocolId: (state, action) => {
            state.reportingStateUpdateRequest.entryProtocolId = action.payload
        },
        setUpdateReportingStateDepartureProtocolId: (state, action) => {
            state.reportingStateUpdateRequest.departureProtocolId = action.payload
        },
        setUpdateReportingStateNote: (state, action) => {
            state.reportingStateUpdateRequest.note = action.payload
        },
        setUpdateReportingStateReportingId: (state, action) => {
            state.reportingStateUpdateRequest.reportingId = action.payload
        },
        resetReportingStateUpdateRequest: (state) => {
            state.reportingStateUpdateRequest = {
                entryProtocolId: '',
                departureProtocolId: '',
                reportingId: ''
            }
        },

        // ERRORS
        setReportingValidationReportingDate: (state, action) => {
            state.reportingErrors.reportingDate = action.payload
        },
        setReportingValidationSender: (state, action) => {
            state.reportingErrors.sender = action.payload
        },
        setReportingValidationEntryProtocolId: (state, action) => {
            state.reportingErrors.entryProtocolId = action.payload
        },
        setReportingValidationReportingState: (state, action) => {
            state.reportingErrors.reportingState = action.payload
        },
        setReportingValidationReportingType: (state, action) => {
            state.reportingErrors.reportingType = action.payload
        },
        setReportingValidationDetails: (state, action) => {
            state.reportingErrors.details = action.payload
        },
        resetReportingErrors: (state) => {
            state.reportingErrors = {
                reportingDate: false,
                sender: false,
                entryProtocolId: false,
                reportingState: false,
                reportingType: false,
                details: false
            }
        },
        setReportingStateValidationReportingOuterState: (state, action) => {
            state.reportingStateErrors.reportingOuterState = action.payload
        },
        setReportingStateValidationEntryProtocolId: (state, action) => {
            state.reportingStateErrors.entryProtocolId = action.payload
        },
        setReportingStateValidationDepartureProtocolId: (state, action) => {
            state.reportingStateErrors.departureProtocolId = action.payload
        },
        resetReportingStateErrors: (state) => {
            state.reportingStateErrors = {
                reportingOuterState: false,
                entryProtocolId: false,
                departureProtocolId: false
            }
        },

        // FILTERS [REPORTING]
        setGetAllReportingsFiltersItemsPerPage: (state, action) => {
            state.getAllReportingsFilters.itemsPerPage = action.payload
        },
        setGetAllReportingsFiltersPage: (state, action) => {
            state.getAllReportingsFilters.page = action.payload
        },
        setGetAllReportingsFiltersCustomerId: (state, action) => {
            state.getAllReportingsFilters.customerId = action.payload
        },
        setGetAllReportingsFiltersHeadquarter: (state, action) => {
            state.getAllReportingsFilters.headquarter = action.payload
        },
        setGetAllReportingsFiltersOrder: (state, action) => {
            state.getAllReportingsFilters.order = action.payload
        },
        setGetAllReportingsFiltersReportingState: (state, action) => {
            state.getAllReportingsFilters.reportingState = action.payload
        },
        setGetAllReportingsFiltersSort: (state, action) => {
            state.getAllReportingsFilters.sort = action.payload
        },
        setGetAllReportingsFiltersYear: (state, action) => {
            state.getAllReportingsFilters.year = action.payload
        },
        resetGetAllReportingsFilters: (state) => {
            state.getAllReportingsFilters = {
                itemsPerPage: 0,
                page: 0
            }
        },

        // FILTERS [REPORTING STATE]
        setGetAllReportingStatesFiltersItemsPerPage: (state, action) => {
            state.getAllReportingStatesFilters.itemsPerPage = action.payload
        },
        setGetAllReportingStatesFiltersPage: (state, action) => {
            state.getAllReportingStatesFilters.page = action.payload
        },
        setGetAllReportingStatesFiltersReportingId: (state, action) => {
            state.getAllReportingStatesFilters.reportingId = action.payload
        },
        setGetAllReportingStatesFiltersOrder: (state, action) => {
            state.getAllReportingStatesFilters.order = action.payload
        },
        setGetAllReportingStatesFiltersSort: (state, action) => {
            state.getAllReportingStatesFilters.sort = action.payload
        },
        resetGetAllReportingStatesFilters: (state) => {
            state.getAllReportingStatesFilters = {
                itemsPerPage: 0,
                page: 0
            }
        },

        // STATUS
        resetReportingCreationStatus: (state) => {
            state.reportingCreationStatus = 'idle'
        },
        resetReportingUpdateStatus: (state) => {
            state.reportingUpdateStatus = 'idle'
        },
        resetReportingDeleteStatus: (state) => {
            state.reportingDeleteStatus = 'idle'
        },
        resetGetAllReportingsStatus: (state) => {
            state.getAllReportingsStatus = 'idle'
        },
        resetGetReportingByIdStatus: (state) => {
            state.getReportingByIdStatus = 'idle'
        },
        resetReportingValidationStatus: (state) => {
            state.reportingValidationStatus = 'idle'
        },

        resetReportingStateCreationStatus: (state) => {
            state.reportingStateCreationStatus = 'idle'
        },
        resetReportingStateUpdateStatus: (state) => {
            state.reportingStateUpdateStatus = 'idle'
        },
        resetReportingStateDeleteStatus: (state) => {
            state.reportingStateDeleteStatus = 'idle'
        },
        resetGetAllReportingStatesStatus: (state) => {
            state.getAllReportingStatesStatus = 'idle'
        },
        resetGetReportingStateByIDStatus: (state) => {
            state.getReportingStateByIdStatus ='idle'
        },
        resetReportingStateValidationStatus: (state) => {
            state.reportingStateValidationStatus = 'idle'
        },

        // RESPONSE
        resetGetAllReportingStateResponse: (state) => {
            state.getAllReportingStatesResponse = undefined
        }
    },
    extraReducers(builder) {
        builder

            // [REPORTING]
            // CREATION
            .addCase(ReportingCreation.pending, (state) => {
                state.reportingCreationStatus = 'loading'
            })
            .addCase(ReportingCreation.fulfilled, (state, action) => {
                state.reportingCreationStatus = 'successfully'
                state.reportingCreationResponse = action.payload
            })
            .addCase(ReportingCreation.rejected, (state) => {
                state.reportingCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(ReportingUpdate.pending, (state) => {
                state.reportingUpdateStatus = 'loading'
            })
            .addCase(ReportingUpdate.fulfilled, (state) => {
                state.reportingUpdateStatus = 'successfully'
            })
            .addCase(ReportingUpdate.rejected, (state) => {
                state.reportingUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(ReportingDelete.pending, (state) => {
                state.reportingDeleteStatus = 'loading'
            })
            .addCase(ReportingDelete.fulfilled, (state) => {
                state.reportingDeleteStatus = 'successfully'
            })
            .addCase(ReportingDelete.rejected, (state) => {
                state.reportingDeleteStatus = 'failed'
            })

            // GET ALL
            .addCase(GetAllReportings.pending, (state) => {
                state.getAllReportingsStatus = 'loading'
            })
            .addCase(GetAllReportings.fulfilled, (state, action) => {
                state.getAllReportingsStatus = 'successfully'
                state.getAllReportingsResponse = action.payload
            })
            .addCase(GetAllReportings.rejected, (state) => {
                state.getAllReportingsStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetReportingById.pending, (state) => {
                state.getReportingByIdStatus = 'loading'
            })
            .addCase(GetReportingById.fulfilled, (state, action) => {
                state.getReportingByIdStatus = 'successfully'
                state.getReportingByIdResponse = action.payload
            })
            .addCase(GetReportingById.rejected, (state) => {
                state.getReportingByIdStatus = 'failed'
            })

            // VALIDATION
            .addCase(ReportingValidation.pending, (state) => {
                state.reportingValidationStatus = 'loading'
            })
            .addCase(ReportingValidation.fulfilled, (state) => {
                state.reportingValidationStatus = 'successfully'
            })
            .addCase(ReportingValidation.rejected, (state) => {
                state.reportingValidationStatus = 'failed'
            })

            // [REPORTING STATE]
            // CREATION
            .addCase(ReportingStateCreation.pending, (state) => {
                state.reportingStateCreationStatus = 'loading'
            })
            .addCase(ReportingStateCreation.fulfilled, (state) => {
                state.reportingStateCreationStatus = 'successfully'
            })
            .addCase(ReportingStateCreation.rejected, (state) => {
                state.reportingStateCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(ReportingStateUpdate.pending, (state) => {
                state.reportingStateUpdateStatus = 'loading'
            })
            .addCase(ReportingStateUpdate.fulfilled, (state) => {
                state.reportingStateUpdateStatus = 'successfully'
            })
            .addCase(ReportingStateUpdate.rejected, (state) => {
                state.reportingStateUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(ReportingStateDelete.pending, (state) => {
                state.reportingStateDeleteStatus = 'loading'
            })
            .addCase(ReportingStateDelete.fulfilled, (state) => {
                state.reportingStateDeleteStatus = 'successfully'
            })
            .addCase(ReportingStateDelete.rejected, (state) => {
                state.reportingStateDeleteStatus = 'failed'
            })

            // GET ALL
            .addCase(GetAllReportingStates.pending, (state) => {
                state.getAllReportingStatesStatus = 'loading'
            })
            .addCase(GetAllReportingStates.fulfilled, (state, action) => {
                state.getAllReportingStatesStatus = 'successfully'
                state.getAllReportingStatesResponse = action.payload
            })
            .addCase(GetAllReportingStates.rejected, (state) => {
                state.getAllReportingStatesStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetReportingStateById.pending, (state) => {
                state.getReportingStateByIdStatus = 'loading'
            })
            .addCase(GetReportingStateById.fulfilled, (state, action) => {
                state.getReportingStateByIdStatus = 'successfully'
                state.getReportingStateByIdResponse = action.payload
            })
            .addCase(GetReportingStateById.rejected, (state) => {
                state.getReportingStateByIdStatus = 'failed'
            })

            // VALIDATION
            .addCase(ReportingStateValidation.pending, (state) => {
                state.reportingStateValidationStatus = 'loading'
            })
            .addCase(ReportingStateValidation.fulfilled, (state) => {
                state.reportingStateValidationStatus = 'successfully'
            })
            .addCase(ReportingStateValidation.rejected, (state) => {
                state.reportingStateValidationStatus = 'failed'
            })
    },
})

export const {
    setFromCreationToUpdate,

    // REQUEST
    setReportingCustomerOtherODC,
    setReportingDetails,
    setReportingEntryProtocolId,
    setReportingIdToUpdate,
    setReportingNote,
    setReportingOfficerId,
    setReportingReportingDate,
    setReportingReportingState,
    setReportingReportingType,
    setReportingSender,
    setReportingStateDepartureProtocolId,
    setReportingStateEntryProtocolId,
    setReportingStateNote,
    setReportingStateReportingId,
    setReportingStateReportingOuterState,
    setReportingCustomerId,
    setUpdateReportingCustomerOtherODC,
    setUpdateReportingDetails,
    setUpdateReportingEntryProtocolId,
    setUpdateReportingNote,
    setUpdateReportingReportingDate,
    setUpdateReportingReportingState,
    setUpdateReportingReportingType,
    setUpdateReportingSender,
    setUpdateReportingStateDepartureProtocolId,
    setUpdateReportingStateEntryProtocolId,
    setUpdateReportingStateNote,
    setUpdateReportingStateReportingId,
    setUpdateReportingStateReportingOuterState,
    resetReportingCreationRequest,
    resetReportingStateCreationRequest,
    resetReportingStateUpdateRequest,
    resetReportingUpdateRequest,

    // ERRORS
    setReportingStateValidationDepartureProtocolId,
    setReportingStateValidationEntryProtocolId,
    setReportingStateValidationReportingOuterState,
    setReportingValidationDetails,
    setReportingValidationEntryProtocolId,
    setReportingValidationReportingDate,
    setReportingValidationReportingState,
    setReportingValidationReportingType,
    setReportingValidationSender,
    resetReportingErrors,
    resetReportingStateErrors,

    // FILTERS
    setGetAllReportingStatesFiltersItemsPerPage,
    setGetAllReportingStatesFiltersOrder,
    setGetAllReportingStatesFiltersPage,
    setGetAllReportingStatesFiltersReportingId,
    setGetAllReportingStatesFiltersSort,
    setGetAllReportingsFiltersCustomerId,
    setGetAllReportingsFiltersHeadquarter,
    setGetAllReportingsFiltersItemsPerPage,
    setGetAllReportingsFiltersOrder,
    setGetAllReportingsFiltersPage,
    setGetAllReportingsFiltersReportingState,
    setGetAllReportingsFiltersSort,
    setGetAllReportingsFiltersYear,
    resetGetAllReportingsFilters,
    resetGetAllReportingStatesFilters,

    // STATUS
    resetGetAllReportingStatesStatus,
    resetGetAllReportingsStatus,
    resetGetReportingByIdStatus,
    resetGetReportingStateByIDStatus,
    resetReportingCreationStatus,
    resetReportingDeleteStatus,
    resetReportingStateCreationStatus,
    resetReportingStateDeleteStatus,
    resetReportingStateUpdateStatus,
    resetReportingUpdateStatus,
    resetReportingStateValidationStatus,
    resetReportingValidationStatus,

    // RESPONSE
    resetGetAllReportingStateResponse

} = ReportingSlice.actions

export default ReportingSlice.reducer