import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { ArrowLeftIcon } from "../../../../ui/icons/arrow-left"
import { Layout } from "../../../../ui/layout"
import Button from "../../../../ui/molecules/button"
import { MenuItems } from "../../../../ui/organisms/navbar/dto"
import { ReinforcedControlMeasureForm } from "./forms"
import { ReinforcedControlMeasureCreation, ReinforcedControlMeasureValidation, resetReinforcedControlMeasureValidation, setReinforcedCreationCustomerDataId } from "../slice"
import { useEffect } from "react"

export function AddReinforcedControlMeasure() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const sessionState = useAppSelector(state => state.session)
    const reinforcedControlMeasureState = useAppSelector(state => state.reinforcedControlMeasure)

    useEffect(() => {
        dispatch(setReinforcedCreationCustomerDataId(sessionState.selected.customerId))
    }, [])

    useEffect(() => {
        if(reinforcedControlMeasureState.reinforcedControlMeasureValidationStatus === 'successfully') {
            dispatch(resetReinforcedControlMeasureValidation())
            dispatch(ReinforcedControlMeasureCreation(reinforcedControlMeasureState.reinforcedControlMeasuresCreationRequest))
        }
    }, [reinforcedControlMeasureState.reinforcedControlMeasureValidationStatus])

    useEffect(() => {
        if(reinforcedControlMeasureState.reinforcedControlMeasureCreationStatus === 'successfully') {
            navigate('/reinforcedControlMeasure')
        }
    }, [reinforcedControlMeasureState.reinforcedControlMeasureCreationStatus])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Provvedimenti', 'Inserimento Misura di Controllo Rinforzato']}
            noPaddingLat
            headerLabel="Misura di Controllo Rinforzato"
            headerIcon={
                <button style={{ paddingTop: '12px' }} onClick={() => navigate('/reinforcedControlMeasure')}>
                    <ArrowLeftIcon />
                </button>
            }
            headerChildren={
                <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>
                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                        onClick={() => dispatch(ReinforcedControlMeasureValidation(reinforcedControlMeasureState.reinforcedControlMeasuresCreationRequest))}
                    />
                </div>
            }
        >
            <div>
                <ReinforcedControlMeasureForm />
            </div>
        </Layout>
    )
}