/* NEW MEASURE */

import { nonComplianceAreaDTO, NonComplianceCodeByIdDTO } from "../../NonComplianceCode/dto"
import { AppliedMeasureDTO } from "../components/measureBox/dataService/dto"

/* UTILS */
export enum MeasureVersionEnum {
    OLD = 'OLD',
    NEW = 'NEW'
}

export enum NonComplianceLevelEnum {
    SCARSO = 'SCARSO',
    GRAVE = 'GRAVE',
    CRITICO = 'CRITICO'
}

export enum ControlTypeEnum {
    DOCUMENTALE = 'DOCUMENTALE',
    ISPETTIVO = 'ISPETTIVO',
    ANALITICO = 'ANALITICO'
}

export enum FindingFromEnum {
    TECNICO_ISPETTORE = 'TECNICO_ISPETTORE',
    RIESAME = 'RIESAME'
}

export enum DepartureChannelEnum {
    RACCOMANDATA_AR = 'RACCOMANDATA_AR',
    POSTA_ELETTRONICA_CERTIFICATA = 'POSTA_ELETTRONICA_CERTIFICATA',
    RACCOMANDATA_AR_PEC = 'RACCOMANDATA_AR_PEC'
}

export enum MeasureStatusEnum {
    EMESSO = 'EMESSO',
    RICEVUTO_APERTO = 'RICEVUTO_APERTO',
    CHIUSO = 'CHIUSO',
    MANCATO_RISPETTO = 'MANCATO_RISPETTO',
    RECEDUTO = 'RECEDUTO',
    DECADUTO = 'DECADUTO',
    REVOCATO = 'REVOCATO',
    REVOCATO_A_SEGUITO_RICORSO = 'REVOCATO_A_SEGUITO_RICORSO',
    CLIENTE_ESCLUSO_DAL_SISTEMA_DI_CONTROLLO = 'CLIENTE_ESCLUSO_DAL_SISTEMA_DI_CONTROLLO',
    ANNULLATO = 'ANNULLATO'
}

export enum RecourseResultEnum {
    ACCOLTO = 'ACCOLTO',
    RIGETTATO = 'RIGETTATO',
    ACCOLTO_PARZIALE = 'ACCOLTO_PARZIALE'
}

export enum MeasureBioActivityEnum {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F'
}

export enum MeasureBioProductCategoryEnum {
    A = 'A',
    B = 'B',
    C = 'C',
    D = 'D',
    E = 'E',
    F = 'F',
    G = 'G'
}

export interface MeasureRecipientDTO {
    id: string
    deletedAt: string
    description: string
    email: string
    pec: string
}

/* DTOs */
export interface NewMeasureCreationDTO {
    isCodexRealesed?: boolean // MANDATORY
    nonComplianceCodeVersion?: MeasureVersionEnum
    m024IdTest?: string
    measureDate?: Date
    departureProtocolId?: string
    customerId: string
    officerId: string
}

// VALIDATION ONLY FOR CREATION
export interface NewMeasureErrorDTO {
    isCodexRealesed: boolean
    nonComplianceCodeVersion: boolean
    m024Id: boolean
    dateOrProtocol: boolean
}

export interface NewMeasureUpdateDTO {
    oldNonComplianceCode?: string
    newNonComplianceCodeId?: string
    nonComplianceNote?: string
    activities?: MeasureBioActivityEnum[]
    productsCategories?: MeasureBioProductCategoryEnum[]

    // SETTARE OLD O NEW IN BASE AL PROVVEDIMENTO SELEZIONATO
    measureCorrectionVersion?: MeasureVersionEnum
    measureCorrectionId?: string
    oldNonComplianceCodeCorrection?: string
    newNonComplianceCodeCorrectionId?: string


    noCorrectionNote?: string
    nonComplianceLevel?: NonComplianceLevelEnum
    additionalNote?: string
    controlType?: ControlTypeEnum
    nonComplianceFindingDate?: Date
    nonComplianceFindingNote?: string
    documentEntryProtocolId?: string
    documentDepartureProtocolId?: string
    documentIntegrationRequestId?: string
    inspectionVisitIdTest?: string
    findingFrom?: FindingFromEnum
    inspectorMeasureConfirmed?: boolean
    laboratoryTestReportProtocolId?: string
    preventiveSuppressionM024IdTest?: string
    m018IdTest?: string
    officerMeasureSignatureId?: string
    departureChannel?: DepartureChannelEnum
    p0035IdTest?: string
    measureRecipientsIds?: string[]
    status?: MeasureStatusEnum
    statusProtocolId?: string
    actionPlaneProtocolId?: string
    closingNote?: string
    presentationDate?: Date
    presentationProtocolId?: string
    recourseDetail?: string
    appealReportDate?: Date
    appealReportProtocolId?: string
    recourseResult?: RecourseResultEnum
    recourseNote?: string
}

export interface NewMeasureDTO {
    id: string
    createdAt: Date
    updatedAt: Date
    deletedAt: Date
    isCodexRealesed: boolean
    nonComplianceCodeVersion: MeasureVersionEnum
    m024IdTest: string
    measureDate: Date
    departureProtocolId: string
    departureProtocolInfo: string
    customerId: string
    officerId: string
    officerInfo: string
    oldNonComplianceCode: string
    newNonComplianceCodeDTO: NonComplianceCodeByIdDTO
    nonComplianceNote: string
    activities: MeasureBioActivityEnum[]
    productsCategories: MeasureBioProductCategoryEnum[]
    measureCorrectionVersion: MeasureVersionEnum
    oldMeasureCorrectionId: string
    newMeasureCorrectionId: string
    oldNonComplianceCodeCorrection: string
    newNonComplianceCodeCorrectionDTO: NonComplianceCodeByIdDTO
    noCorrectionNote: string
    nonComplianceLevel: NonComplianceLevelEnum
    appliedMeasures: AppliedMeasureDTO[]
    additionalMeasures: AppliedMeasureDTO[]
    additionalNote: string
    controlType: ControlTypeEnum
    nonComplianceFindingDate: Date
    nonComplianceFindingNote: string
    documentEntryProtocolId: string
    documentEntryProtocolInfo: string
    documentDepartureProtocolId: string
    documentDepartureProtocolInfo: string
    documentIntegrationRequestId: string
    documentIntegrationRequestInfo: string
    inspectionVisitIdTest: string
    findingFrom: FindingFromEnum
    inspectorMeasureConfirmed: boolean
    laboratoryTestReportProtocolId: string
    laboratoryTestReportProtocolInfo: string
    preventiveSuppressionM024IdTest: string
    m018IdTest: string
    officerMeasureSignatureId: string
    officerMeasureSignatureInfo: string
    departureChannel: DepartureChannelEnum
    p0035IdTest: string
    measureRecipientsDTO: MeasureRecipientDTO[]
    status: MeasureStatusEnum
    statusProtocolId: string
    statusProtocolInfo: string
    actionPlaneProtocolId: string
    actionPlaneProtocolInfo: string
    closingNote: string
    presentationDate: Date
    presentationProtocolId: string
    presentationProtocolInfo: string
    recourseDetail: string
    appealReportDate: Date
    appealReportProtocolId: string
    appealReportProtocolInfo: string
    recourseResult: RecourseResultEnum
    recourseNote: string
}

export interface GetAllNewMeasureDTO {
    total: number | null
    page: null
    perPage: null
    prev: false
    next: false
    totalPage: null
    data: NewMeasureDTO[]   
}

export interface GetAllNewMeasuresFiltersDTO {
    itemsPerPage: number
    page: number
    order?: boolean
    sort?: string
    customerId?: string
}