import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { PromiseStatuses } from "../../lib/utils"
import { NewSotService } from "./service"
import { headQuarterResponseDTO, sotFilterDTO } from "./dto"

interface SotState {
    responseHeadQuarter?: headQuarterResponseDTO
    responseHeadQuarterById?: headQuarterResponseDTO
    sotFilters: sotFilterDTO
    responseHeadQuarterStatus: PromiseStatuses
    responseHeadQuarterByIdStatus: PromiseStatuses
}

const initialState: SotState = {
    responseHeadQuarterStatus: 'idle',
    responseHeadQuarterByIdStatus: 'idle',
    sotFilters: {
        page: 0,
        itemsPerPage: 0
    }
}

export const GetHeadQuarter = createAsyncThunk(
    'sot/GetHeadQuarter',
    async (filters: sotFilterDTO, thunkApi): Promise<any> => {
        const SotService = NewSotService()
        return SotService.GetHeadQuarter(filters)
    }
)

export const FindSotById = createAsyncThunk(
    'sot/FindSotById',
    async (id: string, thunkApi): Promise<any> => {
        const SotService = NewSotService()

        return SotService.FindSotById(id)
    }
)


const SotSlice = createSlice({
    name: 'sot/slice',
    initialState,
    reducers: {
     
        resetGetAllSot:(state) => {
            state.responseHeadQuarterStatus = 'idle'
        }

    },
    extraReducers(builder) {
        builder
            .addCase(GetHeadQuarter.pending, (state) => {
                state.responseHeadQuarterStatus = 'loading'
            })
            .addCase(GetHeadQuarter.fulfilled, (state, action) => {
                state.responseHeadQuarterStatus = 'successfully'
                state.responseHeadQuarter = action.payload

            })
            .addCase(GetHeadQuarter.rejected, (state, action) => {
                state.responseHeadQuarterStatus = 'failed'
            })

            .addCase(FindSotById.pending, (state) => {
                state.responseHeadQuarterByIdStatus = 'loading'
            })
            .addCase(FindSotById.fulfilled, (state, action) => {
                state.responseHeadQuarterByIdStatus = 'successfully'
                state.responseHeadQuarterById = action.payload

            })
            .addCase(FindSotById.rejected, (state, action) => {
                state.responseHeadQuarterByIdStatus = 'failed'
            })

    },
})

export const {
    resetGetAllSot
} = SotSlice.actions

export default SotSlice.reducer