import axios from "axios";
import { GetAllNewMeasureDTO, GetAllNewMeasuresFiltersDTO, NewMeasureCreationDTO, NewMeasureDTO, NewMeasureUpdateDTO } from "./dto";
import { NewMeasureService } from "./service";
import { keycloak } from "../../../../lib/keycloak";

export class NewMeasureServiceImpl implements NewMeasureService {

    public CreateNewMeasure(data: NewMeasureCreationDTO): Promise<string> {
        return axios.post("/api/new-measure",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateNewMeasure(data: NewMeasureUpdateDTO, id: string): Promise<void> {
        return axios.put("/api/new-measure/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteNewMeasure(id: string): Promise<void> {
        return axios.delete("/api/new-measure/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllNewMeasures(filters: GetAllNewMeasuresFiltersDTO): Promise<GetAllNewMeasureDTO> {
        return axios.get("/api/new-measure", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order,
                customerId: filters.customerId
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetNewMeasureById(id: string): Promise<NewMeasureDTO> {
        return axios.get("/api/new-measure/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}