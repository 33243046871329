import axios from "axios";
import { MeasureRecipientsService } from "./service";
import { keycloak } from "../../../../lib/keycloak";
import { GetAllMeasureRecipientsDTO, GetAllMeasureRecipientsFiltersDTO, MeasureRecipientDTO } from "./dto";

export class MeasureRecipientServiceImpl implements MeasureRecipientsService {

    public CreateMeasureRecipient(data: MeasureRecipientDTO): Promise<void> {
        return axios.post("/api/measure-recipient",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateMeasureRecipient(data: MeasureRecipientDTO, id: string): Promise<void> {
        return axios.put("/api/measure-recipient/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteMeasureRecipient(id: string): Promise<void> {
        return axios.delete("/api/measure-recipient/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public RemoveMeasureRecipient(id: string): Promise<void> {
        return axios.put("/api/measure-recipient/remove/" + id,
            {},
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public RestoreMeasureRecipient(id: string): Promise<void> {
        return axios.put("/api/measure-recipient/restore/" + id,
            {},
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllMeasureRecipients(filters: GetAllMeasureRecipientsFiltersDTO): Promise<GetAllMeasureRecipientsDTO> {
        return axios.get("/api/measure-recipient", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order,
                active: filters.active
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetMeasureRecipientById(id: string): Promise<MeasureRecipientDTO> {
        return axios.get("/api/measure-recipient/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}