import { GetAllReinforcedControlMeasureFiltersDTO, GetAllReinforcedControlMeasuresDTO, ReinforcedControlMeasureDTO, ReinforcedControlMeasuresCreationUpdateDTO } from "./dto";
import { ReinforcedControlMeasureServiceImpl } from "./serviceImpl";

export interface ReinforcedControlMeasureService {
    CreateReinforcedControlMeasure(data: ReinforcedControlMeasuresCreationUpdateDTO): Promise<void>
    UpdateReinforcedControlMeasure(data: ReinforcedControlMeasuresCreationUpdateDTO, id: string): Promise<void>
    DeleteReinforcedControlMeasure(id: string): Promise<void>
    GetAllReinforcedControlMeasures(filters: GetAllReinforcedControlMeasureFiltersDTO): Promise<GetAllReinforcedControlMeasuresDTO>
    GetReinforcedControlMeasureById(id: string): Promise<ReinforcedControlMeasureDTO>
}

export function NewReinforcedControlMeasure(): ReinforcedControlMeasureService {
    return new ReinforcedControlMeasureServiceImpl()
}