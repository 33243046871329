import { Backdrop } from "@mui/material";
import "./styles.scss";

interface Props {
    fullScreen?: boolean;
}

export function LogoSpinnerComponent(props: Props) {
    const { fullScreen } = props;

    const spinner = (
        <div className="loader">
            <div className="ball first"></div>
            <div className="ball second"></div>
            <div className="ball third"></div>
        </div>
    );

    if (fullScreen) {
        return (
            <Backdrop
                sx={{ backgroundColor: "transparent", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open
            >
                {spinner}
            </Backdrop>
        );
    }

    return spinner;
}
