import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { Calendar } from "../../../../ui/molecules/calendar"
import { ProtocolSearch } from "../../../../ui/molecules/ProtocolSearch"
import { MultiProtocolSearch } from "../../../../ui/molecules/ProtocolSearch/multisearch"
import { SelectCustom } from "../../../../ui/molecules/select"
import TextArea from "../../../../ui/molecules/textArea"
import { ReinforcedControlMeasureMotivationOptions } from "../../../../utils"
import { ReinforcedMotivationsEnum } from "../dataService/dto"
import { setReinforcedUpdateActiveFrom, setReinforcedUpdateClosingDate, setReinforcedUpdateClosingMotivationNote, setReinforcedUpdateClosingNote, setReinforcedUpdateClosingProtocolId, setReinforcedUpdateDepartureProtocolIds, setReinforcedUpdateEntryProtocolIds, setReinforcedUpdateMotivationNote, setReinforcedUpdateMotivationType, setReinforcedUpdateNote } from "../slice"

export function ReinforcedControlMeasureEditForm() {
    const dispatch = useAppDispatch()
    const reinforcedControlMeasureState = useAppSelector(state => state.reinforcedControlMeasure)

    return (
        <div>
            <div className='rcm-container'>
                <div className='rcm-header'>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}>Applicazione della Misura di Controllo Rinforzato</span>
                    </div>
                </div>

                <div style={{ padding: '24px 24px 32px 24px' }}>
                    <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                        <div style={{ flex: '0.5' }}>
                            <div className="text-left">
                                <span className="input-label">Misura di Controllo Rinforzato attiva dal: </span>
                            </div>
                            <Calendar
                                errorLabel={reinforcedControlMeasureState.reinforcedControlMeasureErrors.activeFrom ? 'Data mancante' : undefined}
                                selected={reinforcedControlMeasureState.reinforcedControlMeasuresUpdateRequest.activeFrom}
                                onChange={(date) => dispatch(setReinforcedUpdateActiveFrom(date))}
                            />
                        </div>
                        <div style={{ flex: '0.5' }}>
                            <div className="text-left">
                                <span className="input-label">Motivazione</span>
                            </div>
                            <SelectCustom
                                error={reinforcedControlMeasureState.reinforcedControlMeasureErrors.motivationType}
                                errorLabel='Motivazione mancante'
                                placeholder={'Seleziona motivazione...'}
                                value={reinforcedControlMeasureState.reinforcedControlMeasuresUpdateRequest.motivationType}
                                options={ReinforcedControlMeasureMotivationOptions}
                                onChange={(value) => dispatch(setReinforcedUpdateMotivationType(value))}
                            />
                        </div>
                    </div>

                    {
                        reinforcedControlMeasureState.reinforcedControlMeasuresUpdateRequest.motivationType === ReinforcedMotivationsEnum.OTHER && (
                            <div className='pb-5'>
                                <div className="text-left">
                                    <span className="input-label">Descrizione della motivazione: </span>
                                </div>
                                <TextArea
                                    value={reinforcedControlMeasureState.reinforcedControlMeasuresUpdateRequest.motivationNote}
                                    onChangeText={(text) => dispatch(setReinforcedUpdateMotivationNote(text))}
                                />
                            </div>
                        )
                    }

                    <div className='pb-5 pt-3' style={{ borderTopWidth: 1 }}>
                        <div className="text-left">
                            <span className="input-label">Elenco Documenti che hanno generato l'applicazione della misura (Entrata): </span>
                        </div>
                        <MultiProtocolSearch
                            type='entry'
                            defaultValue={reinforcedControlMeasureState.reinforcedControlMeasuresUpdateRequest.entryProtocolIds}
                            onSelect={(value) => dispatch(setReinforcedUpdateEntryProtocolIds(value))}
                        />
                    </div>

                    <div className='pb-5' style={{ borderBottomWidth: 1 }}>
                        <div className="text-left">
                            <span className="input-label">Elenco Documenti che hanno generato l'applicazione della misura (Partenza): </span>
                        </div>
                        <MultiProtocolSearch
                            type='departure'
                            defaultValue={reinforcedControlMeasureState.reinforcedControlMeasuresUpdateRequest.departureProtocolIds}
                            onSelect={(value) => dispatch(setReinforcedUpdateDepartureProtocolIds(value))}
                        />
                    </div>

                    <div className='pb-5 pt-3'>
                        <div className="text-left">
                            <span className="input-label">Note: </span>
                        </div>
                        <TextArea
                            value={reinforcedControlMeasureState.reinforcedControlMeasuresUpdateRequest.note}
                            onChangeText={(text) => dispatch(setReinforcedUpdateNote(text))}
                        />
                    </div>
                </div>
            </div>

            <div className='rcm-container'>
                <div className='rcm-header'>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}>Chiusura della Misura di Controllo Rinforzato</span>
                    </div>
                </div>

                <div style={{ padding: '24px 24px 32px 24px' }}>
                    <div className='pb-5'>
                        <div className="text-left">
                            <span className="input-label">Misura di Controllo Rinforzato chiusa il:  </span>
                        </div>
                        <Calendar
                            selected={reinforcedControlMeasureState.reinforcedControlMeasuresUpdateRequest.closingDate}
                            onChange={(date) => dispatch(setReinforcedUpdateClosingDate(date))}
                            onClear={(date) => dispatch(setReinforcedUpdateClosingDate(date))}
                        />
                    </div>

                    <div className='pb-5'>
                        <div className="text-left">
                            <span className="input-label">Descrizione della motivazione di chiusura: </span>
                        </div>
                        <TextArea
                            value={reinforcedControlMeasureState.reinforcedControlMeasuresUpdateRequest.closingMotivationNote}
                            onChangeText={(text) => dispatch(setReinforcedUpdateClosingMotivationNote(text))}
                        />
                    </div>

                    <div className='pb-5'>
                        <div className="text-left">
                            <span className="input-label">Comunicazione chiusura al Cliente: </span>
                        </div>
                        <ProtocolSearch
                            type='departure'
                            value={reinforcedControlMeasureState.reinforcedControlMeasuresUpdateRequest.closingProtocolId}
                            onSelect={(value) => dispatch(setReinforcedUpdateClosingProtocolId(value))}
                        />
                    </div>

                    <div className='pb-5'>
                        <div className="text-left">
                            <span className="input-label">Note </span>
                        </div>
                        <TextArea
                            value={reinforcedControlMeasureState.reinforcedControlMeasuresUpdateRequest.closingNote}
                            onChangeText={(text) => dispatch(setReinforcedUpdateClosingNote(text))}
                        />
                    </div>

                </div>
            </div>
        </div>
    )
}