/* REPORTING */

export enum ReportingInnerState {
    APERTA = 'APERTA',
    CHIUSA = 'CHIUSA',
    IN_GESTIONE = 'IN_GESTIONE'
} 

export enum ReportingType {
    OFIS = 'OFIS',
    PRODOTTO_NON_CONFORME = 'PRODOTTO_NON_CONFORME',
    ANALISI_CON_ESITO_NON_CONFORME = 'ANALISI_CON_ESITO_NON_CONFORME',
    ANALISI_CON_ESITO_CONFORME_CON_PRESENZA_DI_PRINCIPI_ATTIVI_NON_AMMESSI = 'ANALISI_CON_ESITO_CONFORME_CON_PRESENZA_DI_PRINCIPI_ATTIVI_NON_AMMESSI',
    VERIFICA_AUTORITA_DI_CONTROLLO = 'VERIFICA_AUTORITA_DI_CONTROLLO',
    VERIFICA_ORGANISMO_DI_CONTROLLO = 'VERIFICA_ORGANISMO_DI_CONTROLLO',
    ALTRO = 'ALTRO'
}

export interface ReportingCreationDTO {
    customerId: string
    reportingDate?: Date
    sender?: string
    customerOtherODC?: string
    entryProtocolId: string
    reportingState?: ReportingInnerState
    officerId: string
    reportingType?: ReportingType
    details?: string
    note?: string
}

export interface ReportingUpdateDTO {
    reportingDate?: Date
    sender?: string
    customerOtherODC?: string
    entryProtocolId: string
    reportingState?: ReportingInnerState
    reportingType?: ReportingType
    details?: string
    note?: string
}

export interface ReportingErrorsDTO {
    reportingDate: boolean
    sender: boolean
    entryProtocolId: boolean
    reportingState: boolean
    reportingType: boolean
    details: boolean
}

export interface ReportingDTO {
    id: string
    customerId: string
    reportingDate: Date
    customerHeadQuarters?: string[]
    customerInfo?: string
    sender: string
    customerOtherODC: string
    entryProtocolId: string
    entryProtocolInfo: string
    reportingState: ReportingInnerState
    officerId: string
    officerInfo: string
    reportingType: ReportingType
    details: string
    note: string
    reportingStateIds: string[]
}

export interface GetAllReportingsDTO {
    total: number
    page: number
    perPage: number
    prev: boolean
    next: boolean
    totalPage: number
    data: ReportingDTO[]
}

export interface GetAllReportingsFiltersDTO {
    itemsPerPage: number
    page: number
    customerId?: string
    headquarter?: string
    order?: boolean
    reportingState?: ReportingInnerState
    sort?: string
    year?: number
}

/* REPORTING STATE */

export enum ReportingOuterState {
    PRESA_IN_CARICO_DELLA_RICHIESTA = 'PRESA_IN_CARICO_DELLA_RICHIESTA',
    PRESA_IN_CARICO_DELLA_RICHIESTA_E_COMUNICAZIONE_AL_MITTENTE = 'PRESA_IN_CARICO_DELLA_RICHIESTA_E_COMUNICAZIONE_AL_MITTENTE',
    COMUNICAZIONE_AL_MITTENTE = 'COMUNICAZIONE_AL_MITTENTE',
    RICHIESTA_INTEGRAZIONE_DOCUMENTI = 'RICHIESTA_INTEGRAZIONE_DOCUMENTI',
    SOPPRESSIONE_CAUTELATIVA = 'SOPPRESSIONE_CAUTELATIVA',
    PROVVEDIMENTO_DEFINITIVO = 'PROVVEDIMENTO_DEFINITIVO',
    VISITA_ISPETTIVA = 'VISITA_ISPETTIVA',
    CHIUSURA_SEGNALAZIONE_E_RISPOSTA_AL_MITTENTE = 'CHIUSURA_SEGNALAZIONE_E_RISPOSTA_AL_MITTENTE'
}

export interface ReportingStateCreationUpdateDTO {
    reportingOuterState?: ReportingOuterState
    entryProtocolId: string
    departureProtocolId: string
    note?: string
    reportingId: string
}

export interface ReportingStateDTO {
    id: string
    reportingOuterState: ReportingOuterState
    entryProtocolId: string
    entryProtocolInfo: string
    departureProtocolId: string
    departureProtocolInfo: string
    note: string
    date: Date
    reportingId: string
}

export interface ReportingStateErrorsDTO {
    reportingOuterState: boolean
    entryProtocolId: boolean
    departureProtocolId: boolean
}

export interface GetAllReportingStatesDTO {
    total: number
    page: number
    perPage: number
    prev: boolean
    next: boolean
    totalPage: number
    data: ReportingStateDTO[]
}

export interface GetAllReportingStateFIltersDTO {
    itemsPerPage: number
    page: number
    reportingId?: string
    order?: boolean
    sort?: string
}