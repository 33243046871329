import axios from "axios";
import { keycloak } from "../../lib/keycloak";
import { ClienteEditCreationDTO, CustomerResponseDTO, FindAllCustomersFilters, GetAddOnByIdResponseDTO, GetAddOnsResponseDTO, GetAllRappLegaleResponseDTO, GetRapplegaleByIdDTO, PostUpdateAddOnRequestDTO, RappLegaleEditCreationDTO, contactsEditCreateRequestDTO, corporateCenterDTO } from "./dto";
import { ClientiService } from "./service";
import { addTechinicianUserDTO } from "../businessTechnician/dto";

export class ClientiServiceImpl implements ClientiService {

    public CustomerCreation(data: ClienteEditCreationDTO): Promise<number> {
        return axios.post("/api/customer-data",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public createRappLegale(data: RappLegaleEditCreationDTO): Promise<void> {
        
        return axios.post("/api/legal-representive",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public createContacts(data: contactsEditCreateRequestDTO): Promise<void> {
        
        return axios.post("/api/contact",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public createCompanyCenter(data: corporateCenterDTO): Promise<void> {
        
        return axios.post("/api/company-center",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public editCompanyCenter(id: string, data: corporateCenterDTO): Promise<void> {
        return axios.put("/api/company-center/"+id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public EditCustomerRequest(id: string, data: ClienteEditCreationDTO): Promise<void> {
        
        return axios.put("/api/customer-data/"+id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public EditLegalRapRequest(id: string, data: RappLegaleEditCreationDTO): Promise<void> {
        return axios.put("/api/legal-representive/"+id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public EditCustomerContacts(id: string, data: contactsEditCreateRequestDTO): Promise<void> {
        return axios.put("/api/contact/"+id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetCustomers(filters: FindAllCustomersFilters): Promise<any>{
        return axios.get("/api/customer-data", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page,
                norm: filters.norm,
                companyName: filters.companyName,
                search: filters.search !== '' ? filters.search : undefined,
                order: filters.order,
                headQuarterIds: filters.headquarterId,
                active: filters.active,
                operatorsGroup: filters.operatorsGroup
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetCustomersById(id: string): Promise<any>{
        return axios.get("/api/customer-data/"+id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetCustomersByCode(code: string): Promise<any>{
        return axios.get("/api/customer-data", {
            params: {
                page: 0,
                itemsPerPage: 0,
                clientCode: code
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetCustomerByCompanyName(name: string): Promise<any> {
        return axios.get("/api/customer-data/?search="+name, {
            params: {
                page: 0,
                itemsPerPage: 0
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetRappLegales(): Promise<GetAllRappLegaleResponseDTO> {
        return axios.get("/api/legal-representive", {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetRappLegaleById(id: string): Promise<any>{
        return axios.get("/api/legal-representive/"+id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public UpdateRappLegaleById(id: string, data: RappLegaleEditCreationDTO): Promise<any> {
        return axios.put("/api/legal-representive/"+id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteRappLegaleById(id: string): Promise<any> {
        return axios.delete("/api/legal-representive/"+id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeactivateRappLegaleById(id: string): Promise<any> {
        return axios.put("/api/legal-representive/remove/"+id,
            {},
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetContactsById(id: string): Promise<any>{
        return axios.get("/api/contact/"+id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetCorporateCenterById(id: string): Promise<any>{
        return axios.get("/api/company-center/"+id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public getAddOnById(id: string): Promise<GetAddOnByIdResponseDTO> {
        return axios.get("/api/add-on/"+id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    
    public postAddOn(data: PostUpdateAddOnRequestDTO): Promise<string> {
        return axios.post("/api/add-on",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public linkBusinessTech(data: addTechinicianUserDTO): Promise<void> {
   
        return axios.post("/api/users/"+data.id+"/add-technician/"+data.techId,
        {},    
        {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public updateAddOn(id: string, data: PostUpdateAddOnRequestDTO): Promise<void> {
        return axios.put("/api/add-on/"+id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public deleteAddOn(id: string): Promise<void> {
        return axios.delete("/api/add-on/"+id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public getAddOns(): Promise<GetAddOnsResponseDTO> {
        return axios.get("/api/add-on", {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}
