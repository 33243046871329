import { ReactNode, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { SelectCustom } from "../../molecules/select";
import { OptionType, sot } from "../../../utils";
import { setAuthorityOffices } from "../../../pages/clienti/slice";
import { TrashIcon } from "../../icons/trash";
import Button from "../../molecules/button";
import { GetHeadQuarter, resetGetAllSot } from "../../../pages/sot/slice";

export function AuthorityOffices() {
  const clientiState = useAppSelector((state) => state.clienti);
  const dispatch = useAppDispatch();
  const [headQuartersIdResponse, setHeadquartersIdResponse] = useState<string[]>([]);
  const [headquarter, setHeadquarters] = useState<OptionType[]>([])
  const sotState = useAppSelector(state => state.headQuarter)
  let offices: ReactNode[] = [];

  useEffect(() => {
    dispatch(GetHeadQuarter(sotState.sotFilters))
  }, [])

  useEffect(() => {
    if (sotState.responseHeadQuarterStatus === 'successfully') {
      if (sotState.responseHeadQuarter) {
        const data = sotState.responseHeadQuarter.data
        const newObjects: OptionType[] = data.map(d => {
          return { value: d.id, label: `${d.code}` } as OptionType
        })
        setHeadquarters(newObjects)
      }

      dispatch(resetGetAllSot())
    }
  }, [sotState.responseHeadQuarterStatus])

  useEffect(() => {
    if (clientiState.getCustomersByIdResponse !== undefined && clientiState.getCustomersByIdResponse.headQuartersInfo) {
      const ids = clientiState.getCustomersByIdResponse.headQuartersInfo.map((element) => element.id);
      setHeadquartersIdResponse(ids);
      dispatch(setAuthorityOffices(ids));
    }
  }, [clientiState.getCustomersByIdResponseStatus]);

  if (
    clientiState.clienteEditCreateRequest.headQuarterIds !== undefined &&
    clientiState.clienteEditCreateRequest.headQuarterIds.length > 0
  ) {
    clientiState.clienteEditCreateRequest.headQuarterIds.forEach((element, index) => {
      offices.push(
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div style={{ flex: 0.95, paddingRight: 5 }}>
            <SelectCustom
              error={clientiState.createCustomerErrors.headQuarterIds}
              errorLabel="Scegliere SOT"
              placeholder="Sede di competenza"
              options={headquarter}
              defaultValue={element}
              value={element}
              onChange={(value) => {
                const newOffice = [...clientiState.clienteEditCreateRequest.headQuarterIds];
                if (value !== undefined) {
                  newOffice[index] = value;
                  dispatch(setAuthorityOffices(newOffice));
                }
              }}
            />
          </div>
          <div
            style={{ flex: 0.05 }}
            onClick={() => {
              let newOffices = [...clientiState.clienteEditCreateRequest.headQuarterIds];
              newOffices = newOffices
                .slice(0, index)
                .concat(newOffices.slice(index + 1));
              dispatch(
                setAuthorityOffices(newOffices.length === 0 ? [""] : newOffices)
              );
            }}
          >
            <TrashIcon color="#8383AD" size={5} />
          </div>
        </div>
      );
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "6px",
        padding: "0px 24px",
        marginBottom: '50px'
      }}
    >
      <span className="input-label">Sede di competenza</span>
      {offices}

      <div className="mt-2">
        <Button
          onClick={() => {
            const newOffices = [...clientiState.clienteEditCreateRequest.headQuarterIds]
            newOffices.push('')
            dispatch(setAuthorityOffices(newOffices))
          }}
          label="Aggiungi sede"
          size="sm"
          iconPosition="off"
          variant="outline"
          color="dimgrey"
        />
      </div>
      {
        clientiState.createCustomerErrors.headQuarterIds && (
          <span className="input-supporting-text">Scegliere almeno una Sede</span>
        )
      }

    </div>
  )
}
