import axios from "axios";
import { AddedRegisterCreationDTO, AddedRegisterDTO, AddedRegisterUpdateDTO, BusinessRegisterCreationDTO, BusinessRegisterDTO, BusinessRegisterUpdateDTO, GetAllAddedRegistersDTO, GetAllAddedRegistersFiltersDTO, GetAllBusinessRegistersDTO, GetAllBusinessRegistersFiltersDTO, GetAllTypeRegistersDTO, GetAllTypeRegistersFiltersDTO, TypeRegisterDTO } from "./dto";
import { RegisterService } from "./service";
import { keycloak } from "../../../../lib/keycloak";

export class RegisterServiceImpl implements RegisterService {

    /* TYPE REGISTER */

    public CreateTypeRegister(data: TypeRegisterDTO): Promise<void> {
        return axios.post("/api/type-register",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateTypeRegister(data: TypeRegisterDTO, id: string): Promise<void> {
        return axios.put("/api/type-register/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteTypeRegister(id: string): Promise<void> {
        return axios.delete("/api/type-register/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllTypeRegisters(filters: GetAllTypeRegistersFiltersDTO): Promise<GetAllTypeRegistersDTO> {
        return axios.get("/api/type-register", {
            params: {
                itemsPerPage: filters.itemsPerPage ? filters.itemsPerPage : 0,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetTypeRegisterById(id: string): Promise<TypeRegisterDTO> {
        return axios.get("/api/type-register/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    /* ADDED REGISTER */

    public CreateAddedRegister(data: AddedRegisterCreationDTO): Promise<string> {
        return axios.post("/api/added-register",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateAddedRegister(data: AddedRegisterUpdateDTO, id: string): Promise<void> {
        return axios.put("/api/added-register/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteAddedRegister(id: string): Promise<void> {
        return axios.delete("/api/added-register/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllAddedRegisters(filters: GetAllAddedRegistersFiltersDTO): Promise<GetAllAddedRegistersDTO> {
        return axios.get("/api/added-register", {
            params: {
                itemsPerPage: filters.itemsPerPage ? filters.itemsPerPage : 0,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order,
                businessRegisterId: filters.businessRegisterId
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetAddedRegisterById(id: string): Promise<AddedRegisterDTO> {
        return axios.get("/api/added-register/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    /* BUSINESS REGISTER */
    public CreateBusinessRegister(data: BusinessRegisterCreationDTO): Promise<string> {
        return axios.post("/api/business-register",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateBusinessRegister(data: BusinessRegisterUpdateDTO, id: string): Promise<void> {
        return axios.put("/api/business-register/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteBusinessRegister(id: string): Promise<void> {
        return axios.delete("/api/business-register/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllBusinessRegisters(filters: GetAllBusinessRegistersFiltersDTO): Promise<GetAllBusinessRegistersDTO> {
        return axios.get("/api/business-register", {
            params: {
                itemsPerPage: filters.itemsPerPage ? filters.itemsPerPage : 0,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order,
                customerId: filters.customerId,
                headQuarter: filters.headQuarter,
                year: filters.year
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetBusinessRegisterById(id: string): Promise<BusinessRegisterDTO> {
        return axios.get("/api/business-register/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public LetterInfoRegister(data: { id: string, infoType: string }): Promise<void> {
        return axios.post(`/api/download/custom-letter-docx/${data.id}`,
            { infoType: data.infoType },
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                },
                responseType: 'text',
            },

        ).then((response) => {
            const base64String = response.data.slice(1, -1);
            // Decode Base64
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            // Create blob with binary
            const blob = new Blob([byteArray], {
                type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });

            // tmp url
            const url = URL.createObjectURL(blob);

            //link trigger download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Rid' + data.infoType + '.docx');
            document.body.appendChild(link);
            link.click();

            // remove link
            document.body.removeChild(link);

            // empty memory
            URL.revokeObjectURL(url);
        }).catch((error) => {
            console.error('Errore durante il download del file:', error);
        });
    }

    public DownloadRegisterLetter(id: string): Promise<void> {
        return axios.get(`/api/download/register-letter-docx/${id}`, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            },
            responseType: 'text',
        }).then((response) => {
            const base64String = response.data.slice(1, -1);
            // Decode Base64
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            // Create blob with binary
            const blob = new Blob([byteArray], {
                type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });

            // tmp url
            const url = URL.createObjectURL(blob);

            //link trigger download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Registro.docx');
            document.body.appendChild(link);
            link.click();

            // remove link
            document.body.removeChild(link);

            // empty memory
            URL.revokeObjectURL(url);
        }).catch((error) => {
            console.error('Errore durante il download del file:', error);
        });
    }

    public DownloadRegisterSubmissionDocx(data: { id: string, infoType: string }): Promise<void> {
        return axios.post(`/api/download/generate-register-submission-letter-docx/${data.id}`,
            { infoType: data.infoType },
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                },
                responseType: 'text',
            },

        ).then((response) => {
            const base64String = response.data.slice(1, -1);
            // Decode Base64
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            // Create blob with binary
            const blob = new Blob([byteArray], {
                type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });

            // tmp url
            const url = URL.createObjectURL(blob);

            //link trigger download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `LetteraInvio_${data.infoType}` + '.docx');
            document.body.appendChild(link);
            link.click();

            // remove link
            document.body.removeChild(link);

            // empty memory
            URL.revokeObjectURL(url);
        }).catch((error) => {
            console.error('Errore durante il download del file:', error);
        });
    }
}