import { ReactNode } from "react"
import { OptionType } from "../../../utils"

export interface TableSelectProps {
    type: 'radio' | 'checkbox'
    color?: TableSelectColor
    headers: string[]
    data: {value: string, labels: (string | null)[]}[] | null
    defaultValue?: string[]
    onChange?: (value: string | string[]) => void
}

export type TableSelectColor = 'green' | 'orange'

export const TableSelectColorMap = new Map<TableSelectColor, { 
    titleColor: string, 
    borderColor: string, 
    backgroundColor: string, 
    contentColor: string
  }>([
    ['green', { titleColor: '#314d51', borderColor: '#D9E6E8', backgroundColor: '#f0f7f8', contentColor: '#4D797F' }],
    ['orange', { titleColor: '#925016', borderColor: '#FAE7D7', backgroundColor: '#fff4e6', contentColor: '#BA6520' }]
  ])