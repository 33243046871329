import { useNavigate } from "react-router-dom"
import Button from "../../../ui/molecules/button"
import { ProtocolSearch } from "../../../ui/molecules/ProtocolSearch"
import { SingleCheckbox } from "../../../ui/molecules/SingleCheckbox"
import { SelectCustom } from "../../../ui/molecules/select"
import { Calendar } from "../../../ui/molecules/calendar"
import InputSearch from "../../../ui/molecules/input/InputSearch"
import Input from "../../../ui/molecules/input"
import { SearchType } from "../../../ui/molecules/input/Input.types"
import TextArea from "../../../ui/molecules/textArea"
import { useEffect, useState } from "react"
import { resetWaiverRequest, setAllowedDepartureProtocolId, setBreakoutDate, setCompletePassing, setCustomerId, setDeniedDepartureProtocolId, setEntryProtocol, setFieldsPassing, setNote, setNotificationChangeProtocolId, setOdc, setSendingDossier, setSubmissionNotificationOdcDate, UpdateWaiverToCodex } from "./liberatorieToCodex/dataService/slice"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { setFromDeniedEntryProtocolId, setFromAllowedEntryProtocolId, setFromBreakoutDate, setFromNote, UpdateWaiverFromCodex, setFromOdc, setFromFieldsPassing, setFromCompletePassing, setFromCustomerId, resetWaiverFromRequest, setFromSubmissionNotificationOdcDate, setFromNotificationSubmissionProtocolId } from "./liberatorieFromCodex/dataService/slice"
import InputMultiSearch from "../../../ui/molecules/input/InputMultiSearch"
import { format } from "date-fns"
import { OptionType } from "../../../utils"
import { GetAllOdc, resetGetAllOdcStatus } from "../../odc/slice"

interface Props {
    type: string
    close: () => void
}

export function EditLiberatoria(props: Props) {
    const navigate = useNavigate()
    const [releaseToCompleteAnotherOdc, setReleaseToCompleteAnotherOdc] = useState<boolean>(false)
    const [releaseToLandsAnotherOdc, setReleaseToLandsAnotherOdc] = useState<boolean>(false)
    const [sendingToDossier, setSendingToDossierCheck] = useState<boolean>(false)

    const [releaseFromCompleteAnotherOdc, setReleaseFromCompleteAnotherOdc] = useState<boolean>(false)
    const [releaseFromLandsAnotherOdc, setReleaseFromLandsAnotherOdc] = useState<boolean>(false)
    const [odc, setOdcOptions] = useState<OptionType[]>([])

    const waiverToState = useAppSelector(state => state.waiverToCodex)
    const waiverFromState = useAppSelector(state => state.waiverFromCodex)
    const odcState = useAppSelector(state => state.odc)
    const dispatch = useAppDispatch()

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                dispatch(resetWaiverRequest())
                dispatch(resetWaiverFromRequest())
                props.close();
            }
        };
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [props]);


    useEffect(() => {
        if (waiverToState.createUpdateWaiverToCodex.sendingDossier) {
            setSendingToDossierCheck(true)
        }
        if (waiverToState.createUpdateWaiverToCodex.completePassing) {
            setReleaseToCompleteAnotherOdc(true)
        }

        if (waiverToState.createUpdateWaiverToCodex.fieldsPassing) {
            setReleaseToLandsAnotherOdc(true)
        }
        if (waiverFromState.createUpdateWaiverFromCodex.completePassing) {
            setReleaseFromCompleteAnotherOdc(true)
        }

        if (waiverFromState.createUpdateWaiverFromCodex.fieldsPassing) {
            setReleaseFromLandsAnotherOdc(true)
        }

        dispatch(GetAllOdc({
            itemsPerPage: 0,
            page: 0
        }))
    }, [])

    useEffect(() => {
        if (odcState.getAllOdcStatus === 'successfully' &&
            odcState.getAllOdcResponse !== undefined
        ) {
            dispatch(resetGetAllOdcStatus())
            const data = odcState.getAllOdcResponse.data.map(odc => {
                return {
                    value: odc.id,
                    label: odc.companyName
                } as OptionType
            })
            setOdcOptions(data)
        }
    }, [odcState.getAllOdcStatus])

    if (props.type === 'from') {
        return (
            <div style={{ padding: '16px 24px' }}>
                <div>
                    <div style={{ flex: '1' }}>
                        <ProtocolSearch
                            type="departure"
                            defaultValue={waiverFromState.createUpdateWaiverFromCodex.departureProtocolId}
                            onSelect={(value) => console.log(value)}
                        />
                    </div>
                    <div style={{ flex: '1' }} className="mt-4">
                        <InputMultiSearch
                            defaultClientIds={[waiverFromState.createUpdateWaiverFromCodex.customerId]}
                            searchOne
                            label={'Cerca cliente'}
                            placeholder="Ragione sociale"
                            onSelectAddHandler={(res) => dispatch(setFromCustomerId(res[0]))}
                        />
                    </div>
                    <div style={{ flex: '1', flexDirection: 'row', display: 'flex', gap: '24px' }} className="mt-4">
                        <div style={{ flex: '0.5' }}>
                            <Input
                                label="Destinatario"
                                defaultValue={waiverFromState.createUpdateWaiverFromCodex.senderOrReceiver}
                            />
                        </div>
                        <div style={{ flex: '0.5' }}>
                            <Calendar
                                label="Data Invio"
                                selected={waiverFromState.createUpdateWaiverFromCodex.protocolDate}
                            />
                        </div>
                    </div>
                    <div className="text-left mt-4">
                        <SelectCustom
                            label="OdC"
                            placeholder={'ODC'}
                            defaultValue={waiverFromState.createUpdateWaiverFromCodex.odcReferenceId}
                            options={odc}
                            onChange={(value) => {
                                dispatch(setFromOdc(value))
                            }}
                        />
                    </div>
                    <div className='pb-5 mt-4'>
                        <SingleCheckbox
                            checked={releaseFromCompleteAnotherOdc}
                            setChecked={(value) => {
                                setReleaseFromCompleteAnotherOdc(value)
                                dispatch(setFromCompletePassing(value))
                            }}
                            label='Liberatoria per passaggio completo ad altro ODC'
                        />
                    </div>

                    <div className='pb-5'>
                        <Calendar
                            label="Data Presentazione Notifica"
                            selected={waiverFromState.createUpdateWaiverFromCodex.submissionNotificationDate}
                            onChange={(date) => dispatch(setFromSubmissionNotificationOdcDate(format(new Date(date), 'yyyy-MM-dd')))}
                        />
                        <div style={{ flex: '1' }} className="mt-4">
                            <ProtocolSearch
                                type="entry"
                                label="Protocollo entrata presentazione notifica"
                                defaultValue={waiverFromState.createUpdateWaiverFromCodex.notificationSubmissionProtocolId}
                                onSelect={(value) => dispatch(setFromNotificationSubmissionProtocolId(value))}
                            />
                        </div>
                    </div>

                    <div className='pb-5'>
                        <SingleCheckbox
                            checked={releaseFromLandsAnotherOdc}
                            setChecked={(value) => {
                                setReleaseFromLandsAnotherOdc(value)
                                dispatch(setFromFieldsPassing(value))
                            }}
                            label='Liberatoria per passaggio terreni ad altro ODC'
                        />
                    </div>

                    <div style={{ flex: '1' }}>
                        <ProtocolSearch
                            type="entry"
                            label="Comunicazione Liberatoria Negata"
                            defaultValue={waiverFromState.createUpdateWaiverFromCodex.deniedEntryProtocolId}
                            onSelect={(value) => dispatch(setFromDeniedEntryProtocolId(value))}

                        />
                    </div>

                    <div style={{ flex: '1' }} className="mt-4">
                        <ProtocolSearch
                            type="entry"
                            label="Comunicazione Liberatoria Concessa"
                            defaultValue={waiverFromState.createUpdateWaiverFromCodex.allowedEntryProtocolId}
                            onSelect={(value) => dispatch(setFromAllowedEntryProtocolId(value))}
                        />
                    </div>
                    <div style={{ flex: '1', flexDirection: 'row', display: 'flex', gap: '24px' }} className="mt-4">
                        <div style={{ flex: '0.5' }}>
                            <Calendar
                                selected={waiverFromState.createUpdateWaiverFromCodex.breakoutDate}
                                label="Data Evasione"
                                onClear={(date) => dispatch(setFromBreakoutDate(date))}
                                onChange={(date) => dispatch(setFromBreakoutDate(format(new Date(date), 'yyyy-MM-dd')))}
                            />
                        </div>
                        <div style={{ flex: '0.5' }}>
                            <Calendar
                                label="Data Scadenza"
                                selected={waiverFromState.createUpdateWaiverFromCodex.expirationDate}
                                disabled
                            />
                        </div>
                    </div>

                    <div className="mt-4">
                        <TextArea
                            label="Note"
                            defaultValue={waiverFromState.createUpdateWaiverFromCodex.note}
                            onChangeText={(text) => {
                                dispatch(setFromNote(text))
                            }}
                        />
                    </div>

                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingRight: 15 }}>
                    <Button
                        size="md"
                        variant="outline"
                        iconPosition="off"
                        label="Annulla"
                        color="dimgrey"
                        onClick={() => {
                            dispatch(resetWaiverFromRequest())
                            props.close();
                        }}
                    />
                    <div className="pl-2">
                        <Button
                            size="md"
                            variant="solid"
                            iconPosition="off"
                            label="Salva"
                            color="orange"
                            onClick={() => {
                                if (waiverFromState.createUpdateWaiverFromCodex.id) {
                                    dispatch(UpdateWaiverFromCodex({ data: waiverFromState.createUpdateWaiverFromCodex, id: waiverFromState.createUpdateWaiverFromCodex.id }))
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
    else if (props.type === 'to') {
        return (
            <div style={{ padding: '16px 24px' }}>
                <div>
                    <div style={{ flex: '1' }}>
                        <ProtocolSearch
                            type="entry"
                            defaultValue={waiverToState.createUpdateWaiverToCodex.entryProtocolId}
                            onSelect={(value) => dispatch(setEntryProtocol(value))
                            }
                        />
                    </div>
                    <div style={{ flex: '1' }} className="mt-4">
                        <InputMultiSearch
                            defaultClientIds={[waiverToState.createUpdateWaiverToCodex.customerId]}
                            searchOne
                            label={'Cerca cliente'}
                            placeholder="Ragione sociale"
                            onSelectAddHandler={(res) => dispatch(setCustomerId(res[0]))}
                        />
                    </div>
                    <div style={{ flex: '1', flexDirection: 'row', display: 'flex', gap: '24px' }} className="mt-4">
                        <div style={{ flex: '0.5' }}>
                            <Input
                                label="Mittente"
                                defaultValue={waiverToState.createUpdateWaiverToCodex.senderOrReceiver}
                            />
                        </div>
                        <div style={{ flex: '0.5' }}>
                            <Calendar
                                label="Data Ricezione"
                                selected={waiverToState.createUpdateWaiverToCodex.protocolDate}
                            />
                        </div>
                    </div>
                    <div className="text-left mt-4">
                        <SelectCustom
                            label="OdC"
                            placeholder={'ODC'}
                            defaultValue={waiverToState.createUpdateWaiverToCodex.odcReferenceId}
                            options={odc}
                            onChange={(value) => {
                                dispatch(setOdc(value))
                            }}
                        />
                    </div>
                    <div className='pb-5 mt-4'>
                        <SingleCheckbox
                            checked={releaseToCompleteAnotherOdc}
                            setChecked={(value) => {
                                setReleaseToCompleteAnotherOdc(value)
                                dispatch(setCompletePassing(value))
                            }}
                            label='Liberatoria per passaggio completo ad altro ODC'
                        />
                    </div>

                    <div className='pb-5'>
                        <SingleCheckbox
                            checked={releaseToLandsAnotherOdc}
                            setChecked={(value) => {
                                setReleaseToLandsAnotherOdc(value)
                                dispatch(setFieldsPassing(value))
                            }}
                            label='Liberatoria per passaggio terreni ad altro ODC'
                        />
                    </div>

                    <div>
                        <div style={{ flex: '1' }} className="mt-4">
                            <ProtocolSearch
                                label="Notifica di variazione per cambio OdC"
                                defaultValue={waiverToState.createUpdateWaiverToCodex.deniedDepartureProtocolId}
                                type="entry"
                                onSelect={(value) => dispatch(setNotificationChangeProtocolId(value))}
                            />
                        </div>
                        <div className="mt-4">
                            <Calendar
                                label="Data Presentazione Notifica"
                                selected={waiverToState.createUpdateWaiverToCodex.submissionNotificationOdcDate}
                                onChange={(date) => dispatch(setSubmissionNotificationOdcDate(format(new Date(date), 'yyyy-MM-dd')) )}
                            />
                        </div>
                    </div>

                    <div style={{ flex: '1' }} className="mt-4">
                        <ProtocolSearch
                            defaultValue={waiverToState.createUpdateWaiverToCodex.allowedDepartureProtocolId}
                            type="departure"
                            label="Comunicazione Liberatoria Negata"
                            onSelect={(value) => dispatch(setAllowedDepartureProtocolId(value))}
                        />
                    </div>

                    <div style={{ flex: '1' }} className="mt-4">
                        <ProtocolSearch
                            defaultValue={waiverToState.createUpdateWaiverToCodex.deniedDepartureProtocolId}
                            type="departure"
                            label="Comunicazione Liberatoria Concessa"
                            onSelect={(value) => dispatch(setDeniedDepartureProtocolId(value))}
                        />
                    </div>
                    <div style={{ flex: '1', flexDirection: 'row', display: 'flex', gap: '24px' }} className="mt-4">
                        <div style={{ flex: '0.5' }}>
                            <Calendar
                                selected={waiverToState.createUpdateWaiverToCodex.breakoutDate}
                                label="Data Evasione"
                                onClear={(date) => dispatch(setBreakoutDate(date))}
                                onChange={(date) => { dispatch(setBreakoutDate(format(new Date(date), 'yyyy-MM-dd'))); }}
                            />
                        </div>
                        <div style={{ flex: '0.5' }}>
                            <Calendar
                                selected={waiverToState.createUpdateWaiverToCodex.expirationDate}
                                label="Data Scadenza"
                                disabled
                            />
                        </div>
                    </div>

                    <div className="mt-4">
                        <div className='pb-5'>
                            <SingleCheckbox
                                checked={sendingToDossier}
                                setChecked={(value) => {
                                    setSendingToDossierCheck(value)
                                    dispatch(setSendingDossier(value))
                                }}
                                label="Invio fascicolo senza richiesta dell'OdC (articolo, 14 comma 1, lettera e) del Decreto Legislativo n.148 del 06/10/2023"
                            />
                        </div>
                    </div>

                    <div className="mt-4">
                        <TextArea
                            label="Note"
                            defaultValue={waiverToState.createUpdateWaiverToCodex.note}
                            onChangeText={(text) => {
                                dispatch(setNote(text))
                            }}
                        />
                    </div>

                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10, paddingRight: 15 }}>
                    <Button
                        size="md"
                        variant="outline"
                        iconPosition="off"
                        label="Annulla"
                        color="dimgrey"
                        onClick={() => {
                            dispatch(resetWaiverRequest())
                            props.close();
                        }}
                    />
                    <div className="pl-2">
                        <Button
                            size="md"
                            variant="solid"
                            iconPosition="off"
                            label="Salva"
                            color="orange"
                            onClick={() => {
                                if (waiverToState.createUpdateWaiverToCodex.id) {
                                    dispatch(UpdateWaiverToCodex({ data: waiverToState.createUpdateWaiverToCodex, id: waiverToState.createUpdateWaiverToCodex.id }))
                                }

                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
    else return (<div></div>)

}