import { useNavigate } from "react-router-dom"
import { DocumentIcon } from "../../../ui/icons/documentIcon"
import { EditIcon } from "../../../ui/icons/edit"
import { PdfDocumentIcon } from "../../../ui/icons/PdfDocumentIcon"
import { PlusIcon } from "../../../ui/icons/plus"
import { PrinterIcon } from "../../../ui/icons/PrinterIcon"
import { RoundedPlusIcon } from "../../../ui/icons/RoundedPlusIcon"
import { SquaredTickIcon } from "../../../ui/icons/SquaredTickIcon"
import { WordDocumentIcon } from "../../../ui/icons/WordDocumentIcon"
import Button from "../../../ui/molecules/button"
import { SingleCheckbox } from "../../../ui/molecules/SingleCheckbox"
import { formatterDate, optionsFormatter } from "../../../utils"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { OldCodexMeasureDTO } from "./edit/dataService/dto"
import { MeasureVersionEnum, NewMeasureDTO, NewMeasureUpdateDTO } from "./dataService/dto"
import { useEffect, useState } from "react"
import { GetAllNewMeasure, GetNewMeasureById, NewMeasureTempDTO, OldMeasureTempDTO, resetGetAllNewMeasureStatus, resetGetNewMeasureByIdStatus, setGetAllNewMeasureCustomerId, setNewMeasureIdtoUpdate, setNewMeasureUpdateRequest, setSavedNewMeasures, setSavedOldMeasures } from "./slice"
import { MeasureStatusOptions, nonComplianceMap } from "../../../utils/measuresUtils"
import { NonComplianceMapping } from "../measuresOtherOdc/dataService/dto"
import { GetAllCustomerOldMeasure } from "../measuresOtherOdc/dataService/slice"
import { GetAllOldMeasure, resetGetAllOldCodexMeasureStatus, setOldCodexMeasureIdToUpdate } from "./edit/slice"

enum MixedVersionEnum {
    OLD,
    NEW
}

interface MixedMeasureDTO {
    version: MixedVersionEnum
    measureId: string
    measureDate: Date
    measureProtocolInfo: string
    notConformityCode: string
    notConformityInfo: string
    measureType: string
    measureTypeDetail: string
    expirationDate?: Date
    status: string
    closingProtocol?: string
    RAC?: string
}

export function CodexCustomerList() {
    const dispatch = useAppDispatch()
    const newMeasureState = useAppSelector(state => state.newMeasure)
    const oldCodexMeasure = useAppSelector(state => state.oldCodexMeasure)
    const navigate = useNavigate()
    const sessionState = useAppSelector(state => state.session)
    const [measures, setMeasures] = useState<MixedMeasureDTO[]>([])

    useEffect(() => {
        dispatch(setGetAllNewMeasureCustomerId(sessionState.selected.customerId));
    }, [sessionState.selected.customerId]);

    useEffect(() => {
        if (newMeasureState.getAllNewMeasureFilters) {
            if (newMeasureState.getAllNewMeasureFilters.customerId) {
                dispatch(GetAllNewMeasure(newMeasureState.getAllNewMeasureFilters));
            }

        }
    }, [newMeasureState.getAllNewMeasureFilters]);

    useEffect(() => {
        if (newMeasureState.getAllNewMeasureStatus === 'successfully' &&
            newMeasureState.getAllNewMeasureResponse !== undefined
        ) {
            dispatch(resetGetAllNewMeasureStatus())
            const savedMeasure = newMeasureState.getAllNewMeasureResponse.data.map(nm => {
                return {
                    id: nm.id,
                    protocolInfo: nm.departureProtocolInfo,
                    ncCode: {
                        id: nm.newNonComplianceCodeDTO?.id,
                        code: nm.newNonComplianceCodeDTO?.nonComplianceCode
                    }
                } as NewMeasureTempDTO
            })
            dispatch(setSavedNewMeasures(savedMeasure))

            const currentMeasure = [...measures]
            const data: MixedMeasureDTO[] = newMeasureState.getAllNewMeasureResponse.data.map(nm => {
                return {
                    version: MixedVersionEnum.NEW,
                    measureId: nm.id,
                    measureDate: nm.measureDate,
                    measureProtocolInfo: nm.departureProtocolInfo,
                    notConformityCode: nm.nonComplianceCodeVersion === MeasureVersionEnum.OLD ? nm.oldNonComplianceCode : nm.newNonComplianceCodeDTO?.nonComplianceCode,
                    notConformityInfo: nm.nonComplianceCodeVersion === MeasureVersionEnum.OLD ? (nonComplianceMap.get(nm.oldNonComplianceCode) as NonComplianceMapping)?.nonComplianceDescription : nm.newNonComplianceCodeDTO?.description,
                    measureType: nm.nonComplianceCodeVersion === MeasureVersionEnum.OLD ? (nonComplianceMap.get(nm.oldNonComplianceCode) as NonComplianceMapping)?.measureType : null,
                    measureTypeDetail: nm.nonComplianceCodeVersion === MeasureVersionEnum.OLD ? (nonComplianceMap.get(nm.oldNonComplianceCode) as NonComplianceMapping)?.measureTypeDetail : null,
                    closingProtocol: nm.statusProtocolInfo,
                    status: optionsFormatter(nm.status, MeasureStatusOptions),
                    rac: nm.actionPlaneProtocolInfo
                } as MixedMeasureDTO
            })

            setMeasures([...currentMeasure, ...data])

            // DISPATCH GET ALL OLD CODEX
            dispatch(GetAllOldMeasure({
                itemsPerPage: 0,
                page: 0,
                customerId: sessionState.selected.customerId!,
                isCodexReleased: true
            }))
        }
    }, [newMeasureState.getAllNewMeasureStatus])

    useEffect(() => {
        if (oldCodexMeasure.getAllOldCodexMeasureStatus === 'successfully' &&
            oldCodexMeasure.getAllOldCodexMeasureResponse !== undefined
        ) {
            dispatch(resetGetAllOldCodexMeasureStatus())
            const savedOldMeasure = oldCodexMeasure.getAllOldCodexMeasureResponse.data.map(om => {
                return {
                    id: om.id,
                    protocolInfo: om.departureProtocolInfo,
                    code: om.nonCompliance?.nonComplianceCode
                } as OldMeasureTempDTO
            })
            dispatch(setSavedOldMeasures(savedOldMeasure))

            const currentMeasure = [...measures]
            const data: MixedMeasureDTO[] = oldCodexMeasure.getAllOldCodexMeasureResponse.data.map(om => {
                return {
                    version: MixedVersionEnum.OLD,
                    measureId: om.id,
                    measureDate: om.measureDate,
                    measureProtocolInfo: om.departureProtocolInfo,
                    notConformityCode: om.nonCompliance?.nonComplianceCode,
                    notConformityInfo: om.nonCompliance?.nonComplianceDescription,
                    measureType: (nonComplianceMap.get(om.nonCompliance?.nonComplianceCode) as NonComplianceMapping)?.measureType,
                    measureTypeDetail: (nonComplianceMap.get(om.nonCompliance?.nonComplianceCode) as NonComplianceMapping)?.measureTypeDetail,
                    closingProtocol: om.closingProtocolInfo,
                    status: om.status,
                    rac: om.racProtocolInfo
                } as MixedMeasureDTO
            })

            setMeasures([...currentMeasure, ...data])
        }
    }, [oldCodexMeasure.getAllOldCodexMeasureStatus])

    const onEditHandle = (m: MixedMeasureDTO) => {
        // OLD CODEX CLIENT
        if (m.version === MixedVersionEnum.OLD) {
            dispatch(setOldCodexMeasureIdToUpdate(m.measureId))
            navigate('/editCodexMeasure')
        }
        else {
            dispatch(GetNewMeasureById(m.measureId))
        }
    }

    useEffect(() => {

    }, [])

    useEffect(() => {
        if (newMeasureState.getNewMeasureByIdStatus === 'successfully' &&
            newMeasureState.getNewMeasureByIdResponse !== undefined
        ) {
            const mea = newMeasureState.getNewMeasureByIdResponse

            dispatch(setNewMeasureIdtoUpdate(mea.id))
            dispatch(setNewMeasureUpdateRequest({
                oldNonComplianceCode: mea.oldNonComplianceCode,
                newNonComplianceCodeId: mea.newNonComplianceCodeDTO?.id,
                nonComplianceNote: mea.nonComplianceNote,
                activities: mea.activities,
                productsCategories: mea.productsCategories,
                measureCorrectionVersion: mea.measureCorrectionVersion,
                measureCorrectionId: mea.measureCorrectionVersion === MeasureVersionEnum.NEW ? mea.newMeasureCorrectionId : mea.oldMeasureCorrectionId,
                oldNonComplianceCodeCorrection: mea.measureCorrectionVersion === MeasureVersionEnum.NEW ? mea.oldNonComplianceCodeCorrection : undefined,
                newNonComplianceCodeCorrectionId: mea.measureCorrectionVersion === MeasureVersionEnum.NEW ? mea.newNonComplianceCodeCorrectionDTO?.id : undefined,
                noCorrectionNote: mea.noCorrectionNote,
                nonComplianceLevel: mea.nonComplianceLevel,
                additionalNote: mea.additionalNote,
                controlType: mea.controlType,
                nonComplianceFindingDate: mea.nonComplianceFindingDate,
                nonComplianceFindingNote: mea.nonComplianceFindingNote,
                documentEntryProtocolId: mea.documentEntryProtocolId !== null ? mea.documentEntryProtocolId : undefined,
                documentDepartureProtocolId: mea.documentDepartureProtocolId !== null ? mea.documentDepartureProtocolId : undefined,
                documentIntegrationRequestId: mea.documentIntegrationRequestId,
                inspectionVisitIdTest: mea.inspectionVisitIdTest,
                findingFrom: mea.findingFrom,
                inspectorMeasureConfirmed: mea.inspectorMeasureConfirmed,
                laboratoryTestReportProtocolId: mea.laboratoryTestReportProtocolId !== null ? mea.laboratoryTestReportProtocolId : undefined,
                preventiveSuppressionM024IdTest: mea.preventiveSuppressionM024IdTest,
                m018IdTest: mea.m018IdTest,
                officerMeasureSignatureId: mea.officerMeasureSignatureId,
                departureChannel: mea.departureChannel,
                p0035IdTest: mea.p0035IdTest,
                measureRecipientsIds: mea.measureRecipientsDTO?.map(mr => mr.id),
                status: mea.status,
                statusProtocolId: mea.statusProtocolId !== null ? mea.statusProtocolId : undefined,
                actionPlaneProtocolId: mea.actionPlaneProtocolId !== null ? mea.actionPlaneProtocolId : undefined,
                closingNote: mea.closingNote,
                presentationDate: mea.presentationDate,
                presentationProtocolId: mea.presentationProtocolId !== null ? mea.presentationProtocolId : undefined,
                recourseDetail: mea.recourseDetail,
                appealReportDate: mea.appealReportDate,
                appealReportProtocolId: mea.appealReportProtocolId !== null ? mea.appealReportProtocolId : undefined,
                recourseResult: mea.recourseResult,
                recourseNote: mea.recourseNote
            }))
            dispatch(resetGetNewMeasureByIdStatus())
            navigate('/addNewCodexMeasureStep2')
        }
    }, [newMeasureState.getNewMeasureByIdStatus])

    return (
        <div style={{ padding: '24px' }}>

            {/* BUTTONS */}
            <div className="pb-5" style={{ display: 'flex', justifyContent: 'flex-end', gap: '24px' }}>
                <Button
                    variant="outline"
                    size="md"
                    color="dimgrey"
                    iconPosition="left"
                    icon={<DocumentIcon />}
                    label="Stampa provvedimenti selezionati"
                />

                <Button
                    variant="solid"
                    disabled={sessionState.selected.customerId === undefined}
                    size="md"
                    color="orange"
                    iconPosition="left"
                    icon={<RoundedPlusIcon />}
                    label="Crea nuovo"
                    onClick={() => navigate('/addNewCodexMeasureStep1')}
                />
            </div>
            <table id="users-table" className="w-[100%]">
                <thead className="h-[52px] uppercase">
                    <tr>
                        <th style={{ borderTopLeftRadius: '8px' }}></th>
                        <th className="width-[130px]">Data</th>
                        <th className="width-[130px]">Protocollo Partenza</th>
                        <th className="width-[100px]">Codice Non Conformità</th>
                        <th className="width-[20%]">Descrizione Non Conformità</th>
                        <th className="width-[130px]">Tipo Provvedimento</th>
                        <th className="width-[130px]">Misure Provvedimento</th>
                        <th className="width-[130px]">Data Scadenza</th>
                        <th className="width-[130px]">Stato</th>
                        <th className="width-[130px]">Protocollo Chiusura</th>
                        <th className="width-[130px]" style={{ textAlign: 'center' }}>M024</th>
                        <th className="width-[130px]" style={{ textAlign: 'center' }}>Provvedimento</th>
                        <th className="width-[130px]" style={{ textAlign: 'center' }}>RAC</th>
                        <th className="width-[130px]" style={{ textAlign: 'center' }}>Seleziona</th>
                        <th className="width-[130px]" style={{ textAlign: 'center' }}>Pdf Protocolli</th>
                        <th className="width-[130px]" style={{ textAlign: 'center' }}>Decaduto</th>
                        <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        measures.map((measure, index) =>
                            <tr
                                key={index}
                                style={{
                                    color: '#4D797F',
                                    background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                }}
                            >
                                <td>
                                    <div style={{ display: 'flex', gap: '6px' }}>
                                        <button onClick={() => onEditHandle(measure)}>
                                            <SquaredTickIcon />
                                        </button>
                                    </div>
                                </td>
                                <td>{formatterDate(measure.measureDate)}</td>
                                <td>{measure.measureProtocolInfo}</td>
                                <td>{measure.notConformityCode}</td>
                                <td>{measure.notConformityInfo}</td>
                                <td>{measure.measureType}</td>
                                <td>{measure.measureTypeDetail}</td>
                                <td>{formatterDate(measure.expirationDate)}</td>
                                <td>{measure.status}</td>
                                <td>{measure.closingProtocol}</td>
                                <td>
                                    <div style={{ display: 'flex', gap: '6px', justifyContent: 'center' }}>
                                        <button onClick={() => { }}>
                                            <PrinterIcon color="#5D9298" />
                                        </button>
                                        <button onClick={() => { }}>
                                            <PrinterIcon color="#0096D1" />
                                        </button>
                                    </div>
                                </td>
                                <td>
                                    <div style={{ display: 'flex', gap: '6px', justifyContent: 'center' }}>
                                        <button onClick={() => { }}>
                                            <WordDocumentIcon />
                                        </button>
                                    </div>
                                </td>
                                <td>
                                    <div style={{ display: 'flex', gap: '6px', justifyContent: 'center' }}>
                                        <button onClick={() => { }}>
                                            <WordDocumentIcon />
                                        </button>
                                        <button onClick={() => { }}>
                                            <PdfDocumentIcon />
                                        </button>
                                    </div>
                                </td>
                                <td>
                                    <div style={{ display: 'flex', gap: '6px', justifyContent: 'flex-end' }}>
                                        <SingleCheckbox
                                            checked={false}
                                            setChecked={(value) => {
                                                // dispatch
                                            }}
                                            label=''
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div style={{ display: 'flex', gap: '6px', justifyContent: 'center' }}>
                                        <button onClick={() => { }}>
                                            <PdfDocumentIcon />
                                        </button>
                                    </div>
                                </td>
                                <td>
                                    <div style={{ display: 'flex', gap: '6px', justifyContent: 'center' }}>
                                        <button onClick={() => { }}>
                                            <PrinterIcon color="#5D9298" />
                                        </button>
                                    </div>
                                </td>
                                <td></td>
                            </tr>
                        )}
                </tbody>
            </table>
        </div>
    )
}