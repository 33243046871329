import { createBrowserRouter } from "react-router-dom";
import { DashboardView } from "../../pages/dashboard";
import { Clienti } from "../../pages/clienti";
import { NewCliente } from "../../pages/clienti/newCliente";
import { EditCustomer } from "../../pages/clienti/editCustomer";
import { GestionePersonale } from "../../pages/gestionePersonale";
import { Formazione } from "../../pages/gestionePersonale/formazione";
import { NewTraining } from "../../pages/gestionePersonale/boxFormazione/newFormazione";
import { EditTraining } from "../../pages/gestionePersonale/boxFormazione/editFormazione";
import { GestioneDocumenti } from "../../pages/gestioneDocumenti";
import { PageNotFound } from "../../pages/pageNotFound";
import { DepartureProtocol } from "../../pages/protocol/departure";
import { CartellaAziendale } from "../../pages/cartellaAziendale";
import { ComplianceRequests } from "../../pages/gestioneCertificazione/complianceRequests";
import { AddComplianceRequest } from "../../pages/gestioneCertificazione/complianceRequests/add";
import { EditRequestComplianceRequest } from "../../pages/gestioneCertificazione/complianceRequests/edit";
import { RegistriAziendali } from "../../pages/gestioneClienti/registriAziendali";
import { AddRegistroAziendale } from "../../pages/gestioneClienti/registriAziendali/add";
import { EditRegistroAziendale } from "../../pages/gestioneClienti/registriAziendali/edit";
import { ReportRegistriAziendali } from "../../pages/gestioneClienti/registriAziendali/report";
import { RID } from "../../pages/gestioneClienti/RID";
import { AddRID } from "../../pages/gestioneClienti/RID/addRID";
import { EditRID } from "../../pages/gestioneClienti/RID/editRID";
import { Liberatorie } from "../../pages/gestioneControllo/liberatorie";
import { PAC } from "../../pages/gestioneControllo/pac";
import { AddPAC } from "../../pages/gestioneControllo/pac/addPac";
import { EditPAC } from "../../pages/gestioneControllo/pac/editPac";
import { PeriodoConversione } from "../../pages/gestioneControllo/periodoConversione";
import { PeriodoConversioneAdd } from "../../pages/gestioneControllo/periodoConversione/add";
import { PeriodoConversioneEdit } from "../../pages/gestioneControllo/periodoConversione/edit";
import { Segnalazioni } from "../../pages/gestioneControllo/segnalazioni";
import { AddReporting } from "../../pages/gestioneControllo/segnalazioni/addReporting";
import { EditReporting } from "../../pages/gestioneControllo/segnalazioni/editReporting";
import { EditStaff } from "../../pages/gestionePersonale/editStaff";
import { NewStaff } from "../../pages/gestionePersonale/NewStaff";
import { GestionePrelieviForAll } from "../../pages/gestionePrelievi";
import { CustomerMeasueres } from "../../pages/gestioneProvvedimenti/measures";
import { AddNewCodexMeasureStep1 } from "../../pages/gestioneProvvedimenti/measures/addNew/Step1";
import { AddNewCodexMeasureStep2 } from "../../pages/gestioneProvvedimenti/measures/addNew/Step2";
import { EditCodexMeasure } from "../../pages/gestioneProvvedimenti/measures/edit";
import { AddNewCodexMeasureOtherOdc } from "../../pages/gestioneProvvedimenti/measuresOtherOdc/add";
import { EditCodexMeasureOtherOdc } from "../../pages/gestioneProvvedimenti/measuresOtherOdc/edit";
import { ReinforcedControlMeasure } from "../../pages/gestioneProvvedimenti/reinforcedControlMeasure";
import { AddReinforcedControlMeasure } from "../../pages/gestioneProvvedimenti/reinforcedControlMeasure/add";
import { EditReinforcedControlMeasure } from "../../pages/gestioneProvvedimenti/reinforcedControlMeasure/edit";
import { AddDepartureProtocol } from "../../pages/protocol/departure/add";
import { EditDepartureProtocol } from "../../pages/protocol/departure/edit";
import { EntryProtocol } from "../../pages/protocol/entry";
import { AddEntryProtocol } from "../../pages/protocol/entry/add";
import { EditEntryProtocol } from "../../pages/protocol/entry/edit";
import { ReportProtocol } from "../../pages/protocol/report";
import { SchedaCalcoloCustomer } from "../../pages/schedaCalcolo/customer";

const userRouter = createBrowserRouter([
    {
        path: '/',
        element: (<DashboardView />),
    },
    {
        path: '/clienti',
        element: (<Clienti />)
    },
    {
        path: '/cliente',
        element: (<NewCliente />)
    },
    {
        path: '/edit-customer/:id',
        element: (<EditCustomer />)
    },
    {
        path: '/gestionePersonale',
        element: (<GestionePersonale />)
    },
    {
        path: '/formazione',
        element: (<Formazione />)
    },
    {
        path: '/nuovoFormazione',
        element: (<NewTraining />)
    },
    {
        path: '/editFormazione',
        element: (<EditTraining />)
    },
    {
        path: '/nuovoPersonale',
        element: (<NewStaff />)
    },
    {
        path: '/editPm/:id',
        element: (<EditStaff />)
    },
    {
        path: '/entryProtocol',
        element: (<EntryProtocol />)
    },
    {
        path: '/departureProtocol',
        element: (<DepartureProtocol />)
    },
    {
        path: '/reportProtocol',
        element: (<ReportProtocol />)
    },
    {
        path: '/addEntryProtocol',
        element: (<AddEntryProtocol />)
    },
    {
        path: '/editEntryProtocol',
        element: (<EditEntryProtocol />)
    },
    {
        path: '/addDepartureProtocol',
        element: (<AddDepartureProtocol />)
    },
    {
        path: '/editDepartureProtocol',
        element: (<EditDepartureProtocol />)
    },
    {
        path: '/gestioneDocumenti',
        element: (<GestioneDocumenti />)
    },
    {
        path: '*',
        element: (<PageNotFound />)
    },
    {
        path: '/cartellaAziendale',
        element: (<CartellaAziendale />)
    },
    {
        path: '/segnalazioni',
        element: (<Segnalazioni />)
    },
    {
        path: '/addSegnalazione',
        element: (<AddReporting />)
    },
    {
        path: '/rid',
        element: (<RID />)
    },
    {
        path: '/addRid',
        element: (<AddRID />)
    },
    {
        path: '/editRid',
        element: (<EditRID />)
    },
    {
        path: '/liberatorie',
        element: (<Liberatorie />)
    },
    {
        path: '/editSegnalazione',
        element: (<EditReporting />)
    },
    {
        path: '/cartellaAziendale',
        element: (<CartellaAziendale />)
    },
    {
        path: '/registriAziendali',
        element: (<RegistriAziendali />)
    },
    {
        path: '/addRegistroAziendale',
        element: (<AddRegistroAziendale />)
    },
    {
        path: '/editRegistroAziendale',
        element: (<EditRegistroAziendale />)
    },
    {
        path: '/reportRegistroAziendale',
        element: (<ReportRegistriAziendali />)
    },
    {
        path: '/pac',
        element: (<PAC />)
    },
    {
        path: '/addPacDetail',
        element: (<AddPAC />)
    },
    {
        path: '/editPacDetail',
        element: (<EditPAC />)
    },
    {
        path: '/gestionePrelieviForAll',
        element: (<GestionePrelieviForAll />)
    },
    {
        path: '/complianceRequests',
        element: (<ComplianceRequests />)
    },
    {
        path: '/addComplianceRequest',
        element: (<AddComplianceRequest />)
    },
    {
        path: '/editComplianceRequest',
        element: (<EditRequestComplianceRequest />)
    },
    {
        path: '/periodoConversione',
        element: (<PeriodoConversione />)
    },
    {
        path: '/addPeriodoConversione',
        element: (<PeriodoConversioneAdd />)
    },
    {
        path: '/editPeriodoConversione',
        element: (<PeriodoConversioneEdit />)
    },
    {
        path: '/reinforcedControlMeasure',
        element: (<ReinforcedControlMeasure />)
    },
    {
        path: '/addReinforcedControlMeasure',
        element: (<AddReinforcedControlMeasure />)
    },
    {
        path: '/editReinforcedControlMeasure',
        element: (<EditReinforcedControlMeasure />)
    },
    {
        path: '/provvedimentiCliente',
        element: (<CustomerMeasueres />)
    },
    {
        path: '/editCodexMeasure',
        element: (<EditCodexMeasure />)
    },
    {
        path: '/creaProvvedimentoAltroOdc',
        element: (<AddNewCodexMeasureOtherOdc />)
    },
    {
        path: '/addNewCodexMeasureStep1',
        element: (<AddNewCodexMeasureStep1 />)
    }, 
    {
        path: '/addNewCodexMeasureStep2',
        element: (<AddNewCodexMeasureStep2 />)
    },
    {
        path: '/editProvvedimentoAltroOdc',
        element: (<EditCodexMeasureOtherOdc />)
    },
    {
        path: '/schedaCalcoloCustomer',
        element: (<SchedaCalcoloCustomer />)
    },
])

export default userRouter;