import axios from "axios";
import { GetAllOdcDTO, GetAllOdcFiltersDTO, OdcDTO } from "./dto";
import { OdcService } from "./service";
import { keycloak } from "../../../lib/keycloak";

export class OdcServiceImpl implements OdcService {

    public CreateODC(data: OdcDTO): Promise<void> {
        return axios.post("/api/odc",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateODC(data: OdcDTO, id: string): Promise<void> {
        return axios.put("/api/odc/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public RemoveOdc(id: string): Promise<void> {
        return axios.put("/api/odc/remove/" + id,
            {},
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public RestoreOdc(id: string): Promise<void> {
        return axios.put("/api/odc/restore/" + id,
            {},
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteODC(id: string): Promise<void> {
        return axios.delete("/api/odc/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllODC(filters: GetAllOdcFiltersDTO): Promise<GetAllOdcDTO> {
        return axios.get("/api/odc", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order,
                active: filters.active
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetODCById(id: string): Promise<OdcDTO> {
        return axios.get("/api/odc/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}