import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../utils";
import { AllAreasDTO, AllAreasResponseDTO, NonComplianceCodeByIdDTO, NonComplianceCodResponseDTO } from "./dto";
import { NewNcService } from "./service";

export interface NonComplianceCodesState {
    NonComplianceCodesResponse: NonComplianceCodResponseDTO
    ConComplianceCodeResponseById: NonComplianceCodeByIdDTO
    AreasResponse: AllAreasResponseDTO
    AreasResponseByCode: NonComplianceCodeByIdDTO[]
    //STATUS
    nonComplianceCodesResponseStatus: PromiseStatuses
    ConComplianceCodeResponseByIdStatus: PromiseStatuses
    AreasResponseStatus: PromiseStatuses
    AreasbyCodeResponseStatus: PromiseStatuses
}

const initialState: NonComplianceCodesState = {
    NonComplianceCodesResponse: {},
    ConComplianceCodeResponseById: {},
    AreasResponse: {},
    AreasResponseByCode: [],
    //STATUS
    nonComplianceCodesResponseStatus: 'idle',
    ConComplianceCodeResponseByIdStatus: 'idle',
    AreasResponseStatus: 'idle',
    AreasbyCodeResponseStatus: 'idle',
}

export const GetAllNc = createAsyncThunk(
    'nonComplianceCodes/GetAllNc',
    async (thunkApi): Promise<NonComplianceCodResponseDTO> => {
        const ncService = NewNcService()
        return ncService.GetAllNc()
    },
)

export const GetAllAreas = createAsyncThunk(
    'nonComplianceCodes/GetAllAreas',
    async (thunkApi): Promise<AllAreasResponseDTO> => {
        const ncService = NewNcService()
        return ncService.GetAllAreas()
    },
)

export const GetAllncByArea = createAsyncThunk(
    'nonComplianceCodes/GetAllncByArea',
    async (areaCode: string, thunkApi): Promise<NonComplianceCodeByIdDTO[]> => {
        const ncService = NewNcService()
        return ncService.GetAllNcByArea(areaCode)
    },
)

export const GetNcById = createAsyncThunk(
    'nonComplianceCodes/GetNcById',
    async (id: string, thunkApi): Promise<NonComplianceCodeByIdDTO> => {
        const ncService = NewNcService()
        return ncService.GetNcById(id)
    },
)

const NonComplianceCodesSlice = createSlice({
    name: 'NonComplianceCodes/slice',
    initialState,
    reducers: {
        //RESET STATUS
        resetGetAllStatus: (state) => {
            state.nonComplianceCodesResponseStatus = 'idle'
        },
        resetGetByIdStatus: (state) => {
            state.ConComplianceCodeResponseByIdStatus = 'idle'
        },
        resetGetByAreaStatus: (state) => {
            state.AreasbyCodeResponseStatus = 'idle'
        }

    }
    ,
    extraReducers(builder) {
        builder
            .addCase(GetAllNc.pending, (state) => {
                state.nonComplianceCodesResponseStatus = 'loading';
            })
            .addCase(GetAllNc.fulfilled, (state, action) => {
                state.nonComplianceCodesResponseStatus = 'successfully';
                state.NonComplianceCodesResponse = action.payload
            })
            .addCase(GetAllNc.rejected, (state) => {
                state.nonComplianceCodesResponseStatus = 'failed';
            })

            .addCase(GetAllAreas.pending, (state) => {
                state.AreasResponseStatus = 'loading';
            })
            .addCase(GetAllAreas.fulfilled, (state, action) => {
                state.AreasResponseStatus = 'successfully';
                state.AreasResponse = action.payload
            })
            .addCase(GetAllAreas.rejected, (state) => {
                state.AreasResponseStatus = 'failed';
            })

            .addCase(GetAllncByArea.pending, (state) => {
                state.AreasbyCodeResponseStatus = 'loading';
            })
            .addCase(GetAllncByArea.fulfilled, (state, action) => {
                state.AreasbyCodeResponseStatus = 'successfully';
                state.AreasResponseByCode = action.payload
            })
            .addCase(GetAllncByArea.rejected, (state) => {
                state.AreasbyCodeResponseStatus = 'failed';
            })

            .addCase(GetNcById.pending, (state) => {
                state.ConComplianceCodeResponseByIdStatus = 'loading';
            })
            .addCase(GetNcById.fulfilled, (state, action) => {
                state.ConComplianceCodeResponseByIdStatus = 'successfully';
                state.ConComplianceCodeResponseById = action.payload
            })
            .addCase(GetNcById.rejected, (state) => {
                state.ConComplianceCodeResponseByIdStatus = 'failed';
            })
    },
})

export const {
    //RESET STATUS
    resetGetAllStatus,
    resetGetByIdStatus,
    resetGetByAreaStatus


} = NonComplianceCodesSlice.actions

export default NonComplianceCodesSlice.reducer