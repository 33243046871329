import React, { useEffect, useState } from "react";
import { MeasureBoxColorMap, MeasureBoxProps } from "./measureBox.types";
import './styles.scss'
import { RoundedCloseIcon } from "../../../../../ui/icons/CloseIcon";
import { EditIcon } from "../../../../../ui/icons/edit";
import Button from "../../../../../ui/molecules/button";
import { RoundedPlusIcon } from "../../../../../ui/icons/RoundedPlusIcon";
import { PopupForm } from "../../../../../ui/organisms/popupForm";
import { MeasureBoxAddPopup } from "./popup/add";
import { MeasureBoxEditPopup } from "./popup/edit";
import { Alert } from "../../../../../ui/organisms/alert";
import { InfoBox } from "../../../../../ui/molecules/infoBox";
import { AppliedMeasureDTO } from "./dataService/dto";
import { formatterDate } from "../../../../../utils";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { AppliedMeasureCreation, AppliedMeasureDelete, GetAllAppliedMeasure, resetAppliedMeasureCreationRequest, resetAppliedMeasureCreationStatus, resetAppliedMeasureDeleteStatus, resetAppliedMeasureUpdateRequest, resetAppliedMeasureUpdateStatus, resetGetAllAppliedMeasureStatus, setAppliedMeasureCreationAdditionMeasureRe16Id, setAppliedMeasureCreationAppliedMeasureRe16Id, setAppliedMeasureCreationIsAdditional, setAppliedMeasureIdToUpdate, setAppliedMeasureUpdateDetail, setAppliedMeasureUpdateEndingDate, setAppliedMeasureUpdateExpirationDate } from "../../slice";

export const MeasureBox: React.FC<MeasureBoxProps> = ({
    ...props
}) => {
    const dispatch = useAppDispatch()
    const newMeasureState = useAppSelector(state => state.newMeasure)
    const [measures, setMeasures] = useState<AppliedMeasureDTO[]>([])
    const [showPopupAdd, setShowPopupAdd] = useState<boolean>(false)
    const [showPopupEdit, setShowPopupEdit] = useState<boolean>(false)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [measureToDel, setMeasureToDel] = useState<AppliedMeasureDTO | null>(null)
    const [measureToUpdate, setMeasureToUpdate] = useState<AppliedMeasureDTO | null>(null)

    const selectedColor = MeasureBoxColorMap.get(!props.isAdditional ? 'green' : 'orange')!

    useEffect(() => {
        dispatch(GetAllAppliedMeasure({
            itemsPerPage: 0,
            page: 0,
            newMeasureId: props.newMeasureId,
            order: false
        }))
    }, [])

    useEffect(() => {
        if(props.standardMeasureToSet) {
            if (props.standardMeasure && !props.isAdditional &&
                measures.filter(m => m.code === props.standardMeasure).length < 1) {
                    props.setStandardMeasureToSet && props.setStandardMeasureToSet(false)
                    dispatch(AppliedMeasureCreation({
                        isAdditional: false,
                        appliedMeasureRe16Id: props.newMeasureId,
                        code: props.standardMeasure
                    }))
            }
        }
    }, [props.standardMeasureToSet])

    useEffect(() => {
        if (newMeasureState.getAllAppliedMeasureStatus === 'successfully' &&
            newMeasureState.getAllAppliedMeasureResponse !== undefined
        ) {
            dispatch(resetGetAllAppliedMeasureStatus())
            const data = newMeasureState.getAllAppliedMeasureResponse.data.filter(am => props.isAdditional === am.isAdditional)
            setMeasures(data)
        }
    }, [newMeasureState.getAllAppliedMeasureStatus])

    useEffect(() => {
        if (newMeasureState.appliedMeasureUpdateStatus === 'successfully') {
            dispatch(resetAppliedMeasureUpdateStatus())
            dispatch(GetAllAppliedMeasure({
                itemsPerPage: 0,
                page: 0,
                newMeasureId: props.newMeasureId,
                order: false
            }))
        }
    }, [newMeasureState.appliedMeasureUpdateStatus])

    useEffect(() => {
        if (newMeasureState.appliedMeasureCreationStatus === 'successfully') {
            dispatch(resetAppliedMeasureCreationStatus())
            dispatch(GetAllAppliedMeasure({
                itemsPerPage: 0,
                page: 0,
                newMeasureId: props.newMeasureId,
                order: false
            }))
        }
    }, [newMeasureState.appliedMeasureCreationStatus])

    useEffect(() => {
        if (newMeasureState.appliedMeasureDeleteStatus === 'successfully') {
            dispatch(resetAppliedMeasureDeleteStatus())
            dispatch(GetAllAppliedMeasure({
                itemsPerPage: 0,
                page: 0,
                newMeasureId: props.newMeasureId,
                order: false
            }))
        }
    }, [newMeasureState.appliedMeasureDeleteStatus])

    const onEditHandler = (m: AppliedMeasureDTO) => {
        dispatch(setAppliedMeasureUpdateDetail(m.detail))
        dispatch(setAppliedMeasureUpdateEndingDate(m.endingDate))
        dispatch(setAppliedMeasureUpdateExpirationDate(m.expirationDate))
        dispatch(setAppliedMeasureIdToUpdate(m.id))
        setMeasureToUpdate(m)
        setShowPopupEdit(true)
    }

    const onAddHandler = () => {
        dispatch(setAppliedMeasureCreationIsAdditional(props.isAdditional))

        props.isAdditional ?
            dispatch(setAppliedMeasureCreationAdditionMeasureRe16Id(props.newMeasureId)) :
            dispatch(setAppliedMeasureCreationAppliedMeasureRe16Id(props.newMeasureId))
        setShowPopupAdd(true)
    }

    return (
        <div id="measure-box">
            <div className="measure-box-title text-left pb-1 mb-5" style={{ color: selectedColor.titleColor, borderBottom: '2px solid ' + selectedColor.borderBottomColor }}>
                {
                    !props.isAdditional ?
                        "Misure da applicare" : "Misure aggiuntive da applicare"
                }
            </div>
            {
                measures.map(m =>
                    <div className="row mb-5" style={{ backgroundColor: selectedColor.backgroundColor }}>
                        <div style={{ display: 'flex', gap: '12px' }}>
                            <button onClick={() => {
                                setMeasureToDel(m)
                                setShowAlert(true)
                            }}>
                                <RoundedCloseIcon color={selectedColor.subtitleColor} />
                            </button>
                            <button onClick={() => {
                                onEditHandler(m)
                            }}>
                                <EditIcon color={selectedColor.subtitleColor} size={20} />
                            </button>
                        </div>
                        <div style={{ flex: 1 }}>
                            <div className="pb-2 pt-2" style={{ display: 'flex', borderBottom: '1px solid ' + selectedColor.borderBottomColor }}>
                                <div className="r-child-1">
                                    <div className="measure-box-subtitle" style={{ color: selectedColor.subtitleColor }}>
                                        Codice
                                    </div>
                                </div>
                                <div className="r-child-3">
                                    <div className="measure-box-subtitle" style={{ color: selectedColor.subtitleColor }}>
                                        Descrizione
                                    </div>
                                </div>
                                <div className="r-child-3">
                                    <div className="measure-box-subtitle" style={{ color: selectedColor.subtitleColor }}>
                                        Dettaglio
                                    </div>
                                </div>
                                <div className="r-child-1">
                                    <div className="measure-box-subtitle" style={{ color: selectedColor.subtitleColor }}>
                                        Scadenza
                                    </div>
                                </div>
                                <div className="r-child-1">
                                    <div className="measure-box-subtitle" style={{ color: selectedColor.subtitleColor }}>
                                        Data Fine
                                    </div>
                                </div>
                            </div>
                            <div className="pt-2" style={{ display: 'flex' }}>
                                <div className="r-child-1">
                                    <div className="measure-box-content" style={{ color: selectedColor.contentColor }}>
                                        {m.code}
                                    </div>
                                </div>
                                <div className="r-child-3">
                                    <div className="measure-box-content" style={{ color: selectedColor.contentColor }}>
                                        {m.description}
                                    </div>
                                </div>
                                <div className="r-child-3">
                                    <div className="measure-box-content" style={{ color: selectedColor.contentColor }}>
                                        {m.detail}
                                    </div>
                                </div>
                                <div className="r-child-1">
                                    <div className="measure-box-content" style={{ color: selectedColor.contentColor }}>
                                        {formatterDate(m.expirationDate)}
                                    </div>
                                </div>
                                <div className="r-child-1">
                                    <div className="measure-box-content" style={{ color: selectedColor.contentColor }}>
                                        {formatterDate(m.endingDate)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    disabled={!props.isAdditional && measures.length > 1}
                    variant="solid"
                    color={!props.isAdditional ? 'dimgrey' : 'orange'}
                    iconPosition="left"
                    icon={<RoundedPlusIcon />}
                    size="md"
                    label="Aggiungi misura"
                    onClick={() => onAddHandler()}
                />
            </div>
            {
                showPopupAdd &&
                <PopupForm title={'Aggiungi Misura'} headerGreen={!props.isAdditional} headerOrange={props.isAdditional} close={() => setShowPopupAdd(false)}>
                    <MeasureBoxAddPopup
                        close={() => {
                            dispatch(resetAppliedMeasureCreationRequest())
                            setShowPopupAdd(false)
                        }}
                        codeOptions={props.availableOptions}
                        type={props.isAdditional ? 'additional' : 'applied'}
                    />
                </PopupForm>
            }
            {
                (showPopupEdit && measureToUpdate) && (
                    <PopupForm title={'Modifica Misura'} headerGreen={!props.isAdditional} headerOrange={props.isAdditional} close={() => setShowPopupEdit(false)}>
                        <MeasureBoxEditPopup
                            close={() => {
                                dispatch(resetAppliedMeasureUpdateRequest())
                                setShowPopupEdit(false)
                            }}
                            measure={measureToUpdate}
                            type={props.isAdditional ? 'additional' : 'applied'}
                        />
                    </PopupForm>
                )
            }
            {
                (showAlert && measureToDel) && (
                    <Alert title="Conferma operazione" close={() => setShowAlert(false)}>
                        <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
                            <div className="pb-3">
                                <div className="pb-3">
                                    Sei sicuro di voler eliminare la misura?
                                </div>
                                <div className="m-[8px]">
                                    <InfoBox
                                        color="green"
                                        freeText={{
                                            title: null,
                                            label: `${measureToDel.code} (${measureToDel.description})`
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="mt-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    variant="outline"
                                    color="dimgrey"
                                    iconPosition="off"
                                    label="Annulla"
                                    size="md"
                                    onClick={() => setShowAlert(false)}
                                />
                                <div className="pl-8">
                                    <Button
                                        variant="solid"
                                        color="orange"
                                        iconPosition="off"
                                        label="Elimina"
                                        size="md"
                                        onClick={() => {
                                            dispatch(AppliedMeasureDelete(measureToDel.id))
                                            setShowAlert(false)
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                    </Alert>
                )
            }
        </div>
    )
}