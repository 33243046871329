import { useEffect, useState } from "react"
import { RoundedCloseIcon } from "../../../ui/icons/CloseIcon"
import { EditIcon } from "../../../ui/icons/edit"
import { SquaredTickIcon } from "../../../ui/icons/SquaredTickIcon"
import { formatterDate, optionsFormatter, ServiceTypeNormoptions } from "../../../utils"
import { PopupForm } from "../../../ui/organisms/popupForm"
import { SchedaCalcoloListViewPopup } from "./viewpopup"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { CustomerBillingWorksheetDTO } from "../dataService/dto"
import { calculateFee } from "../utils"
import { SchedaCalcoloListEditPopup } from "./editPopup"
import { GetAllCustomerBillingWorksheet, setAECreationCustomerBWId, setCustomerBWIdToUpdate, setGetAllAECustomerBillingWorksheetId, setGetAllServiceCustomerBillingWorksheetId, setGetAllSTbillingWorksheetId, setServiceCreationCustomerBillingWorksheetId } from "../slice"

export function SchedaCalcoloCustomerList() {
    const dispatch = useAppDispatch()
    const sessionState = useAppSelector(state => state.session)
    const serviceAndBillingState = useAppSelector(state => state.billingAndServiceType)
    const [showViewPopup, setShowViewPopup] = useState<boolean>(false)
    const [SCToShow, setSCToShow] = useState<CustomerBillingWorksheetDTO | null>(null)
    const [showEditPopup, setShowEditPopup] = useState<boolean>(false)

    const handleOnEdit = (sc: CustomerBillingWorksheetDTO) => {
        dispatch(setGetAllServiceCustomerBillingWorksheetId(sc.id))
        dispatch(setGetAllAECustomerBillingWorksheetId(sc.id))
        dispatch(setGetAllSTbillingWorksheetId(sc.billingWorksheetId))
        dispatch(setServiceCreationCustomerBillingWorksheetId(sc.id))
        dispatch(setAECreationCustomerBWId(sc.id))
        setShowEditPopup(true)
    }

    useEffect(() => {
        dispatch(GetAllCustomerBillingWorksheet({
            itemsPerPage: 0,
            page: 0,
            customerId: sessionState.selected.customerId!
        }))
    }, [])

    return (
        <div style={{ padding: '24px' }}>
            <table id="users-table" className="w-[100%]">
                <thead className="h-[52px] uppercase">
                    <tr>
                        <th style={{ borderTopLeftRadius: '8px' }}></th>
                        <th style={{ textAlign: 'center' }}>Anno</th>
                        <th style={{ textAlign: 'center' }}>Data Emissione</th>
                        <th style={{ textAlign: 'center' }}>Normativa</th>
                        <th style={{ textAlign: 'center' }}>Totale Imponibile</th>
                        <th style={{ textAlign: 'center' }}>Totale Iva</th>
                        <th style={{ textAlign: 'center' }}>Importo Totale</th>
                        <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        serviceAndBillingState.getAllCustomerBillingWorksheetResponse?.data.map((sc, index) =>
                            <tr
                                key={index}
                                style={{
                                    color: '#4D797F',
                                    background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                }}
                            >
                                <td></td>
                                <td style={{ textAlign: 'center' }}>{sc.year}</td>
                                <td style={{ textAlign: 'center' }}>{formatterDate(sc.createdAt)}</td>
                                <td style={{ textAlign: 'center' }}>{optionsFormatter(sc.norm, ServiceTypeNormoptions)}</td>
                                <td style={{ textAlign: 'center' }}>€{sc.totalTaxableFee}</td>
                                <td style={{ textAlign: 'center' }}>€{sc.totalIva}</td>
                                <td style={{ textAlign: 'center' }}>€{sc.totalFee}</td>
                                <td>
                                    <div style={{ display: 'flex', gap: '12px' }}>
                                        <button onClick={() => {
                                            setSCToShow(sc)
                                            setShowViewPopup(true)
                                        }}>
                                            <SquaredTickIcon />
                                        </button>
                                        <button onClick={() => {
                                            handleOnEdit(sc)
                                        }}>
                                            <EditIcon color="orange" size={20} />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )}
                </tbody>
            </table>
            {
                (showViewPopup && SCToShow) &&
                <PopupForm title={`Dettaglio Scheda di Calcolo`} headerGreen close={() => setShowViewPopup(false)}>
                    <SchedaCalcoloListViewPopup customerBilling={SCToShow} close={() => setShowViewPopup(false)} />
                </PopupForm>
            }
            {
                showEditPopup &&
                <PopupForm title={`Modifica Scheda di Calcolo`} headerGreen close={() => setShowEditPopup(false)}>
                    <SchedaCalcoloListEditPopup close={() => setShowEditPopup(false)} />
                </PopupForm>
            }
        </div>
    )
}