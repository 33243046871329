import clsx from 'clsx'
import { useNavigate } from "react-router-dom"
import { Layout } from "../../ui/layout"
import Button from "../../ui/molecules/button"
import { ClientBanner } from "../../ui/molecules/clientBanner"
import { MenuItems } from "../../ui/organisms/navbar/dto"
import { optionsFormatter, attivita_bio, normativa } from "../../utils"
import { RegistriAziendaliList } from "../gestioneClienti/registriAziendali/customerList"
import { GestionePrelieviLoadAssign } from "./loadAssign"
import { HorizontalTab } from "../../ui/organisms/horizontalTab"
import { useEffect, useState } from "react"
import { Tab } from 'react-tabs'
import { GestionePrelieviEdit } from './edit'
import { GestionePrelieviList } from './list'
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks'
import { GetByIdAnagraphic, resetGetByIdStatus } from '../gestionePersonale/boxAnagrafica/slice'
import { GetAllPersonalManagement, resetGetAllPersonalManagementResourcesStatus } from '../gestionePersonale/slice'
import { addPMResource } from './slice'

export function GestionePrelievi() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const personalManagementState = useAppSelector(state => state.personalManagement)
    const anagraficaState = useAppSelector(state => state.PMAnagrafica)
    const [selectedTab, setSelectedTab] = useState<number>(0)

    useEffect(() => {
        dispatch(GetAllPersonalManagement({itemsPerPage: 0, page: 0, function: 'PERSONALE_ISPETTIVO'}))
    }, [])

    useEffect(() => {
        if(personalManagementState.getAllPersonalManagementResourcesStatus === 'successfully' &&
            personalManagementState.getAllPersonalManagementResourcesResponse !== undefined
        ) {
            dispatch(resetGetAllPersonalManagementResourcesStatus())
            const data = personalManagementState.getAllPersonalManagementResourcesResponse.data
            for(let i=0; i<data.length; i++) {
                dispatch(GetByIdAnagraphic(String(data[i].anagraphicId)))
            }
        }
    }, [personalManagementState.getAllPersonalManagementResourcesStatus])

    useEffect(() => {
        if(anagraficaState.GetByIdAnagraphicStatus === 'successfully' &&
            anagraficaState.getByIdAnagraphicRequest !== undefined
        ) {
            dispatch(resetGetByIdStatus('idle'))
            const data = anagraficaState.getByIdAnagraphicRequest
            dispatch(addPMResource({value: data.personalManagementId, label: `${data.name} ${data.surname}`}))
        }
    }, [anagraficaState.GetByIdAnagraphicStatus])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Strumenti', 'Gestione Prelievi']}
            noPaddingLat
            headerLabel="Gestione Prelievi"
        >
            <HorizontalTab
                children={[
                    <>
                        <Tab
                            onClick={() => {
                                setSelectedTab(0)
                            }}
                            className={
                                clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                    "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": selectedTab === 0,
                                    "text-label-sm text-neutral-600": selectedTab !== 0
                                })
                            }
                            tabIndex={"0"}
                        >
                            Carica/Assegna
                        </Tab>
                        <Tab
                            onClick={() => {
                                setSelectedTab(1)
                            }}
                            className={
                                clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                    "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": selectedTab === 1,
                                    "text-label-sm text-brandSecondary-600": selectedTab !== 1
                                }
                                )
                            }
                            tabIndex={"1"}
                        >
                            Modifica
                        </Tab>
                        <Tab
                            onClick={() => {
                                setSelectedTab(2)
                            }}
                            className={
                                clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                    "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": selectedTab === 2,
                                    "text-label-sm text-brandSecondary-600": selectedTab !== 2
                                }
                                )
                            }
                            tabIndex={"2"}
                        >
                            Consultazione
                        </Tab>
                    </>
                ]}
            />
            <div className="fieldset bg-brandPrimary-200" style={{ padding: '24px' }}>
                {
                    selectedTab === 0 &&
                    <GestionePrelieviLoadAssign />
                }
                {
                    selectedTab === 1 &&
                    <GestionePrelieviEdit />
                }
                {
                    selectedTab === 2 &&
                    <GestionePrelieviList />
                }
            </div>
        </Layout>
    )
}