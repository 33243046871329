import { MeasureRecipientDTO } from "./measures/dataService/dto"

export enum VerifyComplianceMeasureEnum {
    ENTRO_20_GIORNI = 'ENTRO_20_GIORNI',
    ENTRO_10_GIORNI = 'ENTRO_10_GIORNI'
}

export enum ReliefSourceEnum {
    CONTROLLO = 'CONTROLLO',
    ALTRO = 'ALTRO'
}

export enum DepartureChannelEnum {
    RACCOMANDATA_AR = 'RACCOMANDATA_AR',
    POSTA_ELETTRONICA_CERTIFICATA = 'POSTA_ELETTRONICA_CERTIFICATA',
    RACCOMANDATA_AR_PEC = 'RACCOMANDATA_AR_PEC'
}

export enum RecourseResultEnum {
    ACCOLTO = 'ACCOLTO',
    RIGETTATO = 'ACCOLTO'
}

export enum RecurrenceEnum {
    SI = 'SI',
    NO = 'NO',
    NA = 'NA'
}

export enum StatusEnum {
    EMESSO = 'EMESSO',
    RICEVUTO_APERTO = 'RICEVUTO_APERTO',
    CHIUSO = 'CHIUSO',
    MANCATO_RISPETTO = 'MANCATO_RISPETTO',
    RECEDUTO = 'RECEDUTO',
    DECADUTO = 'DECADUTO',
    REVOCATO = 'REVOCATO',
    REVOCATO_A_SEGUITO_RICORSO = 'REVOCATO_A_SEGUITO_RICORSO',
    CLIENTE_ESCLUSO_DAL_SISTEMA_DI_CONTROLLO = 'CLIENTE_ESCLUSO_DAL_SISTEMA_DI_CONTROLLO',
    ANNULLATO = 'ANNULLATO'
}

export interface MeasureDTO {
    id: string
    isCodexRealesed: boolean

    // STEP 1
    // TO FUTURE
    // m024Id: string
    // m024Info: string
    m024IdTest: string

    // STEP 2
    departureProtocolId: string
    departureProtocolInfo: string

    // STEP 3
    customerId: string

    // DATI PROVVEDIMENTO EMESSO DA CODEX
    measureDescription: string
    specificAccessoryMeasures: string
    terminationTreatment: number
    otherAccessoryMeasures: string
    applicationMeasure: string
    verifyComplianceMeasure: VerifyComplianceMeasureEnum
    correctiveAction: boolean
    officerId: string
    officerInfo: string

    // DATI DEL RILIEVO DELLA NON CONFORMITA'
    reliefDate: Date
    entryDocumentProtocolId: string
    entryDocumentProtocolInfo: string
    departureDocumentProtocolId: string
    departureDocumentProtocolInfo: string

    reliefSource: ReliefSourceEnum

    // TO FUTURE
    // m018Id: string
    // m018Info: string
    m018IdTest: string

    m018ProtocolId: string
    m018ProtocolInfo: string // object Id: 65

    // TO FUTURE
    // inspectionVisitId: string
    // inspectionVisitInfo: string
    inspectionVisitIdTest: string

    laboratoryTestReportProtocolId: string
    laboratoryTestReportProtocolInfo: string // object Id: 63

    ncDetectedByInspector: boolean

    documentIntegrationRequestId: string
    documentIntegrationRequestInfo: string

    otherEntryProtocolId: string
    otherEntryProtocolInfo: string

    otherDepartureProtocolId: string
    otherDepartureProtocolInfo: string

    note: string

    officerMeasureSignatureId: string
    officerMeasureSignatureInfo: string

    // TO FUTURE
    // p0035Id: string
    // p0035Info: string
    p0035IdTest: string

    measureRecipients: MeasureRecipientDTO[]

    // STATO DEL PROVVEDIMENTO
    activeFrom: Date
    expirationMeasure: Date

    receiptProtocolId: string
    receiptProtocolInfo: string

    status: StatusEnum

    closingProtocolId: string
    closingProtocolInfo: string

    racProtocolId: string
    racProtocolInfo: string

    closingNote: string

    nextMeasureProtocolId: string
    nextMeasureProtocolInfo: string

    // GESTIONE RICORSO
    presentationDate: Date

    presentationProtocolId: string
    presentationProtocolInfo: string

    recourseDetail: string
    recourseResult: RecourseResultEnum
    recourseDate: Date

    resultProtocolId: string
    resultProtocolInfo: string
}