import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "../../../ui/icons/arrow-left";
import { Layout } from "../../../ui/layout";
import { MenuItems } from "../../../ui/organisms/navbar/dto";
import { SchedaCalcoloForms } from "../forms";

export function SchedaCalcoloAdminEdit() {
    const navigate = useNavigate()

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Strumenti', 'Gestione Scheda di Calcolo']}
            noPaddingLat
            headerLabel="Gestione Scheda di Calcolo"
            headerIcon={
                <button style={{paddingTop: '12px'}} onClick={() => navigate('/schedaCalcoloAdmin')}>
                    <ArrowLeftIcon />
                </button>
            }
        >
            <div className="fieldset bg-brandPrimary-200" style={{ padding: '12px 24px 24px 24px' }}>
                <SchedaCalcoloForms />
            </div>
        </Layout>
    )
}