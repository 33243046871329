import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import Button from "../../../../ui/molecules/button";
import { ProtocolSearch } from "../../../../ui/molecules/ProtocolSearch";
import { SelectCustom } from "../../../../ui/molecules/select";
import TextArea from "../../../../ui/molecules/textArea";
import { formatterDate, optionsFormatter, statoRichiestaComplianceRequest } from "../../../../utils";
import { Calendar } from "../../../../ui/molecules/calendar";
import { ComplianceRequestsDeleteState, ComplianceRequestsState, ComplianceRequetsStatesValidation, resetComplianceRequestsStateValidationStatus, setStatusComplianceRequestId, setStatusDate, setStatusDepartureProtocol, setStatusEntryProtocol, setStatusNote, setStatusOfficer, setStatusRequest } from "../slice";
import { TrashIcon } from "../../../../ui/icons/trash";
import { Alert } from "../../../../ui/organisms/alert";

export function EditStateRequest() {
    const dispatch = useAppDispatch()
    const reportingState = useAppSelector(state => state.reporting)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const complianceRequestState = useAppSelector(state => state.complianceRequest)
    const userState = useAppSelector(state => state.users)
    const [complianceRequestStateToDelete, setComplianceRequestStateToDelete] = useState<string | null>(null)

    useEffect(() => {
        dispatch(setStatusOfficer(userState.findMeResponse.id))
        dispatch(setStatusComplianceRequestId(complianceRequestState.complianceRequestId))
    })

    useEffect(() => {
        if (complianceRequestState.complianceRequestsStateErrorsStatus === 'successfully') {
            dispatch(resetComplianceRequestsStateValidationStatus())
            dispatch(ComplianceRequestsState(complianceRequestState.ComplianceRequestStateCreation))
        }
    }, [complianceRequestState.complianceRequestsStateErrorsStatus])

    return (
        <div>

            <div className='text-left' style={{ padding: '24px 24px 12px 24px' }}>
                <span className='rid-title' style={{ color: '#4D797F' }}>Stati </span>
                <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                <span className='rid-title' style={{ color: '#314D51' }}> Creazione</span>
            </div>

            <div style={{ padding: '0px 24px 12px 24px' }} className="borderBottomLightGreen">
                <div style={{ textAlign: 'left' }}>

                    <SelectCustom
                        label='Stato'
                        placeholder={'Seleziona Stato'}
                        error={complianceRequestState.complianceRequestsStateErrors.state}
                        defaultValue={complianceRequestState.ComplianceRequestStateCreation.state}
                        key={complianceRequestState.ComplianceRequestStateCreation.state || 'default'}
                        errorLabel='Selezionare uno stato'
                        options={statoRichiestaComplianceRequest}
                        onChange={(value) => dispatch(setStatusRequest(value))}
                    />
                </div>
                <div className="mt-3">

                    <div className="text-left">
                        <span className="input-label">Data</span>
                    </div>
                    <Calendar
                        selected={complianceRequestState.ComplianceRequestStateCreation.date}
                        onClear={(date) => dispatch(setStatusDate(date))}
                        errorLabel={!complianceRequestState.ComplianceRequestStateCreation.date ? 'Seleziona una data valida' : undefined}
                        onChange={(date) => dispatch(setStatusDate(date))}
                    />
                </div>
                <div className="mt-3">
                    <div>
                        <ProtocolSearch
                            type='entry'
                            onSelect={(value) => { dispatch(setStatusEntryProtocol(value)) }}
                        />
                    </div>
                </div>
                <div className="mt-3">
                    <div>
                        <ProtocolSearch
                            type='departure'
                            onSelect={(value) => { dispatch(setStatusDepartureProtocol(value)) }}
                        />
                    </div>
                </div>
                <div className="mt-4" style={{ textAlign: 'left' }}>

                    <TextArea
                        label='Note'
                        onChangeText={(text) => { dispatch(setStatusNote(text)) }}
                        defaultValue={complianceRequestState.ComplianceRequestStateCreation.note}
                        value={complianceRequestState.ComplianceRequestStateCreation.note}
                    />
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }} className="mt-6">
                    <Button
                        iconPosition="off"
                        variant="solid"
                        color="orange"
                        label="Aggiungi Stato"
                        size="sm"
                        onClick={() =>
                            dispatch(ComplianceRequetsStatesValidation(complianceRequestState.ComplianceRequestStateCreation))
                        }
                    />
                </div>
            </div>

            <div className="mt-4">
                {
                    complianceRequestState.complianceRequestStates?.map(requestState => (
                        <div style={{ padding: '12px 24px' }}>
                            <div className="state-box" style={{ display: 'flex' }}>
                                <div style={{ flex: '1' }}>
                                    <div className="text-left">
                                        <span className="state-main">{optionsFormatter(requestState.state, statoRichiestaComplianceRequest)} - {formatterDate(requestState.date)}</span>
                                    </div>
                                    <div className="text-left">
                                        {
                                            requestState.entryProtocolInfo &&
                                            <span className="state-sub">{requestState.entryProtocolInfo} </span>
                                        }
                                        {
                                            requestState.departureProtocolInfo &&
                                            <span className="state-sub">
                                                {
                                                    requestState.entryProtocolInfo &&
                                                    <span className="state-sub"> -  </span>
                                                }
                                                {requestState.departureProtocolInfo}
                                            </span>
                                        }


                                    </div>
                                    <div className="text-left">
                                        <span className="state-note">{requestState.note}</span>
                                    </div>
                                </div>
                                <div style={{ flex: '0.05', display: 'flex', alignItems: 'center' }}>
                                    <button style={{ padding: '12px', border: '1px solid #f2f2f2', borderRadius: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                        onClick={() => {
                                            setComplianceRequestStateToDelete(requestState.id)
                                            setShowAlert(true)
                                        }}>
                                        <TrashIcon size={20} color="white" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))
                }

                {
                    (showAlert && complianceRequestStateToDelete) && (
                        <Alert title="Conferma operazione" close={() => setShowAlert(false)}>
                            <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
                                <div className="pb-3">
                                    <div>
                                        Sei sicuro di voler eliminare la riga?
                                    </div>
                                </div>
                                <div className="mt-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Button
                                        variant="outline"
                                        color="dimgrey"
                                        iconPosition="off"
                                        label="Annulla"
                                        size="md"
                                        onClick={() => setShowAlert(false)}
                                    />
                                    <div className="pl-8">
                                        <Button
                                            variant="solid"
                                            color="orange"
                                            iconPosition="off"
                                            label="Elimina"
                                            size="md"
                                            onClick={() => {
                                                dispatch(ComplianceRequestsDeleteState(complianceRequestStateToDelete))
                                                setShowAlert(false)
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>
                        </Alert>
                    )
                }

            </div>

        </div>
    )
}