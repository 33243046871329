import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "../../../../ui/icons/arrow-left";
import { Layout } from "../../../../ui/layout";
import Button from "../../../../ui/molecules/button";
import { MenuItems } from "../../../../ui/organisms/navbar/dto";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { CustomerAddOtherOdcMeasueres } from "./form";
import { SelectCustom } from "../../../../ui/molecules/select";
import { useEffect, useState } from "react";
import { CreateNewMeasure, CreateOldMeasure, MeasuerOldOtherOdcValidation, resetCreationNewStatus, resetCreationStatus, setOtherOdcNewMeasuresNonComplianceVersion } from "../dataService/slice";

export function AddNewCodexMeasureOtherOdc() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const OtherOdcMeasuresState = useAppSelector(state => state.OtherOdcMeasuresState)
    const sessionState = useAppSelector(state => state.session)
    const [selectMeasureType, setSelectMeasureType] = useState<'old' | 'new'>();
    const [showSuccess, setShowSuccess] = useState<boolean>(false)

    useEffect(() => {
        if (OtherOdcMeasuresState.OtherOdcMeasuresCreationStatus === 'successfully') {
            dispatch(resetCreationStatus())
            setShowSuccess(true);
        }
    }, [OtherOdcMeasuresState.OtherOdcMeasuresCreationStatus])

    useEffect(() => {
        if (OtherOdcMeasuresState.OtherOdcNewMeasuresCreationStatus === 'successfully') {
            dispatch(resetCreationNewStatus())
            setShowSuccess(true);
        }
    }, [OtherOdcMeasuresState.OtherOdcNewMeasuresCreationStatus])

    useEffect(() => {
        if (OtherOdcMeasuresState.OtherOdcMeasuresOldErrorsStatus === 'successfully') {
            dispatch(resetCreationStatus())
            dispatch(CreateOldMeasure(OtherOdcMeasuresState.OtherOdcMeasuresCreationUpdate))
        }
    }, [])

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setShowSuccess(false)
                navigate('/provvedimentiCliente')
            }, 2000)
        }
    }, [showSuccess])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Gestione Provvedimenti', 'Nuovo Provvedimento Altro Odc']}
            headerLabel={`Nuovo Provvedimento Altro OdC`}
            customerName={sessionState.selected.customerName ? sessionState.selected.customerName : undefined}
            customerCode={sessionState.selected.customerCode ? sessionState.selected.customerCode : undefined}
            rightBar={true}
            headerIcon={
                <button style={{ paddingTop: '12px' }} onClick={() => navigate('/provvedimentiCliente')}>
                    <ArrowLeftIcon />
                </button>
            }
            headerChildren={
                <div className="text-left flex flex-row justify-end" style={{ padding: '16px', justifyContent: 'end' }}>
                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                        onClick={() => {
                            if (selectMeasureType === 'old') {
                                dispatch(MeasuerOldOtherOdcValidation(OtherOdcMeasuresState.OtherOdcMeasuresCreationUpdate))
                                //dispatch(CreateOldMeasure(OtherOdcMeasuresState.OtherOdcMeasuresCreationUpdate))
                            }
                            if (selectMeasureType === 'new') {
                                dispatch(CreateNewMeasure(OtherOdcMeasuresState.OtherOdcNewMeasuresCreationUpdate))
                            }
                        }}
                    />
                </div>
            }
        >
            {
                showSuccess &&
                <div className="gap-[8px] text-left" style={{ margin: '24px', borderRadius: '12px', backgroundColor: '#D1F5D9', borderColor: '#8AE59F', borderWidth: 2 }}>
                    <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                        Dati salvati correttamente.
                    </span>
                </div>
            }
            <div className="measure-container" style={{ backgroundColor: '#F2F7F7' }}>
                <div className='measure-header' style={{ backgroundColor: '#314d51' }}>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Provvedimenti </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}> Modalità di Inserimento</span>
                    </div>
                </div>

                <div style={{ padding: '24px 24px 32px 24px' }}>
                    <SelectCustom
                        // value={}
                        disabled={selectMeasureType ? true : false}
                        placeholder={'Seleziona modalità...'}
                        options={[
                            { value: 'new', label: 'Provvedimento (DM n.0323651 del 18/07/2024)' },
                            { value: 'old', label: 'Provvedimento (DM n.15962 del 20/12/2013)' }]}
                        onChange={(value) => {
                            if (value !== undefined)
                                setSelectMeasureType(value as 'old' | 'new');
                        }}
                    />
                </div>
            </div>
            {
                (selectMeasureType !== undefined) &&
                <div>
                    <CustomerAddOtherOdcMeasueres measureType={selectMeasureType} />
                </div>
            }

        </Layout>
    )
}