import { useEffect, useState } from 'react'
import { RoundedPlusIcon } from '../../ui/icons/RoundedPlusIcon'
import Button from '../../ui/molecules/button'
import Input from '../../ui/molecules/input/Input'
import './styles.scss'
import { RoundedCloseIcon } from '../../ui/icons/CloseIcon'
import { SelectCustom } from '../../ui/molecules/select'
import MultiSelectCheckbox from '../../ui/molecules/multiSelectCheckbox'
import { OptionType } from '../../utils'
import { ProtocolSearch } from '../../ui/molecules/ProtocolSearch'
import TextArea from '../../ui/molecules/textArea'
import { EnvelopeIcon } from '../../ui/icons/EnvelopeIcon'
import { DocumentIcon } from '../../ui/icons/documentIcon'
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks'
import { CodexSamplesAssignment, CodexSamplesCreation, GetAllCodexSamples, resetCodexSampleAssigmentResponse, resetCodexSampleAssignmentRequest, resetCodexSampleCreationRequest, resetCodexSamplesAssigmentStatus, resetCodexSamplesCreationResponse, resetCodexSamplesCreationStatus, resetGetAllCodexSamplesStatus, setCodexSampleAssignmentEntryProtocolId, setCodexSampleAssignmentEnvelopeNumbers, setCodexSampleAssignmentNote, setCodexSampleAssignmentPersonalManagementId, setCodexSampleCreationFromEnvelopeNumber, setCodexSampleCreationToEnvelopeNumber } from './slice'
import { CodexSampleChangeStatusEnum } from './dataService/dto'

export function GestionePrelieviLoadAssign() {
    const dispatch = useAppDispatch()
    const codexSampleState = useAppSelector(state => state.codexSample)
    const [loadErrorFrom, setLoadErrorFrom] = useState<boolean>(false)
    const [loadErrorTo, setLoadErrorTo] = useState<boolean>(false)
    const [stockEnvelopes, setStockEnvelopes] = useState<OptionType[]>([])
    const [selectedEnvelopes, setSelectedEnvelopes] = useState<OptionType[]>([]);

    const addZerosToNumber = (envelopNumber: string) => {
        return `${Array(7 - envelopNumber.length).fill('0').join('')}${envelopNumber}`
    }

    useEffect(() => {
        dispatch(GetAllCodexSamples({itemsPerPage: 0, page: 0, status: CodexSampleChangeStatusEnum.STOCK}))
    }, [])

    useEffect(() => {
        if(codexSampleState.getAllCodexSamplesStatus === 'successfully' &&
            codexSampleState.getAllCodexSampleResponse !== undefined
        ) {
            dispatch(resetGetAllCodexSamplesStatus())
            const options: OptionType[] = codexSampleState.getAllCodexSampleResponse.data.map(env => {
                return {
                    value: String(env.envelopNumber),
                    label: addZerosToNumber(String(env.envelopNumber))
                } as OptionType
            })
            setStockEnvelopes(options)
        }
    }, [codexSampleState.getAllCodexSamplesStatus])

    useEffect(() => {
        if (codexSampleState.codexSamplesCreationStatus === 'successfully') {
            dispatch(resetCodexSampleCreationRequest())
            dispatch(resetCodexSamplesCreationStatus())
            dispatch(GetAllCodexSamples({itemsPerPage: 0, page: 0, status: CodexSampleChangeStatusEnum.STOCK}))
        }
    }, [codexSampleState.codexSamplesCreationStatus])

    useEffect(() => {
        if(codexSampleState.codexSamplesAssigmentStatus === 'successfully') {
            dispatch(resetCodexSampleAssignmentRequest())
            dispatch(resetCodexSamplesAssigmentStatus())
            setSelectedEnvelopes([])
            dispatch(GetAllCodexSamples({itemsPerPage: 0, page: 0, status: CodexSampleChangeStatusEnum.STOCK}))
        }
    }, [codexSampleState.codexSamplesAssigmentStatus])

    return (
        <div>
            <div className="prelievi-container">
                {/* HEADER */}
                <div className='prelievi-header'>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Buste Prelievo Campioni </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title uppercase' style={{ color: '#d9e6e8' }}> Carica</span>
                    </div>
                </div>

                {/* CONTENT */}
                <div style={{ padding: '24px 24px 32px 24px' }}>

                    <div className='text-left'>
                        <span className='subtitle' style={{ color: '#314d51' }}>Carica nuove Buste Prelievo Campioni</span>
                    </div>
                    <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'flex-end' }}>
                        <div style={{ flex: '0.33' }}>
                            <div className="text-left">
                                <span className="input-label">Dal n.</span>
                            </div>
                            <Input
                                error={loadErrorFrom}
                                supportingText={loadErrorFrom ? 'N. busta di partenza mancante' : undefined}
                                value={codexSampleState.codexSamplesCreationRequest.fromEnvelopNumber}
                                placeholder="Specifica n. busta di partenza..."
                                onChangeText={(text) => dispatch(setCodexSampleCreationFromEnvelopeNumber(text))}
                            />
                        </div>
                        <div style={{ flex: '0.33' }}>
                            <div className="text-left">
                                <span className="input-label">Al n.</span>
                            </div>
                            <Input
                                error={loadErrorTo}
                                supportingText={loadErrorFrom ? 'N. busta di arrivo mancante' : undefined}
                                value={codexSampleState.codexSamplesCreationRequest.toEnvelopNumber}
                                placeholder="Specifica n. busta di arrivo..."
                                onChangeText={(text) => dispatch(setCodexSampleCreationToEnvelopeNumber(text))}
                            />
                        </div>
                        <div style={{ flex: '0.33', display: 'flex', alignItems: 'flex-end' }}>
                            <Button
                                onClick={() =>
                                    dispatch(CodexSamplesCreation(codexSampleState.codexSamplesCreationRequest))
                                }
                                iconPosition='left'
                                label='Aggiungi Buste'
                                icon={<RoundedPlusIcon />}
                                variant='solid'
                                color='orange'
                                size='lg'
                            />
                        </div>
                    </div>

                    {
                        codexSampleState.codexSamplesCreationResponse && (
                            <div>
                                {
                                    codexSampleState.codexSamplesCreationResponse.successEnvelopNumbers.length > 0 && (
                                        <div className="mt-4" style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className="prelievi-vertical-line" />
                                            <div className="pr-5" style={{ display: 'flex', gap: '24px' }}>
                                                <div className="text-left">
                                                    <div className="prelievi-bold-text">Dal n. </div>
                                                    <div className="prelievi-text">{addZerosToNumber(codexSampleState.codexSamplesCreationResponse.successEnvelopNumbers[0])}</div>
                                                </div>
                                                <div className="text-left">
                                                    <div className="prelievi-bold-text">Al n.</div>
                                                    <div className="prelievi-text">{addZerosToNumber(codexSampleState.codexSamplesCreationResponse.successEnvelopNumbers.slice(-1)[0])}</div>
                                                </div>
                                                <div className="text-left">
                                                    <div className="prelievi-bold-text">Stato</div>
                                                    <div className="prelievi-text">In Giacenza</div>
                                                </div>
                                                <button onClick={() => dispatch(resetCodexSamplesCreationResponse())}>
                                                    <RoundedCloseIcon color="#166428" />
                                                </button>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    codexSampleState.codexSamplesCreationResponse.failedEnvelopNumbers.length > 0 && (
                                        <div className="mt-4" style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className="prelievi-vertical-line-fail" />
                                            <div>
                                                <div className="text-left">
                                                    <div className="prelievi-bold-text-fail">Buste già esistenti.</div>
                                                </div>
                                                <div className="pr-5" style={{ display: 'flex', gap: '24px' }}>
                                                    <div className="text-left">
                                                        <div className="prelievi-bold-text-fail">Dal n. </div>
                                                        <div className="prelievi-text-fail">{addZerosToNumber(codexSampleState.codexSamplesCreationResponse.failedEnvelopNumbers[0])}</div>
                                                    </div>
                                                    <div className="text-left">
                                                        <div className="prelievi-bold-text-fail">Al n.</div>
                                                        <div className="prelievi-text-fail">{addZerosToNumber(codexSampleState.codexSamplesCreationResponse.failedEnvelopNumbers.slice(-1)[0])}</div>
                                                    </div>
                                                    <button onClick={() => dispatch(resetCodexSamplesCreationResponse())}>
                                                        <RoundedCloseIcon color="#940500" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }

                </div>
            </div>

            <div className="prelievi-container">
                {/* HEADER */}
                <div className='prelievi-header'>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Buste Prelievo Campioni </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title uppercase' style={{ color: '#d9e6e8' }}> ASSEGNA</span>
                    </div>
                </div>

                {/* CONTENT */}
                <div style={{ padding: '24px 24px 32px 24px' }}>
                    <div className="text-left">
                        <span className="input-label">Tecnico Ispettore</span>
                    </div>
                    <SelectCustom
                        // error={}
                        key={codexSampleState.codexSamplesAssigmentRequest.personalManagementId || 'default'}
                        value={codexSampleState.codexSamplesAssigmentRequest.personalManagementId}
                        errorLabel='Ispettore mancante'
                        placeholder={'Seleziona ispettore...'}
                        options={codexSampleState.PMResources}
                        onChange={(value) => dispatch(setCodexSampleAssignmentPersonalManagementId(value))}
                    />

                    <div className='pt-5 pb-5' style={{ borderBottomWidth: '1px' }}>
                        <div className="text-left">
                            <span className="input-label">Elenco Buste per prelievo campioni da assegnare</span>
                        </div>
                        <MultiSelectCheckbox
                            // error={}
                            errorLabel="Non è stata selezionata alcuna busta!"
                            onChange={(e) => {
                                setSelectedEnvelopes(e.value)
                                dispatch(setCodexSampleAssignmentEnvelopeNumbers(e.value))
                            }}
                            value={selectedEnvelopes}
                            option={stockEnvelopes}
                            placeholder="Buste da assegnare"
                            selectAllLabel="Tutte"
                        />
                    </div>

                    <div className='text-left pt-5 pb-3'>
                        <span className='subtitle' style={{ color: '#314d51' }}>Protocollo Invio</span>
                    </div>
                    <div className='pb-5' style={{ borderBottomWidth: '1px' }}>
                        <ProtocolSearch
                            value={codexSampleState.codexSamplesAssigmentRequest.entryProtocolId}
                            type='departure'
                            onSelect={(value) => dispatch(setCodexSampleAssignmentEntryProtocolId(value))}
                        />
                    </div>

                    <div className='pb-5'>
                        <div className="text-left">
                            <span className="input-label">Note</span>
                        </div>
                        <TextArea
                            value={codexSampleState.codexSamplesAssigmentRequest.note} 
                            onChangeText={(value) => dispatch(setCodexSampleAssignmentNote(value))} />
                    </div>

                    <div style={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
                        <Button
                            iconPosition='left'
                            icon={<EnvelopeIcon />}
                            variant='solid'
                            color='lightGreen'
                            label='Assegna Buste Prelievo Campioni'
                            size='lg'
                            onClick={() => {
                                dispatch(CodexSamplesAssignment(codexSampleState.codexSamplesAssigmentRequest))
                            }}
                        />
                        <Button
                            iconPosition='left'
                            icon={<DocumentIcon color='#fff' />}
                            variant='outline'
                            color='blue'
                            label='Stampa Lettera di Consegna'
                            size='lg'
                        />
                    </div>

                    {
                        codexSampleState.codexSamplesAssigmentResponse && (
                            <div>
                                {
                                    codexSampleState.codexSamplesAssigmentResponse.successEnvelopNumbers.length > 0 && (
                                        <div className="mt-4" style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className="prelievi-vertical-line" />
                                            <div>
                                                <div className="text-left">
                                                    <div className="prelievi-bold-text">Buste correttamente assegnate.</div>
                                                </div>
                                                <div className="pr-5" style={{ display: 'flex', gap: '24px' }}>
                                                    <div className="text-left">
                                                        <div className="prelievi-bold-text">Dal n. </div>
                                                        <div className="prelievi-text">{addZerosToNumber(codexSampleState.codexSamplesAssigmentResponse.successEnvelopNumbers[0])}</div>
                                                    </div>
                                                    <div className="text-left">
                                                        <div className="prelievi-bold-text">Al n.</div>
                                                        <div className="prelievi-text">{addZerosToNumber(codexSampleState.codexSamplesAssigmentResponse.successEnvelopNumbers.slice(-1)[0])}</div>
                                                    </div>
                                                    <div className="text-left">
                                                        <div className="prelievi-bold-text">Stato</div>
                                                        <div className="prelievi-text">Consegnate</div>
                                                    </div>
                                                    <button onClick={() => dispatch(resetCodexSampleAssigmentResponse())}>
                                                        <RoundedCloseIcon color="#166428" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    codexSampleState.codexSamplesAssigmentResponse.failedEnvelopNumbers.length > 0 && (
                                        <div className="mt-4" style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className="prelievi-vertical-line-fail" />
                                            <div>
                                                <div className="text-left">
                                                    <div className="prelievi-bold-text-fail">Errore nell'assegnazione.</div>
                                                </div>
                                                <div className="pr-5" style={{ display: 'flex', gap: '24px' }}>
                                                    <div className="text-left">
                                                        <div className="prelievi-bold-text-fail">Dal n. </div>
                                                        <div className="prelievi-text-fail">{addZerosToNumber(codexSampleState.codexSamplesAssigmentResponse.failedEnvelopNumbers[0])}</div>
                                                    </div>
                                                    <div className="text-left">
                                                        <div className="prelievi-bold-text-fail">Al n.</div>
                                                        <div className="prelievi-text-fail">{addZerosToNumber(codexSampleState.codexSamplesAssigmentResponse.failedEnvelopNumbers.slice(-1)[0])}</div>
                                                    </div>
                                                    <button onClick={() => dispatch(resetCodexSampleAssigmentResponse())}>
                                                        <RoundedCloseIcon color="#940500" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}