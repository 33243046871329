import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../utils";
import { GetAllReinforcedControlMeasureFiltersDTO, GetAllReinforcedControlMeasuresDTO, ReinforcedControlMeasureDTO, ReinforcedControlMeasureErrorsDTO, ReinforcedControlMeasuresCreationUpdateDTO } from "./dataService/dto";
import { NewReinforcedControlMeasure } from "./dataService/service";

export interface ReinforcedControlMeasuresState {

    // REQUEST
    reinforcedControlMeasuresCreationRequest: ReinforcedControlMeasuresCreationUpdateDTO
    reinforcedControlMeasuresUpdateRequest: ReinforcedControlMeasuresCreationUpdateDTO
    reinforcedControlMeasureIdToUpdate: string

    // FILTERS
    getAllReinforcedControlMeasureFilters: GetAllReinforcedControlMeasureFiltersDTO

    // ERRORS
    reinforcedControlMeasureErrors: ReinforcedControlMeasureErrorsDTO

    // STATUS
    reinforcedControlMeasureCreationStatus: PromiseStatuses
    reinforcedControlMeasureUpdateStatus: PromiseStatuses
    reinforcedControlMeasureDeleteStatus: PromiseStatuses
    getAllReinforcedControlMeasureStatus: PromiseStatuses
    getReinforcedControlMeasureByIdStatus: PromiseStatuses
    reinforcedControlMeasureValidationStatus: PromiseStatuses

    // RESPONSE
    getAllReinforcedControlMeasureResponse?: GetAllReinforcedControlMeasuresDTO
    getReinforcedControlMeasureByIdResponse?: ReinforcedControlMeasureDTO
}

const initialState: ReinforcedControlMeasuresState = {

    // REQUEST
    reinforcedControlMeasuresCreationRequest: {},
    reinforcedControlMeasuresUpdateRequest: {},
    reinforcedControlMeasureIdToUpdate: '',

    // FILTERS
    getAllReinforcedControlMeasureFilters: {
        itemsPerPage: 0,
        page: 0
    },

    // ERRORS
    reinforcedControlMeasureErrors: {
        activeFrom: false,
        motivationType: false
    },

    // STATUS
    reinforcedControlMeasureCreationStatus: 'idle',
    reinforcedControlMeasureUpdateStatus: 'idle',
    reinforcedControlMeasureDeleteStatus: 'idle',
    getAllReinforcedControlMeasureStatus: 'idle',
    getReinforcedControlMeasureByIdStatus: 'idle',
    reinforcedControlMeasureValidationStatus: 'idle'
}

export const ReinforcedControlMeasureValidation = createAsyncThunk(
    'reinforcedControlMeasure/validation',
    async (request: ReinforcedControlMeasuresCreationUpdateDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetReinforcedErrors())

        if(request.activeFrom === undefined || request.activeFrom === null) {
            thunkApi.dispatch(setReinforcedErrorActiveFrom(true))
            isValid = false
        }

        if(request.motivationType === undefined || request.motivationType === null) {
            thunkApi.dispatch(setReinforcedErrorMotivationType(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)


export const ReinforcedControlMeasureCreation = createAsyncThunk(
    'reinforcedControlMeasure/Creation',
    async (body: ReinforcedControlMeasuresCreationUpdateDTO, thunkApi): Promise<void> => {
        const reinforcedControlMeasureService = NewReinforcedControlMeasure()
        return reinforcedControlMeasureService.CreateReinforcedControlMeasure(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const ReinforcedControlMeasureUpdate = createAsyncThunk(
    'reinforcedControlMeasure/Update',
    async (request: {body: ReinforcedControlMeasuresCreationUpdateDTO, id: string}, thunkApi): Promise<void> => {
        const reinforcedControlMeasureService = NewReinforcedControlMeasure()
        return reinforcedControlMeasureService.UpdateReinforcedControlMeasure(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const ReinforcedControlMeasureDelete = createAsyncThunk(
    'reinforcedControlMeasure/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const reinforcedControlMeasureService = NewReinforcedControlMeasure()
        return reinforcedControlMeasureService.DeleteReinforcedControlMeasure(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllReinforcedControlMeasures = createAsyncThunk(
    'reinforcedControlMeasure/GetAll',
    async (filters: GetAllReinforcedControlMeasureFiltersDTO, thunkApi): Promise<GetAllReinforcedControlMeasuresDTO> => {
        const reinforcedControlMeasureService = NewReinforcedControlMeasure()
        return reinforcedControlMeasureService.GetAllReinforcedControlMeasures(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetReinforcedControlMeasureById = createAsyncThunk(
    'reinforcedControlMeasure/GetById',
    async (id: string, thunkApi): Promise<ReinforcedControlMeasureDTO> => {
        const reinforcedControlMeasureService = NewReinforcedControlMeasure()
        return reinforcedControlMeasureService.GetReinforcedControlMeasureById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

const ReinforcedControlMeasureSlice = createSlice({
    name: 'reinforcedControlMeasure/slice',
    initialState,
    reducers: {

        // REQUEST [CREATION]
        setReinforcedCreationActiveFrom: (state, action) => {
            state.reinforcedControlMeasuresCreationRequest.activeFrom = action.payload
        },
        setReinforcedCreationMotivationType: (state, action) => {
            state.reinforcedControlMeasuresCreationRequest.motivationType = action.payload
        },
        setReinforcedCreationMotivationNote: (state, action) => {
            state.reinforcedControlMeasuresCreationRequest.motivationNote = action.payload
        },
        setReinforcedCreationEntryProtocolIds: (state, action) => {
            state.reinforcedControlMeasuresCreationRequest.entryProtocolIds = action.payload
        },
        setReinforcedCreationDepartureProtocolIds: (state, action) => {
            state.reinforcedControlMeasuresCreationRequest.departureProtocolIds = action.payload
        },
        setReinforcedCreationNote: (state, action) => {
            state.reinforcedControlMeasuresCreationRequest.note = action.payload
        },
        setReinforcedCreationClosingDate: (state, action) => {
            state.reinforcedControlMeasuresCreationRequest.closingDate = action.payload
        },
        setReinforcedCreationClosingMotivationNote: (state, action) => {
            state.reinforcedControlMeasuresCreationRequest.closingMotivationNote = action.payload
        },
        setReinforcedCreationClosingProtocolId: (state, action) => {
            state.reinforcedControlMeasuresCreationRequest.closingProtocolId = action.payload
        },
        setReinforcedCreationClosingNote: (state, action) => {
            state.reinforcedControlMeasuresCreationRequest.closingNote = action.payload
        },
        setReinforcedCreationCustomerDataId: (state, action) => {
            state.reinforcedControlMeasuresCreationRequest.customerDataId = action.payload
        },
        resetReinforcedControlMeasureCreationRequest: (state) => {
            state.reinforcedControlMeasuresCreationRequest = {}
        },

        // REQUEST [UPDATE]
        setReinforcedIdToUpdate: (state, action) => {
            state.reinforcedControlMeasureIdToUpdate = action.payload
        },
        setReinforcedUpdateActiveFrom: (state, action) => {
            state.reinforcedControlMeasuresUpdateRequest.activeFrom = action.payload
        },
        setReinforcedUpdateMotivationType: (state, action) => {
            state.reinforcedControlMeasuresUpdateRequest.motivationType = action.payload
        },
        setReinforcedUpdateMotivationNote: (state, action) => {
            state.reinforcedControlMeasuresUpdateRequest.motivationNote = action.payload
        },
        setReinforcedUpdateEntryProtocolIds: (state, action) => {
            state.reinforcedControlMeasuresUpdateRequest.entryProtocolIds = action.payload
        },
        setReinforcedUpdateDepartureProtocolIds: (state, action) => {
            state.reinforcedControlMeasuresUpdateRequest.departureProtocolIds = action.payload
        },
        setReinforcedUpdateNote: (state, action) => {
            state.reinforcedControlMeasuresUpdateRequest.note = action.payload
        },
        setReinforcedUpdateClosingDate: (state, action) => {
            state.reinforcedControlMeasuresUpdateRequest.closingDate = action.payload
        },
        setReinforcedUpdateClosingMotivationNote: (state, action) => {
            state.reinforcedControlMeasuresUpdateRequest.closingMotivationNote = action.payload
        },
        setReinforcedUpdateClosingProtocolId: (state, action) => {
            state.reinforcedControlMeasuresUpdateRequest.closingProtocolId = action.payload
        },
        setReinforcedUpdateClosingNote: (state, action) => {
            state.reinforcedControlMeasuresUpdateRequest.closingNote = action.payload
        },
        resetReinforcedControlMeasureUpdateRequest: (state) => {
            state.reinforcedControlMeasuresUpdateRequest = {}
        },

        // FILTERS
        setGetAllReinforcedItemsPerPage: (state, action) => {
            state.getAllReinforcedControlMeasureFilters.itemsPerPage = action.payload
        },
        setGetAllReinforcedPage: (state, action) => {
            state.getAllReinforcedControlMeasureFilters.page = action.payload
        },
        setGetAllReinforcedCountryRegion: (state, action) => {
            state.getAllReinforcedControlMeasureFilters.countryRegion = action.payload
        },
        setGetAllReinforcedCustomerDataId: (state, action) => {
            state.getAllReinforcedControlMeasureFilters.customerDataId = action.payload
        },
        setGetAllReinforcedHeadQuarter: (state, action) => {
            state.getAllReinforcedControlMeasureFilters.headQuarter = action.payload
        },
        setGetAllReinforcedOrder: (state, action) => {
            state.getAllReinforcedControlMeasureFilters.order = action.payload
        },
        setGetAllReinforcedSort: (state, action) => {
            state.getAllReinforcedControlMeasureFilters.sort = action.payload
        },
        setGetAllReinforcedYear: (state, action) => {
            state.getAllReinforcedControlMeasureFilters.year = action.payload
        },
        resetGetAllReinforcedControlMeasureFilters: (state) => {
            state.getAllReinforcedControlMeasureFilters = {
                itemsPerPage: 0,
                page: 0,
                countryRegion: ''
            }
        },

        // ERRORS
        setReinforcedErrorActiveFrom: (state, action) => {
            state.reinforcedControlMeasureErrors.activeFrom = action.payload
        },
        setReinforcedErrorMotivationType: (state, action) => {
            state.reinforcedControlMeasureErrors.motivationType = action.payload
        },
        resetReinforcedErrors: (state) => {
            state.reinforcedControlMeasureErrors = {
                activeFrom: false,
                motivationType: false
            }
        },

        // STATUS
        resetReinforcedControlMeasureCreationStatus: (state) => {
            state.reinforcedControlMeasureCreationStatus = 'idle'
        },
        resetReinforcedControlMeasureUpdateStatus: (state) => {
            state.reinforcedControlMeasureUpdateStatus = 'idle'
        },
        resetReinforcedControlMeasureDeleteStatus: (state) => {
            state.reinforcedControlMeasureDeleteStatus = 'idle'
        },
        resetGetAllReinforcedControlMeasureStatus: (state) => {
            state.getAllReinforcedControlMeasureStatus = 'idle'
        },
        resetGetReinforcedControlMeasureByIdStatus: (state) => {
            state.getReinforcedControlMeasureByIdStatus = 'idle'
        },
        resetReinforcedControlMeasureValidation: (state) => {
            state.reinforcedControlMeasureValidationStatus = 'idle'
        }
    },
    extraReducers(builder) {
        builder

            // [REINFORCED CONTROL MEASURE]
            // CREATION
            .addCase(ReinforcedControlMeasureCreation.pending, (state) => {
                state.reinforcedControlMeasureCreationStatus = 'loading'
            })
            .addCase(ReinforcedControlMeasureCreation.fulfilled, (state) => {
                state.reinforcedControlMeasureCreationStatus = 'successfully'
            })
            .addCase(ReinforcedControlMeasureCreation.rejected, (state) => {
                state.reinforcedControlMeasureCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(ReinforcedControlMeasureUpdate.pending, (state) => {
                state.reinforcedControlMeasureUpdateStatus = 'loading'
            })
            .addCase(ReinforcedControlMeasureUpdate.fulfilled, (state) => {
                state.reinforcedControlMeasureUpdateStatus = 'successfully'
            })
            .addCase(ReinforcedControlMeasureUpdate.rejected, (state) => {
                state.reinforcedControlMeasureUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(ReinforcedControlMeasureDelete.pending, (state) => {
                state.reinforcedControlMeasureDeleteStatus = 'loading'
            })
            .addCase(ReinforcedControlMeasureDelete.fulfilled, (state) => {
                state.reinforcedControlMeasureDeleteStatus = 'successfully'
            })
            .addCase(ReinforcedControlMeasureDelete.rejected, (state) => {
                state.reinforcedControlMeasureDeleteStatus = 'failed'
            })

            // GET ALL
            .addCase(GetAllReinforcedControlMeasures.pending, (state) => {
                state.getAllReinforcedControlMeasureStatus = 'loading'
            })
            .addCase(GetAllReinforcedControlMeasures.fulfilled, (state, action) => {
                state.getAllReinforcedControlMeasureStatus = 'successfully'
                state.getAllReinforcedControlMeasureResponse = action.payload
            })
            .addCase(GetAllReinforcedControlMeasures.rejected, (state) => {
                state.getAllReinforcedControlMeasureStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetReinforcedControlMeasureById.pending, (state) => {
                state.getReinforcedControlMeasureByIdStatus = 'loading'
            })
            .addCase(GetReinforcedControlMeasureById.fulfilled, (state, action) => {
                state.getReinforcedControlMeasureByIdStatus = 'successfully'
                state.getReinforcedControlMeasureByIdResponse = action.payload
            })
            .addCase(GetReinforcedControlMeasureById.rejected, (state) => {
                state.getReinforcedControlMeasureByIdStatus = 'failed'
            })

            // VALIDATION
            .addCase(ReinforcedControlMeasureValidation.pending, (state) => {
                state.reinforcedControlMeasureValidationStatus = 'loading'
            })
            .addCase(ReinforcedControlMeasureValidation.fulfilled, (state) => {
                state.reinforcedControlMeasureValidationStatus = 'successfully'
            })
            .addCase(ReinforcedControlMeasureValidation.rejected, (state) => {
                state.reinforcedControlMeasureValidationStatus = 'failed'
            })
    },
})

export const {
    // REQUEST
    setReinforcedCreationActiveFrom,
    setReinforcedCreationClosingDate,
    setReinforcedCreationClosingMotivationNote,
    setReinforcedCreationClosingNote,
    setReinforcedCreationClosingProtocolId,
    setReinforcedCreationCustomerDataId,
    setReinforcedCreationDepartureProtocolIds,
    setReinforcedCreationEntryProtocolIds,
    setReinforcedCreationMotivationNote,
    setReinforcedCreationMotivationType,
    setReinforcedCreationNote,
    setReinforcedIdToUpdate,
    setReinforcedUpdateActiveFrom,
    setReinforcedUpdateClosingDate,
    setReinforcedUpdateClosingMotivationNote,
    setReinforcedUpdateClosingNote,
    setReinforcedUpdateClosingProtocolId,
    setReinforcedUpdateDepartureProtocolIds,
    setReinforcedUpdateEntryProtocolIds,
    setReinforcedUpdateMotivationNote,
    setReinforcedUpdateMotivationType,
    setReinforcedUpdateNote,
    resetReinforcedControlMeasureCreationRequest,
    resetReinforcedControlMeasureUpdateRequest,

    // ERRORS
    setReinforcedErrorActiveFrom,
    setReinforcedErrorMotivationType,
    resetReinforcedErrors,

    // FILTERS
    setGetAllReinforcedCountryRegion,
    setGetAllReinforcedCustomerDataId,
    setGetAllReinforcedHeadQuarter,
    setGetAllReinforcedItemsPerPage,
    setGetAllReinforcedOrder,
    setGetAllReinforcedPage,
    setGetAllReinforcedSort,
    setGetAllReinforcedYear,
    resetGetAllReinforcedControlMeasureFilters,

    // STATUS
    resetGetAllReinforcedControlMeasureStatus,
    resetGetReinforcedControlMeasureByIdStatus,
    resetReinforcedControlMeasureCreationStatus,
    resetReinforcedControlMeasureDeleteStatus,
    resetReinforcedControlMeasureUpdateStatus,
    resetReinforcedControlMeasureValidation
} = ReinforcedControlMeasureSlice.actions

export default ReinforcedControlMeasureSlice.reducer