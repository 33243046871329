import axios from "axios";
import { GetAllOldCodexMeasureFiltersDTO, GetAllOldCodexMeasureDTO, OldCodexMeasureDTO, OldCodexMeasureUpdateDTO } from "./dto";
import { OldCodexMeasureService } from "./service";
import { keycloak } from "../../../../../lib/keycloak";

export class OldCodexMeasureServiceImpl implements OldCodexMeasureService {

    public GetAllOldCodexMeasures(filters: GetAllOldCodexMeasureFiltersDTO): Promise<GetAllOldCodexMeasureDTO> {
        return axios.get("/api/old-measure", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order,
                customerId: filters.customerId,
                isCodexReleased: filters.isCodexReleased
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetOldCodexMeasureById(id: string): Promise<OldCodexMeasureDTO> {
        return axios.get("/api/old-measure/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public UpdateOldCodexMeasure(data: OldCodexMeasureUpdateDTO, id: string): Promise<void> {
        return axios.put("/api/old-measure/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }
}