interface ProtocolData {
    protocolId: string;
    protocolInfo: string;
}

export const handleProtocolListing = (ep: ProtocolData[], dp: ProtocolData[]) => {
    const allProts: ProtocolData[] = []
    if (ep && ep.length > 0)
        ep.forEach(p => allProts.push(p))

    if (dp && dp.length > 0)
        dp.forEach(p => allProts.push(p))

    if (allProts.length < 1)
        return null

    return (
        <ul style={{ listStyleType: 'disc' }}>
            {
                allProts.map(m => (
                    <li>{m.protocolInfo}</li>
                ))
            }
        </ul>
    )
}