import Button from "../../../../ui/molecules/button";
import { PlusOrange } from "../../../../ui/icons/plusOrange";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { TrashIcon } from "../../../../ui/icons/trash";
import Input from "../../../../ui/molecules/input";
import { useEffect } from "react";
import { addCustomerOtherOdcDefault, addCustomerOtherOdcDefaultEdit, setClientCodeOtherOdcCreation, setClientCodeOtherOdcUpdate, setCompanyNameOtherOdcCreation, setCompanyNameOtherOdcUpdate, setCreationCustomerOtherOdcDefault, setCuaaOtherOdcCreation, setCuaaOtherOdcUpdate, setEditCustomerOtherOdcDefault } from "../slice";

export function CustomersOtherOdCAdd() {
    const dispatch = useAppDispatch()
    const complianceRequestState = useAppSelector(state => state.complianceRequest)

    return (
        <div className="pb-5" style={{ borderBottomWidth: 1 }}>
            <div className='pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                <div className="text-left" style={{ flex: '0.5' }}>
                    <span className="subtitle">Clienti altro Odc</span>
                </div>
                <div style={{ flex: '0.5', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        size='sm'
                        color='orange'
                        variant='outline'
                        label='Aggiungi Cliente altro OdC'
                        iconPosition='right'
                        onClick={() => {
                            dispatch(addCustomerOtherOdcDefault())
                            // dispatch(addCustomerOtherOdc())
                        }}
                        icon={<PlusOrange color='orange' size={20} />} />
                </div>
            </div>
            {complianceRequestState.complianceRequestsCreation.otherOdcCustomer.map((singleCustomer, index) => (
                <div className='pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                    <div style={{ flex: '0.475' }}>
                        <Input
                            // error={}
                            // supportingText={}
                            label={'Ragione Sociale'}
                            placeholder="Ragione Sociale"
                            value={complianceRequestState.complianceRequestsCreation.otherOdcCustomer[index].companyName}
                            defaultValue={complianceRequestState.complianceRequestsCreation.otherOdcCustomer[index].companyName}
                            onChangeText={(text) => { dispatch(setCompanyNameOtherOdcCreation({ data: text, index: index })) }}
                        />
                    </div>
                    <div style={{ flex: '0.475' }}>
                        <Input
                            label={'CUAA'}
                            placeholder="CUAA"
                            onChangeText={(text) => { dispatch(setCuaaOtherOdcCreation({ data: text, index: index })) }}
                            value={complianceRequestState.complianceRequestsCreation.otherOdcCustomer[index].cuaa}
                            defaultValue={complianceRequestState.complianceRequestsCreation.otherOdcCustomer[index].cuaa}
                        />
                    </div>
                    <div style={{ flex: '0.475' }}>
                        <Input

                            label={'Codice'}
                            placeholder="Codice"
                            onChangeText={(text) => { dispatch(setClientCodeOtherOdcCreation({ data: text, index: index })) }}
                            value={complianceRequestState.complianceRequestsCreation.otherOdcCustomer[index].clientCode}
                            defaultValue={complianceRequestState.complianceRequestsCreation.otherOdcCustomer[index].clientCode}
                        />
                    </div>
                    <div style={{ flex: '0.05', display: 'flex', alignSelf: 'flex-end', paddingBottom: 5 }}>
                        <Button
                            size='md'
                            iconPosition='only'
                            icon={<TrashIcon color='#f00' size={20} />}
                            variant='outline'
                            color='orange'
                            onClick={() => {
                                const newActivities = complianceRequestState.complianceRequestsCreation.otherOdcCustomer.filter((odc, idx) => odc != singleCustomer)
                                dispatch(setCreationCustomerOtherOdcDefault(newActivities))
                            }}
                        />
                    </div>

                </div>

            ))}

        </div>
    )
}

export function CustomersOtherOdCEdit() {
    const dispatch = useAppDispatch()
    const complianceRequestState = useAppSelector(state => state.complianceRequest)

    return (
        <div className="pb-5" style={{ borderBottomWidth: 1 }}>
            <div className='pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                <div className="text-left" style={{ flex: '0.5' }}>
                    <span className="subtitle">Clienti altro OdC</span>
                </div>
                <div style={{ flex: '0.5', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        size='sm'
                        color='orange'
                        variant='outline'
                        label='Aggiungi cliente altro OdC'
                        iconPosition='right'
                        onClick={() => {
                            dispatch(addCustomerOtherOdcDefaultEdit())
                        }}
                        icon={<PlusOrange color='orange' size={20} />} />
                </div>
            </div>
            {
                complianceRequestState.complianceRequestsUpdate.otherOdcCustomer?.map((singleCustomer, index) => (
                    <div className='pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                        <div style={{ flex: '0.475' }}>
                            <Input
                                // error={}
                                // supportingText={}
                                label={'Ragione Sociale'}
                                placeholder="Ragione Sociale"
                                value={complianceRequestState.complianceRequestsUpdate.otherOdcCustomer[index].companyName}
                                defaultValue={complianceRequestState.complianceRequestsUpdate.otherOdcCustomer[index].companyName}
                                onChangeText={(text) => { dispatch(setCompanyNameOtherOdcUpdate({ data: text, index: index })) }}
                            />
                        </div>
                        <div style={{ flex: '0.475' }}>
                            <Input
                                // error={}
                                // supportingText={}
                                label={'CUAA'}
                                placeholder="CUAA"
                                value={complianceRequestState.complianceRequestsUpdate.otherOdcCustomer[index].cuaa}
                                defaultValue={complianceRequestState.complianceRequestsUpdate.otherOdcCustomer[index].cuaa}
                                onChangeText={(text) => { dispatch(setCuaaOtherOdcUpdate({ data: text, index: index })) }}
                            />
                        </div>
                        <div style={{ flex: '0.475' }}>
                            <Input

                                label={'Codice'}
                                placeholder="Codice"
                                value={complianceRequestState.complianceRequestsUpdate.otherOdcCustomer[index].clientCode}
                                defaultValue={complianceRequestState.complianceRequestsUpdate.otherOdcCustomer[index].clientCode}
                                onChangeText={(text) => { dispatch(setClientCodeOtherOdcUpdate({ data: text, index: index })) }}
                            />
                        </div>
                        <div style={{ flex: '0.05', display: 'flex', alignSelf: 'flex-end', paddingBottom: 5 }}>
                            <Button
                                size='md'
                                iconPosition='only'
                                icon={<TrashIcon color='#f00' size={20} />}
                                variant='outline'
                                color='orange'
                                onClick={() => {
                                    const newActivities = complianceRequestState.complianceRequestsUpdate.otherOdcCustomer.filter((odc, idx) => odc != singleCustomer)
                                    dispatch(setEditCustomerOtherOdcDefault(newActivities))
                                }}
                            />
                        </div>
                    </div>
                ))
            }
        </div>
    )
}