import clsx from "clsx"
import { useNavigate } from "react-router-dom"
import { Layout } from "../../../ui/layout"
import { MenuItems } from "../../../ui/organisms/navbar/dto"
import Button from "../../../ui/molecules/button"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { Tab } from "react-tabs"
import { HorizontalTab } from "../../../ui/organisms/horizontalTab"
import { SelectCustom } from "../../../ui/molecules/select"
import { sot, yearOptions } from "../../../utils"
import Input from "../../../ui/molecules/input"
import { ReinforcedCustomerList } from "./customerList"
import { ReinforcedList } from "./list"
import { GetAllReinforcedControlMeasures, resetGetAllReinforcedControlMeasureFilters, resetReinforcedControlMeasureCreationStatus, resetReinforcedControlMeasureUpdateStatus, setGetAllReinforcedCountryRegion, setGetAllReinforcedHeadQuarter, setGetAllReinforcedYear } from "./slice"
import { SOTFilter } from "../../sot"

export function ReinforcedControlMeasure() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const sessionState = useAppSelector(state => state.session)
    const reinforcedControlMeasureState = useAppSelector(state => state.reinforcedControlMeasure)
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const [showSuccess, setShowSuccess] = useState<boolean>(false)

    useEffect(() => {
        if (reinforcedControlMeasureState.reinforcedControlMeasureCreationStatus === 'successfully') {
            dispatch(resetReinforcedControlMeasureCreationStatus())
            setShowSuccess(true)
        }
        if (reinforcedControlMeasureState.reinforcedControlMeasureUpdateStatus === 'successfully') {
            dispatch(resetReinforcedControlMeasureUpdateStatus())
            setShowSuccess(true)
        }
    }, [reinforcedControlMeasureState.reinforcedControlMeasureCreationStatus,
    reinforcedControlMeasureState.reinforcedControlMeasureUpdateStatus
    ])

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setShowSuccess(false)
            }, 2000)
        }
    }, [showSuccess])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Provvedimenti', 'Misura di Controllo Rinforzato']}
            noPaddingLat
            headerLabel="Misura di Controllo Rinforzato"
            headerChildren={
                <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>
                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Crea nuovo" color={"orange"} disabled={!sessionState.selected.customerId}
                        onClick={() => {
                            navigate('/addReinforcedControlMeasure')
                        }}
                    />
                </div>
            }
        >
            <HorizontalTab
                children={[
                    <>
                        <Tab
                            onClick={() => {
                                setSelectedTab(0)
                            }}
                            className={
                                clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                    "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": selectedTab === 0,
                                    "text-label-sm text-neutral-600": selectedTab !== 0
                                })
                            }
                            tabIndex={"0"}
                        >
                            Elenco cliente
                        </Tab>
                        <Tab
                            onClick={() => {
                                setSelectedTab(1)
                            }}
                            className={
                                clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                    "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": selectedTab === 1,
                                    "text-label-sm text-brandSecondary-600": selectedTab !== 1
                                }
                                )
                            }
                            tabIndex={"1"}
                        >
                            Elenco completo
                        </Tab>
                    </>
                ]}
            />

            {
                selectedTab !== 0 && (
                    <div style={{ display: 'flex', padding: '12px 24px 0px 24px' }}>
                        <div style={{ display: 'flex', flex: '0.90', gap: '24px' }}>
                            <div style={{ flex: '0.33' }}>
                                <div className="text-left">
                                    <span className="input-label">Anno</span>
                                </div>
                                <SelectCustom
                                    key={reinforcedControlMeasureState.getAllReinforcedControlMeasureFilters.year || 'default'}
                                    placeholder={'Anno'}
                                    value={reinforcedControlMeasureState.getAllReinforcedControlMeasureFilters.year}
                                    options={yearOptions}
                                    onChange={(value) => dispatch(setGetAllReinforcedYear(value))}
                                />
                            </div>
                            <div style={{ flex: '0.33' }}>
                                <div className="text-left">
                                    <span className="input-label">Stato</span>
                                </div>
                                <SOTFilter
                                    key={reinforcedControlMeasureState.getAllReinforcedControlMeasureFilters.headQuarter || 'default'}
                                    value={reinforcedControlMeasureState.getAllReinforcedControlMeasureFilters.headQuarter}
                                    // disabled={usersState.findMeResponse.headQuarterInfo?.code !== 'SC' && usersState.findMeResponse.role !== 'admin'}
                                    onChange={(value) => dispatch(setGetAllReinforcedHeadQuarter(value))}
                                />
                            </div>
                            <div style={{ flex: '0.33' }}>
                                <div className="text-left">
                                    <span className="input-label">Regione</span>
                                </div>
                                <Input
                                    placeholder={'Regione'}
                                    value={reinforcedControlMeasureState.getAllReinforcedControlMeasureFilters.countryRegion}
                                    onChangeText={(value) => dispatch(setGetAllReinforcedCountryRegion(value))}
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', gap: '18px', alignItems: 'flex-end' }}>
                            <Button
                                size={"lg"}
                                iconPosition={"off"}
                                variant={"link"}
                                label="Pulisci"
                                color={"gray"}
                                onClick={() => dispatch(resetGetAllReinforcedControlMeasureFilters())}
                            />
                            <Button
                                variant='outline'
                                color='blue'
                                size='md'
                                iconPosition='off'
                                label='Cerca'
                                onClick={() => dispatch(GetAllReinforcedControlMeasures(reinforcedControlMeasureState.getAllReinforcedControlMeasureFilters))}
                            />
                        </div>
                    </div>
                )
            }
            {
                showSuccess &&
                <div className="gap-[8px] text-left" style={{ margin: '24px', borderRadius: '12px', backgroundColor: '#D1F5D9', borderColor: '#8AE59F', borderWidth: 2 }}>
                    <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                        Dati salvati correttamente.
                    </span>
                </div>
            }

            <div className="fieldset bg-brandPrimary-200">
                {
                    selectedTab === 0 &&
                    <ReinforcedCustomerList />
                }
                {
                    selectedTab === 1 &&
                    <ReinforcedList />
                }
            </div>
        </Layout>
    )
}