import axios from "axios";
import { AppliedMeasureCreationDTO, AppliedMeasureDTO, AppliedMeasureUpdateDTO, GetAllAppliedMeasureDTO, GetAllAppliedMeasureFiltersDTO } from "./dto";
import { AppliedMeasureService } from "./service";
import { keycloak } from "../../../../../../lib/keycloak";

export class AppliedMeasureServiceImpl implements AppliedMeasureService {

    public CreateAppliedMeasure(data: AppliedMeasureCreationDTO): Promise<void> {
        return axios.post("/api/applied-measure",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateAppliedMeasure(data: AppliedMeasureUpdateDTO, id: string): Promise<void> {
        return axios.put("/api/applied-measure/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteAppliedMeasure(id: string): Promise<void> {
        return axios.delete("/api/applied-measure/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllAppliedMeasure(filters: GetAllAppliedMeasureFiltersDTO): Promise<GetAllAppliedMeasureDTO> {
        return axios.get("/api/applied-measure", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order,
                newMeasureId: filters.newMeasureId
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetAppliedMeasureById(id: string): Promise<AppliedMeasureDTO> {
        return axios.get("/api/applied-measure/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}