import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../lib/redux/hooks'
import { ProtocolSelectByObject } from '../../../../ui/molecules/ProtocolSelectByObj'
import { CustomRadio } from '../../../../ui/molecules/radioButton'
import { SelectCustom } from '../../../../ui/molecules/select'
import '../styles.scss'
import { Calendar } from '../../../../ui/molecules/calendar'
import TextArea from '../../../../ui/molecules/textArea'
import Button from '../../../../ui/molecules/button'
import { ProtocolSelectFromDate } from '../../../../ui/molecules/ProtocolSelectFromDate'
import { BusinessRegisterRequestMotivationOptions, BusinessRegisterTypologyRequestOptions } from '../../../../utils'
import {  DownloadRegisterSubmissionDocx, GetAllBusinessRegisters,resetGetAllBusinessRegistersStatus, setBusinessRegisterCreationNote, setBusinessRegisterCreationReleaseDate, setBusinessRegisterCreationReleaseProtocolId, setBusinessRegisterCreationReleaseRegister, setBusinessRegisterCreationRequestProtocolId, setBusinessRegisterCreationRequestReason, setBusinessRegisterCreationRequestType, setBusinessRegisterCreationCustomerId, setBusinessRegisterUpdateNote, setBusinessRegisterUpdateReleaseDate, setBusinessRegisterUpdateReleaseProtocolId, setBusinessRegisterUpdateRequestProtocolId, setBusinessRegisterUpdateRequestReason, setBusinessRegisterUpdateRequestType, setLatestAvailableDate } from '../slice'
import { BusinessRegisterReleaseRegister } from '../dataService/dto'

export function AddRegistroForms() {
    const dispatch = useAppDispatch()
    const userState = useAppSelector(state => state.users)
    const registerState = useAppSelector(state => state.register)
    const sessionState = useAppSelector(state => state.session)
    const [closeFlag, setCloseFlag] = useState<string>('IDLE')

    useEffect(() => {
        dispatch(GetAllBusinessRegisters({ itemsPerPage: 1, page: 0, order: false, headQuarter: userState.findMeResponse.headQuarterInfo?.code }))
    }, [])

    useEffect(() => {
        if (registerState.getAllBusinessRegistersStatus === 'successfully' &&
            registerState.getAllBusinessRegistersResponse !== undefined
        ) {
            dispatch(resetGetAllBusinessRegistersStatus())
            if (registerState.getAllBusinessRegistersResponse.data.length > 0) {
                const latestDate: Date = new Date(registerState.getAllBusinessRegistersResponse.data[0].releaseDate)
                dispatch(setLatestAvailableDate(latestDate.setDate(latestDate.getDate() + 1)))
            }
            else {
                dispatch(setLatestAvailableDate(undefined))
            }

        }
    }, [registerState.getAllBusinessRegistersStatus])

    return (
        <div>

            {/* HEADER */}
            <div className="register-header">
                <span className='title'>Nuovo registro aziendale</span>

            </div>

            {/* CONTENT */}
            <div className='register-content'>
                <div className='text-left mb-3'>
                    <span className='rid-title' style={{ color: '#4D797F' }}>Registro aziendale </span>
                    <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                    <span className='rid-title' style={{ color: '#314D51' }}> Dati richiesta RE002</span>
                </div>

                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Motivo richiesta</span>
                        </div>
                        <SelectCustom
                            error={registerState.businessRegisterErrors.requestReason}
                            errorLabel='Motivazione mancante'
                            placeholder={'Seleziona motivazione...'}
                            options={BusinessRegisterRequestMotivationOptions}
                            onChange={(value) => !registerState.fromCreationToUpdate ?
                                dispatch(setBusinessRegisterCreationRequestReason(value)) :
                                dispatch(setBusinessRegisterUpdateRequestReason(value))
                            }
                        />
                    </div>

                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Protocollo Richiesta [RRA]</span>
                        </div>
                        <ProtocolSelectByObject
                            error={registerState.businessRegisterErrors.requestProtocolId}
                            errorLabel='Protocollo mancante'
                            type='entry'
                            objectId='88'
                            customerId={sessionState.selected.customerId!}
                            placeholder='Seleziona protocollo...'
                            onChange={(value) => !registerState.fromCreationToUpdate ?
                                dispatch(setBusinessRegisterCreationRequestProtocolId(value)) :
                                dispatch(setBusinessRegisterUpdateRequestProtocolId(value))}
                        />
                    </div>
                </div>

                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center', borderBottomWidth: 1 }}>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Tipo di richiesta</span>
                        </div>
                        <SelectCustom
                            error={registerState.businessRegisterErrors.requestType}
                            errorLabel='Tipologia mancante'
                            placeholder={'Seleziona tipologia...'}
                            options={BusinessRegisterTypologyRequestOptions}
                            onChange={(value) => !registerState.fromCreationToUpdate ?
                                dispatch(setBusinessRegisterCreationRequestType(value)) :
                                dispatch(setBusinessRegisterUpdateRequestType(value))}
                        />
                    </div>

                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Notifica</span>
                        </div>
                        <SelectCustom
                            // error={}
                            // errorLabel='Tipologia mancante'
                            placeholder={'Seleziona tipologia...'}
                            options={[{ value: '1', label: 'Tipo 1' }]}
                            onChange={(value) => { }}
                        />
                    </div>
                </div>

                <div className='text-left mt-3 mb-3'>
                    <span className='rid-title' style={{ color: '#4D797F' }}>Registro aziendale </span>
                    <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                    <span className='rid-title' style={{ color: '#314D51' }}> Rilascio registro</span>
                </div>

                {
                    registerState.businessRegisterErrors.releaseMutualExclusion && (
                        <div className="gap-[8px] text-left bg-red-300" style={{ margin: '12px', borderRadius: '8px' }}>
                            <span className="text-red-700 fieldsetTitle" style={{ padding: '15px 12px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                                Non è presente né Protocollo di rilascio né Data di Rilascio dal tecnico ispettore
                            </span>
                        </div>
                    )
                }

                <div className='pb-5' style={{ borderBottomWidth: 1 }}>
                    <div className='mb-5' style={{ display: 'flex', gap: '8px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CustomRadio
                                options={[{ label: '', value: 'CLOSE1' }]}
                                selectedValue={closeFlag}
                                onChange={(value) => {
                                    setCloseFlag(value)
                                    dispatch(setBusinessRegisterCreationReleaseRegister(BusinessRegisterReleaseRegister.PROTOCOLLO_RILASCIO))
                                    !registerState.fromCreationToUpdate ?
                                        dispatch(setBusinessRegisterCreationReleaseDate(undefined)) :
                                        dispatch(setBusinessRegisterUpdateReleaseDate(undefined))
                                }}
                                id={'close1'}
                                name={'close1'}
                            />
                        </div>
                        <div style={{ flex: '1' }}>
                            <div className='text-left'>
                                <span className='tit-1-status'>Protocollo di rilascio</span>
                            </div>
                            <div>
                                <ProtocolSelectFromDate
                                    type='departure'
                                    disabled={closeFlag !== 'CLOSE1'}
                                    fromDate={registerState.latestAvailableDate} // DA CAMBIARE CON ULTIMA DATA + 1 NEL LISTING
                                    customerId={sessionState.selected.customerId!}
                                    placeholder='Scegli protocollo di rilascio...'
                                    onChange={(value) => !registerState.fromCreationToUpdate ?
                                        dispatch(setBusinessRegisterCreationReleaseProtocolId(value)) :
                                        dispatch(setBusinessRegisterUpdateReleaseProtocolId(value))}
                                />
                            </div>
                        </div>
                    </div>

                    <div style={{ display: 'flex', gap: '8px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CustomRadio
                                options={[{ label: '', value: 'CLOSE2' }]}
                                selectedValue={closeFlag}
                                onChange={(value) => {
                                    setCloseFlag(value)
                                    dispatch(setBusinessRegisterCreationReleaseRegister(BusinessRegisterReleaseRegister.CONSEGNATI_DAL_TECNICO_ISPETTORE))
                                    !registerState.fromCreationToUpdate ?
                                        dispatch(setBusinessRegisterCreationReleaseProtocolId(undefined)) :
                                        dispatch(setBusinessRegisterUpdateReleaseProtocolId(undefined))
                                }}
                                id={'close2'}
                                name={'close2'}
                            />
                        </div>
                        <div style={{ flex: '1' }}>
                            <div className='text-left'>
                                <span className='tit-1-status'>Consegnati dal tecnico ispettore nel corso della visita ispettiva </span>
                                <span className='tit-1-status'> / Data </span>
                            </div>
                            <div>
                                <Calendar
                                    selected={!registerState.fromCreationToUpdate ?
                                        registerState.businessRegisterCreationRequest.releaseDate :
                                        registerState.businessRegisterUpdateRequest.releaseDate
                                    }
                                    onChange={(date) => !registerState.fromCreationToUpdate ?
                                        dispatch(setBusinessRegisterCreationReleaseDate(date)) :
                                        dispatch(setBusinessRegisterUpdateReleaseDate(date))}
                                    minDate={registerState.latestAvailableDate}
                                    disabled={closeFlag !== 'CLOSE2'}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='pt-5'>
                    <TextArea
                        onChangeText={(text) => !registerState.fromCreationToUpdate ?
                            dispatch(setBusinessRegisterCreationNote(text)) :
                            dispatch(setBusinessRegisterUpdateNote(text))}
                        label="Note"
                    />
                </div>
                {
                    registerState.fromCreationToUpdate &&
                    <div className='mt-12'>
                        {
                            registerState.businessRegisterCreationResponse && 
                             <div style={{ display: "flex", gap: "24px" }}>
                            <Button
                                size="sm"
                                iconPosition="off"
                                variant="solid"
                                label="Crea Word fase di avvio"
                                color="lightGreen"
                                onClick={() => {
                                    if (registerState.businessRegisterCreationResponse) {
                                        dispatch(DownloadRegisterSubmissionDocx({ id: registerState.businessRegisterCreationResponse, infoType: 'LETTERA_CLIENTE' }))
                                    }
                                }}
                            />
                            <Button
                                size="sm"
                                iconPosition="off"
                                variant="solid"
                                label="Crea Word fase inserimento nuova attività"
                                color="lightGreen"
                                onClick={() => {
                                    if (registerState.businessRegisterCreationResponse) {
                                        dispatch(DownloadRegisterSubmissionDocx({ id: registerState.businessRegisterCreationResponse, infoType: 'LETTERA_INSERIMENTO_ATTIVITA' }))
                                    }
                                }}
                            />
                            <Button
                                size="sm"
                                iconPosition="off"
                                variant="solid"
                                label="Crea Word Smarriti"
                                color="lightGreen"
                                onClick={() => {
                                    if (registerState.businessRegisterCreationResponse) {
                                        dispatch(DownloadRegisterSubmissionDocx({ id: registerState.businessRegisterCreationResponse, infoType: 'LETTERA_REGISTRI_SMARRITI' }))
                                    }
                                }}
                            />
                            <Button
                                size="sm"
                                iconPosition="off"
                                variant="solid"
                                label="Crea Word Esauriti"
                                color="lightGreen"
                                onClick={() => {
                                    if (registerState.businessRegisterCreationResponse) {
                                        dispatch(DownloadRegisterSubmissionDocx({ id: registerState.businessRegisterCreationResponse, infoType: 'LETTERA_REGISTRI_ESAURITI' }))
                                    }
                                }}
                            />
                        </div>
                        }
                       
                    </div>
                }


            </div>
        </div>
    )
}