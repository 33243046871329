import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "../../../../../ui/icons/arrow-left";
import { Layout } from "../../../../../ui/layout";
import Button from "../../../../../ui/molecules/button";
import { MenuItems } from "../../../../../ui/organisms/navbar/dto";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { AddNewCodexMeasureStep2Forms } from "./forms";
import { AddNewCodexMeasureStep2Right } from "./right";
import { NewMeasureUpdate, resetNewMeasureUpdateStatus, setNewMeasureUpdateStatus } from "../../slice";
import { useEffect, useRef, useState } from "react";
import { MeasureStatusEnum } from "../../dataService/dto";

export function AddNewCodexMeasureStep2() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const sessionState = useAppSelector(state => state.session)
    const newMeasureState = useAppSelector(state => state.newMeasure)
    const [showSuccess, setShowSuccess] = useState<boolean>(false)

    useEffect(() => {
        if (newMeasureState.newMeasureUpdateStatus === 'successfully') {
            dispatch(resetNewMeasureUpdateStatus())
            setShowSuccess(true)
        }
    }, [newMeasureState.newMeasureUpdateStatus])

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setShowSuccess(false)
                navigate('/provvedimentiCliente')
            }, 2000)
        }
    }, [showSuccess])


    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Gestione Provvedimenti', 'Inserimento Nuovo Provvedimento Cliente']}
            headerLabel={`Nuovo Provvedimento`}
            customerName={sessionState.selected.customerName ? sessionState.selected.customerName : undefined}
            customerCode={sessionState.selected.customerCode ? sessionState.selected.customerCode : undefined}
            rightBar={true}
            headerIcon={
                <button style={{ paddingTop: '12px' }} onClick={() => navigate('/provvedimentiCliente')}>
                    <ArrowLeftIcon />
                </button>
            }
            headerChildren={
                <div className="text-left flex flex-row justify-end" style={{ padding: '16px', justifyContent: 'end', gap: '24px' }}>

                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                        onClick={() => {
                            dispatch(NewMeasureUpdate({ body: newMeasureState.newMeasureUpdateRequest, id: newMeasureState.newMeasureIdToUpdate }))
                        }}
                    />

                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva e Chiudi" color={"red"}
                        onClick={() => {
                            let tempUpdate = {
                                ...newMeasureState.newMeasureUpdateRequest,
                                status: MeasureStatusEnum.CHIUSO
                            }
                            dispatch(NewMeasureUpdate({ body: tempUpdate, id: newMeasureState.newMeasureIdToUpdate }))
                        }}
                    />
                </div>
            }
            onScrollTop={newMeasureState.newMeasureUpdateStatus === 'successfully'}
        >
            <div style={{ display: 'flex', minHeight: window.innerHeight, position: 'relative' }}>
                <div style={{ flex: '0.65' }}>
                    {
                        showSuccess &&
                        <div className="gap-[8px] text-left" style={{ margin: '24px', borderRadius: '12px', backgroundColor: '#D1F5D9', borderColor: '#8AE59F', borderWidth: 2 }}>
                            <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                                Dati salvati correttamente.
                            </span>
                        </div>
                    }
                    <AddNewCodexMeasureStep2Forms />
                </div>
                <div className="bg-brandPrimary-100" style={{ flex: '0.35' }}>
                    <AddNewCodexMeasureStep2Right />
                </div>
            </div>
        </Layout>
    )
}