import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { InfoBox } from "../../../ui/molecules/infoBox"
import { formatDecimalTime, optionsFormatter, OptionType, ServiceTypeUnitOptions } from "../../../utils"
import { AdditionalExpenseCreation, AdditionalExpenseDelete, AdditionalExpenseValidation, GetAllAdditionalExpense, GetAllCustomerBillingWorksheet, GetAllService, GetAllServiceType, resetAECreationRequest, resetAECreationStatus, resetAEDeleteStatus, resetAEValidationStatus, resetGetAllSTStatus, resetServiceCreationRequest, resetServiceCreationStatus, resetServiceDeleteStatus, resetServiceValidationStatus, ServiceCreation, ServiceDelete, ServiceValidation, setAECreationOfficerId, setAECreationServiceTypeId, setAECreationTaxableFee, setServiceCreationDate, setServiceCreationOfficerId, setServiceCreationServiceTypeId, setServiceCreationUnitEmployed } from "../slice"
import Button from "../../../ui/molecules/button"
import { RoundedPlusIcon } from "../../../ui/icons/RoundedPlusIcon"
import { CustomerBillingWorksheetDTO, ServiceTypeCategoryEnum } from "../dataService/dto"
import { SelectCustom } from "../../../ui/molecules/select"
import Input from "../../../ui/molecules/input"
import { TableSelect } from "../../../ui/molecules/tableSelect"
import { MinusIcon } from "../../../ui/icons/minusIcon"
import { RoundedMinusIcon } from "../../../ui/icons/RoundedMinusIcon"
import { Alert } from "../../../ui/organisms/alert"
import { HoursMinutesBox } from "../components/hoursMinutes"

interface Props {
    close: () => void
}

export function SchedaCalcoloListEditPopup(props: Props) {
    const dispatch = useAppDispatch()
    const serviceAndBillingState = useAppSelector(state => state.billingAndServiceType)
    const sessionState = useAppSelector(state => state.session)
    const userState = useAppSelector(state => state.users)
    const [serviceAddFlag, setServiceAddFlag] = useState<boolean>(false)
    const [additionalAddFlag, setAdditionalAddFlag] = useState<boolean>(false)
    const [serviceST, setServiceST] = useState<OptionType[]>([])
    const [additionalST, setAdditionalST] = useState<OptionType[]>([])
    const [serviceToDel, setServiceToDel] = useState<string | null>(null)
    const [additionalToDel, setAdditionalToDel] = useState<string | null>(null)
    const [showServiceAlert, setShowServiceAlert] = useState<boolean>(false)
    const [showAdditionalAlert, setShowAdditionalAlert] = useState<boolean>(false)

    const hoursMinutesPredicate = (): boolean => {
        return serviceAndBillingState.serviceCreationRequest.serviceTypeId !== undefined &&
            serviceST.filter(st => st.value === serviceAndBillingState.serviceCreationRequest.serviceTypeId)
                .map(st => st.label.split(' - ')[1])[0] === 'Ore/Minuti'
    }

    useEffect(() => {
        dispatch(setServiceCreationDate(new Date()))
        dispatch(setServiceCreationOfficerId(userState.findMeResponse.id))

        dispatch(setAECreationOfficerId(userState.findMeResponse.id))

        dispatch(GetAllService({
            itemsPerPage: 0,
            page: 0,
            customerBillingWorksheetId: serviceAndBillingState.getAllServiceFilters.customerBillingWorksheetId
        }))

        dispatch(GetAllAdditionalExpense({
            itemsPerPage: 0,
            page: 0,
            customerBillingWorksheetId: serviceAndBillingState.getAllAdditionalExpenseFilters.customerBillingWorksheetId
        }))

        dispatch(GetAllServiceType({
            itemsPerPage: 0,
            page: 0,
            billingWorksheetId: serviceAndBillingState.getAllServiceTypeFilters.billingWorksheetId
        }))
    }, [])

    useEffect(() => {
        if (serviceAndBillingState.getAllServiceTypeStatus === 'successfully' &&
            serviceAndBillingState.getAllServiceTypeResponse !== undefined
        ) {
            dispatch(resetGetAllSTStatus())

            const serviceSTTemp = serviceAndBillingState.getAllServiceTypeResponse.data
                .filter(st => st.category === ServiceTypeCategoryEnum.SERVICE)
                .map(st => {
                    return {
                        value: st.id,
                        label: `[${st.code}] - ${optionsFormatter(st.unitType, ServiceTypeUnitOptions)}`
                    } as OptionType
                })

            setServiceST(serviceSTTemp)

            const additionalSTTemp = serviceAndBillingState.getAllServiceTypeResponse.data
                .filter(st => st.category === ServiceTypeCategoryEnum.ADDITIONAL_EXPENSE)
                .map(st => {
                    return {
                        value: st.id,
                        label: `[${st.code}]`
                    } as OptionType
                })

            setAdditionalST(additionalSTTemp)

        }
    }, [serviceAndBillingState.getAllServiceTypeStatus])

    useEffect(() => {
        if (serviceAndBillingState.serviceValidationStatus === 'successfully') {
            dispatch(resetServiceValidationStatus())
            dispatch(ServiceCreation(serviceAndBillingState.serviceCreationRequest))
        }
    }, [serviceAndBillingState.serviceValidationStatus])

    useEffect(() => {
        if (serviceAndBillingState.serviceCreationStatus === 'successfully') {
            dispatch(resetServiceCreationStatus())
            setServiceAddFlag(false)
            dispatch(setServiceCreationServiceTypeId(undefined))
            dispatch(setServiceCreationUnitEmployed(undefined))

            dispatch(GetAllService({
                itemsPerPage: 0,
                page: 0,
                customerBillingWorksheetId: serviceAndBillingState.getAllServiceFilters.customerBillingWorksheetId
            }))

            dispatch(GetAllCustomerBillingWorksheet({
                itemsPerPage: 0,
                page: 0,
                customerId: sessionState.selected.customerId!
            }))
        }
    }, [serviceAndBillingState.serviceCreationStatus])

    useEffect(() => {
        if (serviceAndBillingState.additionalExpenseValidationStatus === 'successfully') {
            dispatch(resetAEValidationStatus())
            dispatch(AdditionalExpenseCreation(serviceAndBillingState.additionalExpenseCreationRequest))
        }
    }, [serviceAndBillingState.additionalExpenseValidationStatus])

    useEffect(() => {
        if (serviceAndBillingState.additionalExpenseCreationStatus === 'successfully') {
            dispatch(resetAECreationStatus())
            setAdditionalAddFlag(false)
            dispatch(setAECreationServiceTypeId(undefined))
            dispatch(setAECreationTaxableFee(undefined))

            dispatch(GetAllAdditionalExpense({
                itemsPerPage: 0,
                page: 0,
                customerBillingWorksheetId: serviceAndBillingState.getAllAdditionalExpenseFilters.customerBillingWorksheetId
            }))

            dispatch(GetAllCustomerBillingWorksheet({
                itemsPerPage: 0,
                page: 0,
                customerId: sessionState.selected.customerId!
            }))
        }
    }, [serviceAndBillingState.additionalExpenseCreationStatus])

    useEffect(() => {
        if (serviceAndBillingState.serviceDeleteStatus === 'successfully') {
            dispatch(resetServiceDeleteStatus())
            setServiceToDel(null)
            dispatch(GetAllService({
                itemsPerPage: 0,
                page: 0,
                customerBillingWorksheetId: serviceAndBillingState.getAllServiceFilters.customerBillingWorksheetId
            }))

            dispatch(GetAllCustomerBillingWorksheet({
                itemsPerPage: 0,
                page: 0,
                customerId: sessionState.selected.customerId!
            }))
        }
    }, [serviceAndBillingState.serviceDeleteStatus])

    useEffect(() => {
        if (serviceAndBillingState.additionalExpenseDeleteStatus === 'successfully') {
            dispatch(resetAEDeleteStatus())
            setAdditionalToDel(null)
            dispatch(GetAllAdditionalExpense({
                itemsPerPage: 0,
                page: 0,
                customerBillingWorksheetId: serviceAndBillingState.getAllAdditionalExpenseFilters.customerBillingWorksheetId
            }))

            dispatch(GetAllCustomerBillingWorksheet({
                itemsPerPage: 0,
                page: 0,
                customerId: sessionState.selected.customerId!
            }))
        }
    }, [serviceAndBillingState.additionalExpenseDeleteStatus])

    return (
        <div>
            <div style={{ padding: '24px 24px 18px 24px' }}>
                <div className='text-left pb-3'>
                    <span className='rid-title' style={{ color: '#74A4A9' }}>Elenco Servizi </span>
                </div>

                <div className="pb-5">
                    <TableSelect
                        type='radio'
                        onChange={(value) => setServiceToDel(value[0])}
                        headers={['Codice', 'Descrizione', 'Tipo Unità', 'Quantità', 'Imponibile', 'Iva', 'Importo']}
                        data={serviceAndBillingState.getAllServiceResponse?.data.map(s => {
                            return {
                                value: s.id,
                                labels: [`[${s.serviceTypeCode}]`,
                                s.serviceTypeDescription,
                                optionsFormatter(s.unitType, ServiceTypeUnitOptions) || '',
                                String(s.unitType === 'HOURS' ? formatDecimalTime(s.unitEmployed) : s.unitEmployed),
                                "€" + String(s.taxableFee),
                                "€" + String(s.iva),
                                "€" + String(s.totalFee)
                                ]
                            }
                        }) || []}
                    />
                </div>

                {
                    !serviceAddFlag &&
                    <div className="pb-5" style={{ display: 'flex', justifyContent: 'flex-end', gap: '24px' }}>
                        <Button
                            variant="outline"
                            color="dimgrey"
                            size="md"
                            iconPosition="left"
                            icon={<RoundedPlusIcon />}
                            label="Nuovo Servizio"
                            onClick={() => setServiceAddFlag(true)}
                        />

                        <Button
                            disabled={serviceToDel === null}
                            variant="outline"
                            color="red"
                            size="md"
                            iconPosition="left"
                            icon={<RoundedMinusIcon color="rgba(255, 68, 61, 1)" />}
                            label="Rimuovi Servizio"
                            onClick={() => {
                                setShowServiceAlert(true)
                            }}
                        />
                    </div>
                }

                {
                    serviceAddFlag &&
                    <div className="pb-5" style={{ display: 'flex', gap: '24px' }}>
                        <div style={{ flex: '0.4' }}>
                            <div className="text-left">
                                <span className="input-label">Tipo di Servizio</span>
                            </div>
                            <SelectCustom
                                placeholder='Specifica tipo di servizio...'
                                errorLabel="Tipo di servizio mancante"
                                error={serviceAndBillingState.serviceErrors.serviceTypeId}
                                options={serviceST}
                                value={serviceAndBillingState.serviceCreationRequest.serviceTypeId}
                                onChange={(value) => dispatch(setServiceCreationServiceTypeId(value))}
                            />
                        </div>
                        <div style={{ flex: '0.4' }}>
                            {
                                hoursMinutesPredicate() &&
                                (
                                    <div>
                                        <HoursMinutesBox onChange={(value) => dispatch(setServiceCreationUnitEmployed(value))} />
                                    </div>
                                )
                            }
                            {
                                !hoursMinutesPredicate() &&
                                <div>
                                    <div className="text-left pt-1 pb-1">
                                        <span className="input-label">Unità impiegate</span>
                                    </div>
                                    <Input
                                        placeholder='Specifica unità impiegate...'
                                        supportingText={serviceAndBillingState.serviceErrors.unitEmployed ? 'Unità mancanti' : undefined}
                                        error={serviceAndBillingState.serviceErrors.unitEmployed}
                                        value={serviceAndBillingState.serviceCreationRequest.unitEmployed}
                                        onChangeText={(text) => dispatch(setServiceCreationUnitEmployed(text))}
                                    />
                                </div>
                            }
                        </div>
                        <div className="pb-1" style={{ display: 'flex', gap: '24px', flex: '0.2', alignItems: 'flex-end' }}>
                            <Button
                                variant="outline"
                                color="dimgrey"
                                size="md"
                                iconPosition="off"
                                label="Annulla"
                                onClick={() => {
                                    dispatch(setServiceCreationServiceTypeId(undefined))
                                    dispatch(setServiceCreationUnitEmployed(undefined))
                                    setServiceAddFlag(false)
                                }}
                            />

                            <Button
                                variant="solid"
                                color="dimgrey"
                                size="md"
                                iconPosition="left"
                                icon={<RoundedPlusIcon />}
                                label="Salva"
                                onClick={() => dispatch(ServiceValidation(serviceAndBillingState.serviceCreationRequest))}
                            />
                        </div>
                    </div>
                }

                <div className="pt-5 mb-5" style={{ borderBottom: '1px solid #74A4A9' }} />

                <div className='text-left pb-3'>
                    <span className='rid-title' style={{ color: '#BA6520' }}>Elenco Spese Aggiuntive </span>
                </div>

                <div className="pb-5">
                    <TableSelect
                        type='radio'
                        color="orange"
                        onChange={(value) => setAdditionalToDel(value[0])}
                        headers={['Codice', 'Descrizione', 'Imponibile', 'Iva', 'Importo']}
                        data={serviceAndBillingState.getAllAdditionalExpenseResponse?.data.map(s => {
                            return {
                                value: s.id,
                                labels: [`[${s.serviceTypeCode}]`,
                                s.serviceTypeDescription,
                                "€" + String(s.taxableFee),
                                "€" + String(s.iva),
                                "€" + String(s.totalFee)
                                ]
                            }
                        }) || []}
                    />
                </div>

                {
                    !additionalAddFlag &&
                    <div className="pb-5" style={{ display: 'flex', justifyContent: 'flex-end', gap: '24px' }}>
                        <Button
                            variant="outline"
                            color="orange"
                            size="md"
                            iconPosition="left"
                            icon={<RoundedPlusIcon />}
                            label="Nuova Spesa Addizionale"
                            onClick={() => setAdditionalAddFlag(true)}
                        />

                        <Button
                            disabled={additionalToDel === null}
                            variant="outline"
                            color="red"
                            size="md"
                            iconPosition="left"
                            icon={<RoundedMinusIcon color="rgba(255, 68, 61, 1)" />}
                            label="Rimuovi Spesa"
                            onClick={() => {
                                setShowAdditionalAlert(true)
                            }}
                        />
                    </div>
                }

                {
                    additionalAddFlag &&
                    <div className="pb-5" style={{ display: 'flex', gap: '24px' }}>
                        <div style={{ flex: '0.4' }}>
                            <div className="text-left">
                                <span className="input-label">Tipo di Servizio</span>
                            </div>
                            <SelectCustom
                                placeholder='Specifica tipo di servizio...'
                                errorLabel="Tipo di servizio mancante"
                                error={serviceAndBillingState.additionalErrors.serviceTypeId}
                                options={additionalST}
                                value={serviceAndBillingState.additionalExpenseCreationRequest.serviceTypeId}
                                onChange={(value) => dispatch(setAECreationServiceTypeId(value))}
                            />
                        </div>
                        <div style={{ flex: '0.4' }}>
                            <div className="text-left pt-1 pb-1">
                                <span className="input-label">Totale</span>
                            </div>
                            <Input
                                placeholder='Specifica totale...'
                                supportingText={serviceAndBillingState.additionalErrors.taxableFee ? 'Totale mancante' : undefined}
                                error={serviceAndBillingState.additionalErrors.taxableFee}
                                value={serviceAndBillingState.additionalExpenseCreationRequest.taxableFee}
                                onChangeText={(text) => dispatch(setAECreationTaxableFee(text))}
                            />
                        </div>
                        <div className="pb-1" style={{ display: 'flex', gap: '24px', flex: '0.2', alignItems: 'flex-end' }}>
                            <Button
                                variant="outline"
                                color="orange"
                                size="md"
                                iconPosition="off"
                                label="Annulla"
                                onClick={() => {
                                    dispatch(setAECreationServiceTypeId(undefined))
                                    dispatch(setAECreationTaxableFee(undefined))
                                    setAdditionalAddFlag(false)
                                }}
                            />

                            <Button
                                variant="solid"
                                color="orange"
                                size="md"
                                iconPosition="left"
                                icon={<RoundedPlusIcon />}
                                label="Salva"
                                onClick={() => dispatch(AdditionalExpenseValidation(serviceAndBillingState.additionalExpenseCreationRequest))}
                            />
                        </div>
                    </div>
                }

            </div>

            {
                (showServiceAlert && serviceToDel) && (
                    <Alert title="Conferma operazione" close={() => setShowServiceAlert(false)}>
                        <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
                            <div className="pb-3">
                                <div>
                                    Sei sicuro di voler eliminare il servizio?
                                </div>
                            </div>
                            <div className="mt-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    variant="outline"
                                    color="dimgrey"
                                    iconPosition="off"
                                    label="Annulla"
                                    size="md"
                                    onClick={() => setShowServiceAlert(false)}
                                />
                                <div className="pl-8">
                                    <Button
                                        variant="solid"
                                        color="orange"
                                        iconPosition="off"
                                        label="Elimina"
                                        size="md"
                                        onClick={() => {
                                            dispatch(ServiceDelete(serviceToDel))
                                            setShowServiceAlert(false)
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                    </Alert>
                )
            }

            {
                (showAdditionalAlert && additionalToDel) && (
                    <Alert title="Conferma operazione" close={() => setShowAdditionalAlert(false)}>
                        <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
                            <div className="pb-3">
                                <div>
                                    Sei sicuro di voler eliminare la spesa aggiuntiva?
                                </div>
                            </div>
                            <div className="mt-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    variant="outline"
                                    color="dimgrey"
                                    iconPosition="off"
                                    label="Annulla"
                                    size="md"
                                    onClick={() => setShowAdditionalAlert(false)}
                                />
                                <div className="pl-8">
                                    <Button
                                        variant="solid"
                                        color="orange"
                                        iconPosition="off"
                                        label="Elimina"
                                        size="md"
                                        onClick={() => {
                                            dispatch(AdditionalExpenseDelete(additionalToDel))
                                            setShowAdditionalAlert(false)
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                    </Alert>
                )
            }
        </div >
    )
}