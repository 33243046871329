import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import Button from "../../../../ui/molecules/button";
import { countries, DestinationOfficesType, entryMeansOptions, OptionType, regioniList } from "../../../../utils";
import { EntryProtocolUpdate, EntryProtocolUploadAttachmentFile, EntryProtocolValidation, GetAllEntryProtocolObjects, GetEntryProtocolById, resetEntryProtocolErrors, resetEntryProtocolUpdateStatus, resetEntryProtocolUploadAttachmentFileStatus, resetEntryProtocolValidationStatus, resetEPObjCreationRequest, resetGetAllEntryProtocolObjectsStatus, setEPFileAndAtt, setFileToUpdate, setUpdatedEPFileAndAtt, setUpdatedFile, setUpdateEPCustomerIds, setUpdateEPCustomersNotRegistered, setUpdateEPDestinationOffice, setUpdateEPEntryChannel, setUpdateEPEntryObjectId, setUpdateEPLinkExistingProtocol, setUpdateEPNation, setUpdateEPObjectSpec, setUpdateEPRegion, setUpdateEPSend, setUpdateEPSender, setUpdateEPTotalPage, setUpdateEPUsersVisibility } from "../slice";
import { SelectCustom } from "../../../../ui/molecules/select";
import { Calendar } from "../../../../ui/molecules/calendar";
import Input from "../../../../ui/molecules/input";
import InputMultiSearch from "../../../../ui/molecules/input/InputMultiSearch";
import TextArea from "../../../../ui/molecules/textArea";
import { SingleCheckbox } from "../../../../ui/molecules/SingleCheckbox";
import CheckboxGroup from "../../../../ui/molecules/CheckBoxGroup";
import { PopupForm } from "../../../../ui/organisms/popupForm";
import { AddObjectPopupEntry } from "../../popup/addObjectEntry";
import { GetFileObjectById, resetGetFileObjectByIdStatus } from "../../../../objectStorage/slice";
import { ProtocolSearch } from "../../../../ui/molecules/ProtocolSearch";
import { resetfindMeStatusIdle } from "../../../users/slice";

export function EditEntryLeft() {
    const dispatch = useAppDispatch()
    const entryProtocolState = useAppSelector(state => state.entryProtocol)
    const objectStorageState = useAppSelector(state => state.objectStorage)
    const userState = useAppSelector(state => state.users)
    const [viewable, setViewable] = useState<boolean>(false)
    const [selectedOffices, setSelectedOffices] = useState<DestinationOfficesType[]>([])
    const [objects, setObjects] = useState<OptionType[]>([])
    const [showPopup, setShowPopup] = useState<boolean>(false)
    const [file, setFile] = useState<File | null>(null)
    const [officeSot, setOfficeSot] = useState<OptionType[]>([])


    let formData = new FormData();
    let attachmentFormData = new FormData();

    const handleTypesChange = (value: DestinationOfficesType) => {
        let currentOffices = [...selectedOffices]
        if (currentOffices.includes(value)) {
            currentOffices = currentOffices.filter(t => t !== value)
        }
        else {
            currentOffices.push(value)
        }
        setSelectedOffices(currentOffices)

        dispatch(setUpdateEPDestinationOffice(currentOffices))
    }

    useEffect(() => {
        if (entryProtocolState.getEntryProtocolByIdStatus === 'successfully' &&
            entryProtocolState.getEntryProtocolByIdResponse !== undefined
        ) {
            dispatch(setUpdatedEPFileAndAtt(entryProtocolState.getEntryProtocolByIdResponse.attachmentFile))
        }
    }, [entryProtocolState.getEntryProtocolByIdStatus])

    useEffect(() => {
        if (userState.findMeStatus === 'successfully') {
            dispatch(resetfindMeStatusIdle())
            if (userState.findMeResponse.headQuarterInfo !== null && userState.findMeResponse.headQuarterInfo !== undefined) {
                const newOption: OptionType = {
                    value: userState.findMeResponse.headQuarterInfo.id,
                    label: userState.findMeResponse.headQuarterInfo.code
                };

                setOfficeSot(prevOptions => {
                    const optionExists = prevOptions.some(option => option.value === newOption.value);
                    if (!optionExists) {
                        return [...prevOptions, newOption];
                    }
                    return prevOptions;
                });
            }
        }
    }, [userState.findMeStatus])

    useEffect(() => {
        setFile(entryProtocolState.fileToUpdate as File)
    }, [entryProtocolState.fileToUpdate])

    useEffect(() => {
        setViewable(entryProtocolState.entryProtocolUpdateRequest.usersVisibility)
        setSelectedOffices(entryProtocolState.entryProtocolUpdateRequest.destinationOffice as DestinationOfficesType[])
        dispatch(resetEntryProtocolErrors())
        dispatch(GetAllEntryProtocolObjects())
    }, [])

    useEffect(() => {
        if (entryProtocolState.getAllEntryProtocolObjectsStatus === 'successfully' &&
            entryProtocolState.getAllEntryProtocolObjectsResponse !== undefined
        ) {
            dispatch(resetGetAllEntryProtocolObjectsStatus())
            const data = entryProtocolState.getAllEntryProtocolObjectsResponse
            const newObjects: OptionType[] = data.map(d => {
                return { value: d.id, label: `${d.description} [${d.coding}]` } as OptionType
            })
            setObjects(newObjects)
        }
    }, [entryProtocolState.getAllEntryProtocolObjectsStatus])

    useEffect(() => {
        if (entryProtocolState.entryProtocolValidationStatus === 'successfully') {
            dispatch(resetEntryProtocolValidationStatus())
            formData.delete('file')
            if (file !== null)
                formData.append('file', file)
            formData.append('entryProtocolUpdateDTO', JSON.stringify(entryProtocolState.entryProtocolUpdateRequest))
            dispatch(EntryProtocolUpdate({ body: formData, id: entryProtocolState.entryProtocolIdToUpdate }))
        }
    }, [entryProtocolState.entryProtocolValidationStatus])

    useEffect(() => {
        if (entryProtocolState.entryProtocolUpdateStatus === 'successfully' &&
            entryProtocolState.entryProtocolUpdateResponse !== undefined
        ) {
            dispatch(resetEntryProtocolUpdateStatus())
            dispatch(setFileToUpdate(null))
            if (entryProtocolState.entryProtocolUpdateResponse !== null &&
                entryProtocolState.entryProtocolUpdateResponse !== '' &&
                entryProtocolState.entryProtocolUpdateResponse) {
                dispatch(GetFileObjectById(entryProtocolState.entryProtocolUpdateResponse))
            }

            if (entryProtocolState.entryProtocolUpdateFileAndAtt.length > 0) {

                const attF = entryProtocolState.entryProtocolUpdateFileAndAtt[0]
                dispatch(setEPFileAndAtt(entryProtocolState.entryProtocolUpdateFileAndAtt.slice(1)))
                if (attF !== undefined && attF && attF !== null) {
                    if (attF.file !== undefined && attF.file) {
                        attachmentFormData.delete('file')
                        attachmentFormData.append('file', attF.file)
                        attachmentFormData.append('attachmentFileDTO', JSON.stringify({ attachmentId: attF.attachmentId }))
                        dispatch(EntryProtocolUploadAttachmentFile({ data: attachmentFormData, id: entryProtocolState.entryProtocolIdToUpdate }))
                    }
                }
            }
        }
    }, [entryProtocolState.entryProtocolUpdateStatus])

    useEffect(() => {
        if (entryProtocolState.entryProtocolUploadAttachmentFileStatus === 'successfully') {
            dispatch(resetEntryProtocolUploadAttachmentFileStatus())
            if (entryProtocolState.entryProtocolUpdateFileAndAtt.length > 0) {
                const attF = entryProtocolState.entryProtocolUpdateFileAndAtt[0]
                dispatch(setEPFileAndAtt(entryProtocolState.entryProtocolUpdateFileAndAtt.slice(1)))

                if (attF !== undefined && attF && attF !== null) {
                    if (attF.file !== undefined && attF.file) {
                        attachmentFormData.delete('file')
                        attachmentFormData.append('file', attF.file)
                        attachmentFormData.append('attachmentFileDTO', JSON.stringify({ attachmentId: attF.attachmentId }))
                        dispatch(EntryProtocolUploadAttachmentFile({ data: attachmentFormData, id: entryProtocolState.entryProtocolIdToUpdate }))
                    }
                }
            }
            else {
                dispatch(GetEntryProtocolById(entryProtocolState.entryProtocolIdToUpdate))
            }
        }
    }, [entryProtocolState.entryProtocolUploadAttachmentFileStatus])

    useEffect(() => {
        if (objectStorageState.getFileObjectByIdStatus === 'successfully' &&
            objectStorageState.getFileObjectByIdResponse !== undefined
        ) {
            dispatch(resetGetFileObjectByIdStatus())
            dispatch(setUpdatedFile(objectStorageState.getFileObjectByIdResponse))
        }
    }, [objectStorageState.getFileObjectByIdStatus])

    useEffect(() => {
        dispatch(setUpdateEPUsersVisibility(viewable))
    }, [viewable])

    return (
        <div>

            {/* HEADER */}
            <div className="protocol-header">
                <span className='title'>Modifica protocollo</span>
                <Button
                    variant='solid'
                    color='orange'
                    size='sm'
                    iconPosition='off'
                    label='Salva protocollo'
                    onClick={() => dispatch(EntryProtocolValidation(entryProtocolState.entryProtocolUpdateRequest))}
                // da cambiare con validation
                />
            </div>

            {/* CONTENT */}
            <div className='protocol-content'>

                {/* FIRST ROW */}
                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Protocollo</span>
                        </div>
                        {
                            userState.findMeResponse.role === 'admin' ? (
                                <Input disabled value={entryProtocolState.entryProtocolUpdateRequest.headQuarter} />
                            ) : (
                                <SelectCustom
                                    placeholder={'Seleziona protocollo'}
                                    options={officeSot}
                                    disabled
                                    value={entryProtocolState.entryProtocolUpdateRequest.headQuarter}
                                    defaultValue={entryProtocolState.entryProtocolUpdateRequest.headQuarter}
                                />
                            )

                        }
                    </div>

                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Data protocollo</span>
                        </div>
                        <Calendar
                            disabled
                            selected={entryProtocolState.entryProtocolUpdateRequest.protocolDate}
                        />
                    </div>
                </div>

                {/* SECOND ROW */}
                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Nazione</span>
                        </div>
                        <SelectCustom
                            placeholder={'Seleziona nazione'}
                            options={countries}
                            onChange={(value) => dispatch(setUpdateEPNation(value))}
                            value={entryProtocolState.entryProtocolUpdateRequest.nation}
                        />
                    </div>

                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Regione</span>
                        </div>
                        <SelectCustom
                            error={entryProtocolState.entryProtocolErrors.region}
                            errorLabel='Regione mancante'
                            placeholder={'Seleziona regione'}
                            options={regioniList}
                            onChange={(value) => dispatch(setUpdateEPRegion(value))}
                            value={entryProtocolState.entryProtocolUpdateRequest.region}
                        />
                    </div>
                </div>

                {/* THIRD ROW */}
                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                    <div style={{ flex: '1' }}>
                        <Input
                            error={entryProtocolState.entryProtocolErrors.sender}
                            supportingText={entryProtocolState.entryProtocolErrors.sender ? 'Mittente mancante' : undefined}
                            label={'Mittente'}
                            placeholder="Mittente dei documenti da protocollare"
                            value={entryProtocolState.entryProtocolUpdateRequest.sender}
                            onChangeText={(text) => dispatch(setUpdateEPSender(text))}
                        />
                    </div>
                </div>

                {/* FOURTH ROW */}
                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                    <div style={{ flex: '1' }}>
                        <InputMultiSearch
                            defaultClientIds={entryProtocolState.entryProtocolUpdateRequest.customerIds}
                            label={'Cliente'}
                            placeholder="Ragione sociale"
                            onSelectAddHandler={(res) => dispatch(setUpdateEPCustomerIds(res))}
                        />
                    </div>
                </div>

                {/* FIFTH ROW */}
                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                    <div style={{ flex: '1' }}>
                        <Input
                            label={'Cliente non presente in archivio'}
                            placeholder="Indicare nel caso di cliente non ancora inserito in archivio"
                            value={entryProtocolState.entryProtocolUpdateRequest.customersNotRegistered}
                            onChangeText={(text) => dispatch(setUpdateEPCustomersNotRegistered(text))}
                        />
                    </div>
                </div>

                {/* SIXTH ROW */}
                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                    <div style={{ flex: '0.85' }}>
                        <div className="text-left">
                            <span className="input-label">{'Oggetto (Da elenco)'}</span>
                        </div>
                        { /* options da backend */}
                        <SelectCustom
                            error={entryProtocolState.entryProtocolErrors.entryObjectId}
                            errorLabel='Oggetto mancante'
                            placeholder={'Seleziona...'}
                            options={objects}
                            value={entryProtocolState.entryProtocolUpdateRequest.entryObjectId}
                            onChange={(value) => dispatch(setUpdateEPEntryObjectId(value))}
                        />
                    </div>
                    {userState.findMeResponse.role === 'admin' &&
                        <div style={{ flex: '0.15', alignSelf: 'flex-end', paddingBottom: '6px' }}>
                            <Button
                                variant='outline'
                                color='orange'
                                size='sm'
                                iconPosition='off'
                                label='Crea oggetto'
                                onClick={() => setShowPopup(true)}
                            />
                        </div>
                    }

                </div>

                {/* SEVENTH ROW */}
                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center', borderBottomWidth: 1 }}>
                    <div style={{ flex: '1' }}>
                        <div className="text-left">
                            <span className="input-label">Specifiche oggetto</span>
                        </div>
                        <TextArea
                            placeholder="Integrazione all'oggetto indicato sopra"
                            onChangeText={(text) => dispatch(setUpdateEPObjectSpec(text))}
                        />
                    </div>
                </div>

                {/* EIGHTH ROW */}
                <div className='pb-5 pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                    <div style={{ flex: '0.33' }}>
                        <Input
                            error={entryProtocolState.entryProtocolErrors.totalPage}
                            supportingText={entryProtocolState.entryProtocolErrors.totalPage ? 'Totale pagine mancante' : undefined}
                            label={'Totale pagine'}
                            placeholder="Specificare numero"
                            value={entryProtocolState.entryProtocolUpdateRequest.totalPage}
                            onChangeText={(text) => dispatch(setUpdateEPTotalPage(Number(text)))}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Mezzo entrata</span>
                        </div>
                        <SelectCustom
                            error={entryProtocolState.entryProtocolErrors.entryChannel}
                            errorLabel='Mezzo mancante'
                            placeholder={'Seleziona...'}
                            options={entryMeansOptions}
                            onChange={(value) => dispatch(setUpdateEPEntryChannel(value))}
                            value={entryProtocolState.entryProtocolUpdateRequest.entryChannel}
                        />
                    </div>
                    <div style={{ flex: '0.33' }}>
                        <div className="text-left">
                            <span className="input-label">Data Spedizione/Invio</span>
                        </div>
                        <Calendar
                            errorLabel={entryProtocolState.entryProtocolErrors.send ? 'Data mancante' : undefined}
                            selected={entryProtocolState.entryProtocolUpdateRequest.send}
                            onChange={(date) => dispatch(setUpdateEPSend(date))}
                        />
                    </div>
                </div>

                {/* NINETH ROW */}
                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center', borderBottomWidth: 1 }}>
                    <div style={{ flex: '1' }}>
                        <SingleCheckbox
                            checked={viewable}
                            setChecked={setViewable}
                            label='Protocollo visualizzabile da tutti gli utenti'
                        />
                    </div>
                </div>

                <div className="text-left pt-5 pb-2">
                    <span className="input-label" style={{ fontSize: '16px', fontWeight: 600 }}>
                        Aggancia a protocollo esistente
                    </span>
                </div>

                {/* TENTH ROW (Da rimpiazzare con componente) */}
                <div className='pb-5' style={{ borderBottomWidth: 1 }}>
                    <ProtocolSearch
                        type='entry'
                        defaultValue={entryProtocolState.entryProtocolUpdateRequest.linkExistingProtocol}
                        onSelect={(value) => dispatch(setUpdateEPLinkExistingProtocol(value))} />
                </div>

                <div className="text-left pt-5 pb-2">
                    <span className="input-label" style={{ fontSize: '16px', fontWeight: 600 }}>
                        Uffici di destinazione
                    </span>
                </div>

                {/* ELEVENTH ROW */}
                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                    <div style={{ flex: '0.33' }}>
                        <CheckboxGroup
                            name="tipo_ufficio_1"
                            options={Object.values(DestinationOfficesType).splice(0, 4)}
                            selectedValues={selectedOffices}
                            onChange={handleTypesChange}
                            optionLabels={{
                                [DestinationOfficesType.DIREZIONE]: "Direzione",
                                [DestinationOfficesType.MON_PEC_DIREZIONE]: "Monitoraggio PEC Direzione",
                                [DestinationOfficesType.AMM_UNICO]: "Amministratore Unico (Fasano)",
                                [DestinationOfficesType.SCHEME_MANAGER_GG]: "Scheme Manager GG"
                            }}
                        />
                    </div>

                    <div style={{ flex: '0.33' }}>
                        <CheckboxGroup
                            name="tipo_ufficio_1"
                            options={Object.values(DestinationOfficesType).splice(4, 4)}
                            selectedValues={selectedOffices}
                            onChange={handleTypesChange}
                            optionLabels={{
                                [DestinationOfficesType.UFFICIO_CERT]: "Ufficio certificazione",
                                [DestinationOfficesType.UFFICIO_CONTR]: "Ufficio Controllo",
                                [DestinationOfficesType.UFFICIO_SEGR_TEC]: "Ufficio Segreteria Tecnica",
                                [DestinationOfficesType.UFFICIO_CONT_AMM]: "Ufficio Contabile Amministrativo"
                            }}
                        />
                    </div>

                    <div style={{ flex: '0.33' }}>
                        <CheckboxGroup
                            name="tipo_ufficio_2"
                            options={Object.values(DestinationOfficesType).splice(8, 4)}
                            selectedValues={selectedOffices}
                            onChange={handleTypesChange}
                            optionLabels={{
                                [DestinationOfficesType.SOT_IS]: "S.O.T. IS",
                                [DestinationOfficesType.SOT_PU]: "S.O.T. PU",
                                [DestinationOfficesType.SOT_BA]: "S.O.T. BA",
                                [DestinationOfficesType.SOT_CL]: "S.O.T. CL"
                            }}
                        />
                    </div>
                </div>
                {
                    entryProtocolState.entryProtocolErrors.destinationOffice && (
                        <span className="input-supporting-text pt-2" style={{ color: '#fd665b', justifyContent: 'left', display: 'flex' }}>Indica almeno un ufficio di destinazione</span>
                    )
                }

            </div>

            {
                showPopup && (
                    <PopupForm title='Aggiungi Oggetto' close={() => {
                        dispatch(resetEPObjCreationRequest())
                        setShowPopup(false)
                    }}>
                        <AddObjectPopupEntry close={() => {
                            dispatch(resetEPObjCreationRequest())
                            setShowPopup(false)
                        }} />
                    </PopupForm>
                )
            }

        </div>
    )
}