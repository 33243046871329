import { useEffect, useState } from "react"
import { InfoBox } from "../../../../../../ui/molecules/infoBox"
import { SelectCustom } from "../../../../../../ui/molecules/select"
import { OptionType } from "../../../../../../utils"
import TextArea from "../../../../../../ui/molecules/textArea"
import { Calendar } from "../../../../../../ui/molecules/calendar"
import Button from "../../../../../../ui/molecules/button"
import { nonMeasureMap } from "../../../../../../utils/measuresUtils"
import { useAppDispatch, useAppSelector } from "../../../../../../lib/redux/hooks"
import { AppliedMeasureCreation, resetAppliedMeasureCreationRequest, setAppliedMeasureCreationCode, setAppliedMeasureCreationDetail, setAppliedMeasureCreationEndingDate, setAppliedMeasureCreationExpirationDate } from "../../../slice"

interface Props {
    close: () => void
    codeOptions: OptionType[]
    type: 'applied' | 'additional'
}

export function MeasureBoxAddPopup(props: Props) {
    const dispatch = useAppDispatch()
    const newMeasureState = useAppSelector(state => state.newMeasure)
    const [code, setCode] = useState<string | undefined>(undefined)

    useEffect(() => {
        if(newMeasureState.appliedMeasureCreationStatus === 'successfully') {
            dispatch(resetAppliedMeasureCreationRequest())
            props.close()
        }
    }, [newMeasureState.appliedMeasureCreationStatus])

    const handleDescription = (): string => {
        if (code === undefined)
            return 'Non definita'

        return nonMeasureMap.get(code)?.description || ''
    }

    return (
        <div>
            <div style={{ padding: '24px 24px 18px 24px' }}>
                <div>
                    <div className="text-left">
                        <span className="measure-text-bold">Codice</span>
                    </div>
                    <SelectCustom
                        key={newMeasureState.appliedMeasureCreationRequest.code || 'default'}
                        value={newMeasureState.appliedMeasureCreationRequest.code}
                        placeholder={'Seleziona codice...'}
                        options={props.codeOptions}
                        onChange={(value) => {
                            dispatch(setAppliedMeasureCreationCode(value))
                            setCode(value)
                        }}
                    />
                </div>
                <div className="pt-5">
                    <InfoBox
                        color={props.type === 'applied' ? 'green' : 'orange'}
                        freeText={{
                            title: 'Descrizione',
                            label: handleDescription()
                        }}
                    />
                </div>
                <div>
                    <div className="text-left pt-5">
                        <span className="input-label">Dettaglio</span>
                    </div>
                    <TextArea
                        onChangeText={(text) => dispatch(setAppliedMeasureCreationDetail(text))}
                    />
                </div>
                <div className="pt-5" style={{ display: 'flex', gap: '24px' }}>
                    <div style={{flex: '0.5'}}>
                        <div className="text-left">
                            <span className="input-label">Scadenza</span>
                        </div>
                        <Calendar
                            selected={newMeasureState.appliedMeasureCreationRequest.expirationDate}
                            onChange={(date) => dispatch(setAppliedMeasureCreationExpirationDate(date))}
                        />
                    </div>
                    <div style={{flex: '0.5'}}>
                        <div className="text-left">
                            <span className="input-label">Data Fine</span>
                        </div>
                        <Calendar
                            selected={newMeasureState.appliedMeasureCreationRequest.endingDate}
                            onChange={(date) => dispatch(setAppliedMeasureCreationEndingDate(date))}
                        />
                    </div>
                </div>
                <div className="pt-5" style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button 
                            variant="solid"
                            color="orange"
                            size="md"
                            iconPosition="off"
                            label="Salva"
                            onClick={() => dispatch(AppliedMeasureCreation(newMeasureState.appliedMeasureCreationRequest))}
                            />
                </div>  
            </div>
        </div>
    )
}