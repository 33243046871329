import { useEffect, useRef, useState } from "react";
import { Calendar } from "../../../../../ui/molecules/calendar";
import { InfoBox } from "../../../../../ui/molecules/infoBox";
import MultiSelectCheckbox from "../../../../../ui/molecules/multiSelectCheckbox";
import { ProtocolSearch } from "../../../../../ui/molecules/ProtocolSearch";
import { CustomRadio } from "../../../../../ui/molecules/radioButton";
import { SelectCustom } from "../../../../../ui/molecules/select";
import TextArea from "../../../../../ui/molecules/textArea";
import '../../../styles.scss'
import { ProtocolSelectByObject } from "../../../../../ui/molecules/ProtocolSelectByObj";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { MeasureBox } from "../../components/measureBox";
import { ControlTypeOptions, DepartureChannelOptions, nonMeasureMap, nonMeasureOptions } from "../../../../../utils/measuresUtils";
import { useNavigate } from "react-router-dom";
import { formatterDate, getLabelACtivity, HourOptions, MinuteOptions, OptionType } from "../../../../../utils";
import { NonComplianceMapping } from "../../../measuresOtherOdc/dataService/dto";
import { NonComplianceCodeByIdDTO } from "../../../NonComplianceCode/dto";
import { NonComplianceCode } from "../../../NonComplianceCode";
import { ControlTypeEnum, FindingFromEnum, MeasureVersionEnum, NonComplianceLevelEnum } from "../../dataService/dto";
import { setNewMeasureUpdateActivities, setNewMeasureUpdateControlType, setNewMeasureUpdateDepartureChannel, setNewMeasureUpdateDocumentDepartureProtocolId, setNewMeasureUpdateDocumentEntryProtocolId, setNewMeasureUpdateDocumentIntegrationRequestId, setNewMeasureUpdateFindingFrom, setNewMeasureUpdateInspectionVisitId, setNewMeasureUpdateInspectorMeasureConfirmed, setNewMeasureUpdateLaboratoryTestReportProtocolId, setNewMeasureUpdateM018Id, setNewMeasureUpdateMeasureCorrectionId, setNewMeasureUpdateMeasureCorrectionversion, setNewMeasureUpdateMeasureRecipientsIds, setNewMeasureUpdateNewNonComplianceCodeCorrectionId, setNewMeasureUpdateNewNonComplianceCodeId, setNewMeasureUpdateNoCorrectionNote, setNewMeasureUpdateNonComplianceFindingDate, setNewMeasureUpdateNonComplianceFindingNote, setNewMeasureUpdateNonComplianceLevel, setNewMeasureUpdateNonComplianceNote, setNewMeasureUpdateOfficerMeasureSignatureId, setNewMeasureUpdateOldNonComplianceCode, setNewMeasureUpdateOldNonComplianceCodeCorrection, setNewMeasureUpdatePreventiveSuppressionM024Id, setNewMeasureUpdateProductsCategories, setNewMeasureUpdateRequest } from "../../slice";
import { GetAllRID, resetGetAllRIDStatus } from "../../../../gestioneClienti/RID/slice";
import { GetNcById, resetGetByIdStatus } from "../../../NonComplianceCode/slice";
import { getUsers } from "../../../../users/slice";
import { GetAllMeasureRecipients } from "../../../measureRecipients/slice";
import { HoursMinutesBox } from "../../../../schedaCalcolo/components/hoursMinutes";

/* LOCAL UTILS */

enum SampledByEnum {
    TECNICO_ISPETTORE = 'TECNICO_ISPETTORE',
    RIESAME = 'RIESAME'
}

enum IsConfirmedEnum {
    SI = 'SI',
    NO = 'NO'
}

const SeverityLevelOptions: OptionType[] = [
    { value: NonComplianceLevelEnum.SCARSO, label: 'Scarsa' },
    { value: NonComplianceLevelEnum.GRAVE, label: 'Grave' },
    { value: NonComplianceLevelEnum.CRITICO, label: 'Critica' },
]

export function AddNewCodexMeasureStep2Forms() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const sessionState = useAppSelector(state => state.session)
    const newMeasureState = useAppSelector(state => state.newMeasure)
    const NonComplianceCodesState = useAppSelector(state => state.NonComplianceCodes)
    const userState = useAppSelector(state => state.users)
    const measureRecipientState = useAppSelector(state => state.measureRecipient)
    const ridState = useAppSelector(state => state.rid)
    const [sampledBy, setSampledBy] = useState<SampledByEnum | null>(null)
    const [isConfirmed, setIsConfirmed] = useState<IsConfirmedEnum | null>(null)
    const [selectedActivities, setSelectedActivities] = useState<OptionType[]>([]);
    const [selectedCategories, setSelectedCategories] = useState<OptionType[]>([]);
    const [measureVersionSelection, setMeasureVersionSelection] = useState<MeasureVersionEnum | null>(null)
    const [severityLevel, setSeverityLevel] = useState<NonComplianceLevelEnum>(NonComplianceLevelEnum.SCARSO)
    const [RID, setRID] = useState<OptionType[]>([])
    const [ncResource, setNcResource] = useState<NonComplianceCodeByIdDTO | null>(null)
    const [measureOptions, setMeasureOptions] = useState<OptionType[]>([])
    const [usersForSignature, setUsersForSignature] = useState<OptionType[]>([])
    const [measureRecipientsOptions, setMeasureRecipientsOptions] = useState<OptionType[]>([])
    const [measureRecipients, setMeasureRecipients] = useState<OptionType[]>([])
    const [standardMeasureToSet, setStandardMeasureToSet] = useState<boolean>(false)

    useEffect(() => {
        dispatch(GetAllRID({
            itemsPerPage: 0,
            page: 0,
            customerId: sessionState.selected.customerId!
        }))

        dispatch(getUsers({
            itemsPerPage: 0,
            page: 0,
            isMeasuresSignature: true,
            order: false
        }))

        dispatch(GetAllMeasureRecipients({
            itemsPerPage: 0,
            page: 0,
            active: true
        }))

        const findingFrom = newMeasureState.newMeasureUpdateRequest.findingFrom
        const inspectorMeasureConfirmed = newMeasureState.newMeasureUpdateRequest.inspectorMeasureConfirmed

        if (findingFrom !== null && findingFrom !== undefined)
            setSampledBy(findingFrom === FindingFromEnum.TECNICO_ISPETTORE ? SampledByEnum.TECNICO_ISPETTORE : SampledByEnum.RIESAME)

        if (inspectorMeasureConfirmed !== null && inspectorMeasureConfirmed !== undefined)
            setIsConfirmed(inspectorMeasureConfirmed ? IsConfirmedEnum.SI : IsConfirmedEnum.NO)

        const activities = newMeasureState.newMeasureUpdateRequest.activities
        const productCategories = newMeasureState.newMeasureUpdateRequest.productsCategories
        const measureRec = newMeasureState.newMeasureUpdateRequest.measureRecipientsIds
        setMeasureRecipients(measureRec as unknown as OptionType[])

        setMeasureVersionSelection(newMeasureState.newMeasureUpdateRequest.measureCorrectionVersion as MeasureVersionEnum)
        setSelectedActivities(activities as unknown as OptionType[])
        setSelectedCategories(productCategories as unknown as OptionType[])

    }, [])

    useEffect(() => {
        if (ridState.getAllRidStatus === 'successfully' &&
            ridState.getAllRidResponse !== undefined
        ) {
            dispatch(resetGetAllRIDStatus())
            const data = ridState.getAllRidResponse.data.map(rid => {
                return {
                    value: rid.id,
                    label: rid.departureProtocolInfo
                } as OptionType
            })
            setRID(data)
        }
    }, [ridState.getAllRidStatus])

    useEffect(() => {
        if (userState.getUsersStatus === 'successfully' &&
            userState.getUsersResponse !== undefined
        ) {
            const data = userState.getUsersResponse.data.map(user => {
                return {
                    value: String(user.id), label: `${user.name} ${user.surname}`
                } as OptionType
            })
            console.log(data);

            setUsersForSignature(data)
        }
    }, [userState.getUsersStatus])

    useEffect(() => {
        if (measureRecipientState.getAllMeasureRecipientsStatus === 'successfully' &&
            measureRecipientState.getAllMeasureRecipientsResponse !== undefined
        ) {
            const data = measureRecipientState.getAllMeasureRecipientsResponse.data.map(mr => {
                return {
                    value: mr.id, label: mr.description
                } as OptionType
            })
            setMeasureRecipientsOptions(data)
        }
    }, [measureRecipientState.getAllMeasureRecipientsStatus])

    useEffect(() => {
        if (newMeasureState.newMeasureUpdateRequest.newNonComplianceCodeId &&
            newMeasureState.newMeasureUpdateRequest.newNonComplianceCodeId !== ''
        ) {
            dispatch(GetNcById(newMeasureState.newMeasureUpdateRequest.newNonComplianceCodeId))
        }
    }, [newMeasureState.newMeasureUpdateRequest.newNonComplianceCodeId])

    useEffect(() => {
        if (NonComplianceCodesState.ConComplianceCodeResponseByIdStatus === 'successfully' &&
            NonComplianceCodesState.ConComplianceCodeResponseById !== undefined
        ) {
            dispatch(resetGetByIdStatus())
            setNcResource(NonComplianceCodesState.ConComplianceCodeResponseById)
        }
    }, [NonComplianceCodesState.ConComplianceCodeResponseByIdStatus])

    useEffect(() => {
        if (ncResource) {
            let availableOptions: OptionType[] = []
            switch (severityLevel) {
                case NonComplianceLevelEnum.SCARSO:
                    if (ncResource.nonComplianceAreaDTO?.lowMeasure && ncResource.nonComplianceAreaDTO?.lowMeasure !== '' &&
                        ncResource.nonComplianceAreaDTO?.lowMeasure !== 'n.a.')
                        availableOptions = [{ value: ncResource.nonComplianceAreaDTO?.lowMeasure, label: ncResource.nonComplianceAreaDTO?.lowMeasure }]
                    break

                case NonComplianceLevelEnum.GRAVE:
                    if (ncResource.nonComplianceAreaDTO?.graveMeasure && ncResource.nonComplianceAreaDTO?.graveMeasure !== '' &&
                        ncResource.nonComplianceAreaDTO?.graveMeasure !== 'n.a.') {
                        availableOptions = ncResource.nonComplianceAreaDTO?.graveMeasure.split('|').map(gm => {
                            return { value: gm, label: gm } as OptionType
                        })
                    }
                    break

                case NonComplianceLevelEnum.CRITICO:
                    if (ncResource.nonComplianceAreaDTO?.criticalMeasure && ncResource.nonComplianceAreaDTO?.criticalMeasure !== '' &&
                        ncResource.nonComplianceAreaDTO?.criticalMeasure !== 'n.a.') {
                        availableOptions = ncResource.nonComplianceAreaDTO?.criticalMeasure.split('|').map(cm => {
                            return { value: cm, label: cm } as OptionType
                        })
                    }
                    break
            }
            setMeasureOptions(availableOptions)
        }
    }, [severityLevel])

    const getAdditionalMeasureOptions = (): OptionType[] => {
        if (ncResource && ncResource.nonComplianceAreaDTO?.additionalMeasures && ncResource.nonComplianceAreaDTO?.additionalMeasures !== '' &&
            ncResource.nonComplianceAreaDTO?.additionalMeasures !== 'n.a.') {
            return ncResource.nonComplianceAreaDTO?.additionalMeasures.split(', ').map(am => {
                return {
                    value: am, label: am
                } as OptionType
            })
        }
        return []
    }

    return (
        <div>
            <div className="measure-container" style={{ backgroundColor: '#F2F7F7' }}>
                <div className='measure-header' style={{ backgroundColor: '#314d51' }}>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Provvedimenti </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}> Dati Generali</span>
                    </div>
                </div>
                <div style={{ padding: '24px 24px 32px 24px', display: 'flex', gap: '24px' }}>
                    <div style={{ flex: '0.5' }}>
                        <InfoBox
                            title='Informazione Valutazione (CDXM024)'
                            color='green'
                            content={[
                                { title: 'Data Valutazione', label: '20/02/2025 (MOCK)' },
                                { title: 'Data Riesame', label: '22/02/2025 (MOCK)' },
                                { title: 'Data Decisione', label: '22/02/2025 (MOCK)' },
                            ]}
                        />
                    </div>
                    <div style={{ flex: '0.5' }}>
                        <InfoBox
                            title='Data e protocollo'
                            color='green'
                            content={[
                                { title: 'Data Provvedimento', label: formatterDate(newMeasureState.getNewMeasureByIdResponse?.measureDate) },
                                { title: 'Protocollo', label: newMeasureState.getNewMeasureByIdResponse?.departureProtocolInfo || null }
                            ]}
                        />
                    </div>
                </div>
            </div>

            <div className="measure-container" style={{ backgroundColor: '#F2F7F7' }}>
                <div className='measure-header' style={{ backgroundColor: '#925016' }}>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Provvedimenti </span>
                        <span className='rid-title' style={{ color: '#E67E22' }}>/</span>
                        <span className='rid-title' style={{ color: '#FAE7D7' }}> Non Conformità</span>
                    </div>
                </div>

                <div style={{ padding: '24px 24px 32px 24px' }}>
                    <NonComplianceCode
                        formType="edit"
                        newDefaultValue={{
                            areaCode: newMeasureState.getNewMeasureByIdResponse?.newNonComplianceCodeDTO?.nonComplianceAreaDTO?.areaCode || '',
                            areaDescription: newMeasureState.getNewMeasureByIdResponse?.newNonComplianceCodeDTO?.nonComplianceAreaDTO?.area || '',
                            ncId: newMeasureState.getNewMeasureByIdResponse?.newNonComplianceCodeDTO?.id || '',
                            ncDescription: newMeasureState.getNewMeasureByIdResponse?.newNonComplianceCodeDTO?.description || '',
                            legReference: newMeasureState.getNewMeasureByIdResponse?.newNonComplianceCodeDTO?.legislativeReference || ''
                        }}
                        oldDefaultValue={newMeasureState.getNewMeasureByIdResponse?.nonComplianceCodeVersion === MeasureVersionEnum.OLD ?
                            newMeasureState.getNewMeasureByIdResponse?.oldNonComplianceCode : undefined
                        }
                        disabled={newMeasureState.getNewMeasureByIdResponse?.nonComplianceCodeVersion === MeasureVersionEnum.NEW &&
                            newMeasureState.getAllAppliedMeasureResponse && newMeasureState.getAllAppliedMeasureResponse.data.length > 0}
                        type={newMeasureState.getNewMeasureByIdResponse?.nonComplianceCodeVersion === MeasureVersionEnum.NEW ? 'new' : 'old'}
                        onChange={(value) => {
                            if (newMeasureState.getNewMeasureByIdResponse?.nonComplianceCodeVersion === MeasureVersionEnum.NEW){
                                dispatch(setNewMeasureUpdateNewNonComplianceCodeId(value))
                                setStandardMeasureToSet(true)
                            }else
                                dispatch(setNewMeasureUpdateOldNonComplianceCode(value))

                        }}
                    />

                    <div className="text-left pt-5">
                        <span className="measure-text-bold">Note</span>
                    </div>
                    <TextArea
                        value={newMeasureState.newMeasureUpdateRequest.nonComplianceNote}
                        onChangeText={(text) => dispatch(setNewMeasureUpdateNonComplianceNote(text))}
                    />
                </div>
            </div>
            <div className="measure-container" style={{ backgroundColor: '#F2F7F7' }}>
                <div className='measure-header' style={{ backgroundColor: '#314d51' }}>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Provvedimenti </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}> Non Conformità rilevata nelle seguenti attività</span>
                    </div>
                </div>

                <div style={{ padding: '24px 24px 32px 24px', display: 'flex', gap: '24px' }}>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="measure-text-bold">Attività</span>
                        </div>
                        <MultiSelectCheckbox
                            // value={}
                            placeholder={'Seleziona attività...'}
                            value={selectedActivities}
                            option={sessionState.selected.customerActivities.map(a => {
                                return { value: a, label: getLabelACtivity(a, 'attivita_bio')! }
                            })}
                            selectAllLabel="Tutti"
                            maxSelectedLabel={2}
                            onChange={(e) => {
                                dispatch(setNewMeasureUpdateActivities(e.value))
                                setSelectedActivities(e.value)
                            }}
                        />
                    </div>

                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="measure-text-bold">Classificazione</span>
                        </div>
                        {
                            (
                                sessionState.selected.customerCategories !== undefined &&
                                sessionState.selected.customerCategories.length > 0
                            )
                            &&
                            <MultiSelectCheckbox
                                // value={}
                                placeholder={'Seleziona classificazione...'}
                                value={selectedCategories}
                                option={sessionState.selected.customerCategories.filter(c => c != undefined).map(c => {
                                    return { value: c, label: getLabelACtivity(c, 'categorie_prodotti')! }
                                })}
                                selectAllLabel="Tutti"
                                maxSelectedLabel={1}
                                onChange={(e) => {
                                    dispatch(setNewMeasureUpdateProductsCategories(e.value))
                                    setSelectedCategories(e.value)
                                }}
                            />
                        }

                    </div>
                </div>
            </div>

            <div className="measure-container" style={{ backgroundColor: '#F2F7F7' }}>
                <div className='measure-header' style={{ backgroundColor: '#314d51' }}>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Provvedimenti </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}> Mancata Correzione o Correzione Non Tempestiva di una Non Conformità</span>
                    </div>
                </div>

                <div style={{ padding: '24px 24px 32px 24px' }}>

                    <div className='mb-1' style={{ display: 'flex', gap: '8px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CustomRadio
                                options={[{ label: '', value: MeasureVersionEnum.NEW }]}
                                selectedValue={measureVersionSelection}
                                onChange={(value) => {
                                    setMeasureVersionSelection(value as MeasureVersionEnum)
                                    dispatch(setNewMeasureUpdateMeasureCorrectionversion(MeasureVersionEnum.NEW))
                                    dispatch(setNewMeasureUpdateOldNonComplianceCodeCorrection(undefined))
                                }}
                                id={'new_measure_correction'}
                                name={'new_measure_correction'}
                            />
                        </div>
                        <div className='text-left'>
                            <span className='subtitle' style={{ color: '#5a8388' }}>Seleziona Provvedimento (DM n.0323651 del 18/07/2024)</span>
                        </div>
                    </div>

                    <div className="ml-20 mb-5">
                        <div style={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
                            <div style={{ flex: '0.5' }}>
                                <SelectCustom
                                    value={newMeasureState.newMeasureUpdateRequest.measureCorrectionId}
                                    disabled={measureVersionSelection === MeasureVersionEnum.OLD}
                                    placeholder={'Seleziona provvedimento...'}
                                    options={newMeasureState.savedNewMeasures.map(sm => {
                                        return { value: sm.id, label: sm.protocolInfo }
                                    })}
                                    onChange={(value) => dispatch(setNewMeasureUpdateMeasureCorrectionId(value))}
                                />
                            </div>
                            <div style={{ flex: '0.5' }}>
                                <div className="text-left">
                                    <div className="measure-text-bold">Codice Riferimento Normativo Non Conformità</div>
                                    <div className="measure-text-content">{newMeasureState.savedNewMeasures.filter(nm => nm.id === newMeasureState.newMeasureUpdateRequest.measureCorrectionId)[0]?.ncCode.code || 'Codice non trovato'}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mb-1' style={{ display: 'flex', gap: '8px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CustomRadio
                                options={[{ label: '', value: MeasureVersionEnum.OLD }]}
                                selectedValue={measureVersionSelection}
                                onChange={(value) => {
                                    setMeasureVersionSelection(value as MeasureVersionEnum)
                                    dispatch(setNewMeasureUpdateMeasureCorrectionversion(MeasureVersionEnum.OLD))
                                    dispatch(setNewMeasureUpdateNewNonComplianceCodeCorrectionId(undefined))
                                }}
                                id={'old_measure_correction'}
                                name={'old_measure_correction'}
                            />
                        </div>
                        <div className='text-left'>
                            <span className='subtitle' style={{ color: '#5a8388' }}>Seleziona Provvedimento (DM n.15962 del 20/12/2013)</span>
                        </div>
                    </div>

                    <div className="ml-20">
                        <div style={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
                            <div style={{ flex: '0.5' }}>
                                <SelectCustom
                                    value={newMeasureState.newMeasureUpdateRequest.measureCorrectionId}
                                    disabled={measureVersionSelection === MeasureVersionEnum.NEW}
                                    placeholder={'Seleziona provvedimento...'}
                                    options={newMeasureState.savedOldMeasures.map(sm => {
                                        return { value: sm.id, label: sm.protocolInfo }
                                    })}
                                    onChange={(value) => dispatch(setNewMeasureUpdateMeasureCorrectionId(value))}
                                />
                            </div>
                            <div style={{ flex: '0.5' }}>
                                <div className="text-left">
                                    <div className="measure-text-bold">Codice Non Conformità</div>
                                    <div className="measure-text-content">{newMeasureState.savedOldMeasures.filter(om => om.id === newMeasureState.newMeasureUpdateRequest.measureCorrectionId)[0]?.code || 'Codice non trovato'}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="text-left pt-5">
                        <span className="measure-text-bold">Note</span>
                    </div>
                    <TextArea
                        value={newMeasureState.newMeasureUpdateRequest.noCorrectionNote}
                        onChangeText={(text) => dispatch(setNewMeasureUpdateNoCorrectionNote(text))}
                    />
                </div>
            </div>

            {
                newMeasureState.getNewMeasureByIdResponse?.nonComplianceCodeVersion === MeasureVersionEnum.NEW && (
                    <div>
                        <div className="measure-container" style={{ backgroundColor: '#F2F7F7' }}>
                            <div className='measure-header' style={{ backgroundColor: '#314d51' }}>
                                <div className='text-left'>
                                    <span className='rid-title' style={{ color: '#fff' }}>Provvedimenti </span>
                                    <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                                    <span className='rid-title' style={{ color: '#d9e6e8' }}> Misure previste per la non conformità</span>
                                </div>
                            </div>

                            <div style={{ padding: '24px 24px 32px 24px' }}>

                                {
                                    ncResource === null &&
                                    <div className='measure-title-bold text-left' style={{ color: '#925016' }}>Seleziona un Codice Riferimento Normativo Non Conformità</div>
                                }

                                {
                                    ncResource &&
                                    <div>
                                        <div className="pb-5 text-left" style={{ borderBottomWidth: 2, borderColor: '#D9E6E8' }}>
                                            <div className='measure-title-bold text-left' style={{ color: '#74A4A9' }}>{ncResource.nonComplianceCode}</div>
                                            <span className="measure-text-content">({ncResource.description})</span>
                                        </div>

                                        {
                                            (ncResource.nonComplianceAreaDTO?.standardMeasure && ncResource.nonComplianceAreaDTO?.standardMeasure !== '' &&
                                                ncResource.nonComplianceAreaDTO?.standardMeasure !== 'n.a.'
                                            ) && (
                                                <div className="text-left pb-5 pt-5" style={{ display: 'flex', gap: '36px', alignItems: 'center', borderBottomWidth: 1 }}>
                                                    <span className="measure-text-bold">Misura: </span>
                                                    <span className="measure-text-content">{`${ncResource.nonComplianceAreaDTO?.standardMeasure} (${nonMeasureMap.get(ncResource.nonComplianceAreaDTO?.standardMeasure)?.description})`}</span>
                                                </div>
                                            )
                                        }

                                        <div className="text-left pb-2 pt-5">
                                            <span className="measure-text-bold">Misura minima: </span>
                                        </div>

                                        <div className="pb-5" style={{ borderBottomWidth: 1 }}>
                                            <div id="measure-table">
                                                <div className="head uppercase">
                                                    <div className="h-child green-bg rounded-tl-[12px]">Scarsa Entità</div>
                                                    <div className="h-child green-bg">Grave</div>
                                                    <div className="h-child green-bg rounded-tr-[12px]">Critica</div>
                                                </div>
                                                <div className="body">
                                                    <div className="b-row" style={{
                                                        color: "#4D797F", background: "#F2F7F7"
                                                    }}>
                                                        <div className="b-child">
                                                            <InfoBox
                                                                color="green"
                                                                unorderedList={{
                                                                    title: null, items:
                                                                        ncResource.nonComplianceAreaDTO?.lowMeasure && ncResource.nonComplianceAreaDTO?.lowMeasure !== '' &&
                                                                            ncResource.nonComplianceAreaDTO?.lowMeasure !== 'n.a.' ?
                                                                            [`${ncResource.nonComplianceAreaDTO?.lowMeasure} (${nonMeasureMap.get(ncResource.nonComplianceAreaDTO?.lowMeasure)?.description})`] : []
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="b-child">
                                                            <InfoBox
                                                                color="orange"
                                                                unorderedList={{
                                                                    title: null, items:
                                                                        ncResource.nonComplianceAreaDTO?.graveMeasure && ncResource.nonComplianceAreaDTO?.graveMeasure !== '' &&
                                                                            ncResource.nonComplianceAreaDTO?.graveMeasure !== 'n.a.' ?
                                                                            ncResource.nonComplianceAreaDTO?.graveMeasure.split('|').map(gm =>
                                                                                `${gm} (${nonMeasureMap.get(gm)?.description})`
                                                                            ) : []
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="b-child">
                                                            <InfoBox
                                                                color="red"
                                                                unorderedList={{
                                                                    title: null, items:
                                                                        ncResource.nonComplianceAreaDTO?.criticalMeasure && ncResource.nonComplianceAreaDTO?.criticalMeasure !== '' &&
                                                                            ncResource.nonComplianceAreaDTO?.criticalMeasure !== 'n.a.' ?
                                                                            ncResource.nonComplianceAreaDTO?.criticalMeasure.split('|').map(cm =>
                                                                                `${cm} (${nonMeasureMap.get(cm)?.description})`
                                                                            ) : []
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            ncResource.nonComplianceAreaDTO?.additionalMeasures && ncResource.nonComplianceAreaDTO?.additionalMeasures !== '' &&
                                            ncResource.nonComplianceAreaDTO?.additionalMeasures !== 'n.a.' && (
                                                <div className="text-left pt-5" style={{ display: 'flex', gap: '36px', alignItems: 'center' }}>
                                                    <span className="measure-text-bold">Misura aggiuntiva: </span>
                                                    <div>
                                                        {
                                                            ncResource.nonComplianceAreaDTO?.additionalMeasures.split(', ').map(am =>
                                                                <span className="measure-text-content">{`${am} (${nonMeasureMap.get(am)?.description}) - `}</span>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="measure-container" style={{ backgroundColor: '#F2F7F7' }}>
                            <div className='measure-header' style={{ backgroundColor: '#925016' }}>
                                <div className='text-left'>
                                    <span className='rid-title' style={{ color: '#fff' }}>Provvedimenti </span>
                                    <span className='rid-title' style={{ color: '#E67E22' }}>/</span>
                                    <span className='rid-title' style={{ color: '#FAE7D7' }}> Misure da applicare per la non conformità</span>
                                </div>
                            </div>

                            <div style={{ padding: '24px 24px 32px 24px' }}>
                                {
                                    ncResource === null &&
                                    <div className='measure-title-bold text-left' style={{ color: '#925016' }}>Seleziona un Codice Riferimento Normativo Non Conformità</div>
                                }
                                {
                                    ncResource && (
                                        <div>
                                            <div className="pb-5 text-left" style={{ borderBottomWidth: 2, borderColor: '#D9E6E8' }}>
                                                <div className='measure-title-bold text-left' style={{ color: '#74A4A9' }}>{ncResource.nonComplianceCode}</div>
                                                <span className="measure-text-content">({ncResource.description})</span>
                                            </div>

                                            <div className="pb-5 pt-5">
                                                <div className="text-left">
                                                    <span className="measure-text-bold">Livello Non Conformità</span>
                                                </div>
                                                <SelectCustom
                                                    placeholder={'Seleziona livello...'}
                                                    value={newMeasureState.newMeasureUpdateRequest.nonComplianceLevel}
                                                    options={SeverityLevelOptions}
                                                    onChange={(value) => {
                                                        dispatch(setNewMeasureUpdateNonComplianceLevel(value))
                                                        setSeverityLevel(value as NonComplianceLevelEnum)
                                                    }}
                                                />
                                            </div>


                                            <MeasureBox
                                                isAdditional={false}
                                                newMeasureId={newMeasureState.newMeasureIdToUpdate}
                                                availableOptions={measureOptions}
                                                standardMeasureToSet={standardMeasureToSet}
                                                setStandardMeasureToSet={setStandardMeasureToSet}
                                                standardMeasure={
                                                    ncResource.nonComplianceAreaDTO?.standardMeasure && ncResource.nonComplianceAreaDTO?.standardMeasure !== '' &&
                                                        ncResource.nonComplianceAreaDTO?.standardMeasure !== 'n.a.' ? ncResource.nonComplianceAreaDTO?.standardMeasure : undefined
                                                }
                                            />

                                            <div className="mt-5">
                                                <MeasureBox
                                                    isAdditional={true}
                                                    newMeasureId={newMeasureState.newMeasureIdToUpdate}
                                                    availableOptions={getAdditionalMeasureOptions()}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            }
            <div className="measure-container" style={{ backgroundColor: '#F2F7F7', position: 'relative' }}>
                <div className='measure-header' style={{ backgroundColor: '#314d51' }}>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Provvedimenti </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}> Dettaglio rilievo non conformità</span>
                    </div>
                </div>

                <div style={{ padding: '24px 24px 32px 24px' }}>

                    <div className="pb-3" style={{ display: 'flex', gap: '24px' }}>
                        <div style={{ flex: '0.5' }}>
                            <div className="text-left">
                                <span className="measure-text-bold">Tipo di Controllo (Fonte Rilievo)</span>
                            </div>
                            <SelectCustom
                                value={newMeasureState.newMeasureUpdateRequest.controlType}
                                placeholder={'Seleziona tipo...'}
                                options={ControlTypeOptions}
                                onChange={(value) => dispatch(setNewMeasureUpdateControlType(value))}
                            />
                        </div>
                        <div style={{ flex: '0.5' }}>
                            <div className="text-left">
                                <span className="measure-text-bold">Data rilievo Non Confomità</span>
                            </div>
                            <Calendar
                                selected={newMeasureState.newMeasureUpdateRequest.nonComplianceFindingDate}
                                onChange={(date) => dispatch(setNewMeasureUpdateNonComplianceFindingDate(date))}
                            />
                        </div>
                    </div>

                    <div className="pb-5" style={{ borderBottomWidth: '1px' }}>
                        <div className="text-left pt-5">
                            <span className="measure-text-bold">Note</span>
                        </div>
                        <TextArea
                            value={newMeasureState.newMeasureUpdateRequest.nonComplianceFindingNote}
                            onChangeText={(text) => dispatch(setNewMeasureUpdateNonComplianceFindingNote(text))}
                        />
                    </div>

                    <div className="text-left pt-5">
                        <span className="measure-text-bold">Documento in Entrata</span>
                    </div>
                    <div className="pb-5">
                        <ProtocolSearch
                            type="entry"
                            value={newMeasureState.newMeasureUpdateRequest.documentEntryProtocolId}
                            onSelect={(value) => dispatch(setNewMeasureUpdateDocumentEntryProtocolId(value))}
                        />
                    </div>

                    <div className="text-left">
                        <span className="measure-text-bold">Documento in Partenza</span>
                    </div>
                    <div className="pb-5">
                        <ProtocolSearch
                            type="departure"
                            value={newMeasureState.newMeasureUpdateRequest.documentDepartureProtocolId}
                            onSelect={(value) => dispatch(setNewMeasureUpdateDocumentDepartureProtocolId(value))}
                        />
                    </div>

                    <div className="pb-5" style={{ borderBottomWidth: '1px' }}>
                        <div className="text-left">
                            <span className="measure-text-bold">Richiesta Integrazione Documenti</span>
                        </div>
                        <SelectCustom
                            value={newMeasureState.newMeasureUpdateRequest.documentIntegrationRequestId}
                            placeholder={'Seleziona RID...'}
                            options={RID}
                            onChange={(value) => dispatch(setNewMeasureUpdateDocumentIntegrationRequestId(value))}
                        />
                    </div>

                    <div className="pb-5 pt-5">
                        <div className="text-left">
                            <span className="measure-text-bold">Visita Ispettiva</span>
                        </div>
                        <SelectCustom
                            // value={}
                            placeholder={'Seleziona visita ispettiva...'}
                            options={[]}
                            onChange={(value) => dispatch(setNewMeasureUpdateInspectionVisitId(value))}
                        />
                    </div>

                    <div className="pb-5" style={{ display: 'flex', gap: '48px' }}>
                        <div style={{ display: 'flex', gap: '12px' }}>
                            <div className="text-left">
                                <span className="measure-text-bold">Rilevato da</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <CustomRadio
                                    options={[
                                        { label: ' Tecnico Ispettore', value: SampledByEnum.TECNICO_ISPETTORE }
                                    ]}
                                    selectedValue={sampledBy}
                                    onChange={(value) => {
                                        dispatch(setNewMeasureUpdateFindingFrom(FindingFromEnum.TECNICO_ISPETTORE))
                                        setSampledBy(value as SampledByEnum)
                                    }}
                                    id={'sampled_by_1'}
                                    name={'sampled_by_1'}
                                />
                                <CustomRadio
                                    options={[
                                        { label: ' Riesame', value: SampledByEnum.RIESAME }
                                    ]}
                                    selectedValue={sampledBy}
                                    onChange={(value) => {
                                        dispatch(setNewMeasureUpdateFindingFrom(FindingFromEnum.RIESAME))
                                        setSampledBy(value as SampledByEnum)
                                    }}
                                    id={'sampled_by_2'}
                                    name={'sampled_by_2'}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', gap: '12px' }}>
                            <div className="text-left">
                                <span className="measure-text-bold">Confermata misura rilevata dal Tecnico Ispettore</span>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <CustomRadio
                                    options={[
                                        { label: ' Sì', value: IsConfirmedEnum.SI }
                                    ]}
                                    selectedValue={isConfirmed}
                                    onChange={(value) => {
                                        dispatch(setNewMeasureUpdateInspectorMeasureConfirmed(true))
                                        setIsConfirmed(value as IsConfirmedEnum)
                                    }}
                                    id={'is_confirmed_1'}
                                    name={'is_confirmed_1'}
                                />
                                <CustomRadio
                                    options={[
                                        { label: ' No', value: IsConfirmedEnum.NO }
                                    ]}
                                    selectedValue={isConfirmed}
                                    onChange={(value) => {
                                        dispatch(setNewMeasureUpdateInspectorMeasureConfirmed(false))
                                        setIsConfirmed(value as IsConfirmedEnum)
                                    }}
                                    id={'is_confirmed_2'}
                                    name={'is_confirmed_2'}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="pb-5">
                        <div className="text-left">
                            <span className="measure-text-bold">Rapporto di Prova</span>
                        </div>
                        <ProtocolSelectByObject
                            customerId={sessionState.selected.customerId!}
                            objectId="63"
                            type="entry"
                            value={newMeasureState.newMeasureUpdateRequest.laboratoryTestReportProtocolId}
                            onChange={(value) => dispatch(setNewMeasureUpdateLaboratoryTestReportProtocolId(value))}
                            placeholder="Seleziona protocollo..."
                        />
                    </div>

                    <div className="pb-5">
                        <div className="text-left">
                            <span className="measure-text-bold">Soppressione cautelativa dei riferimenti alla produzione biologica</span>
                        </div>
                        <SelectCustom
                            // value={}
                            placeholder={'Seleziona soppressione cautelativa...'}
                            options={[]}
                            onChange={(value) => dispatch(setNewMeasureUpdatePreventiveSuppressionM024Id(value))}
                        />
                    </div>

                    <div className="pb-5">
                        <div className="text-left">
                            <span className="measure-text-bold">M18</span>
                        </div>
                        <SelectCustom
                            // value={}
                            placeholder={'Seleziona M18...'}
                            options={[]}
                            onChange={(value) => dispatch(setNewMeasureUpdateM018Id(value))}
                        />
                    </div>
                </div>
            </div>
            <div className="measure-container" style={{ backgroundColor: '#F2F7F7' }}>
                <div className='measure-header' style={{ backgroundColor: '#314d51' }}>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Provvedimenti </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}> Funzionario per la firma del Provvedimento</span>
                    </div>
                </div>

                <div style={{ padding: '24px 24px 32px 24px' }}>
                    <div style={{ display: 'flex', gap: '24px' }}>
                        <div style={{ flex: '0.5' }}>
                            <div className="text-left">
                                <span className="measure-text-bold">Firma del Provvedimento</span>
                            </div>
                            <SelectCustom
                                defaultValue={newMeasureState.newMeasureUpdateRequest.officerMeasureSignatureId}
                                value={newMeasureState.newMeasureUpdateRequest.officerMeasureSignatureId as string}
                                placeholder={'Seleziona funzionario...'}
                                options={usersForSignature}
                                onChange={(value) => dispatch(setNewMeasureUpdateOfficerMeasureSignatureId(value))}
                            />
                        </div>
                        <div style={{ flex: '0.5' }}>
                            <div className="text-left">
                                <span className="measure-text-bold">Mezzo di Partenza</span>
                            </div>
                            <SelectCustom
                                value={newMeasureState.newMeasureUpdateRequest.departureChannel}
                                placeholder={'Seleziona mezzo...'}
                                options={DepartureChannelOptions}
                                onChange={(value) => dispatch(setNewMeasureUpdateDepartureChannel(value))}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="measure-container" style={{ backgroundColor: '#F2F7F7' }}>
                <div className='measure-header' style={{ backgroundColor: '#314d51' }}>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Provvedimenti </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}> Gestione Scheda di calcolo</span>
                    </div>
                </div>

                <div style={{ padding: '24px 24px 32px 24px' }}>
                    <HoursMinutesBox 
                        defaultValue={Number(newMeasureState.newMeasureUpdateRequest.p0035IdTest)}
                        onChange={(value) => {

                        }}
                        />
                </div>
            </div>

            <div className="measure-container" style={{ backgroundColor: '#F2F7F7' }}>
                <div className='measure-header' style={{ backgroundColor: '#314d51' }}>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Provvedimenti </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}> Elenco Destinatari</span>
                    </div>
                </div>

                <div style={{ padding: '24px 24px 32px 24px', position: 'relative' }}>
                    <div className="pb-5">
                        <div className="text-left">
                            <span className="measure-text-bold">Elenco Destinatari</span>
                        </div>
                        <MultiSelectCheckbox
                            placeholder={'Seleziona destinatario...'}
                            value={measureRecipients}
                            option={measureRecipientsOptions}
                            selectAllLabel="Tutti"
                            maxSelectedLabel={0}
                            onChange={(e) => {
                                setMeasureRecipients(e.value)
                                dispatch(setNewMeasureUpdateMeasureRecipientsIds(e.value))
                            }}
                        />
                    </div>

                    <div>
                        <InfoBox
                            title="Destinatari selezionati"
                            color="green"
                            unorderedList={{
                                title: null, items: measureRecipientsOptions.filter(mro => (measureRecipients as unknown as string[])?.includes(mro.value)).map(mr => mr.label)
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}