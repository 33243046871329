import { ChangeEvent, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../../lib/redux/hooks';
import '../style.scss';
import DatePicker, { registerLocale } from "react-datepicker";
import { it } from 'date-fns/locale/it';
import "react-datepicker/dist/react-datepicker.css";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useNavigate } from "react-router-dom";
import { MinusIcon } from "../../../ui/icons/minusIcon";
import React from "react";
import { PlusIconAccordion } from "../../../ui/icons/plusIcon";
import AccordionDetails from "@mui/material/AccordionDetails";
import Input from "../../../ui/molecules/input/Input";
import { SelectCustom } from "../../../ui/molecules/select";
import { Province, Region, formaGiuridica, regioniProvinceMap, regions } from "../../../utils";
import TextArea from "../../../ui/molecules/textArea/TextArea";
import { CustomRadio } from "../../../ui/molecules/radioButton";
import { provinceComuniMap } from "../../../utils/listaComuni";
import { GetCustomersById, setAddressAnagrafica, setCapAnagrafica, setCodContabileAnagrafica, setCodDestinatarioAnagrafica, setComuneAnagrafica, setCoordBancarieAnagrafica, setCountryRegion, setCuaaAnagrafica, setEndingDate, setEntryOdc, setEntryReason, setExitOdc, setExitReason, setFormaGiuridicaAnagrafica, setNormCustomer, setPIvaAnagrafica, setProvinciaAnagrafica, setRagSocialeAnagrafica, setRegioneAnagrafica, setRpAddressAnagrafica, setRpCapAnagrafica, setRpComuneAnagrafica, setRpProvinciaAnagrafica, setRpRegioneAnagrafica, setStartingDate } from "../slice";
import { format } from "date-fns";
import { CalendarIcon } from "../../../ui/icons/calendar";
import { SpinnerComponent } from "../../../lib/spinner";
import { Calendar } from "../../../ui/molecules/calendar";
import { LogoSpinnerComponent } from "../../../lib/spinner/spinnerLogo";
import { GetAllOdc, resetGetAllOdcStatus } from "../../odc/slice";

registerLocale('it', it)

type OptionType = {
    value: string;
    label: string;
};

export function BoxAnagraficaEdit() {


    const clientiState = useAppSelector(state => state.clienti)
    const odcState = useAppSelector(state => state.odc)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [expanded, setExpanded] = React.useState<string | false>("panel1");
    const handleChangeAccordion =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };
    const [dateActivate, setDataActivate] = useState<Date | undefined>(undefined)
    const [dateDeActivate, setDataDeActivate] = useState<Date | undefined>(undefined)
    const [provinceOptions, setProvinceOptions] = useState<OptionType[]>([]);
    const [rpProvinceOptions, setRpProvinceOptions] = useState<OptionType[]>([]);
    const [selectedRegion, setSelectedRegion] = useState<OptionType | null>(null);
    const [comuneOptions, setComuneOptions] = useState<OptionType[]>([]);
    const [rpComuneOptions, setRpComuneOptions] = useState<OptionType[]>([]);
    const [selectedComune, setSelectedComune] = useState<OptionType | null>(null);
    const [selectedProvince, setSelectedProvince] = useState<OptionType | null>(null);
    const [showCustomer, setCustomer] = useState<boolean>(false);
    const [odc, setOdc] = useState<OptionType[]>([])
    // const [primaNotifica, setPrimaNotifica] = useState<string | null>(null);
    // const [subentro, setSubentro] = useState<string | null>(null);
    // const [cambioOdc, setCambioOdc] = useState<string | null>(null);

    //rp
    const [selectedRpRegion, setSelectedRpRegion] = useState<OptionType | null>(null);
    const [selectedRpComune, setSelectedRpComune] = useState<OptionType | null>(null);
    const [selectedRpProvince, setSelectedRpProvince] = useState<OptionType | null>(null);

    const regionOptions: OptionType[] = regions.map((region: Region) => ({
        value: region.name,
        label: region.name
    }));

    useEffect(() => {
        dispatch(GetAllOdc({
            itemsPerPage: 0,
            page: 0
        }))
    }, [])

    useEffect(() => {
        if(odcState.getAllOdcStatus === 'successfully' &&
            odcState.getAllOdcResponse !== undefined
        ) {
            dispatch(resetGetAllOdcStatus())
            const data = odcState.getAllOdcResponse.data.map(odc => {
                return {
                    value: odc.id,
                    label: odc.companyName
                } as OptionType
            })
            setOdc(data)
        }
    }, [odcState.getAllOdcStatus])


    useEffect(() => {
        if (selectedRegion) {
            const foundRegion = regions.find((region: Region) => region.name === selectedRegion.toString());
            if (foundRegion) {
                const newProvinceOptions = foundRegion.provinces.map((province: Province) => ({
                    value: province.code,
                    label: province.name
                }));
                setProvinceOptions(newProvinceOptions);
            } else {
                setProvinceOptions([]);
            }
        } else {
            setProvinceOptions([]);
        }
    }, [selectedRegion]);

    useEffect(() => {
        if (selectedProvince) {
            const comuni = provinceComuniMap.get(selectedProvince.toString()) || [];
            const newComuneOptions = comuni.map((comune: string) => ({
                value: comune,
                label: comune
            }));
            setComuneOptions(newComuneOptions);
        } else {
            setComuneOptions([]);
        }
    }, [selectedProvince]);

    //regioni, province, comuni rp
    useEffect(() => {
        if (selectedRpRegion) {
            const foundRegion = regions.find((region: Region) => region.name === selectedRpRegion.toString());
            if (foundRegion) {
                const newProvinceOptions = foundRegion.provinces.map((province: Province) => ({
                    value: province.code,
                    label: province.name
                }));
                setRpProvinceOptions(newProvinceOptions);
            } else {
                setRpProvinceOptions([]);
            }
        } else {
            setRpProvinceOptions([]);
        }
    }, [selectedRpRegion]);

    useEffect(() => {
        if (selectedRpProvince) {
            const comuni = provinceComuniMap.get(selectedRpProvince.toString()) || [];
            const newComuneOptions = comuni.map((comune: string) => ({
                value: comune,
                label: comune
            }));
            setRpComuneOptions(newComuneOptions);
        } else {
            setRpComuneOptions([]);
        }
    }, [selectedRpProvince]);



    const handleRegionChange = (selectedOption: OptionType | null) => {
        setSelectedRegion(selectedOption);
        setSelectedProvince(null); // Reset province when region changes
        setSelectedComune(null);
    };

    const handleRpRegionChange = (selectedOption: OptionType | null) => {
        setSelectedRpRegion(selectedOption);
        setSelectedRpProvince(null); // Reset province when region changes
        setSelectedRpComune(null);
    };

    const handleRpProvinceChange = (selectedOption: OptionType | null) => {
        setSelectedRpProvince(selectedOption);
        setSelectedRpComune(null);
    };

    const handleProvinceChange = (selectedOption: OptionType | null) => {
        setSelectedProvince(selectedOption);
        setSelectedComune(null);
    };

    const handleComuneChange = (selectedOption: OptionType | null) => {
        setSelectedComune(selectedOption);
    };

    //radio 
    const handleRadioChange = (value: string) => {
        if (value === 'SOSTITUZIONE') {
            dispatch(setEntryReason(value));

        }
        else if (value === 'PRIMA_NOTIFICA') {
            dispatch(setEntryReason(value));

        }
        else if (value === 'CAMBIO_ODC_INGRESSO') {
            dispatch(setEntryReason(value));

        }
        //gestione uscita
        else if (value === 'RECESSO') {
            dispatch(setExitReason(value));

        }
        else if (value === 'CAMBIO_ODC_USCITA') {

            dispatch(setExitReason(value));

        }
        else if (value === 'CAMBIO_OPERATORE') {
            dispatch(setExitReason(value));

        }
        else if (value === 'ESCLUSIONE') {
            dispatch(setExitReason(value));

        }
    };

    useEffect(() => {
        if (clientiState.getCustomersByIdResponseStatus === 'successfully') {
            if (clientiState.getCustomersByIdResponse?.countryRegion) {
                const foundRegion = regions.find((region: Region) => region.name === clientiState.getCustomersByIdResponse?.countryRegion.toString());
                if (foundRegion) {

                    const newProvinceOptions = foundRegion.provinces.map((province: Province) => ({
                        value: province.code,
                        label: province.name
                    }));

                    setProvinceOptions(newProvinceOptions);
                } else {
                    setProvinceOptions([]);
                }
            } else {
                setProvinceOptions([]);
            }

            if (clientiState.getCustomersByIdResponse?.province) {
                const comuni = provinceComuniMap.get(clientiState.getCustomersByIdResponse?.province.toString()) || [];
                const newComuneOptions = comuni.map((comune: string) => ({
                    value: comune,
                    label: comune
                }));
                setComuneOptions(newComuneOptions);
            } else {
                setComuneOptions([]);
            }

            if (clientiState.getCustomersByIdResponse?.rpCountryRegion) {
                const foundRegion = regions.find((region: Region) => region.name === clientiState.getCustomersByIdResponse?.rpCountryRegion.toString());
                if (foundRegion) {
                    const newProvinceOptions = foundRegion.provinces.map((province: Province) => ({
                        value: province.code,
                        label: province.name
                    }));
                    setRpProvinceOptions(newProvinceOptions);
                } else {
                    setRpProvinceOptions([]);
                }
            } else {
                setRpProvinceOptions([]);
            }

            if (clientiState.getCustomersByIdResponse?.rpProvince) {
                const comuni = provinceComuniMap.get(clientiState.getCustomersByIdResponse?.rpProvince.toString()) || [];
                const newComuneOptions = comuni.map((comune: string) => ({
                    value: comune,
                    label: comune
                }));
                setRpComuneOptions(newComuneOptions);
            } else {
                setRpComuneOptions([]);
            }

            if (clientiState.getCustomersByIdResponse !== undefined) {
                dispatch(setNormCustomer(clientiState.getCustomersByIdResponse.norm))
                dispatch(setRagSocialeAnagrafica(clientiState.getCustomersByIdResponse.companyName))
                dispatch(setCuaaAnagrafica(clientiState.getCustomersByIdResponse.cuaa))
                dispatch(setPIvaAnagrafica(clientiState.getCustomersByIdResponse.vat))
                dispatch(setFormaGiuridicaAnagrafica(clientiState.getCustomersByIdResponse.legalForm))
                dispatch(setCapAnagrafica(clientiState.getCustomersByIdResponse.zipCode))
                dispatch(setAddressAnagrafica(clientiState.getCustomersByIdResponse.address))
                dispatch(setComuneAnagrafica(clientiState.getCustomersByIdResponse.city))
                dispatch(setRegioneAnagrafica(clientiState.getCustomersByIdResponse.countryRegion))
                dispatch(setProvinciaAnagrafica(clientiState.getCustomersByIdResponse.province))
                dispatch(setRpAddressAnagrafica(clientiState.getCustomersByIdResponse.rpAddress))
                dispatch(setRpCapAnagrafica(clientiState.getCustomersByIdResponse.rpZipCode))
                dispatch(setRpComuneAnagrafica(clientiState.getCustomersByIdResponse.rpCity))
                dispatch(setRpProvinciaAnagrafica(clientiState.getCustomersByIdResponse.rpProvince))
                dispatch(setRpRegioneAnagrafica(clientiState.getCustomersByIdResponse.rpCountryRegion))
                dispatch(setCodContabileAnagrafica(clientiState.getCustomersByIdResponse.adresseeCode))
                dispatch(setCodDestinatarioAnagrafica(clientiState.getCustomersByIdResponse.accountingCode))
                dispatch(setCoordBancarieAnagrafica(clientiState.getCustomersByIdResponse.bankDetails))
                dispatch(setStartingDate(clientiState.getCustomersByIdResponse.startingDate))
                dispatch(setEndingDate(clientiState.getCustomersByIdResponse.endingDate))
                dispatch(setEntryReason(clientiState.getCustomersByIdResponse.entryReason))
                dispatch(setExitReason(clientiState.getCustomersByIdResponse.exitReason))
                setCustomer(true)
            }
        }
    }, [clientiState.getCustomersByIdResponseStatus]);

    if (!showCustomer) {
        return (
            <LogoSpinnerComponent fullScreen />
        )
    }
    return (

        <div style={{ padding: '24px' }} id="boxAnagrafica">
            <div>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')} defaultExpanded>
                    <AccordionSummary
                        expandIcon={expanded === 'panel1' ? <MinusIcon /> : <PlusIconAccordion />}
                        aria-controls="panel1-content"
                        className="rounded-xl headingAccordion"
                        id="panel1-header"
                    >
                        <div><span className="text-uppercase text-white label-input">Anagrafica</span></div>
                    </AccordionSummary>

                    <AccordionDetails className="bg-brandPrimary-100" style={{ padding: '24px' }}>
                        <div className="bg-brandPrimary-100">
                            <div className="formAnagrafica" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                                <div style={{ flex: '1' }}>
                                    <Input
                                        label={'Ragione Sociale'}
                                        placeholder="Ragione Sociale"
                                        supportingText={clientiState.createCustomerErrors.companyName ? "Inserire la ragione sociale." : undefined} error={clientiState.createCustomerErrors.companyName}
                                        value={clientiState.clienteEditCreateRequest.companyName}
                                        defaultValue={clientiState.clienteEditCreateRequest.companyName}
                                        onChangeText={(text) => dispatch(setRagSocialeAnagrafica(text))}
                                    />
                                </div>

                            </div>
                            <div className="formAnagrafica mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                                <div style={{ flex: '0.5' }}>
                                    <Input
                                        label={'CUAA'}
                                        placeholder="CUAA"
                                        supportingText={clientiState.createCustomerErrors.cuaa ? "Inserire cuaa (11-16 caratteri)." : undefined} error={clientiState.createCustomerErrors.cuaa}
                                        value={clientiState.clienteEditCreateRequest.cuaa}
                                        onChangeText={(text) => dispatch(setCuaaAnagrafica(text))}
                                    />
                                </div>

                                <div style={{ flex: '0.5' }}>
                                    <Input
                                        label={'Partita IVA'}
                                        placeholder="Partita IVA"
                                        supportingText={clientiState.createCustomerErrors.vat ? "Inserire p. iva (11-16 caratteri)." : undefined} error={clientiState.createCustomerErrors.vat}
                                        value={clientiState.clienteEditCreateRequest.vat}
                                        onChangeText={(text) => dispatch(setPIvaAnagrafica(text))}
                                    />
                                </div>

                                <div style={{ flex: '0.5' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                        <span className="input-label">Forma Giuridica</span>
                                    </div>
                                    <SelectCustom
                                        error={clientiState.createCustomerErrors.legalForm}
                                        errorLabel="Scegliere Forma Giuridica"
                                        placeholder={'Forma Giuridica'}
                                        defaultValue={clientiState.clienteEditCreateRequest.legalForm}
                                        options={formaGiuridica}
                                        onChange={(value) => {
                                            dispatch(setFormaGiuridicaAnagrafica(value))
                                        }}
                                    />
                                </div>

                            </div>
                        </div>


                    </AccordionDetails>
                </Accordion>
            </div>
            <div className="mt-6">
                <Accordion expanded={expanded === 'panel2'} onChange={handleChangeAccordion('panel2')}>
                    <AccordionSummary
                        expandIcon={expanded === 'panel2' ? <MinusIcon /> : <PlusIconAccordion />}
                        aria-controls="panel2-content"
                        className="rounded-xl headingAccordion"
                        id="panel2-header"
                    >
                        <div>
                            <span className="text-uppercase text-white label-input">
                                Sede Legale
                            </span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="bg-brandPrimary-100" style={{ padding: '24px' }}>
                        <div className="formSedeLegale" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                            <div style={{ flex: '1 1 auto' }}>
                                <Input
                                    label={'Indirizzo'}
                                    placeholder="Indirizzo"
                                    supportingText={clientiState.createCustomerErrors.address ? "Inserire un indirizzo." : undefined} error={clientiState.createCustomerErrors.address}
                                    value={clientiState.clienteEditCreateRequest.address}
                                    defaultValue={clientiState.clienteEditCreateRequest.address}
                                    onChangeText={(text) => dispatch(setAddressAnagrafica(text))}
                                />

                            </div>

                        </div>

                        <div className="formSedeLegale mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                            <div style={{ flex: '0.5' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                    <span className="input-label">Regione</span>
                                </div>
                                <SelectCustom
                                    defaultValue={clientiState.clienteEditCreateRequest.countryRegion}
                                    placeholder={'Regione'}
                                    options={regionOptions}
                                    onChange={(value) => { handleRegionChange(value as unknown as OptionType | null); dispatch(setRegioneAnagrafica(value)) }}
                                />
                            </div>
                            <div style={{ flex: '0.5' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                    <span className="input-label">Provincia</span>
                                </div>
                                <SelectCustom
                                    placeholder={'Provincia'}
                                    defaultValue={clientiState.clienteEditCreateRequest.province}
                                    options={provinceOptions}
                                    onChange={(value) => { handleProvinceChange(value as unknown as OptionType | null); dispatch(setProvinciaAnagrafica(value)) }}
                                />
                            </div>

                        </div>

                        <div className="formSedeLegale mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                            <div style={{ flex: '0.5' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                    <span className="input-label">Comune</span>
                                </div>
                                <SelectCustom
                                    placeholder={'Comune'}
                                    options={comuneOptions}
                                    defaultValue={clientiState.clienteEditCreateRequest.city}
                                    onChange={(value) => {
                                        dispatch(setComuneAnagrafica(value))
                                    }}
                                />
                            </div>
                            <div style={{ flex: '0.5' }}>
                                <Input
                                    label={'CAP'}
                                    placeholder="CAP"
                                    value={clientiState.clienteEditCreateRequest.zipCode}
                                    defaultValue={clientiState.clienteEditCreateRequest.zipCode}
                                    onChangeText={(text) => dispatch(setCapAnagrafica(text))}
                                />
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>

            <div className="mt-6">
                <Accordion expanded={expanded === 'panel3'} onChange={handleChangeAccordion('panel3')}>
                    <AccordionSummary
                        expandIcon={expanded === 'panel3' ? <MinusIcon /> : <PlusIconAccordion />}
                        aria-controls="panel3-content"
                        className="rounded-xl headingAccordion"
                        id="panel3-header"
                    >
                        <div>
                            <span className="text-uppercase text-white label-input">
                                Recapito Postale
                            </span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="bg-brandPrimary-100" style={{ padding: '24px' }}>

                        <div className="formRecapitoPostale" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                            <div style={{ flex: '1 1 auto' }}>
                                <Input
                                    label={'Indirizzo'}
                                    placeholder="Indirizzo"
                                    onChangeText={(text) => dispatch(setRpAddressAnagrafica(text))}
                                    value={clientiState.clienteEditCreateRequest.rpAddress}
                                />
                            </div>

                        </div>

                        <div className="formRecapitoPostale mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                            <div style={{ flex: '0.5' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                    <span className="input-label">Regione</span>
                                </div>
                                <SelectCustom
                                    placeholder={'Regione'}
                                    options={regionOptions}
                                    defaultValue={clientiState.clienteEditCreateRequest.rpCountryRegion}
                                    onChange={(value) => { handleRpRegionChange(value as unknown as OptionType | null); dispatch(setRpRegioneAnagrafica(value)) }}
                                />
                            </div>
                            <div style={{ flex: '0.5' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                    <span className="input-label">Provincia</span>
                                </div>
                                <SelectCustom
                                    placeholder={'Provincia'}
                                    options={rpProvinceOptions}
                                    defaultValue={clientiState.clienteEditCreateRequest.rpProvince}
                                    onChange={(value) => { handleRpProvinceChange(value as unknown as OptionType | null); dispatch(setRpProvinciaAnagrafica(value)) }}
                                />
                            </div>


                        </div>

                        <div className="formRecapitoPostale mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                            <div style={{ flex: '0.5' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                    <span className="input-label">Comune</span>
                                </div>
                                <SelectCustom
                                    placeholder={'Comune'}
                                    options={rpComuneOptions}
                                    defaultValue={clientiState.clienteEditCreateRequest.rpCity}
                                    onChange={(value) => {
                                        dispatch(setRpComuneAnagrafica(value))
                                    }}
                                />
                            </div>
                            <div style={{ flex: '0.5' }}>
                                <Input
                                    label={'CAP'}
                                    placeholder="CAP"
                                    value={clientiState.clienteEditCreateRequest.rpZipCode}
                                    defaultValue={clientiState.clienteEditCreateRequest.rpZipCode}
                                    onChangeText={(text) => dispatch(setRpCapAnagrafica(text))}
                                />
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>

            <div className="mt-6">
                <Accordion expanded={expanded === 'panel4'} onChange={handleChangeAccordion('panel4')}>
                    <AccordionSummary
                        expandIcon={expanded === 'panel4' ? <MinusIcon /> : <PlusIconAccordion />}
                        aria-controls="panel4-content"
                        className="rounded-xl headingAccordion"
                        id="panel4-header"
                    >
                        <div>
                            <span className="text-uppercase text-white label-input">
                                Dati Contabili
                            </span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="bg-brandPrimary-100" style={{ padding: '24px' }}>
                        <div style={{ display: 'flex', gap: '24px' }}>

                            <div style={{ flex: 0.5 }}>
                                <Input
                                    label={'Codice Destinatario'}
                                    placeholder="Codice Destinatario"
                                    defaultValue={clientiState.clienteEditCreateRequest.adresseeCode}
                                    onChangeText={(text) => dispatch(setCodDestinatarioAnagrafica(text))}
                                />
                            </div>
                            <div style={{ flex: 0.5 }}>
                                <Input label={'Codice Contabile / Partitario'}
                                    placeholder="Codice Contabile / Partitario"
                                    defaultValue={clientiState.clienteEditCreateRequest.accountingCode}
                                    onChangeText={(text) => dispatch(setCodContabileAnagrafica(text))}
                                />
                            </div>

                        </div>

                        <div className="formSedeLegale mt-6" >
                            <div>
                                <TextArea
                                    label="Coordinate Bancarie"
                                    onChangeText={(text) => dispatch(setCoordBancarieAnagrafica(text))}
                                />
                            </div>

                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>

            <div className="mt-6">
                <Accordion expanded={expanded === 'panel5'} onChange={handleChangeAccordion('panel5')}>
                    <AccordionSummary
                        expandIcon={expanded === 'panel5' ? <MinusIcon /> : <PlusIconAccordion />}
                        aria-controls="panel5-content"
                        className="rounded-xl headingAccordion"
                        id="panel5-header"
                    >
                        <div>
                            <span className="text-uppercase text-white label-input">
                                Ingresso nel sistema di controllo
                            </span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="bg-brandPrimary-100" style={{ padding: '24px' }}>
                        <div className="formInOut" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                            <div style={{ flex: '0.5' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                    <span className="input-label">Data Ingresso</span>
                                </div>
                                <Calendar
                                    selected={clientiState.clienteEditCreateRequest.startingDate}
                                    onChange={(date) => {
                                        dispatch(setStartingDate(format(new Date(date), 'yyyy-MM-dd')))
                                        setDataActivate(date)
                                    }}
                                />
                            </div>


                        </div>

                        <div className="formInOut mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                            <div style={{ display: 'flex', flex: '0.5' }}>
                                <div>
                                    <CustomRadio
                                        options={[{ label: 'Prima Notifica', value: 'PRIMA_NOTIFICA' }]}
                                        selectedValue={clientiState.clienteEditCreateRequest.entryReason}
                                        onChange={
                                            handleRadioChange
                                        }
                                        id={'prima_notifica'}
                                        name={'prima_notifica'}
                                    />
                                </div>
                            </div>


                        </div>

                        <div className="formInOut mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                            <div style={{ display: 'flex', flex: '0.5' }}>
                                <div style={{ flex: '0.5 1 0%', textAlign: 'left' }}>
                                    <CustomRadio
                                        options={[{ label: 'Cambio Odc', value: 'CAMBIO_ODC_INGRESSO' }]}
                                        selectedValue={clientiState.clienteEditCreateRequest.entryReason}
                                        onChange={handleRadioChange}
                                        id={'CHANGE_ODC_ENTRY'}
                                        name={'CHANGE_ODC_ENTRY'}
                                    />
                                    <div className="mt-6">
                                        <SelectCustom
                                            value={clientiState.clienteEditCreateRequest.entryOdcId}
                                            placeholder={'ODC'}
                                            options={odc}
                                            onChange={(value) => {
                                                dispatch(setEntryOdc(value))
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{ flex: '0.5 1 0%', textAlign: 'left' }} className="pl-5">
                                    <CustomRadio
                                        options={[{ label: 'Subentro', value: 'SOSTITUZIONE' }]}
                                        selectedValue={clientiState.clienteEditCreateRequest.entryReason}
                                        onChange={handleRadioChange}
                                        id={'TAKEOVER'}
                                        name={'TAKEOVER'}
                                    />
                                    <div className="mt-6">
                                        <Input placeholder="Subentro" onChangeText={(text) => console.log(text)} />
                                    </div>
                                </div>
                            </div>

                        </div>


                    </AccordionDetails>
                </Accordion>
            </div>

            <div className="mt-6">
                <Accordion expanded={expanded === 'panel6'} onChange={handleChangeAccordion('panel6')}>
                    <AccordionSummary
                        expandIcon={expanded === 'panel6' ? <MinusIcon /> : <PlusIconAccordion />}
                        aria-controls="panel6-content"
                        className="rounded-xl headingAccordion"
                        id="panel6-header"
                    >
                        <div>
                            <span className="text-uppercase text-white label-input">
                                Uscita nel sistema di controllo
                            </span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails className="bg-brandPrimary-100" style={{ padding: '24px' }}>
                        <div className="formInOut" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                            <div style={{ flex: '0.5' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '6px' }}>
                                    <span className="input-label">Data Uscita</span>
                                </div>
                                <Calendar
                                    selected={clientiState.clienteEditCreateRequest.endingDate}
                                    onChange={(date) => {
                                        dispatch(setEndingDate(format(new Date(date), 'yyyy-MM-dd')))
                                        setDataDeActivate(date)
                                    }}
                                />
                            </div>

                        </div>

                        <div className="formInOut mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                            <div style={{ display: 'flex', flex: '0.5' }}>
                                <div>
                                    <CustomRadio
                                        options={[{ label: 'Recesso', value: 'RECESSO' }]}
                                        selectedValue={clientiState.clienteEditCreateRequest.exitReason}
                                        onChange={handleRadioChange}
                                        id={'recesso'}
                                        name={'recesso'}
                                    />
                                </div>
                                <div>
                                    <CustomRadio
                                        options={[{ label: 'Esclusione', value: 'ESCLUSIONE' }]}
                                        selectedValue={clientiState.clienteEditCreateRequest.exitReason}
                                        onChange={handleRadioChange}
                                        id={'exlusion'}
                                        name={'exlusion'}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="formInOut mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>
                            <div style={{ display: 'flex', flex: '0.5' }}>
                                <div>
                                    <CustomRadio
                                        options={[{ label: 'Cambio Odc', value: 'CAMBIO_ODC_USCITA' }]}
                                        selectedValue={clientiState.clienteEditCreateRequest.exitReason}
                                        onChange={handleRadioChange}
                                        id={'CHANGE_ODC_EXIT'}
                                        name={'CHANGE_ODC_EXIT'}
                                    />
                                </div>
                                <div>
                                    <CustomRadio
                                        options={[{ label: 'Passaggio ad altro operatore', value: 'CAMBIO_OPERATORE' }]}
                                        selectedValue={clientiState.clienteEditCreateRequest.exitReason}
                                        onChange={handleRadioChange}
                                        id={'passaggio_altro_operatore'}
                                        name={'passaggio_altro_operatore'}
                                    />
                                </div>
                            </div>

                        </div>

                        <div className="formInOut mt-6" style={{ display: 'flex', flex: 'wrap', gap: '24px' }}>

                            <div style={{ display: 'flex', flex: '0.5', flexWrap: 'wrap', gap: '24px' }}>
                                <div style={{ flex: '1 1 auto' }}>
                                    <SelectCustom
                                        value={clientiState.clienteEditCreateRequest.exitOdcId}
                                        placeholder={'ODC'}
                                        options={odc}
                                        onChange={(value) => {
                                            dispatch(setExitOdc(value))
                                        }}
                                    />
                                </div>
                                <div style={{ flex: '1 1 auto' }}>
                                    <Input disabled placeholder="Subentro" onChangeText={(text) => console.log(text)} />
                                </div>
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>


        </div>
    )
}