import { AllAreasDTO, AllAreasResponseDTO, NonComplianceCodeByIdDTO, NonComplianceCodResponseDTO } from "./dto";
import { NcServiceImpl } from "./serviceImpl";

export interface NcService {
    GetAllNc(): Promise<NonComplianceCodResponseDTO>
    GetAllAreas(): Promise<AllAreasResponseDTO>
    GetAllNcByArea(areaCode: string): Promise<NonComplianceCodeByIdDTO[]>
    GetNcById(id: string): Promise<NonComplianceCodeByIdDTO>
}

export function NewNcService(): NcService {
    return new NcServiceImpl()
}