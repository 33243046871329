import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { createWaiverToDTO, GetAllWaiverToFiltersDTO, GetAllWaiverToResponse, updateWaiverToDTO, WaiverToErrorsDTO } from "./dto"
import { NewWaiverToCodexService } from "./service"
import { PromiseStatuses } from "../../../../../utils"

interface waiverToCodexState {
    selectedTab: number,
    // RESPONSE
    getAllWaiverToResponse?: GetAllWaiverToResponse

    //CREATE
    createWaiverToCodex?: createWaiverToDTO

    //UPDATE
    createUpdateWaiverToCodex: updateWaiverToDTO

    // FILTERS
    getAllWaiverToFilters: GetAllWaiverToFiltersDTO

    //ERRORS
    waiverToErrors: WaiverToErrorsDTO

    //STATUS
    GetAllWaiverToCodexStatus: PromiseStatuses
    CreateWaiverToCodexStatus: PromiseStatuses
    UpdateWaiverToCodexStatus: PromiseStatuses
    waiverToValidationStatus: PromiseStatuses
    downloadExcelStatus: PromiseStatuses
    downloadDocStatus: PromiseStatuses
}

const initialState: waiverToCodexState = {
    selectedTab: 0,
    getAllWaiverToFilters: {
        page: 0,
        itemsPerPage: 0
    },
    waiverToErrors: {
        odcReference: false
    },
    createUpdateWaiverToCodex: {
        customerId: '',
        entryProtocolId: '',
        odcReferenceId: '',
    },
    GetAllWaiverToCodexStatus: 'idle',
    CreateWaiverToCodexStatus: 'idle',
    UpdateWaiverToCodexStatus: 'idle',
    waiverToValidationStatus: 'idle',
    downloadExcelStatus: 'idle',
    downloadDocStatus: 'idle',
}

export const GetAllWaiverToCodex = createAsyncThunk(
    'Waiver/GetAllWaiverToCodex',
    async (filters: GetAllWaiverToFiltersDTO, thunkApi): Promise<GetAllWaiverToResponse> => {
        const WaiverToCodexService = NewWaiverToCodexService();

        return WaiverToCodexService.GetAllWaiverToCodex(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const CreateWaiverToCodex = createAsyncThunk(
    'Waiver/CreateWaiverToCodex',
    async (data: createWaiverToDTO, thunkApi): Promise<any> => {
        const WaiverToCodexService = NewWaiverToCodexService();

        return WaiverToCodexService.CreateWaiverToCodex(data).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const UpdateWaiverToCodex = createAsyncThunk(
    'Waiver/UpdateWaiverToCodex',
    async (request: { data: updateWaiverToDTO, id: string }, thunkApi): Promise<any> => {
        const WaiverToCodexService = NewWaiverToCodexService();

        return WaiverToCodexService.UpdateWaiverToCodex(request.data, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const WaiverToCodexValidation = createAsyncThunk(
    'waiver/WaiverToCodex/validation',
    async (request: updateWaiverToDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetWaiverToErrors())
        if(request.odcReferenceId === '') {
            thunkApi.dispatch(setValidateOdcReference(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)

export const GetWaivertoExcel = createAsyncThunk(
    'Waiver/WaiverToCodex',
    async (filters: GetAllWaiverToFiltersDTO, thunkApi): Promise<any> => {
        const WaiverToCodexService = NewWaiverToCodexService()
        return WaiverToCodexService.GetWaiverToExcel(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const DownloadWaiverToCodexDoc = createAsyncThunk(
    'Waiver/DownloadWaiverToCodexDoc',
    async (id: string, thunkApi): Promise<void> => {
        const WaiverToCodexService = NewWaiverToCodexService()

        return WaiverToCodexService.DownloadWaiverToCodexDoc(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

const waiverToCodexSlice = createSlice({
    name: 'WaiverToCodexSlice/slice',
    initialState,
    reducers: {
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload
        },
        setEntryProtocol: (state, action) => {
            state.createUpdateWaiverToCodex.entryProtocolId = action.payload
        },
        setIdWaiver: (state, action) => {
            state.createUpdateWaiverToCodex.id = action.payload
        },
        setCustomerId: (state, action) => {
            state.createUpdateWaiverToCodex.customerId = action.payload
        },
        setOdc: (state, action) => {
            state.createUpdateWaiverToCodex.odcReferenceId = action.payload
        },
        setExpirationDate: (state, action) => {
            state.createUpdateWaiverToCodex.expirationDate = action.payload
        },
        setCompletePassing: (state, action) => {
            state.createUpdateWaiverToCodex.completePassing = action.payload
        },
        setFieldsPassing: (state, action) => {
            state.createUpdateWaiverToCodex.fieldsPassing = action.payload
        },
        setBreakoutDate: (state, action) => {
            console.log('action ', action.payload)
            state.createUpdateWaiverToCodex.breakoutDate = action.payload
        },
        setNote: (state, action) => {
            state.createUpdateWaiverToCodex.note = action.payload
        },
        setDeniedDepartureProtocolId: (state, action) => {
            state.createUpdateWaiverToCodex.deniedDepartureProtocolId = action.payload
        },
        setAllowedDepartureProtocolId: (state, action) => {
            state.createUpdateWaiverToCodex.allowedDepartureProtocolId = action.payload
        },
        setSendingDossier: (state, action) => {
            state.createUpdateWaiverToCodex.sendingDossier = action.payload
        },
        setSenderOrReceiver: (state, action) => {
            state.createUpdateWaiverToCodex.senderOrReceiver = action.payload
        },
        setProtocolDate: (state, action) => {
            state.createUpdateWaiverToCodex.protocolDate = action.payload
        },
        setNotificationChangeProtocolId: (state, action) => {
            state.createUpdateWaiverToCodex.notificationChangeProtocolId = action.payload
        },
        setSubmissionNotificationOdcDate: (state, action) => {
            state.createUpdateWaiverToCodex.submissionNotificationOdcDate = action.payload
        },
        //filters 
        setYearFilter: (state, action) => {
            state.getAllWaiverToFilters.year = action.payload
        },
        setStatusFilter: (state, action) => {
            state.getAllWaiverToFilters.status = action.payload
        },

        //ERRORS
        setValidateOdcReference: (state, action) => {
            state.waiverToErrors.odcReference = action.payload
        },

        //RESET
        resetWaiverFilters: (state) => {
            state.getAllWaiverToFilters = {}
        },

        resetWaiverUpdateStatus: (state) => {
            state.UpdateWaiverToCodexStatus = 'idle'
        },

        resetWaiverCreateStatus: (state) => {
            state.CreateWaiverToCodexStatus = 'idle'
        },

        resetWaiverRequest: (state) => {
            state.createUpdateWaiverToCodex = {
                customerId: '',
                entryProtocolId: ''
            }
        },

        resetWaiverToErrors: (state) => {
            state.waiverToErrors = {
                odcReference: false,
            }
        },

    },
    extraReducers(builder) {
        builder

            //CREATE
            .addCase(CreateWaiverToCodex.pending, (state) => {
                state.CreateWaiverToCodexStatus = 'loading'
            })
            .addCase(CreateWaiverToCodex.fulfilled, (state, action) => {
                state.CreateWaiverToCodexStatus = 'successfully'
            })
            .addCase(CreateWaiverToCodex.rejected, (state) => {
                state.CreateWaiverToCodexStatus = 'failed'
            })

            //UPDATE
            .addCase(UpdateWaiverToCodex.pending, (state) => {
                state.UpdateWaiverToCodexStatus = 'loading'
            })
            .addCase(UpdateWaiverToCodex.fulfilled, (state, action) => {
                state.UpdateWaiverToCodexStatus = 'successfully'
            })
            .addCase(UpdateWaiverToCodex.rejected, (state) => {
                state.UpdateWaiverToCodexStatus = 'failed'
            })

            // GET ALL
            .addCase(GetAllWaiverToCodex.pending, (state) => {
                state.GetAllWaiverToCodexStatus = 'loading'
            })
            .addCase(GetAllWaiverToCodex.fulfilled, (state, action) => {
                state.GetAllWaiverToCodexStatus = 'successfully'
                state.getAllWaiverToResponse = action.payload
            })
            .addCase(GetAllWaiverToCodex.rejected, (state) => {
                state.GetAllWaiverToCodexStatus = 'failed'
            })

            // VALIDATION (Obj/Att)
            .addCase(WaiverToCodexValidation.pending, (state) => {
                state.waiverToValidationStatus = 'loading'
            })
            .addCase(WaiverToCodexValidation.fulfilled, (state) => {
                state.waiverToValidationStatus = 'successfully'
            })
            .addCase(WaiverToCodexValidation.rejected, (state) => {
                state.waiverToValidationStatus = 'failed'
            })

            // download Excel
            .addCase(GetWaivertoExcel.pending, (state) => {
                state.downloadExcelStatus = 'loading'
            })
            .addCase(GetWaivertoExcel.fulfilled, (state) => {
                state.downloadExcelStatus = 'successfully'
            })
            .addCase(GetWaivertoExcel.rejected, (state) => {
                state.downloadExcelStatus = 'failed'
            })

            // download word
            .addCase(DownloadWaiverToCodexDoc.pending, (state) => {
                state.downloadDocStatus = 'loading'
            })
            .addCase(DownloadWaiverToCodexDoc.fulfilled, (state) => {
                state.downloadDocStatus = 'successfully'
            })
            .addCase(DownloadWaiverToCodexDoc.rejected, (state) => {
                state.downloadDocStatus = 'failed'
            })

            
    }
})

export const {
    setSelectedTab,
    setAllowedDepartureProtocolId,
    setBreakoutDate,
    setCompletePassing,
    setCustomerId,
    setDeniedDepartureProtocolId,
    setEntryProtocol,
    setFieldsPassing,
    setNote,
    setNotificationChangeProtocolId,
    setOdc,
    setSendingDossier,
    setSubmissionNotificationOdcDate,
    resetWaiverFilters,
    setStatusFilter,
    setYearFilter,
    setSenderOrReceiver,
    setProtocolDate,
    setExpirationDate,
    //errors
    setValidateOdcReference,
    //reset
    resetWaiverUpdateStatus,
    resetWaiverCreateStatus,
    resetWaiverRequest,
    resetWaiverToErrors,
    setIdWaiver
} = waiverToCodexSlice.actions

export default waiverToCodexSlice.reducer