import { useNavigate } from "react-router-dom";
import Button from "../../../ui/molecules/button";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { useEffect, useState } from "react";
import { resetGetAllOdcStatus } from "../../odc/slice";
import { formatterDate, OptionType } from "../../../utils";
import { Banner } from "../../../ui/organisms/banner";
import { RoundedPlusIcon } from "../../../ui/icons/RoundedPlusIcon";
import { GetAllCustomerNewMeasure, GetAllCustomerOldMeasure, setDescriptionsAreaCode, setMeasureVersion, setOtherOdcMeasureDescription, setOtherOdcMeasureFiltersCodexReleased, setOtherOdcMeasureFiltersCustomerId, setOtherOdcMeasureIsReleased, setOtherOdcMeasureNonComplianceDescription, setOtherOdcMeasureNonComplianceOfficerId, setOtherOdcMeasuresCustomerId, setOtherOdcMeasuresDate, setOtherOdcMeasureSelected, setOtherOdcMeasuresNonComplianceCode, setOtherOdcMeasuresOdc, setOtherOdcNewMeasureDescription, setOtherOdcNewMeasureFiltersCodexReleased, setOtherOdcNewMeasureFiltersCustomerId, setOtherOdcNewMeasureIsReleased, setOtherOdcNewMeasureNonComplianceDescription, setOtherOdcNewMeasureNonComplianceOfficerId, setOtherOdcNewMeasuresCustomerId, setOtherOdcNewMeasuresDate, setOtherOdcNewMeasuresNewComplianceCode, setOtherOdcNewMeasuresNonComplianceVersion, setOtherOdcNewMeasuresOdc } from "./dataService/slice";
import { EditIcon } from "../../../ui/icons/edit";
import { OtherOdcMeasuresCreationUpdateDTO, OtherOdcMeasuresResponseDataDTO } from "./dataService/dto";

export function CustomerOtherOdcList() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const OtherOdcMeasuresState = useAppSelector(state => state.OtherOdcMeasuresState)
    const odcState = useAppSelector(state => state.odc)
    const [odc, setOdc] = useState<OptionType[]>([])
    const sessionState = useAppSelector(state => state.session)

    useEffect(() => {
        dispatch(setOtherOdcNewMeasureFiltersCodexReleased(false))
        dispatch(setOtherOdcMeasureFiltersCodexReleased(false))
        dispatch(setOtherOdcMeasureFiltersCustomerId(sessionState.selected.customerId))
        dispatch(setOtherOdcNewMeasureFiltersCustomerId(sessionState.selected.customerId))
    })
    useEffect(() => {
        if (sessionState.selected.customerId) {
            dispatch(GetAllCustomerOldMeasure({ customerId: sessionState.selected.customerId, isCodexReleased: false }))
            dispatch(GetAllCustomerNewMeasure({ customerId: sessionState.selected.customerId, isCodexReleased: false }))
        }


    }, [])

    useEffect(() => {
        if (odcState.getAllOdcStatus === 'successfully' &&
            odcState.getAllOdcResponse !== undefined
        ) {
            dispatch(resetGetAllOdcStatus())
            const data = odcState.getAllOdcResponse.data.map(odc => {
                return {
                    value: odc.id,
                    label: odc.code + ' - ' + odc.companyName
                } as OptionType
            })
            setOdc(data)
        }
    }, [odcState.getAllOdcStatus])

    const onEditHandleOld = (measure: OtherOdcMeasuresResponseDataDTO) => {
        dispatch(setMeasureVersion('old'))
        dispatch(setOtherOdcMeasureSelected(measure.id))
        dispatch(setOtherOdcMeasureIsReleased(measure.isCodexRealesed))
        dispatch(setOtherOdcMeasuresDate(measure.measureDate))
        dispatch(setOtherOdcMeasuresOdc(measure.odcId))
        dispatch(setOtherOdcMeasuresCustomerId(measure.customerId))
        dispatch(setOtherOdcMeasuresNonComplianceCode(measure.nonComplianceDTO.nonComplianceCode))
        dispatch(setOtherOdcMeasureNonComplianceDescription(measure.nonComplianceDescription))
        dispatch(setOtherOdcMeasureDescription(measure.measureDescription))
        dispatch(setOtherOdcMeasureNonComplianceOfficerId(measure.officerId))

        navigate('/editProvvedimentoAltroOdc')
    }

    const onEditHandleNew = (measure: OtherOdcMeasuresResponseDataDTO) => {
        dispatch(setMeasureVersion('new'))
        dispatch(setOtherOdcNewMeasuresNonComplianceVersion('NEW'))
        dispatch(setOtherOdcMeasureSelected(measure.id))
        dispatch(setOtherOdcNewMeasuresCustomerId(sessionState.selected.customerId))
        dispatch(setOtherOdcNewMeasureIsReleased(false))
        dispatch(setOtherOdcNewMeasureNonComplianceOfficerId(sessionState.selected.officerId))
        dispatch(setOtherOdcNewMeasuresDate(measure.measureDate))
        dispatch(setOtherOdcNewMeasuresOdc(measure.odcId))
        dispatch(setOtherOdcNewMeasuresNewComplianceCode(measure.odcId))
        dispatch(setOtherOdcNewMeasureNonComplianceDescription(measure.nonComplianceDescription))
        dispatch(setOtherOdcNewMeasureDescription(measure.measureDescription))
        dispatch(setDescriptionsAreaCode(measure.newNonComplianceCodeDTO))

        navigate('/editProvvedimentoAltroOdc')
    }

    if (sessionState.selected.customerId) {
        return (
            <div style={{ padding: '24px' }}>
                <div className="pb-5" style={{ display: 'flex', justifyContent: 'flex-end', gap: '24px' }}>
                    <Button
                        variant="solid"
                        disabled={sessionState.selected.customerId === undefined}
                        size="md"
                        color="orange"
                        iconPosition="left"
                        icon={<RoundedPlusIcon />}
                        label="Crea nuovo"
                        onClick={() => navigate('/creaProvvedimentoAltroOdc')}
                    />
                </div>
                <table id="users-table" className="w-[100%]">
                    <thead className="h-[52px] uppercase">
                        <tr>
                            <th style={{ borderTopLeftRadius: '8px' }}>Data Provvedimento</th>
                            <th>OdC</th>
                            <th>Codice N.C.</th>
                            <th>Descrizione Nc</th>
                            <th>Descrizione Provvedimento</th>
                            <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            OtherOdcMeasuresState.OtherOdcMeasureResponse.data !== undefined &&
                            OtherOdcMeasuresState.OtherOdcMeasureResponse.data.map((measure, index) =>
                                <tr
                                    key={index}
                                    style={{
                                        color: '#4D797F',
                                        background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                    }}
                                >
                                    <td>{formatterDate(measure.measureDate)}</td>
                                    <td>{measure.odcInfo}</td>
                                    <td>{measure.nonComplianceDTO.nonComplianceCode}</td>
                                    <td>{measure.nonComplianceDescription}</td>
                                    <td>{measure.measureDescription}</td>
                                    <td>
                                        <div style={{ display: 'flex', gap: '6px' }}>
                                            <button onClick={() => onEditHandleOld(measure)}>
                                                <EditIcon color="orange" size={20} />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                        {
                            OtherOdcMeasuresState.OtherOdcNewMeasureResponse.data &&
                            OtherOdcMeasuresState.OtherOdcNewMeasureResponse.data.map((measure, index) =>
                                <tr
                                    key={index}
                                    style={{
                                        color: '#4D797F',
                                        background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                    }}
                                >
                                    <td>{formatterDate(measure.measureDate)}</td>
                                    <td>{measure.odcInfo}</td>
                                    <td>
                                        {
                                            measure.newNonComplianceCodeDTO &&
                                            <span>{measure.newNonComplianceCodeDTO.nonComplianceCode}</span>
                                        }

                                    </td>
                                    <td>{measure.nonComplianceDescription}</td>
                                    <td>{measure.measureDescription}</td>
                                    <td>
                                        <div style={{ display: 'flex', gap: '6px' }}>
                                            <button onClick={() => onEditHandleNew(measure)}>
                                                <EditIcon color="orange" size={20} />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <div style={{ padding: '24px' }}>
            <Banner
                type="error"
                visible={!sessionState.selected.customerId}
                label={"Devi prima selezionare un cliente per accedere all'elenco"}
                closeAction={function (): void {
                    alert("Devi prima selezionare un cliente per accedere all'elenco")
                }}
            />
        </div>
    )
}