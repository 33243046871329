/* SERVICE TYPE */

export enum ServiceTypeCategoryEnum {
    SERVICE = 'SERVICE',
    ADDITIONAL_EXPENSE = 'ADDITIONAL_EXPENSE'
}

export enum ServiceTypeNormEnum {
    NORMA_BIO = 'NORMA_BIO',
    PRODUZIONE_INTEGRATA = 'PRODUZIONE_INTEGRATA',
    DETERGENZA = 'DETERGENZA',
    GLOBAL_GAP = 'GLOBAL_GAP',
    QUALITA_SICURA = 'QUALITA_SICURA',
    RESIDUO_ZERO = 'RESIDUO_ZERO',
    BIODINAMICA = 'BIODINAMICA',
    NICHEL_FREE = 'NICHEL_FREE'
}

export enum ServiceTypeUnitEnum {
    HOURS = 'HOURS',
    DAY = 'DAY',
    UNIT = 'UNIT'
}

export interface ServiceTypeCreationDTO {
    category?: ServiceTypeCategoryEnum
    code?: string
    description?: string
    note?: string
    unitType?: ServiceTypeUnitEnum
    amountPerUnit?: number
    billingWorksheetId?: string // ONLY IN CREATION
}

export interface ServiceTypeUpdateDTO {
    category?: ServiceTypeCategoryEnum
    code?: string
    description?: string
    note?: string
    unitType?: ServiceTypeUnitEnum
    amountPerUnit?: number
}

export interface ServiceTypeDTO {
    id: string
    createdAt: Date | null
    updatedAt: Date | null
    deletedAt: Date | null
    category: ServiceTypeCategoryEnum
    norm: ServiceTypeNormEnum
    code: string
    year: number
    description: string
    note: string
    unitType: ServiceTypeUnitEnum
    amountPerUnit: number
    serviceIds: string[]
    billingWorksheetId: string
}

export interface GetAllServiceTypeDTO {
    total: number | null
    page: null
    perPage: null
    prev: false
    next: false
    totalPage: null
    data: ServiceTypeDTO[]
}

export interface GetAllServiceTypeFiltersDTO {
    itemsPerPage: number
    page: number
    order?: boolean
    sort?: string
    year?: number
    category?: ServiceTypeCategoryEnum
    code?: string
    norm?: ServiceTypeNormEnum
    billingWorksheetId?: string
}

/* BILLING WORKSHEET */

export interface BillingWorksheetCreationDTO {
    norm?: ServiceTypeNormEnum
    year?: number
    yearToCloneServiceTypes?: number
}

export interface BillingWorksheetUpdateDTO {
    yearToCloneServiceTypes?: number
}

export interface BillingWorksheetDTO {
    id: string
    createdAt: Date | null
    updatedAt: Date | null
    deletedAt: Date | null
    norm: ServiceTypeNormEnum
    year: number
    serviceTypeIds: string[]
}

export interface GetAllBillingWorksheetsDTO {
    total: number | null
    page: null
    perPage: null
    prev: false
    next: false
    totalPage: null
    data: BillingWorksheetDTO[]
}

export interface GetAllBillingWorksheetsFiltersDTO {
    itemsPerPage: number
    page: number
    order?: boolean
    sort?: string
    year?: number
    norm?: ServiceTypeNormEnum
}

/* SERVICE */

export interface ServiceCreationDTO {
    serviceTypeId?: string
    date?: Date
    officerId?: string
    unitEmployed?: number
    customerBillingWorksheetId?: string
}

export interface ServiceUpdateDTO {
    serviceTypeId?: string
    unitEmployed?: number
}

export interface ServiceDTO {
    id: string
    createdAt: Date | null
    serviceTypeId: string
    date: Date
    officerId: string
    unitEmployed: number
    unitType: ServiceTypeUnitEnum
    taxableFee: number
    iva: number
    totalFee: number
    customerBillingWorksheetId: string
    serviceTypeCode: string
    serviceTypeDescription: string
    serviceTypeNote: string
}

export interface GetAllServiceDTO {
    total: number | null
    page: null
    perPage: null
    prev: false
    next: false
    totalPage: null
    data: ServiceDTO[]
}

export interface GetAllServiceFiltersDTO {
    itemsPerPage: number
    page: number
    order?: boolean
    sort?: string
    officerId?: string
    serviceTypeId?: string
    customerBillingWorksheetId?: string
    customerId?: string
}

/* ADDITIONAL EXPENSE */

export interface AdditionalExpenseCreationDTO {
    officerId?: string
    taxableFee?: number
    serviceTypeId?: string
    customerBillingWorksheetId?: string
}

export interface AdditionalExpenseUpdateDTO {
    code?: string
    description?: string
    note?: string
    amount?: number
    serviceTypeId?: string
}

export interface AdditionalExpenseDTO {
    id: string
    officerId: string
    taxableFee: number
    iva: number
    totalFee: number
    customerBillingWorksheetId: string
    serviceTypeId: string
    serviceTypeCode: string
    serviceTypeDescription: string
    serviceTypeNote: string
}

export interface GetAllAdditionalExpenseDTO {
    total: number | null
    page: null
    perPage: null
    prev: false
    next: false
    totalPage: null
    data: AdditionalExpenseDTO[]
}

export interface GetAllAdditionalExpenseFiltersDTO {
    itemsPerPage: number
    page: number
    order?: boolean
    sort?: string
    customerBillingWorksheetId?: string
    code?: string
    customerId?: string
    serviceTypeId?: string
}

/* CUSTOMER BILLING WORKSHEET */



export interface CustomerBillingWorksheetDTO {
    id: string
    createdAt: Date
    worksheetServiceTypes: {
        id: number
        code: string
        description: string
        category: ServiceTypeCategoryEnum
    }[]
    year: string
    norm: ServiceTypeNormEnum
    billingWorksheetId: string
    totalTaxableFee: number
    totalIva: number
    totalFee: number
    customerId: string
    services: ServiceDTO[]
    additionalExpenses: AdditionalExpenseDTO[]
}

export interface CustomerBillingWorksheetCreationDTO {
    billingWorksheetId?: string
    customerId?: string
}

export interface CustomerBillingWorksheetUpdateDTO {
    serviceIds?: string[]
    additionalExpenseIds?: string[]
}

export interface GetAllCustomerBillingWorksheetDTO {
    total: number | null
    page: null
    perPage: null
    prev: false
    next: false
    totalPage: null
    data: CustomerBillingWorksheetDTO[]
}

export interface GetAllCustomerBillingWorksheetFiltersDTO {
    itemsPerPage: number
    page: number
    order?: boolean
    sort?: string
    billingWorksheetId?: string
    customerId?: string
}