/* SERVICE TYPE */

import axios from "axios";
import { AdditionalExpenseCreationDTO, AdditionalExpenseDTO, AdditionalExpenseUpdateDTO, BillingWorksheetCreationDTO, BillingWorksheetDTO, BillingWorksheetUpdateDTO, CustomerBillingWorksheetCreationDTO, CustomerBillingWorksheetDTO, CustomerBillingWorksheetUpdateDTO, GetAllAdditionalExpenseDTO, GetAllAdditionalExpenseFiltersDTO, GetAllBillingWorksheetsDTO, GetAllBillingWorksheetsFiltersDTO, GetAllCustomerBillingWorksheetDTO, GetAllCustomerBillingWorksheetFiltersDTO, GetAllServiceDTO, GetAllServiceFiltersDTO, GetAllServiceTypeDTO, GetAllServiceTypeFiltersDTO, ServiceCreationDTO, ServiceDTO, ServiceTypeCreationDTO, ServiceTypeDTO, ServiceTypeUpdateDTO, ServiceUpdateDTO } from "./dto";
import { AdditionalExpenseService, BillingWorksheetService, CustomerBillingWorksheetService, ServiceEntityService, ServiceTypeService } from "./service";
import { keycloak } from "../../../lib/keycloak";

export class ServiceTypeServiceImpl implements ServiceTypeService {

    public CreateServiceType(data: ServiceTypeCreationDTO): Promise<void> {
        return axios.post("/api/service-types",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateServiceType(data: ServiceTypeUpdateDTO, id: string): Promise<void> {
        return axios.put("/api/service-types/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteServiceType(id: string): Promise<void> {
        return axios.delete("/api/service-types/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllServiceType(filters: GetAllServiceTypeFiltersDTO): Promise<GetAllServiceTypeDTO> {
        return axios.get("/api/service-types", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order,
                year: filters.year,
                category: filters.category,
                code: filters.code,
                norm: filters.norm,
                billingWorksheetId: filters.billingWorksheetId
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetServiceTypeById(id: string): Promise<ServiceTypeDTO> {
        return axios.get("/api/service-types/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}

export class ServiceEntityServiceImpl implements ServiceEntityService {
    
    public CreateService(data: ServiceCreationDTO): Promise<void> {
        return axios.post("/api/services",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateService(data: ServiceUpdateDTO, id: string): Promise<void> {
        return axios.put("/api/services/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteService(id: string): Promise<void> {
        return axios.delete("/api/services/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllService(filters: GetAllServiceFiltersDTO): Promise<GetAllServiceDTO> {
        return axios.get("/api/services", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order,
                customerBillingWorksheetId: filters.customerBillingWorksheetId,
                customerId: filters.customerId,
                officerId: filters.officerId,
                serviceTypeId: filters.serviceTypeId
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetServiceById(id: string): Promise<ServiceDTO> {
        return axios.get("/api/services/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}

export class BillingWorksheetServiceImpl implements BillingWorksheetService {

    public CreateBillingWorksheet(data: BillingWorksheetCreationDTO): Promise<string> {
        return axios.post("/api/billing-worksheets",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateBillingWorksheetById(data: BillingWorksheetUpdateDTO, id: string): Promise<void> {
        return axios.put("/api/billing-worksheets/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteBillingWorksheet(id: string): Promise<void> {
        return axios.delete("/api/billing-worksheets/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllBillingWorksheet(filters: GetAllBillingWorksheetsFiltersDTO): Promise<GetAllBillingWorksheetsDTO> {
        return axios.get("/api/billing-worksheets", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order,
                year: filters.year,
                norm: filters.norm
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetBillingWorksheetById(id: string): Promise<BillingWorksheetDTO> {
        return axios.get("/api/billing-worksheets/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}

export class AdditionalExpenseServiceImpl implements AdditionalExpenseService {

    public CreateAdditionalExpense(data: AdditionalExpenseCreationDTO): Promise<void> {
        return axios.post("/api/additional-expenses",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateAdditionalExpense(data: AdditionalExpenseUpdateDTO, id: string): Promise<void> {
        return axios.put("/api/additional-expenses/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteAdditionalExpense(id: string): Promise<void> {
        return axios.delete("/api/additional-expenses/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllAdditionalExpense(filters: GetAllAdditionalExpenseFiltersDTO): Promise<GetAllAdditionalExpenseDTO> {
        return axios.get("/api/additional-expenses", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order,
                customerBillingWorksheetId: filters.customerBillingWorksheetId,
                code: filters.code,
                customerId: filters.customerId,
                serviceTypeId: filters.serviceTypeId
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetAdditionalExpenseById(id: string): Promise<AdditionalExpenseDTO> {
        return axios.get("/api/additional-expenses/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}

export class CustomerBillingWorksheetServiceImpl implements CustomerBillingWorksheetService {

    public CreateCustomerBillingWorksheet(data: CustomerBillingWorksheetCreationDTO): Promise<void> {
        return axios.post("/api/customer-billing-worksheets",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateCustomerBillingWorksheet(data: CustomerBillingWorksheetUpdateDTO, id: string): Promise<void> {
        return axios.put("/api/customer-billing-worksheets/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteCustomerBillingWorksheet(id: string): Promise<void> {
        return axios.delete("/api/customer-billing-worksheets/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllCustomerBillingWorksheet(filters: GetAllCustomerBillingWorksheetFiltersDTO): Promise<GetAllCustomerBillingWorksheetDTO> {
        return axios.get("/api/customer-billing-worksheets", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order,
                customerId: filters.customerId,
                billingWorksheetId: filters.billingWorksheetId
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetCustomerBillingWorksheetById(id: string): Promise<CustomerBillingWorksheetDTO> {
        return axios.get("/api/customer-billing-worksheets/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}