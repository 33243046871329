import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "../../../ui/icons/arrow-left";
import { Layout } from "../../../ui/layout";
import Button from "../../../ui/molecules/button";
import { MenuItems } from "../../../ui/organisms/navbar/dto";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { useEffect, useState } from "react";
import { HorizontalTab } from "../../../ui/organisms/horizontalTab";
import { Tab } from "react-tabs"
import clsx from "clsx"
import { CodexCustomerList } from "./codexList";
import { CustomerOtherOdcList } from "../measuresOtherOdc/customerList";
import { Banner } from "../../../ui/organisms/banner";

export function CustomerMeasueres() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const sessionState = useAppSelector(state => state.session)
    const [selectedTab, setSelectedTab] = useState<number>(0)

    if (sessionState.selected.customerId)
        return (
            <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Provvedimenti', 'Provvedimenti']}
                noPaddingLat
                headerLabel="Provvedimenti"
                customerName={sessionState.selected.customerName ? sessionState.selected.customerName : undefined}
                customerCode={sessionState.selected.customerCode ? sessionState.selected.customerCode : undefined}
            >
                <div>
                    <HorizontalTab
                        children={[
                            <>
                                <Tab
                                    onClick={() => {
                                        setSelectedTab(0)
                                    }}
                                    className={
                                        clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                            "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": selectedTab === 0,
                                            "text-label-sm text-neutral-600": selectedTab !== 0
                                        })
                                    }
                                    tabIndex={"0"}
                                >
                                    Elenco Provvedimenti
                                </Tab>
                                <Tab
                                    onClick={() => {
                                        setSelectedTab(1)
                                    }}
                                    className={
                                        clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                            "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": selectedTab === 1,
                                            "text-label-sm text-brandSecondary-600": selectedTab !== 1
                                        }
                                        )
                                    }
                                    tabIndex={"1"}
                                >
                                    Elenco Provvedimenti altro OdC
                                </Tab>

                            </>
                        ]}
                    />
                    <div className="fieldset bg-brandPrimary-200">
                        {
                            selectedTab === 0 &&
                            <CodexCustomerList />
                        }
                        {
                            selectedTab === 1 &&
                            <CustomerOtherOdcList />
                        }
                    </div>
                </div>
            </Layout>
        )

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Provvedimenti', 'Provvedimenti']}
            noPaddingLat
            headerLabel={"Provvedimenti Cliente"}
            customerName={sessionState.selected.customerName ? sessionState.selected.customerName : undefined}
            customerCode={sessionState.selected.customerCode ? sessionState.selected.customerCode : undefined}
        >
            <Banner
                type="error"
                visible={!sessionState.selected.customerId}
                label={"Devi prima selezionare un cliente per accedere all'elenco"}
                closeAction={function (): void {
                    alert("Devi prima selezionare un cliente per accedere all'elenco")
                }}
            />
        </Layout>
    )
}