import { useNavigate } from "react-router-dom"
import { ArrowLeftIcon } from "../../../../ui/icons/arrow-left"
import { Layout } from "../../../../ui/layout"
import { MenuItems } from "../../../../ui/organisms/navbar/dto"
import Button from "../../../../ui/molecules/button"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { Banner } from "../../../../ui/organisms/banner"
import { useEffect, useState } from "react"
import { AddFormRequest } from "./form"
import { RightBar } from "../../../../ui/organisms/rightBar"
import { AddStateRequest } from "./addStateRequest"
import { ComplianceRequestsCreation, ComplianceRequetsValidation, resetComplianceRequestsValidationStatus, resetCreateStatus, setFromCreationToUpdate, setRequestCustomerDataId, setRequestOfficerId } from "../slice"

export function AddComplianceRequest() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const sessionState = useAppSelector(state => state.session)
    const complianceRequestState = useAppSelector(state => state.complianceRequest)
    const userState = useAppSelector(state => state.users)
    const [showSuccess, setShowSuccess] = useState<boolean>(false)

    useEffect(() => {
        
        dispatch(setRequestCustomerDataId(sessionState.selected.customerId))
        dispatch(setRequestOfficerId(userState.findMeResponse.id))
    })
    useEffect(() => {
        if (complianceRequestState.complianceRequestCreationStatus === 'successfully') {
            dispatch(setFromCreationToUpdate(true))
            setShowSuccess(true)
            dispatch(resetCreateStatus())
            setTimeout(() => {
                setShowSuccess(false)
                navigate('/complianceRequests')
            }, 2000);
        }
    })

    useEffect(() => {
        if (complianceRequestState.complianceRequestErrorsStatus === 'successfully') {
            dispatch(resetComplianceRequestsValidationStatus())
            dispatch(ComplianceRequestsCreation(complianceRequestState.complianceRequestsCreation))
        }
    }, [complianceRequestState.complianceRequestErrorsStatus])

    if (sessionState.selected.customerId) {
        return (
            <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Gestione Certificazione', 'Richieste Conformità', 'Nuova Richiesta']}
                headerLabel="Nuova Richiesta"
                rightBar={true}
                headerIcon={
                    <button style={{ paddingTop: '12px' }} onClick={() => navigate('/complianceRequests')}>
                        <ArrowLeftIcon />
                    </button>
                }
                headerChildren={
                    <div className="text-left flex flex-row justify-end" style={{ padding: '16px', justifyContent: 'end' }}>

                        <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                            onClick={() => {
                                dispatch(ComplianceRequetsValidation(complianceRequestState.complianceRequestsCreation))
                            }}
                        />
                    </div>
                }
            >
                <>
                    {
                        (showSuccess) &&

                        <div className="gap-[8px] text-left bg-green-300" style={{ margin: '24px', borderRadius: '8px' }}>
                            <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                                Dati salvati correttamente.
                            </span>
                        </div>
                    }
                    <div style={{ display: 'flex', minHeight: window.innerHeight }}>
                        <div style={{ flex: '0.6' }}>
                            <AddFormRequest />
                        </div>

                        <div className="bg-brandPrimary-100" style={{ flex: '0.4' }}>
                            <RightBar disabled={!complianceRequestState.fromCreationToUpdate}>
                                <AddStateRequest />
                            </RightBar>
                        </div>
                    </div>
                </>
            </Layout>
        )
    }
    return (
        <Banner
            type="error"
            visible={!sessionState.selected.customerId}
            label={"Devi prima selezionare un cliente per accedere all'area"}
            closeAction={function (): void {
                alert("Devi prima selezionare un cliente per accedere all'area")
            }}
        />
    )
}