import { useEffect } from "react"
import { EditIcon } from "../../../ui/icons/edit"
import { ConversionPeriodStateOptions, formatterDate, optionsFormatter } from "../../../utils"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { GetAllConversionPeriods, setConversionPeriodIdToUpdate, setConversionPeriodUpdateActiveIngredientsFound, setConversionPeriodUpdateArchivedRequestNote, setConversionPeriodUpdateArchivedRequestProtocolId, setConversionPeriodUpdateCanceledRequestNote, setConversionPeriodUpdateCanceledRequestProtocolId, setConversionPeriodUpdateCustomerId, setConversionPeriodUpdateInspectionVisitProtocolId, setConversionPeriodUpdateLastNote, setConversionPeriodUpdateNote, setConversionPeriodUpdateRegionResponseProtocolId, setConversionPeriodUpdateRequestProtocolId, setConversionPeriodUpdateSamplingResult, setConversionPeriodUpdateSendingDocumentationProtocolId, setConversionPeriodUpdateState, setConversionPeriodUpdateTrialReportProtocolId } from "./slice"
import { useNavigate } from "react-router-dom"
import { ConversionPeriodDTO } from "./dataService/dto"

export function PeriodoConversioneList() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const conversionPeriodState = useAppSelector(state => state.conversionPeriod)

    useEffect(() => {
        dispatch(GetAllConversionPeriods(conversionPeriodState.getAllConversionPeriodsFilters))
    }, [])

    const onEditHandle = (pc: ConversionPeriodDTO) => {
        dispatch(setConversionPeriodIdToUpdate(pc.id))
        dispatch(setConversionPeriodUpdateCustomerId(pc.customerId))
        dispatch(setConversionPeriodUpdateRequestProtocolId(pc.requestProtocolId))
        dispatch(setConversionPeriodUpdateInspectionVisitProtocolId(pc.inspectionVisitProtocolId))
        dispatch(setConversionPeriodUpdateTrialReportProtocolId(pc.trialReportProtocolId))
        dispatch(setConversionPeriodUpdateSamplingResult(pc.samplingResult))
        dispatch(setConversionPeriodUpdateActiveIngredientsFound(pc.activeIngredientsFound))
        dispatch(setConversionPeriodUpdateSendingDocumentationProtocolId(pc.sendingDocumentationProtocolId))
        dispatch(setConversionPeriodUpdateRegionResponseProtocolId(pc.regionResponseProtocolId))
        dispatch(setConversionPeriodUpdateNote(pc.note))
        dispatch(setConversionPeriodUpdateCanceledRequestProtocolId(pc.canceledRequestProtocolId))
        dispatch(setConversionPeriodUpdateCanceledRequestNote(pc.canceledRequestNote))
        dispatch(setConversionPeriodUpdateArchivedRequestProtocolId(pc.archivedRequestProtocolId))
        dispatch(setConversionPeriodUpdateArchivedRequestNote(pc.archivedRequestNote))
        dispatch(setConversionPeriodUpdateState(pc.state))
        dispatch(setConversionPeriodUpdateLastNote(pc.lastNote))
        navigate('/editPeriodoConversione')
    }

    return (
        <div style={{padding: '24px'}}>
            <table id="users-table" className="w-[100%]">
                <thead className="h-[52px] uppercase">
                    <tr>
                        <th style={{ borderTopLeftRadius: '8px' }}></th>
                        <th>SOT</th>
                        <th>Regione</th>
                        <th>Cliente</th>
                        <th>Codice</th>
                        <th>Data Richiesta</th>
                        <th>Protocollo Richiesta</th>
                        <th>Data Controllo</th>
                        <th>Esito/Stato</th>
                        <th>Note</th>
                        <th>Protocollo Esito Regione</th>
                        <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        conversionPeriodState.getAllConversionPeriodsResponse?.data.map((pc, index) =>
                            <tr
                                key={index}
                                style={{
                                    color: '#4D797F',
                                    background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                }}
                            >
                                <td>
                                    <div style={{display: 'flex', gap: '6px'}}>
                                        <button onClick={() => onEditHandle(pc)}>
                                            <EditIcon color="orange" size={20} />
                                        </button>
                                    </div>
                                </td>
                                <td>{pc.customerHeadQuarters}</td>
                                <td>{pc.customerCountryRegion}</td>
                                <td>{pc.customerCompanyName}</td>
                                <td>{pc.customerClientCode}</td>
                                <td>{formatterDate(pc.requestProtocolDate)}</td>
                                <td>{pc.requestProtocolInfo}</td>
                                <td>{formatterDate(pc.inspectionVisitProtocolDate!)}</td>
                                <td>{optionsFormatter(pc.state, ConversionPeriodStateOptions)}</td>
                                <td>{pc.note}</td>
                                <td>{pc.regionResponseProtocolInfo}</td>
                                <td></td>
                            </tr>
                        )}
                </tbody>
            </table>
        </div>
    )
}