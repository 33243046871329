import { OptionType } from ".";
import { DepartureChannelEnum } from "../pages/gestioneProvvedimenti/dto";
import { ControlTypeEnum, MeasureStatusEnum, MeasureVersionEnum, RecourseResultEnum } from "../pages/gestioneProvvedimenti/measures/dataService/dto";
import { NonComplianceMapping } from "../pages/gestioneProvvedimenti/measuresOtherOdc/dataService/dto";

export const nonComplianceOptions: OptionType[] = [
    { value: "A1.01", label: "A1.01" },
    { value: "A1.02", label: "A1.02" },
    { value: "A1.03", label: "A1.03" },
    { value: "A1.04", label: "A1.04" },
    { value: "A1.05", label: "A1.05" },
    { value: "A1.06", label: "A1.06" },
    { value: "A1.07", label: "A1.07" },
    { value: "A1.08", label: "A1.08" },
    { value: "A1.09", label: "A1.09" },
    { value: "A1.10", label: "A1.10" },
    { value: "A1.11", label: "A1.11" },
    { value: "A1.12", label: "A1.12" },
    { value: "A3.01", label: "A3.01" },
    { value: "A3.02", label: "A3.02" },
    { value: "A3.03", label: "A3.03" },
    { value: "B1.01", label: "B1.01" },
    { value: "B1.02", label: "B1.02" },
    { value: "B1.03", label: "B1.03" },
    { value: "B1.04", label: "B1.04" },
    { value: "B2.01", label: "B2.01" },
    { value: "B3.01", label: "B3.01" },
    { value: "B4.01", label: "B4.01" },
    { value: "C1.01", label: "C1.01" },
    { value: "C1.02", label: "C1.02" },
    { value: "C1.03", label: "C1.03" },
    { value: "C1.04", label: "C1.04" },
    { value: "C1.05", label: "C1.05" },
    { value: "C1.06", label: "C1.06" },
    { value: "C1.07", label: "C1.07" },
    { value: "C1.08", label: "C1.08" },
    { value: "C1.09", label: "C1.09" },
    { value: "C1.10", label: "C1.10" },
    { value: "C1.11", label: "C1.11" },
    { value: "C2.01", label: "C2.01" },
    { value: "C2.02", label: "C2.02" },
    { value: "C2.03", label: "C2.03" },
    { value: "C2.04", label: "C2.04" },
    { value: "C2.05", label: "C2.05" },
    { value: "C2.06", label: "C2.06" },
    { value: "C2.07", label: "C2.07" },
    { value: "C3.01", label: "C3.01" },
    { value: "C3.02", label: "C3.02" },
    { value: "C3.03", label: "C3.03" },
    { value: "C4.01", label: "C4.01" },
    { value: "D1.01", label: "D1.01" },
    { value: "D1.02", label: "D1.02" },
    { value: "D1.03", label: "D1.03" },
    { value: "D1.04", label: "D1.04" },
    { value: "D1.05", label: "D1.05" },
    { value: "D1.06", label: "D1.06" },
    { value: "D1.07", label: "D1.07" },
    { value: "D1.08", label: "D1.08" },
    { value: "D1.09", label: "D1.09" },
    { value: "D2.01", label: "D2.01" },
    { value: "D2.02", label: "D2.02" },
    { value: "D2.03", label: "D2.03" },
    { value: "D2.04", label: "D2.04" },
    { value: "D2.05", label: "D2.05" },
    { value: "D2.06", label: "D2.06" },
    { value: "D3.01", label: "D3.01" },
    { value: "E1.01", label: "E1.01" },
    { value: "E1.02", label: "E1.02" },
    { value: "E1.03", label: "E1.03" },
    { value: "E1.04", label: "E1.04" },
    { value: "E1.05", label: "E1.05" },
    { value: "E1.06", label: "E1.06" },
    { value: "E1.07", label: "E1.07" },
    { value: "E1.09", label: "E1.09" },
    { value: "E1.10", label: "E1.10" },
    { value: "E1.11", label: "E1.11" },
    { value: "E1.12", label: "E1.12" },
    { value: "E1.13", label: "E1.13" },
    { value: "E1.14", label: "E1.14" },
    { value: "E1.15", label: "E1.15" },
    { value: "E1.16", label: "E1.16" },
    { value: "E1.17", label: "E1.17" },
    { value: "E1.18", label: "E1.18" },
    { value: "E2.01", label: "E2.01" },
    { value: "E2.02", label: "E2.02" },
    { value: "E2.03", label: "E2.03" },
    { value: "E2.04", label: "E2.04" },
    { value: "E2.05", label: "E2.05" },
    { value: "E2.06", label: "E2.06" },
    { value: "E2.07", label: "E2.07" },
    { value: "E2.08", label: "E2.08" },
    { value: "E3.01", label: "E3.01" },
    { value: "E3.02", label: "E3.02" },
    { value: "E3.03", label: "E3.03" },
    { value: "E3.04", label: "E3.04" },
    { value: "E3.05", label: "E3.05" },
    { value: "E3.06", label: "E3.06" },
    { value: "E3.07", label: "E3.07" },
    { value: "E3.08", label: "E3.08" },
    { value: "E3.09", label: "E3.09" },
    { value: "E3.10", label: "E3.10" },
    { value: "E3.11", label: "E3.11" },
    { value: "G1.01", label: "G1.01" },
    { value: "G1.02", label: "G1.02" },
    { value: "G1.03", label: "G1.03" },
    { value: "G1.04", label: "G1.04" },
    { value: "G2.01", label: "G2.01" },
    { value: "G2.02", label: "G2.02" },
    { value: "G2.03", label: "G2.03" },
    { value: "G2.04", label: "G2.04" },
    { value: "H1.01", label: "H1.01" },
    { value: "H1.02", label: "H1.02" },
    { value: "H1.03", label: "H1.03" },
    { value: "H1.04", label: "H1.04" },
    { value: "H1.05", label: "H1.05" },
    { value: "H1.06", label: "H1.06" },
    { value: "H1.07", label: "H1.07" },
    { value: "H1.08", label: "H1.08" },
    { value: "H1.09", label: "H1.09" },
    { value: "H1.10", label: "H1.10" },
    { value: "H1.11", label: "H1.11" },
    { value: "H2.01", label: "H2.01" },
    { value: "H2.02", label: "H2.02" },
    { value: "H2.03", label: "H2.03" },
    { value: "H2.04", label: "H2.04" },
    { value: "H2.05", label: "H2.05" },
    { value: "H3.01", label: "H3.01" },
    { value: "H3.02", label: "H3.02" },
    { value: "H3.03", label: "H3.03" },
    { value: "H3.04", label: "H3.04" },
    { value: "I2.01", label: "I2.01" },
    { value: "I3.01", label: "I3.01" },
    { value: "L1.01", label: "L1.01" },
    { value: "L2.01", label: "L2.01" },
    { value: "L3.01", label: "L3.01" },
    { value: "L4.01", label: "L4.01" },
    { value: "M3.01", label: "M3.01" },
    { value: "M4.01", label: "M4.01" },
    { value: "M4.02", label: "M4.02" },
];

export const nonComplianceMap: Map<string, object> = new Map([
    ["A1.01", {
        "areaCode": "A",
        "levelAreaCode": "A1",
        "nonComplianceCode": "A1.01",
        "nonComplianceDescription": "Assenza o incompletezza della documentazione relativa all'appalto a terzi di operazioni",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.34(3)(5), art. 39(1)(d)(ii)(iii) del Reg. 2018/848",
        "note": ""
    }],
    ["A1.02", {
        "areaCode": "A",
        "levelAreaCode": "A1",
        "nonComplianceCode": "A1.02",
        "nonComplianceDescription": "Errata o mancata compilazione dei programmi di produzione ",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.3(d) del Reg. 2021/2119 - Art. 2 D.M. 18321/2012",
        "note": ""
    }],
    ["A1.03", {
        "areaCode": "A",
        "levelAreaCode": "A1",
        "nonComplianceCode": "A1.03",
        "nonComplianceDescription": "Errore materiale di compilazione della notifica e della notifica di variazione",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.34(1) del Reg. 2018/848 - Art. 5 D.M. 2049/2012",
        "note": ""
    }],
    ["A1.04", {
        "areaCode": "A",
        "levelAreaCode": "A1",
        "nonComplianceCode": "A1.04",
        "nonComplianceDescription": "Incompleta messa a disposizione, da parte dell’operatore, dei documenti richiesti dall’ODC ",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.1 del Reg. 2021/771 - art.2, art.3 del Reg. 2021/2119",
        "note": ""
    }],
    ["A1.05", {
        "areaCode": "A",
        "levelAreaCode": "A1",
        "nonComplianceCode": "A1.05",
        "nonComplianceDescription": "Incompleta redazione o mancato aggiornamento della relazione tecnica ",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.39(1)(d)(ii)(iii), art. 28(1)(2) del Reg. 2018/848",
        "note": ""
    }],
    ["A1.06", {
        "areaCode": "A",
        "levelAreaCode": "A1",
        "nonComplianceCode": "A1.06",
        "nonComplianceDescription": "Mancata compilazione della notifica di variazione e mancato invio degli altri documenti obbligatori ivi compresa la mancata informatizzazione della notifica cartacea",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.34(1) del Reg. 2018/848 - Art. 5 e 8 D.M. 2049/2012",
        "note": ""
    }],
    ["A1.07", {
        "areaCode": "A",
        "levelAreaCode": "A1",
        "nonComplianceCode": "A1.07",
        "nonComplianceDescription": "Mancata compilazione o mancato aggiornamento e non corretta archiviazione dei registri aziendali e altri documenti obbligatori e/o concordati con l’ODC ",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.2 del Reg. 2021/2119",
        "note": ""
    }],
    ["A1.08", {
        "areaCode": "A",
        "levelAreaCode": "A1",
        "nonComplianceCode": "A1.08",
        "nonComplianceDescription": "Mancata comunicazione del calendario delle preparazioni o del preavviso di lavorazione per le aziende miste ",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte IV punto 1.5 del Reg. 2018/848",
        "note": ""
    }],
    ["A1.09", {
        "areaCode": "A",
        "levelAreaCode": "A1",
        "nonComplianceCode": "A1.09",
        "nonComplianceDescription": "Mancata registrazione delle produzioni da raccolta separata e/o scarti di produzione e/o produzioni declassate",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.2, art.3 del Reg. 2021/2119",
        "note": ""
    }],
    ["A1.10", {
        "areaCode": "A",
        "levelAreaCode": "A1",
        "nonComplianceCode": "A1.10",
        "nonComplianceDescription": "Mancata segnalazione al proprio ODC di irregolarità ed infrazione anche sospetta",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.27(c), art.28(2)(c) del Reg. 2018/848",
        "note": "Se la non conformità ha effetti sulla certificazione l'inosservanza si trasforma in irregolarità che comporta la soppressione delle indicazioni biologiche."
    }],
    ["A1.11", {
        "areaCode": "A",
        "levelAreaCode": "A1",
        "nonComplianceCode": "A1.11",
        "nonComplianceDescription": "Mancato aggiornamento delle autorizzazioni igienico sanitarie necessarie allo svolgimento delle attività aziendali",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art. 2(4)(5) Reg. 2018/848",
        "note": ""
    }],
    ["A1.12", {
        "areaCode": "A",
        "levelAreaCode": "A1",
        "nonComplianceCode": "A1.12",
        "nonComplianceDescription": "Ritardo nella spedizione dei documenti obbligatori (notifiche, PAP, relazioni ecc.)",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.1 del Reg. 2021/771 - art.2, art.3 del Reg. 2021/2119 - Art. 5 D.M. 2049/2012",
        "note": ""
    }],
    ["A3.01", {
        "areaCode": "A",
        "levelAreaCode": "A3",
        "nonComplianceCode": "A3.01",
        "nonComplianceDescription": "Mancanza delle autorizzazioni igienico sanitarie necessarie allo svolgimento delle attività aziendali",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione",
        "accessoryMeasures": "",
        "legislativeReference": "art. 2(4)(5) Reg. 2018/848",
        "note": "Il provvedimento decade qualora l'operatore regolarizzi la sua posizione."
    }],
    ["A3.02", {
        "areaCode": "A",
        "levelAreaCode": "A3",
        "nonComplianceCode": "A3.02",
        "nonComplianceDescription": "Manomissione documenti e/o false comunicazioni",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione 12 mesi",
        "accessoryMeasures": "",
        "legislativeReference": "art. 39(1) Reg. UE 2018/848",
        "note": ""
    }],
    ["A3.03", {
        "areaCode": "A",
        "levelAreaCode": "A3",
        "nonComplianceCode": "A3.03",
        "nonComplianceDescription": "Negato accesso alla documentazione ed alla contabilità aziendale nei casi previsti dalla normativa ",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione 3 mesi",
        "accessoryMeasures": "",
        "legislativeReference": "art.15 del Reg. 2017/625 - art.1 del Reg. 2021/771 - art.2, art.3 del Reg. 2021/2119",
        "note": ""
    }],
    ["B1.01", {
        "areaCode": "B",
        "levelAreaCode": "B1",
        "nonComplianceCode": "B1.01",
        "nonComplianceDescription": "Mancato invio all'OdC della copia della Dichiarazione di Conformità rilasciata al cliente",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "",
        "note": ""
    }],
    ["B1.02", {
        "areaCode": "B",
        "levelAreaCode": "B1",
        "nonComplianceCode": "B1.02",
        "nonComplianceDescription": "Prodotto diverso da quello indicato nei documenti di certificazione e coperto da certificazione ",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.35(1)(2) del Reg. 2018/848",
        "note": ""
    }],
    ["B1.03", {
        "areaCode": "B",
        "levelAreaCode": "B1",
        "nonComplianceCode": "B1.03",
        "nonComplianceDescription": "Utilizzo dell’etichetta senza la preventiva autorizzazione dell'OdC, ove sussistevano i requisiti per l'autorizzazione",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "",
        "note": ""
    }],
    ["B1.04", {
        "areaCode": "B",
        "levelAreaCode": "B1",
        "nonComplianceCode": "B1.04",
        "nonComplianceDescription": "Utilizzo erroneo delle indicazioni di conformità",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.35(1)(2) del Reg. 2018/848",
        "note": ""
    }],
    ["B2.01", {
        "areaCode": "B",
        "levelAreaCode": "B2",
        "nonComplianceCode": "B2.01",
        "nonComplianceDescription": "Configurazione dell’etichetta diversa dalla versione approvata ovvero senza preventiva autorizzazione dell'OdC",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "art.32(1)(2) del Reg. 2018/848 - art.3 del Reg.2021/279",
        "note": ""
    }],
    ["B3.01", {
        "areaCode": "B",
        "levelAreaCode": "B3",
        "nonComplianceCode": "B3.01",
        "nonComplianceDescription": "Prodotto diverso da quello indicato nei documenti di certificazione e non coperto da certificazione",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione 6 mesi",
        "accessoryMeasures": "",
        "legislativeReference": "art.35(1)(2) del Reg. 2018/848",
        "note": ""
    }],
    ["B4.01", {
        "areaCode": "B",
        "levelAreaCode": "B4",
        "nonComplianceCode": "B4.01",
        "nonComplianceDescription": "Manomissione delle indicazioni e/o certificazioni di conformità rilasciate dall’OdC ",
        "nonComplianceType": "IF",
        "measureType": "E",
        "measureTypeDetail": "Esclusione",
        "accessoryMeasures": "",
        "legislativeReference": "art.35(1)(2) del Reg. 2018/848",
        "note": ""
    }],
    ["C1.01", {
        "areaCode": "C",
        "levelAreaCode": "C1",
        "nonComplianceCode": "C1.01",
        "nonComplianceDescription": "Carenza del sistema di qualifica dei fornitori e/o delle forniture ",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.35(6) del Reg. 2018/848",
        "note": ""
    }],
    ["C1.02", {
        "areaCode": "C",
        "levelAreaCode": "C1",
        "nonComplianceCode": "C1.02",
        "nonComplianceDescription": "Inadeguata identificazione del prodotto e/o dell’imballaggio",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato III punto 2 del Reg. 2018/848",
        "note": ""
    }],
    ["C1.03", {
        "areaCode": "C",
        "levelAreaCode": "C1",
        "nonComplianceCode": "C1.03",
        "nonComplianceDescription": "Inadeguata separazione spazio/temporale dei prodotti e dei mezzi tecnici durante le fasi di produzione, preparazione, stoccaggio e trasporto ",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato III punto 2 del Reg. 2018/848",
        "note": ""
    }],
    ["C1.04", {
        "areaCode": "C",
        "levelAreaCode": "C1",
        "nonComplianceCode": "C1.04",
        "nonComplianceDescription": "Mancanza della dichiarazione di prodotto non proveniente da OGM ove necessario",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.11(4) del Reg. 2018/848",
        "note": ""
    }],
    ["C1.05", {
        "areaCode": "C",
        "levelAreaCode": "C1",
        "nonComplianceCode": "C1.05",
        "nonComplianceDescription": "Mancata comunicazione della presenza di altri odc operanti anche su altri schemi di certificazione biologici",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.35(4) del Reg. 2018/848",
        "note": ""
    }],
    ["C1.06", {
        "areaCode": "C",
        "levelAreaCode": "C1",
        "nonComplianceCode": "C1.06",
        "nonComplianceDescription": "Mancata indicazione nei documenti accompagnatori dei riferimenti alla certificazione del prodotto",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato III punti 2 e 3 del Reg. 2018/848",
        "note": "Purchè sia garantita la tracciabilità. "
    }],
    ["C1.07", {
        "areaCode": "C",
        "levelAreaCode": "C1",
        "nonComplianceCode": "C1.07",
        "nonComplianceDescription": "Mancata o parziale applicazione della procedura di pulizia, nonché della sua efficacia, degli impianti e dei locali, destinati alla produzione ed alla conservazione delle materie prime e dei prodotti biologici",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "parti pertinenti dell'allegato II del Reg. 2018/848 - parti pertinenti dell'allegato III del Reg. 2018/848",
        "note": ""
    }],
    ["C1.08", {
        "areaCode": "C",
        "levelAreaCode": "C1",
        "nonComplianceCode": "C1.08",
        "nonComplianceDescription": "Mancata o parziale registrazione delle verifiche, in fase di accettazione, dei prodotti provenienti da altre unità o operatori controllati",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato III punto 5 del Reg. 2018/848",
        "note": ""
    }],
    ["C1.09", {
        "areaCode": "C",
        "levelAreaCode": "C1",
        "nonComplianceCode": "C1.09",
        "nonComplianceDescription": "Mancata verifica del documento giustificativo del fornitore",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.35(6) del Reg. 2018/848",
        "note": ""
    }],
    ["C1.10", {
        "areaCode": "C",
        "levelAreaCode": "C1",
        "nonComplianceCode": "C1.10",
        "nonComplianceDescription": "Mancato rispetto delle prescrizioni generali per il trasporto di prodotti verso altri operatori ",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato III punti 2 e 3 del Reg. 2018/848",
        "note": ""
    }],
    ["C1.11", {
        "areaCode": "C",
        "levelAreaCode": "C1",
        "nonComplianceCode": "C1.11",
        "nonComplianceDescription": "Utilizzo di strutture non controllate per il transito di prodotti biologici confezionati",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.39(1)(d)(i) del Reg. 2018/848",
        "note": ""
    }],
    ["C2.01", {
        "areaCode": "C",
        "levelAreaCode": "C2",
        "nonComplianceCode": "C2.01",
        "nonComplianceDescription": "Impossibile identificazione del prodotto e/o dell’imballaggio",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "allegato III punti 2 e 3 del Reg. 2018/848",
        "note": ""
    }],
    ["C2.02", {
        "areaCode": "C",
        "levelAreaCode": "C2",
        "nonComplianceCode": "C2.02",
        "nonComplianceDescription": "Mancata applicazione della procedura di gestione di un prodotto non conforme",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "art.27, art.28(2), art.39(1)(d)(iii) del Reg. 2018/848",
        "note": ""
    }],
    ["C2.03", {
        "areaCode": "C",
        "levelAreaCode": "C2",
        "nonComplianceCode": "C2.03",
        "nonComplianceDescription": "Mancata applicazione della procedura di gestione di una non conformità o di un reclamo (compreso il reso)",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "art.27, art.28(2), art.39(1)(d)(iii) del Reg. 2018/848",
        "note": ""
    }],
    ["C2.04", {
        "areaCode": "C",
        "levelAreaCode": "C2",
        "nonComplianceCode": "C2.04",
        "nonComplianceDescription": "Mancata o parziale adozione delle azioni preventive previste",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "art.39(1)(d)(iii) del Reg. 2018/848",
        "note": ""
    }],
    ["C2.05", {
        "areaCode": "C",
        "levelAreaCode": "C2",
        "nonComplianceCode": "C2.05",
        "nonComplianceDescription": "Mancata separazione delle produzioni e dei mezzi tecnici non ammessi in azienda mista",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "allegato III punto 1 del Reg. 2018/848",
        "note": ""
    }],
    ["C2.06", {
        "areaCode": "C",
        "levelAreaCode": "C2",
        "nonComplianceCode": "C2.06",
        "nonComplianceDescription": "Mancata separazione spazio/temporale dei prodotti e dei mezzi tecnici durante le fasi di produzione, preparazione, stoccaggio e trasporto",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "parti pertinenti dell'allegato II del Reg. 2018/848 - parti pertinenti dell'allegato III del Reg. 2018/848",
        "note": ""
    }],
    ["C2.07", {
        "areaCode": "C",
        "levelAreaCode": "C2",
        "nonComplianceCode": "C2.07",
        "nonComplianceDescription": "Utilizzo di strutture non controllate per il transito di prodotti biologici sfusi",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "art.39(1)(d)(i) del Reg. 2018/848 e allegato III punto 3 del Reg. 2018/848",
        "note": ""
    }],
    ["C3.01", {
        "areaCode": "C",
        "levelAreaCode": "C3",
        "nonComplianceCode": "C3.01",
        "nonComplianceDescription": "Mancato pagamento dei corrispettivi dovuti all’odc",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione",
        "accessoryMeasures": " La sospensione non può comunque essere superiore ai 9 mesi. Al termine dei 9 mesi si applica l'esclusione.",
        "legislativeReference": "art.34(7) del Reg. 2018/848",
        "note": "Il provvedimento decade qualora l'operatore regolarizzi la sua posizione."
    }],
    ["C3.02", {
        "areaCode": "C",
        "levelAreaCode": "C3",
        "nonComplianceCode": "C3.02",
        "nonComplianceDescription": "Sistema di rintracciabilità insufficiente",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione 3 mesi",
        "accessoryMeasures": "",
        "legislativeReference": "art.1 del Reg. 2021/771",
        "note": ""
    }],
    ["C3.03", {
        "areaCode": "C",
        "levelAreaCode": "C3",
        "nonComplianceCode": "C3.03",
        "nonComplianceDescription": "Uso di prodotti contenenti ogm o da essi derivati o ottenuti",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione 6 mesi",
        "accessoryMeasures": "",
        "legislativeReference": "art.5(f)(iii), art.11(1) del Reg. 2018/848",
        "note": ""
    }],
    ["C4.01", {
        "areaCode": "C",
        "levelAreaCode": "C4",
        "nonComplianceCode": "C4.01",
        "nonComplianceDescription": "Negato accesso alle strutture aziendali",
        "nonComplianceType": "IF",
        "measureType": "E",
        "measureTypeDetail": "Esclusione",
        "accessoryMeasures": "",
        "legislativeReference": "art.15 del Reg. 2017/625",
        "note": ""
    }],
    ["D1.01", {
        "areaCode": "D",
        "levelAreaCode": "D1",
        "nonComplianceCode": "D1.01",
        "nonComplianceDescription": "Assenza della richiesta di deroga per le sementi e per il materiale di moltiplicazione vegetale (solo in caso di semente non ancora utilizzata)",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte I punto 1.8.5.1 e punto 1.8.5.4 del Reg. 2018/848",
        "note": ""
    }],
    ["D1.02", {
        "areaCode": "D",
        "levelAreaCode": "D1",
        "nonComplianceCode": "D1.02",
        "nonComplianceDescription": "Inadeguata applicazione della rotazione pluriennale delle colture",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte I punto 1.9.2 del Reg. 2018/848",
        "note": ""
    }],
    ["D1.03", {
        "areaCode": "D",
        "levelAreaCode": "D1",
        "nonComplianceCode": "D1.03",
        "nonComplianceDescription": "Mancata predisposizione della documentazione giustificativa per uso dei mezzi tecnici autorizzati in agricoltura biologica",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte I punto 1.10.2 del Reg. 2018/848",
        "note": ""
    }],
    ["D1.04", {
        "areaCode": "D",
        "levelAreaCode": "D1",
        "nonComplianceCode": "D1.04",
        "nonComplianceDescription": "Pratiche agronomiche non adeguate",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.5, art.6, art.9 del Reg. 2018/848",
        "note": ""
    }],
    ["D1.05", {
        "areaCode": "D",
        "levelAreaCode": "D1",
        "nonComplianceCode": "D1.05",
        "nonComplianceDescription": "Presenza non autorizzata di mezzi tecnici non ammessi in azienda completamente convertita",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato III punto 7.2 del Reg. 2018/848",
        "note": ""
    }],
    ["D1.06", {
        "areaCode": "D",
        "levelAreaCode": "D1",
        "nonComplianceCode": "D1.06",
        "nonComplianceDescription": "Superamento dei limiti consentiti dell'azoto (170 kg di azoto per anno/ettaro) nell’utilizzo degli effluenti ammessi dal Regolamento",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte I punto 1.9.4 del Reg. 2018/848",
        "note": ""
    }],
    ["D1.07", {
        "areaCode": "D",
        "levelAreaCode": "D1",
        "nonComplianceCode": "D1.07",
        "nonComplianceDescription": "Superamento dei limiti consentiti nell’utilizzo del rame per la difesa delle colture",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato I del Reg. 2021/1165",
        "note": ""
    }],
    ["D1.08", {
        "areaCode": "D",
        "levelAreaCode": "D1",
        "nonComplianceCode": "D1.08",
        "nonComplianceDescription": "Utilizzo di semente e materiale di moltiplicazione convenzionale, non trattato con prodotti non ammessi, senza richiesta di deroga ove sussistevano i requisiti per la concessione o per colture da sovescio",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte I punto 1.8.5.1 del Reg. 2018/848",
        "note": ""
    }],
    ["D1.09", {
        "areaCode": "D",
        "levelAreaCode": "D1",
        "nonComplianceCode": "D1.09",
        "nonComplianceDescription": "Mancato rispetto delle condizioni previste per l'utilizzo dei substrati nella produzione di funghi ",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte I punto 2.1 del Reg. 2018/848",
        "note": ""
    }],
    ["D2.01", {
        "areaCode": "D",
        "levelAreaCode": "D2",
        "nonComplianceCode": "D2.01",
        "nonComplianceDescription": "Mancata attuazione del piano di conversione",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "art.9(8) del Reg. 2018/848",
        "note": ""
    }],
    ["D2.02", {
        "areaCode": "D",
        "levelAreaCode": "D2",
        "nonComplianceCode": "D2.02",
        "nonComplianceDescription": "Mancata effettuazione della rotazione pluriennale delle colture",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte I punto 1.9.2 del Reg. 2018/848",
        "note": ""
    }],
    ["D2.03", {
        "areaCode": "D",
        "levelAreaCode": "D2",
        "nonComplianceCode": "D2.03",
        "nonComplianceDescription": "Mancato rispetto delle condizioni previste dalla normativa per la “produzione parallela”",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "art.9(10) del Reg. 2018/848",
        "note": ""
    }],
    ["D2.04", {
        "areaCode": "D",
        "levelAreaCode": "D2",
        "nonComplianceCode": "D2.04",
        "nonComplianceDescription": "Presenza contemporanea, di varietà parallele non facilmente distinguibili prive di autorizzazione",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "art.9(8) del Reg. 2018/848",
        "note": ""
    }],
    ["D2.05", {
        "areaCode": "D",
        "levelAreaCode": "D2",
        "nonComplianceCode": "D2.05",
        "nonComplianceDescription": "Utilizzo di semente e materiale di moltiplicazione convenzionale trattati con prodotti non ammessi",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "Ritorno in conversione per 12 mesi",
        "legislativeReference": "allegato II parte I punto 1.8.5.3",
        "note": ""
    }],
    ["D2.06", {
        "areaCode": "D",
        "levelAreaCode": "D2",
        "nonComplianceCode": "D2.06",
        "nonComplianceDescription": "Utilizzo di semente e materiale di moltiplicazione convenzionale, non trattato con prodotti non ammessi, senza richiesta di deroga ove non sussistevano i requisiti per la concessione",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte I punto 1.8.5.1 del Reg. 2018/848",
        "note": ""
    }],
    ["D3.01", {
        "areaCode": "D",
        "levelAreaCode": "D3",
        "nonComplianceCode": "D3.01",
        "nonComplianceDescription": "Utilizzo di prodotti non ammessi o non registrati, in appezzamenti già convertiti o in corso di conversione all'agricoltura biologica",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione 6 mesi",
        "accessoryMeasures": "Ritorno in conversione per 24/36 mesi",
        "legislativeReference": "art.1, art.2 del Reg. 2021/1165",
        "note": ""
    }],
    ["E1.01", {
        "areaCode": "E",
        "levelAreaCode": "E1",
        "nonComplianceCode": "E1.01",
        "nonComplianceDescription": "Carenze a carico della gestione degli accessi agli spazi all’aria aperta e della pratica del pascolo",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.4.1(e), punto 1.4.2, punto 1.6.2, punto 1.7.3, punto 1.7.5 e pertinenti norme generali aggiuntive del punto 1.9 del Reg. 2018/848 ",
        "note": ""
    }],
    ["E1.02", {
        "areaCode": "E",
        "levelAreaCode": "E1",
        "nonComplianceCode": "E1.02",
        "nonComplianceDescription": "Carenze a carico della scelta delle aree di ubicazione degli apiari, dei materiali che li costituiscono e delle pratiche e dei prodotti utilizzati per la loro gestione",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.9.6 del Reg. 2018/848",
        "note": ""
    }],
    ["E1.03", {
        "areaCode": "E",
        "levelAreaCode": "E1",
        "nonComplianceCode": "E1.03",
        "nonComplianceDescription": "Carenze, non di tipo strutturale, a carico degli edifici zootecnici, della pavimentazione, della lettiera di stabulazione e materiali utilizzati non conformi, salvo deroga ",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.6, punto 1.7 e pertinenti norme generali aggiuntive del punto 1.9 del Reg. 2018/848 e pertinenti norme generali aggiuntive del capo II e capo VI del Reg. 2020/464",
        "note": ""
    }],
    ["E1.04", {
        "areaCode": "E",
        "levelAreaCode": "E1",
        "nonComplianceCode": "E1.04",
        "nonComplianceDescription": "Inadeguata identificazione degli animali e/o degli alveari",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.3.4.4.5 del Reg. 2018/848 e art.2, art.3 del Reg. 2021/2119",
        "note": ""
    }],
    ["E1.05", {
        "areaCode": "E",
        "levelAreaCode": "E1",
        "nonComplianceCode": "E1.05",
        "nonComplianceDescription": "Inadeguata stabulazione degli animali, salvo deroga",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.6 del Reg. 2018/848 e pertinenti norme generali aggiuntive del capo II, capo VI e allegati da I a V del Reg. 2020/464",
        "note": ""
    }],
    ["E1.06", {
        "areaCode": "E",
        "levelAreaCode": "E1",
        "nonComplianceCode": "E1.06",
        "nonComplianceDescription": "Insufficienti condizioni di benessere degli animali anche nelle fasi di trasporto e macellazione",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.7 del Reg. 2018/848",
        "note": ""
    }],
    ["E1.07", {
        "areaCode": "E",
        "levelAreaCode": "E1",
        "nonComplianceCode": "E1.07",
        "nonComplianceDescription": "Introduzione di animali convenzionali  senza richiesta di deroga ove sussistevano i requisiti per la concessione",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.3.4 del Reg. 2018/848",
        "note": ""
    }],
    ["E1.09", {
        "areaCode": "E",
        "levelAreaCode": "E1",
        "nonComplianceCode": "E1.09",
        "nonComplianceDescription": "Mancata richiesta delle deroghe previste dal regolamento per l’attività zootecnica ove sussistevano i presupposti per concederle",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.7.8 del Reg. 2018/848",
        "note": ""
    }],
    ["E1.10", {
        "areaCode": "E",
        "levelAreaCode": "E1",
        "nonComplianceCode": "E1.10",
        "nonComplianceDescription": "Mancato rispetto del carico massimo di animali per ettaro",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte I punto 1.9.4 del Reg. 2018/848",
        "note": ""
    }],
    ["E1.11", {
        "areaCode": "E",
        "levelAreaCode": "E1",
        "nonComplianceCode": "E1.11",
        "nonComplianceDescription": "Mancato rispetto del piano di utilizzo delle deiezioni zootecniche",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte I punto 1.7.4 del Reg. 2018/848",
        "note": ""
    }],
    ["E1.12", {
        "areaCode": "E",
        "levelAreaCode": "E1",
        "nonComplianceCode": "E1.12",
        "nonComplianceDescription": "Mancato rispetto della superficie minima per animale per i casi non previsti in deroga o con deroga negata",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.6 del Reg. 2018/848 e pertinenti norme generali aggiuntive del capo II, capo VI e allegati da I a V del Reg. 2020/464",
        "note": ""
    }],
    ["E1.13", {
        "areaCode": "E",
        "levelAreaCode": "E1",
        "nonComplianceCode": "E1.13",
        "nonComplianceDescription": "Mancato rispetto delle condizioni previste dalla normativa per l’introduzione di animali, api regine e sciami, non biologici",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.3.4.2 del Reg. 2018/848",
        "note": ""
    }],
    ["E1.14", {
        "areaCode": "E",
        "levelAreaCode": "E1",
        "nonComplianceCode": "E1.14",
        "nonComplianceDescription": "Mancato rispetto delle condizioni previste dalla normativa per l’uso di materie prime per mangimi, additivi, ausiliari di fabbricazione e altri prodotti per l’alimentazione animale, ammessi in agricoltura biologica",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.4 del Reg. 2018/848 e pertinenti norme generali aggiuntive del capo II, capo VI e allegati da I a V del Reg. 2020/464",
        "note": ""
    }],
    ["E1.15", {
        "areaCode": "E",
        "levelAreaCode": "E1",
        "nonComplianceCode": "E1.15",
        "nonComplianceDescription": "Mancato rispetto delle condizioni previste dalla normativa, per la produzione simultanea di animali allevati con metodo biologico e non biologico",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.9(10), allegato II parte II punto 1.4.2.1 e punto 1.4.2.2 del Reg. 2018/848",
        "note": ""
    }],
    ["E1.16", {
        "areaCode": "E",
        "levelAreaCode": "E1",
        "nonComplianceCode": "E1.16",
        "nonComplianceDescription": "Periodo non conforme di finissaggio in stalla",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "",
        "note": ""
    }],
    ["E1.17", {
        "areaCode": "E",
        "levelAreaCode": "E1",
        "nonComplianceCode": "E1.17",
        "nonComplianceDescription": "Pratiche zootecniche non adeguate",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II del Reg. 2018/848 e pertinenti norme generali aggiuntive del capo II, capo VI e allegati da I a V del Reg. 2020/464",
        "note": ""
    }],
    ["E1.18", {
        "areaCode": "E",
        "levelAreaCode": "E1",
        "nonComplianceCode": "E1.18",
        "nonComplianceDescription": "Utilizzo di prodotti, per la pulizia e la disinfezione, diversi da quelli autorizzati in agricoltura biologica",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.5.1.6 e punto 1.5.1.7 del Reg. 2018/848",
        "note": ""
    }],
    ["E2.01", {
        "areaCode": "E",
        "levelAreaCode": "E2",
        "nonComplianceCode": "E2.01",
        "nonComplianceDescription": "Introduzione di animali convenzionali senza richiesta di deroga ove non sussistevano i requisiti per la concessione",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "Allontanamento ",
        "legislativeReference": "allegato II parte II punto 1.3.4.2, 1.3.4.3, 1.3.4.4 del Reg. 2018/848",
        "note": ""
    }],
    ["E2.02", {
        "areaCode": "E",
        "levelAreaCode": "E2",
        "nonComplianceCode": "E2.02",
        "nonComplianceDescription": "Mancata richiesta delle deroghe previste dal regolamento per l’attività zootecnica ove non sussistevano i presupposti per concederle",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.7.8 del Reg. 2018/848",
        "note": ""
    }],
    ["E2.03", {
        "areaCode": "E",
        "levelAreaCode": "E2",
        "nonComplianceCode": "E2.03",
        "nonComplianceDescription": "Mancato rispetto dei tempi di sospensione o conversione previsti dalla normativa per i medicinali allopatici",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.5.2.5 e punto 1.9.6.3(f) del Reg. 2018/848",
        "note": ""
    }],
    ["E2.04", {
        "areaCode": "E",
        "levelAreaCode": "E2",
        "nonComplianceCode": "E2.04",
        "nonComplianceDescription": "Mancato rispetto delle condizioni poste dalla normativa per i trattamenti veterinari e mancato uso delle pratiche per la riduzione della sofferenza",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.5.2, punto 1.7.7 e punto 1.7.9 del Reg. 2018/848",
        "note": ""
    }],
    ["E2.05", {
        "areaCode": "E",
        "levelAreaCode": "E2",
        "nonComplianceCode": "E2.05",
        "nonComplianceDescription": "Mancato rispetto delle condizioni previste per la gestione degli animali che hanno subito più di tre cicli di trattamenti in 12 mesi o più di un ciclo in caso di vita produttiva inferiore all'anno",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.5.2.4 del Reg. 2018/848",
        "note": ""
    }],
    ["E2.06", {
        "areaCode": "E",
        "levelAreaCode": "E2",
        "nonComplianceCode": "E2.06",
        "nonComplianceDescription": "Mancato rispetto delle condizioni previste per la scelta delle aree di ubicazione degli apiari, dei materiali che li costituiscono e delle pratiche e dei prodotti utilizzati per la loro gestione",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.9.6.5 del Reg. 2018/848",
        "note": ""
    }],
    ["E2.07", {
        "areaCode": "E",
        "levelAreaCode": "E2",
        "nonComplianceCode": "E2.07",
        "nonComplianceDescription": "Mancato rispetto dell'età minima di macellazione",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.9.4.1 del Reg. 2018/848",
        "note": ""
    }],
    ["E2.08", {
        "areaCode": "E",
        "levelAreaCode": "E2",
        "nonComplianceCode": "E2.08",
        "nonComplianceDescription": "Presenza a seguito di analisi nei mangimi, additivi, ausiliari di fabbricazione e altri prodotti per l’alimentazione animale, di sostanze non ammesse in agricoltura biologica",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "Ritorna in conversione",
        "legislativeReference": "allegato II parte II punto 1.4.1 del Reg. 2018/848",
        "note": ""
    }],
    ["E3.01", {
        "areaCode": "E",
        "levelAreaCode": "E3",
        "nonComplianceCode": "E3.01",
        "nonComplianceDescription": "Impiego di antibiotici in apicoltura",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione 6 mesi",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.5.1.3 del Reg. 2018/848",
        "note": ""
    }],
    ["E3.02", {
        "areaCode": "E",
        "levelAreaCode": "E3",
        "nonComplianceCode": "E3.02",
        "nonComplianceDescription": "Impiego di materie prime per mangimi, additivi, ausiliari di fabbricazione e altri prodotti per l’alimentazione animale, non ammessi in agricoltura biologica",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione 6 mesi",
        "accessoryMeasures": "Ritorna in conversione",
        "legislativeReference": "allegato II parte II punto 1.4.1 del Reg. 2018/848",
        "note": ""
    }],
    ["E3.03", {
        "areaCode": "E",
        "levelAreaCode": "E3",
        "nonComplianceCode": "E3.03",
        "nonComplianceDescription": "Impiego di medicinali veterinari allopatici ottenuti per sintesi chimica o di antibiotici per trattamenti preventivi",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione 6 mesi",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.5.1.3 del Reg. 2018/848",
        "note": ""
    }],
    ["E3.04", {
        "areaCode": "E",
        "levelAreaCode": "E3",
        "nonComplianceCode": "E3.04",
        "nonComplianceDescription": "Impiego di sostanze destinate a stimolare la crescita o la produzione compresi antibiotici, coccidiostatici e altri stimolanti artificiali della crescita",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione 6 mesi",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.4.1(f) e punto 1.5.1.4 del Reg. 2018/848",
        "note": ""
    }],
    ["E3.05", {
        "areaCode": "E",
        "levelAreaCode": "E3",
        "nonComplianceCode": "E3.05",
        "nonComplianceDescription": "Impossibile identificazione degli animali e/o degli alveari",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.3.4.4.5 del Reg. 2018/848 e art.2, art.3 del Reg. 2021/2119",
        "note": "Il provvedimento decade qualora l'operatore regolarizzi la sua posizione."
    }],
    ["E3.06", {
        "areaCode": "E",
        "levelAreaCode": "E3",
        "nonComplianceCode": "E3.06",
        "nonComplianceDescription": "Mancata attuazione del piano di adeguamento, concesso in deroga, per strutture non conformi",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione 3 mesi",
        "accessoryMeasures": "",
        "legislativeReference": "",
        "note": ""
    }],
    ["E3.07", {
        "areaCode": "E",
        "levelAreaCode": "E3",
        "nonComplianceCode": "E3.07",
        "nonComplianceDescription": "Pratica della produzione animale “senza terra”",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione 3 mesi",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.1 del Reg. 2018/848",
        "note": ""
    }],
    ["E3.08", {
        "areaCode": "E",
        "levelAreaCode": "E3",
        "nonComplianceCode": "E3.08",
        "nonComplianceDescription": "Presenza contemporanea della stessa specie in parallelo, senza autorizzazione ex art. 40, parag. 2, Reg. CE n. 889/08",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione",
        "accessoryMeasures": "",
        "legislativeReference": "",
        "note": "Il provvedimento decade qualora l'operatore regolarizzi la sua posizione."
    }],
    ["E3.09", {
        "areaCode": "E",
        "levelAreaCode": "E3",
        "nonComplianceCode": "E3.09",
        "nonComplianceDescription": "Trasferimento di embrioni e impiego di ormoni o sostanze analoghe destinati a controllare la riproduzione o ad indurre o sincronizzare gli estri",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione 6 mesi",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.5.1.4 del Reg. 2018/848",
        "note": ""
    }],
    ["E3.10", {
        "areaCode": "E",
        "levelAreaCode": "E3",
        "nonComplianceCode": "E3.10",
        "nonComplianceDescription": "Mancanza del piano di gestione dell’allevamento ",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.7.4 del Reg. 2018/848",
        "note": "Il provvedimento decade qualora l'operatore regolarizzi la sua posizione."
    }],
    ["E3.11", {
        "areaCode": "E",
        "levelAreaCode": "E3",
        "nonComplianceCode": "E3.11",
        "nonComplianceDescription": "Mancanza del piano di utilizzo delle deiezioni zootecniche ",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II parte II punto 1.7.4 del Reg. 2018/848",
        "note": "Il provvedimento decade qualora l'operatore regolarizzi la sua posizione."
    }],
    ["G1.01", {
        "areaCode": "G",
        "levelAreaCode": "G1",
        "nonComplianceCode": "G1.01",
        "nonComplianceDescription": "Impiego di ingredienti, additivi e ausiliari in assenza di deroghe o non autorizzati in agricoltura biologica ove sussistevano i requisiti per concederle",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.24 del Reg. 2018/848 e Allegato III e Allegato V del Reg. 2021/1165 ",
        "note": ""
    }],
    ["G1.02", {
        "areaCode": "G",
        "levelAreaCode": "G1",
        "nonComplianceCode": "G1.02",
        "nonComplianceDescription": "Mancata o parziale applicazione della procedura di pulizia degli impianti, prima della produzione.",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato II, punto 2.2.3 della parte IV, punto 1.5(f) e punto 2.4 della parte V del Reg. 2018/848 e allegato IV del Reg. 2021/1165",
        "note": ""
    }],
    ["G1.03", {
        "areaCode": "G",
        "levelAreaCode": "G1",
        "nonComplianceCode": "G1.03",
        "nonComplianceDescription": "Pratiche di preparazione non adeguate",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.24 e allegato II parte IV, parte V, parte VI e parte VII del Reg. 2018/848 e Allegato III e Allegato V del Reg. 2021/1165 ",
        "note": "Se la non conformità ha effetti sulla certificazione l'inosservanza si trasforma in irregolarità che comporta la soppressione delle indicazioni biologiche."
    }],
    ["G1.04", {
        "areaCode": "G",
        "levelAreaCode": "G1",
        "nonComplianceCode": "G1.04",
        "nonComplianceDescription": "Uso di prodotti o tecniche nella disinfezione e disinfestazione dei locali e delle attrezzature che possono contaminare il prodotto, in assenza di pratiche atte a preservarne la conformità",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.24 e allegato II parte IV, parte V, parte VI e parte VII del Reg. 2018/848 e Allegato III e Allegato V del Reg. 2021/1165 ",
        "note": ""
    }],
    ["G2.01", {
        "areaCode": "G",
        "levelAreaCode": "G2",
        "nonComplianceCode": "G2.01",
        "nonComplianceDescription": "Impiego di ingredienti, additivi e ausiliari in assenza di deroghe o non autorizzati in agricoltura biologica ove non sussistevano i requisiti per concederle",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "art.24 del Reg. 2018/848 e Allegato III e Allegato V del Reg. 2021/1165 ",
        "note": ""
    }],
    ["G2.02", {
        "areaCode": "G",
        "levelAreaCode": "G2",
        "nonComplianceCode": "G2.02",
        "nonComplianceDescription": "Mancato rispetto delle condizioni previste dalla normativa, per l’uso di additivi, ausiliari di fabbricazione e altre sostanze o ingredienti, autorizzati in agricoltura biologica",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "art.24 e allegato II parte IV, parte V, parte VI e parte VII del Reg. 2018/848 e Allegato III e Allegato V del Reg. 2021/1165 ",
        "note": ""
    }],
    ["G2.03", {
        "areaCode": "G",
        "levelAreaCode": "G2",
        "nonComplianceCode": "G2.03",
        "nonComplianceDescription": "Uso di radiazioni ionizzanti per il trattamento di alimenti o mangimi biologici, o di materie prime utilizzate in alimenti o mangimi biologici",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "art.9(4) del Reg. 2018/848",
        "note": ""
    }],
    ["G2.04", {
        "areaCode": "G",
        "levelAreaCode": "G2",
        "nonComplianceCode": "G2.04",
        "nonComplianceDescription": "Utilizzo di ingredienti, additivi e ausiliari di fabbricazione non autorizzati in agricoltura biologica compresi prodotti, sostanze e pratiche enologiche non ammesse nell’elaborazione dei prodotti vitivinicoli con metodo biologico",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "art.24 e allegato II parte IV, parte V, parte VI e parte VII del Reg. 2018/848 e Allegato III e Allegato V del Reg. 2021/1165 ",
        "note": ""
    }],
    ["H1.01", {
        "areaCode": "H",
        "levelAreaCode": "H1",
        "nonComplianceCode": "H1.01",
        "nonComplianceDescription": "Assenza dell’originale del certificato di ispezione o delle copie degli estratti dello stesso",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.45(1)(b)(iii) del Reg. 2018/848, art.4 e art.11 del Reg. 2021/2306, art.4, art.5 e art.8 del Reg. 2021/2307",
        "note": ""
    }],
    ["H1.02", {
        "areaCode": "H",
        "levelAreaCode": "H1",
        "nonComplianceCode": "H1.02",
        "nonComplianceDescription": "Carenze nella tenuta della contabilità di magazzino e/o finanziaria",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.39(1)(d)(i) del Reg. 2018/848 e art.1 del Reg. 2021/771 e art.3, art.4, art.5, art.6 e art.7 del Reg. 2021/2307",
        "note": ""
    }],
    ["H1.03", {
        "areaCode": "H",
        "levelAreaCode": "H1",
        "nonComplianceCode": "H1.03",
        "nonComplianceDescription": "Commercializzazione di prodotto importato a primo destinatario non soggetto a sistema di controllo",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "",
        "note": ""
    }],
    ["H1.04", {
        "areaCode": "H",
        "levelAreaCode": "H1",
        "nonComplianceCode": "H1.04",
        "nonComplianceDescription": "Mancanza di ragguagli in merito alle modalità di trasporto ",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "",
        "note": ""
    }],
    ["H1.05", {
        "areaCode": "H",
        "levelAreaCode": "H1",
        "nonComplianceCode": "H1.05",
        "nonComplianceDescription": "Mancata archiviazione, da parte del primo destinatario, degli estratti del certificato di ispezione ",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.39(1)(d)(i) del Reg. 2018/848 e art.1 del Reg. 2021/771 e art.3, art.4, art.5, art.8 del Reg. 2021/2307",
        "note": ""
    }],
    ["H1.06", {
        "areaCode": "H",
        "levelAreaCode": "H1",
        "nonComplianceCode": "H1.06",
        "nonComplianceDescription": "Mancata o errata comunicazione delle partite importate",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.3 del Reg. 2021/2307",
        "note": ""
    }],
    ["H1.07", {
        "areaCode": "H",
        "levelAreaCode": "H1",
        "nonComplianceCode": "H1.07",
        "nonComplianceDescription": "Mancata verifica del certificato di controllo della partita importata  in caso di primo destinatario",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.4 e art.8 del Reg. 2021/2307",
        "note": ""
    }],
    ["H1.08", {
        "areaCode": "H",
        "levelAreaCode": "H1",
        "nonComplianceCode": "H1.08",
        "nonComplianceDescription": "Mancata verifica, da parte del primo destinatario, del certificato di ispezione ",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.4 e art.8 del Reg. 2021/2307",
        "note": ""
    }],
    ["H1.09", {
        "areaCode": "H",
        "levelAreaCode": "H1",
        "nonComplianceCode": "H1.09",
        "nonComplianceDescription": "Mancato accertamento della chiusura dell’imballaggio in caso di primo destinatario",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "allegato III punto 6 del Reg. 2018/848 e art.4 e art.8 del Reg. 2021/2307",
        "note": ""
    }],
    ["H1.10", {
        "areaCode": "H",
        "levelAreaCode": "H1",
        "nonComplianceCode": "H1.10",
        "nonComplianceDescription": "Mancato invio all'importatore, da parte del primo destinatario, dell’originale del certificato di ispezione",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.2bis(g) del Reg. 2019/2123",
        "note": ""
    }],
    ["H1.11", {
        "areaCode": "H",
        "levelAreaCode": "H1",
        "nonComplianceCode": "H1.11",
        "nonComplianceDescription": "Trasporto di prodotto non sigillato affidato ad operatore non controllato",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida",
        "accessoryMeasures": "",
        "legislativeReference": "art.6(3) del Reg. 2019/2124 e allegato II punto 2 del Reg. 2018/848",
        "note": ""
    }],
    ["H2.01", {
        "areaCode": "H",
        "levelAreaCode": "H2",
        "nonComplianceCode": "H2.01",
        "nonComplianceDescription": "Assenza dell’estratto del certificato di controllo ove necessario",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "art.3, art.4, art.5, art.8 del Reg. 2021/2307",
        "note": ""
    }],
    ["H2.02", {
        "areaCode": "H",
        "levelAreaCode": "H2",
        "nonComplianceCode": "H2.02",
        "nonComplianceDescription": "Assenza della contabilità di magazzino e/o finanziaria",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "art.39(1)(d)(i) del Reg. 2018/848 e art.1 del Reg. 2021/771 e art.3, art.4, art.5, art.6 e art.7 del Reg. 2021/2307",
        "note": ""
    }],
    ["H2.03", {
        "areaCode": "H",
        "levelAreaCode": "H2",
        "nonComplianceCode": "H2.03",
        "nonComplianceDescription": "Commercializzazione o altra attività con utilizzo di certificato di ispezione non verificato e non vidimato dall’autorità doganale",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "art.5 e art.11 del Reg. 2021/2306 e art.8 del Reg. 2021/2307",
        "note": ""
    }],
    ["H2.04", {
        "areaCode": "H",
        "levelAreaCode": "H2",
        "nonComplianceCode": "H2.04",
        "nonComplianceDescription": "Modalità di trasporto dal Paese Terzo al primo destinatario non conformi alla normativa con conseguenze sul prodotto",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "art.6(3) del Reg. 2019/2124 e allegato II punto 2 del Reg. 2018/848",
        "note": ""
    }],
    ["H2.05", {
        "areaCode": "H",
        "levelAreaCode": "H2",
        "nonComplianceCode": "H2.05",
        "nonComplianceDescription": "Non corrispondenza dei prodotti importati con l’autorizzazione all’importazione",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "",
        "note": ""
    }],
    ["H3.01", {
        "areaCode": "H",
        "levelAreaCode": "H3",
        "nonComplianceCode": "H3.01",
        "nonComplianceDescription": "Importazione da paese terzo al di fuori del regime d’equivalenza in assenza dell’autorizzazione ministeriale ",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione 3 mesi",
        "accessoryMeasures": "con soppressione, se del caso",
        "legislativeReference": "art.47 e art.48 del Reg. 2018/848",
        "note": ""
    }],
    ["H3.02", {
        "areaCode": "H",
        "levelAreaCode": "H3",
        "nonComplianceCode": "H3.02",
        "nonComplianceDescription": "Importazione da paese terzo da operatore non incluso nell'elenco di cui all'art. 7 del D.M. 2049/2012 ",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione 1 mese",
        "accessoryMeasures": "con soppressione, se del caso",
        "legislativeReference": "art.34 e 35 del Reg.2018/848",
        "note": ""
    }],
    ["H3.03", {
        "areaCode": "H",
        "levelAreaCode": "H3",
        "nonComplianceCode": "H3.03",
        "nonComplianceDescription": "Importazione da paese terzo di prodotti non coperti da certificato di controllo – certificato d’ispezione",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione 3 mesi ",
        "accessoryMeasures": "con soppressione, se del caso",
        "legislativeReference": "art.34 e 35 del Reg.2018/848",
        "note": ""
    }],
    ["H3.04", {
        "areaCode": "H",
        "levelAreaCode": "H3",
        "nonComplianceCode": "H3.04",
        "nonComplianceDescription": "Importazione da paese terzo, in assenza del proprio documento giustificativo",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione 3 mesi",
        "accessoryMeasures": "con soppressione, se del caso",
        "legislativeReference": "art.34 e 35 del Reg.2018/848",
        "note": ""
    }],
    ["I2.01", {
        "areaCode": "I",
        "levelAreaCode": "I2",
        "nonComplianceCode": "I2.01",
        "nonComplianceDescription": "Presenza nei prodotti ottenuti e nei mezzi tecnici e/o materie prime utilizzate dall'operatore di residui di sostanze attive non ammesse in quantità superiore alla soglia numerica prevista dal D.M. 309/2011 e superiore alla soglia di tolleranza per gli Ogm",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "D.M. n. 309/2011",
        "note": ""
    }],
    ["I3.01", {
        "areaCode": "I",
        "levelAreaCode": "I3",
        "nonComplianceCode": "I3.01",
        "nonComplianceDescription": "Presenza non accidentale, a causa di misure precauzionali non applicate sui prodotti e sui mezzi tecnici e/o materie prime utilizzate ottenuti dall'operatore, di residui di sostanze attive non ammesse e/o presenza di DNA modificato ",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione 1 mese",
        "accessoryMeasures": "",
        "legislativeReference": "art.28(1) Reg. UE 2018/848",
        "note": ""
    }],
    ["L1.01", {
        "areaCode": "L",
        "levelAreaCode": "",
        "nonComplianceCode": "L1.01",
        "nonComplianceDescription": "Mancato rispetto di una diffida",
        "nonComplianceType": "IS",
        "measureType": "D",
        "measureTypeDetail": "Diffida ultimativa",
        "accessoryMeasures": "Diffida ultimativa",
        "legislativeReference": "",
        "note": ""
    }],
    ["L2.01", {
        "areaCode": "L",
        "levelAreaCode": "",
        "nonComplianceCode": "L2.01",
        "nonComplianceDescription": "Mancato adempimento del termine \"supplementare\" concesso ",
        "nonComplianceType": "IR",
        "measureType": "SP",
        "measureTypeDetail": "Soppressione",
        "accessoryMeasures": "",
        "legislativeReference": "",
        "note": ""
    }],
    ["L3.01", {
        "areaCode": "L",
        "levelAreaCode": "",
        "nonComplianceCode": "L3.01",
        "nonComplianceDescription": "Mancato rispetto di una soppressione delle indicazioni biologiche ",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione 3 mesi",
        "accessoryMeasures": "",
        "legislativeReference": "",
        "note": "Il provvedimento decade qualora l'operatore regolarizzi la sua posizione."
    }],
    ["L4.01", {
        "areaCode": "L",
        "levelAreaCode": "",
        "nonComplianceCode": "L4.01",
        "nonComplianceDescription": "Mancato rispetto di una sospensione delle indicazioni biologiche",
        "nonComplianceType": "IF",
        "measureType": "E",
        "measureTypeDetail": "Esclusione",
        "accessoryMeasures": "",
        "legislativeReference": "",
        "note": ""
    }],
    ["M3.01", {
        "areaCode": "M",
        "levelAreaCode": "",
        "nonComplianceCode": "M3.01",
        "nonComplianceDescription": "Dopo 1 soppressione (alla SECONDA NC della stessa area)",
        "nonComplianceType": "IF",
        "measureType": "S",
        "measureTypeDetail": "Sospensione 3 mesi",
        "accessoryMeasures": "",
        "legislativeReference": "",
        "note": "Per determinare una non conformità  di gravità maggiore le inosservanze deve avvenire nell'arco di 36 mesi.  "
    }],
    ["M4.01", {
        "areaCode": "M",
        "levelAreaCode": "",
        "nonComplianceCode": "M4.01",
        "nonComplianceDescription": "Dopo 2 soppressioni (alla TERZA NC dello stessa area)",
        "nonComplianceType": "IF",
        "measureType": "E",
        "measureTypeDetail": "Esclusione",
        "accessoryMeasures": "",
        "legislativeReference": "",
        "note": "Per determinare una non conformità  di gravità maggiore le inosservanze deve avvenire nell'arco di 36 mesi.  "
    }],
    ["M4.02", {
        "areaCode": "M",
        "levelAreaCode": "",
        "nonComplianceCode": "M4.02",
        "nonComplianceDescription": "Dopo 1 sospensione (alla SECONDA NC dello stessa area) ",
        "nonComplianceType": "IF",
        "measureType": "E",
        "measureTypeDetail": "Esclusione",
        "accessoryMeasures": "",
        "legislativeReference": "",
        "note": "Per determinare una non conformità  di gravità maggiore le inosservanze deve avvenire nell'arco di 36 mesi.  "
    }],
]);

export function isNonComplianceMapping(obj: object): obj is NonComplianceMapping {
    return (obj as NonComplianceMapping).nonComplianceCode !== undefined;
}

export const typeNcDetails = new Map<string, string>([
    ['IF', 'Infrazione'],
    ['IS', 'Inosservanza'],
    ['IR', 'Irregolarità'],
]);

export const nonMeasureMap: Map<string, { description: string }> = new Map([
    ["B0", { description: "Presentazione di un piano d'azione per la correzione della non conformità" }],
    ["B1", { description: "Miglioramento dell'attuazione delle misure precauzionali e dei controlli" }],
    ["B2", { description: "Soppressione dei riferimenti alla produzione biologica nell'etichettatura e nella pubblicità" }],
    ["B3", { description: "Divieto di commercializzazione di prodotti che facciano riferimento alla produzione biologica" }],
    ["B4", { description: "Nuovo periodo di conversione" }],
    ["B5", { description: "Limitazione dell'ambito di applicazione del certificato" }],
    ["B6", { description: "Sospensione del certificato" }],
    ["B7", { description: "Ritiro del certificato" }],
    ["B9", { description: "Obbligo di informare tempestivamente per iscritto i clienti" }]
]);

export const nonMeasureOptions: OptionType[] = [
    {value: 'B0', label: 'B0'},
    {value: 'B1', label: 'B1'},
    {value: 'B2', label: 'B2'},
    {value: 'B3', label: 'B3'},
    {value: 'B4', label: 'B4'},
    {value: 'B5', label: 'B5'},
    {value: 'B6', label: 'B6'},
    {value: 'B7', label: 'B7'},
    {value: 'B9', label: 'B9'},
]

export const MeasureVersionOptions: OptionType[] = [
    {value: MeasureVersionEnum.OLD, label: 'Provvedimento (DM n.15962 del 20/12/2013)'},
    {value: MeasureVersionEnum.NEW, label: 'Provvedimento (DM n.0323651 del 18/07/2024)'}
]

export const MeasureStatusOptions: OptionType[] = [
    {value: MeasureStatusEnum.EMESSO, label: 'Emesso'},
    {value: MeasureStatusEnum.RICEVUTO_APERTO, label: 'Ricevuto / Aperto'},
    {value: MeasureStatusEnum.CHIUSO, label: 'Chiuso'},
    {value: MeasureStatusEnum.MANCATO_RISPETTO, label: 'Mancato Rispetto'},
    {value: MeasureStatusEnum.RECEDUTO, label: 'Receduto'},
    {value: MeasureStatusEnum.DECADUTO, label: 'Decaduto'},
    {value: MeasureStatusEnum.REVOCATO, label: 'Revocato'},
    {value: MeasureStatusEnum.REVOCATO_A_SEGUITO_RICORSO, label: 'Revocato a seguito Ricorso'},
    {value: MeasureStatusEnum.CLIENTE_ESCLUSO_DAL_SISTEMA_DI_CONTROLLO, label: 'Cliente escluso dal sistema di controllo'},
    {value: MeasureStatusEnum.ANNULLATO, label: 'Annullato'},
]

export const ControlTypeOptions: OptionType[] = [
    {value: ControlTypeEnum.ANALITICO, label: 'Analitico'},
    {value: ControlTypeEnum.DOCUMENTALE, label: 'Documentale'},
    {value: ControlTypeEnum.ISPETTIVO, label: 'Ispettivo'},   
]

export const DepartureChannelOptions: OptionType[] = [
    {value: DepartureChannelEnum.POSTA_ELETTRONICA_CERTIFICATA, label: 'Posta elettronica certificata'},
    {value: DepartureChannelEnum.RACCOMANDATA_AR, label: 'Raccomandata A/R'},
    {value: DepartureChannelEnum.RACCOMANDATA_AR_PEC, label: 'Raccomandata A/R / Pec'}
]

export const RecourseResultOptions: OptionType[] = [
    {value: RecourseResultEnum.ACCOLTO, label: 'Accolto'},
    {value: RecourseResultEnum.RIGETTATO, label: 'Rigettato'},
    {value: RecourseResultEnum.ACCOLTO_PARZIALE, label: 'Accolto Parziale'}
]