import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "../../../../ui/icons/arrow-left";
import { Layout } from "../../../../ui/layout";
import Button from "../../../../ui/molecules/button";
import { MenuItems } from "../../../../ui/organisms/navbar/dto";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { useEffect, useState } from "react";
import { EditOldMeasure, resetEditNewStatus, resetEditStatus, UpdateNewMeasureOtherOdc } from "../dataService/slice";
import { CustomerEditOtherOdcMeasueres } from "./form";

export function EditCodexMeasureOtherOdc() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const OtherOdcMeasuresState = useAppSelector(state => state.OtherOdcMeasuresState)
    const sessionState = useAppSelector(state => state.session)
    const [selectMeasureType, setSelectMeasureType] = useState<'old' | 'new'>();
    const [showSuccess, setShowSuccess] = useState<boolean>(false)

    useEffect(() => {
        setSelectMeasureType(OtherOdcMeasuresState.measureVersion)
    }, [])

    useEffect(() => {
        if (OtherOdcMeasuresState.OtherOdcMeasuresEditStatus === 'successfully') {
            dispatch(resetEditStatus())
            setShowSuccess(true);
        }
    }, [OtherOdcMeasuresState.OtherOdcMeasuresEditStatus])

    useEffect(() => {
        if (OtherOdcMeasuresState.OtherOdcNewMeasuresEditStatus === 'successfully') {
            dispatch(resetEditNewStatus())
            setShowSuccess(true);
        }
    }, [OtherOdcMeasuresState.OtherOdcNewMeasuresEditStatus])

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setShowSuccess(false)
                navigate('/provvedimentiCliente')
            }, 2000)
        }
    }, [showSuccess])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Gestione Provvedimenti', 'Modifica Provvedimento Altro Odc']}
            headerLabel={`Modifica Provvedimento Altro OdC`}
            customerName={sessionState.selected.customerName ? sessionState.selected.customerName : undefined}
            customerCode={sessionState.selected.customerCode ? sessionState.selected.customerCode : undefined}
            rightBar={true}
            headerIcon={
                <button style={{ paddingTop: '12px' }} onClick={() => navigate('/provvedimentiCliente')}>
                    <ArrowLeftIcon />
                </button>
            }
            headerChildren={
                <div className="text-left flex flex-row justify-end" style={{ padding: '16px', justifyContent: 'end' }}>

                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                        onClick={() => {
                            if (selectMeasureType === 'old') {
                                dispatch(EditOldMeasure({ id: OtherOdcMeasuresState.selected, body: OtherOdcMeasuresState.OtherOdcMeasuresCreationUpdate }))
                            }
                            if (selectMeasureType === 'new') {
                                dispatch(UpdateNewMeasureOtherOdc({ id: OtherOdcMeasuresState.selected, body: OtherOdcMeasuresState.OtherOdcNewMeasuresCreationUpdate }))
                            }

                        }}
                    />
                </div>
            }
        >
            {
                showSuccess &&
                <div className="gap-[8px] text-left" style={{ margin: '24px', borderRadius: '12px', backgroundColor: '#D1F5D9', borderColor: '#8AE59F', borderWidth: 2 }}>
                    <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                        Dati salvati correttamente.
                    </span>
                </div>
            }
            <div>
                <CustomerEditOtherOdcMeasueres measureType={selectMeasureType} />
            </div>

        </Layout>
    )
}