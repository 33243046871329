import { GetAllOldCodexMeasureDTO, GetAllOldCodexMeasureFiltersDTO, OldCodexMeasureDTO, OldCodexMeasureUpdateDTO } from "./dto";
import { OldCodexMeasureServiceImpl } from "./serviceImpl";

export interface OldCodexMeasureService {
    UpdateOldCodexMeasure(data: OldCodexMeasureUpdateDTO, id: string): Promise<void>
    GetAllOldCodexMeasures(filters: GetAllOldCodexMeasureFiltersDTO): Promise<GetAllOldCodexMeasureDTO>
    GetOldCodexMeasureById(id: string): Promise<OldCodexMeasureDTO>
}

export function NewOldCodexMeasureService(): OldCodexMeasureService {
    return new OldCodexMeasureServiceImpl()
}