import { useEffect, useState } from "react";
import { SelectCustom } from "../../../ui/molecules/select";
import { isNonComplianceMapping, nonComplianceMap, nonComplianceOptions } from "../../../utils/measuresUtils";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { GetAllAreas, GetAllncByArea, resetGetAllStatus, resetGetByAreaStatus } from "./slice";
import { OptionType } from "../../../utils";
import { AllAreasDTO, NonComplianceCodeByIdDTO } from "./dto";
import { NonComplianceMapping } from "../measuresOtherOdc/dataService/dto";

interface Props {
    type: 'old' | 'new' | undefined
    formType?: 'add' | 'edit' | undefined
    onChange?: (value: string | undefined) => void
    newDefaultValue?: {
        areaCode: string
        areaDescription: string
        ncId: string
        ncDescription: string
        legReference: string
    }
    oldDefaultValue?: string
    value?: string
    errorLabel?: string
    label?: string
    disabled?: boolean
}

export function NonComplianceCode(props: Props) {
    const [selectedValue, setSelectedValue] = useState<string | undefined>(props.type === 'old' ? props.oldDefaultValue : props.newDefaultValue?.areaCode);
    const NonComplianceCodesState = useAppSelector(state => state.NonComplianceCodes)
    const OtherOdcMeasuresState = useAppSelector(state => state.OtherOdcMeasuresState)
    const [nonComplianceCodes, setNonComplianceCodes] = useState<OptionType[]>([])
    const [areaCodes, setAreaCodes] = useState<OptionType[]>([])
    const [areadescription, setAreaDescription] = useState<string | undefined>('')
    const [ncdescription, setNcDescription] = useState<string | undefined>('')
    const [legislativeReference, setLegislativeReference] = useState<string | undefined>('')
    const [selectedNcInfoOld, setSelectedNcInfoOld] = useState<NonComplianceMapping | null>(null);

    const dispatch = useAppDispatch()
    useEffect(() => {
        if (props.type === 'new') {
            dispatch(GetAllAreas())
        } else {
            setNonComplianceCodes(nonComplianceOptions)
        }
    }, [props.type])

    useEffect(() => {
        if (props.formType === 'edit' && props.type === 'old') {
            if (props.oldDefaultValue) {
                const infoNc = nonComplianceMap.get(props.oldDefaultValue) || null
                if (infoNc && isNonComplianceMapping(infoNc)) {
                    setSelectedNcInfoOld(infoNc)
                    console.log(infoNc)
                }
            }
        }

        if (props.formType === 'edit' && props.type === 'new') {
            if (props.newDefaultValue) {
                dispatch(GetAllncByArea(props.newDefaultValue.areaCode))
                setAreaDescription(props.newDefaultValue.areaDescription)
                setNcDescription(props.newDefaultValue.ncDescription)
                setLegislativeReference(props.newDefaultValue.legReference)
            }
        }
    }, [])

    //POPOLAMENTO SELECT AREA
    useEffect(() => {
        if (
            NonComplianceCodesState.AreasResponseStatus === 'successfully' &&
            NonComplianceCodesState.AreasResponse
        ) {
            const areas = (NonComplianceCodesState.AreasResponse as AllAreasDTO[]).map(area => ({
                value: area.areaCode,
                label: area.areaCode
            }) as OptionType)

            dispatch(resetGetAllStatus())
            setAreaCodes(areas)
        }
    }, [NonComplianceCodesState.AreasResponseStatus])

    //popolamento select codice non conformità
    useEffect(() => {
        if (NonComplianceCodesState.AreasbyCodeResponseStatus === 'successfully') {
            const dataNc = NonComplianceCodesState.AreasResponseByCode.map(ncCode => {
                setAreaDescription(ncCode.nonComplianceAreaDTO?.areaCode + " - " + ncCode.nonComplianceAreaDTO?.ncDescription)
                return {
                    value: ncCode.id,
                    label: ncCode.nonComplianceCode
                } as OptionType
            })
            setNonComplianceCodes(dataNc)
            dispatch(resetGetByAreaStatus())
        }
    }, [NonComplianceCodesState.AreasbyCodeResponseStatus])
    //popolamento select area
    // useEffect(() => {
    //     console.log('use effect dubbio')
    //     if (NonComplianceCodesState.AreasbyCodeResponseStatus === 'successfully' && NonComplianceCodesState.AreasResponseByCode) {
    //         const dataNc = NonComplianceCodesState.AreasResponseByCode.map(ncCode => {
    //             return {
    //                 value: ncCode.id,
    //                 label: ncCode.nonComplianceCode
    //             } as OptionType
    //         })
    //         dispatch(resetGetByAreaStatus())
    //         setNonComplianceCodes(dataNc)
    //     }
    // }, [NonComplianceCodesState.nonComplianceCodesResponseStatus])

    //SELEZIONE AREA
    const handleChangeArea = (value: string | undefined) => {
        if (value !== undefined) {
            dispatch(GetAllncByArea(value))
        }
    };

    //SELEZIONE CODICE NON CONFORMITÀ
    const handleChangeNc = (value: string | undefined) => {
        setSelectedValue(value);
        console.log(value)
        //prendo descrizione non conformità da selezionato
        if (props.type === 'new') {
            const ncSelected = NonComplianceCodesState.AreasResponseByCode.find(el => el.id === value);
            setNcDescription(ncSelected?.description)
            setLegislativeReference(ncSelected?.legislativeReference)
            if (props.onChange) {
                props.onChange(value);
            }
        }
        else {
            if (value) {
                if (props.onChange) {
                    props.onChange(value);
                }
                const infoNc = nonComplianceMap.get(value) || null
                if (infoNc && isNonComplianceMapping(infoNc)) {
                    console.log('sono su old ' + infoNc)
                    setSelectedNcInfoOld(infoNc)
                }
            }
        }
    };

    if (props.type === 'new') {
        return (
            <>
                <div>
                    <SelectCustom
                        label="Codice Non Conformità"
                        placeholder={'es. AA.01'}
                        value={props.value ?? selectedValue}
                        options={areaCodes}
                        defaultValue={props.newDefaultValue?.areaCode}
                        onChange={handleChangeArea}
                        disabled={props.disabled}
                    />
                </div>
                <div className="text-left pt-5 pb-5" style={{ display: 'flex', gap: '36px', alignItems: 'center', borderBottomWidth: 2, borderColor: '#D9E6E8' }}>
                    <span className="measure-text-bold">Area</span>
                    <span className="measure-text-content">{areadescription}</span>
                </div>
                <div className="mt-6">
                    <SelectCustom
                        label="Codice Riferimento Normativo Non Conformità"
                        placeholder={'Codice NC'}
                        options={nonComplianceCodes}
                        value={props.newDefaultValue?.ncId}
                        onChange={handleChangeNc}
                        disabled={props.disabled}
                    />
                </div>
                <div className="text-left pt-5 pb-5" style={{ display: 'flex', gap: '36px', alignItems: 'center', borderBottomWidth: 1 }}>
                    <span className="measure-text-bold">Dettaglio</span>
                    <span className="measure-text-content">{ncdescription}</span>
                </div>
                <div className="text-left pt-5 pb-5" style={{ display: 'flex', gap: '36px', alignItems: 'center', borderBottomWidth: 2, borderColor: '#D9E6E8' }}>
                    <span className="measure-text-bold">Riferimento Normativo</span>
                    <span className="measure-text-content">{legislativeReference}</span>
                </div>
            </>
        )
    }

    if (props.type === 'old') {
        return (
            <>
                <div>
                    <SelectCustom
                        label="Codice NC"
                        placeholder={'Codice NC'}
                        value={props.value ?? selectedValue}
                        //gestire caso nuovo provvedimento ma con codice vecchio
                        error={OtherOdcMeasuresState.otherOdcMeasuserOldErrors.nonComplianceCode}
                        errorLabel='Codice mancante'
                        options={nonComplianceCodes}
                        defaultValue={props.oldDefaultValue}
                        onChange={handleChangeNc}
                        disabled={props.disabled}
                    />
                </div>
                <div className="mt-6">
                    <div style={{ flex: '0.33', textAlign: 'left' }}>
                        <span>Area Controllo: </span>
                        {
                            selectedNcInfoOld &&
                            <span>
                                {selectedNcInfoOld.areaCode} - {selectedNcInfoOld.nonComplianceDescription}
                            </span>
                        }
                    </div>
                </div>
                <div className="mt-6" style={{ display: 'flex' }}>
                    <div style={{ flex: '0.5', textAlign: 'left' }}>
                        <span>Tipologia: </span>
                        {
                            selectedNcInfoOld &&
                            <span>
                                {selectedNcInfoOld.nonComplianceType}
                            </span>
                        }
                    </div>
                    <div style={{ flex: '0.5', textAlign: 'left' }}>
                        <span>Misura Adottata: </span>
                        {
                            selectedNcInfoOld &&
                            <span>
                                {selectedNcInfoOld.measureTypeDetail}
                            </span>
                        }
                    </div>

                </div>
            </>
        )
    }
    return (<></>)

}