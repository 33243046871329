import clsx from "clsx"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { Layout } from "../../../ui/layout"
import { ClientBanner } from "../../../ui/molecules/clientBanner"
import { MenuItems } from "../../../ui/organisms/navbar/dto"
import { optionsFormatter, attivita_bio, normativa, sot, MultipleMonthOptions, yearOptions } from "../../../utils"
import { RegistriAziendaliList } from "../../gestioneClienti/registriAziendali/customerList"
import Button from "../../../ui/molecules/button"
import { HorizontalTab } from "../../../ui/organisms/horizontalTab"
import { Tab } from "react-tabs"
import { useEffect, useState } from "react"
import { PACCustomerList } from "./customerList"
import { PACList } from "./list"
import { GetAllPacDetails, resetGetAllPACDetailsFilters, resetPACDetailCreationStatus, resetPACDetailUpdateStatus, setGetAllPACDetailsFiltersCountryRegion, setGetAllPACDetailsFiltersHeadquarter, setGetAllPACDetailsFiltersNorm, setGetAllPACDetailsFiltersPeriodicity, setGetAllPACDetailsFiltersYear } from "./slice"
import { SelectCustom } from "../../../ui/molecules/select"
import { periodicityValueGenerator } from "./utils"
import Input from "../../../ui/molecules/input"
import { SOTFilter } from "../../sot"

export function PAC() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const sessionState = useAppSelector(state => state.session)
    const pacState = useAppSelector(state => state.pac)
    const [selectedTab, setSelectedTab] = useState<number>(0)
    const [showSuccess, setShowSuccess] = useState<boolean>(false)

    useEffect(() => {
        if (pacState.PACDetailCreationStatus === 'successfully') {
            setShowSuccess(true)
            dispatch(resetPACDetailCreationStatus())
        }
        if (pacState.PACDetailUpdateStatus === 'successfully') {
            setShowSuccess(true)
            dispatch(resetPACDetailUpdateStatus())
        }
    }, [pacState.PACDetailCreationStatus, pacState.PACDetailUpdateStatus])

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setShowSuccess(false)
            }, 2000)
        }
    }, [showSuccess])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Controllo', 'PAC']}
            noPaddingLat
            headerLabel="Piano Annuale Controllo"
            headerChildren={
                <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>


                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Crea nuovo" color={"orange"}
                        disabled={!sessionState.selected.customerId}
                        onClick={() => {
                            navigate('/addPacDetail')
                        }}
                    />

                </div>
            }
        >
            <HorizontalTab
                children={[
                    <>
                        <Tab
                            onClick={() => {
                                setSelectedTab(0)
                            }}
                            className={
                                clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                    "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": selectedTab === 0,
                                    "text-label-sm text-neutral-600": selectedTab !== 0
                                })
                            }
                            tabIndex={"0"}
                        >
                            Elenco cliente
                        </Tab>
                        <Tab
                            onClick={() => {
                                setSelectedTab(1)
                            }}
                            className={
                                clsx("px-6 h-[64px] flex items-center cursor-pointer labelTab", {
                                    "border-b-4 border-brandPrimary-500 text-label-sm text-neutral-800": selectedTab === 1,
                                    "text-label-sm text-brandSecondary-600": selectedTab !== 1
                                }
                                )
                            }
                            tabIndex={"1"}
                        >
                            Elenco completo
                        </Tab>
                    </>
                ]}
            />
            {/* {
                    (selectedTab !== 1 && sessionState.selected.customerId) && (
                        <div style={{padding: '24px 24px 6px 24px'}}>
                            <ClientBanner
                                region={sessionState.selected.customerRegion!}
                                client={sessionState.selected.customerName!}
                                code={sessionState.selected.customerCode!}
                                activity={sessionState.selected.customerActivities ? `${sessionState.selected.customerActivities.map(act => optionsFormatter(act, attivita_bio))}` : undefined}
                                norm={optionsFormatter(sessionState.selected.customerNorm!, normativa)!}
                            />
                        </div>
                    )
                } */}
            {
                (selectedTab !== 0 || sessionState.selected.customerId) && (
                    <div style={{ display: 'flex', padding: '12px 24px 0px 24px' }}>
                        <div style={{ display: 'flex', flex: '0.90', gap: '24px' }}>
                            <div style={{ flex: '0.20' }}>
                                <div className="text-left">
                                    <span className="input-label">Anno</span>
                                </div>
                                <SelectCustom
                                    key={pacState.getAllPACDetailsFilters.year || 'default'}
                                    placeholder={'Anno'}
                                    value={String(pacState.getAllPACDetailsFilters.year)}
                                    options={yearOptions}
                                    onChange={(value) => dispatch(setGetAllPACDetailsFiltersYear(Number(value)))}
                                />
                            </div>
                            <div style={{ flex: '0.20' }}>
                                <div className="text-left">
                                    <span className="input-label">Sede</span>
                                </div>
                                <SOTFilter
                                    key={pacState.getAllPACDetailsFilters.head_quarter || 'default'}
                                    value={pacState.getAllPACDetailsFilters.head_quarter}
                                    // disabled={usersState.findMeResponse.headQuarterInfo?.code !== 'SC' && usersState.findMeResponse.role !== 'admin'}
                                    onChange={(value) => dispatch(setGetAllPACDetailsFiltersHeadquarter(value))}
                                />
                            </div>
                            <div style={{ flex: '0.20' }}>
                                <div className="text-left">
                                    <span className="input-label">Regione</span>
                                </div>
                                <Input
                                    placeholder={'Regione'}
                                    value={pacState.getAllPACDetailsFilters.country}
                                    onChangeText={(value) => dispatch(setGetAllPACDetailsFiltersCountryRegion(value))}
                                />
                            </div>
                            <div style={{ flex: '0.20' }}>
                                <div className="text-left">
                                    <span className="input-label">Periodicità</span>
                                </div>
                                <SelectCustom
                                    key={periodicityValueGenerator(pacState.getAllPACDetailsFilters.periodicity || []) || 'default'}
                                    placeholder={'Periodicità'}
                                    value={periodicityValueGenerator(pacState.getAllPACDetailsFilters.periodicity || [])}
                                    options={MultipleMonthOptions}
                                    onChange={(value) => dispatch(setGetAllPACDetailsFiltersPeriodicity(value?.split('-').map(v => Number(v))))}
                                />
                            </div>
                            <div style={{ flex: '0.20' }}>
                                <div className="text-left">
                                    <span className="input-label">Normativa</span>
                                </div>
                                <SelectCustom
                                    key={pacState.getAllPACDetailsFilters.norm || 'default'}
                                    placeholder={'Normativa'}
                                    value={pacState.getAllPACDetailsFilters.norm}
                                    options={normativa}
                                    onChange={(value) => dispatch(setGetAllPACDetailsFiltersNorm(value))}
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', gap: '18px', alignItems: 'flex-end' }}>
                            <Button
                                size={"lg"}
                                iconPosition={"off"}
                                variant={"link"}
                                label="Pulisci"
                                color={"gray"}
                                onClick={() => dispatch(resetGetAllPACDetailsFilters())}
                            />
                            <Button
                                variant='outline'
                                color='blue'
                                size='md'
                                iconPosition='off'
                                label='Cerca'
                                onClick={() => dispatch(GetAllPacDetails({
                                    page: pacState.getAllPACDetailsFilters.page,
                                    itemsPerPage: pacState.getAllPACDetailsFilters.itemsPerPage,
                                    order: true,
                                    customerId: selectedTab === 0 ? sessionState.selected.customerId! : undefined,
                                    head_quarter: pacState.getAllPACDetailsFilters.head_quarter,
                                    year: pacState.getAllPACDetailsFilters.year,
                                    country: pacState.getAllPACDetailsFilters.country,
                                    norm: pacState.getAllPACDetailsFilters.norm,
                                    periodicity: pacState.getAllPACDetailsFilters.periodicity
                                }))}
                            />
                        </div>
                    </div>
                )
            }
            {
                showSuccess &&
                <div className="gap-[8px] text-left" style={{ margin: '24px', borderRadius: '12px', backgroundColor: '#D1F5D9', borderColor: '#8AE59F', borderWidth: 2 }}>
                    <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                        Dati salvati correttamente.
                    </span>
                </div>
            }
            <div className="fieldset bg-brandPrimary-200">
                {
                    selectedTab === 0 &&
                    <PACCustomerList />
                }
                {
                    selectedTab === 1 &&
                    <PACList />
                }
            </div>
        </Layout>
    )
}