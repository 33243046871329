import { useNavigate } from "react-router-dom"
import { EditIcon } from "../../../ui/icons/edit"
import { TrashIcon } from "../../../ui/icons/trash"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { useEffect, useState } from "react"
import { BusinessRegisterDelete, GetAllBusinessRegisters, resetBusinessRegisterDeleteStatus, setAddedRegisterCreationBusinessRegisterId, setAddedRegisterCreationCustomerId, setAddedRegisterCreationOfficerId, setAddedRegisterCreationTypeRegisterId, setBusinessRegisterIdToUpdate, setBusinessRegisterUpdateNote, setBusinessRegisterUpdateReleaseDate, setBusinessRegisterUpdateReleaseProtocolId, setBusinessRegisterUpdateReleaseRegister, setBusinessRegisterUpdateRequestProtocolId, setBusinessRegisterUpdateRequestReason, setBusinessRegisterUpdateRequestType, setGetAllAddedRegistersFiltersBusinessRegisterId } from "./slice"
import { BusinessRegisterRequestMotivationOptions, BusinessRegisterTypologyRequestOptions, formatterDate, optionsFormatter } from "../../../utils"
import { Alert } from "../../../ui/organisms/alert"
import Button from "../../../ui/molecules/button"
import { BusinessRegisterDTO } from "./dataService/dto"

export function RegistriAziendaliList() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const sessionState = useAppSelector(state => state.session)
    const userState = useAppSelector(state => state.users)
    const registerState = useAppSelector(state => state.register)
    const [regToDelete, setRegToDelete] = useState<string | null>(null)
    const [showAlert, setShowAlert] = useState<boolean>(false)

    const onEditHandler = (data: BusinessRegisterDTO) => {
        dispatch(setBusinessRegisterIdToUpdate(data.id))
        dispatch(setBusinessRegisterUpdateRequestProtocolId(data.requestProtocolId))
        dispatch(setBusinessRegisterUpdateRequestType(data.requestType))
        dispatch(setBusinessRegisterUpdateRequestReason(data.requestReason))
        dispatch(setBusinessRegisterUpdateReleaseRegister(data.releaseRegister))
        dispatch(setBusinessRegisterUpdateReleaseProtocolId(data.releaseProtocolId))
        dispatch(setBusinessRegisterUpdateReleaseDate(data.releaseDate))
        dispatch(setBusinessRegisterUpdateNote(data.note))
        dispatch(setGetAllAddedRegistersFiltersBusinessRegisterId(data.id))
        dispatch(setAddedRegisterCreationBusinessRegisterId(data.id))
        dispatch(setAddedRegisterCreationOfficerId(userState.findMeResponse.id))
        dispatch(setAddedRegisterCreationCustomerId(sessionState.selected.customerId))
        dispatch(setAddedRegisterCreationTypeRegisterId(""))
        navigate('/editRegistroAziendale')
    }

    useEffect(() => {
        dispatch(GetAllBusinessRegisters({itemsPerPage: 0, page: 0, customerId: sessionState.selected.customerId!}))
    }, [])

    useEffect(() => {
        
        if(registerState.businessRegisterDeleteStatus === 'successfully') {
            dispatch(resetBusinessRegisterDeleteStatus())
            dispatch(GetAllBusinessRegisters({itemsPerPage: 0, page: 0, customerId: sessionState.selected.customerId!}))
        }
    }, [registerState.businessRegisterDeleteStatus])

    return (
        <div>
            <table id="users-table" className="w-[100%]">
                <thead className="h-[52px] uppercase">
                    <tr>
                        <th style={{ borderTopLeftRadius: '8px' }}>Data Richiesta</th>
                        <th>Motivo Richiesta</th>
                        <th>Protocollo Richiesta</th>
                        <th>Tipo Richiesta</th>
                        <th>Data rilascio</th>
                        <th>Protocollo Rilascio</th>
                        <th>Note</th>
                        <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        registerState.getAllBusinessRegistersResponse?.data.map((regAz, index) =>
                            <tr
                                key={index}
                                style={{
                                    color: '#4D797F',
                                    background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                }}
                            >
                                <td>{formatterDate(regAz.requestDate)}</td>
                                <td>{optionsFormatter(regAz.requestReason, BusinessRegisterRequestMotivationOptions)}</td>
                                <td>{regAz.requestProtocolInfo}</td>
                                <td>{optionsFormatter(regAz.requestType, BusinessRegisterTypologyRequestOptions)}</td>
                                <td>{formatterDate(regAz.releaseDate)}</td>
                                <td>{regAz.releaseProtocolInfo}</td>
                                <td>{regAz.note}</td>
                                <td>
                                    <div style={{ display: 'flex', gap: '6px' }}>
                                        <button onClick={() => onEditHandler(regAz)}>
                                            <EditIcon color="orange" size={20} />
                                        </button>
                                        <button onClick={() => {
                                            setRegToDelete(regAz.id)
                                            setShowAlert(true)
                                        }}>
                                            <TrashIcon color="red" size={20} />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )}
                </tbody>
            </table>
            {
                (showAlert && regToDelete) && (
                    <Alert title="Conferma operazione" close={() => setShowAlert(false)}>
                        <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
                            <div className="pb-3">
                                <div>
                                    Sei sicuro di voler eliminare il registro?
                                </div>
                            </div>
                            <div className="mt-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    variant="outline"
                                    color="dimgrey"
                                    iconPosition="off"
                                    label="Annulla"
                                    size="md"
                                    onClick={() => setShowAlert(false)}
                                />
                                <div className="pl-8">
                                    <Button
                                        variant="solid"
                                        color="orange"
                                        iconPosition="off"
                                        label="Elimina"
                                        size="md"
                                        onClick={() => {
                                            dispatch(BusinessRegisterDelete(regToDelete))
                                            setShowAlert(false)
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                    </Alert>
                )
            }
        </div>
    )
}