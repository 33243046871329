import axios from "axios";
import { CompilanceRequestService } from "./service";
import { keycloak } from "../../../../lib/keycloak";
import qs from "qs";
import { ComplianceRequestsCreateDTO, ComplianceRequestsDTO, ComplianceRequestStateDTO, ComplianceRequestsUpdateDTO, ComplianceResponseDTO, GetAllComplianceRequestsFiltersDTO } from "./dto";
import { filter } from "jszip";

export class CompilanceRequestServiceImpl implements CompilanceRequestService {

    public CreateCompilanceRequest(data: ComplianceRequestsCreateDTO): Promise<void> {
        return axios.post("/api/compliance-request",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public ComplianceRequestsState(data: ComplianceRequestStateDTO): Promise<void> {
        return axios.post("/api/compliance-request-state",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllCompilanceRequests(filters: GetAllComplianceRequestsFiltersDTO): Promise<ComplianceRequestsDTO> {
        return axios.get("/api/compliance-request", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                customerDataId: filters.customerDataId,
                year: filters.year,
                state: filters.state,
                headQuarter: filters.headQuarter,
                order: filters.order
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetCompilanceRequestsById(id: string): Promise<ComplianceRequestsDTO> {
        return axios.get("/api/compliance-request/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetByIdComplianceRequests(id: string): Promise<ComplianceResponseDTO> {
        return axios.get("/api/compliance-request/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public CompilanceRequestsDeleteState(id: string): Promise<void> {
        return axios.delete("/api/compliance-request-state/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public ComplianceRequestDelete(id: string): Promise<void> {
        return axios.delete("/api/compliance-request/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public UpdateCompilanceRequest(data: ComplianceRequestsUpdateDTO, id: string): Promise<void> {
        return axios.put("/api/compliance-request/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

}