import { useNavigate } from "react-router-dom"
import { EditIcon } from "../../../ui/icons/edit"
import { TrashIcon } from "../../../ui/icons/trash"
import { formatterDate, optionsFormatter, ReportingStatusOption, ReportingTypologyOption, sot, yearOptions } from "../../../utils"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { useEffect, useState } from "react"
import { GetAllReportings, ReportingDelete, resetGetAllReportingsFilters, resetReportingDeleteStatus, setGetAllReportingsFiltersHeadquarter, setGetAllReportingsFiltersPage, setGetAllReportingsFiltersReportingState, setGetAllReportingsFiltersYear, setReportingIdToUpdate, setReportingStateReportingId, setUpdateReportingCustomerOtherODC, setUpdateReportingDetails, setUpdateReportingEntryProtocolId, setUpdateReportingNote, setUpdateReportingReportingDate, setUpdateReportingReportingState, setUpdateReportingReportingType, setUpdateReportingSender } from "./slice"
import { handleState } from "./utils"
import { Alert } from "../../../ui/organisms/alert"
import Button from "../../../ui/molecules/button"
import { ReportingDTO } from "./dataService/dto"
import { SelectCustom } from "../../../ui/molecules/select"
import { Pagination } from "../../../ui/organisms/pagination"
import { SOTFilter } from "../../sot"

export function AllCustomersList() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const reportingState = useAppSelector(state => state.reporting)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [repToDelete, setRepToDelete] = useState<string | null>(null)

    const handleOnEdit = (data: ReportingDTO) => {
        dispatch(setReportingIdToUpdate(data.id))
        dispatch(setReportingStateReportingId(data.id))
        dispatch(setUpdateReportingReportingDate(data.reportingDate))
        dispatch(setUpdateReportingSender(data.sender))
        dispatch(setUpdateReportingCustomerOtherODC(data.customerOtherODC))
        dispatch(setUpdateReportingEntryProtocolId(data.entryProtocolId))
        dispatch(setUpdateReportingReportingState(data.reportingState))
        dispatch(setUpdateReportingReportingType(data.reportingType))
        dispatch(setUpdateReportingDetails(data.details))
        dispatch(setUpdateReportingNote(data.note))

        navigate('/editSegnalazione')
    }

    const handleOnHQ = (hq: string[]) => {
        if (hq === undefined || hq.length < 1)
            return null
        return (
            <ul>{hq.map(h => (<li>{h}</li>))}</ul> // DIVISI dA TRATTINI (TO DO)
        )
    }

    useEffect(() => {
        if (reportingState.reportingDeleteStatus === 'successfully') {
            dispatch(resetReportingDeleteStatus())
            dispatch(GetAllReportings({
                itemsPerPage: 0,
                page: 0,
                headquarter: reportingState.getAllReportingsFilters.headquarter,
                year: reportingState.getAllReportingsFilters.year,
                reportingState: reportingState.getAllReportingsFilters.reportingState,
            }))
        }
    }, [reportingState.reportingDeleteStatus])

    return (
        <div style={{ padding: '24px' }}>
            {/* FILTERS */}

            <div style={{ display: 'flex', paddingBottom: '12px' }}>
                <div style={{ flex: '0.5', display: 'flex', gap: '24px' }}>
                    <div style={{ flex: '0.25' }}>
                        <div className="text-left">
                            <span className="input-label">Anno</span>
                        </div>
                        <SelectCustom
                            key={reportingState.getAllReportingsFilters.year || 'default'}
                            placeholder={'Anno'}
                            value={String(reportingState.getAllReportingsFilters.year)}
                            options={yearOptions}
                            onChange={(value) => dispatch(setGetAllReportingsFiltersYear(Number(value)))}
                        />
                    </div>

                    <div style={{ flex: '0.25' }}>
                        <div className="text-left">
                            <span className="input-label">Stato</span>
                        </div>
                        <SelectCustom
                            key={reportingState.getAllReportingsFilters.reportingState || 'default'}
                            placeholder={'Stato'}
                            value={reportingState.getAllReportingsFilters.reportingState}
                            options={ReportingStatusOption}
                            onChange={(value) => dispatch(setGetAllReportingsFiltersReportingState(value))}
                        />
                    </div>

                    <div style={{ flex: '0.25' }}>
                        <div className="text-left">
                            <span className="input-label">Sede</span>
                        </div>
                        <SOTFilter
                            key={reportingState.getAllReportingsFilters.headquarter || 'default'}
                            value={reportingState.getAllReportingsFilters.headquarter}
                            // disabled={usersState.findMeResponse.headQuarterInfo?.code !== 'SC' && usersState.findMeResponse.role !== 'admin'}
                            onChange={(value) => dispatch(setGetAllReportingsFiltersHeadquarter(value))}
                        />
                    </div>

                    <div style={{ flex: '0.25', display: 'flex', gap: '18px', alignItems: 'flex-end' }}>
                        <Button
                            size={"md"}
                            iconPosition={"off"}
                            variant={"link"}
                            label="Pulisci"
                            color={"gray"}
                            onClick={() => dispatch(resetGetAllReportingsFilters())}
                        />
                        <Button
                            variant='outline'
                            color='blue'
                            size='md'
                            iconPosition='off'
                            label='Cerca'
                            onClick={() => dispatch(GetAllReportings({
                                itemsPerPage: 30,
                                page: reportingState.getAllReportingsFilters.page,
                                headquarter: reportingState.getAllReportingsFilters.headquarter,
                                year: reportingState.getAllReportingsFilters.year,
                                reportingState: reportingState.getAllReportingsFilters.reportingState,
                            }))}
                        />
                    </div>
                </div>

                <div className="mt-2" style={{ display: 'flex', flex: '0.5', alignItems: 'flex-end', justifyContent: 'flex-end', paddingRight: '20px' }}>
                    <Pagination
                        numbResults={reportingState.getAllReportingsResponse ? reportingState.getAllReportingsResponse.total : 0}
                        detailsResults={'Segnalazioni'}
                        fullWidth={false}
                        pages={reportingState.getAllReportingsResponse?.totalPage || 1}
                        currentPage={reportingState.getAllReportingsFilters.page ? reportingState.getAllReportingsFilters.page : 0} setPage={(page) => dispatch(setGetAllReportingsFiltersPage(page))}
                    />
                </div>
            </div>

            <table id="users-table" className="w-[100%] mt-4">
                <thead className="h-[52px] uppercase">
                    <tr>
                        <th style={{ borderTopLeftRadius: '8px' }}>Sede</th>
                        <th>Cliente</th>
                        <th>Data segnalazione</th>
                        <th>Mittente</th>
                        <th>Cliente altro ODC</th>
                        <th>Protocollo entrata</th>
                        <th style={{ textAlign: 'center' }}>Stato</th>
                        <th>Funzionario</th>
                        <th>Data</th>
                        <th>Tipo</th>
                        <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        reportingState.getAllReportingsResponse?.data.map((rep, index) =>
                            <tr
                                key={index}
                                style={{
                                    color: '#4D797F',
                                    background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                }}
                            >
                                <td>{handleOnHQ(rep.customerHeadQuarters!)}</td>
                                <td className="w-[15%]">{rep.customerInfo}</td>
                                <td>{formatterDate(rep.reportingDate)}</td>
                                <td>{rep.sender}</td>
                                <td>{rep.customerOtherODC}</td>
                                <td>{rep.entryProtocolInfo}</td>
                                <td style={{ display: 'flex', justifyContent: 'center' }}>{handleState(rep.reportingState)}</td>
                                <td>{rep.officerInfo}</td>
                                <td>{'Da stabilire'}</td>
                                <td>{optionsFormatter(rep.reportingType, ReportingTypologyOption)}</td>
                                <td>
                                    <div style={{ display: 'flex', gap: '6px' }}>
                                        <button onClick={() => handleOnEdit(rep)}>
                                            <EditIcon color="orange" size={20} />
                                        </button>
                                        <button onClick={() => {
                                            setRepToDelete(rep.id)
                                            setShowAlert(true)
                                        }}>
                                            <TrashIcon color="orange" size={20} />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )}
                </tbody>
            </table>
            {
                (showAlert && repToDelete) && (
                    <Alert title="Conferma operazione" close={() => setShowAlert(false)}>
                        <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
                            <div className="pb-3">
                                <div>
                                    Sei sicuro di voler eliminare la segnalazione?
                                </div>
                            </div>
                            <div className="mt-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    variant="outline"
                                    color="dimgrey"
                                    iconPosition="off"
                                    label="Annulla"
                                    size="md"
                                    onClick={() => setShowAlert(false)}
                                />
                                <div className="pl-8">
                                    <Button
                                        variant="solid"
                                        color="orange"
                                        iconPosition="off"
                                        label="Elimina"
                                        size="md"
                                        onClick={() => {
                                            dispatch(ReportingDelete(repToDelete))
                                            setShowAlert(false)
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                    </Alert>
                )
            }
        </div>
    )
}