export enum CodexSampleChangeStatusEnum {
    STOCK = 'STOCK',
    DELIVERED = 'DELIVERED',
    LOST = 'LOST',
    USED = 'USED',
    DESTROYED = 'DESTROYED'
}

export interface CodexSampleDTO {
    id: string
    envelopNumber: number
    personalManagementId: string | null
    personalManagementInfo: string | null
    entryProtocolId: string | null
    entryProtocolInfo: string | null
    note: string | null
    status: CodexSampleChangeStatusEnum
}

export interface GetAllCodexSampleDTO {
    total: number | null
    page: number | string | null
    perPage: number | null
    prev: string | null
    next: string | null
    totalPage: number | null
    data: CodexSampleDTO[]
}

export interface GetAllCodexSampleFiltersDTO {
    entryProtocolId?: string
    envelopNumber?: string
    itemsPerPage: number
    order?: boolean
    page: number
    personalManagementId?: string
    sort?: string
    status?: CodexSampleChangeStatusEnum
}

// CREATION

export interface CodexSampleCreationDTO {
    fromEnvelopNumber?: string
    toEnvelopNumber?: string
}

export interface CodexSampleCreationResponseDTO {
    successEnvelopNumbers: string[]
    failedEnvelopNumbers: string[]
}

// ASSIGNMENT

export interface CodexSampleAssignDTO {
    envelopNumbers: number[]
    note?: string
    personalManagementId: string
    entryProtocolId: string
    status?: CodexSampleChangeStatusEnum
}

// EDIT

export interface CodexSampleEditStatusDTO {
    envelopNumbers: number[]
    status?: CodexSampleChangeStatusEnum
}

