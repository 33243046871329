import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../utils";
import { GetAllPACDetailsDTO, GetAllPACDetailsFiltersDTO, GetAllPACsDTO, GetAllPACsFiltersDTO, PACDetailCreationDTO, PACDetailDTO, PACDetailErrors, PACDetailUpdateDTO, PACDTO, PACErrorsDTO } from "./dataService/dto";
import { NewPACService } from "./dataService/service";

interface PACState {

    // REQUEST
    PACCreationRequest: PACDTO
    PACUpdateRequest: PACDTO
    PACIdToUpdate: string
    PACDetailCreationRequest: PACDetailCreationDTO
    PACDetailUpdateRequest: PACDetailUpdateDTO
    PACDetailNorm: string
    PACDetailClient: string
    PACDetailIdToUpdate: string

    // ERRORS
    PACErrors: PACErrorsDTO
    PACDetailErrors: PACDetailErrors

    // FILTERS
    getAllPACsFilters: GetAllPACsFiltersDTO
    getAllPACDetailsFilters: GetAllPACDetailsFiltersDTO

    // STATUS
    PACValidationStatus: PromiseStatuses
    PACCreationStatus: PromiseStatuses
    PACUpdateStatus: PromiseStatuses
    getAllPACsStatus: PromiseStatuses
    getPACByIdStatus: PromiseStatuses

    PACDetailValidationStatus: PromiseStatuses
    PACDetailCreationStatus: PromiseStatuses
    PACDetailUpdateStatus: PromiseStatuses
    PACDetailDeleteStatus: PromiseStatuses
    getAllPACDetailsStatus: PromiseStatuses
    getPACDetailByIdStatus: PromiseStatuses

    // RESPONSE
    getAllPACsResponse?: GetAllPACsDTO
    getPACByIdResponse?: PACDTO
    getAllPACDetailsResponse?: GetAllPACDetailsDTO
    getPACDetailByIdResponse?: PACDetailDTO
}

const initialState: PACState = {

    // REQUEST
    PACCreationRequest: {},
    PACUpdateRequest: {},
    PACIdToUpdate: '',
    PACDetailCreationRequest: {
        periodicity: [],
        sampling: false,
        specifications: '',
        customerId: '',
        annualControlPlaneId: ''
    },
    PACDetailUpdateRequest: {},
    PACDetailNorm: '',
    PACDetailClient: '',
    PACDetailIdToUpdate: '',

    // ERRORS
    PACErrors: {
        year: false,
        norm: false,
        startingDate: false,
        endingDate: false
    },
    PACDetailErrors: {
        periodicity: false,
        mode: false,
        visitType: false,
        sampling: false,
        annualControlPlaneId: false
    },

    // FILTERS
    getAllPACsFilters: {
        page: 0,
        itemsPerPage: 0
    },
    getAllPACDetailsFilters: {
        page: 0,
        itemsPerPage: 0
    },

    // STATUS
    PACValidationStatus: 'idle',
    PACCreationStatus: 'idle',
    PACUpdateStatus: 'idle',
    getAllPACsStatus: 'idle',
    getPACByIdStatus: 'idle',

    PACDetailValidationStatus: 'idle',
    PACDetailCreationStatus: 'idle',
    PACDetailUpdateStatus: 'idle',
    PACDetailDeleteStatus: 'idle',
    getAllPACDetailsStatus: 'idle',
    getPACDetailByIdStatus: 'idle'
}

export const PACValidation = createAsyncThunk(
    'pac/Validation',
    async (request: PACDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetPACErrors())

        if(request.year === undefined) {
            thunkApi.dispatch(setValidatePACYear(true))
            isValid = false
        }

        if(request.norm === undefined) {
            thunkApi.dispatch(setValidatePACNorm(true))
            isValid = false
        }

        if(request.startingDate === undefined) {
            thunkApi.dispatch(setValidatePACStartingDate(true))
            isValid = false
        }

        if(request.endingDate === undefined) {
            thunkApi.dispatch(setValidatePACEndingDate(true))
            isValid = false
        }

        console.log(request);
        

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)

export const PACDetailValidation = createAsyncThunk(
    'pac/detailValidation',
    async (request: PACDetailCreationDTO | PACDetailUpdateDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetPACDetailErrors())

        if(request.periodicity === undefined ||request.periodicity?.length < 1 ) {
            thunkApi.dispatch(setValidatePACDetailPeriodicity(true))
            isValid = false
        }

        if(request.mode === undefined) {
            thunkApi.dispatch(setValidatePACDetailMode(true))
            isValid = false
        }

        if(request.visitType === undefined) {
            thunkApi.dispatch(setValidatePACDetailVisitType(true))
            isValid = false
        }

        if(request.sampling === undefined) {
            thunkApi.dispatch(setValidatePACDettailSampling(true))
            isValid = false
        }

        if(request.annualControlPlaneId === undefined || request.annualControlPlaneId === '') {
            thunkApi.dispatch(setValidatePACAnnualControlPlaneId(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)

export const PacCreation = createAsyncThunk(
    'pac/Creation',
    async (body: PACDTO, thunkApi): Promise<void> => {
        const pacService = NewPACService()

        return pacService.CreatePAC(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const PacUpdate = createAsyncThunk(
    'pac/Update',
    async (request: {body: PACDTO, id: string}, thunkApi): Promise<void> => {
        const pacService = NewPACService()

        return pacService.UpdatePAC(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllPac = createAsyncThunk(
    'pac/GetAll',
    async (filters: GetAllPACsFiltersDTO, thunkApi): Promise<GetAllPACsDTO> => {
        const pacService = NewPACService()

        return pacService.GetAllPACs(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetPacById = createAsyncThunk(
    'pac/GetById',
    async (id: string, thunkApi): Promise<PACDTO> => {
        const pacService = NewPACService()

        return pacService.GetPACById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const PacDetailCreation = createAsyncThunk(
    'pac/detail/Creation',
    async (body: PACDetailCreationDTO, thunkApi): Promise<void> => {
        const pacService = NewPACService()

        return pacService.CreatePACDetail(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const PacDetailUpdate = createAsyncThunk(
    'pac/detail/Update',
    async (request: {body: PACDetailUpdateDTO, id: string}, thunkApi): Promise<void> => {
        const pacService = NewPACService()

        return pacService.UpdatePACDetail(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const DeletePacDetail = createAsyncThunk(
    'pac/detail/delete',
    async (id: string, thunkApi): Promise<void> => {
        const pacService = NewPACService()

        return pacService.DeletePACDetail(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllPacDetails = createAsyncThunk(
    'pac/detail/GetAll',
    async (filters: GetAllPACDetailsFiltersDTO, thunkApi): Promise<GetAllPACDetailsDTO> => {
        const pacService = NewPACService()

        return pacService.GetAllPACDetails(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetPacDetailById = createAsyncThunk(
    'pac/detail/GetById',
    async (id: string, thunkApi): Promise<PACDetailDTO> => {
        const pacService = NewPACService()

        return pacService.GetPACDetailById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

const PACSlice = createSlice({
    name: 'pac/slice',
    initialState,
    reducers: {
        // REQUEST [PAC CREATION]
        setPACCreationYear: (state, action) => {
            state.PACCreationRequest.year = action.payload
        },
        setPACCreationNorm: (state, action) => {
            state.PACCreationRequest.norm = action.payload
        },
        setPACCreationNote: (state, action) => {
            state.PACCreationRequest.note = action.payload
        },
        setPACCreationStartingDate: (state, action) => {
            state.PACCreationRequest.startingDate = action.payload
        },
        setPACCreationEndingDate: (state, action) => {
            state.PACCreationRequest.endingDate = action.payload
        },
        setPACCreationUserId: (state, action) => {
            state.PACCreationRequest.userId = action.payload
        },
        resetPACCreationRequest: (state) => {
            state.PACCreationRequest = {}
        },

        // REQUEST [PAC UPDATE]
        setPACUpdateYear: (state, action) => {
            state.PACUpdateRequest.year = action.payload
        },
        setPACUpdateNorm: (state, action) => {
            state.PACUpdateRequest.norm = action.payload
        },
        setPACUpdateNote: (state, action) => {
            state.PACUpdateRequest.note = action.payload
        },
        setPACUpdateStartingDate: (state, action) => {
            state.PACUpdateRequest.startingDate = action.payload
        },
        setPACUpdateEndingDate: (state, action) => {
            state.PACUpdateRequest.endingDate = action.payload
        },
        setPACUpdateUserId: (state, action) => {
            state.PACCreationRequest.userId = action.payload
        },
        setPACIdToUpdate: (state, action) => {
            state.PACIdToUpdate = action.payload
        },
        resetPACUpdateRequest: (state) => {
            state.PACUpdateRequest = {}
        },

        // REQUEST [PAC DETAIL CREATION]
        setPACDetailCreationPeriodicity: (state, action) => {
            state.PACDetailCreationRequest.periodicity = action.payload
        },
        setPACDetailCreationMode: (state, action) => {
            state.PACDetailCreationRequest.mode = action.payload
        },
        setPACDetailCreationVisitingType: (state, action) => {
            state.PACDetailCreationRequest.visitType = action.payload
        },
        setPACDetailCreationSampling: (state, action) => {
            state.PACDetailCreationRequest.sampling = action.payload
        },
        setPACDetailCreationSpecifications: (state, action) => {
            state.PACDetailCreationRequest.specifications = action.payload
        },
        setPACDetailsCreationNote: (state, action) => {
            state.PACDetailCreationRequest.note = action.payload
        },
        setPACDetailCreationCustomerId: (state, action) => {
            state.PACDetailCreationRequest.customerId = action.payload
        },
        setPACDetailCreationAnnualControlPlaneId: (state, action) => {
            state.PACDetailCreationRequest.annualControlPlaneId = action.payload
        },
        resetPACDetailCreationRequest: (state) => {
            state.PACDetailCreationRequest = {
                periodicity: [],
                sampling: undefined,
                specifications: '',
                customerId: '',
                annualControlPlaneId: ''
            }
        },

        // REQUEST [PAC DETAIL UPDATE]
        setPACDetailUpdatePeriodicity: (state, action) => {
            state.PACDetailUpdateRequest.periodicity = action.payload
        },
        setPACDetailUpdateMode: (state, action) => {
            state.PACDetailUpdateRequest.mode = action.payload
        },
        setPACDetailUpdateVisitingType: (state, action) => {
            state.PACDetailUpdateRequest.visitType = action.payload
        },
        setPACDetailUpdateSampling: (state, action) => {
            state.PACDetailUpdateRequest.sampling = action.payload
        },
        setPACDetailUpdateSpecifications: (state, action) => {
            state.PACDetailUpdateRequest.specifications = action.payload
        },
        setPACDetailsUpdateNote: (state, action) => {
            state.PACDetailUpdateRequest.note = action.payload
        },
        setPACDetailIdToUpdate: (state, action) => {
            state.PACDetailIdToUpdate = action.payload
        },
        setPACDetailUpdateAnnualControlPlaneId: (state, action) => {
            state.PACDetailUpdateRequest.annualControlPlaneId = action.payload
        },
        setPACDetailNorm: (state, action) => {
            state.PACDetailNorm = action.payload
        },
        setPACDetailClient: (state, action) => {
            state.PACDetailClient = action.payload
        },
        resetPACDetailUpdateRequest: (state) => {
            state.PACDetailUpdateRequest = {}
        },

        // ERRORS
        setValidatePACYear: (state, action) => {
            state.PACErrors.year = action.payload
        },
        setValidatePACNorm: (state, action) => {
            state.PACErrors.norm = action.payload
        },
        setValidatePACStartingDate: (state, action) => {
            state.PACErrors.startingDate = action.payload
        },
        setValidatePACEndingDate: (state, action) => {
            state.PACErrors.endingDate = action.payload
        },
        resetPACErrors: (state) => {
            state.PACErrors = {
                year: false,
                norm: false,
                startingDate: false,
                endingDate: false
            }
        },

        setValidatePACDetailPeriodicity: (state, action) => {
            state.PACDetailErrors.periodicity = action.payload
        },
        setValidatePACDetailMode: (state, action) => {
            state.PACDetailErrors.mode = action.payload
        },
        setValidatePACDetailVisitType: (state, action) => {
            state.PACDetailErrors.visitType = action.payload
        },
        setValidatePACDettailSampling: (state, action) => {
            state.PACDetailErrors.sampling = action.payload
        },
        setValidatePACAnnualControlPlaneId: (state, action) => {
            state.PACDetailErrors.annualControlPlaneId = action.payload
        },
        resetPACDetailErrors: (state) => {
            state.PACDetailErrors = {
                periodicity: false,
                mode: false,
                visitType: false,
                sampling: false,
                annualControlPlaneId: false
            }
        },

        // FILTERS
        setGetAllPACsFiltersPage: (state, action) => {
            state.getAllPACsFilters.page = action.payload
        },
        setGetAllPACSFiltersItemsPerPage: (state, action) => {
            state.getAllPACsFilters.itemsPerPage = action.payload
        },
        setGetAllPACsFiltersOrder: (state, action) => {
            state.getAllPACsFilters.order = action.payload
        },
        setGetAllPACsFiltersSort: (state, action) => {
            state.getAllPACsFilters.sort = action.payload
        },
        resetGetAllPACsFilters: (state) => {
            state.getAllPACsFilters = {
                page: 0,
                itemsPerPage: 0
            }
        },

        setGetAllPACDetailsFiltersPage: (state, action) => {
            state.getAllPACDetailsFilters.page = action.payload
        },
        setGetAllPACDetailsFiltersItemsPerPage: (state, action) => {
            state.getAllPACDetailsFilters.itemsPerPage = action.payload
        },
        setGetAllPACDetailsFiltersOrder: (state, action) => {
            state.getAllPACDetailsFilters.order = action.payload
        },
        setGetAllPACDetailsFiltersSort: (state, action) => {
            state.getAllPACDetailsFilters.sort = action.payload
        },
        setGetAllPACDetailsFiltersCustomerId: (state, action) => {
            state.getAllPACDetailsFilters.customerId = action.payload
        },
        setGetAllPACDetailsFiltersHeadquarter: (state, action) => {
            state.getAllPACDetailsFilters.head_quarter = action.payload
        },
        setGetAllPACDetailsFiltersPeriodicity: (state, action) => {
            state.getAllPACDetailsFilters.periodicity = action.payload
        },
        setGetAllPACDetailsFiltersNorm: (state, action) => {
            state.getAllPACDetailsFilters.norm = action.payload
        },
        setGetAllPACDetailsFiltersYear: (state, action) => {
            state.getAllPACDetailsFilters.year = action.payload
        },
        setGetAllPACDetailsFiltersCountryRegion: (state, action) => {
            state.getAllPACDetailsFilters.country = action.payload
        },
        resetGetAllPACDetailsFilters: (state) => {
            state.getAllPACDetailsFilters.page = 0
            state.getAllPACDetailsFilters.itemsPerPage = 0
            state.getAllPACDetailsFilters.head_quarter = undefined
            state.getAllPACDetailsFilters.periodicity = undefined
            state.getAllPACDetailsFilters.norm = undefined
            state.getAllPACDetailsFilters.year = undefined
            state.getAllPACDetailsFilters.country = ''

        },

        // STATUS
        resetPACValidationStatus: (state) => {
            state.PACValidationStatus = 'idle'
        },
        resetPACCreationStatus: (state) => {
            state.PACCreationStatus = 'idle'
        },
        resetPACUpdateStatus: (state) => {
            state.PACUpdateStatus = 'idle'
        },
        resetGetAllPACsStatus: (state) => {
            state.getAllPACsStatus = 'idle'
        },
        resetGetPACByIdStatus: (state) => {
            state.getPACByIdStatus = 'idle'
        },
        resetPACDetailCreationStatus: (state) => {
            state.PACDetailCreationStatus = 'idle'
        },
        resetPACDetailUpdateStatus: (state) => {
            state.PACDetailUpdateStatus = 'idle'
        },
        resetPACDetailDeleteStatus: (state) => {
            state.PACDetailDeleteStatus = 'idle'
        },
        resetGetAllPACDetailsStatus: (state) => {
            state.getAllPACDetailsStatus = 'idle'
        },
        resetGetPACDetailByIdStatus: (state) => {
            state.getPACDetailByIdStatus = 'idle'
        },
        resetPACDetailValidationStatus: (state) => {
            state.PACDetailValidationStatus = 'idle'
        }
    },
    extraReducers(builder) {
        builder

            // [PAC]
            // CREATION
            .addCase(PacCreation.pending, (state) => {
                state.PACCreationStatus = 'loading'
            })
            .addCase(PacCreation.fulfilled, (state) => {
                state.PACCreationStatus = 'successfully'
            })
            .addCase(PacCreation.rejected, (state) => {
                state.PACCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(PacUpdate.pending, (state) => {
                state.PACUpdateStatus = 'loading'
            })
            .addCase(PacUpdate.fulfilled, (state) => {
                state.PACUpdateStatus = 'successfully'
            })
            .addCase(PacUpdate.rejected, (state) => {
                state.PACUpdateStatus = 'failed'
            })

            // GET ALL
            .addCase(GetAllPac.pending, (state) => {
                state.getAllPACsStatus = 'loading'
            })
            .addCase(GetAllPac.fulfilled, (state, action) => {
                state.getAllPACsStatus = 'successfully'
                state.getAllPACsResponse = action.payload
            })
            .addCase(GetAllPac.rejected, (state) => {
                state.getAllPACsStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetPacById.pending, (state) => {
                state.getPACByIdStatus = 'loading'
            })
            .addCase(GetPacById.fulfilled, (state, action) => {
                state.getPACByIdStatus = 'successfully'
                state.getPACByIdResponse = action.payload
            })
            .addCase(GetPacById.rejected, (state) => {
                state.getPACByIdStatus = 'failed'
            })

            // VALIDATION
            .addCase(PACValidation.pending, (state) => {
                state.PACValidationStatus = 'loading'
            })
            .addCase(PACValidation.fulfilled, (state) => {
                state.PACValidationStatus = 'successfully'
            })
            .addCase(PACValidation.rejected, (state) => {
                state.PACValidationStatus = 'failed'
            })


            // [PAC DETAIL]
            // CREATION
            .addCase(PacDetailCreation.pending, (state) => {
                state.PACDetailCreationStatus = 'loading'
            })
            .addCase(PacDetailCreation.fulfilled, (state) => {
                state.PACDetailCreationStatus = 'successfully'
            })
            .addCase(PacDetailCreation.rejected, (state) => {
                state.PACDetailCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(PacDetailUpdate.pending, (state) => {
                state.PACDetailUpdateStatus = 'loading'
            })
            .addCase(PacDetailUpdate.fulfilled, (state) => {
                state.PACDetailUpdateStatus = 'successfully'
            })
            .addCase(PacDetailUpdate.rejected, (state) => {
                state.PACDetailUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(DeletePacDetail.pending, (state) => {
                state.PACDetailDeleteStatus = 'loading'
            })
            .addCase(DeletePacDetail.fulfilled, (state) => {
                state.PACDetailDeleteStatus = 'successfully'
            })
            .addCase(DeletePacDetail.rejected, (state) => {
                state.PACDetailDeleteStatus = 'failed'
            })

            // GET ALL
            .addCase(GetAllPacDetails.pending, (state) => {
                state.getAllPACDetailsStatus = 'loading'
            })
            .addCase(GetAllPacDetails.fulfilled, (state, action) => {
                state.getAllPACDetailsStatus = 'successfully'
                state.getAllPACDetailsResponse = action.payload
            })
            .addCase(GetAllPacDetails.rejected, (state) => {
                state.getAllPACDetailsStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetPacDetailById.pending, (state) => {
                state.getPACDetailByIdStatus = 'loading'
            })
            .addCase(GetPacDetailById.fulfilled, (state, action) => {
                state.getPACDetailByIdStatus = 'successfully'
                state.getPACDetailByIdResponse = action.payload
            })
            .addCase(GetPacDetailById.rejected, (state) => {
                state.getPACDetailByIdStatus = 'failed'
            })

            // VALIDATION
            .addCase(PACDetailValidation.pending, (state) => {
                state.PACDetailValidationStatus = 'loading'
            })
            .addCase(PACDetailValidation.fulfilled, (state) => {
                state.PACDetailValidationStatus = 'successfully'
            })
            .addCase(PACDetailValidation.rejected, (state) => {
                state.PACDetailValidationStatus = 'failed'
            })
    },
})

export const {
    // REQUEST
    setPACCreationEndingDate,
    setPACCreationNorm,
    setPACCreationNote,
    setPACCreationStartingDate,
    setPACCreationUserId,
    setPACCreationYear,
    setPACDetailCreationAnnualControlPlaneId,
    setPACDetailCreationCustomerId,
    setPACDetailCreationMode,
    setPACDetailCreationPeriodicity,
    setPACDetailCreationSampling,
    setPACDetailCreationSpecifications,
    setPACDetailCreationVisitingType,
    setPACDetailIdToUpdate,
    setPACDetailUpdateMode,
    setPACDetailUpdatePeriodicity,
    setPACDetailUpdateSampling,
    setPACDetailUpdateSpecifications,
    setPACDetailUpdateVisitingType,
    setPACDetailUpdateAnnualControlPlaneId,
    setPACDetailNorm,
    setPACDetailClient,
    setPACDetailsCreationNote,
    setPACDetailsUpdateNote,
    resetPACCreationRequest,
    resetPACDetailCreationRequest,
    resetPACDetailUpdateRequest,
    setPACUpdateEndingDate,
    setPACUpdateNorm,
    setPACUpdateNote,
    setPACUpdateStartingDate,
    setPACUpdateUserId,
    setPACIdToUpdate,
    setPACUpdateYear,
    resetPACUpdateRequest,

    // ERRORS
    setValidatePACEndingDate,
    setValidatePACNorm,
    setValidatePACStartingDate,
    setValidatePACYear,
    resetPACErrors,
    setValidatePACAnnualControlPlaneId,
    setValidatePACDetailMode,
    setValidatePACDetailPeriodicity,
    setValidatePACDetailVisitType,
    setValidatePACDettailSampling,
    resetPACDetailErrors,

    // FILTERS
    setGetAllPACDetailsFiltersCustomerId,
    setGetAllPACDetailsFiltersItemsPerPage,
    setGetAllPACDetailsFiltersOrder,
    setGetAllPACDetailsFiltersPage,
    setGetAllPACDetailsFiltersHeadquarter,
    setGetAllPACDetailsFiltersPeriodicity,
    setGetAllPACDetailsFiltersYear,
    setGetAllPACDetailsFiltersNorm,
    setGetAllPACDetailsFiltersCountryRegion,
    setGetAllPACDetailsFiltersSort,
    setGetAllPACSFiltersItemsPerPage,
    setGetAllPACsFiltersOrder,
    setGetAllPACsFiltersPage,
    setGetAllPACsFiltersSort,
    resetGetAllPACDetailsFilters,
    resetGetAllPACsFilters,

    // STATUS
    resetPACValidationStatus,
    resetGetAllPACDetailsStatus,
    resetGetAllPACsStatus,
    resetGetPACByIdStatus,
    resetGetPACDetailByIdStatus,
    resetPACCreationStatus,
    resetPACUpdateStatus,
    resetPACDetailCreationStatus,
    resetPACDetailDeleteStatus,
    resetPACDetailUpdateStatus,
    resetPACDetailValidationStatus
} = PACSlice.actions

export default PACSlice.reducer