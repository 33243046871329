import axios from "axios";
import { OldMeasureService } from "./service";
import { keycloak } from "../../../../lib/keycloak";
import { NewMeasureFiltersDTO, OldMeasureFiltersDTO, OtherOdcMeasuresCreationUpdateDTO, OtherOdcMeasuresResponseDTO, OtherOdcNewMeasuresCreationUpdateDTO } from "./dto";
import { filter } from "jszip";

export class OldMeasureServiceImpl implements OldMeasureService {

    public CreateOldMeasure(data: OtherOdcMeasuresCreationUpdateDTO): Promise<void> {
        return axios.post("/api/old-measure",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public CreateNewMeasure(data: OtherOdcNewMeasuresCreationUpdateDTO): Promise<void> {
        return axios.post("/api/new-measure",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllCustomerOldMeasure(filters: OldMeasureFiltersDTO): Promise<OtherOdcMeasuresResponseDTO> {
        return axios.get("/api/old-measure", {
            params: {
                itemsPerPage: 0,
                page: 0,
                customerId: filters.customerId ? filters.customerId : undefined,
                isCodexReleased: filters.isCodexReleased ? true : false
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetAllCustomerNewMeasure(filters: NewMeasureFiltersDTO): Promise<OtherOdcMeasuresResponseDTO> {
        return axios.get("/api/new-measure", {
            params: {
                itemsPerPage: 0,
                page: 0,
                customerId: filters.customerId ? filters.customerId : undefined,
                isCodexReleased: filters.isCodexReleased ? true : false
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public UpdateOldMewasureOtherOdc(id: string, data: OtherOdcMeasuresCreationUpdateDTO): Promise<void> {
        return axios.put("/api/old-measure/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateNewMeasureOtherOdc(id: string, data: OtherOdcNewMeasuresCreationUpdateDTO): Promise<void> {
        return axios.put("/api/new-measure/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

}