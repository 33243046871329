export enum ConversionPeriodState {
    IN_CORSO = 'IN_CORSO',
    IN_ATTESA_DEL_CONTROLLO_ISPETTIVO = 'IN_ATTESA_DEL_CONTROLLO_ISPETTIVO',
    IN_ATTESA_DI_DOCUMENTAZIONE = 'IN_ATTESA_DI_DOCUMENTAZIONE',
    APPROVATA = 'APPROVATA',
    APPROVATA_PER_SILENZIO_ASSENSO = 'APPROVATA_PER_SILENZIO_ASSENSO',
    NON_APPROVATA = 'NON_APPROVATA',
    RICHIESTA_ANNULLATA_DAL_CLIENTE = 'RICHIESTA_ANNULLATA_DAL_CLIENTE',
    RICHIESTA_ARCHIVIATA_DA_CODEX_SRL = 'RICHIESTA_ARCHIVIATA_DA_CODEX_SRL'
}

export interface ConversionPeriodCreationDTO {
    requestProtocolId: string
    inspectionVisitProtocolId?: string
    trialReportProtocolId?: string
    samplingResult?: boolean
    activeIngredientsFound?: string
    sendingDocumentationProtocolId?: string
    regionResponseProtocolId?: string
    note?: string
    canceledRequestProtocolId?: string
    canceledRequestNote?: string
    archivedRequestProtocolId?: string
    archivedRequestNote?: string
    lastNote?: string
}

export interface ConversionPeriodUpdateDTO {
    requestProtocolId?: string
    inspectionVisitProtocolId?: string
    trialReportProtocolId?: string
    samplingResult?: boolean
    activeIngredientsFound?: string
    sendingDocumentationProtocolId?: string
    regionResponseProtocolId?: string
    note?: string
    canceledRequestProtocolId?: string
    canceledRequestNote?: string
    archivedRequestProtocolId?: string
    archivedRequestNote?: string
    state?: ConversionPeriodState
    lastNote?: string
}

export interface ConversionPeriodDTO {
    id: string
    requestProtocolId: string
    requestProtocolInfo: string
    requestProtocolDate: Date
    inspectionVisitProtocolId: string | null
    inspectionVisitProtocolInfo: string | null
    inspectionVisitProtocolDate: Date | null
    trialReportProtocolId: string | null
    trialReportProtocolInfo: string | null
    samplingResult: boolean | null
    activeIngredientsFound: string | null
    sendingDocumentationProtocolId: string | null
    sendingDocumentationProtocolInfo: string | null
    regionResponseProtocolId: string | null
    regionResponseProtocolInfo: string | null
    note: string | null
    canceledRequestProtocolId: string | null
    canceledRequestProtocolInfo: string | null
    canceledRequestNote: string | null
    archivedRequestProtocolId: string | null
    archivedRequestProtocolInfo: string | null
    archivedRequestNote: string | null
    state: ConversionPeriodState
    lastNote: string | null
    customerId: string
    customerHeadQuarters: string
    customerCountryRegion: string
    customerCompanyName: string
    customerClientCode: string
}

export interface GetAllConversionPeriodsDTO {
    total: number,
    page: number,
    perPage: number | null,
    prev: string | null,
    next: string | null,
    totalPage: number | null,
    data: ConversionPeriodDTO[]
}

export interface GetAllConversionPeriodsFiltersDTO {
    itemsPerPage: number
    page: number
    order?: boolean
    sort?: string
    countryRegion?: string
    headQuarter?: string
    state?: ConversionPeriodState
    year?: string
}