import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "../../../../../ui/icons/arrow-left";
import { Layout } from "../../../../../ui/layout";
import Button from "../../../../../ui/molecules/button";
import { MenuItems } from "../../../../../ui/organisms/navbar/dto";
import { AddNewCodexMeasureFormsStep1 } from "./forms";
import { useAppDispatch, useAppSelector } from "../../../../../lib/redux/hooks";
import { GetNewMeasureById, NewMeasureCreation, NewMeasureValidation, resetGetNewMeasureByIdStatus, resetNewMeasureCreationStatus, resetNewMeasureValidationStatus, setNewMeasureIdtoUpdate } from "../../slice";
import { useEffect } from "react";
import { LogoSpinnerComponent } from "../../../../../lib/spinner/spinnerLogo";

export function AddNewCodexMeasureStep1() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const sessionState = useAppSelector(state => state.session)
    const newMeasureState = useAppSelector(state => state.newMeasure)

    useEffect(() => {
        if(newMeasureState.newMeasureValidationStatus === 'successfully') {
            dispatch(resetNewMeasureValidationStatus())
            dispatch(NewMeasureCreation(newMeasureState.newMeasureCreationRequest))
        }
    }, [newMeasureState.newMeasureValidationStatus])

    useEffect(() => {
        
        if(newMeasureState.newMeasureCreationStatus === 'successfully' &&
            newMeasureState.newMeasureCreationResponse !== undefined
        ) {
            dispatch(resetNewMeasureCreationStatus())
            dispatch(setNewMeasureIdtoUpdate(newMeasureState.newMeasureCreationResponse))
            dispatch(GetNewMeasureById(newMeasureState.newMeasureCreationResponse))
        }
    }, [newMeasureState.newMeasureCreationStatus])

    useEffect(() => {
        if(newMeasureState.getNewMeasureByIdStatus === 'successfully' &&
            newMeasureState.getNewMeasureByIdResponse !== undefined
        ) {
            dispatch(resetGetNewMeasureByIdStatus())
            navigate('/addNewCodexMeasureStep2')
            // TO FUTURE DATA FOR M024
        }
    }, [newMeasureState.getNewMeasureByIdStatus])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Gestione Provvedimenti', 'Inserimento Nuovo Provvedimento Cliente']}
            headerLabel={`Nuovo Provvedimento`}
            customerName={sessionState.selected.customerName ? sessionState.selected.customerName : undefined}
            customerCode={sessionState.selected.customerCode ? sessionState.selected.customerCode : undefined}
            rightBar={true}
            headerIcon={
                <button style={{ paddingTop: '12px' }} onClick={() => navigate('/provvedimentiCliente')}>
                    <ArrowLeftIcon />
                </button>
            }
            headerChildren={
                <div className="text-left flex flex-row justify-end" style={{ padding: '16px', justifyContent: 'end' }}>

                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                        onClick={() => {
                            dispatch(NewMeasureValidation(newMeasureState.newMeasureCreationRequest))
                        }}
                    />
                </div>
            }
        >
            {
                (newMeasureState.newMeasureCreationStatus === 'loading' && newMeasureState.getNewMeasureByIdStatus === 'loading') && 
                    <LogoSpinnerComponent fullScreen />
            }
            <div>
                <AddNewCodexMeasureFormsStep1 />
            </div>
        </Layout>
    )
}