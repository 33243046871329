import axios from "axios";
import { CodexSampleAssignDTO, CodexSampleCreationDTO, CodexSampleCreationResponseDTO, CodexSampleDTO, CodexSampleEditStatusDTO, GetAllCodexSampleDTO, GetAllCodexSampleFiltersDTO } from "./dto";
import { CodexSampleService } from "./service";
import { keycloak } from "../../../lib/keycloak";

export class CodexSampleServiceImpl implements CodexSampleService {

    public CreateEnvelopeSamples(data: CodexSampleCreationDTO): Promise<CodexSampleCreationResponseDTO> {
        return axios.post("/api/sample-codex",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public AssignEnvelopeSamples(data: CodexSampleAssignDTO): Promise<CodexSampleCreationResponseDTO> {
        return axios.put("/api/sample-codex",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public EditEnvelopeSamplesStatus(data: CodexSampleEditStatusDTO): Promise<CodexSampleCreationResponseDTO> {
        return axios.put("/api/sample-codex/status",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllEnvelopeSamples(filters: GetAllCodexSampleFiltersDTO): Promise<GetAllCodexSampleDTO> {
        return axios.get("/api/sample-codex", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order,
                entryProtocolId: filters.entryProtocolId,
                personalManagementId: filters.personalManagementId,
                envelopNumber: filters.envelopNumber !== '' ? filters.envelopNumber : undefined,
                status: filters.status
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetEnvelopeSampleById(id: string): Promise<CodexSampleDTO> {
        return axios.get("/api/sample-codex/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}