import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { EditIcon } from "../../../ui/icons/edit"
import { optionsFormatter, formatterDate, statoRichiestaComplianceRequest } from "../../../utils"
import { useEffect, useState } from "react"
import { ComplianceRequestDelete, GetAllComplianceRequests, resetGetAllStatus, setEditCustomerOtherOdcDefault, setEditRequestDescription, setEditRequestNote, setEditRequestProduct, setEditRequestProtocolId, setEditRequestSender, setEditRequestType, setRequestId, setRequestStateDetails } from "./slice"
import { SpinnerComponent } from "../../../lib/spinner"
import { ComplianceRequestsDTO, ComplianceResponseDTO } from "./dataService/dto"
import { TrashIcon } from "../../../ui/icons/trash"
import { Alert } from "../../../ui/organisms/alert"
import Button from "../../../ui/molecules/button"
import { Banner } from "../../../ui/organisms/banner"

export function CustomerList() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const complianceRequestState = useAppSelector(state => state.complianceRequest)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const sessionState = useAppSelector(state => state.session)
    const [complianceRequestToDelete, setComplianceRequestToDelete] = useState<string | null>(null)
    useEffect(() => {
        dispatch(GetAllComplianceRequests({ itemsPerPage: 0, page: 0, customerDataId: sessionState.selected.customerId!, order: true }))
    }, [])

    useEffect(() => {
        if (complianceRequestState.getAllComplianceRequestsStatus === 'successfully') {
            resetGetAllStatus()
        }
    }, [complianceRequestState.getAllComplianceRequestsStatus])

    useEffect(() => {
        if (complianceRequestState.ComplianceRequestDeleteStatus === 'successfully') {
            dispatch(GetAllComplianceRequests({ itemsPerPage: 0, page: 0, customerDataId: sessionState.selected.customerId!, order: true }))
        }
    }, [complianceRequestState.ComplianceRequestDeleteStatus])

    if (complianceRequestState.getAllComplianceRequestsStatus === 'loading') {
        return (
            <SpinnerComponent size={'large'} />
        )
    }

    if (complianceRequestState.allComplianceRequestsResponse && complianceRequestState.allComplianceRequestsResponse.data.length === 0) {
        return (<div>Nessun Dato Disponibile</div>)
    }

    const onEditHandle = (request: ComplianceResponseDTO) => {
        dispatch(setRequestStateDetails(request.complianceRequestStates))
        if (request.otherOdcCustomer && request.otherOdcCustomer.length > 0) {
            dispatch(setEditCustomerOtherOdcDefault(request.otherOdcCustomer))
        }
        dispatch(setRequestId(request.id))
        dispatch(setEditRequestDescription(request.description))
        dispatch(setEditRequestNote(request.note))
        dispatch(setEditRequestProtocolId(request.requestProtocolId))
        dispatch(setEditRequestSender(request.sender))
        dispatch(setEditRequestType(request.requestType))
        dispatch(setEditRequestProduct(request.product))

        navigate('/editComplianceRequest')
    }
    if (sessionState.selected.customerId) {
        return (
            <div style={{ padding: '24px' }}>
                <table id="users-table" className="w-[100%]">
                    <thead className="h-[52px] uppercase">
                        <tr>
                            <th style={{ borderTopLeftRadius: '8px' }}>Data Richiesta</th>
                            <th>Mittente</th>
                            <th>Cliente Altro OdC</th>
                            <th>Protocollo Entrata</th>
                            <th>Stato</th>
                            <th>Funzionario</th>
                            <th>Data</th>
                            <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            complianceRequestState.allComplianceRequestsResponse?.data.map((request, index) =>
                                <tr
                                    key={index}
                                    style={{
                                        color: '#4D797F',
                                        background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                    }}
                                >
                                    <td>{formatterDate(request.date)}</td>
                                    <td>{request.sender}</td>
                                    <td>
                                        {
                                            request.otherOdcCustomer?.map((customerOtherOdc, index) => (
                                                <div>{customerOtherOdc.companyName} - {customerOtherOdc.cuaa} - {customerOtherOdc.clientCode}</div>
                                            ))
                                        }
                                    </td>
                                    <td>{request.requestProtocolInfo}</td>
                                    <td>
                                        {
                                            request.complianceRequestStates?.map((state, index) => (
                                                <div>{optionsFormatter(state.state, statoRichiestaComplianceRequest)}</div>
                                            ))
                                        }
                                    </td>
                                    <td>{request.officerInfo}</td>
                                    <td>{formatterDate(request.date)}</td>
                                    <td>
                                        <div style={{ display: 'flex', gap: '6px' }}>
                                            <button onClick={() => onEditHandle(request)}>
                                                <EditIcon color="orange" size={20} />
                                            </button>
                                            <button className="ml-2"
                                                onClick={() => {
                                                    if (request.id) {
                                                        setComplianceRequestToDelete(request.id)
                                                        setShowAlert(true)
                                                    }

                                                }}
                                            >
                                                <TrashIcon color="orange" size={20} />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )}
                    </tbody>
                </table>

                {
                    (showAlert && complianceRequestToDelete) && (
                        <Alert title="Conferma operazione" close={() => setShowAlert(false)}>
                            <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
                                <div className="pb-3">
                                    <div>
                                        Sei sicuro di voler eliminare questa richiesta?
                                    </div>
                                </div>
                                <div className="mt-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Button
                                        variant="outline"
                                        color="dimgrey"
                                        iconPosition="off"
                                        label="Annulla"
                                        size="md"
                                        onClick={() => setShowAlert(false)}
                                    />
                                    <div className="pl-8">
                                        <Button
                                            variant="solid"
                                            color="orange"
                                            iconPosition="off"
                                            label="Elimina"
                                            size="md"
                                            onClick={() => {
                                                dispatch(ComplianceRequestDelete(complianceRequestToDelete))
                                                setShowAlert(false)
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>
                        </Alert>
                    )
                }
            </div>
        )
    }

    return (
        <div style={{ padding: '24px' }}>
            <Banner
                type="error"
                visible={!sessionState.selected.customerId}
                label={"Devi prima selezionare un cliente per accedere all'elenco"}
                closeAction={function (): void {
                    alert("Devi prima selezionare un cliente per accedere all'elenco")
                }}
            />
        </div>
    )
}