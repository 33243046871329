import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../utils";
import { GetAllOdcDTO, GetAllOdcFiltersDTO, OdcDTO, OdcErrors } from "./dataService/dto";
import { NewOdcService } from "./dataService/service";

interface ODCState {
    // REQUEST
    odcCreationRequest: OdcDTO
    odcUpdateRequest: OdcDTO
    odcIdToUpdate: string

    // ERRORS
    odcErrors: OdcErrors

    // FILTERS
    getAllOdcFilters: GetAllOdcFiltersDTO

    // STATUS
    odcCreationStatus: PromiseStatuses
    odcUpdateStatus: PromiseStatuses
    odcDeleteStatus: PromiseStatuses
    getAllOdcStatus: PromiseStatuses
    getOdcByIdStatus: PromiseStatuses
    odcvalidationStatus: PromiseStatuses
    odcRemoveStatus: PromiseStatuses
    odcRestoreStatus: PromiseStatuses

    // RESPONSE
    getAllOdcResponse?: GetAllOdcDTO
    getOdcByIdResponse?: OdcDTO
}

const initialState: ODCState = {
    // REQUEST
    odcCreationRequest: {
        companyName: "",
        code: "",
        address: "",
        region: "",
        province: "",
        city: "",
        zipCode: "",
        phone: "",
        pec: "",
        email: "",
        nation: ""
    },
    odcUpdateRequest: {},
    odcIdToUpdate: '',

    // ERRORS
    odcErrors: {
        companyName: false,
        code: false
    },

    // FILTERS
    getAllOdcFilters: {
        itemsPerPage: 0,
        page: 0,
        order: true
    },

    // STATUS
    odcCreationStatus: 'idle',
    odcUpdateStatus: 'idle',
    odcDeleteStatus: 'idle',
    odcRemoveStatus: 'idle',
    odcRestoreStatus: 'idle',
    getAllOdcStatus: 'idle',
    getOdcByIdStatus: 'idle',
    odcvalidationStatus: 'idle'
}

export const OdcValidation = createAsyncThunk(
    'odc/Validation',
    async (request: OdcDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetOdcErrors())

        if(request.companyName === undefined || request.companyName === '') {
            thunkApi.dispatch(setValidateOdcCompanyName(true))
            isValid = false
        }

        if(request.code === undefined || request.code === '') {
            thunkApi.dispatch(setValidationOdcCode(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)

export const OdcCreation = createAsyncThunk(
    'odc/Creation',
    async (body: OdcDTO, thunkApi): Promise<void> => {
        const odcService = NewOdcService()
        return odcService.CreateODC(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const OdcUpdate = createAsyncThunk(
    'odc/Update',
    async (request: {body: OdcDTO, id: string}, thunkApi): Promise<void> => {
        const odcService = NewOdcService()
        return odcService.UpdateODC(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const OdcRemove = createAsyncThunk(
    'odc/Remove',
    async (id: string, thunkApi): Promise<void> => {
        const odcService = NewOdcService()
        return odcService.RemoveOdc(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const OdcRestore = createAsyncThunk(
    'odc/Restore',
    async (id: string, thunkApi): Promise<void> => {
        const odcService = NewOdcService()
        return odcService.RestoreOdc(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const OdcDelete = createAsyncThunk(
    'odc/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const odcService = NewOdcService()
        return odcService.DeleteODC(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllOdc = createAsyncThunk(
    'odc/GetAll',
    async (filters: GetAllOdcFiltersDTO, thunkApi): Promise<GetAllOdcDTO> => {
        const odcService = NewOdcService()
        return odcService.GetAllODC(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetOdcById = createAsyncThunk(
    'odc/GetById',
    async (id: string, thunkApi): Promise<OdcDTO> => {
        const odcService = NewOdcService()
        return odcService.GetODCById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

const OdcSlice = createSlice({
    name: 'odc/slice',
    initialState,
    reducers: {
        // REQUEST [CREATION]
        setOdcCreationCompanyName: (state, action) => {
            state.odcCreationRequest.companyName = action.payload !== '' ? action.payload : undefined
        },
        setOdcCreationCode: (state, action) => {
            state.odcCreationRequest.code = action.payload !== '' ? action.payload : undefined
        },
        setOdcCreationAddress: (state, action) => {
            state.odcCreationRequest.address = action.payload !== '' ? action.payload : undefined
        },
        setOdcCreationRegion: (state, action) => {
            state.odcCreationRequest.region = action.payload !== '' ? action.payload : undefined
        },
        setOdcCreationProvince: (state, action) => {
            state.odcCreationRequest.province = action.payload !== '' ? action.payload : undefined
        },
        setOdcCreationCity: (state, action) => {
            state.odcCreationRequest.city = action.payload !== '' ? action.payload : undefined
        },
        setOdcCreationZipcode: (state, action) => {
            state.odcCreationRequest.zipCode = action.payload !== '' ? action.payload : undefined
        },
        setOdcCreationPhone: (state, action) => {
            state.odcCreationRequest.phone = action.payload !== '' ? action.payload : undefined
        },
        setOdcCreationPec: (state, action) => {
            state.odcCreationRequest.pec = action.payload !== '' ? action.payload : undefined
        },
        setOdcCreationEmail: (state, action) => {
            state.odcCreationRequest.email = action.payload !== '' ? action.payload : undefined
        },
        setOdcCreationNation: (state, action) => {
            state.odcCreationRequest.nation = action.payload !== '' ? action.payload : undefined
        },
        resetOdcCreationRequest: (state) => {
            state.odcCreationRequest = {
                companyName: "",
                code: "",
                address: "",
                region: "",
                province: "",
                city: "",
                zipCode: "",
                phone: "",
                pec: "",
                email: "",
                nation: ""
            }
        },

        // REQUEST [UPDATE]
        setOdcIdToUpdate: (state, action) => {
            state.odcIdToUpdate = action.payload
        },
        setOdcUpdateCompanyName: (state, action) => {
            state.odcUpdateRequest.companyName = action.payload
        },
        setOdcUpdateCode: (state, action) => {
            state.odcUpdateRequest.code = action.payload
        },
        setOdcUpdateAddress: (state, action) => {
            state.odcUpdateRequest.address = action.payload
        },
        setOdcUpdateRegion: (state, action) => {
            state.odcUpdateRequest.region = action.payload
        },
        setOdcUpdateProvince: (state, action) => {
            state.odcUpdateRequest.province = action.payload
        },
        setOdcUpdateCity: (state, action) => {
            state.odcUpdateRequest.city = action.payload
        },
        setOdcUpdateZipcode: (state, action) => {
            state.odcUpdateRequest.zipCode = action.payload
        },
        setOdcUpdatePhone: (state, action) => {
            state.odcUpdateRequest.phone = action.payload
        },
        setOdcUpdatePec: (state, action) => {
            state.odcUpdateRequest.pec = action.payload
        },
        setOdcUpdateEmail: (state, action) => {
            state.odcUpdateRequest.email = action.payload
        },
        setOdcUpdateNation: (state, action) => {
            state.odcUpdateRequest.nation = action.payload
        },
        resetOdcUpdateRequest: (state) => {
            state.odcCreationRequest = {}
        },

        // ERRORS
        setValidateOdcCompanyName: (state, action) => {
            state.odcErrors.companyName = action.payload
        },
        setValidationOdcCode: (state, action) => {
            state.odcErrors.code = action.payload
        },
        resetOdcErrors: (state) => {
            state.odcErrors = {
                companyName: false,
                code: false
            }
        },

        // FILTERS
        setGetAllOdcItemsPerPage: (state, action) => {
            state.getAllOdcFilters.itemsPerPage = action.payload
        },
        setGetAllOdcPage: (state, action) => {
            state.getAllOdcFilters.page = action.payload
        },
        setGetAllOdcOrder: (state, action) => {
            state.getAllOdcFilters.order = action.payload
        },
        setGetAllOdcSort: (state, action) => {
            state.getAllOdcFilters.sort = action.payload
        },
        setGetAllOdcActive: (state, action) => {
            state.getAllOdcFilters.active = action.payload
        },
        resetGetAllOdcFilters: (state) => {
            state.getAllOdcFilters = {
                itemsPerPage: 0,
                page: 0,
                order: true
            }
        },

        // STATUS
        resetOdcCreationStatus: (state) => {
            state.odcCreationStatus = 'idle'
        },
        resetOdcUpdateStatus: (state) => {
            state.odcUpdateStatus = 'idle'
        },
        resetOdcDeleteStatus: (state) => {
            state.odcDeleteStatus = 'idle'
        },
        resetGetAllOdcStatus: (state) => {
            state.getAllOdcStatus = 'idle'
        },
        resetGetOdcByIdStatus: (state) => {
            state.getOdcByIdStatus = 'idle'
        },
        resetOdcValidationStatus: (state) => {
            state.odcvalidationStatus = 'idle'
        },
        resetOdcRemoveStatus: (state) => {
            state.odcRemoveStatus = 'idle'
        },
        resetOdcRestoreStatus: (state) => {
            state.odcRestoreStatus = 'idle'
        }
    },

    extraReducers(builder) {
        builder

            // [ODC]
            // CREATION
            .addCase(OdcCreation.pending, (state) => {
                state.odcCreationStatus = 'loading'
            })
            .addCase(OdcCreation.fulfilled, (state) => {
                state.odcCreationStatus = 'successfully'
            })
            .addCase(OdcCreation.rejected, (state) => {
                state.odcCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(OdcUpdate.pending, (state) => {
                state.odcUpdateStatus = 'loading'
            })
            .addCase(OdcUpdate.fulfilled, (state) => {
                state.odcUpdateStatus = 'successfully'
            })
            .addCase(OdcUpdate.rejected, (state) => {
                state.odcUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(OdcDelete.pending, (state) => {
                state.odcDeleteStatus = 'loading'
            })
            .addCase(OdcDelete.fulfilled, (state) => {
                state.odcDeleteStatus = 'successfully'
            })
            .addCase(OdcDelete.rejected, (state) => {
                state.odcDeleteStatus = 'failed'
            })

            // REMOVE
            .addCase(OdcRemove.pending, (state) => {
                state.odcRemoveStatus = 'loading'
            })
            .addCase(OdcRemove.fulfilled, (state) => {
                state.odcRemoveStatus = 'successfully'
            })
            .addCase(OdcRemove.rejected, (state) => {
                state.odcRemoveStatus = 'failed'
            })

            // RESTORE
            .addCase(OdcRestore.pending, (state) => {
                state.odcRestoreStatus = 'loading'
            })
            .addCase(OdcRestore.fulfilled, (state) => {
                state.odcRestoreStatus = 'successfully'
            })
            .addCase(OdcRestore.rejected, (state) => {
                state.odcRestoreStatus = 'failed'
            })

            // GET ALL
            .addCase(GetAllOdc.pending, (state) => {
                state.getAllOdcStatus = 'loading'
            })
            .addCase(GetAllOdc.fulfilled, (state, action) => {
                state.getAllOdcStatus = 'successfully'
                state.getAllOdcResponse = action.payload
            })
            .addCase(GetAllOdc.rejected, (state) => {
                state.getAllOdcStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetOdcById.pending, (state) => {
                state.getOdcByIdStatus = 'loading'
            })
            .addCase(GetOdcById.fulfilled, (state, action) => {
                state.getOdcByIdStatus = 'successfully'
                state.getOdcByIdResponse = action.payload
            })
            .addCase(GetOdcById.rejected, (state) => {
                state.getOdcByIdStatus = 'failed'
            })

            // VALIDATION
            .addCase(OdcValidation.pending, (state) => {
                state.odcvalidationStatus = 'loading'
            })
            .addCase(OdcValidation.fulfilled, (state) => {
                state.odcvalidationStatus = 'successfully'
            })
            .addCase(OdcValidation.rejected, (state) => {
                state.odcvalidationStatus = 'failed'
            })
    },
})

export const {
    //REQUEST
    setOdcCreationAddress,
    setOdcCreationCity,
    setOdcCreationCode,
    setOdcCreationCompanyName,
    setOdcCreationEmail,
    setOdcCreationNation,
    setOdcCreationPec,
    setOdcCreationPhone,
    setOdcCreationProvince,
    setOdcCreationRegion,
    setOdcCreationZipcode,
    setOdcIdToUpdate,
    setOdcUpdateAddress,
    setOdcUpdateCity,
    setOdcUpdateCode,
    setOdcUpdateCompanyName,
    setOdcUpdateEmail,
    setOdcUpdateNation,
    setOdcUpdatePec,
    setOdcUpdatePhone,
    setOdcUpdateProvince,
    setOdcUpdateRegion,
    setOdcUpdateZipcode,
    resetOdcCreationRequest,
    resetOdcUpdateRequest,

    // ERRORS
    setValidateOdcCompanyName,
    setValidationOdcCode,
    resetOdcErrors,

    // FILTERS
    setGetAllOdcActive,
    setGetAllOdcItemsPerPage,
    setGetAllOdcOrder,
    setGetAllOdcPage,
    setGetAllOdcSort,
    resetGetAllOdcFilters,

    // STATUS
    resetGetAllOdcStatus,
    resetGetOdcByIdStatus,
    resetOdcCreationStatus,
    resetOdcDeleteStatus,
    resetOdcUpdateStatus,
    resetOdcValidationStatus,
    resetOdcRemoveStatus,
    resetOdcRestoreStatus
} = OdcSlice.actions

export default OdcSlice.reducer