import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../lib/redux/hooks'
import { SelectCustom } from '../../../../ui/molecules/select'
import TextArea from '../../../../ui/molecules/textArea'
import { tipologiaRichiestaComplianceRequest } from '../../../../utils'
import '../styles.scss'
import Input from '../../../../ui/molecules/input'
import { ProtocolSelectByObject } from '../../../../ui/molecules/ProtocolSelectByObj'
import { setRequestDescription, setRequestNote, setRequestProduct, setRequestProtocolId, setRequestSender, setRequestType } from '../slice'
import { CustomersOtherOdCAdd } from '../components/extCustomers'

export function AddFormRequest() {
    const dispatch = useAppDispatch()
    const sessionState = useAppSelector(state => state.session)
    const complianceRequestState = useAppSelector(state => state.complianceRequest)
    return (
        <div>

            <div className="request-container">

                <div className='request-header'>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Richieste di Conformità </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}> Richiesta</span>
                    </div>
                </div>

                <div style={{ padding: '24px 24px 32px 24px' }}>
                    <div className='pb-5' style={{ gap: '24px', alignItems: 'center' }}>
                        <div>
                            <ProtocolSelectByObject
                                objectId='138'
                                error={complianceRequestState.complianceRequestErrors.requestProtocolId}
                                errorLabel='Inserire protocollo richiesta'
                                customerId={sessionState.selected.customerId!}
                                placeholder='Seleziona protocollo...'
                                type='entry'
                                onChange={(value) => dispatch(setRequestProtocolId(value))}
                            />
                        </div>

                    </div>
                    <div className='pb-5 borderBottomLightGreen' style={{ gap: '24px', alignItems: 'center' }}>
                        <div>
                            <div style={{ flex: '0.33' }}>
                                <div className="text-left">
                                    <span className="input-label">Mittente</span>
                                </div>
                                <Input
                                    error={complianceRequestState.complianceRequestErrors.sender}
                                    supportingText={complianceRequestState.complianceRequestErrors.sender ? 'Indicare Mittente' : undefined}
                                    placeholder="Mittente"
                                    onChangeText={(text) => { dispatch(setRequestSender(text)) }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='pb-5 borderBottomLightGreen' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                        <div style={{ flex: '0.5', textAlign: 'left' }}>

                            <SelectCustom
                                error={complianceRequestState.complianceRequestErrors.requestType}
                                errorLabel='Inserire protocollo richiesta'
                                label='Tipologia della Richiesta'
                                placeholder={'Seleziona Tipologia'}
                                options={tipologiaRichiestaComplianceRequest}
                                onChange={(value) => dispatch(setRequestType(value))}
                            />
                        </div>
                        <div style={{ flex: '0.5' }}>
                            <div style={{ flex: '0.33' }}>
                                <div className="text-left">
                                    <span className="input-label">Prodotto</span>
                                </div>
                                <Input
                                    placeholder="Prodotto"
                                    onChangeText={(text) => { dispatch(setRequestProduct(text)) }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='pb-5'>
                        <CustomersOtherOdCAdd />
                    </div>

                    <div className='mt-5' style={{ gap: '24px', alignItems: 'center' }}>
                        <div style={{ textAlign: 'left' }}>
                            <TextArea
                                error={complianceRequestState.complianceRequestErrors.description}
                                supportingText={complianceRequestState.complianceRequestErrors.description ? 'Indicare Descrizione' : undefined}
                                label='Descrizione Richiesta'
                                onChangeText={(text) => { dispatch(setRequestDescription(text)) }}
                            />
                        </div>


                    </div>

                    <div className='pb-5 mt-5' style={{ gap: '24px', alignItems: 'center' }}>

                        <div style={{ textAlign: 'left' }}>
                            <TextArea
                                label='Note'
                                onChangeText={(text) => { dispatch(setRequestNote(text)) }}
                            />
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}