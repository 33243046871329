import React, { useEffect, useState } from "react";
import { ServiceTypeBoxColorMap, ServiceTypeBoxProps } from "./serviceTypeBox.types";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { RoundedCloseIcon } from "../../../ui/icons/CloseIcon";
import { EditIcon } from "../../../ui/icons/edit";
import './styles.scss'
import Button from "../../../ui/molecules/button";
import { RoundedPlusIcon } from "../../../ui/icons/RoundedPlusIcon";
import { PopupForm } from "../../../ui/organisms/popupForm";
import { Alert } from "../../../ui/organisms/alert";
import { InfoBox } from "../../../ui/molecules/infoBox";
import { ServiceTypePopupAdd } from "./popup/add";
import { ServiceTypePopupEdit } from "./popup/edit";
import { GetAllServiceType, resetBWUpdateStatus, resetGetAllSTStatus, resetSTCreationRequest, resetSTCreationStatus, resetSTDeleteStatus, resetSTUpdateRequest, resetSTUpdateStatus, ServiceTypeDelete, setSTIdToUpdate, setSTUpdateAmountPerUnit, setSTUpdateCategory, setSTUpdateCode, setSTUpdateDescription, setSTUpdateNote, setSTUpdateUnitType } from "../slice";
import { ServiceTypeCategoryEnum, ServiceTypeDTO } from "../dataService/dto";
import { optionsFormatter, ServiceTypeUnitOptions } from "../../../utils";

export const ServiceTypeBox: React.FC<ServiceTypeBoxProps> = ({
    ...props
}) => {
    const dispatch = useAppDispatch()
    const serviceTypeState = useAppSelector(state => state.billingAndServiceType)
    const selectedColor = ServiceTypeBoxColorMap.get(props.category === ServiceTypeCategoryEnum.SERVICE ? 'green' : 'orange')!
    const [showPopupAdd, setShowPopupAdd] = useState<boolean>(false)
    const [showPopupEdit, setShowPopupEdit] = useState<boolean>(false)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [serviceToDel, setServiceToDel] = useState<ServiceTypeDTO | null>(null)

    const onEditHandler = (s: ServiceTypeDTO) => {
        dispatch(setSTIdToUpdate(s.id))
        dispatch(setSTUpdateCategory(s.category))
        dispatch(setSTUpdateCode(s.code))
        dispatch(setSTUpdateDescription(s.description))
        dispatch(setSTUpdateNote(s.note))
        dispatch(setSTUpdateUnitType(s.unitType !== null ? s.unitType : undefined))
        dispatch(setSTUpdateAmountPerUnit(s.amountPerUnit !== null ? s.amountPerUnit : undefined))
        setShowPopupEdit(true)
    }

    useEffect(() => {
        dispatch(GetAllServiceType({
            itemsPerPage: 0,
            page: 0,
            billingWorksheetId: props.billingWorksheetId
        }))
    }, [])

    useEffect(() => {
        if (serviceTypeState.serviceTypeCreationStatus === 'successfully') {
            dispatch(resetSTCreationStatus())
            dispatch(resetSTCreationRequest())
            dispatch(GetAllServiceType({
                itemsPerPage: 0,
                page: 0,
                billingWorksheetId: props.billingWorksheetId
            }))
        }
    }, [serviceTypeState.serviceTypeCreationStatus])

    useEffect(() => {
        if (serviceTypeState.serviceTypeUpdateStatus === 'successfully') {
            dispatch(resetSTUpdateStatus())
            dispatch(resetSTUpdateRequest())
            dispatch(GetAllServiceType({
                itemsPerPage: 0,
                page: 0,
                billingWorksheetId: props.billingWorksheetId
            }))
        }
    }, [serviceTypeState.serviceTypeUpdateStatus])

    useEffect(() => {
        if (serviceTypeState.serviceTypeDeleteStatus === 'successfully') {
            dispatch(resetSTDeleteStatus())
            setShowAlert(false)
            dispatch(GetAllServiceType({
                itemsPerPage: 0,
                page: 0,
                billingWorksheetId: props.billingWorksheetId
            }))
        }
    }, [serviceTypeState.serviceTypeDeleteStatus])

    useEffect(() => {
        if(serviceTypeState.billingWorksheetUpdateStatus === 'successfully') {
            dispatch(resetBWUpdateStatus())
            dispatch(GetAllServiceType({
                itemsPerPage: 0,
                page: 0,
                billingWorksheetId: props.billingWorksheetId
            }))
        }
    }, [serviceTypeState.billingWorksheetUpdateStatus])

    return (
        <div id="sc-box">
            <div className="sc-box-title text-left pb-1 mb-5" style={{ color: selectedColor.titleColor, borderBottom: '2px solid ' + selectedColor.borderBottomColor }}>
                {
                    props.category === ServiceTypeCategoryEnum.SERVICE ?
                        "Elenco Servizi" : "Elenco Spese Aggiuntive"
                }
            </div>
            {
                serviceTypeState.getAllServiceTypeResponse?.data.filter(st => st.category === props.category).map(s =>
                    <div className="row mb-5" style={{ backgroundColor: selectedColor.backgroundColor }}>
                        <div style={{ display: 'flex', gap: '12px' }}>
                            <button onClick={() => {
                                setServiceToDel(s)
                                setShowAlert(true)
                            }}>
                                <RoundedCloseIcon color={selectedColor.subtitleColor} />
                            </button>
                            <button onClick={() => {
                                onEditHandler(s)
                            }}>
                                <EditIcon color={selectedColor.subtitleColor} size={20} />
                            </button>
                        </div>
                        <div style={{ flex: 1 }}>
                            <div className="pb-2 pt-2" style={{ display: 'flex', borderBottom: '1px solid ' + selectedColor.borderBottomColor }}>
                                <div className="r-child-3">
                                    <div className="sc-box-subtitle" style={{ color: selectedColor.subtitleColor }}>
                                        Codice
                                    </div>
                                </div>
                                <div className="r-child-3">
                                    <div className="sc-box-subtitle" style={{ color: selectedColor.subtitleColor }}>
                                        Descrizione
                                    </div>
                                </div>
                                {
                                    props.category === ServiceTypeCategoryEnum.SERVICE && (
                                        <div className="r-child-3">
                                            <div className="sc-box-subtitle" style={{ color: selectedColor.subtitleColor }}>
                                                Unità di Calcolo
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    props.category === ServiceTypeCategoryEnum.SERVICE && (
                                        <div className="r-child-3">
                                            <div className="sc-box-subtitle" style={{ color: selectedColor.subtitleColor }}>
                                                Importo
                                            </div>
                                        </div>
                                    )
                                }
                                <div className="r-child-3">
                                    <div className="sc-box-subtitle" style={{ color: selectedColor.subtitleColor }}>
                                        Note
                                    </div>
                                </div>
                            </div>
                            <div className="pt-2" style={{ display: 'flex' }}>
                                <div className="r-child-3">
                                    <div className="sc-box-content" style={{ color: selectedColor.contentColor }}>
                                        {s.code}
                                    </div>
                                </div>
                                <div className="r-child-3">
                                    <div className="sc-box-content" style={{ color: selectedColor.contentColor }}>
                                        {s.description}
                                    </div>
                                </div>
                                {
                                    props.category === ServiceTypeCategoryEnum.SERVICE && (
                                        <div className="r-child-3">
                                            <div className="sc-box-content" style={{ color: selectedColor.contentColor }}>
                                                {optionsFormatter(s.unitType, ServiceTypeUnitOptions)}
                                            </div>
                                        </div>
                                    )
                                }
                                {
                                    props.category === ServiceTypeCategoryEnum.SERVICE && (
                                        <div className="r-child-3">
                                            <div className="sc-box-content" style={{ color: selectedColor.contentColor }}>
                                                {s.amountPerUnit}
                                            </div>
                                        </div>
                                    )
                                }

                                <div className="r-child-3">
                                    <div className="sc-box-content" style={{ color: selectedColor.contentColor }}>
                                        {s.note}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="solid"
                    color={props.category === ServiceTypeCategoryEnum.SERVICE ? 'dimgrey' : 'orange'}
                    iconPosition="left"
                    icon={<RoundedPlusIcon />}
                    size="md"
                    label={props.category === ServiceTypeCategoryEnum.SERVICE ? "Aggiungi Tipo di Servizio" : "Aggiungi Spesa Aggiuntiva"}
                    onClick={() => {
                        setShowPopupAdd(true)
                    }}
                />
            </div>
            {
                showPopupAdd &&
                <PopupForm title={props.category === ServiceTypeCategoryEnum.SERVICE ? 'Aggiungi Tipo di Servizio' : 'Aggiungi Spesa Aggiuntiva'}
                    headerGreen={props.category === ServiceTypeCategoryEnum.SERVICE}
                    headerOrange={props.category === ServiceTypeCategoryEnum.ADDITIONAL_EXPENSE} close={() => {
                        dispatch(resetSTCreationRequest())
                        setShowPopupAdd(false)
                    }}>
                    <ServiceTypePopupAdd close={() => {
                        dispatch(resetSTCreationRequest())
                        setShowPopupAdd(false)
                    }} isAdditional={props.category === ServiceTypeCategoryEnum.ADDITIONAL_EXPENSE} billingWorksheetId={props.billingWorksheetId} />
                </PopupForm>
            }
            {
                showPopupEdit && (
                    <PopupForm title={props.category === ServiceTypeCategoryEnum.SERVICE ? 'Modifica Tipo di Servizio' : 'Modifica Spesa Aggiuntiva'}
                        headerGreen={props.category === ServiceTypeCategoryEnum.SERVICE}
                        headerOrange={props.category === ServiceTypeCategoryEnum.ADDITIONAL_EXPENSE}
                        close={() => {
                            dispatch(resetSTUpdateRequest())
                            setShowPopupEdit(false)
                        }}>
                        <ServiceTypePopupEdit close={() => {
                            dispatch(resetSTUpdateRequest())
                            setShowPopupEdit(false)
                        }} isAdditional={props.category === ServiceTypeCategoryEnum.ADDITIONAL_EXPENSE} />
                    </PopupForm>
                )
            }
            {
                (showAlert && serviceToDel) && (
                    <Alert title="Conferma operazione" close={() => setShowAlert(false)}>
                        <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
                            <div className="pb-3">
                                <div className="pb-3">
                                    Sei sicuro di voler eliminare il tipo di servizio?
                                </div>
                                <div className="m-[8px]">
                                    <InfoBox
                                        color="green"
                                        freeText={{
                                            title: null,
                                            label: `${serviceToDel.code} (${serviceToDel.description})`
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="mt-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    variant="outline"
                                    color="dimgrey"
                                    iconPosition="off"
                                    label="Annulla"
                                    size="md"
                                    onClick={() => setShowAlert(false)}
                                />
                                <div className="pl-8">
                                    <Button
                                        variant="solid"
                                        color="orange"
                                        iconPosition="off"
                                        label="Elimina"
                                        size="md"
                                        onClick={() => {
                                            dispatch(ServiceTypeDelete(serviceToDel.id))
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                    </Alert>
                )
            }
        </div>
    )
}