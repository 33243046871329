import { ReactNode, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../lib/redux/hooks';
import { Layout } from "../../ui/layout";
import { keycloak } from "../../lib/keycloak"
import './style.scss';
import Select from 'react-select'
import Button from "../../ui/molecules/button";
import { useNavigate } from "react-router-dom";
import { getUsers, setHeadQuarterFilter, setStatusFilter } from "./slice";
import { UsersList } from "./usersList";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { SelectCustom } from "../../ui/molecules/select";
import { status, sot } from "../../utils";

interface Props {
    children: ReactNode
}

export function Users() {
    const usersState = useAppSelector(state => state.users)
    useEffect(() => {
        dispatch(getUsers(usersState.filters))
    }, [])

    useEffect(() => {
        dispatch(getUsers(usersState.filters))
    }, [usersState.userCreationStatus])


    const pathname = window.location.pathname

    const layoutState = useAppSelector(state => state.layout)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [selectSot, setSelectSot] = useState<string | boolean>(false)

    return (
        <Layout menuItem={MenuItems.USERS}  breadcrumbItems={['Dashboard','Utenti']}
            headerLabel="Lista Utenti"
            headerChildren={
                <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>


                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Nuovo Utente" color={"orange"}
                        onClick={() => {
                            navigate('/user')
                        }}
                    />
                </div>
            }
        >

            <div className="w-[100%] flex flex-col gap-[16px] filter" style={{ padding: '24px 0px 0px 0px' }}>
                <div className="flex flex-row gap-[16px]" style={{ flex: 1 }}>
                    <div style={{flex: 0.3}}>
                        <SelectCustom
                            placeholder={'Sede'}
                            options={sot}
                            onChange={(value) => {
                                dispatch(setHeadQuarterFilter(value))
                            }}
                        />

                    </div>

                    <div  style={{flex: 0.3}}>
                        <SelectCustom
                            placeholder={'Stato'}
                            options={status}
                            onChange={(value) => {
                                dispatch(
                                    setStatusFilter(value),
                                )
                                if(value !== undefined)
                                    setSelectSot(value)
                            }}
                        />

                    </div>

                    <div>
                        <span>
                            <Button
                                size={"lg"}
                                iconPosition={"off"}
                                variant={"link"}
                                label="Pulisci"
                                color={"gray"}
                                onClick={
                                    () => {
                                        dispatch(setHeadQuarterFilter(''))
                                    }
                                }
                            />
                        </span>
                    </div>

                    <div>
                        <span>
                            <Button
                                size={"lg"}
                                iconPosition={"off"}
                                variant={"outline"}
                                label="Cerca"
                                color={"blue"}
                                onClick={
                                    () => {
                                        dispatch(getUsers(usersState.filters))
                                    }
                                }
                            />
                        </span>
                    </div>

                </div>


            </div>
            <UsersList />
        </Layout>
    )
}