import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../../utils";
import { NewMeasureFiltersDTO, NewNonComplianceCodeDTO, OldMeasureFiltersDTO, OtherOdcMeasureErrorsDTO, OtherOdcMeasuresCreationUpdateDTO, OtherOdcMeasuresResponseDTO, OtherOdcNewMeasuresCreationUpdateDTO } from "./dto";
import { NewOldMeasureService } from "./service";
import { act } from "react";
import { setValidateNonComplianceCodeVersion } from "../../measures/slice";

export interface OtherOdcMeasuresState {
    OtherOdcMeasuresCreationUpdate: OtherOdcMeasuresCreationUpdateDTO
    OtherOdcNewMeasuresCreationUpdate: OtherOdcNewMeasuresCreationUpdateDTO
    OtherOdcMeasureResponse: OtherOdcMeasuresResponseDTO
    OtherOdcNewMeasureResponse: OtherOdcMeasuresResponseDTO

    // ERROR (TO DO)
    otherOdcMeasuserOldErrors: OtherOdcMeasureErrorsDTO
    filters: OldMeasureFiltersDTO
    filtersNew: NewMeasureFiltersDTO
    selected: string
    measureVersion: 'old' | 'new'
    editMeasureDescriptionArea?: NewNonComplianceCodeDTO
    //status
    OtherOdcMeasureResponseStatus: PromiseStatuses
    OtherOdcNewMeasureResponseStatus: PromiseStatuses
    OtherOdcNewMeasuresEditStatus: PromiseStatuses
    OtherOdcMeasuresEditStatus: PromiseStatuses
    OtherOdcMeasuresCreationStatus: PromiseStatuses
    OtherOdcMeasuresOldErrorsStatus: PromiseStatuses
    OtherOdcNewMeasuresCreationStatus: PromiseStatuses

}

const initialState: OtherOdcMeasuresState = {
    OtherOdcMeasuresCreationUpdate: {},
    OtherOdcNewMeasureResponse: {},
    OtherOdcNewMeasuresCreationUpdate: {},
    selected: '',
    OtherOdcMeasureResponse: {},
    measureVersion: 'old',
    //STATUS
    OtherOdcMeasureResponseStatus: 'idle',
    OtherOdcMeasuresCreationStatus: 'idle',
    OtherOdcNewMeasuresCreationStatus: 'idle',
    OtherOdcMeasuresOldErrorsStatus: 'idle',
    OtherOdcNewMeasureResponseStatus: 'idle',
    OtherOdcNewMeasuresEditStatus: 'idle',
    otherOdcMeasuserOldErrors: {
        measureDate: false,
        nonComplianceCode: false
    },
    filters: {
        page: 0,
        itemsPerPage: 0,
        customerId: ''
    },
    filtersNew: {
        page: 0,
        itemsPerPage: 0,
        customerId: ''
    },
    OtherOdcMeasuresEditStatus: 'idle'
}

export const CreateOldMeasure = createAsyncThunk(
    'oldMeasure/Creation',
    async (body: OtherOdcMeasuresCreationUpdateDTO, thunkApi): Promise<void> => {
        const oldMeasureService = NewOldMeasureService()
        return oldMeasureService.CreateOldMeasure(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const CreateNewMeasure = createAsyncThunk(
    'newMeasure/CreateNewMeasure',
    async (body: OtherOdcNewMeasuresCreationUpdateDTO, thunkApi): Promise<void> => {
        const oldMeasureService = NewOldMeasureService()
        return oldMeasureService.CreateNewMeasure(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const UpdateNewMeasureOtherOdc = createAsyncThunk(
    'newMeasure/UpdateNewMeasureOtherOdc',
    async (request: { body: OtherOdcNewMeasuresCreationUpdateDTO, id: string }, thunkApi): Promise<void> => {
        const oldMeasureService = NewOldMeasureService()
        return oldMeasureService.UpdateNewMeasureOtherOdc(request.id, request.body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllCustomerOldMeasure = createAsyncThunk(
    'oldMeasure/GetAllCustomerOldMeasure',
    async (filters: OldMeasureFiltersDTO, thunkApi): Promise<OtherOdcMeasuresResponseDTO> => {
        const oldMeasureService = NewOldMeasureService()
        return oldMeasureService.GetAllCustomerOldMeasure(filters)
    },
)

export const GetAllCustomerNewMeasure = createAsyncThunk(
    'newMeasure/GetAllCustomerNewMeasure',
    async (filtersNew: NewMeasureFiltersDTO, thunkApi): Promise<OtherOdcMeasuresResponseDTO> => {
        const oldMeasureService = NewOldMeasureService()
        return oldMeasureService.GetAllCustomerNewMeasure(filtersNew)
    },
)

export const EditOldMeasure = createAsyncThunk(
    'oldMeasure/Update',
    async (request: { body: OtherOdcMeasuresCreationUpdateDTO, id: string }, thunkApi): Promise<void> => {
        const oldMeasureService = NewOldMeasureService()
        return oldMeasureService.UpdateOldMewasureOtherOdc(request.id, request.body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const MeasuerOldOtherOdcValidation = createAsyncThunk(
    'oldMeasure/validation',
    async (request: OtherOdcMeasuresCreationUpdateDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetOtherOdcMeasureErrors())

        if (!request.measureDate) {

            thunkApi.dispatch(setValidateMeasureDate(true))
            isValid = false
        }
        if (!request.nonComplianceCode) {

            thunkApi.dispatch(setValidateNonComplianceCode(true))
            isValid = false
        }
        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
    }
)


const OtherOdcMeasuresSlice = createSlice({
    name: 'OtherOdcMeasures/slice',
    initialState,
    reducers: {
        setOtherOdcMeasureIsReleased: (state, action) => {
            state.OtherOdcMeasuresCreationUpdate.isCodexRealesed = action.payload
        },
        setOtherOdcMeasureSelected: (state, action) => {
            state.selected = action.payload
        },
        setOtherOdcMeasuresDate: (state, action) => {
            state.OtherOdcMeasuresCreationUpdate.measureDate = action.payload
        },
        setOtherOdcMeasuresOdc: (state, action) => {
            state.OtherOdcMeasuresCreationUpdate.odcId = action.payload
        },
        setOtherOdcMeasuresCustomerId: (state, action) => {
            state.OtherOdcMeasuresCreationUpdate.customerId = action.payload
        },
        setOtherOdcMeasuresNonComplianceCode: (state, action) => {
            state.OtherOdcMeasuresCreationUpdate.nonComplianceCode = action.payload
        },
        setOtherOdcMeasureNonComplianceDescription: (state, action) => {
            state.OtherOdcMeasuresCreationUpdate.nonComplianceDescription = action.payload
        },
        setOtherOdcMeasureDescription: (state, action) => {
            state.OtherOdcMeasuresCreationUpdate.measureDescription = action.payload
        },
        setOtherOdcMeasureNonComplianceOfficerId: (state, action) => {
            state.OtherOdcMeasuresCreationUpdate.officerId = action.payload.toString()
        },
        setMeasureVersion: (state, action) => {
            state.measureVersion = action.payload
        },

        //NEW MEASURE
        setOtherOdcNewMeasureNonComplianceDescription: (state, action) => {
            state.OtherOdcNewMeasuresCreationUpdate.nonComplianceDescription = action.payload
        },
        setOtherOdcNewMeasureDescription: (state, action) => {
            state.OtherOdcNewMeasuresCreationUpdate.measureDescription = action.payload
        },
        setOtherOdcNewMeasureNonComplianceOfficerId: (state, action) => {
            state.OtherOdcNewMeasuresCreationUpdate.officerId = action.payload.toString()
        },
        setOtherOdcNewMeasureIsReleased: (state, action) => {
            state.OtherOdcNewMeasuresCreationUpdate.isCodexRealesed = action.payload
        },
        setOtherOdcNewMeasuresDate: (state, action) => {
            state.OtherOdcNewMeasuresCreationUpdate.measureDate = action.payload
        },
        setOtherOdcNewMeasuresOdc: (state, action) => {
            state.OtherOdcNewMeasuresCreationUpdate.odcId = action.payload
        },
        setOtherOdcNewMeasuresCustomerId: (state, action) => {
            state.OtherOdcNewMeasuresCreationUpdate.customerId = action.payload
        },
        setOtherOdcNewMeasuresOldComplianceCode: (state, action) => {
            state.OtherOdcNewMeasuresCreationUpdate.oldNonComplianceCode = action.payload
        },
        setOtherOdcNewMeasuresNewComplianceCode: (state, action) => {
            state.OtherOdcNewMeasuresCreationUpdate.newNonComplianceCodeId = action.payload
        },
        setOtherOdcNewMeasuresNonComplianceVersion: (state, action) => {
            state.OtherOdcNewMeasuresCreationUpdate.nonComplianceCodeVersion = action.payload
        },
        setDescriptionsAreaCode: (state, action) => {
            state.editMeasureDescriptionArea = action.payload
        },

        //VALIDATION
        setValidateMeasureDate: (state, action) => {
            state.otherOdcMeasuserOldErrors.measureDate = action.payload
        },
        setValidateNonComplianceCode: (state, action) => {
            state.otherOdcMeasuserOldErrors.nonComplianceCode = action.payload
        },
        //filters 
        setOtherOdcMeasureFiltersCustomerId: (state, action) => {
            state.filters.customerId = action.payload
        },
        //filters 
        setOtherOdcNewMeasureFiltersCustomerId: (state, action) => {
            state.filtersNew.customerId = action.payload
        },
        setOtherOdcNewMeasureFiltersCodexReleased: (state, action) => {
            state.filtersNew.isCodexReleased = action.payload
        },
        setOtherOdcMeasureFiltersCodexReleased: (state, action) => {
            state.filters.isCodexReleased = action.payload
        },
        //RESET
        resetCreationStatus: (state) => {
            state.OtherOdcMeasuresCreationStatus = 'idle'
        },
        resetCreationNewStatus: (state) => {
            state.OtherOdcNewMeasuresCreationStatus = 'idle'
        },
        resetEditNewStatus: (state) => {
            state.OtherOdcNewMeasuresEditStatus = 'idle'
        },
        resetEditStatus: (state) => {
            state.OtherOdcMeasuresEditStatus = 'idle'
        },

        resetOtherOdcMeasureErrors: (state) => {
            state.otherOdcMeasuserOldErrors = {
                measureDate: false,
                nonComplianceCode: false
            }
        },

        resetValidationStatus: (state) => {
            state.OtherOdcMeasuresEditStatus = 'idle'
        },
    }
    ,
    extraReducers(builder) {
        builder
            // CREATION
            .addCase(GetAllCustomerOldMeasure.pending, (state) => {
                state.OtherOdcMeasureResponseStatus = 'loading'
            })
            .addCase(GetAllCustomerOldMeasure.fulfilled, (state, action) => {
                state.OtherOdcMeasureResponseStatus = 'successfully'
                state.OtherOdcMeasureResponse = action.payload
            })
            .addCase(GetAllCustomerOldMeasure.rejected, (state) => {
                state.OtherOdcMeasureResponseStatus = 'failed'
            })

            .addCase(CreateOldMeasure.pending, (state) => {
                state.OtherOdcMeasuresCreationStatus = 'loading'
            })
            .addCase(CreateOldMeasure.fulfilled, (state, action) => {
                state.OtherOdcMeasuresCreationStatus = 'successfully'
            })
            .addCase(CreateOldMeasure.rejected, (state) => {
                state.OtherOdcMeasuresCreationStatus = 'failed'
            })

            .addCase(EditOldMeasure.pending, (state) => {
                state.OtherOdcMeasuresEditStatus = 'loading'
            })
            .addCase(EditOldMeasure.fulfilled, (state, action) => {
                state.OtherOdcMeasuresEditStatus = 'successfully'
            })
            .addCase(EditOldMeasure.rejected, (state) => {
                state.OtherOdcMeasuresEditStatus = 'failed'
            })
            //NEW
            .addCase(GetAllCustomerNewMeasure.pending, (state) => {
                state.OtherOdcNewMeasureResponseStatus = 'loading'
            })
            .addCase(GetAllCustomerNewMeasure.fulfilled, (state, action) => {
                state.OtherOdcNewMeasureResponseStatus = 'successfully'
                state.OtherOdcNewMeasureResponse = action.payload
            })
            .addCase(GetAllCustomerNewMeasure.rejected, (state) => {
                state.OtherOdcNewMeasureResponseStatus = 'failed'
            })

            .addCase(UpdateNewMeasureOtherOdc.pending, (state) => {
                state.OtherOdcNewMeasuresEditStatus = 'loading'
            })
            .addCase(UpdateNewMeasureOtherOdc.fulfilled, (state, action) => {
                state.OtherOdcNewMeasuresEditStatus = 'successfully'
            })
            .addCase(UpdateNewMeasureOtherOdc.rejected, (state) => {
                state.OtherOdcNewMeasuresEditStatus = 'failed'
            })

            .addCase(CreateNewMeasure.pending, (state) => {
                state.OtherOdcNewMeasuresCreationStatus = 'loading'
            })
            .addCase(CreateNewMeasure.fulfilled, (state, action) => {
                state.OtherOdcNewMeasuresCreationStatus = 'successfully'
            })
            .addCase(CreateNewMeasure.rejected, (state) => {
                state.OtherOdcNewMeasuresCreationStatus = 'failed'
            })

            // VALIDATION
            .addCase(MeasuerOldOtherOdcValidation.pending, (state) => {
                state.OtherOdcMeasuresOldErrorsStatus = 'loading'
            })
            .addCase(MeasuerOldOtherOdcValidation.fulfilled, (state) => {
                state.OtherOdcMeasuresOldErrorsStatus = 'successfully'
            })
            .addCase(MeasuerOldOtherOdcValidation.rejected, (state) => {
                state.OtherOdcMeasuresOldErrorsStatus = 'failed'
            })

    },
})

export const {
    // REQUEST
    setOtherOdcMeasureIsReleased,
    setOtherOdcMeasuresOdc,
    setOtherOdcMeasuresCustomerId,
    setOtherOdcMeasuresNonComplianceCode,
    setOtherOdcMeasureNonComplianceDescription,
    setOtherOdcMeasureDescription,
    setOtherOdcMeasuresDate,
    setOtherOdcMeasureNonComplianceOfficerId,
    setOtherOdcMeasureSelected,
    setMeasureVersion,
    setOtherOdcNewMeasuresNonComplianceVersion,
    setOtherOdcNewMeasureFiltersCustomerId,

    //new measure
    setOtherOdcNewMeasureDescription,
    setOtherOdcNewMeasureIsReleased,
    setOtherOdcNewMeasureNonComplianceDescription,
    setOtherOdcNewMeasureNonComplianceOfficerId,
    setOtherOdcNewMeasuresCustomerId,
    setOtherOdcNewMeasuresDate,
    setOtherOdcNewMeasuresNewComplianceCode,
    setOtherOdcNewMeasuresOdc,
    setOtherOdcNewMeasuresOldComplianceCode,
    setDescriptionsAreaCode,

    //filters
    setOtherOdcMeasureFiltersCustomerId,
    setOtherOdcNewMeasureFiltersCodexReleased,
    setOtherOdcMeasureFiltersCodexReleased,

    //validate
    setValidateNonComplianceCode,
    setValidateMeasureDate,

    //reset
    resetCreationStatus,
    resetEditStatus,
    resetOtherOdcMeasureErrors,
    resetCreationNewStatus,
    resetEditNewStatus,
    resetValidationStatus,
} = OtherOdcMeasuresSlice.actions

export default OtherOdcMeasuresSlice.reducer