import React from "react";
import { InfoBoxColorMap, InfoBoxProps } from "./infoBox.types";
import './styles.scss'

export const InfoBox: React.FC<InfoBoxProps> = ({
    ...props
}) => {

    if (props.hidden)
        return <></>

    const selectedColor = InfoBoxColorMap.get(props.color)!

    return (
        <div className="infobox-box" style={{
            backgroundColor: selectedColor.backgroundColor,
            border: '1px solid ' + selectedColor.borderColor
        }}>
            {
                props.title && (
                    <div className='text-left'>
                        <span className='infobox-title' style={{
                            color: selectedColor.titleColor,
                            borderBottom: '2px solid ' + selectedColor.borderColor
                        }}>
                            {props.title}
                        </span>
                    </div>
                )
            }
            {
                props.freeText && (
                    <div>
                        {
                            props.freeText.title &&
                            <div className="content-bold text-left" style={{ color: selectedColor.titleColor }}>
                                {props.freeText.title}
                            </div>
                        }
                        <div className="content text-left pl-3" style={{ color: selectedColor.contentColor }}>
                            {props.freeText.label}
                        </div>
                    </div>
                )
            }
            {
                (props.content && props.content.length > 0) && (
                    <div className="infobox-content">
                        {
                            props.content.slice(0, -1).map(data =>
                                <div>
                                    <div className="infobox-data">
                                        <div className="content-bold" style={{ color: selectedColor.titleColor }}>
                                            {data.title}
                                        </div>
                                        <div className="content" style={{ color: selectedColor.contentColor }}>
                                            {data.label}
                                        </div>
                                    </div>
                                    <div className="line" style={{
                                        borderBottom: '1px solid ' + selectedColor.borderColor
                                    }}></div>
                                </div>
                            )
                        }

                        <div className="infobox-data">
                            <div className="content-bold" style={{ color: selectedColor.titleColor }}>
                                {props.content.slice(-1)[0].title}
                            </div>
                            <div className="content" style={{ color: selectedColor.contentColor }}>
                                {props.content.slice(-1)[0].label}
                            </div>
                        </div>

                        {
                            (props.textArea && props.textArea.length > 0) && (
                                <div className="line" style={{
                                    borderBottom: '1px solid ' + selectedColor.borderColor
                                }}></div>
                            )
                        }
                    </div>
                )
            }

            {
                (props.textArea && props.textArea.length > 0) && (
                    <div>
                        {
                            props.textArea.slice(0, -1).map(data =>
                                <div>
                                    {
                                        data.title &&
                                        <div className="content-bold text-left" style={{ color: selectedColor.titleColor }}>
                                            {data.title}
                                        </div>
                                    }
                                    {
                                        data.label !== null && (
                                            <div className="infobox-textarea" style={{
                                                border: '2px solid ' + selectedColor.borderColor
                                            }}>
                                                <div className="content text-left" style={{ color: selectedColor.contentColor }}>
                                                    {data.label}
                                                </div>
                                            </div>
                                        )
                                    }
                                    <div className="line" style={{
                                        borderBottom: '1px solid ' + selectedColor.borderColor
                                    }}></div>
                                </div>

                            )
                        }
                        <div className="content-bold text-left" style={{ color: selectedColor.titleColor }}>
                            {props.textArea.slice(-1)[0].title}
                        </div>
                        {
                            props.textArea.slice(-1)[0].label !== null && (
                                <div className="infobox-textarea" style={{
                                    border: '2px solid ' + selectedColor.borderColor
                                }}>
                                    <div className="content text-left" style={{ color: selectedColor.contentColor }}>
                                        {props.textArea.slice(-1)[0].label}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }

            {
                props.unorderedList && (
                    <div>
                        {
                            props.unorderedList.title &&
                            <div className="content-bold text-left" style={{ color: selectedColor.titleColor }}>
                                {props.unorderedList.title}
                            </div>
                        }
                        <ul style={{ listStyleType: 'disc', paddingLeft: '36px' }}>
                            {
                                props.unorderedList.items.map(i => (
                                    <li className="content text-left" style={{ color: selectedColor.contentColor }}>{i}</li>
                                ))
                            }
                        </ul>
                    </div>
                )
            }

            {
                props.table && (
                    <div>
                        {
                            props.table.title &&
                            <div className="content-bold text-left" style={{ color: selectedColor.titleColor }}>
                                {props.table.title}
                            </div>
                        }
                        <table className="w-[100%] infobox-table">
                            <thead className="h-[30px] uppercase">
                                <tr>
                                    <th className="width-[40px] rounded-tl-[12px]" style={{ backgroundColor: selectedColor.contentColor }}></th>
                                    {props.table.headers.map((h, idx) => (
                                        <th style={{ backgroundColor: selectedColor.contentColor }} key={idx}>{h}</th>
                                    ))}
                                    <th style={{ backgroundColor: selectedColor.contentColor }} className="width-[40px] rounded-tr-[12px]"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.table.values &&
                                    props.table.values.map((v, index) => (
                                        <tr
                                            key={index}
                                            style={{
                                                color: selectedColor.contentColor,
                                                background: index % 2 === 0 ? selectedColor.borderColor : '#FFFFFF'
                                            }}
                                        >
                                            <td></td>
                                            {v.map((label, idx) => (
                                                <td key={idx}>{label}</td>
                                            ))}
                                            <td></td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                )
            }
        </div>
    )
}