import { PrinterIcon } from '../../ui/icons/PrinterIcon'
import { RoundedPlusIcon } from '../../ui/icons/RoundedPlusIcon'
import Button from '../../ui/molecules/button'
import { SelectCustom } from '../../ui/molecules/select'
import { normativa, yearOptions } from '../../utils'
import './styles.scss'

export function SchedaCalcoloPrint() {
    return (
        <div>
            <div className="sc-container">
                {/* HEADER */}
                <div className='sc-header'>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Scheda di Calcolo </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title uppercase' style={{ color: '#d9e6e8' }}> Stampa</span>
                    </div>
                </div>

                <div style={{ padding: '24px 24px 32px 24px' }}>
                    <div className='pb-5' style={{ display: 'flex', gap: '24px' }}>
                        <div style={{ flex: '0.5' }}>
                            <div className="text-left">
                                <span className="input-label">Normativa</span>
                            </div>
                            <SelectCustom
                                placeholder='Specifica normativa...'
                                options={normativa}
                                onChange={(value) => { }}
                            />
                        </div>
                        <div style={{ flex: '0.5' }}>
                            <div className="text-left">
                                <span className="sc-text-bold">Anno</span>
                            </div>
                            <SelectCustom
                                placeholder='Specifica anno...'
                                options={yearOptions}
                            />
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant='solid'
                            color='dimgrey'
                            size='md'
                            iconPosition='left'
                            icon={<PrinterIcon color='rgba(77,121,127,1)' />}
                            label='Stampa Schede di calcolo'
                            onClick={() => { }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}