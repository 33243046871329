import { useNavigate } from "react-router-dom";
import { ArrowLeftIcon } from "../../../../ui/icons/arrow-left";
import { Layout } from "../../../../ui/layout";
import Button from "../../../../ui/molecules/button";
import { MenuItems } from "../../../../ui/organisms/navbar/dto";
import { ReinforcedControlMeasureUpdate, ReinforcedControlMeasureValidation, resetReinforcedControlMeasureValidation } from "../slice";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { useEffect } from "react";
import { ReinforcedControlMeasureEditForm } from "./forms";

export function EditReinforcedControlMeasure() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const reinforcedControlMeasureState = useAppSelector(state => state.reinforcedControlMeasure)

    useEffect(() => {
        if(reinforcedControlMeasureState.reinforcedControlMeasureValidationStatus === 'successfully') {
            dispatch(resetReinforcedControlMeasureValidation())
            dispatch(ReinforcedControlMeasureUpdate({body: reinforcedControlMeasureState.reinforcedControlMeasuresUpdateRequest, id: reinforcedControlMeasureState.reinforcedControlMeasureIdToUpdate}))
        }
    }, [reinforcedControlMeasureState.reinforcedControlMeasureValidationStatus])

    useEffect(() => {
        if(reinforcedControlMeasureState.reinforcedControlMeasureUpdateStatus === 'successfully') {
            navigate('/reinforcedControlMeasure')
        }
    }, [reinforcedControlMeasureState.reinforcedControlMeasureUpdateStatus])

    useEffect(() => {
        console.log(reinforcedControlMeasureState.reinforcedControlMeasuresUpdateRequest);
        
    }, [])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Provvedimenti', 'Modifica Misura di Controllo Rinforzato']}
            noPaddingLat
            headerLabel="Misura di Controllo Rinforzato"
            headerIcon={
                <button style={{ paddingTop: '12px' }} onClick={() => navigate('/reinforcedControlMeasure')}>
                    <ArrowLeftIcon />
                </button>
            }
            headerChildren={
                <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>
                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                        onClick={() => dispatch(ReinforcedControlMeasureValidation(reinforcedControlMeasureState.reinforcedControlMeasuresUpdateRequest))}
                    />
                </div>
            }
        >
            <div>
                <ReinforcedControlMeasureEditForm />
            </div>
        </Layout>
    )
}