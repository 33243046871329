import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../utils";
import { CompilationStateDTO, GetAllRIDEntitiesDTO, GetAllRIDFiltersDTO, RIDCreationDTO, RIDEntityDTO, RIDUpdateDTO, RIDUpdateStateDTO } from "./dataService/dto";
import { NewRIDService } from "./dataService/service";

interface RIDState {
    fromCreationToUpdate: boolean

    // REQUEST
    ridCreationRequest: RIDCreationDTO
    ridUpdateRequest: RIDUpdateDTO
    ridIdToUpdate: string
    ridUpdateStateRequest: RIDUpdateStateDTO

    // FILTERS
    getAllRidFilters: GetAllRIDFiltersDTO

    // ERRORS
    depProtError: boolean

    // STATUS
    ridCreationStatus: PromiseStatuses
    ridUpdateStatus: PromiseStatuses
    ridDeleteStatus: PromiseStatuses
    ridStateUpdateStatus: PromiseStatuses
    getAllRidStatus: PromiseStatuses
    getRidByIdStatus: PromiseStatuses

    // RESPONSE
    ridCreationResponse?: string
    getAllRidResponse?: GetAllRIDEntitiesDTO
    getRidByIdResponse?: RIDEntityDTO
}

const initialState: RIDState = {
    fromCreationToUpdate: false,

    // REQUEST
    ridCreationRequest: {
        customerId: '',
        departureProtocolId: '',
        object: 'RID',
        officerId: '1'
    },
    ridUpdateRequest: {
        attachmentIds: [],
        documentNotes: [],
        closingProtocolIds: []
    },
    ridUpdateStateRequest: {
        compilationState: CompilationStateDTO.COMPLETATA
    },
    ridIdToUpdate: '',

    // ERROR
    depProtError: false,

    // FILTERS
    getAllRidFilters: {
        itemsPerPage: 0,
        page: 0
    },

    // STATUS
    ridCreationStatus: 'idle',
    ridUpdateStatus: 'idle',
    ridDeleteStatus: 'idle',
    ridStateUpdateStatus: 'idle',
    getAllRidStatus: 'idle',
    getRidByIdStatus: 'idle'
}

export const RIDCreation = createAsyncThunk(
    'rid/Creation',
    async (body: RIDCreationDTO, thunkApi): Promise<string> => {
        const ridService = NewRIDService()

        return ridService.CreateRID(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const RIDUpdate = createAsyncThunk(
    'rid/Update',
    async (request: {body: RIDUpdateDTO, id: string}, thunkApi): Promise<void> => {
        const ridService = NewRIDService()

        return ridService.UpdateRID(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const RIDUpdateState = createAsyncThunk(
    'rid/UpdateState',
    async (request: {body: RIDUpdateStateDTO, id: string}, thunkApi): Promise<void> => {
        const ridService = NewRIDService()

        return ridService.UpdateRIDState(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const RIDDelete = createAsyncThunk(
    'rid/Delete',
    async (id: string, thunkApi): Promise<void> => {
        const ridService = NewRIDService()

        return ridService.DeleteRID(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllRID = createAsyncThunk(
    'rid/GetAll',
    async (filters: GetAllRIDFiltersDTO, thunkApi): Promise<GetAllRIDEntitiesDTO> => {
        const ridService = NewRIDService()

        return ridService.GetAllRID(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetRIDBYId = createAsyncThunk(
    'rid/GetById',
    async (id: string, thunkApi): Promise<RIDEntityDTO> => {
        const ridService = NewRIDService()

        return ridService.GetRIDById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const DownloadRidDocx = createAsyncThunk(
    'rid/DownloadridDoc',
    async (data:{id: string, infoType: string}, thunkApi): Promise<void> => {
        const ridService = NewRIDService()

        return ridService.DownloadRidDocx(data).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

const RIDSlice = createSlice({
    name: 'rid/ridSlice',
    initialState,
    reducers: {
        setFromCreationToUpdate: (state, action) => {
            state.fromCreationToUpdate = action.payload
        },

        // REQUEST [CREATION]
        setRIDCustomerId: (state, action) => {
            state.ridCreationRequest.customerId = action.payload
        },
        setRIDDepartureProtocolId: (state, action) => {
            state.ridCreationRequest.departureProtocolId = action.payload
        },
        setRIDObject: (state, action) => {
            state.ridCreationRequest.object = action.payload
        },
        setRIDNote: (state, action) => {
            state.ridCreationRequest.note = action.payload
        },
        setRIDDeadlineRequestedDocument: (state, action) => {
            state.ridCreationRequest.deadlineRequestedDocument = action.payload
        },
        setRIDReportingId: (state, action) => {
            state.ridCreationRequest.reportingId = action.payload
        },
        setRIDOfficerId: (state, action) => {
            state.ridCreationRequest.officerId = action.payload
        },
        setRIDDocumentalControlProtocolId: (state, action) => {
            state.ridCreationRequest.documentalControlProtocolId = action.payload
        },
        setRIDCertificateRequestProtocolId: (state, action) => {
            state.ridCreationRequest.certificateRequestProtocolId = action.payload
        },
        setRIDOther: (state, action) => {
            state.ridCreationRequest.other = action.payload
        },
        resetRIDCreationRequest: (state) => {
            state.ridCreationRequest = {
                customerId: '',
                object: 'RID',
                departureProtocolId: '',
                officerId: ''
            }
        },

        // REQUEST [UPDATE]
        setUpdateRIDDepartureProtocolId: (state, action) => {
            state.ridUpdateRequest.departureProtocolId = action.payload
        },
        setUpdateRIDObject: (state, action) => {
            state.ridUpdateRequest.object = action.payload
        },
        setUpdateRIDNote: (state, action) => {
            state.ridUpdateRequest.note = action.payload !== '' ? action.payload : undefined
        },
        setUpdateRIDDeadlineRequestedDocument: (state, action) => {
            state.ridUpdateRequest.deadlineRequestedDocument = action.payload
        },
        setUpdateRIDReportingId: (state, action) => {
            state.ridUpdateRequest.reportingId = action.payload
        },
        setUpdateRIDDocumentalControlProtocolId: (state, action) => {
            state.ridUpdateRequest.documentalControlProtocolId = action.payload
        },
        setUpdateRIDCertificateRequestProtocolId: (state, action) => {
            state.ridUpdateRequest.certificateRequestProtocolId = action.payload
        },
        setUpdateRIDOther: (state, action) => {
            state.ridUpdateRequest.other = action.payload !== '' ? action.payload : undefined
        },
        setUpdateRIDAttachmentIds: (state, action) => {
            state.ridUpdateRequest.attachmentIds = action.payload
        },
        addUpdateRIDDefaultAttachmentId: (state) => {
            if(state.ridUpdateRequest.attachmentIds === null)
                state.ridUpdateRequest.attachmentIds = []

            state.ridUpdateRequest.attachmentIds.push('')
        },
        setUpdateRIDAttachmentIdByIndex: (state, action) => {
            state.ridUpdateRequest.attachmentIds[action.payload.index] = action.payload.data
        },
        setUpdateRIDDocumentNotes: (state, action) => {
            state.ridUpdateRequest.documentNotes = action.payload
        },
        addUpdateRIDDefaultDocumentNote: (state) => {
            state.ridUpdateRequest.documentNotes.push('')
        },
        setUpdateRIDDocumentNoteByIndex: (state, action) => {
            state.ridUpdateRequest.documentNotes[action.payload.index] = action.payload.data
        },
        setUpdateRIDClosingProtocolIds: (state, action) => {
            state.ridUpdateRequest.closingProtocolIds = action.payload
        },
        setUpdateRIDClosedNote: (state, action) => {
            state.ridUpdateRequest.closedNote = action.payload !== '' ? action.payload : undefined
        },
        setRIDIdToUpdate: (state, action) => {
            state.ridIdToUpdate = action.payload
        },
        resetRIDUpdateRequest: (state) => {
            state.ridUpdateRequest = {
                attachmentIds: [],
                documentNotes: [],
                closingProtocolIds: []
            }
        },

        // REQUEST [UPDATE STATE]
        setRIDStateCompilationState: (state, action) => {
            state.ridUpdateStateRequest.compilationState = action.payload
        },
        resetRIDStateUpdateRequest: (state) => {
            state.ridUpdateStateRequest = {
                compilationState: CompilationStateDTO.COMPLETATA
            }
        },

        // FILTERS
        setGetAllRIDFiltersItemsPerPage: (state, action) => {
            state.getAllRidFilters.itemsPerPage = action.payload
        },
        setGetAllRIDFiltersPage: (state, action) => {
            state.getAllRidFilters.page = action.payload
        },
        setGetAllRIDFiltersCustomerId: (state, action) => {
            state.getAllRidFilters.customerId = action.payload
        },
        setGetAllRIDFiltersOrder: (state, action) => {
            state.getAllRidFilters.order = action.payload
        },
        setGetAllRIDFiltersSort: (state, action) => {
            state.getAllRidFilters.sort = action.payload
        },
        resetGetAllRIDFilters: (state) => {
            state.getAllRidFilters = {
                itemsPerPage: 0,
                page: 0
            }
        },

        // ERROR
        setDepartureProtocolError: (state, action) => {
            state.depProtError = action.payload
        },

        // STATUS
        resetRIDCreationStatus: (state) => {
            state.ridCreationStatus = 'idle'
        },
        resetRIDUpdateStatus: (state) => {
            state.ridUpdateStatus = 'idle'
        },
        resetRIDDeleteStatus: (state) => {
            state.ridDeleteStatus = 'idle'
        },
        resetStateUpdateStatus: (state) => {
            state.ridStateUpdateStatus = 'idle'
        },
        resetGetAllRIDStatus: (state) => {
            state.getAllRidStatus = 'idle'
        },
        resetGetRIDByIdStatus: (state) => {
            state.getRidByIdStatus = 'idle'
        },

        // RESPONSE
        resetGetRidByIdResponse: (state) => {
            state.getRidByIdResponse = undefined
        }
    },
    extraReducers(builder) {
        builder

            // CREATION
            .addCase(RIDCreation.pending, (state) => {
                state.ridCreationStatus = 'loading'
            })
            .addCase(RIDCreation.fulfilled, (state, action) => {
                state.ridCreationStatus = 'successfully'
                state.ridCreationResponse = action.payload
            })
            .addCase(RIDCreation.rejected, (state) => {
                state.ridCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(RIDUpdate.pending, (state) => {
                state.ridUpdateStatus = 'loading'
            })
            .addCase(RIDUpdate.fulfilled, (state) => {
                state.ridUpdateStatus = 'successfully'
            })
            .addCase(RIDUpdate.rejected, (state) => {
                state.ridUpdateStatus = 'failed'
            })

            // UPDATE STATE
            .addCase(RIDUpdateState.pending, (state) => {
                state.ridStateUpdateStatus = 'loading'
            })
            .addCase(RIDUpdateState.fulfilled, (state) => {
                state.ridStateUpdateStatus = 'successfully'
            })
            .addCase(RIDUpdateState.rejected, (state) => {
                state.ridStateUpdateStatus = 'failed'
            })

            // DELETE
            .addCase(RIDDelete.pending, (state) => {
                state.ridDeleteStatus = 'loading'
            })
            .addCase(RIDDelete.fulfilled, (state) => {
                state.ridDeleteStatus = 'successfully'
            })
            .addCase(RIDDelete.rejected, (state) => {
                state.ridDeleteStatus = 'failed'
            })

            // GET ALL
            .addCase(GetAllRID.pending, (state) => {
                state.getAllRidStatus = 'loading'
            })
            .addCase(GetAllRID.fulfilled, (state, action) => {
                state.getAllRidStatus = 'successfully'
                state.getAllRidResponse = action.payload
            })
            .addCase(GetAllRID.rejected, (state) => {
                state.getAllRidStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetRIDBYId.pending, (state) => {
                state.getRidByIdStatus = 'loading'
            })
            .addCase(GetRIDBYId.fulfilled, (state, action) => {
                state.getRidByIdStatus = 'successfully'
                state.getRidByIdResponse = action.payload
            })
            .addCase(GetRIDBYId.rejected, (state) => {
                state.getRidByIdStatus = 'failed'
            })
    },
})

export const {
    setFromCreationToUpdate,

    // REQUEST
    setRIDCertificateRequestProtocolId,
    setRIDDeadlineRequestedDocument,
    setRIDDepartureProtocolId,
    setRIDDocumentalControlProtocolId,
    setRIDIdToUpdate,
    setRIDNote,
    setRIDObject,
    setRIDOfficerId,
    setRIDOther,
    setRIDStateCompilationState,
    setRIDReportingId,
    setRIDCustomerId,
    setUpdateRIDAttachmentIdByIndex,
    setUpdateRIDAttachmentIds,
    setUpdateRIDCertificateRequestProtocolId,
    setUpdateRIDReportingId,
    setUpdateRIDClosedNote,
    setUpdateRIDClosingProtocolIds,
    setUpdateRIDDeadlineRequestedDocument,
    setUpdateRIDDepartureProtocolId,
    setUpdateRIDDocumentalControlProtocolId,
    setUpdateRIDDocumentNoteByIndex,
    setUpdateRIDDocumentNotes,
    addUpdateRIDDefaultDocumentNote,
    setUpdateRIDNote,
    setUpdateRIDObject,
    setUpdateRIDOther,
    addUpdateRIDDefaultAttachmentId,
    resetRIDCreationRequest,
    resetRIDStateUpdateRequest,
    resetRIDUpdateRequest,

    // FILTERS
    setGetAllRIDFiltersCustomerId,
    setGetAllRIDFiltersItemsPerPage,
    setGetAllRIDFiltersOrder,
    setGetAllRIDFiltersPage,
    setGetAllRIDFiltersSort,
    resetGetAllRIDFilters,

    // ERRORS
    setDepartureProtocolError,

    // STATUS
    resetGetAllRIDStatus,
    resetGetRIDByIdStatus,
    resetRIDCreationStatus,
    resetRIDDeleteStatus,
    resetRIDUpdateStatus,
    resetStateUpdateStatus,

    // RESPONSE
    resetGetRidByIdResponse

} = RIDSlice.actions

export default RIDSlice.reducer