import { useNavigate } from "react-router-dom"
import { Layout } from "../../../../ui/layout"
import Button from "../../../../ui/molecules/button"
import { MenuItems } from "../../../../ui/organisms/navbar/dto"
import { ArrowLeftIcon } from "../../../../ui/icons/arrow-left"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { ConversionPeriodCreation, ConversionPeriodUpdate } from "../slice"
import { PeriodoConversioneEditForms } from "./forms"
import { useEffect } from "react"

export function PeriodoConversioneEdit() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const conversionPeriodState = useAppSelector(state => state.conversionPeriod)

    useEffect(() => {
        if(conversionPeriodState.conversionPeriodUpdateStatus === 'successfully') {
            navigate('/periodoConversione')
        }
    }, [conversionPeriodState.conversionPeriodUpdateStatus])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Controllo', 'Modifica Richiesta Periodo di Conversione']}
            noPaddingLat
            headerLabel="Gestione Periodo di conversione"
            headerIcon={
                <button style={{paddingTop: '12px'}} onClick={() => navigate('/periodoConversione')}>
                    <ArrowLeftIcon />
                </button>
            }
            headerChildren={
                <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>
                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                        onClick={() => {
                            dispatch(ConversionPeriodUpdate({body: conversionPeriodState.conversionPeriodUpdateRequest, id: conversionPeriodState.conversionPeriodIdToUpdate}))
                        }}
                    />
                </div>
            }
        >
            <div>
                <PeriodoConversioneEditForms />
            </div>
        </Layout>
    )
}