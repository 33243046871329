import { CodexSampleAssignDTO, CodexSampleCreationDTO, CodexSampleCreationResponseDTO, CodexSampleDTO, CodexSampleEditStatusDTO, GetAllCodexSampleDTO, GetAllCodexSampleFiltersDTO } from "./dto";
import { CodexSampleServiceImpl } from "./serviceImpl";

export interface CodexSampleService {
    CreateEnvelopeSamples(data: CodexSampleCreationDTO): Promise<CodexSampleCreationResponseDTO>
    AssignEnvelopeSamples(data: CodexSampleAssignDTO): Promise<CodexSampleCreationResponseDTO>
    EditEnvelopeSamplesStatus(data: CodexSampleEditStatusDTO): Promise<CodexSampleCreationResponseDTO>
    GetAllEnvelopeSamples(filters: GetAllCodexSampleFiltersDTO): Promise<GetAllCodexSampleDTO>
    GetEnvelopeSampleById(id: string): Promise<CodexSampleDTO>
}

export function NewCodexSampleService(): CodexSampleService {
    return new CodexSampleServiceImpl()
}