import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks"
import { EditIcon } from "../../ui/icons/edit"
import { TrashIcon } from "../../ui/icons/trash"
import { Pagination } from "../../ui/organisms/pagination"
import { GetAllTypeRegisters, resetTypeRegisterCreationRequest, resetTypeRegisterCreationStatus, resetTypeRegisterDeleteStatus, resetTypeRegisterUpdateStatus, setGetAllTypeRegistersFiltersPage, setTypeRegisterIdToUpdate, setTypeRegisterUpdateCode, setTypeRegisterUpdateName, TypeRegisterDelete } from "../gestioneClienti/registriAziendali/slice"
import { PopupForm } from "../../ui/organisms/popupForm"
import { Alert } from "../../ui/organisms/alert"
import { TypeRegisterDTO } from "../gestioneClienti/registriAziendali/dataService/dto"
import Button from "../../ui/molecules/button"
import { TypeRegisterEditPopup } from "./popup"

export function TypeRegisterList() {
    const dispatch = useAppDispatch()
    const registerState = useAppSelector(state => state.register)
    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const [showPopup, setShowPopup] = useState<boolean>(false)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [typeRegToDelete, setTypeRegToDelete] = useState<TypeRegisterDTO | null>(null)

    useEffect(() => {
        dispatch(GetAllTypeRegisters({itemsPerPage: 30, page: registerState.getAllTypeRegistersFilters.page, order: false}))
    }, [registerState.getAllTypeRegistersFilters])

    useEffect(() => {
        if(registerState.typeRegisterCreationStatus === 'successfully') {
            dispatch(resetTypeRegisterCreationStatus())
            dispatch(resetTypeRegisterCreationRequest())
            setShowSuccess(true)
            dispatch(GetAllTypeRegisters({itemsPerPage: 30, page: registerState.getAllTypeRegistersFilters.page, order: false}))
        }
    }, [registerState.typeRegisterCreationStatus])

    useEffect(() => {
        if(registerState.typeRegisterDeleteStatus === 'successfully') {
            dispatch(resetTypeRegisterDeleteStatus())
            setShowAlert(false)
            setShowSuccess(true)
            dispatch(GetAllTypeRegisters({itemsPerPage: 30, page: registerState.getAllTypeRegistersFilters.page, order: false}))
        }
    }, [registerState.typeRegisterDeleteStatus])

    useEffect(() => {
        if(registerState.typeRegisterUpdateStatus === 'successfully') {
            dispatch(resetTypeRegisterUpdateStatus())
            setShowSuccess(true)
            setShowPopup(false)
            dispatch(GetAllTypeRegisters({itemsPerPage: 30, page: registerState.getAllTypeRegistersFilters.page, order: false}))
        }
    }, [registerState.typeRegisterUpdateStatus])

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setShowSuccess(false)
            }, 2000)
        }
    }, [showSuccess])

    const onEditHandler = (tr: TypeRegisterDTO) => {
        dispatch(setTypeRegisterIdToUpdate(tr.id))
        dispatch(setTypeRegisterUpdateName(tr.name))
        dispatch(setTypeRegisterUpdateCode(tr.code))
        setShowPopup(true)
    }

    return (
        <div className="w-[100%] flex flex-col gap-[16px] filter bg-brandPrimary-200" style={{ flex: 0.7, padding: '0px 24px 24px 24px', borderTopRightRadius: '20px' }}>
            <div className="w-[100%]">
            {
                (showSuccess) &&

                <div className="gap-[8px] text-left bg-green-300" style={{ margin: '24px', borderRadius: '8px' }}>
                    <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                        Dati salvati correttamente.
                    </span>
                </div>
            }
                <div style={{ padding: '24px 0' }}>
                    <table id="users-table" className="w-[100%]">
                        <thead className="h-[52px] uppercase">
                            <tr>
                                <th style={{ borderTopLeftRadius: '8px', width: '50%' }}>Nome</th>
                                <th style={{ width: '50%' }}>Codice</th>
                                <th style={{ borderTopRightRadius: '8px', width: '5%' }}></th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                registerState.getAllTypeRegistersResponse?.data.map((tr, index) =>
                                    <tr
                                        key={index}
                                        style={{
                                            color: '#4D797F',
                                            background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                        }}
                                    >
                                        <td>{tr.name}</td>
                                        <td>{tr.code}</td>
                                        <td className="w-[20px]">
                                            <div className="flex justify-content-between align-items-center">
                                                <button onClick={() => {
                                                    onEditHandler(tr)
                                                }}>
                                                    <EditIcon color={"Orange"} size={18} />
                                                </button>

                                                <div className="pl-2 flex align-items-center">
                                                    <button onClick={() => {
                                                        setTypeRegToDelete(tr)
                                                        setShowAlert(true)
                                                    }}>
                                                        <TrashIcon color={"Orange"} size={0} />
                                                    </button>
                                                </div>

                                            </div>
                                        </td>


                                    </tr>
                                )}
                        </tbody>
                    </table>
                    <Pagination
                        numbResults={registerState.getAllTypeRegistersResponse?.total || 0}
                        pages={registerState.getAllTypeRegistersResponse?.totalPage || 1}
                        currentPage={registerState.getAllTypeRegistersFilters.page || 0}
                        setPage={
                            (page) => {
                                dispatch(setGetAllTypeRegistersFiltersPage(page))
                            }
                        }

                    />
                </div>
            </div>
            {
                (showAlert && typeRegToDelete) && (
                    <Alert title="Conferma operazione" close={() => setShowAlert(false)}>
                        <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
                            <div className="pb-3">
                                <div>
                                    Sei sicuro di voler eliminare l'allegato?
                                </div>
                                <div className="mt-3 font-bold">
                                    {`${typeRegToDelete.name} - [${typeRegToDelete.code}]`}
                                </div>
                            </div>
                            <div className="mt-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    variant="outline"
                                    color="dimgrey"
                                    iconPosition="off"
                                    label="Annulla"
                                    size="md"
                                    onClick={() => setShowAlert(false)}
                                />
                                <div className="pl-8">
                                    <Button
                                        variant="solid"
                                        color="orange"
                                        iconPosition="off"
                                        label="Elimina"
                                        size="md"
                                        onClick={() => dispatch(TypeRegisterDelete(typeRegToDelete.id!))}
                                    />
                                </div>
                            </div>

                        </div>
                    </Alert>
                )
            }
            {
                showPopup && (
                    <PopupForm title="Modifica Tipologia" close={() => setShowPopup(false)}>
                        <TypeRegisterEditPopup close={() => setShowPopup(false)} />
                    </PopupForm>
                )
            }
        </div>
    )
}