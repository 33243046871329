import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import Button from "../../../../ui/molecules/button"
import Input from "../../../../ui/molecules/input"
import { SelectCustom } from "../../../../ui/molecules/select"
import TextArea from "../../../../ui/molecules/textArea"
import { ServiceTypeUnitOptions } from "../../../../utils"
import { ServiceTypeUpdate, setSTUpdateAmountPerUnit, setSTUpdateCode, setSTUpdateDescription, setSTUpdateNote, setSTUpdateUnitType } from "../../slice"

interface Props {
    close: () => void
    isAdditional: boolean
}

export function ServiceTypePopupEdit(props: Props) {
    const dispatch = useAppDispatch()
    const serviceTypeState = useAppSelector(state => state.billingAndServiceType)

    useEffect(() => {
        if(serviceTypeState.serviceTypeUpdateStatus === 'successfully') {
            props.close()
        }
    }, [serviceTypeState.serviceTypeUpdateStatus])

    return (
        <div>
            <div style={{ padding: '24px 24px 18px 24px' }}>
                <div>
                    <div className="text-left">
                        <span className="measure-text-bold">Codice</span>
                    </div>
                    <Input
                        value={serviceTypeState.serviceTypeUpdateRequest.code}
                        onChangeText={(text) => dispatch(setSTUpdateCode(text))}
                    />
                </div>
                <div>
                    <div className="text-left pt-5">
                        <span className="input-label">Descrizione</span>
                    </div>
                    <TextArea
                        value={serviceTypeState.serviceTypeUpdateRequest.description}
                        onChangeText={(text) => dispatch(setSTUpdateDescription(text))}
                    />
                </div>
                {
                    !props.isAdditional && (
                        <div className="pt-5" style={{ display: 'flex', gap: '24px' }}>
                            <div style={{ flex: '0.5' }}>
                                <div className="text-left">
                                    <span className="input-label">Unità di Calcolo</span>
                                </div>
                                <SelectCustom
                                    value={serviceTypeState.serviceTypeUpdateRequest.unitType}
                                    placeholder={'Seleziona unità...'}
                                    options={ServiceTypeUnitOptions}
                                    onChange={(value) => dispatch(setSTUpdateUnitType(value))}
                                />
                            </div>
                            <div style={{ flex: '0.5' }}>
                                <div className="text-left pt-1">
                                    <span className="input-label">Importo</span>
                                </div>
                                <Input
                                    value={serviceTypeState.serviceTypeUpdateRequest.amountPerUnit}
                                    onChangeText={(text) => dispatch(setSTUpdateAmountPerUnit(text))}
                                />
                            </div>
                        </div>
                    )
                }
                <div>
                    <div className="text-left pt-5">
                        <span className="input-label">Note</span>
                    </div>
                    <TextArea
                        value={serviceTypeState.serviceTypeUpdateRequest.note}
                        onChangeText={(text) => dispatch(setSTUpdateNote(text))}
                    />
                </div>
                <div className="pt-5" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        variant="solid"
                        color="orange"
                        size="md"
                        iconPosition="off"
                        label="Salva"
                        onClick={() => dispatch(ServiceTypeUpdate({body: serviceTypeState.serviceTypeUpdateRequest, id: serviceTypeState.serviceTypeIdToUpdate}))}
                    />
                </div>
            </div>
        </div>
    )
}