import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { PromiseStatuses } from "../../../../../utils"
import {  createWaiverFromDTO, GetAllWaiverFromFiltersDTO, GetAllWaiverFromResponse, createUpdateWaiverFromDTO } from "./dto"
import { NewWaiverFromCodexService } from "./service"
import { GetAllWaiverToFiltersDTO, GetAllWaiverToResponse } from "../../liberatorieToCodex/dataService/dto"

interface waiverFromCodexState {
    selectedTab: number,
    // RESPONSE
    getAllWaiverToResponse?: GetAllWaiverFromResponse

    //CREATE
    createWaiverFromCodex?: createWaiverFromDTO

    //UPDATE
    createUpdateWaiverFromCodex: createUpdateWaiverFromDTO

    waiverFromErrors: {
        odcReference: false
    },

    // FILTERS
    getAllWaiverFromFilters: GetAllWaiverFromFiltersDTO

    //STATUS
    GetAllWaiverFromCodexStatus: PromiseStatuses
    CreateWaiverFromCodexStatus: PromiseStatuses
    UpdateWaiverFromCodexStatus: PromiseStatuses
    waiverFromValidationStatus: PromiseStatuses
    downloadExcelStatus: PromiseStatuses
}

const initialState: waiverFromCodexState = {
    selectedTab: 0,
    getAllWaiverFromFilters: {
        page: 0,
        itemsPerPage: 0
    },
    createUpdateWaiverFromCodex: {
        customerId: '',
        departureProtocolId: '',
        odcReferenceId: ''
    },
    waiverFromErrors: {
        odcReference: false
    },
    GetAllWaiverFromCodexStatus: 'idle',
    CreateWaiverFromCodexStatus: 'idle',
    UpdateWaiverFromCodexStatus: 'idle',
    waiverFromValidationStatus: 'idle',
    downloadExcelStatus: 'idle',
}

export const GetAllWaiverFromCodex = createAsyncThunk(
    'Waiver/GetAllWaiverFromCodex',
    async (filters: GetAllWaiverFromFiltersDTO, thunkApi): Promise<GetAllWaiverFromResponse> => {
        const WaiverFromCodexService = NewWaiverFromCodexService();

        return WaiverFromCodexService.GetAllWaiverFromCodex(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const CreateWaiverFromCodex = createAsyncThunk(
    'Waiver/CreateWaiverFromCodex',
    async (data: createUpdateWaiverFromDTO, thunkApi): Promise<any> => {
        const WaiverFromCodexService = NewWaiverFromCodexService();

        return WaiverFromCodexService.CreateWaiverFromCodex(data).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetWaiverFromExcel = createAsyncThunk(
    'Waiver/GetWaiverFromExcel',
    async (filters: GetAllWaiverFromFiltersDTO, thunkApi): Promise<any> => {
        const WaiverFromCodexService = NewWaiverFromCodexService()
        return WaiverFromCodexService.GetWaiverFromExcel(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const UpdateWaiverFromCodex = createAsyncThunk(
    'Waiver/UpdateWaiverFromCodex',
    async (request: { data: createUpdateWaiverFromDTO, id: string }, thunkApi): Promise<any> => {
        const WaiverFromCodexService = NewWaiverFromCodexService();

        return WaiverFromCodexService.UpdateWaiverFromCodex(request.data, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const WaiverFromCodexValidation = createAsyncThunk(
    'waiver/WaiverFromCodex/validation',
    async (request: createUpdateWaiverFromDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetWaiverFromErrors())
        if(request.odcReferenceId === '') {
            thunkApi.dispatch(setValidateOdcReference(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)


const waiverFromCodexSlice = createSlice({
    name: 'WaiverFromCodexSlice/slice',
    initialState,
    reducers: {
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload
        },
        setFromWaiverDepartureProtocol: (state, action) => {
            state.createUpdateWaiverFromCodex.departureProtocolId = action.payload
        },

        setFromIdWaiver: (state, action) => {
            state.createUpdateWaiverFromCodex.id = action.payload
        },
        setFromCustomerId: (state, action) => {
            state.createUpdateWaiverFromCodex.customerId = action.payload
        },
        setFromOdc: (state, action) => {
            state.createUpdateWaiverFromCodex.odcReferenceId = action.payload
        },
        setFromExpirationDate: (state, action) => {
            state.createUpdateWaiverFromCodex.expirationDate = action.payload
        },
        setFromCompletePassing: (state, action) => {
            state.createUpdateWaiverFromCodex.completePassing = action.payload
        },
        setFromFieldsPassing: (state, action) => {
            state.createUpdateWaiverFromCodex.fieldsPassing = action.payload
        },
        setFromBreakoutDate: (state, action) => {
            state.createUpdateWaiverFromCodex.breakoutDate = action.payload
        },
        setFromNote: (state, action) => {
            state.createUpdateWaiverFromCodex.note = action.payload
        },
        setFromDeniedEntryProtocolId: (state, action) => {
            state.createUpdateWaiverFromCodex.deniedEntryProtocolId = action.payload
        },
        setFromAllowedEntryProtocolId: (state, action) => {
            state.createUpdateWaiverFromCodex.allowedEntryProtocolId = action.payload
        },
        setFromSendingDossier: (state, action) => {
            state.createUpdateWaiverFromCodex.sendingDossier = action.payload
        },
        setFromSenderOrReceiver: (state, action) => {
            state.createUpdateWaiverFromCodex.senderOrReceiver = action.payload
        },
        setFromProtocolDate: (state, action) => {
            state.createUpdateWaiverFromCodex.protocolDate = action.payload
        },
        setFromNotificationChangeProtocolId: (state, action) => {
            state.createUpdateWaiverFromCodex.notificationChangeProtocolId = action.payload
        },
        setFromSubmissionNotificationOdcDate: (state, action) => {
            state.createUpdateWaiverFromCodex.submissionNotificationDate = action.payload
        },
        setFromNotificationSubmissionProtocolId: (state, action) => {
            state.createUpdateWaiverFromCodex.notificationSubmissionProtocolId = action.payload
        },

        //filters 
        setYearFilter: (state, action) => {
            state.getAllWaiverFromFilters.year = action.payload
        },
        setStatusFilter: (state, action) => {
            state.getAllWaiverFromFilters.status = action.payload
        },

        //ERRORS
        setValidateOdcReference: (state, action) => {
            state.waiverFromErrors.odcReference = action.payload
        },


        //RESET
        resetWaiverFromFilters: (state) => {
            state.getAllWaiverFromFilters = {}
        },
        resetWaiverFromUpdateStatus: (state) => {
            state.UpdateWaiverFromCodexStatus = 'idle'
        },

        resetWaiverFromCreateStatus: (state) => {
            state.CreateWaiverFromCodexStatus = 'idle'
        },

        resetWaiverFromRequest: (state) => {
            state.createUpdateWaiverFromCodex = {
                customerId: '',
                departureProtocolId: ''
            }
        },

        resetWaiverFromErrors: (state) => {
            state.waiverFromErrors = {
                odcReference: false,
            }
        },
    },
    extraReducers(builder) {
        builder

            //CREATE
            .addCase(CreateWaiverFromCodex.pending, (state) => {
                state.CreateWaiverFromCodexStatus = 'loading'
            })
            .addCase(CreateWaiverFromCodex.fulfilled, (state, action) => {
                state.CreateWaiverFromCodexStatus = 'successfully'
            })
            .addCase(CreateWaiverFromCodex.rejected, (state) => {
                state.CreateWaiverFromCodexStatus = 'failed'
            })

            //UPDATE
            .addCase(UpdateWaiverFromCodex.pending, (state) => {
                state.UpdateWaiverFromCodexStatus = 'loading'
            })
            .addCase(UpdateWaiverFromCodex.fulfilled, (state, action) => {
                state.UpdateWaiverFromCodexStatus = 'successfully'
            })
            .addCase(UpdateWaiverFromCodex.rejected, (state) => {
                state.UpdateWaiverFromCodexStatus = 'failed'
            })

            // GET ALL
            .addCase(GetAllWaiverFromCodex.pending, (state) => {
                state.GetAllWaiverFromCodexStatus = 'loading'
            })
            .addCase(GetAllWaiverFromCodex.fulfilled, (state, action) => {
                state.GetAllWaiverFromCodexStatus = 'successfully'
                state.getAllWaiverToResponse = action.payload
            })
            .addCase(GetAllWaiverFromCodex.rejected, (state) => {
                state.GetAllWaiverFromCodexStatus = 'failed'
            })

            // VALIDATION (Obj/Att)
            .addCase(WaiverFromCodexValidation.pending, (state) => {
                state.waiverFromValidationStatus = 'loading'
            })
            .addCase(WaiverFromCodexValidation.fulfilled, (state) => {
                state.waiverFromValidationStatus = 'successfully'
            })
            .addCase(WaiverFromCodexValidation.rejected, (state) => {
                state.waiverFromValidationStatus = 'failed'
            })

            // download Excel
            .addCase(GetWaiverFromExcel.pending, (state) => {
                state.downloadExcelStatus = 'loading'
            })
            .addCase(GetWaiverFromExcel.fulfilled, (state) => {
                state.downloadExcelStatus = 'successfully'
            })
            .addCase(GetWaiverFromExcel.rejected, (state) => {
                state.downloadExcelStatus = 'failed'
            })
    }
})

export const {
    setSelectedTab,
    setStatusFilter,
    setYearFilter,
    setFromWaiverDepartureProtocol,
    setFromAllowedEntryProtocolId,
    setFromBreakoutDate,
    setFromCompletePassing,
    setFromCustomerId,
    setFromDeniedEntryProtocolId,
    setFromExpirationDate,
    setFromFieldsPassing,
    setFromNote,
    setFromIdWaiver,
    setFromNotificationChangeProtocolId,
    setFromOdc,
    setFromProtocolDate,
    setFromSenderOrReceiver,
    setFromSendingDossier,
    setFromSubmissionNotificationOdcDate,
    setFromNotificationSubmissionProtocolId,
    //errors
    setValidateOdcReference,
    //reset
    resetWaiverFromFilters,
    resetWaiverFromCreateStatus,
    resetWaiverFromRequest,
    resetWaiverFromUpdateStatus,
    resetWaiverFromErrors
} = waiverFromCodexSlice.actions

export default waiverFromCodexSlice.reducer