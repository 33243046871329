import { useAppSelector } from "../../../lib/redux/hooks";
import { Layout } from "../../../ui/layout";
import { Banner } from "../../../ui/organisms/banner";
import { MenuItems } from "../../../ui/organisms/navbar/dto";
import { SchedaCalcoloCustomerList } from "./list";

export function SchedaCalcoloCustomer() {
    const sessionState = useAppSelector(state => state.session)

    if (sessionState.selected.customerId)
        return (
            <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Amministrazione', 'Scheda di Calcolo']}
                noPaddingLat
                headerLabel="Scheda di Calcolo"
                customerName={sessionState.selected.customerName ? sessionState.selected.customerName : undefined}
                customerCode={sessionState.selected.customerCode ? sessionState.selected.customerCode : undefined}
            >
                <div>
                    <SchedaCalcoloCustomerList />
                </div>
            </Layout>
        )

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Amministrazione', 'Scheda di Calcolo']}
            noPaddingLat
            headerLabel={"Scheda di Calcolo"}
            customerName={sessionState.selected.customerName ? sessionState.selected.customerName : undefined}
            customerCode={sessionState.selected.customerCode ? sessionState.selected.customerCode : undefined}
        >
            <Banner
                type="error"
                visible={!sessionState.selected.customerId}
                label={"Devi prima selezionare un cliente per accedere all'elenco"}
                closeAction={function (): void {
                    alert("Devi prima selezionare un cliente per accedere all'elenco")
                }}
            />
        </Layout>
    )
}