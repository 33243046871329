import { useAppDispatch, useAppSelector } from '../../../../../lib/redux/hooks'
import { Calendar } from '../../../../../ui/molecules/calendar'
import { InfoBox } from '../../../../../ui/molecules/infoBox'
import { ProtocolSearch } from '../../../../../ui/molecules/ProtocolSearch'
import { SelectCustom } from '../../../../../ui/molecules/select'
import TextArea from '../../../../../ui/molecules/textArea'
import { formatterDate } from '../../../../../utils'
import { MeasureStatusOptions, RecourseResultOptions } from '../../../../../utils/measuresUtils'
import '../../../styles.scss'
import { MeasureVersionEnum } from '../../dataService/dto'
import { setNewMeasureUpdateActionPlaneProtocolId, setNewMeasureUpdateAppealReportDate, setNewMeasureUpdateAppealReportProtocolId, setNewMeasureUpdateClosingNote, setNewMeasureUpdatePresentationDate, setNewMeasureUpdatePresentationProtocolId, setNewMeasureUpdateRecourseDetail, setNewMeasureUpdateRecourseNote, setNewMeasureUpdaterecourseResult, setNewMeasureUpdateStatus, setNewMeasureUpdateStatusProtocolId } from '../../slice'

export function AddNewCodexMeasureStep2Right() {
    const dispatch = useAppDispatch()
    const newMeasureState = useAppSelector(state => state.newMeasure)

    return (
        <div>
            <div style={{ padding: '24px 24px 32px 24px' }}>
                <div className='text-left'>
                    <span className='rid-title' style={{ color: '#4D797F' }}>Provvedimenti </span>
                    <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                    <span className='rid-title' style={{ color: '#314D51' }}> Stato</span>
                </div>

                <div className="text-left pb-5 pt-5" style={{ display: 'flex', gap: '36px', alignItems: 'center', borderBottomWidth: 1 }}>
                    <span className="measure-text-bold">Data Ricezione Provvedimento: </span>
                    <span className="measure-text-content">{formatterDate(newMeasureState.getNewMeasureByIdResponse?.measureDate)}</span>
                </div>

                {
                    newMeasureState.getNewMeasureByIdResponse?.nonComplianceCodeVersion === MeasureVersionEnum.NEW && (
                        <div className='pb-5'>
                            <InfoBox
                                color='green'
                                title='Termine delle Misure'
                                content={newMeasureState.getAllAppliedMeasureResponse?.data.map(am => {
                                    return {
                                        title: am.code, label: formatterDate(am.endingDate)
                                    }
                                })}
                            />
                        </div>
                    )
                }

                <div className='pb-5'>
                    <div className="text-left">
                        <span className="measure-text-bold">Stato del Provvedimento</span>
                    </div>
                    <SelectCustom
                        value={newMeasureState.newMeasureUpdateRequest.status}
                        placeholder={'Seleziona stato...'}
                        options={MeasureStatusOptions}
                        onChange={(value) => dispatch(setNewMeasureUpdateStatus(value))}
                    />
                </div>

                <div className='pb-5'>
                    <div className="text-left">
                        <span className="input-label">Protocollo Stato</span>
                    </div>
                    <ProtocolSearch
                        type="entry"
                        value={newMeasureState.newMeasureUpdateRequest.statusProtocolId}
                        onSelect={(value) => dispatch(setNewMeasureUpdateStatusProtocolId(value))}
                    />
                </div>

                <div className='pb-5'>
                    <div className="text-left">
                        <span className="input-label">Protocollo del piano d'azione per la correzione della non conformità</span>
                    </div>
                    <ProtocolSearch
                        type="entry"
                        value={newMeasureState.newMeasureUpdateRequest.actionPlaneProtocolId}
                        onSelect={(value) => dispatch(setNewMeasureUpdateActionPlaneProtocolId(value))}
                    />
                </div>

                <div className='pb-5'>
                    <div className="text-left">
                        <span className="input-label">Note Chiusura</span>
                    </div>
                    <TextArea
                        value={newMeasureState.newMeasureUpdateRequest.closingNote}
                        onChangeText={(text) => dispatch(setNewMeasureUpdateClosingNote(text))}
                    />
                </div>

                <div className='text-left pt-5' style={{ borderTop: '1px solid #74A4A9' }}>
                    <span className='rid-title' style={{ color: '#4D797F' }}>Provvedimenti </span>
                    <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                    <span className='rid-title' style={{ color: '#314D51' }}> Gestione Ricorso</span>
                </div>

                <div className="text-left pt-5">
                    <span className="input-label">Data Presentazione</span>
                </div>
                <Calendar
                    selected={newMeasureState.newMeasureUpdateRequest.presentationDate}
                    onChange={(date) => dispatch(setNewMeasureUpdatePresentationDate(date))}
                />

                <div className="text-left pt-5">
                    <span className="input-label">Protocollo Presentazione</span>
                </div>
                <ProtocolSearch
                    type="departure"
                    value={newMeasureState.newMeasureUpdateRequest.presentationProtocolId}
                    onSelect={(value) => dispatch(setNewMeasureUpdatePresentationProtocolId(value))}
                />

                <div className='pb-5' style={{ borderBottomWidth: '1px' }}>
                    <div className="text-left pt-5">
                        <span className="input-label">Dettaglio Ricorso</span>
                    </div>
                    <TextArea
                        value={newMeasureState.newMeasureUpdateRequest.recourseDetail}
                        onChangeText={(text) => dispatch(setNewMeasureUpdateRecourseDetail(text))}
                    />
                </div>

                <div className='pt-5'>
                    <div className="text-left">
                        <span className="input-label">Data Verbale Giunta d'Appello</span>
                    </div>
                    <Calendar
                        selected={newMeasureState.newMeasureUpdateRequest.appealReportDate}
                        onChange={(date) => dispatch(setNewMeasureUpdateAppealReportDate(date))}
                    />
                </div>

                <div className="text-left pt-5">
                    <span className="input-label">Protocollo Verbale Giunta d'Appello</span>
                </div>
                <ProtocolSearch
                    type="departure"
                    value={newMeasureState.newMeasureUpdateRequest.appealReportProtocolId}
                    onSelect={(value) => dispatch(setNewMeasureUpdateAppealReportProtocolId(value))}
                />

                <div className="text-left pt-5">
                    <span className="input-label">Esito</span>
                </div>
                <SelectCustom
                    value={newMeasureState.newMeasureUpdateRequest.recourseResult}
                    placeholder={'Seleziona risultato...'}
                    options={RecourseResultOptions}
                    onChange={(value) => dispatch(setNewMeasureUpdaterecourseResult(value))}
                />

                <div className='pb-5'>
                    <div className="text-left">
                        <span className="input-label">Note</span>
                    </div>
                    <TextArea
                        value={newMeasureState.newMeasureUpdateRequest.recourseNote}
                        onChangeText={(text) => dispatch(setNewMeasureUpdateRecourseNote(text))}
                    />
                </div>
            </div>
        </div>
    )
}