import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { Layout } from "../../ui/layout";
import Button from "../../ui/molecules/button";
import { ClientBanner } from "../../ui/molecules/clientBanner";
import { Banner } from "../../ui/organisms/banner";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { attivita_bio, normativa, optionsFormatter } from "../../utils";
import { CartellaAziendaleActiveList } from "./list/activeList";
import { CartellaAziendaleHistoryList } from "./list/historyList";
import { CompanyFolderBulkUpdate, GetAllCompanyFolders, resetCFDetailsUpdateStatus, resetGetAllCFDetailsStatus, setCartelleAziendali, setGetAllCFFiltersCustomerId, setHistoryView, setUpdateActiveCFDetailsRequest, setUpdateCFHistoryDetailsRequest } from "./slice";
import { GetAllEntryProtocolAttachments, resetGetAllEntryProtocolAttachmentsStatus } from "../protocol/entry/slice";
import { AttachmentCADTO, CompanyFolderDetailBulkUpdateDTO, CompanyFolderDetailDTO } from "./dataService/dto";

export function CartellaAziendale() {
    const dispatch = useAppDispatch()
    const sessionState = useAppSelector(state => state.session)
    const entryProtocolState = useAppSelector(state => state.entryProtocol)
    const cartellaAziendaleState = useAppSelector(state => state.cartellaAziendale)
    const [showSuccess, setShowSuccess] = useState<boolean>(false)

    const handleOnSave = () => {
        const activeBulk: CompanyFolderDetailBulkUpdateDTO[] = cartellaAziendaleState.activeCFDetailsUpdateRequest.map(actDet => {
            return {
                id: actDet.id,
                companyFolderUpdateDTO: {
                    deliveredOnHeadquarter: actDet.deliveredOnHeadquarter,
                    historic: actDet.historic,
                    conformityEvaluation: actDet.conformityEvaluation,
                    note: actDet.note
                }
            } as CompanyFolderDetailBulkUpdateDTO
        })
        const historicBulk: CompanyFolderDetailBulkUpdateDTO[] = cartellaAziendaleState.historyCFDetailsUpdateRequest.map(histDet => {
            return {
                id: histDet.id,
                companyFolderUpdateDTO: {
                    deliveredOnHeadquarter: histDet.deliveredOnHeadquarter,
                    historic: histDet.historic,
                    conformityEvaluation: histDet.conformityEvaluation,
                    note: histDet.note
                }
            } as CompanyFolderDetailBulkUpdateDTO
        })
        dispatch(CompanyFolderBulkUpdate([...activeBulk, ...historicBulk]))
    }

    const genericsFilterPredicate = (norm: string): boolean | undefined => {
        return (norm !== 'NORMA_BIO' && norm !== 'GLOBAL_GAP' && norm !== 'PRODUZIONE_INTEGRATA') ? true : undefined
    }

    useEffect(() => {
        dispatch(GetAllEntryProtocolAttachments({
            bio: sessionState.selected.customerNorm === 'NORMA_BIO' ? true : undefined,
            globalGap: sessionState.selected.customerNorm === 'GLOBAL_GAP' ? true : undefined,
            sqnpi: sessionState.selected.customerNorm === 'PRODUZIONE_INTEGRATA' ? true : undefined,
            generics: genericsFilterPredicate(sessionState.selected.customerNorm!),
            itemsPerPage: 0,
            page: 0
        }))

        dispatch(setGetAllCFFiltersCustomerId(sessionState.selected.customerId))

        dispatch(GetAllCompanyFolders({
            customerId: sessionState.selected.customerId!,
            itemsPerPage: 0,
            page: 0
        }))
    }, [])

    useEffect(() => {
        if (entryProtocolState.getAllEntryProtocolAttachmentsStatus === 'successfully' &&
            entryProtocolState.getAllEntryProtocolAttachmentsResponse !== undefined
        ) {
            dispatch(resetGetAllEntryProtocolAttachmentsStatus())
            const companyFolderAtts: AttachmentCADTO[] = entryProtocolState.getAllEntryProtocolAttachmentsResponse.data.map(epAtt => {
                return {
                    id: epAtt.id,
                    code: epAtt.coding,
                    attachmentName: epAtt.description,
                    protocolDetailIds: []
                } as unknown as AttachmentCADTO
            })

            dispatch(setCartelleAziendali(companyFolderAtts))
        }
    }, [entryProtocolState.getAllEntryProtocolAttachmentsStatus])

    useEffect(() => {
        if (cartellaAziendaleState.getAllCFDetailsStatus === 'successfully' &&
            cartellaAziendaleState.getAllCFDetailsResponse !== undefined
        ) {
            dispatch(resetGetAllCFDetailsStatus())
            const data = cartellaAziendaleState.getAllCFDetailsResponse

            dispatch(setUpdateActiveCFDetailsRequest(data.filter(d => d.historic === false)))
            dispatch(setUpdateCFHistoryDetailsRequest(data.filter(d => d.historic === true)))
        }
    }, [cartellaAziendaleState.getAllCFDetailsStatus])

    useEffect(() => {
        if (cartellaAziendaleState.CFDetailsUpdateStatus === 'successfully') {
            dispatch(resetCFDetailsUpdateStatus())
            dispatch(GetAllCompanyFolders({
                customerId: sessionState.selected.customerId!,
                itemsPerPage: 0,
                page: 0
            }))
        }
    }, [cartellaAziendaleState.CFDetailsUpdateStatus])

    useEffect(() => {
        if (cartellaAziendaleState.singleCFDetailCreationStatus === 'successfully' ||
            cartellaAziendaleState.singleCFDetailUpdateStatus === 'successfully' ||
            cartellaAziendaleState.companyFolderDeleteStatus === 'successfully' ||
            cartellaAziendaleState.CFDetailsUpdateStatus === 'successfully'
        ) {
            setShowSuccess(true)
        }
    }, [cartellaAziendaleState.singleCFDetailCreationStatus,
    cartellaAziendaleState.singleCFDetailUpdateStatus,
    cartellaAziendaleState.companyFolderDeleteStatus,
    cartellaAziendaleState.CFDetailsUpdateStatus
    ])

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setShowSuccess(false)
            }, 2500);
        }
    }, [showSuccess]);


    if (sessionState.selected.customerId) {
        return (
            <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Cartella Aziendale']}
                noPaddingLat
                headerLabel="Cartella Aziendale"
                headerChildren={
                    <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>
                        <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                            onClick={() => handleOnSave()}
                        />
                    </div>
                }
            >

                <div style={{ padding: '24px' }}>
                    {/* <div className="pb-5">
                        <ClientBanner
                            region={sessionState.selected.customerRegion!}
                            client={sessionState.selected.customerName!}
                            code={sessionState.selected.customerCode!}
                            activity={sessionState.selected.customerActivities ? `${sessionState.selected.customerActivities.map(act => optionsFormatter(act, attivita_bio))}` : undefined}
                            norm={optionsFormatter(sessionState.selected.customerNorm!, normativa)!}
                        />
                    </div> */}

                    <div className="pb-5">
                        <Button
                            variant="outline"
                            color="dimgrey"
                            iconPosition="off"
                            size="md"
                            label={cartellaAziendaleState.historyView ? 'Vedi Attivo' : 'Vedi Storico'}
                            onClick={() => dispatch(setHistoryView(!cartellaAziendaleState.historyView))}
                        />
                    </div>

                    {
                        (showSuccess) &&

                        <div className="gap-[8px] text-left bg-green-300" style={{ margin: '24px', borderRadius: '8px' }}>
                            <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                                Dati salvati correttamente.
                            </span>
                        </div>

                    }
                    <div className="table-head-ca uppercase" style={{ display: 'flex' }}>
                        <th style={{ flex: '5%' }}></th>
                        <th style={{ flex: '25%' }}>Riferimento protocolli</th>
                        <th style={{ flex: '40%', maxWidth: '1000px' }}>Note</th>
                        <th style={{ flex: '15%', textAlign: 'center' }}>Storico</th>
                        <th style={{ flex: '15%', textAlign: 'center' }}>Valutazione conformità</th>
                    </div>

                    {
                        cartellaAziendaleState.historyView ?
                            <CartellaAziendaleHistoryList /> :
                            <CartellaAziendaleActiveList />
                    }

                </div>
            </Layout>
        )
    }

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Cartella Aziendale']}
            noPaddingLat
            headerLabel={"Cartella Aziendale"}
            customerName={sessionState.selected.customerName ? sessionState.selected.customerName : undefined}
            customerCode={sessionState.selected.customerCode ? sessionState.selected.customerCode : undefined}
        >
            <Banner
                type="error"
                visible={!sessionState.selected.customerId}
                label={"Devi prima selezionare un cliente per accedere all'area"}
                closeAction={function (): void {
                    alert("Devi prima selezionare un cliente per accedere all'area")
                }}
            />
        </Layout>
    )
}