import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../../../utils";
import { GetAllOldCodexMeasureDTO, GetAllOldCodexMeasureFiltersDTO, OldCodexMeasureDTO, OldCodexMeasureUpdateDTO } from "./dataService/dto";
import { NewOldCodexMeasureService } from "./dataService/service";

export interface OldCodexMeasureState {
    // REQUEST
    oldCodexMeasureUpdateRequest: OldCodexMeasureUpdateDTO
    oldCodexMeasureIdToUpdate: string

    // FILTERS
    getAllOldCodexMeasureFilters: GetAllOldCodexMeasureFiltersDTO

    // STATUS
    oldCodexMeasureUpdateStatus: PromiseStatuses
    getAllOldCodexMeasureStatus: PromiseStatuses
    getOldCodexMeasureByIdStatus: PromiseStatuses

    // RESPONSE
    getAllOldCodexMeasureResponse?: GetAllOldCodexMeasureDTO
    getOldCodexMeasureResponse?: OldCodexMeasureDTO
}

const initialState: OldCodexMeasureState = {
    oldCodexMeasureUpdateRequest: {},
    oldCodexMeasureIdToUpdate: '',

    getAllOldCodexMeasureFilters: {
        itemsPerPage: 0,
        page: 0
    },

    oldCodexMeasureUpdateStatus: 'idle',
    getAllOldCodexMeasureStatus: 'idle',
    getOldCodexMeasureByIdStatus: 'idle'
}

export const OldMeasureUpdate = createAsyncThunk(
    'oldMeasure/Update',
    async (request: { body: OldCodexMeasureUpdateDTO, id: string }, thunkApi): Promise<void> => {
        const oldMeasureService = NewOldCodexMeasureService()

        return oldMeasureService.UpdateOldCodexMeasure(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetOldMeasureById = createAsyncThunk(
    'oldMeasure/GetById',
    async (id: string, thunkApi): Promise<OldCodexMeasureDTO> => {
        const oldMeasureService = NewOldCodexMeasureService()

        return oldMeasureService.GetOldCodexMeasureById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllOldMeasure = createAsyncThunk(
    'oldMeasure/GetAll',
    async (filters: GetAllOldCodexMeasureFiltersDTO, thunkApi): Promise<GetAllOldCodexMeasureDTO> => {
        const oldMeasureService = NewOldCodexMeasureService()

        return oldMeasureService.GetAllOldCodexMeasures(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

const OldCOdexMeasureSlice = createSlice({
    name: 'oldCodexMeasure/slice',
    initialState,
    reducers: {

        // REQUEST [UPDATE]
        setOldCodexMeasureIdToUpdate: (state, action) => {
            state.oldCodexMeasureIdToUpdate = action.payload
        },
        setOldCodexMeasureUpdateActiveFrom: (state, action) => {
            state.oldCodexMeasureUpdateRequest.activeFrom = action.payload
        },
        setOldCodexMeasureUpdateReceiptProtocolId: (state, action) => {
            state.oldCodexMeasureUpdateRequest.receiptProtocolId = action.payload
        },
        setOldCodexMeasureUpdateStatus: (state, action) => {
            state.oldCodexMeasureUpdateRequest.status = action.payload
        },
        setOldCodexMeasureUpdateClosingProtocolId: (state, action) => {
            state.oldCodexMeasureUpdateRequest.closingProtocolId = action.payload
        },
        setOldCodexMeasureUpdateRacProtocolId: (state, action) => {
            state.oldCodexMeasureUpdateRequest.racProtocolId = action.payload
        },
        setOldCodexMeasureUpdateClosingNote: (state, action) => {
            state.oldCodexMeasureUpdateRequest.closingNote = action.payload
        },
        setOldCodexMeasureUpdateNextMeasureProtocolId: (state, action) => {
            state.oldCodexMeasureUpdateRequest.nextMeasureProtocolId = action.payload
        },
        setOldCodexMeasureUpdatePresentationdate: (state, action) => {
            state.oldCodexMeasureUpdateRequest.presentationDate = action.payload
        },
        setOldCodexMeasureUpdatePresentationProtocolId: (state, action) => {
            state.oldCodexMeasureUpdateRequest.presentationProtocolId = action.payload
        },
        setOldCodexMeasureUpdateRecourseDetail: (state, action) => {
            state.oldCodexMeasureUpdateRequest.recourseDetail = action.payload
        },
        setOldCodexMeasureUpdateRecourseResult: (state, action) => {
            state.oldCodexMeasureUpdateRequest.recourseResult = action.payload
        },
        setOldCodexMeasureUpdateRecourseDate: (state, action) => {
            state.oldCodexMeasureUpdateRequest.recourseDate = action.payload
        },
        setOldCodexMeasureUpdateResultProtocolId: (state, action) => {
            state.oldCodexMeasureUpdateRequest.resultProtocolId = action.payload
        },
        resetOldCodexMeasureUpdateRequest: (state) => {
            state.oldCodexMeasureUpdateRequest = {}
        },

        // FILTERS
        setGetAllOldCodexMeasureFilterItemsPerPage: (state, action) => {
            state.getAllOldCodexMeasureFilters.itemsPerPage = action.payload
        },
        setGetAllOldCodexMeasureFilterSort: (state, action) => {
            state.getAllOldCodexMeasureFilters.sort = action.payload
        },
        setGetAllOldCodexMeasureFilterOrder: (state, action) => {
            state.getAllOldCodexMeasureFilters.order = action.payload
        },
        setGetAllOldCodexMeasureFilterPage: (state, action) => {
            state.getAllOldCodexMeasureFilters.page = action.payload
        },
        setGetAllOldCodexMeasureFilterCustomerId: (state, action) => {
            state.getAllOldCodexMeasureFilters.customerId = action.payload
        },
        resetGetAllOldCodexMeasureFilters: (state) => {
            state.getAllOldCodexMeasureFilters = {
                itemsPerPage: 0,
                page: 0
            }
        },

        // STATUS
        resetOldCodexMeasureUpdateStatus: (state) => {
            state.oldCodexMeasureUpdateStatus = 'idle'
        },
        resetGetAllOldCodexMeasureStatus: (state) => {
            state.getAllOldCodexMeasureStatus = 'idle'
        },
        resetGetOldCodexMeasureByIdStatus: (state) => {
            state.getOldCodexMeasureByIdStatus = 'idle'
        }
    },
    extraReducers(builder) {
        builder

            // UPDATE
            .addCase(OldMeasureUpdate.pending, (state) => {
                state.oldCodexMeasureUpdateStatus = 'loading'
            })
            .addCase(OldMeasureUpdate.fulfilled, (state) => {
                state.oldCodexMeasureUpdateStatus = 'successfully'
            })
            .addCase(OldMeasureUpdate.rejected, (state) => {
                state.oldCodexMeasureUpdateStatus = 'failed'
            })

            // GET ALL
            .addCase(GetAllOldMeasure.pending, (state) => {
                state.getAllOldCodexMeasureStatus = 'loading'
            })
            .addCase(GetAllOldMeasure.fulfilled, (state, action) => {
                state.getAllOldCodexMeasureStatus = 'successfully'
                state.getAllOldCodexMeasureResponse = action.payload
            })
            .addCase(GetAllOldMeasure.rejected, (state) => {
                state.getAllOldCodexMeasureStatus = 'failed'
            })

            // GET BY ID
            .addCase(GetOldMeasureById.pending, (state) => {
                state.getOldCodexMeasureByIdStatus = 'loading'
            })
            .addCase(GetOldMeasureById.fulfilled, (state, action) => {
                state.getOldCodexMeasureByIdStatus = 'successfully'
                state.getOldCodexMeasureResponse = action.payload
            })
            .addCase(GetOldMeasureById.rejected, (state) => {
                state.getOldCodexMeasureByIdStatus = 'failed'
            })

    },
})

export const {
    // REQUEST
    setOldCodexMeasureIdToUpdate,
    setOldCodexMeasureUpdateActiveFrom,
    setOldCodexMeasureUpdateClosingNote,
    setOldCodexMeasureUpdateClosingProtocolId,
    setOldCodexMeasureUpdateNextMeasureProtocolId,
    setOldCodexMeasureUpdatePresentationProtocolId,
    setOldCodexMeasureUpdatePresentationdate,
    setOldCodexMeasureUpdateRacProtocolId,
    setOldCodexMeasureUpdateReceiptProtocolId,
    setOldCodexMeasureUpdateRecourseDate,
    setOldCodexMeasureUpdateRecourseDetail,
    setOldCodexMeasureUpdateRecourseResult,
    setOldCodexMeasureUpdateResultProtocolId,
    setOldCodexMeasureUpdateStatus,
    resetOldCodexMeasureUpdateRequest,

    // FILTERS
    setGetAllOldCodexMeasureFilterItemsPerPage,
    setGetAllOldCodexMeasureFilterOrder,
    setGetAllOldCodexMeasureFilterPage,
    setGetAllOldCodexMeasureFilterSort,
    resetGetAllOldCodexMeasureFilters,
    setGetAllOldCodexMeasureFilterCustomerId,

    // STATUS
    resetGetAllOldCodexMeasureStatus,
    resetGetOldCodexMeasureByIdStatus,
    resetOldCodexMeasureUpdateStatus
} = OldCOdexMeasureSlice.actions

export default OldCOdexMeasureSlice.reducer