import { InfoBox } from '../../../../ui/molecules/infoBox'
import { SingleCheckbox } from '../../../../ui/molecules/SingleCheckbox'
import '../../styles.scss'

export function EditCodexMeasuresForms() {
    return (
        <div>
            <div className="measure-container" style={{backgroundColor: '#F2F7F7'}}>
                <div className='measure-header' style={{backgroundColor: '#314d51'}}>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Provvedimenti </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}> Dati Generali</span>
                    </div>
                </div>

                <div style={{ padding: '24px 24px 32px 24px', display: 'flex', gap: '24px' }}>
                    <div style={{ flex: '0.5' }}>
                        <InfoBox
                            title='Informazione Valutazione (CDXM024)'
                            color='green'
                            content={[
                                { title: 'Data Conformità Valutazione', label: '20/02/2025' },
                                { title: 'Data Riesame Valutazione', label: '22/02/2025' }
                            ]}
                        />
                    </div>
                    <div style={{ flex: '0.5' }}>
                        <InfoBox
                            title='Data e protocollo'
                            color='green'
                            content={[
                                { title: 'Data Provvedimento', label: '20/02/2025' },
                                { title: 'Protocollo', label: 'SC E20250220/0002' }
                            ]}
                        />
                    </div>
                </div>
            </div>

            <div className="measure-container" style={{backgroundColor: '#F2F7F7'}}>
                <div className='measure-header' style={{backgroundColor: '#925016'}}>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Provvedimenti </span>
                        <span className='rid-title' style={{ color: '#E67E22' }}>/</span>
                        <span className='rid-title' style={{ color: '#FAE7D7' }}> Dati Non Conformità</span>
                    </div>
                </div>

                <div style={{ padding: '24px 24px 32px 24px' }}>
                    <div style={{ display: 'flex', gap: '24px', paddingBottom: '24px' }}>
                        <div style={{ flex: '0.5' }}>
                            <InfoBox
                                color='green'
                                content={[
                                    { title: 'Codice Non Conformità', label: 'AA.01' },
                                    { title: 'Area di Controllo', label: 'A - Mancata compilazione della notifica di variazione e mancato invio degli altri documenti obbligatori ivi compresa la mancata informatizzazione della notifica cartacea' }
                                ]}
                            />
                        </div>
                        <div style={{ flex: '0.5' }}>
                            <InfoBox
                                color='green'
                                content={[
                                    { title: 'Tipologia', label: 'IS' },
                                    { title: 'Misura Adottata', label: 'Diffida' }
                                ]}
                            />
                        </div>
                    </div>


                    <div style={{ paddingBottom: '24px' }}>
                        <InfoBox
                            color='green'
                            textArea={[
                                { title: 'Descrizione Non Conformità', label: 'Mancata compilazione della variazione di Notifica Attività con Metodo Biologico. In particolare, mancata compilazione della Notifica di variazione a seguito delle seguenti variazioni riscontrate nel corso della visita ispettiva del 30/05/2023: - nuovo impianto di Vite effettuato nelle particelle catastali n.10 e n 18 del foglio di mappa n.49 del comune di Melilli(SR).' },
                                { title: 'Riferimento Normativo', label: 'art.34(1) del Reg. 2018/848 - Art. 5 e8 D.M. 2049/2012' }
                            ]}
                        />
                    </div>

                    <div style={{ display: 'flex', gap: '24px', alignItems: 'center' }}>
                        <div style={{ flex: '0.5' }}>
                            <InfoBox
                                color='green'
                                content={[
                                    { title: 'Recidiva', label: 'NA' },
                                    { title: 'Codice Non Conformità Precedente', label: null },
                                    { title: 'Dettaglio Recidiva', label: null }
                                ]}
                            />
                        </div>
                        <div style={{ flex: '0.5' }}>
                            <InfoBox
                                color='green'
                                content={[
                                    { title: 'Attività in cui viene rilevata la non conformità', label: 'A' },
                                    { title: 'Tipo di produzione in cui viene rilevata la non conformità', label: 'B' },
                                    { title: 'Organismo di Controllo', label: 'IT BIO 002 - Codex S.r.l.' }
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="measure-container" style={{backgroundColor: '#F2F7F7'}}>
                <div className='measure-header' style={{backgroundColor: '#314d51'}}>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Provvedimenti </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}> Dati Provvedimento</span>
                    </div>
                </div>

                <div style={{ padding: '24px 24px 32px 24px' }}>
                    <div style={{ paddingBottom: '24px' }}>
                        <InfoBox
                            color='green'
                            textArea={[
                                { title: 'Descrizione Provvedimento', label: 'Diffida ad adempiere al rilascio, tramite il Sistema Informativo Biologico, della Notifica correttamente compilata ed in linea con la realtà aziendale, entro e non oltre 60 giorni dal ricevimento del presente provvedimento.' },
                            ]}
                        />
                    </div>

                    <div style={{ display: 'flex', gap: '24px', paddingBottom: '24px' }}>
                        <div style={{ flex: '0.5' }}>
                            <InfoBox
                                color='green'
                                content={[
                                    { title: 'Misure Previste', label: null },
                                    { title: 'Altre misure accessorie', label: null }
                                ]}
                            />
                        </div>
                        <div style={{ flex: '0.5' }}>
                            <InfoBox
                                color='green'
                                content={[
                                    { title: 'Specifiche Misure Accessorie', label: null },
                                    { title: 'Termine trattamento', label: null }
                                ]}
                            />
                        </div>
                    </div>

                    <div style={{ paddingBottom: '24px' }}>
                        <InfoBox
                            color='green'
                            textArea={[
                                { title: 'Applicazione Provvedimento', label: null },
                            ]}
                        />
                    </div>

                    <div>
                        <InfoBox
                            color='green'
                            content={[
                                { title: 'Verifica Rispetto Provvedimento', label: "Entro 20 giorni dal ricevimento della comunicazione dell'operatore" },
                                { title: 'Azione Correttiva', label: 'No' },
                                { title: 'Funzionario Provvedimento', label: "D'Agosta Angelo Antonio" }
                            ]}
                        />
                    </div>
                </div>
            </div>

            <div className="measure-container" style={{backgroundColor: '#F2F7F7'}}>
                <div className='measure-header' style={{backgroundColor: '#925016'}}>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Provvedimenti </span>
                        <span className='rid-title' style={{ color: '#E67E22' }}>/</span>
                        <span className='rid-title' style={{ color: '#FAE7D7' }}> Dati Del Rilievo della non conformità</span>
                    </div>
                </div>

                <div style={{ padding: '24px 24px 32px 24px' }}>
                    <div style={{ paddingBottom: '24px' }}>
                        <InfoBox
                            title='Dati Rilievo'
                            color='green'
                            content={[
                                { title: 'Data Rilievo Non Conformità', label: '20/02/2025' },
                                { title: 'Protocollo Documento', label: 'SC E20250222/0003' },
                                { title: 'Fonte Rilievo', label: 'Controllo' },
                            ]}
                        />
                    </div>

                    <div style={{ display: 'flex', gap: '24px', paddingBottom: '24px' }}>
                        <div style={{ flex: '0.5' }}>
                            <InfoBox
                                color='green'
                                content={[
                                    { title: 'CDX M018 Codinfo', label: null },
                                    { title: 'CDX M018', label: null }
                                ]}
                            />
                        </div>
                        <div style={{ flex: '0.5' }}>
                            <InfoBox
                                color='green'
                                content={[
                                    { title: 'Visita Ispettiva', label: 'Visita del 22/02/2025 - Cod. Ispettore: FEGA' },
                                    { title: 'Protocollo Entrata del Rapporto di Prova', label: null }
                                ]}
                            />
                        </div>
                    </div>

                    <div style={{ paddingBottom: '24px' }}>
                        <SingleCheckbox
                            checked={true}
                            setChecked={(value) => {
                                // dispatch
                            }}
                            label='NC Rilevata dal tecnico ispettore'
                        />
                    </div>

                    <div style={{ display: 'flex', gap: '24px', paddingBottom: '24px' }}>
                        <div style={{ flex: '0.5' }}>
                            <InfoBox
                                color='green'
                                content={[
                                    { title: 'RID', label: 'RID del 22/02/2025' },
                                    { title: 'Altro (Protocollo Entrata)', label: 'SC E20250223/0001' },
                                    { title: 'Altro (Protocollo Partenza)', label: null },
                                ]}
                            />
                        </div>
                        <div style={{ flex: '0.5' }}>
                            <InfoBox
                                color='green'
                                textArea={[
                                    { title: 'Note', label: 'Note note note' },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="measure-container" style={{backgroundColor: '#F2F7F7'}}>
                <div className='measure-header' style={{backgroundColor: '#314d51'}}>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Provvedimenti </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}> Funzionario per la firma del Provvedimento</span>
                    </div>
                </div>

                <div style={{ padding: '24px 24px 32px 24px' }}>
                        <InfoBox
                            color='green'
                            content={[
                                { title: 'Firma del Provvedimento', label: 'Alessandro Lo Giudice' },
                                { title: 'Mezzo di Partenza', label: 'Posta Elettronica Certificata' }
                            ]}
                        />
                </div>
            </div>

            <div className="measure-container" style={{backgroundColor: '#F2F7F7'}}>
                <div className='measure-header' style={{backgroundColor: '#314d51'}}>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Provvedimenti </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}> Gestione Scheda di Calcolo</span>
                    </div>
                </div>

                <div style={{ padding: '24px 24px 32px 24px' }}>
                        <InfoBox
                            color='green'
                            content={[
                                { title: 'Ore', label: '15' },
                                { title: 'Minuti', label: '20' }
                            ]}
                        />
                </div>
            </div>

            <div className="measure-container" style={{backgroundColor: '#F2F7F7'}}>
                <div className='measure-header' style={{backgroundColor: '#314d51'}}>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Provvedimenti </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}> Elenco destinatari</span>
                    </div>
                </div>

                <div style={{ padding: '24px 24px 32px 24px' }}>
                        <InfoBox
                            title='Elenco Destinatari'
                            color='green'
                            unorderedList={{
                                title: null,
                                items: [
                                    'ICQRF Nord - Ovest con sede a Torino',
                                    "ICQRF - Ufficio d'area di Asti",
                                    "ICQRF - Ufficio d'area a Genoa"
                                ]
                            }}
                        />
                </div>
            </div>
        </div>
    )
}