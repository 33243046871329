import { createSlice } from "@reduxjs/toolkit"

interface SessionState {

    // SELECTED
    selected: {
        customerId: string | null
        customerName: string | null
        customerCode: string | null
        customerRegion: string | null
        customerNorm: string | null
        customerActivities: string[]
        customerCategories: string[]
        customerUserId: string | null
        officerId: string | null
    }
}

const initialState: SessionState = {

    // SELECTED
    selected: {
        customerId: null,
        customerName: null,
        customerCode: null,
        customerRegion: null,
        customerNorm: null,
        customerActivities: [],
        customerCategories: [],
        customerUserId: null,
        officerId: null
    }
}

const SessionSlice = createSlice({
    name: 'session/slice',
    initialState,
    reducers: {

        // SELECTED
        setSelectedCustomerId: (state, action) => {
            state.selected.customerId = action.payload
        },
        setSelectedCustomerName: (state, action) => {
            state.selected.customerName = action.payload
        },
        setSelectedCustomerCode: (state, action) => {
            state.selected.customerCode = action.payload
        },
        setSelectedCustomerRegion: (state, action) => {
            state.selected.customerRegion = action.payload
        },
        setSelectedCustomerNorm: (state, action) => {
            state.selected.customerNorm = action.payload
        },
        setSelectedCustomerActivities: (state, action) => {
            state.selected.customerActivities = action.payload
        },
        setSelectedCustomerCategories: (state, action) => {
            state.selected.customerCategories = action.payload
        },
        setSelectedCustomerUserId: (state, action) => {
            state.selected.customerUserId = action.payload
        },
        setSelectedOfficerId: (state, action) => {
            state.selected.officerId = action.payload
        },
        resetSelectedCustomer: (state) => {
            state.selected = {
                customerId: null,
                customerName: null,
                customerCode: null,
                customerRegion: null,
                customerNorm: null,
                customerActivities: [],
                customerCategories: [],
                customerUserId: null,
                officerId: null
            }
        },
    }
})

export const {
    // SELECTED
    setSelectedCustomerCode,
    setSelectedCustomerId,
    setSelectedCustomerName,
    setSelectedCustomerActivities,
    setSelectedCustomerCategories,
    setSelectedCustomerNorm,
    setSelectedCustomerRegion,
    setSelectedCustomerUserId,
    setSelectedOfficerId,
    resetSelectedCustomer
} = SessionSlice.actions

export default SessionSlice.reducer