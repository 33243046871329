import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks"
import { Layout } from "../../ui/layout"
import { MenuItems } from "../../ui/organisms/navbar/dto"
import { GetByIdAnagraphic, resetGetByIdStatus } from "../gestionePersonale/boxAnagrafica/slice"
import { GetAllPersonalManagement, resetGetAllPersonalManagementResourcesStatus } from "../gestionePersonale/slice"
import { GestionePrelieviList } from './list'
import { addPMResource } from "./slice"

export function GestionePrelieviForAll() {
    const dispatch = useAppDispatch()
    const personalManagementState = useAppSelector(state => state.personalManagement)
    const anagraficaState = useAppSelector(state => state.PMAnagrafica)

    useEffect(() => {
        dispatch(GetAllPersonalManagement({itemsPerPage: 0, page: 0, function: 'PERSONALE_ISPETTIVO'}))
    }, [])

    useEffect(() => {
        if(personalManagementState.getAllPersonalManagementResourcesStatus === 'successfully' &&
            personalManagementState.getAllPersonalManagementResourcesResponse !== undefined
        ) {
            dispatch(resetGetAllPersonalManagementResourcesStatus())
            const data = personalManagementState.getAllPersonalManagementResourcesResponse.data
            for(let i=0; i<data.length; i++) {
                dispatch(GetByIdAnagraphic(String(data[i].anagraphicId)))
            }
        }
    }, [personalManagementState.getAllPersonalManagementResourcesStatus])

    useEffect(() => {
        if(anagraficaState.GetByIdAnagraphicStatus === 'successfully' &&
            anagraficaState.getByIdAnagraphicRequest !== undefined
        ) {
            dispatch(resetGetByIdStatus('idle'))
            const data = anagraficaState.getByIdAnagraphicRequest
            dispatch(addPMResource({value: data.personalManagementId, label: `${data.name} ${data.surname}`}))
        }
    }, [anagraficaState.GetByIdAnagraphicStatus])

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Strumenti', 'Gestione Prelievi', 'Consultazione']}
            noPaddingLat
            headerLabel="Gestione Prelievi"
        >
            <div className="fieldset bg-brandPrimary-200" style={{ padding: '24px' }}>
                <GestionePrelieviList />
            </div>
        </Layout>
    )
}