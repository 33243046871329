import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks"
import Button from "../../ui/molecules/button"
import Input from "../../ui/molecules/input/Input"
import { OdcCreation, OdcValidation, resetOdcCreationRequest, resetOdcValidationStatus, setOdcCreationAddress, setOdcCreationCity, setOdcCreationCode, setOdcCreationCompanyName, setOdcCreationEmail, setOdcCreationNation, setOdcCreationPec, setOdcCreationPhone, setOdcCreationProvince, setOdcCreationRegion, setOdcCreationZipcode } from "./slice"

export function ODCRight() {
    const dispatch = useAppDispatch()
    const odcState = useAppSelector(state => state.odc)

    useEffect(() => {
        if(odcState.odcvalidationStatus === 'successfully') {
            const req = odcState.odcCreationRequest
            dispatch(resetOdcValidationStatus())
            dispatch(OdcCreation({
                companyName: req.companyName !== '' ? req.companyName : undefined, 
                code: req.code !== '' ? req.code : undefined,
                address: req.address !== '' ? req.companyName : undefined,
                region: req.companyName !== '' ? req.address : undefined,
                province: req.province !== '' ? req.province : undefined,
                city: req.city !== '' ? req.city : undefined,
                zipCode: req.zipCode !== '' ? req.zipCode : undefined,
                phone: req.phone !== '' ? req.phone : undefined,
                pec: req.pec !== '' ? req.pec : undefined,
                email: req.email !== '' ? req.email : undefined,
                nation: req.nation !== '' ? req.nation : undefined,
            }))
        }
    }, [odcState.odcvalidationStatus])

    useEffect(() => {
        if(odcState.odcCreationStatus === 'successfully') {
            dispatch(resetOdcCreationRequest())
        }
    }, [odcState.odcCreationStatus])

    return (
        <div style={{ padding: '0px 24px 24px 24px' }}>
            <div className="text-left pb-3 pt-5">
                <span className="rb-title" >
                    Organismi di Controllo
                </span>
            </div>

            <div className='pb-3'>
                <Input
                    label={'Nominativo'}
                    placeholder="Specifica nome..."
                    error={odcState.odcErrors.companyName}
                    supportingText={odcState.odcErrors.companyName ? 'Nome mancante' : undefined}
                    value={odcState.odcCreationRequest.companyName}
                    onChangeText={(text) => dispatch(setOdcCreationCompanyName(text))}
                />
            </div>

            <div className='pb-3'>
                <Input
                    label={'Codice OdC'}
                    placeholder="Specifica codice OdC..."
                    error={odcState.odcErrors.code}
                    supportingText={odcState.odcErrors.code ? 'Codice mancante' : undefined}
                    value={odcState.odcCreationRequest.code}
                    onChangeText={(text) => dispatch(setOdcCreationCode(text))}
                />
            </div>

            <div className='pb-3'>
                <Input
                    label={'Indirizzo'}
                    placeholder="Specifica indirizzo..."
                    value={odcState.odcCreationRequest.address}
                    onChangeText={(text) => dispatch(setOdcCreationAddress(text))}
                />
            </div>

            <div className='pb-3'>
                <Input
                    label={'Comune'}
                    placeholder="Specifica comune..."
                    value={odcState.odcCreationRequest.city}
                    onChangeText={(text) => dispatch(setOdcCreationCity(text))}
                />
            </div>

            <div className='pb-3'>
                <Input
                    label={'CAP'}
                    placeholder="Specifica CAP..."
                    value={odcState.odcCreationRequest.zipCode}
                    onChangeText={(text) => dispatch(setOdcCreationZipcode(text))}
                />
            </div>

            <div className='pb-3'>
                <Input
                    label={'Provincia'}
                    placeholder="Specifica provincia..."
                    value={odcState.odcCreationRequest.province}
                    onChangeText={(text) => dispatch(setOdcCreationProvince(text))}
                />
            </div>

            <div className='pb-3'>
                <Input
                    label={'Regione'}
                    placeholder="Specifica regione..."
                    value={odcState.odcCreationRequest.region}
                    onChangeText={(text) => dispatch(setOdcCreationRegion(text))}
                />
            </div>

            <div className='pb-3'>
                <Input
                    label={'Nazione'}
                    placeholder="Specifica nazione... (se non Italia)"
                    value={odcState.odcCreationRequest.nation}
                    onChangeText={(text) => dispatch(setOdcCreationNation(text))}
                />
            </div>

            <div className='pb-3'>
                <Input
                    label={'Telefono'}
                    placeholder="Specifica telefono..."
                    value={odcState.odcCreationRequest.phone}
                    onChangeText={(text) => dispatch(setOdcCreationPhone(text))}
                />
            </div>

            <div className='pb-3'>
                <Input
                    label={'PEC'}
                    placeholder="Specifica PEC..."
                    value={odcState.odcCreationRequest.pec}
                    onChangeText={(text) => dispatch(setOdcCreationPec(text))}
                />
            </div>

            <div className='pb-3'>
                <Input
                    label={'Email'}
                    placeholder="Specifica email..."
                    value={odcState.odcCreationRequest.email}
                    onChangeText={(text) => dispatch(setOdcCreationEmail(text))}
                />
            </div>

            <div>
                <Button
                    variant='solid'
                    color='orange'
                    iconPosition='off'
                    size='md'
                    label='Aggiungi OdC'
                    onClick={() => dispatch(OdcValidation(odcState.odcCreationRequest))}
                />
            </div>
        </div>
    )
}