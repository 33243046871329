import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from '../../../lib/redux/hooks';
import '../style.scss';
import { registerLocale } from "react-datepicker";
import { it } from 'date-fns/locale/it';
import "react-datepicker/dist/react-datepicker.css";
import { Dropdown } from "../../../ui/organisms/dropdown";
import { EditIcon } from "../../../ui/icons/edit";
import { TrashIcon } from "../../../ui/icons/trash";
import { GetCorporateCenterById, listCorporateCenter, removeCorporateCenter, resetCreateCompanyCenterByIdStatus, resetEditCompanyCenterStatus, resetIdsCorporateCenterResponse, setSelectedIDCorporateCenter } from "../slice";
import { formatterDate } from "../../../utils";
import { SpinnerComponent } from "../../../lib/spinner";
import { EditSingleCentro } from "./editSingleCentro";
import { corporateCenterDTO } from "../dto";
import { LogoSpinnerComponent } from "../../../lib/spinner/spinnerLogo";


registerLocale('it', it)

export function ListCentriAziendaliEdit() {

    const clientiState = useAppSelector(state => state.clienti)
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const dispatch = useAppDispatch()
    const [showCentriAziendali, setShowCentriAziendali] = useState<boolean>(true);

    useEffect(() => {
        dispatch(resetIdsCorporateCenterResponse())
        if (clientiState.idsCorporateCentersResponse !== undefined && clientiState.idsCorporateCentersResponse.length > 0) {
            for (let index = 0; index < clientiState.idsCorporateCentersResponse.length; index++) {
                dispatch(GetCorporateCenterById(clientiState.idsCorporateCentersResponse[index].toString()))
            }
        }
    }, [clientiState.idsCorporateCentersResponse])

    useEffect(() => {
        if (clientiState.editCorporateCenterStatus === 'successfully') {
            dispatch(resetIdsCorporateCenterResponse())
            dispatch(resetEditCompanyCenterStatus('idle'))
            if (clientiState.idsCorporateCentersResponse !== undefined && clientiState.idsCorporateCentersResponse.length > 0) {
                for (let index = 0; index < clientiState.idsCorporateCentersResponse.length; index++) {
                    dispatch(GetCorporateCenterById(clientiState.idsCorporateCentersResponse[index].toString()))
                }
            }
        }
    }, [clientiState.editCorporateCenterStatus])

    useEffect(() => {
        if (clientiState.GetCorporateCenterByIdStatus === 'successfully') {
            if (clientiState.GetCorporateCenterByIdResponse !== undefined) {
                const corporateCenter: corporateCenterDTO = {
                    id: clientiState.GetCorporateCenterByIdResponse.id,
                    code: clientiState.GetCorporateCenterByIdResponse.code,
                    description: clientiState.GetCorporateCenterByIdResponse.description,
                    countryRegion: clientiState.GetCorporateCenterByIdResponse.countryRegion,
                    province: clientiState.GetCorporateCenterByIdResponse.province,
                    city: clientiState.GetCorporateCenterByIdResponse.city,
                    zipCode: clientiState.GetCorporateCenterByIdResponse.zipCode,
                    address: clientiState.GetCorporateCenterByIdResponse.address,
                    latitude: clientiState.GetCorporateCenterByIdResponse.latitude || undefined,
                    longitude: clientiState.GetCorporateCenterByIdResponse.longitude || undefined,
                    referrer: clientiState.GetCorporateCenterByIdResponse.referrer || undefined,
                    phone: clientiState.GetCorporateCenterByIdResponse.phone || undefined,
                    email: clientiState.GetCorporateCenterByIdResponse.email || undefined,
                    pec: clientiState.GetCorporateCenterByIdResponse.pec || undefined,
                    activationDate: clientiState.GetCorporateCenterByIdResponse.activationDate ? new Date(clientiState.GetCorporateCenterByIdResponse.activationDate) : undefined,
                    deactivationDate: clientiState.GetCorporateCenterByIdResponse.deactivationDate ? new Date(clientiState.GetCorporateCenterByIdResponse.deactivationDate) : undefined,
                    customerId: clientiState.GetCorporateCenterByIdResponse.customerId
                };

                dispatch(listCorporateCenter(corporateCenter))
            }
            dispatch(resetCreateCompanyCenterByIdStatus('idle'))
        }

    }, [clientiState.GetCorporateCenterByIdStatus])

    if (!showCentriAziendali) {
        return (
            <LogoSpinnerComponent fullScreen />
        )
    }

    return (
        <>
            <table id="tableReview" className="w-[100%]">
                <thead className="h-[52px] uppercase">
                    <tr>
                        <th style={{ borderTopLeftRadius: '8px' }}>Descrizione</th>
                        <th>ID/Codice</th>
                        <th>Regione</th>
                        <th>Provincia</th>
                        <th>Città</th>
                        <th>Email</th>
                        <th>Telefono</th>
                        <th>Data Ingresso</th>
                        <th>Data Uscita</th>
                        <th>Note</th>
                        <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        clientiState.listCorporateCentersEdit.map((center, index) =>

                            <tr
                                key={index}
                            >
                                <td className="text-neutral-700">
                                    {center.description}
                                </td>
                                <td className="text-neutral-700">
                                    {center.code}
                                </td>
                                <td className="text-neutral-700">
                                    {center.countryRegion}
                                </td>
                                <td className="text-neutral-700">
                                    {center.province}
                                </td>
                                <td className="text-neutral-700">
                                    {center.city}
                                </td>
                                <td className="text-neutral-700">
                                    {center.email}
                                </td>
                                <td className="text-neutral-700">
                                    {center.phone}
                                </td>
                                <td className="text-neutral-700">
                                    {formatterDate(center.activationDate)}
                                </td>
                                <td className="text-neutral-700">
                                    {formatterDate(center.deactivationDate)}
                                </td>
                                <td className="text-neutral-700">
                                    {center.description}
                                </td>
                                <td className="w-[20px] cursor-pointer">
                                    <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>

                                        <Dropdown items={[
                                            {
                                                label: 'Modifica',
                                                icon: <EditIcon color={""} size={0} />,
                                                onClick: () => {
                                                    dispatch(setSelectedIDCorporateCenter(center.id))
                                                    setShowPopup(true)

                                                }
                                            },
                                            {
                                                label: 'Elimina',
                                                icon: <TrashIcon color={""} size={0} />,
                                                onClick: () => {
                                                    dispatch(removeCorporateCenter(index))
                                                }
                                            }

                                        ]} />
                                    </div>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
            {
                showPopup &&
                <EditSingleCentro closeHandler={() => setShowPopup(false)} />
            }
        </>
    )
}