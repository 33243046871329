import { useEffect, useState } from 'react'
import { CustomRadio } from '../../../../../ui/molecules/radioButton'
import { SelectCustom } from '../../../../../ui/molecules/select'
import { TableSelect } from '../../../../../ui/molecules/tableSelect'
import '../../../styles.scss'
import { InfoBox } from '../../../../../ui/molecules/infoBox'
import { Calendar } from '../../../../../ui/molecules/calendar'
import { ProtocolSelectByObject } from '../../../../../ui/molecules/ProtocolSelectByObj'
import { useAppDispatch, useAppSelector } from '../../../../../lib/redux/hooks'
import { MeasureVersionOptions } from '../../../../../utils/measuresUtils'
import { resetNewMeasureUpdateRequest, setMewMeasureCreationM024Id, setNewMeasureCreationCustomerId, setNewMeasureCreationDepartureProtocolId, setNewMeasureCreationIsCodexRealesed, setNewMeasureCreationMeasureDate, setNewMeasureCreationNonComplianceCodeVersion, setNewMeasureCreationOfficerId } from '../../slice'

export enum SelectionFlagEnum {
    NEW_PROTOCOL = 'NEW_PROTOCOL',
    EXISTENT_PROTOCOL = 'EXISTENT_PROTOCOL'
}

export function AddNewCodexMeasureFormsStep1() {
    const dispatch = useAppDispatch()
    const [selectionFlag, setSelectionFlag] = useState<SelectionFlagEnum>(SelectionFlagEnum.NEW_PROTOCOL)
    const sessionState = useAppSelector(state => state.session)
    const newMeasureState = useAppSelector(state => state.newMeasure)
    const userState = useAppSelector(state => state.users)

    useEffect(() => {
        dispatch(setNewMeasureCreationCustomerId(sessionState.selected.customerId))
        dispatch(setNewMeasureCreationOfficerId(userState.findMeResponse.id))
        dispatch(setNewMeasureCreationIsCodexRealesed(true))
        dispatch(resetNewMeasureUpdateRequest())
    }, [])

    return (
        <div>
            <div className="measure-container" style={{ backgroundColor: '#F2F7F7' }}>
                <div className='measure-header' style={{ backgroundColor: '#314d51' }}>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Provvedimenti </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}> Modalità di Inserimento</span>
                    </div>
                </div>

                <div style={{ padding: '24px 24px 32px 24px' }}>
                    <SelectCustom
                        // value={}
                        placeholder={'Seleziona modalità...'}
                        options={MeasureVersionOptions}
                        onChange={(value) => dispatch(setNewMeasureCreationNonComplianceCodeVersion(value))}
                    />
                </div>
            </div>

            <div className="measure-container" style={{ backgroundColor: '#F2F7F7' }}>
                <div className='measure-header' style={{ backgroundColor: '#314d51' }}>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Provvedimenti </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}> M024 per cui emettere il provvedimento</span>
                    </div>
                </div>

                <div style={{ padding: '24px 24px 32px 24px' }}>
                    <TableSelect
                        type='radio'
                        onChange={(value) => dispatch(setMewMeasureCreationM024Id(value[0]))}// ON CHANGE TO FUTURE
                        headers={['Revisione M024', 'Data Creazione', 'Data Decisione', 'Funzionario Conformità', 'Funzionario Riesame', 'Stato']}
                        data={[
                            { value: '1', labels: ['Revisione 1', '22/02/2025', '22/02/2025', 'Alessandro Lo Giudice', 'Giovanni Fazio', 'Chiuso'] },
                            { value: '2', labels: ['Revisione 2', '23/02/2025', '23/02/2025', 'Camillo Bonaccorso', null, 'Chiuso'] },
                            { value: '3', labels: ['Revisione 3', '22/02/2025', '22/02/2025', 'Alessandro Lo Giudice', null, 'Aperto'] }
                        ]}
                    />
                </div>
            </div>

            <div className="measure-container" style={{ backgroundColor: '#F2F7F7' }}>
                <div className='measure-header' style={{ backgroundColor: '#314d51' }}>
                    <div className='text-left'>
                        <span className='rid-title' style={{ color: '#fff' }}>Provvedimenti </span>
                        <span className='rid-title' style={{ color: '#74A4A9' }}>/</span>
                        <span className='rid-title' style={{ color: '#d9e6e8' }}> Dati di Riepilogo</span>
                    </div>
                </div>

                <div style={{ padding: '24px 24px 32px 24px' }}>

                    <div className='mb-5' style={{ display: 'flex', gap: '8px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CustomRadio
                                options={[{ label: '', value: SelectionFlagEnum.NEW_PROTOCOL }]}
                                selectedValue={selectionFlag}
                                onChange={(value) => {
                                    setSelectionFlag(value as SelectionFlagEnum)
                                    dispatch(setNewMeasureCreationDepartureProtocolId(undefined))
                                }}
                                id={'new_protocol'}
                                name={'new_protocol'}
                            />
                        </div>
                        <div className='text-left'>
                            <span className='subtitle' style={{ color: '#5a8388' }}>Crea Protocollo per il Provvedimento</span>
                        </div>
                    </div>

                    <div className="ml-20">
                        <div className="text-left">
                            <span className="measure-text-bold">Data protocollo</span>
                        </div>
                        <Calendar
                            disabled={selectionFlag === SelectionFlagEnum.EXISTENT_PROTOCOL}
                            selected={newMeasureState.newMeasureCreationRequest.measureDate}
                            onChange={(date) => dispatch(setNewMeasureCreationMeasureDate(date))}
                        />
                    </div>

                    <div className='mb-5 mt-10' style={{ display: 'flex', gap: '8px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <CustomRadio
                                options={[{ label: '', value: SelectionFlagEnum.EXISTENT_PROTOCOL }]}
                                selectedValue={selectionFlag}
                                onChange={(value) => {
                                    setSelectionFlag(value as SelectionFlagEnum)
                                    dispatch(setNewMeasureCreationMeasureDate(undefined))
                                }}
                                id={'ex_protocol'}
                                name={'ex_protocol'}
                            />
                        </div>
                        <div className='text-left'>
                            <span className='subtitle' style={{ color: '#5a8388' }}>Scegli Protocollo esistente per il Provvedimento</span>
                        </div>
                    </div>

                    <div className="ml-20">
                        <ProtocolSelectByObject
                            key={newMeasureState.newMeasureCreationRequest.departureProtocolId || 'default'}
                            disabled={selectionFlag === SelectionFlagEnum.NEW_PROTOCOL}
                            customerId={sessionState.selected.customerId!}
                            objectId='16'
                            type='departure'
                            placeholder='Seleziona protocollo...'
                            value={newMeasureState.newMeasureCreationRequest.departureProtocolId}
                            onChange={(value) => dispatch(setNewMeasureCreationDepartureProtocolId(value))}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}