import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { EditIcon } from "../../../ui/icons/edit"
import { optionsFormatter, formatterDate, statoRichiestaComplianceRequest } from "../../../utils"
import { useEffect } from "react"
import { GetAllComplianceRequests, resetGetAllStatus } from "./slice"
import { SpinnerComponent } from "../../../lib/spinner"
import { LogoSpinnerComponent } from "../../../lib/spinner/spinnerLogo"

export function AllRequests() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const complianceRequestState = useAppSelector(state => state.complianceRequest)

    useEffect(() => {
        dispatch(GetAllComplianceRequests({ itemsPerPage: 0, page: 0, order: true }))
    }, [])

    useEffect(() => {
        if (complianceRequestState.getAllComplianceRequestsStatus === 'successfully') {
            resetGetAllStatus()
        }
    }, [complianceRequestState.getAllComplianceRequestsStatus])

    if (complianceRequestState.getAllComplianceRequestsStatus === 'loading') {
        return (
            <LogoSpinnerComponent fullScreen />
        )
    }

    if (complianceRequestState.allComplianceRequestsResponse && complianceRequestState.allComplianceRequestsResponse.data.length === 0) {
        return (<div>Nessun Dato Disponibile</div>)
    }

    return (
        <div style={{ padding: '24px' }}>
            <table id="users-table" className="w-[100%]">
                <thead className="h-[52px] uppercase">
                    <tr>
                        <th style={{ borderTopLeftRadius: '8px' }}>Cliente</th>
                        <th>Data Richiesta</th>
                        <th>Mittente</th>
                        <th>Cliente Altro OdC</th>
                        <th>Protocollo Entrata</th>
                        <th>Stato</th>
                        <th>Funzionario</th>
                        <th>Data</th>
                        <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        complianceRequestState.allComplianceRequestsResponse?.data.map((request, index) =>
                            <tr
                                key={index}
                                style={{
                                    color: '#4D797F',
                                    background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                }}
                            >
                                <td>{request.customerDataCompanyName}</td>
                                <td>{formatterDate(request.date)}</td>
                                <td>{request.sender}</td>
                                <td>
                                    {
                                        request.otherOdcCustomer?.map((customerOtherOdc, index) => (
                                            <div>{customerOtherOdc.companyName} - {customerOtherOdc.cuaa} - {customerOtherOdc.clientCode}</div>
                                        ))
                                    }
                                </td>
                                <td>{request.requestProtocolInfo}</td>
                                <td>
                                    {
                                        request.complianceRequestStates?.map((state, index) => (
                                            <div>{optionsFormatter(state.state, statoRichiestaComplianceRequest)}</div>
                                        ))
                                    }
                                </td>
                                <td>{request.officerInfo}</td>
                                <td>{formatterDate(request.date)}</td>
                                <td>
                                    <div style={{ display: 'flex', gap: '6px' }}>
                                        <button onClick={() => console.log()}>
                                            <EditIcon color="orange" size={20} />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )}
                </tbody>
            </table>
        </div>
    )
}