interface Props {
    color?: string
    size?: string
}

export function DownloadIcon(props: Props) {
    return (
        <svg width={props.size ? props.size : "22"} height={props.size ? props.size : "22"} viewBox={`0 0 64 64`} fill="none">
            <path d="M44 36V41.3333C44 42.0406 43.719 42.7189 43.219 43.219C42.7189 43.719 42.0406 44 41.3333 44H22.6667C21.9594 44 21.2811 43.719 20.781 43.219C20.281 42.7189 20 42.0406 20 41.3333V36M25.3333 29.3333L32 36M32 36L38.6667 29.3333M32 36V20" stroke={props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}
