import axios from "axios";
import { keycloak } from "../../lib/keycloak";
import { SotService } from "./service";
import { headQuarterResponseDTO, sotFilterDTO } from "./dto";

export class SotServiceImpl implements SotService {

    public GetHeadQuarter(filters: sotFilterDTO): Promise<void> {
        return axios.get("/api/head-quarter", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page,
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public FindSotById(id: string): Promise<headQuarterResponseDTO> {
        return axios.get("/api/head-quarter/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

}
