import { GetAllMeasureRecipientsDTO, GetAllMeasureRecipientsFiltersDTO, MeasureRecipientDTO } from "./dto";
import { MeasureRecipientServiceImpl } from "./serviceImpl";

export interface MeasureRecipientsService {
    CreateMeasureRecipient(data: MeasureRecipientDTO): Promise<void>
    UpdateMeasureRecipient(data: MeasureRecipientDTO, id: string): Promise<void>
    DeleteMeasureRecipient(id: string): Promise<void>
    RemoveMeasureRecipient(id: string): Promise<void>
    RestoreMeasureRecipient(id: string): Promise<void>
    GetAllMeasureRecipients(filters: GetAllMeasureRecipientsFiltersDTO): Promise<GetAllMeasureRecipientsDTO>
    GetMeasureRecipientById(id: string): Promise<MeasureRecipientDTO>
}

export function NewMeasureRecipientService(): MeasureRecipientsService {
    return new MeasureRecipientServiceImpl()
}