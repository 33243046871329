import { AppliedMeasureCreationDTO, AppliedMeasureDTO, AppliedMeasureUpdateDTO, GetAllAppliedMeasureDTO, GetAllAppliedMeasureFiltersDTO } from "./dto";
import { AppliedMeasureServiceImpl } from "./serviceImpl";

export interface AppliedMeasureService {
    CreateAppliedMeasure(data: AppliedMeasureCreationDTO): Promise<void>
    UpdateAppliedMeasure(data: AppliedMeasureUpdateDTO, id: string): Promise<void>
    DeleteAppliedMeasure(id: string): Promise<void>
    GetAppliedMeasureById(id: string): Promise<AppliedMeasureDTO>
    GetAllAppliedMeasure(filters: GetAllAppliedMeasureFiltersDTO): Promise<GetAllAppliedMeasureDTO>
}

export function NewAppliedMeasureService(): AppliedMeasureService {
    return new AppliedMeasureServiceImpl()
}