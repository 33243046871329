export interface RIDCreationDTO {
    customerId: string
    departureProtocolId: string
    object?: string
    note?: string
    deadlineRequestedDocument?: string
    officerId: string
    documentalControlProtocolId?: string
    reportingId?: string
    certificateRequestProtocolId?: string
    other?: string
}

export interface RIDUpdateDTO {
    departureProtocolId?: string
    object?: string
    note?: string
    deadlineRequestedDocument?: string
    documentalControlProtocolId?: string
    reportingId?: string
    certificateRequestProtocolId?: string
    other?: string
    attachmentIds: string[]
    documentNotes: string[]
    closingProtocolIds: string[]
    closedNote?: string
}

export enum CompilationStateDTO {
    IN_CORSO = 'IN_CORSO',
    COMPLETATA = 'COMPLETATA'
}

export enum RequestStateDTO {
    APERTA = 'APERTA',
    CHIUSA = 'CHIUSA'
}

export interface RIDUpdateStateDTO {
    compilationState: CompilationStateDTO
}

export interface RIDEntityDTO {
    id: string
    customerId: string
    date: Date
    departureProtocolId: string
    departureProtocolInfo: string
    compilationState: string
    requestState: RequestStateDTO
    object: string
    note: string
    deadlineRequestedDocument: string
    reportingId?: string
    reportingInfo: string
    officerId: string
    officerInfo: string
    isMotivation: boolean
    documentalControlProtocolId: string
    documentalControlProtocolInfo: string
    certificateRequestProtocolId: string
    certificateRequestProtocolInfo: string
    other: string,
    attachmentIds: string[] | null
    documentNotes: string[]
    closingProtocolIds: string[] | null
    closedNote: string | null
}

export interface GetAllRIDEntitiesDTO {
    total: number
    page: number
    perPage: number
    prev: boolean
    next: boolean
    totalPage: number
    data: RIDEntityDTO[]
}

export interface GetAllRIDFiltersDTO {
    itemsPerPage: number
    page: number
    customerId?: string
    order?: boolean
    sort?: string
}