import { InfoBox } from "../../../ui/molecules/infoBox"
import { formatDecimalTime, formatterDate, optionsFormatter, ServiceTypeNormoptions, ServiceTypeUnitOptions } from "../../../utils"
import { CustomerBillingWorksheetDTO } from "../dataService/dto"
import { calculateFee } from "../utils"

interface Props {
    close: () => void
    customerBilling: CustomerBillingWorksheetDTO
}

export function SchedaCalcoloListViewPopup(props: Props) {
    return (
        <div>
            <div style={{ padding: '24px 24px 18px 24px' }}>
                <div className="pb-5" style={{ display: 'flex', gap: '24px' }}>
                    <div className="flex-[0.5]">
                        <InfoBox
                            color="green"
                            content={[
                                { title: 'Anno', label: props.customerBilling.year },
                                { title: 'Data', label: formatterDate(props.customerBilling.createdAt) },
                                { title: 'Normativa', label: optionsFormatter(props.customerBilling.norm, ServiceTypeNormoptions) || '' },
                            ]}
                        />
                    </div>
                    <div className="flex-[0.5]">
                        <InfoBox
                            color="green"
                            content={[
                                { title: 'Totale Imponibile', label: `€${props.customerBilling.totalTaxableFee}` },
                                { title: 'Totale Iva', label: `€${props.customerBilling.totalIva}` },
                                { title: 'Importo Totale', label: `€${props.customerBilling.totalFee}` }
                            ]}
                        />
                    </div>
                </div>

                <div className="pb-5">
                    <InfoBox
                        color="green"
                        title="Elenco Servizi"
                        table={{
                            title: null,
                            headers: ['Codice', 'Descrizione', 'Tipo Unità', 'Quantità', 'Imponibile', 'Iva', 'Importo'],
                            values: props.customerBilling.services.map(s => {
                                return [`[${s.serviceTypeCode}]`,
                                s.serviceTypeDescription,
                                optionsFormatter(s.unitType, ServiceTypeUnitOptions) || '',
                                String(s.unitType === 'HOURS' ? formatDecimalTime(s.unitEmployed) : s.unitEmployed),
                                "€" + String(s.taxableFee),
                                "€" + String(s.iva),
                                "€" + String(s.totalFee)
                                ]
                            })
                        }}
                    />
                </div>

                <div className="pb-5">
                    <InfoBox
                        color="orange"
                        title="Elenco Spese Aggiuntive"
                        table={{
                            title: null,
                            headers: ['Codice', 'Descrizione', 'Imponibile', 'Iva', 'Importo'],
                            values: props.customerBilling.additionalExpenses.map(a => {
                                return [`[${a.serviceTypeCode}]`,
                                a.serviceTypeDescription,
                                "€" + String(a.taxableFee),
                                "€" + String(a.iva),
                                "€" + String(a.totalFee)
                                ]
                            })
                        }}
                    />
                </div>
            </div>
        </div>
    )
}