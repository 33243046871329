export interface InfoBoxProps {
    hidden?: boolean
    title?: string
    freeText?: {title: string | null, label: string}
    content?: {title: string, label: string | null}[]
    textArea?: {title: string | null, label: string | null}[]
    unorderedList?: {title: string | null, items: string[]}
    table?: {title: string | null, headers: string[], values: (string | null)[][]}
    color: InfoBoxColor
}

export type InfoBoxColor = 'green' | 'orange' | 'red' | 'purple'

export const InfoBoxColorMap = new Map<InfoBoxColor, { 
    titleColor: string, 
    borderColor: string, 
    backgroundColor: string, 
    contentColor: string
  }>([
    ['green', { titleColor: '#314d51', borderColor: '#D9E6E8', backgroundColor: '#f0f7f8', contentColor: '#4D797F' }],
    ['orange', { titleColor: '#925016', borderColor: '#FAE7D7', backgroundColor: '#fff4e6', contentColor: '#BA6520' }],
    ['red',    { titleColor: '#933026', borderColor: '#FADEDC', backgroundColor: '#fdecec', contentColor: '#BB4131' }],
    ['purple', { titleColor: '#5A2B6E', borderColor: '#E7DCF0', backgroundColor: '#f7f1fa', contentColor: '#8E44AD' }]
  ])