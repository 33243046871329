import { colors } from "../../colors";
import { ChevronIcon } from "../../icons/chevron";
import { PaginationProps } from "./dto";
import './style.scss';
import clsx from "clsx";

export function Pagination(props: PaginationProps) {
  const { currentPage, pages, setPage, numbResults, detailsResults, fullWidth } = props;

  const visiblePages = 5;
  let startPage = Math.max(0, currentPage - Math.floor(visiblePages / 2));
  let endPage = Math.min(pages - 1, currentPage + Math.floor(visiblePages / 2));

  if (endPage - startPage + 1 < visiblePages) {
    if (currentPage < Math.floor(visiblePages / 2)) {
      endPage = Math.min(pages - 1, endPage + (visiblePages - (endPage - startPage + 1)));
    } else {
      startPage = Math.max(0, startPage - (visiblePages - (endPage - startPage + 1)));
    }
  }

  const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

  return (
    <div className="flex p-[8px] gap-[8px] drop-shadow-sm items-center justify-end">
      <div className={clsx("text-brandPrimary-800 paginationNumbers flex gap-2", { "w-full space-between": fullWidth })}>
        <div className="flex flex-col sm:flex-row gap-4 sm:gap-2 items-center justify-between w-full">
          <div className="flex textNumberResults">
            <span>
              Trovati: <span className="numberResults">{numbResults} </span>
              {detailsResults}
            </span>
          </div>

          <div className="flex gap-2 items-center">
            <div className="flex gap-2 items-center">
              <div
                className="cursor-pointer paginationIcon"
                onClick={() => {
                  if (currentPage > 0) {
                    setPage(currentPage - 1);
                  }
                }}
              >
                <ChevronIcon size={23} color={colors.brandPrimary[800]} direction="l" />
              </div>

              <div className="flex gap-1 items-center">

                {startPage > 0 && (
                  <span className="text-brandPrimary-800 numbPages">...</span>
                )}

                {pageNumbers.map((page) => (
                  <span
                    key={page}
                    className={clsx(
                      "cursor-pointer numbPages",
                      { "font-bold bg-brandPrimary-100": page === currentPage }
                    )}
                    onClick={() => setPage(page)}
                  >
                    {page + 1}
                  </span>
                ))}

                {endPage < pages - 1 && (
                  <span className="text-brandPrimary-800 numbPages">...</span>
                )}
              </div>

              <div
                className="cursor-pointer paginationIcon"
                onClick={() => {
                  if (currentPage < pages - 1) {
                    setPage(currentPage + 1);
                  }
                }}
              >
                <ChevronIcon size={23} color={colors.brandPrimary[800]} direction="r" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
