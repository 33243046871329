import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PromiseStatuses } from "../../utils";
import { AttachmentCADTO, CompanyFolderDetailBulkUpdateDTO, CompanyFolderDetailCreationDTO, CompanyFolderDetailDTO, CompanyFolderDetailUpdateDTO, CompanyFolderErrorsDTO, GetAllCompanyFoldersFiltersDTO } from "./dataService/dto";
import { NewCartellaAziendaleService } from "./dataService/service";

interface CartellaAziendaleState {
    cartelleAziendali: AttachmentCADTO[]
    historyView: boolean

    // REQUEST
    singleCFDetailCreationRequest: CompanyFolderDetailCreationDTO
    singleCFDetailUpdateRequest: CompanyFolderDetailUpdateDTO
    singleCFIdToUpdate: string
    activeCFDetailsUpdateRequest: CompanyFolderDetailDTO[]
    historyCFDetailsUpdateRequest: CompanyFolderDetailDTO[]

    // ERRORS
    singleCFDetailErrors: CompanyFolderErrorsDTO

    // FILTERS
    getAllCompanyFoldersFilters: GetAllCompanyFoldersFiltersDTO

    // STATUS
    singleCFDetailCreationStatus: PromiseStatuses
    singleCFDetailUpdateStatus: PromiseStatuses
    CFDetailsUpdateStatus: PromiseStatuses
    getAllCFDetailsStatus: PromiseStatuses 
    getCFDetailByIdStatus: PromiseStatuses
    companyFolderValidationStatus: PromiseStatuses
    companyFolderDeleteStatus: PromiseStatuses

    // RESPONSE
    getAllCFDetailsResponse?: CompanyFolderDetailDTO[]
    getCFDetailByIdResponse?: CompanyFolderDetailDTO
}

const initialState: CartellaAziendaleState = {
    cartelleAziendali: [],
    historyView: false,

    // REQUEST
    singleCFDetailCreationRequest: {
        customerId: '',
        entryProtocolId: '',
        entryProtocolAttachmentId: '',
        historic: false
    },
    singleCFDetailUpdateRequest: {},
    singleCFIdToUpdate: '',
    activeCFDetailsUpdateRequest: [],
    historyCFDetailsUpdateRequest: [],

    // ERRORS
    singleCFDetailErrors: {
        entryProtocolId: false
    },

    // FILTERS
    getAllCompanyFoldersFilters: {
        customerId: '',
        itemsPerPage: 0,
        page: 0
    },

    // STATUS
    singleCFDetailCreationStatus: 'idle',
    singleCFDetailUpdateStatus: 'idle',
    CFDetailsUpdateStatus: 'idle',
    getAllCFDetailsStatus: 'idle',
    getCFDetailByIdStatus: 'idle',
    companyFolderValidationStatus: 'idle',
    companyFolderDeleteStatus: 'idle'
}

export const CompanyFolderCreation = createAsyncThunk(
    'companyFolder/creation',
    async (body: CompanyFolderDetailCreationDTO, thunkApi): Promise<void> => {
        const cartellaAziendaleService = NewCartellaAziendaleService()

        return cartellaAziendaleService.CreateCompanyFolderDetail(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const CompanyFolderUpdate = createAsyncThunk(
    'companyFolder/update',
    async (request: {body: CompanyFolderDetailUpdateDTO, id: string}, thunkApi): Promise<void> => {
        const cartellaAziendaleService = NewCartellaAziendaleService()

        return cartellaAziendaleService.UpdateCompanyFolderDetail(request.body, request.id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const CompanyFolderBulkUpdate= createAsyncThunk(
    'companyFolder/update/bulk',
    async (body: CompanyFolderDetailBulkUpdateDTO[], thunkApi): Promise<void> => {
        const cartellaAziendaleService = NewCartellaAziendaleService()

        return cartellaAziendaleService.UpdateAllCompanyFolderDetails(body).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetAllCompanyFolders = createAsyncThunk(
    'companyFolder/getAll',
    async (filters: GetAllCompanyFoldersFiltersDTO, thunkApi): Promise<CompanyFolderDetailDTO[]> => {
        const cartellaAziendaleService = NewCartellaAziendaleService()

        return cartellaAziendaleService.GetAllCompanyFolderDetails(filters).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const GetCompanyFolderById = createAsyncThunk(
    'companyFolder/getByID',
    async (id: string, thunkApi): Promise<CompanyFolderDetailDTO> => {
        const cartellaAziendaleService = NewCartellaAziendaleService()

        return cartellaAziendaleService.GetCompanyFolderDetailById(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const DeleteCompanyFolder = createAsyncThunk(
    'companyFolder/delete',
    async (id: string, thunkApi): Promise<void> => {
        const cartellaAziendaleService = NewCartellaAziendaleService()

        return cartellaAziendaleService.DeleteCompanyFolderDetail(id).catch(error => {
            throw (thunkApi.rejectWithValue(error))
        })
    },
)

export const CompanyFolderValidation = createAsyncThunk(
    'companyFolder/Validation',
    async (body: CompanyFolderDetailCreationDTO, thunkApi): Promise<void> => {
        let isValid = true
        thunkApi.dispatch(resetCFErrors())

        if(body.entryProtocolId === '') {
            thunkApi.dispatch(setCFValidateEntryProtocolId(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }
        return Promise.resolve()
        
    }
)


const CartellaAziendaleSlice = createSlice({
    name: 'cartellaAziendale/slice',
    initialState,
    reducers: {
        resetCartelleAziendali: (state) => {
            state.cartelleAziendali = []
        },
        setCartelleAziendali: (state, action) => {
            state.cartelleAziendali = action.payload
        },
        setHistoryView: (state, action) => {
            state.historyView = action.payload
        },

        // REQUEST [SINGLE DETAIL CREATION]
        setCFDetailCreationRequestCustomerId: (state, action) => {
            state.singleCFDetailCreationRequest.customerId = action.payload
        },
        setCFDetailCreationRequestEntryProtocolId: (state, action) => {
            state.singleCFDetailCreationRequest.entryProtocolId = action.payload
        },
        setCFDetailCreationRequestEntryProtocolAttachmentId: (state, action) => {
            state.singleCFDetailCreationRequest.entryProtocolAttachmentId = action.payload
        },
        setCFDetailCreationRequestNote: (state, action) => {
            state.singleCFDetailCreationRequest.note = action.payload
        },
        setCFDetailCreationRequestConformity: (state, action) => {
            state.singleCFDetailCreationRequest.conformityEvaluation = action.payload
        },
        setCFDetailCreationRequestDeliveryOnHeadquarter: (state, action) => {
            state.singleCFDetailCreationRequest.deliveredOnHeadquarter = action.payload
        },
        setCFDetailCreationRequestHistory: (state, action) => {
            state.singleCFDetailCreationRequest.historic = action.payload
        },
        resetCFDetailCreationRequest: (state) => {
            state.singleCFDetailCreationRequest = {
                customerId: '',
                entryProtocolId: '',
                entryProtocolAttachmentId: '',
                historic: false,
                note: undefined,
                conformityEvaluation: undefined,
                deliveredOnHeadquarter: undefined
            }
        },

        // REQUEST [SINGLE DETAIL UPDATE]
        setUpdateCFDetailRequestNote: (state, action) => {
            state.singleCFDetailUpdateRequest.note = action.payload
        },
        setUpdateCFDetailRequestConformity: (state, action) => {
            state.singleCFDetailUpdateRequest.conformityEvaluation = action.payload
        },
        setUpdateCFDetailRequestDeliveryOnHeadquarter: (state, action) => {
            state.singleCFDetailUpdateRequest.deliveredOnHeadquarter = action.payload
        },
        setSingleCFIdToUpdate: (state, action) => {
            state.singleCFIdToUpdate = action.payload
        },
        setUpdateCFDetailRequestHistory: (state, action) => {
            state.singleCFDetailUpdateRequest.historic = action.payload
        },
        resetUpdateCFDetailRequest: (state) => {
            state.singleCFDetailUpdateRequest = {
                historic: false,
                note: undefined,
                conformityEvaluation: undefined,
                deliveredOnHeadquarter: undefined
            }
        },

        // REQUEST [DETAILS UPDATE]
        setUpdateActiveCFDetailsRequest: (state, action) => {
            state.activeCFDetailsUpdateRequest = action.payload
        },
        resetUpdateCFActiveDetailsRequest: (state) => {
            state.activeCFDetailsUpdateRequest = []
        },
        setUpdateCFActiveDetailConformityById: (state, action) => {
            state.activeCFDetailsUpdateRequest[action.payload.index].conformityEvaluation = action.payload.data
        },
        setUpdateCFActiveDetailHistoryById: (state, action) => {
            state.activeCFDetailsUpdateRequest[action.payload.index].historic = action.payload.data
        },

        // REQUEST [DETAILS UPDATE]
        setUpdateCFHistoryDetailsRequest: (state, action) => {
            state.historyCFDetailsUpdateRequest = action.payload
        },
        resetUpdateCFHistoryDetailsRequest: (state) => {
            state.historyCFDetailsUpdateRequest = []
        },
        setUpdateCFHistoryDetailConformityById: (state, action) => {
            state.historyCFDetailsUpdateRequest[action.payload.index].conformityEvaluation = action.payload.data
        },
        setUpdateCFHistoryDetailHistoryById: (state, action) => {
            state.historyCFDetailsUpdateRequest[action.payload.index].historic = action.payload.data
        },

        // ERRORS
        setCFValidateEntryProtocolId: (state, action) => {
            state.singleCFDetailErrors.entryProtocolId = action.payload
        },
        resetCFErrors: (state) => {
            state.singleCFDetailErrors = {
                entryProtocolId: false
            }
        },

        // FILTERS
        setGetAllCFFiltersCustomerId: (state, action) => {
            state.getAllCompanyFoldersFilters.customerId = action.payload
        },
        setGetAllCFFiltersHistoric: (state, action) => {
            state.getAllCompanyFoldersFilters.customerId = action.payload
        },
        setGetAllCFFiltersItemsPerPage: (state, action) => {
            state.getAllCompanyFoldersFilters.itemsPerPage = action.payload
        },
        setGetAllCFFiltersPage: (state, action) => {
            state.getAllCompanyFoldersFilters.page = action.payload
        },
        setGetAllCFFiltersOrder: (state, action) => {
            state.getAllCompanyFoldersFilters.order = action.payload
        },
        setGetAllCFFiltersSort: (state, action) => {
            state.getAllCompanyFoldersFilters.sort = action.payload
        },
        resetGetAllCFFilters: (state) => {
            state.getAllCompanyFoldersFilters = {
                customerId: '',
                itemsPerPage: 0,
                page: 0
            }
        },


        // STATUS
        resetSingleCFDetailCreationStatus: (state) => {
            state.singleCFDetailCreationStatus = 'idle'
        },
        resetSingleCFDetailUpdateStatus: (state) => {
            state.singleCFDetailUpdateStatus = 'idle'
        },
        resetCFDetailsUpdateStatus: (state) => {
            state.CFDetailsUpdateStatus = 'idle'
        },
        resetGetAllCFDetailsStatus: (state) => {
            state.getAllCFDetailsStatus = 'idle'
        },
        resetGetCFDetailByIdStatus: (state) => {
            state.getCFDetailByIdStatus = 'idle'
        },
        resetCompanyFolderValidationStatus: (state) => {
            state.companyFolderValidationStatus = 'idle'
        },
        resetCompanyFolderDeleteStatus: (state) => {
            state.companyFolderDeleteStatus = 'idle'
        }
    },
    extraReducers(builder) {
        builder
            
            // [COMPANY FOLDER]
            // CREATION
            .addCase(CompanyFolderCreation.pending, (state) => {
                state.singleCFDetailCreationStatus = 'loading'
            })
            .addCase(CompanyFolderCreation.fulfilled, (state) => {
                state.singleCFDetailCreationStatus = 'successfully'
            })
            .addCase(CompanyFolderCreation.rejected, (state) => {
                state.singleCFDetailCreationStatus = 'failed'
            })

            // UPDATE
            .addCase(CompanyFolderUpdate.pending, (state) => {
                state.singleCFDetailUpdateStatus = 'loading'
            })
            .addCase(CompanyFolderUpdate.fulfilled, (state) => {
                state.singleCFDetailUpdateStatus = 'successfully'
            })
            .addCase(CompanyFolderUpdate.rejected, (state) => {
                state.singleCFDetailUpdateStatus = 'failed'
            }) 

            // UPDATE BULK
            .addCase(CompanyFolderBulkUpdate.pending, (state) => {
                state.CFDetailsUpdateStatus = 'loading'
            })
            .addCase(CompanyFolderBulkUpdate.fulfilled, (state) => {
                state.CFDetailsUpdateStatus = 'successfully'
            })
            .addCase(CompanyFolderBulkUpdate.rejected, (state) => {
                state.CFDetailsUpdateStatus = 'failed'
            }) 

            // VALIDATION
            .addCase(CompanyFolderValidation.pending, (state) => {
                state.companyFolderValidationStatus = 'loading'
            })
            .addCase(CompanyFolderValidation.fulfilled, (state) => {
                state.companyFolderValidationStatus = 'successfully'
            })
            .addCase(CompanyFolderValidation.rejected, (state) => {
                state.companyFolderValidationStatus = 'failed'
            }) 

            // DELETE
            .addCase(DeleteCompanyFolder.pending, (state) => {
                state.companyFolderDeleteStatus = 'loading'
            })
            .addCase(DeleteCompanyFolder.fulfilled, (state) => {
                state.companyFolderDeleteStatus = 'successfully'
            })
            .addCase(DeleteCompanyFolder.rejected, (state) => {
                state.companyFolderDeleteStatus = 'failed'
            }) 

            // GET ALL
            .addCase(GetAllCompanyFolders.pending, (state) => {
                state.getAllCFDetailsStatus = 'loading'
            })
            .addCase(GetAllCompanyFolders.fulfilled, (state, action) => {
                state.getAllCFDetailsStatus = 'successfully'
                state.getAllCFDetailsResponse = action.payload
            })
            .addCase(GetAllCompanyFolders.rejected, (state) => {
                state.getAllCFDetailsStatus = 'failed'
            })

            // GET ALL
            .addCase(GetCompanyFolderById.pending, (state) => {
                state.getCFDetailByIdStatus = 'loading'
            })
            .addCase(GetCompanyFolderById.fulfilled, (state, action) => {
                state.getCFDetailByIdStatus = 'successfully'
                state.getCFDetailByIdResponse = action.payload
            })
            .addCase(GetCompanyFolderById.rejected, (state) => {
                state.getCFDetailByIdStatus = 'failed'
            }) 
    },
})

export const {
    setCartelleAziendali,
    resetCartelleAziendali,
    setHistoryView,

    // REQUEST
    setCFDetailCreationRequestConformity,
    setCFDetailCreationRequestDeliveryOnHeadquarter,
    setCFDetailCreationRequestEntryProtocolId,
    setCFDetailCreationRequestHistory,
    setCFDetailCreationRequestEntryProtocolAttachmentId,
    setCFDetailCreationRequestNote,
    setCFDetailCreationRequestCustomerId,
    setUpdateActiveCFDetailsRequest,
    setUpdateCFActiveDetailConformityById,
    setUpdateCFActiveDetailHistoryById,
    setUpdateCFDetailRequestConformity,
    setSingleCFIdToUpdate,
    setUpdateCFDetailRequestDeliveryOnHeadquarter,
    setUpdateCFDetailRequestHistory,
    setUpdateCFDetailRequestNote,
    setUpdateCFHistoryDetailConformityById,
    setUpdateCFHistoryDetailHistoryById,
    setUpdateCFHistoryDetailsRequest,
    resetCFDetailCreationRequest,
    resetUpdateCFActiveDetailsRequest,
    resetUpdateCFDetailRequest,
    resetUpdateCFHistoryDetailsRequest,

    // ERRORS
    setCFValidateEntryProtocolId,
    resetCFErrors,

    // FILTERS
    setGetAllCFFiltersCustomerId,
    setGetAllCFFiltersHistoric,
    setGetAllCFFiltersItemsPerPage,
    setGetAllCFFiltersOrder,
    setGetAllCFFiltersPage,
    setGetAllCFFiltersSort,
    resetGetAllCFFilters,

    // STATUS
    resetCFDetailsUpdateStatus,
    resetGetAllCFDetailsStatus,
    resetGetCFDetailByIdStatus,
    resetSingleCFDetailCreationStatus,
    resetSingleCFDetailUpdateStatus,
    resetCompanyFolderValidationStatus,
    resetCompanyFolderDeleteStatus

} = CartellaAziendaleSlice.actions

export default CartellaAziendaleSlice.reducer