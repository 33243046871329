import { useNavigate } from "react-router-dom"
import { ArrowLeftIcon } from "../../../../ui/icons/arrow-left"
import { Layout } from "../../../../ui/layout"
import Button from "../../../../ui/molecules/button"
import { MenuItems } from "../../../../ui/organisms/navbar/dto"
import { PACDetailValidation } from "../../../gestioneControllo/pac/slice"
import { EditCodexMeasuresForms } from "./forms"
import { EditCodexMeasureRight } from "./right"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { OldMeasureUpdate } from "./slice"

export function EditCodexMeasure() {
    const dispatch = useAppDispatch()
    const oldCodexMeasure = useAppSelector(state => state.oldCodexMeasure)
    const navigate = useNavigate()
    const sessionState = useAppSelector(state => state.session)

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Gestione Provvedimenti', 'Modifica Provvedimento Cliente']}
            headerLabel={`Modifica Provvedimento`}
            customerName={sessionState.selected.customerName ? sessionState.selected.customerName : undefined}
            customerCode={sessionState.selected.customerCode ? sessionState.selected.customerCode : undefined}
            rightBar={true}
            headerIcon={
                <button style={{ paddingTop: '12px' }} onClick={() => navigate('/provvedimentiCliente')}>
                    <ArrowLeftIcon />
                </button>
            }
            headerChildren={
                <div className="text-left flex flex-row justify-end" style={{ padding: '16px', justifyContent: 'end' }}>
                    {oldCodexMeasure.oldCodexMeasureIdToUpdate}
                    <Button size={"sm"} iconPosition={"off"} variant={"solid"} label="Salva" color={"orange"}
                        onClick={() => {
                            dispatch(OldMeasureUpdate({body: oldCodexMeasure.oldCodexMeasureUpdateRequest, id: oldCodexMeasure.oldCodexMeasureIdToUpdate}))
                        }}
                    />
                </div>
            }
        >
            <div style={{ display: 'flex', minHeight: window.innerHeight }}>
                <div style={{ flex: '0.65' }}>
                    <EditCodexMeasuresForms />
                </div>
                <div className="bg-brandPrimary-100" style={{ flex: '0.35' }}>
                    <EditCodexMeasureRight />
                </div>
            </div>
        </Layout>
    )
}