import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { Layout } from "../../../../ui/layout"
import { MenuItems } from "../../../../ui/organisms/navbar/dto"
import { Banner } from "../../../../ui/organisms/banner"
import Button from "../../../../ui/molecules/button"
import { ClientBanner } from "../../../../ui/molecules/clientBanner"
import { attivita_bio, normativa, optionsFormatter } from "../../../../utils"
import { ArrowLeftIcon } from "../../../../ui/icons/arrow-left"
import { RightBar } from "../../../../ui/organisms/rightBar"
import { EditRegistroForms } from "./forms"
import { EditRegistroRight } from "./right"
import { useEffect, useState } from "react"
import { BusinessRegisterUpdate, BusinessRegisterValidation, resetBusinessRegisterDeleteStatus, resetBusinessRegisterUpdateStatus, resetBusinessRegisterValidationStatus } from "../slice"

export function EditRegistroAziendale() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const userState = useAppSelector(state => state.users)
    const registerState = useAppSelector(state => state.register)
    const sessionState = useAppSelector(state => state.session)
    const [showSuccess, setShowSuccess] = useState<boolean>(false)

    useEffect(() => {
        if (registerState.businessRegisterUpdateStatus === 'successfully'
        ) {
            dispatch(resetBusinessRegisterUpdateStatus())
            setShowSuccess(true)
        }
    }, [registerState.businessRegisterUpdateStatus])

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setShowSuccess(false)
            }, 2000)
        }
    }, [showSuccess])

    useEffect(() => {
        if (registerState.businessRegisterValidationStatus === 'successfully') {
            dispatch(resetBusinessRegisterValidationStatus())
            dispatch(BusinessRegisterUpdate({ body: registerState.businessRegisterUpdateRequest, id: registerState.businessRegisterIdToUpdate }))
        }
    }, [registerState.businessRegisterValidationStatus])

    if (sessionState.selected.customerId) {
        return (
            <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione Clienti', 'Registri Aziendali']}
                noPaddingLat
                headerLabel="Registri Aziendali"
                headerIcon={
                    <button style={{ paddingTop: '12px' }} onClick={() => navigate('/registriAziendali')}>
                        <ArrowLeftIcon />
                    </button>
                }
                headerChildren={
                    <div className="flex items-center h-[100%] w-[100%] justify-end" style={{ justifyContent: 'end' }}>
                        <Button
                            iconPosition='off'
                            variant='solid'
                            color='orange'
                            onClick={() => dispatch(BusinessRegisterValidation(registerState.businessRegisterUpdateRequest))}
                            size='sm'
                            label='Modifica'
                        />
                    </div>
                }
            >
                <div style={{ display: 'flex', gap: '12px', minHeight: window.innerHeight }}>
                    <div style={{ flex: '0.6', padding: '24px' }}>
                        {/* <div className="pb-5">
                            <ClientBanner
                                region={sessionState.selected.customerRegion!}
                                client={sessionState.selected.customerName!}
                                code={sessionState.selected.customerCode!}
                                activity={sessionState.selected.customerActivities ? `${sessionState.selected.customerActivities.map(act => optionsFormatter(act, attivita_bio))}` : undefined}
                                norm={optionsFormatter(sessionState.selected.customerNorm!, normativa)!}
                            />
                        </div> */}
                        {
                            showSuccess &&
                            <div className="gap-[8px] text-left" style={{ margin: '24px', borderRadius: '12px', backgroundColor: '#D1F5D9', borderColor: '#8AE59F', borderWidth: 2 }}>
                                <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                                    Dati salvati correttamente.
                                </span>
                            </div>
                        }
                        <EditRegistroForms />
                    </div>
                    <div className="bg-brandPrimary-100" style={{ flex: '0.4' }}>
                        <RightBar disabled={false}>
                            <EditRegistroRight />
                        </RightBar>
                    </div>
                </div>
            </Layout>
        )
    }

    return (
        <Layout menuItem={MenuItems.USERS} breadcrumbItems={['Dashboard', 'Gestione RID']}
            noPaddingLat
            headerLabel={"Registri aziendali"}
            customerName={sessionState.selected.customerName ? sessionState.selected.customerName : undefined}
            customerCode={sessionState.selected.customerCode ? sessionState.selected.customerCode : undefined}
        >
            <Banner
                type="error"
                visible={!sessionState.selected.customerId}
                label={"Devi prima selezionare un cliente per accedere all'area"}
                closeAction={function (): void {
                    alert("Devi prima selezionare un cliente per accedere all'area")
                }}
            />
        </Layout>
    )
}