import { useEffect, useState } from "react";
import Button from "../../../../ui/molecules/button";
import { Calendar } from "../../../../ui/molecules/calendar";
import Input from "../../../../ui/molecules/input";
import InputMultiSearch from "../../../../ui/molecules/input/InputMultiSearch";
import { SelectCustom } from "../../../../ui/molecules/select";
import { countries, DeliveryManagementType, departureMeansOptions, OptionType, regioniList } from "../../../../utils";
import TextArea from "../../../../ui/molecules/textArea";
import { PopupForm } from "../../../../ui/organisms/popupForm";
import { AddObjectPopupEntry } from "../../popup/addObjectEntry";
import CheckboxGroup from "../../../../ui/molecules/CheckBoxGroup";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { DepartureProtocolCreation, DepartureProtocolUpdate, DepartureProtocolUploadAttachmentFile, DepartureProtocolValidation, GetAllDepartureProtocolObjects, GetDepartureProtocolById, resetDepartureProtocolErrors, resetDepartureProtocolUpdateStatus, resetDepartureProtocolUploadAttachmentFileStatus, resetDepartureProtocolValidationStatus, resetGetAllDepartureProtocolObjectsStatus, setCorriere, setDataBolgetta, setDataReceive, setDataRicezioneCompiutaGiacenza, setDataRitornoErratoIndirizzo, setDepartureProtocolIdToUpdate, setFileToUpdate, setFromCreationToUpdate, setNumRaccomandata, setNumeroRaccomandataErratoIndirizzo, setPEC, setPPCustomerIds, setPPCustomersNotRegistered, setPPDepartureChannel, setPPDepartureObjectId, setPPFileAndAtt, setPPHeadquarter, setPPLinkExistingProtocol, setPPNation, setPPObjectSpec, setPPReceiver, setPPRegion, setPPTotalPage, setPPUserId, setPProtocolDate, setPostaElettronica, setRaccomandataAr, setRaccomandataMano, setUpdateCorriere, setUpdateDataBolgetta, setUpdateDataReceive, setUpdateDataRicezioneCompiutaGiacenza, setUpdateDataRitornoErratoIndirizzo, setUpdateNumRaccomandata, setUpdateNumeroRaccomandataErratoIndirizzo, setUpdatePEC, setUpdatePPCustomerIds, setUpdatePPCustomersNotRegistered, setUpdatePPDepartureAttachmentIds, setUpdatePPDepartureChannel, setUpdatePPDepartureObjectId, setUpdatePPHeadquarter, setUpdatePPLinkExistingProtocol, setUpdatePPNation, setUpdatePPObjectSpec, setUpdatePPProtocolDate, setUpdatePPRegion, setUpdatePPSend, setUpdatePPTotalPage, setUpdatePPUsersVisibility, setUpdatePostaElettronica, setUpdateRaccomandataAr, setUpdateRaccomandataMano, setUpdatedFile, setUpdatedPPAttachmentFile, setUpdatepPSender } from "../slice";
import { ProtocolSearch } from "../../../../ui/molecules/ProtocolSearch";
import { GetFileObjectById, resetGetFileObjectByIdStatus } from "../../../../objectStorage/slice";
import { FindMe, findUserById, resetfindMeStatusIdle } from "../../../users/slice";

export function NewDepartureLeft() {
    const dispatch = useAppDispatch()
    const [showPopup, setShowPopup] = useState<boolean>(false)
    const userState = useAppSelector(state => state.users)
    const [selectedDeliveries, setSelectedDeliveries] = useState<DeliveryManagementType[]>([])
    const departureProtocolState = useAppSelector(state => state.departureProtocol)
    const objectStorageState = useAppSelector(state => state.objectStorage)
    const [objects, setObjects] = useState<OptionType[]>([])
    const [file, setFile] = useState<File | null>(null)
    const [officeSot, setOfficeSot] = useState<OptionType[]>([])

    let formData = new FormData();
    let attachmentFormdata = new FormData();

    useEffect(() => {
        dispatch(FindMe())
    }, [])

    useEffect(() => {
        if (userState.findMeStatus === 'successfully') {
            dispatch(resetfindMeStatusIdle())
            if (userState.findMeResponse.headQuarterInfo !== null && userState.findMeResponse.headQuarterInfo !== undefined) {
                const newOption: OptionType = {
                    value: userState.findMeResponse.headQuarterInfo.code,
                    label: userState.findMeResponse.headQuarterInfo.code
                };

                setOfficeSot(prevOptions => {
                    const optionExists = prevOptions.some(option => option.value === newOption.value);
                    if (!optionExists) {
                        return [...prevOptions, newOption];
                    }
                    return prevOptions;
                });
            }

            if (userState.findMeResponse.office !== null && userState.findMeResponse.office !== undefined) {
                const newOption: OptionType = {
                    value: userState.findMeResponse.office,
                    label: userState.findMeResponse.office
                };

                setOfficeSot(prevOptions => {
                    const optionExists = prevOptions.some(option => option.value === newOption.value);
                    if (!optionExists) {
                        return [...prevOptions, newOption];
                    }
                    return prevOptions;
                });
            }
        }
    }, [userState.findMeStatus])

    useEffect(() => {
        if (departureProtocolState.getAllDepartureProtocolObjectsStatus === 'successfully' &&
            departureProtocolState.getAllDepartureProtocolObjectsResponse !== undefined
        ) {
            dispatch(resetGetAllDepartureProtocolObjectsStatus())
            const data = departureProtocolState.getAllDepartureProtocolObjectsResponse
            const newObjects: OptionType[] = data.map(d => {
                return { value: d.id, label: `${d.description} [${d.coding}]` } as OptionType
            })
            setObjects(newObjects)
        }
    }, [departureProtocolState.getAllDepartureProtocolObjectsStatus])

    useEffect(() => {
        setFile(departureProtocolState.fileToUpdate as File)
    }, [departureProtocolState.fileToUpdate])

    useEffect(() => {

        if (departureProtocolState.departureProtocolValidationStatus === 'successfully') {
            dispatch(resetDepartureProtocolValidationStatus())
            if (!departureProtocolState.fromCreationToUpdate) {
                dispatch(DepartureProtocolCreation(departureProtocolState.departureProtocolCreationRequest))
            }
            else {
                formData.delete('file')
                if (file !== null && file !==undefined)
                    formData.append('file', file)
                formData.append('departureProtocolUpdateDTO', JSON.stringify(departureProtocolState.departureProtocolUpdateRequest))
                dispatch(DepartureProtocolUpdate({ body: formData, id: departureProtocolState.departureProtocolIdToUpdate }))
            }
        }
    }, [departureProtocolState.departureProtocolValidationStatus])

    useEffect(() => {
        if (departureProtocolState.getDepartureProtocolByIdStatus === 'successfully' &&
            departureProtocolState.getDepartureProtocolByIdResponse !== undefined
        ) {
            dispatch(setFromCreationToUpdate(true))
            const data = departureProtocolState.getDepartureProtocolByIdResponse
            dispatch(setUpdatePPProtocolDate(data.protocolDate))
            dispatch(setDepartureProtocolIdToUpdate(data.id))
            dispatch(setUpdatePPHeadquarter(data.headQuarter))
            dispatch(setUpdatePPNation(data.nation))
            dispatch(setUpdatePPRegion(data.region))
            dispatch(setUpdatepPSender(data.receiver))
            if (data.customersInfo !== null)
                dispatch(setUpdatePPCustomerIds(data.customersInfo.map(c => c.id)))
            dispatch(setUpdatePPCustomersNotRegistered(data.customersNotRegistered))
            dispatch(setUpdatePPDepartureObjectId(data.departureObject.id))
            dispatch(setUpdatePPObjectSpec(data.objectSpec))
            dispatch(setUpdatePPTotalPage(data.totalPage))
            dispatch(setUpdatePPDepartureChannel(data.departureChannel))
            dispatch(setUpdatePPSend(data.send))
            dispatch(setUpdatePPUsersVisibility(data.usersVisibility))
            dispatch(setUpdatePPLinkExistingProtocol(data.linkExistingProtocol))
            dispatch(setUpdatePPDepartureAttachmentIds(data.departureAttachments.map(att => att.id)))
            dispatch(setUpdateNumRaccomandata(data.numeroRaccomandata))
            dispatch(setUpdateDataBolgetta(data.dataBolgetta))
            dispatch(setUpdateDataRicezioneCompiutaGiacenza(data.dataRicezioneCompiutaGiacenza))
            dispatch(setUpdateDataRitornoErratoIndirizzo(data.dataRitornoErratoIndirizzo))
            dispatch(setUpdateNumeroRaccomandataErratoIndirizzo(data.numeroRaccomandataErratoIndirizzo))
            dispatch(setUpdateCorriere(data.corriere))
            dispatch(setUpdateRaccomandataMano(data.raccomandataMano))
            dispatch(setUpdatePostaElettronica(data.postaElettronica))
            dispatch(setUpdateDataReceive(data.receive))
            dispatch(setUpdateRaccomandataAr(data.raccomandataAr))
            dispatch(setUpdatePEC(data.postaElettronicaCertificata))
            dispatch(setUpdatedPPAttachmentFile(data.attachmentFile))
        }
    }, [departureProtocolState.getDepartureProtocolByIdStatus])

    useEffect(() => {
        setFile(null)
        dispatch(setPPUserId(userState.loggedUserId))
        dispatch(setFromCreationToUpdate(false))
        dispatch(resetDepartureProtocolErrors())
        dispatch(GetAllDepartureProtocolObjects())
    }, [])

    useEffect(() => {
        if (departureProtocolState.departureProtocolUpdateStatus === 'successfully' &&
            departureProtocolState.departureProtocolUpdateResponse !== undefined
        ) {
            dispatch(resetDepartureProtocolUpdateStatus())
            dispatch(setFileToUpdate(null))
            if (departureProtocolState.departureProtocolUpdateResponse !== null)
                dispatch(GetFileObjectById(departureProtocolState.departureProtocolUpdateResponse))

            if(departureProtocolState.departureProtocolUpdateFileAndAtt.length > 0) {
                const attF = departureProtocolState.departureProtocolUpdateFileAndAtt[0]
                dispatch(setPPFileAndAtt(departureProtocolState.departureProtocolUpdateFileAndAtt.slice(1)))
                if (attF !== undefined && attF && attF !== null) {
                    if (attF.file !== undefined && attF.file) {
                        attachmentFormdata.delete('file')
                        attachmentFormdata.append('file', attF.file)
                        attachmentFormdata.append('attachmentFileDTO', JSON.stringify({attachmentId: attF.attachmentId}))
                        dispatch(DepartureProtocolUploadAttachmentFile({data: attachmentFormdata, id: departureProtocolState.departureProtocolIdToUpdate}))
                    }
                }
            }
        }
    }, [departureProtocolState.departureProtocolUpdateStatus])

    useEffect(() => {
        if(departureProtocolState.departureProtocolUploadAttachmentFileStatus === 'successfully') {
            dispatch(resetDepartureProtocolUploadAttachmentFileStatus())
            if(departureProtocolState.departureProtocolUpdateFileAndAtt.length > 0) {
                const attF = departureProtocolState.departureProtocolUpdateFileAndAtt[0]
                dispatch(setPPFileAndAtt(departureProtocolState.departureProtocolUpdateFileAndAtt.slice(1)))
                if (attF !== undefined && attF && attF !== null) {
                    if (attF.file !== undefined && attF.file) {
                        attachmentFormdata.delete('file')
                        attachmentFormdata.append('file', attF.file)
                        attachmentFormdata.append('attachmentFileDTO', JSON.stringify({attachmentId: attF.attachmentId}))
                        dispatch(DepartureProtocolUploadAttachmentFile({data: attachmentFormdata, id: departureProtocolState.departureProtocolIdToUpdate}))
                    }
                }
            } else {
                dispatch(GetDepartureProtocolById(departureProtocolState.departureProtocolIdToUpdate))
            }
        }
    }, [departureProtocolState.departureProtocolUploadAttachmentFileStatus])

    useEffect(() => {
        if (objectStorageState.getFileObjectByIdStatus === 'successfully' &&
            objectStorageState.getFileObjectByIdResponse !== undefined
        ) {
            dispatch(resetGetFileObjectByIdStatus())
            dispatch(setUpdatedFile(objectStorageState.getFileObjectByIdResponse))
        }
    }, [objectStorageState.getFileObjectByIdStatus])

    const handleTypesChange = (value: DeliveryManagementType) => {
        let currentDeliveries = [...selectedDeliveries]
        if (currentDeliveries.includes(value)) {
            currentDeliveries = currentDeliveries.filter(t => t !== value)
        }
        else {
            currentDeliveries.push(value)
        }
        setSelectedDeliveries(currentDeliveries)

        const corriereState = currentDeliveries.includes(DeliveryManagementType.CORRIERE)
        !departureProtocolState.fromCreationToUpdate ?
            dispatch(setCorriere(corriereState)) :
            dispatch(setUpdateCorriere(corriereState))

        const raccAManoState = currentDeliveries.includes(DeliveryManagementType.RACC_M)
        !departureProtocolState.fromCreationToUpdate ?
            dispatch(setRaccomandataMano(raccAManoState)) :
            dispatch(setUpdateRaccomandataMano(raccAManoState))

        const postaElettState = currentDeliveries.includes(DeliveryManagementType.POST_E)
        !departureProtocolState.fromCreationToUpdate ?
            dispatch(setPostaElettronica(postaElettState)) :
            dispatch(setUpdatePostaElettronica(postaElettState))

        const pecState = currentDeliveries.includes(DeliveryManagementType.PEC)
        !departureProtocolState.fromCreationToUpdate ?
            dispatch(setPEC(pecState)) :
            dispatch(setUpdatePEC(pecState))

        const raccARState = currentDeliveries.includes(DeliveryManagementType.RACC_AR)
        !departureProtocolState.fromCreationToUpdate ?
            dispatch(setRaccomandataAr(raccARState)) :
            dispatch(setUpdateRaccomandataAr(raccARState))
    }

    return (
        <div>

            {/* HEADER */}
            <div className="protocol-header">
                <span className='title'>Nuovo protocollo partenza</span>
                <Button
                    variant='solid'
                    color='orange'
                    size='sm'
                    iconPosition='off'
                    label='Salva protocollo'
                    onClick={() => { dispatch(DepartureProtocolValidation(departureProtocolState.departureProtocolCreationRequest)) }}
                />
            </div>

            {/* CONTENT */}
            <div className='protocol-content'>

                {/* FIRST ROW */}
                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                    <div style={{ flex: '0.25' }}>
                        <div className="text-left">
                            <span className="input-label">Protocollo</span>
                        </div>
                        <SelectCustom
                            error={departureProtocolState.departureProtocolErrors.headQuarter}
                            errorLabel='Sede mancante'
                            placeholder={'Seleziona protocollo'}
                            options={officeSot}
                            onChange={(value) => dispatch(setPPHeadquarter(value))}
                            disabled={departureProtocolState.fromCreationToUpdate}
                            defaultValue={departureProtocolState.departureProtocolCreationRequest.headQuarter}
                            value={departureProtocolState.departureProtocolCreationRequest.headQuarter}
                        />
                    </div>

                    <div style={{ flex: '0.25' }}>
                        <div className="text-left">
                            <span className="input-label">Data protocollo</span>
                        </div>
                        <Calendar
                            errorLabel={departureProtocolState.departureProtocolErrors.protocolDate ? 'Data mancante' : undefined}
                            disabled={departureProtocolState.fromCreationToUpdate}
                            selected={departureProtocolState.departureProtocolCreationRequest.protocolDate}
                            onChange={(date) => dispatch(setPProtocolDate(date))}
                        />
                    </div>

                    <div style={{ flex: '0.25' }}>
                        <div className="text-left">
                            <span className="input-label">Nazione</span>
                        </div>
                        <SelectCustom
                            placeholder={'Seleziona nazione'}
                            options={countries}
                            onChange={(value) => !departureProtocolState.fromCreationToUpdate ?
                                dispatch(setPPNation(value)) :
                                dispatch(setUpdatePPNation(value))}
                                value={!departureProtocolState.fromCreationToUpdate ?
                                    (departureProtocolState.departureProtocolCreationRequest.nation ? departureProtocolState.departureProtocolCreationRequest.nation : 'IT') :
                                    (departureProtocolState.departureProtocolCreationRequest.nation ? departureProtocolState.departureProtocolCreationRequest.nation : 'IT')
                                }
                        />
                    </div>

                    <div style={{ flex: '0.25' }}>
                        <div className="text-left">
                            <span className="input-label">Regione</span>
                        </div>
                        <SelectCustom
                            placeholder={'Seleziona regione'}
                            options={regioniList}
                            onChange={(value) => !departureProtocolState.fromCreationToUpdate ?
                                dispatch(setPPRegion(value)) :
                                dispatch(setUpdatePPRegion(value))
                            }
                            value={!departureProtocolState.fromCreationToUpdate ?
                                (departureProtocolState.departureProtocolCreationRequest.nation ? departureProtocolState.departureProtocolCreationRequest.nation : 'IT') :
                                (departureProtocolState.departureProtocolUpdateRequest.nation ? departureProtocolState.departureProtocolUpdateRequest.nation : 'IT')
                            }
                        />
                    </div>
                </div>

                {/* SECOND ROW */}
                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                    <div style={{ flex: '1' }}>
                        <Input
                            error={departureProtocolState.departureProtocolErrors.receiver}
                            supportingText={departureProtocolState.departureProtocolErrors.receiver ? 'Mittente mancante' : undefined}
                            label={'Destinatario'}
                            placeholder="Destinatario dei documenti da protocollare"
                            value={!departureProtocolState.fromCreationToUpdate ?
                                departureProtocolState.departureProtocolCreationRequest.receiver :
                                departureProtocolState.departureProtocolUpdateRequest.receiver
                            }
                            onChangeText={(text) => !departureProtocolState.fromCreationToUpdate ?
                                dispatch(setPPReceiver(text)) :
                                dispatch(setUpdatepPSender(text))
                            }
                        />
                    </div>
                </div>

                {/* THIRD ROW */}
                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center', borderBottomWidth: 1 }}>
                    <div style={{ flex: '1' }}>
                        <InputMultiSearch
                            label={'Cliente'}
                            placeholder="Ragione sociale"
                            onSelectAddHandler={(res) => !departureProtocolState.fromCreationToUpdate ?
                                dispatch(setPPCustomerIds(res)) :
                                dispatch(setUpdatePPCustomerIds(res))
                            }
                        />
                    </div>
                </div>

                {/* FOURTH ROW */}
                <div className='pt-5 pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                    <div style={{ flex: '1' }}>
                        <Input
                            label={'Cliente non presente in archivio'}
                            placeholder="Indicare nel caso di cliente non ancora inserito in archivio"
                            value={!departureProtocolState.fromCreationToUpdate ?
                                departureProtocolState.departureProtocolCreationRequest.customersNotRegistered :
                                departureProtocolState.departureProtocolUpdateRequest.customersNotRegistered
                            }
                            onChangeText={(text) => !departureProtocolState.fromCreationToUpdate ?
                                dispatch(setPPCustomersNotRegistered(text)) :
                                dispatch(setUpdatePPCustomersNotRegistered(text))
                            }
                        />
                    </div>
                </div>

                {/* FIFTH ROW */}
                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                    <div style={{ flex: '0.85' }}>
                        <div className="text-left">
                            <span className="input-label">{'Oggetto (Da elenco)'}</span>
                        </div>
                        { /* options da backend */}
                        <SelectCustom
                            error={departureProtocolState.departureProtocolErrors.entryObjectId}
                            errorLabel='Oggetto mancante'
                            placeholder={'Seleziona...'}
                            options={objects}
                            value={!departureProtocolState.fromCreationToUpdate ? departureProtocolState.departureProtocolUpdateRequest.departureObjectId : undefined}
                            onChange={(value) => !departureProtocolState.fromCreationToUpdate ?
                                dispatch(setPPDepartureObjectId(value)) :
                                dispatch(setUpdatePPDepartureObjectId(value))
                            }
                        />
                    </div>
                    {
                        userState.findMeResponse.role === 'admin' &&
                        <div style={{ flex: '0.15', alignSelf: 'flex-end', paddingBottom: '6px' }}>
                            <Button
                                variant='outline'
                                color='orange'
                                size='sm'
                                iconPosition='off'
                                label='Crea oggetto'
                                onClick={() => setShowPopup(true)}
                            />
                        </div>
                    }

                </div>

                {/* SIXTH ROW */}
                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center', borderBottomWidth: 1 }}>
                    <div style={{ flex: '1' }}>
                        <div className="text-left">
                            <span className="input-label">Specifiche oggetto</span>
                        </div>
                        <TextArea
                            placeholder="Integrazione all'oggetto indicato sopra"
                            onChangeText={(text) => !departureProtocolState.fromCreationToUpdate ?
                                dispatch(setPPObjectSpec(text)) :
                                dispatch(setUpdatePPObjectSpec(text))
                            }
                        />
                    </div>
                </div>

                {/* SEVENTH ROW */}
                <div className='pb-5 pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center', borderBottomWidth: 1 }}>
                    <div style={{ flex: '0.5' }}>
                        <Input
                            // error={}
                            // supportingText={}
                            label={'Totale pagine'}
                            placeholder="Specificare numero"
                            // value={}
                            // defaultValue={}
                            value={!departureProtocolState.fromCreationToUpdate ?
                                departureProtocolState.departureProtocolCreationRequest.totalPage :
                                departureProtocolState.departureProtocolUpdateRequest.totalPage
                            }
                            onChangeText={(text) => !departureProtocolState.fromCreationToUpdate ?
                                dispatch(setPPTotalPage(Number(text))) :
                                dispatch(setUpdatePPTotalPage(Number(text)))
                            }
                        />
                    </div>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Mezzo Partenza</span>
                        </div>
                        <SelectCustom
                            placeholder={'Seleziona...'}
                            options={departureMeansOptions}
                            onChange={(value) => !departureProtocolState.fromCreationToUpdate ?
                                dispatch(setPPDepartureChannel(value)) :
                                dispatch(setUpdatePPDepartureChannel(value))
                            }
                            value={!departureProtocolState.fromCreationToUpdate ?
                                departureProtocolState.departureProtocolCreationRequest.departureChannel :
                                departureProtocolState.departureProtocolUpdateRequest.departureChannel
                            }
                        />
                    </div>
                </div>

                <div className="text-left pt-5 pb-2">
                    <span className="input-label" style={{ fontSize: '16px', fontWeight: 600 }}>
                        Aggancia a protocollo esistente
                    </span>
                </div>

                {/* EIGHTH ROW (Da rimpiazzare con componente) */}
                <div className='pb-5' style={{ borderBottomWidth: 1 }}>
                    <ProtocolSearch
                        type='entry'
                        onSelect={(value) => !departureProtocolState.fromCreationToUpdate ?
                            dispatch(setPPLinkExistingProtocol(value)) :
                            dispatch(setUpdatePPLinkExistingProtocol(value))
                        } />
                </div>

                <div className="text-left pt-5 pb-5">
                    <span className="input-label" style={{ fontSize: '20px', fontWeight: 700 }}>
                        Gestione consegna
                    </span>
                </div>

                <div className="text-left pb-5">
                    <span className="input-label" style={{ fontSize: '16px', fontWeight: 600 }}>
                        Ricevuta a mezzo:
                    </span>
                </div>

                {/* EIGHTH ROW */}
                <div style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                    <div style={{ flex: '1', justifySelf: 'flex-start' }}>
                        <CheckboxGroup
                            name="tipo_consegna_1"
                            options={Object.values(DeliveryManagementType).splice(0, 2)}
                            selectedValues={selectedDeliveries}
                            onChange={handleTypesChange}
                            optionLabels={{
                                [DeliveryManagementType.PEC]: "Posta Elettronica Certificata",
                                [DeliveryManagementType.RACC_AR]: "Raccomandata A/R",
                            }}
                        />
                    </div>
                </div>

                <div style={{ marginLeft: '20px' }}>
                    <div className='pb-2' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                        <div style={{ flex: '0.33' }}>
                            <Input
                                // error={}
                                // supportingText={}
                                label="Numero raccomandata"
                                placeholder="Numero raccomandata..."
                                value={departureProtocolState.departureProtocolCreationRequest.numeroRaccomandata}
                                defaultValue={departureProtocolState.departureProtocolCreationRequest.numeroRaccomandata}
                                onChangeText={(text) => {
                                    !departureProtocolState.fromCreationToUpdate ?
                                    dispatch(setNumRaccomandata(text)) :
                                    dispatch(setUpdateNumRaccomandata(text))
                                }}
                            />
                        </div>
                        <div style={{ flex: '0.33' }}>
                            <div className="text-left">
                                <span className="input-label">Data bolgetta</span>
                            </div>
                            <Calendar
                                selected={departureProtocolState.departureProtocolCreationRequest.dataBolgetta}
                                onChange={(date) => {
                                    !departureProtocolState.fromCreationToUpdate ?
                                    dispatch(setDataBolgetta(date)) :
                                    dispatch(setUpdateDataBolgetta(date))
                                }}
                            />
                        </div>
                        <div style={{ flex: '0.33' }}>
                            <div className="text-left">
                                <span className="input-label">Data ricezione per compiuta giacenza</span>
                            </div>
                            <Calendar
                                selected={departureProtocolState.departureProtocolCreationRequest.dataRicezioneCompiutaGiacenza}
                                onChange={(date) => {
                                    !departureProtocolState.fromCreationToUpdate ?
                                    dispatch(setDataRicezioneCompiutaGiacenza(date)) :
                                    dispatch(setUpdateDataRicezioneCompiutaGiacenza(date))
                                }}
                            />
                        </div>
                    </div>

                    <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                        <div style={{ flex: '0.5' }}>
                            <div className="text-left">
                                <span className="input-label">{`Rispedita per errato indirizzo (Data ritorno)`}</span>
                            </div>
                            <Calendar
                                selected={departureProtocolState.departureProtocolCreationRequest.dataRitornoErratoIndirizzo}
                                onChange={(date) => {
                                    !departureProtocolState.fromCreationToUpdate ?
                                    dispatch(setDataRitornoErratoIndirizzo(date)) :
                                    dispatch(setUpdateDataRitornoErratoIndirizzo(date))
                                }}
                            />
                        </div>
                        <div style={{ flex: '0.5' }}>
                            <Input
                                label="Raccomandata Errato indirizzo"
                                placeholder="Numero raccomandata..."
                                value={departureProtocolState.departureProtocolCreationRequest.numeroRaccomandataErratoIndirizzo}
                                defaultValue={departureProtocolState.departureProtocolCreationRequest.numeroRaccomandataErratoIndirizzo}
                                onChangeText={(text) => {
                                    !departureProtocolState.fromCreationToUpdate ?
                                    dispatch(setNumeroRaccomandataErratoIndirizzo(text)) :
                                    dispatch(setUpdateNumeroRaccomandataErratoIndirizzo(text))
                                }}
                            />
                        </div>
                    </div>
                </div>

                {/* NINETH ROW */}
                <div className='pb-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center', borderBottomWidth: 1 }}>
                    <div style={{ flex: '1', justifySelf: 'flex-start' }}>
                        <CheckboxGroup
                            name="tipo_consegna_2"
                            options={Object.values(DeliveryManagementType).splice(2, 3)}
                            selectedValues={selectedDeliveries}
                            onChange={handleTypesChange}
                            optionLabels={{
                                [DeliveryManagementType.CORRIERE]: "Corriere",
                                [DeliveryManagementType.RACC_M]: "Raccomandata a mano",
                                [DeliveryManagementType.POST_E]: "Posta elettronica",
                            }}
                        />
                    </div>
                </div>

                {/* SECOND ROW */}
                <div className='pb-5 pt-5' style={{ display: 'flex', flex: 'wrap', gap: '24px', alignItems: 'center' }}>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Data ricezione</span>
                        </div>
                        <Calendar
                            selected={departureProtocolState.departureProtocolCreationRequest.receive}
                            onChange={(date) => {
                                !departureProtocolState.fromCreationToUpdate ?
                                dispatch(setDataReceive(date)) :
                                dispatch(setUpdateDataReceive(date))
                            }}
                        />
                    </div>

                </div>
            </div>
            {
                showPopup && (
                    <PopupForm title='Aggiungi Oggetto' close={() => setShowPopup(false)}>
                        <AddObjectPopupEntry close={() => setShowPopup(false)} />
                    </PopupForm>
                )
            }
        </div>
    )
}