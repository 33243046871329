import React, { useEffect, useState } from "react";
import { TableSelectColorMap, TableSelectProps } from "./tableSelect.types";
import "./styles.scss";

export const TableSelect: React.FC<TableSelectProps> = ({ ...props }) => {
    const [selected, setSelected] = useState<string[]>([]);
    const selectedColor = TableSelectColorMap.get(props.color ? props.color : 'green')

    const handleOnCheckboxClick = (value: string) => {
        let currSelected = [...selected];
        if (currSelected.includes(value)) {
            currSelected = currSelected.filter(c => c !== value);
        } else {
            currSelected.push(value);
        }
        props.onChange && props.onChange(currSelected);
        setSelected(currSelected);
    };

    const handleOnRadioClick = (value: string) => {
        const currSelected = selected.includes(value) ? [] : [value];
        setSelected(currSelected);
        props.onChange && props.onChange(currSelected);
    };

    useEffect(() => {
        if(props.defaultValue)
            setSelected(props.defaultValue)
    }, [])

    return (
        <div>
            <table id="table-select" className="w-[100%]" style={{backgroundColor: selectedColor?.contentColor}}>
                <thead className="h-[30px] uppercase">
                    <tr>
                        <th className="width-[40px] rounded-tl-[12px]"></th>
                        {props.headers.map((h, idx) => (
                            <th key={idx}>{h}</th>
                        ))}
                        <th className="width-[40px] rounded-tr-[12px]"></th>
                    </tr>
                </thead>
                <tbody>
                    {props.data &&
                        props.data.map((d, index) => (
                            <tr
                                key={index}
                                style={{
                                    color: selectedColor?.contentColor,
                                    background: index % 2 === 0 ? selectedColor?.borderColor : "#FFFFFF"
                                }}
                            >
                                <td>
                                    {props.type === "checkbox" ? (
                                        <div
                                            className={`checkbox ${selected.includes(d.value) ? `selected ${props.color === "orange" ? " orange" : null}` : ""
                                                }`}
                                            onClick={() => handleOnCheckboxClick(d.value)}
                                        ></div>
                                    ) : (
                                        <div
                                            className={`radio ${selected.includes(d.value) ? `selected ${props.color === "orange" ? " orange" : null}` : ""
                                                }`}
                                            onClick={() => handleOnRadioClick(d.value)}
                                        ></div>
                                    )}
                                </td>
                                {d.labels.map((label, idx) => (
                                    <td key={idx}>{label}</td>
                                ))}
                                <td></td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
};
