import { useEffect, useState } from "react"
import { EditIcon } from "../../ui/icons/edit"
import { SquaredTickIcon } from "../../ui/icons/SquaredTickIcon"
import { TrashIcon } from "../../ui/icons/trash"
import { formatterDate, normativa, optionsFormatter, ServiceTypeNormoptions, yearOptions } from "../../utils"
import { PopupForm } from "../../ui/organisms/popupForm"
import { SchedaCalcoloListPopup } from "./listPopup"
import { SelectCustom } from "../../ui/molecules/select"
import Button from "../../ui/molecules/button"
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks"
import { GetAllBillingWorksheet, setBWCreationNorm, setBWCreationYear, setBWIdToUpdate, setOnEdit } from "./slice"
import { BillingWorksheetDTO } from "./dataService/dto"
import { useNavigate } from "react-router-dom"

export function SchedaCalcoloList() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const serviceAndBillingState = useAppSelector(state => state.billingAndServiceType)

    useEffect(() => {
        dispatch(GetAllBillingWorksheet(serviceAndBillingState.getAllBillingWorksheetFilters))
    }, [])

    const handleOnEdit = (sc: BillingWorksheetDTO) => {
        dispatch(setBWCreationNorm(sc.norm))
        dispatch(setBWCreationYear(sc.year))
        dispatch(setBWIdToUpdate(sc.id))
        dispatch(setOnEdit(true))
        navigate('/schedaCalcoloEdit')
    }

    return (
        <div>
            <div style={{ display: 'flex', padding: '0px 24px 0px 24px' }}>
                <div style={{ display: 'flex', flex: '0.90', gap: '24px' }}>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Normativa</span>
                        </div>
                        <SelectCustom
                            // key={ || 'default'}
                            // value={}
                            placeholder='Specifica normativa...'
                            options={normativa}
                            onChange={(value) => { }}
                        />
                    </div>
                    <div style={{ flex: '0.5' }}>
                        <div className="text-left">
                            <span className="input-label">Anno</span>
                        </div>
                        <SelectCustom
                            // key={ || 'default'}
                            // value={}
                            placeholder='Specifica anno...'
                            options={yearOptions}
                            onChange={(value) => { }}
                        />
                    </div>
                </div>

                <div style={{ display: 'flex', gap: '18px', alignItems: 'flex-end' }}>
                    <Button
                        size={"lg"}
                        iconPosition={"off"}
                        variant={"link"}
                        label="Pulisci"
                        color={"gray"}
                        onClick={() => { }}
                    />
                    <Button
                        variant='outline'
                        color='blue'
                        size='md'
                        iconPosition='off'
                        label='Cerca'
                        onClick={() => { }}
                    />
                </div>
            </div>
            <div style={{ padding: '24px' }}>
                <table id="users-table" className="w-[100%]">
                    <thead className="h-[52px] uppercase">
                        <tr>
                            <th style={{ borderTopLeftRadius: '8px' }}></th>
                            <th style={{ textAlign: 'center' }}>Anno</th>
                            <th style={{ textAlign: 'center' }}>Data Emissione</th>
                            <th style={{ textAlign: 'center' }}>Normativa</th>
                            <th style={{ borderTopRightRadius: '8px', width: '40px' }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            serviceAndBillingState.getAllBillingWorksheetResponse?.data.map((sc, index) =>
                                <tr
                                    key={index}
                                    style={{
                                        color: '#4D797F',
                                        background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                    }}
                                >
                                    <td></td>
                                    <td style={{ textAlign: 'center' }}>{sc.year}</td>
                                    <td style={{ textAlign: 'center' }}>{formatterDate(sc.createdAt!)}</td>
                                    <td style={{ textAlign: 'center' }}>{optionsFormatter(sc.norm, ServiceTypeNormoptions)}</td>
                                    <td>
                                        <button onClick={() => handleOnEdit(sc)}>
                                            <SquaredTickIcon />
                                        </button>
                                    </td>
                                </tr>
                            )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}