import { useEffect, useState } from "react"
import { EditIcon } from "../../ui/icons/edit"
import { TrashIcon } from "../../ui/icons/trash"
import { Alert } from "../../ui/organisms/alert"
import Button from "../../ui/molecules/button"
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks"
import { PopupForm } from "../../ui/organisms/popupForm"
import { ODCPopupEdit } from "./popup"
import { GetAllOdc, OdcDelete, OdcRemove, OdcRestore, resetOdcCreationStatus, resetOdcDeleteStatus, resetOdcRemoveStatus, resetOdcRestoreStatus, resetOdcUpdateStatus, setOdcIdToUpdate, setOdcUpdateAddress, setOdcUpdateCity, setOdcUpdateCode, setOdcUpdateCompanyName, setOdcUpdateEmail, setOdcUpdateNation, setOdcUpdatePec, setOdcUpdatePhone, setOdcUpdateProvince, setOdcUpdateRegion, setOdcUpdateZipcode } from "./slice"
import { OdcDTO } from "./dataService/dto"
import Toggle from "../../ui/molecules/toggle"

export function ODCList() {
    const dispatch = useAppDispatch()
    const odcState = useAppSelector(state => state.odc)
    const [showSuccess, setShowSuccess] = useState<boolean>(false)
    const [showPopup, setShowPopup] = useState<boolean>(false)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    const [odcToDelete, setOdcToDelete] = useState<OdcDTO | null>(null)

    const onEditHandle = (odc: OdcDTO) => {
        dispatch(setOdcIdToUpdate(odc.id))
        dispatch(setOdcUpdateCompanyName(odc.companyName))
        dispatch(setOdcUpdateCode(odc.code))
        dispatch(setOdcUpdateAddress(odc.address))
        dispatch(setOdcUpdateRegion(odc.region))
        dispatch(setOdcUpdateProvince(odc.province))
        dispatch(setOdcUpdateCity(odc.city))
        dispatch(setOdcUpdateZipcode(odc.zipCode))
        dispatch(setOdcUpdatePhone(odc.phone))
        dispatch(setOdcUpdatePec(odc.pec))
        dispatch(setOdcUpdateEmail(odc.email))
        dispatch(setOdcUpdateNation(odc.nation))
        setShowPopup(true)
    }

    useEffect(() => {
        dispatch(GetAllOdc(odcState.getAllOdcFilters))
    }, [])

    useEffect(() => {
        if (odcState.odcCreationStatus === 'successfully') {
            dispatch(resetOdcCreationStatus())
            dispatch(GetAllOdc(odcState.getAllOdcFilters))
            setShowSuccess(true)
        }
        if (odcState.odcDeleteStatus === 'successfully') {
            dispatch(resetOdcDeleteStatus())
            dispatch(GetAllOdc(odcState.getAllOdcFilters))
            setShowSuccess(true)
        }
        if (odcState.odcUpdateStatus === 'successfully') {
            dispatch(resetOdcUpdateStatus())
            dispatch(GetAllOdc(odcState.getAllOdcFilters))
            setShowSuccess(true)
        }
        if (odcState.odcRemoveStatus === 'successfully') {
            dispatch(resetOdcRemoveStatus())
            dispatch(GetAllOdc(odcState.getAllOdcFilters))
        }
        if (odcState.odcRestoreStatus === 'successfully') {
            dispatch(resetOdcRestoreStatus())
            dispatch(GetAllOdc(odcState.getAllOdcFilters))
        }
    }, [odcState.odcCreationStatus,
    odcState.odcDeleteStatus,
    odcState.odcUpdateStatus,
    odcState.odcRemoveStatus,
    odcState.odcRestoreStatus
    ])

    useEffect(() => {
        if (showSuccess) {
            setTimeout(() => {
                setShowSuccess(false)
            }, 2000)
        }
    }, [showSuccess])

    return (
        <div className="w-[100%] flex flex-col gap-[16px] filter bg-brandPrimary-200" style={{ flex: 0.7, padding: '0px 24px 24px 24px', borderTopRightRadius: '20px' }}>
            <div className="w-[100%]">
                {
                    (showSuccess) &&

                    <div className="gap-[8px] text-left bg-green-300" style={{ margin: '24px', borderRadius: '8px' }}>
                        <span className="text-green-700 fieldsetTitle" style={{ padding: '30px 25px', fontSize: '14px', fontFamily: 'Roboto', fontWeight: '700', display: 'block' }}>
                            Dati salvati correttamente.
                        </span>
                    </div>
                }
                <div style={{ padding: '24px 0' }}>
                    <table id="users-table" className="w-full table-fixed">
                        <thead className="h-[52px] uppercase">
                            <tr>
                                <th className="w-[100px]">Nominativo</th>
                                <th className="w-[100px]">Codice OdC</th>
                                <th className="w-[100px]">Indirizzo</th>
                                <th className="w-[100px]">Comune</th>
                                <th className="w-[100px]">CAP</th>
                                <th className="w-[70px]">Provincia</th>
                                <th className="w-[100px]">Regione</th>
                                <th className="w-[100px]">Telefono</th>
                                <th className="w-[100px]">PEC</th>
                                <th className="w-[100px]">Email</th>
                                <th className="w-[70px]">Attivo</th>
                                <th className="w-[60px]" style={{ borderTopRightRadius: '8px'}}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {odcState.getAllOdcResponse?.data.map((odc, index) => (
                                <tr
                                    key={index}
                                    style={{
                                        color: '#4D797F',
                                        background: index % 2 === 0 ? '#F2F7F7' : '#FFFFFF'
                                    }}
                                >
                                    <td className="whitespace-normal break-words" style={{ maxWidth: '150px' }}>{odc.companyName}</td>
                                    <td className="whitespace-normal break-words" style={{ maxWidth: '150px' }}>{odc.code}</td>
                                    <td className="whitespace-normal break-words" style={{ maxWidth: '150px' }}>{odc.address}</td>
                                    <td className="whitespace-normal break-words" style={{ maxWidth: '150px' }}>{odc.city}</td>
                                    <td className="whitespace-normal break-words" style={{ maxWidth: '150px' }}>{odc.zipCode}</td>
                                    <td className="whitespace-normal break-words" style={{ maxWidth: '150px' }}>{odc.province}</td>
                                    <td className="whitespace-normal break-words" style={{ maxWidth: '150px' }}>{odc.region}</td>
                                    <td className="whitespace-normal break-words" style={{ maxWidth: '150px' }}>{odc.phone}</td>
                                    <td className="whitespace-normal break-words" style={{ maxWidth: '150px' }}>{odc.pec}</td>
                                    <td className="whitespace-normal break-words" style={{ maxWidth: '170px' }}>{odc.email}</td>
                                    <td>
                                        <Toggle
                                            checked={odc.deletedAt === null}
                                            onChange={(value) =>
                                                value
                                                    ? dispatch(OdcRestore(odc.id!))
                                                    : dispatch(OdcRemove(odc.id!))
                                            }
                                        />
                                    </td>
                                    <td className="whitespace-normal break-words" style={{ maxWidth: '100px' }}>
                                        <div className="flex justify-content-between align-items-center gap-[8px]">
                                            <button onClick={() => onEditHandle(odc)}>
                                                <EditIcon color={"Orange"} size={18} />
                                            </button>
                                            <div className="flex align-items-center">
                                                <button onClick={() => {
                                                    setOdcToDelete(odc)
                                                    setShowAlert(true)
                                                }}>
                                                    <TrashIcon color={"Orange"} size={0} />
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {
                (showAlert && odcToDelete) && (
                    <Alert title="Conferma operazione" close={() => setShowAlert(false)}>
                        <div style={{ display: "flex", flexDirection: 'column', justifyContent: 'center' }}>
                            <div className="pb-3">
                                <div>
                                    Sei sicuro di voler eliminare l'allegato?
                                </div>
                                <div className="mt-3 font-bold">
                                    {`${odcToDelete.companyName} - [${odcToDelete.code}]`}
                                </div>
                            </div>
                            <div className="mt-6" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    variant="outline"
                                    color="dimgrey"
                                    iconPosition="off"
                                    label="Annulla"
                                    size="md"
                                    onClick={() => setShowAlert(false)}
                                />
                                <div className="pl-8">
                                    <Button
                                        variant="solid"
                                        color="orange"
                                        iconPosition="off"
                                        label="Elimina"
                                        size="md"
                                        onClick={() => {
                                            dispatch(OdcDelete(odcToDelete.id!))
                                            setShowAlert(false)
                                        }}
                                    />
                                </div>
                            </div>

                        </div>
                    </Alert>
                )
            }
            {
                showPopup && (
                    <PopupForm title="Modifica OdC" close={() => setShowPopup(false)}>
                        <ODCPopupEdit close={() => setShowPopup(false)} />
                    </PopupForm>
                )
            }
        </div>
    )
}