import axios from "axios";
import { ConversionPeriodCreationDTO, ConversionPeriodDTO, ConversionPeriodUpdateDTO, GetAllConversionPeriodsDTO, GetAllConversionPeriodsFiltersDTO } from "./dto";
import { ConversionPeriodService } from "./service";
import { keycloak } from "../../../../lib/keycloak";

export class ConversionPeriodServiceImpl implements ConversionPeriodService {

    public CreateConversionPeriod(data: ConversionPeriodCreationDTO): Promise<void> {
        return axios.post("/api/conversion-period",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public UpdateConversionPeriod(data: ConversionPeriodUpdateDTO, id: string): Promise<void> {
        return axios.put("/api/conversion-period/" + id,
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public DeleteConversionPeriod(id: string): Promise<void> {
        return axios.delete("/api/conversion-period/" + id,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'application/json'
                }
            }
        ).then((response) => response.data);
    }

    public GetAllConversionPeriods(filters: GetAllConversionPeriodsFiltersDTO): Promise<GetAllConversionPeriodsDTO> {
        return axios.get("/api/conversion-period", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page ? filters.page : 0,
                sort: filters.sort,
                order: filters.order,
                countryRegion: filters.countryRegion !== '' ? filters.countryRegion : undefined,
                headQuarter: filters.headQuarter,
                state: filters.state,
                year: filters.year
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public GetConversionPeriodById(id: string): Promise<ConversionPeriodDTO> {
        return axios.get("/api/conversion-period/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }
}