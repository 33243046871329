import { NewMeasureFiltersDTO, OldMeasureFiltersDTO, OtherOdcMeasuresCreationUpdateDTO, OtherOdcMeasuresResponseDTO, OtherOdcNewMeasuresCreationUpdateDTO } from "./dto";
import { OldMeasureServiceImpl } from "./serviceImpl";

export interface OldMeasureService {
    CreateOldMeasure(data: OtherOdcMeasuresCreationUpdateDTO): Promise<void>
    CreateNewMeasure(data: OtherOdcNewMeasuresCreationUpdateDTO): Promise<void>
    GetAllCustomerOldMeasure(filters: OldMeasureFiltersDTO): Promise<OtherOdcMeasuresResponseDTO>
    GetAllCustomerNewMeasure(filtersNew: NewMeasureFiltersDTO): Promise<OtherOdcMeasuresResponseDTO>
    UpdateOldMewasureOtherOdc(id:string, data: OtherOdcMeasuresCreationUpdateDTO): Promise<void>
    UpdateNewMeasureOtherOdc(id:string, data: OtherOdcNewMeasuresCreationUpdateDTO): Promise<void>
}

export function NewOldMeasureService(): OldMeasureService {
    return new OldMeasureServiceImpl()
}